import { USER_ROLES } from 'src/common/constants';

const { OWNER, RENTER } = USER_ROLES;

const getContentBit = (role, isPositiveReview, guestName, ownerName) => {
  const noteForOwner = `The details you provide here are private between you and ${guestName}.`;
  const ownerTitle = isPositiveReview ? `Add a private note for ${guestName}.` : `Sorry to hear that. Can you add private feedback for ${guestName}?`;
  const ownerSubTitle = isPositiveReview ? 'Offer thanks, feedback, suggestions or any other comments.' : noteForOwner;

  const content = {
    [OWNER]: {
      title: ownerTitle,
      subTitle: ownerSubTitle,
      text: noteForOwner,
      placeholder: `Add private notes for ${guestName}.`,
    },
    [RENTER]: {
      title: `Add a private note for ${ownerName}.`,
      subTitle: 'Offer thanks, feedback, suggestions or any other comments.',
      text: `This is private between you and ${ownerName}.`,
      placeholder: `Add private notes for ${ownerName}.`,
    },
  };

  return content[role];
};

export default getContentBit;
