import PropTypes from 'prop-types';
import React from 'react';

import { PATHS } from '../../../../common/constants';
import { preserveSearch } from '../../../../common/utils/routing';
import ArchiveButton from '../../ArchiveButton';
import CTA, { CTALink } from '../../CTA';

const MovedCTA = ({
  archived,
  trip,
  location,
}) => (
  <CTA classNameModifier="withSidebar">
    <ArchiveButton
      archived={archived}
      trip={trip}
    >
      {`${archived ? 'Unarchive' : 'Archive'} this Inquiry`}
    </ArchiveButton>
    <CTALink to={preserveSearch(`${PATHS.INBOX}${trip.get('child')}/`, location)}>
      Go to the new Booking
    </CTALink>
  </CTA>
);

MovedCTA.propTypes = {
  archived: PropTypes.bool.isRequired,
  trip: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default MovedCTA;
