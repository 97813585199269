import React, { createContext, FC, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import useBoats from '../../common/hooks/useBoats';
import useFilters from './useFilters';

const useListingsFilter = () => {
  const { allBoats } = useBoats();
  const boatIds = useMemo(() => allBoats.map(({ id: boatId }) => boatId), [allBoats]);
  return useFilters(boatIds);
};

type UseListingsFilters = ReturnType<typeof useListingsFilter>;

const noop = () => { };

const ListingsFiltersContext = createContext<UseListingsFilters>({
  filters: [],
  activeFilters: [],
  addListingFilters: noop,
  clearFilters: noop,
  selectAllFilters: noop,
  toggleAllFilters: noop,
  toggleFilter: noop,
  setActiveFilters: noop,
});

export const useListingsFilterContext = () => useContext(ListingsFiltersContext);

export const ListingsFilterProvider: FC = ({ children }) => (
  <ListingsFiltersContext.Provider value={useListingsFilter()}>
    {children}
  </ListingsFiltersContext.Provider>
);

ListingsFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
