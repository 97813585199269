import React, { FC } from 'react';

import { getClassNameFor } from 'src/common/helpers';

import s from './EventDetailsHeader.module.scss';

type Props = {
  children?: React.ReactNode;
  classNameModifier?: string;
  title?: string;
  description?: React.ReactNode;
};

const EventDetailsHeader:FC<Props> = ({
  children,
  classNameModifier = '',
  title,
  description,
}) => (
  <div className={getClassNameFor(s, 'root', classNameModifier)}>
    {children}
    <h3 className={s.title}>
      {title}
    </h3>
    <p className={s.description}>
      {description}
    </p>
  </div>
);

export default EventDetailsHeader;
