import React, { FC } from 'react';
import s from './Modal.module.scss';

type FooterProps = {
  children: React.ReactNode;
};

const Footer: FC<FooterProps> = ({ children }) => (
  <div className={s.footer}>
    {children}
  </div>
);

export default Footer;
