import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { parse } from 'query-string';
import { BroadcastChannel } from 'broadcast-channel';

import { useDispatch, useSelector } from 'react-redux';
import { fromJS, List } from 'immutable';
import captureException from '../../common/utils/captureException';
import dynamicImport from '../../common/utils/dynamicImport';
import * as StreamProtocol from '../streamProtocol';
import {
  PubSubSeenMessageSocketPayload,
  PubSubTypingSocketPayload,
  PubSubPresenceSocketPayload,
  NotificationSoundTypes,
} from '../types';
import { pushMessage, updateMessage } from '../../inbox/ducks/messages';
import { appendTrips, getTripCall } from '../../inbox/ducks/trips';
import { selectTrip } from '../../inbox/helpers';
import { InboxMessage } from '../../inbox/type/InboxMessage';
import { ImmutableTrip, ImmutableTrips } from '../../types/trips/Trips';
import { ImmutableUser } from '../../types/user/User';
import { ReduxState } from '../../types/reduxState';
import useUnreadMessageStore from './useUnreadMessageStore';
import { getTransactionCall } from '../../inbox/ducks/transaction';
import getInboxRouteInfo from '../util/getInboxRouteInfo';
import apiFetch from '../apiFetch';

export const TYPING_TIMEOUT = 2000;
export const INITIAL_PRESENCE_STATE: Record<number, boolean> = {};
const INITIAL_TYPING_STATE: Record<string, boolean> = {};

type BroadcastPayloads =
  | PubSubTypingSocketPayload
  | PubSubSeenMessageSocketPayload
  | PubSubPresenceSocketPayload;
const broadcastSocketSender = new BroadcastChannel<BroadcastPayloads>('broadcastSocketSender', {
  webWorkerSupport: false,
});

const soundBroadcast = new BroadcastChannel<NotificationSoundTypes>('soundSocketSender', {
  webWorkerSupport: false,
});

/**
 * Acts as a Singleton and prevents initPubSub from being called
 * multiple times
 *
 * Caveat - this stops hot reloading from working when working with pubsub
 */
let hasPubSubInstance = false;

/**
 * Transforms a notification payload to be compatible with the inbox messages
 */
const notificationPayloadToInboxMessage = (
  { data }: StreamProtocol.Notification,
): InboxMessage => ({
  content: data.content,
  date_created: data.date_created,
  id: data.message_id,
  subject: data.subject,
  was_obfuscated: data.was_obfuscated,
  user: {
    id: data.user_id,
    first_name: data.sender_first_name,
    has_photo: data.has_photo,
    last_name: data.sender_last_name,
    photo_url: data.sender_avatar,
  },
});

/**
 * Transforms a thread message payload to be compatible with the inbox messages
 */
const threadMessagePayloadToInboxMessage = (
  { data }: StreamProtocol.ThreadMessage,
  user: ImmutableUser,
): InboxMessage => ({
  content: data.content,
  date_created: data.date_created,
  id: parseInt(data.message_id, 10),
  subject: data.subject,
  was_obfuscated: false,
  user: {
    id: user.get('id'),
    first_name: user.get('first_name'),
    has_photo: user.get('has_photo'),
    last_name: user.get('last_name'),
    photo_url: user.get('photo_url'),
  },
});

/**
 * Load the pubsub functionality.
 */
const usePubsub = () => {
  const [presence, setPresence] = useState(INITIAL_PRESENCE_STATE);
  const clearPresence = () => {
    setPresence(INITIAL_PRESENCE_STATE);
  };
  const [typingIndicators, setTypingIndicators] = useState(INITIAL_TYPING_STATE);
  const {
    addUnreadMessage,
    removeUnreadMessages,
    getUnreadMessagesForThread,
  } = useUnreadMessageStore();

  const handlePresenceUpdate = useCallback((onlinePresence: Record<number, boolean>) => {
    setPresence(prev => ({ ...prev, ...onlinePresence }));
  }, []);

  const resetIdleThread = (threadId: string) => {
    setTimeout(() => {
      setTypingIndicators(prevTypingIndicators => {
        const copyPrevTypingIndicators = { ...prevTypingIndicators };
        delete copyPrevTypingIndicators[threadId];
        return copyPrevTypingIndicators;
      });
    }, TYPING_TIMEOUT);
  };

  const updateTypingIndicators = useCallback(({ thread_id: threadId }: StreamProtocol.Typing) => {
    setTypingIndicators(prevTypingIndicators => ({
      ...prevTypingIndicators,
      [threadId]: true,
    }));
    resetIdleThread(threadId);
  }, []);

  const dispatch = useDispatch();

  const trips = useSelector<ReduxState, ImmutableTrips>(state => state.trips);
  const user = useSelector<ReduxState, ImmutableUser>(state => state.user);
  const tripIds = useMemo(() => {
    const ids: string[] = [];
    trips.get('results', List()).forEach(trip => {
      const threadId = trip?.get('pk');
      if (threadId) {
        ids.push(`${threadId}`);
      }
    });
    return ids;
  }, [trips]);

  const dispatchUnreadTripMessage = useCallback((trip: ImmutableTrip, lastMessageData: string) => {
    const updatedTrip = trip
      .update('has_unread_message', () => true)
      .update('date_last_message', () => lastMessageData);
    dispatch(appendTrips(fromJS({ results: [updatedTrip] })));
  }, [dispatch]);

  const dispatchGetFilteredTrip = useCallback((
    threadId: string,
    isOnActiveThread: boolean,
  ) => {
    const queryParams = window.location && parse(window.location.search);
    const filter = queryParams && queryParams.filter;
    // Add `leave_unseen=true` to prevent prematurely resetting `has_unseen_state_change`
    dispatch(getTripCall(threadId, { filter, leave_unseen: !isOnActiveThread }));
  }, [dispatch]);

  useEffect(() => {
    tripIds.forEach(threadId => {
      broadcastSocketSender.postMessage({ type: 'presence', threadId });
    });
  }, [tripIds]);

  const tripsRef = useRef<ImmutableTrips | null>(null);
  tripsRef.current = trips;

  const userRef = useRef<ImmutableUser | null>(null);
  userRef.current = user;

  const addMessage = useCallback((message: StreamProtocol.Notification) => {
    const threadId = message.thread_id;
    const {
      isInbox: userIsInInbox,
      threadId: activeThreadId,
      view,
    } = getInboxRouteInfo();
    const onMessageTab = view === 'messages';
    const isOnActiveThread = activeThreadId === threadId;

    // Ignoring message as the user is not in the inbox.
    if (!userIsInInbox) {
      return;
    }

    if (document.hidden || !isOnActiveThread || !onMessageTab) {
      soundBroadcast.postMessage(NotificationSoundTypes.Message);
    }

    addUnreadMessage(
      message.thread_id,
      message.ahoy_id,
      message.ahoy_msgid,
      `${message.data.message_id}`,
    );

    const trip = selectTrip(tripsRef.current, threadId);
    const isTripLoaded = trip.has('has_unread_message') && trip.has('date_last_message');
    // If the event is not a new message notification type,
    // we need to fetch the trips updated status.
    if (isTripLoaded && !message.notification.match(/new-message/g)) {
      dispatchGetFilteredTrip(threadId, isOnActiveThread);
    }

    // Append the message when the message is for the active thread.
    if (isOnActiveThread) {
      dispatch(pushMessage(fromJS(notificationPayloadToInboxMessage(message))));
      if (!onMessageTab) {
        dispatchUnreadTripMessage(trip, message.data.date_created);
      }
      if (message.notification === 'renter-new-offer') {
        // Update transaction to reflect latest price change.
        dispatch(getTransactionCall(threadId));
      }
      return;
    }

    // See if the thread is in the already loaded set of threads.
    if (isTripLoaded) {
      // Mark the thread as having unread messages.
      dispatchUnreadTripMessage(trip, message.data.date_created);
    } else {
      // Load the thread so that it is available for the user
      dispatchGetFilteredTrip(threadId, isOnActiveThread);
    }
  }, [addUnreadMessage, dispatch, dispatchUnreadTripMessage, dispatchGetFilteredTrip]);

  const addUserMessage = useCallback((message: StreamProtocol.ThreadMessage) => {
    const threadId = message.thread_id;
    const {
      isInbox: userIsInInbox,
      threadId: activeThreadId,
    } = getInboxRouteInfo();

    // Ignoring message as the user is not in the inbox.
    if (!userIsInInbox) {
      return;
    }

    if (activeThreadId === threadId && userRef.current) {
      dispatch(
        pushMessage(
          fromJS(
            threadMessagePayloadToInboxMessage(
              message,
              userRef.current,
            ),
          ),
        ),
      );
    }
  }, [dispatch]);

  const editMessage = useCallback((message: StreamProtocol.EditMessage) => {
    const threadId = message.thread_id;
    const { threadId: activeThreadId } = getInboxRouteInfo();

    // We will refetch the thread in this case anyway
    if (activeThreadId !== threadId) { return; }

    dispatch(updateMessage(message));
  }, [dispatch]);

  const markMessageAsSeen = useCallback(({ thread_id: threadId }: StreamProtocol.SeenMessage) => {
    if (threadId) {
      const trip = selectTrip(tripsRef.current, threadId);
      if (trip.has('has_unread_message')) {
        const updatedTrip = trip.update('has_unread_message', () => false);
        dispatch(appendTrips(fromJS({
          results: [updatedTrip],
        })));
      }
    }
  }, [dispatch]);

  useEffect(
    () => {
      if (typeof window !== 'undefined' && !hasPubSubInstance) {
        dynamicImport(
          () => import(/* webpackChunkName: "pubsub" */ '../index')
            .then(mod => mod.default),
        )
          .then(init => {
            init?.(
              handlePresenceUpdate,
              clearPresence,
              updateTypingIndicators,
              addMessage,
              addUserMessage,
              editMessage,
              markMessageAsSeen,
            );
            hasPubSubInstance = true;
          })
          .catch(captureException);
      }
    },
    [
      handlePresenceUpdate,
      updateTypingIndicators,
      addMessage,
      markMessageAsSeen,
      editMessage,
      addUserMessage,
    ],
  );

  const getUserPresence = useCallback((userId: number) => presence[userId], [presence]);

  const isThreadTyping = useCallback(
    (threadId: number) => typingIndicators[threadId],
    [typingIndicators],
  );

  const postThreadTyping = useCallback((threadId: string) => {
    broadcastSocketSender.postMessage({ type: 'typing', threadId });
  }, []);

  const seenThreadMessages = useCallback((threadId: string) => {
    const unreadMessages = getUnreadMessagesForThread(threadId);
    if (unreadMessages.length === 0) {
      return;
    }

    apiFetch(`/trips/${threadId}/mark_messages_read/`, { method: 'PATCH' });

    unreadMessages.forEach(({ ahoyId, ahoyMsgId, messageId }) => {
      broadcastSocketSender.postMessage({
        type: 'seen',
        ahoyId,
        ahoyMsgId,
        messageId,
        threadId,
      });
    });

    removeUnreadMessages(threadId);
  }, [getUnreadMessagesForThread, removeUnreadMessages]);

  const postPresence = useCallback(() => {
    broadcastSocketSender.postMessage({ type: 'presence' });
  }, []);

  return {
    getUserPresence,
    isThreadTyping,
    postThreadTyping,
    seenThreadMessages,
    postPresence,
  };
};

const PubSubContext = createContext<ReturnType<typeof usePubsub>>({
  getUserPresence: () => false,
  isThreadTyping: () => false,
  postThreadTyping: () => false,
  seenThreadMessages: () => false,
  postPresence: () => false,
});

export const usePubSubContext = () => useContext(PubSubContext);

export const PubSubProvider: FC = ({ children }) => {
  const pubsub = usePubsub();
  return (
    <PubSubContext.Provider value={pubsub}>
      {children}
    </PubSubContext.Provider>
  );
};
