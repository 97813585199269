import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../../common/helpers';

import { InstabookEvent, InstabookWarning } from '../../types';

import Tooltip from '../../../common/components/Tooltip';
import InstabookDepartureTimesTooltip from '../InstabookDepartureTimesTooltip';

import { getTimeString } from '../../helpers';
import { INSTABOOK_TIME_FORMAT, INSTABOOK_WARNINGS } from '../../constants';

import s from './InstabookCardTimes.module.scss';

export type InstabookCardTimesProps = {
  duration: InstabookEvent['trip_length'];
  tripTimes: InstabookEvent['instabook_trip_times'];
  warning?: InstabookWarning;
};

const InstabookCardTimes: FC<InstabookCardTimesProps> = ({
  duration,
  tripTimes,
  warning,
}) => {
  const formatTime = useCallback(time => getTimeString(time, INSTABOOK_TIME_FORMAT), []);
  const fixedTimes = useMemo(
    () => tripTimes?.[0]?.min_start_time === tripTimes?.[0]?.max_start_time,
    [tripTimes],
  );

  const copy = {
    [INSTABOOK_WARNINGS.PARTIALLY_UNAVAILABLE_TIMES]: '(some departure times are unavailable)',
  };

  if (tripTimes.length === 0) {
    return (
      <div className={s.root}>
        <span className={s.summary}>Departure time TBD</span>
      </div>
    );
  }

  return (
    <div className={getClassNameFor(s, 'root', classNames({ fixedTimes }))}>
      {tripTimes.map(({ min_start_time, max_start_time }, index) => {
        const earliestDepartureTime = formatTime(min_start_time);
        const latestDepartureTime = formatTime(max_start_time);

        return (
          <Tooltip
            // eslint-disable-next-line react/no-array-index-key
            key={`${earliestDepartureTime}-${index}`}
            classNameModifier="instabookTime"
            renderCTA={({ className, open, close, tooltipCTARef }) => (
              <div
                className={className}
                onMouseEnter={open}
                onMouseLeave={close}
                ref={tooltipCTARef}
              >
                {fixedTimes ? (
                  earliestDepartureTime
                ) : (
                  <>
                    {earliestDepartureTime} → {latestDepartureTime}
                  </>
                )}
              </div>
            )}
          >
            <InstabookDepartureTimesTooltip
              earliestDepartureTime={min_start_time}
              latestDepartureTime={fixedTimes ? undefined : max_start_time}
              duration={duration}
            />
          </Tooltip>
        );
      })}
      {warning && copy && <span>{copy[warning]}</span>}
    </div>
  );
};

export default InstabookCardTimes;
