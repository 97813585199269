import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../helpers';
import CaptainIcon from './CaptainIcon';
import s from './CaptainSnippet.module.scss';

export type CaptainSnippetProps = {
  captained?: boolean;
  classNameModifier?: string;
};

const CaptainSnippet: FunctionComponent<CaptainSnippetProps> = ({
  captained,
  children,
  classNameModifier,
}) => (
  <div className={getClassNameFor(s, 'root', classNames(classNameModifier))}>
    <CaptainIcon
      captained={captained}
      classNameModifier={classNameModifier}
    />
    <div className={s.content}>
      {children}
    </div>
  </div>
);

export default CaptainSnippet;
