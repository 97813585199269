import React, { FC } from 'react';
import { Link, Route } from 'react-router-dom';
import moment from 'moment';
import { List, Map } from 'immutable';

import type { ImmutableConflictingTrip } from '../../../../types/trips/Trips';
import { MOMENT_FORMAT } from '../../../../common/constants';
import { preserveSearch, siblingRoute } from '../../../../common/utils/routing';
import { nameWithInitial } from '../../../../common/utils/user';
import s from './ConflictingTripsList.module.scss';

type ConflictingTripsListProps = {
  conflictsList: List<ImmutableConflictingTrip>;
  title: string;
};

const ConflictingTripsList: FC<ConflictingTripsListProps> = ({
  conflictsList,
  title,
}) => (!conflictsList.isEmpty() ? (
  <>
    <h4 className={s.subTitle}>
      {title}
    </h4>
    <ul className={s.list}>
      {conflictsList.map(conflict => {
        const conflictMoment = moment(conflict?.getIn(['conflicts', 0]));
        const threadId = conflict?.get('thread');
        return (
          <li key={threadId}>
            <Route>
              {({ location }) => (
                <Link
                  to={preserveSearch(siblingRoute(location.pathname, threadId), location)}
                  className={s.link}
                >
                  {`${nameWithInitial(Map({
                    first_name: conflict?.get('renter_first_name', ''),
                    last_name: conflict?.get('renter_last_name', ''),
                  }))}, `}
                  <time dateTime={conflictMoment.format(MOMENT_FORMAT.VALUE)}>
                    {conflictMoment.format('D MMM YYYY')}.
                  </time>
                </Link>
              )}
            </Route>
          </li>
        );
      })}
    </ul>
  </>
) : null);

export default ConflictingTripsList;
