import React, { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import InputWrapper from 'src/common/components/InputWrapper';
import TextInput from 'src/common/components/inputs/TextInput/TextInput';
import Button from '../../../../common/components/Button';
import { verifyMFARecoveryCodeAction } from '../../../../common/ducks/user';
import { unpackApiError } from '../../../../common/helpers';
import { ReduxState } from '../../../../types/reduxState';

import s from './MFARecoveryForm.module.scss';

type FormValues = {
  code: string;
};

type FormErrors = Partial<FormValues>;

const validate = (values: FormValues): FormErrors => {
  const errors: FormErrors = {};

  if (!values.code) {
    errors.code = 'Please enter authentication code';
  }
  return errors;
};

type MFARecoveryFormProps = {
  onSubmitSuccess: () => void;
};

const MFARecoveryForm: FC<MFARecoveryFormProps> = ({
  onSubmitSuccess,
}) => {
  const dispatch = useDispatch<ThunkDispatch<ReduxState, {}, Action>>();
  const onSubmit = useCallback(
    async (values: { code: string }) => dispatch(verifyMFARecoveryCodeAction(values))
      .then(onSubmitSuccess)
      .catch(async (error) => {
        const { detail } = await unpackApiError(error);
        return {
          code: detail,
        };
      }),
    [
      dispatch,
      onSubmitSuccess,
    ],
  );
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ handleSubmit, submitting }) => (
        <form
          method="POST"
          onSubmit={handleSubmit}
          className={s.root}
        >
          <fieldset className={s.fieldset}>
            <InputWrapper
              name="code"
              showErrorOnTouch
              showErrorMessageIcon
              preventErrorMessageLayoutShift={false}
              centerErrorMessage
            >
              <TextInput
                name="code"
                autoFocus
              />
            </InputWrapper>
          </fieldset>
          <div className={s.actionGroup}>
            <Button
              type="submit"
              submitting={submitting}
              fullWidth
            >
              Verify
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

export default MFARecoveryForm;
