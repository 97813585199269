import React, { FC, useEffect } from 'react';
import { Route, Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { useFeatureFlag } from 'src/common/hooks';
import type { ReduxState } from '../types/reduxState';
import type { ImmutableSearchBoatDetails } from '../types/boat/BoatDetail';
import type { ImmutableInquiry } from '../types/inquiry/Inquiry';
import Root from './components/Root';
import { BROADCAST_BOATS_FIELD, INSTABOOK_LISTINGS_FIELD, PAGES } from './constants';
import { decorateComponent } from '../common/helpers';
import { hasBroadcastBoats, hasInstabookListings, hasMismatches } from './helpers';
import {
  WelcomeScreen,
  Duration,
  Dates,
  Time,
  GroupSize,
  Captain,
  Experience,
  Extras,
  Contact,
  Password,
  TrySomethingElse,
  SimilarListings,
  InquirySent,
  CommunicationWarning,
  Mismatches,
  InstabookListings,
} from './components/pages';
import PageNotFound from '../common/components/NotFoundContent';
import { BookingMotivatorsProvider } from '../common/hooks/useBookingMotivators';
import useSavedInquiry from './components/hooks/useSavedInquiry';
import { updateInquiry } from './ducks/inquiry';

const mapStateToProps = ({ boat, user, inquiry }: ReduxState) => ({
  userAuthenticated: user.get('loggedIn'),
  inquiryHasMismatches: hasMismatches(inquiry),
  inquiryHasInstabookListings: hasInstabookListings(inquiry),
  inquiryHasBroadcastBoats: hasBroadcastBoats(inquiry),
  boat,
  inquiry,
});

type BookingRoutesProps = {
  userAuthenticated?: boolean;
  boat: ImmutableSearchBoatDetails;
  inquiry: ImmutableInquiry;
  inquiryHasMismatches?: boolean;
  inquiryHasInstabookListings?: boolean;
  inquiryHasBroadcastBoats?: boolean;
};

export const BookingRoutes: FC<BookingRoutesProps> = props => {
  const {
    userAuthenticated,
    boat,
    inquiry,
    inquiryHasMismatches,
    inquiryHasInstabookListings,
    inquiryHasBroadcastBoats,
  } = props;
  const { url } = useRouteMatch();
  const { search } = useLocation();
  const dispatch = useDispatch<ThunkDispatch<ReduxState, {}, Action>>();
  const isInstabookListingsEnabled = useFeatureFlag('instaspike--inquiry-flow');
  const captained = boat.get('captained');
  const bareboat = boat.get('bareboat');
  // This is our name for an inquiry which can be sent early, since it never needs
  // to hit the matching listings page on account of having no mismatches and no
  // boats that it can broadcast to.
  let streamlineInquiry = userAuthenticated
    && inquiry.get('mismatches').isEmpty()
    && inquiry.get(BROADCAST_BOATS_FIELD).isEmpty();

  if (isInstabookListingsEnabled) {
    streamlineInquiry = streamlineInquiry && inquiry.get(INSTABOOK_LISTINGS_FIELD).isEmpty();
  }

  const { savedInquiry } = useSavedInquiry(boat);

  useEffect(() => {
    if (savedInquiry) {
      if (Object.values(savedInquiry.details).every(value => !value)) { return; }
      dispatch(updateInquiry(savedInquiry.details));
    }
  }, [savedInquiry, boat, dispatch]);

  const prices = boat.get('prices');
  const isNight = prices.some((price) => price?.get('price_unit') === 'night');

  return (
    <BookingMotivatorsProvider
      boatId={boat.get('id')}
      hasNightPricing={isNight}
    >
      <Root {...props}>
        <Switch>
          <Route exact path={url} component={WelcomeScreen} />
          <Route exact path={`${url}/${PAGES.DURATION}/`} component={Duration} />
          <Route exact path={`${url}/${PAGES.DATES}/`} component={Dates} />
          <Route exact path={`${url}/${PAGES.TIME}/`} component={Time} />
          <Route exact path={`${url}/${PAGES.GROUP}/`} component={GroupSize} />
          <Route
            exact
            path={`${url}/${PAGES.CAPTAIN}/`}
            // conditional redirection
            render={renderProps => (
              bareboat === captained
                ? <Captain {...renderProps} />
                : <Redirect to={`${url}/${PAGES.EXTRAS}/`} />
            )}
          />
          <Route
            exact
            path={`${url}/${PAGES.EXPERIENCE}/`}
            component={Experience}
          />
          <Route exact path={`${url}/${PAGES.EXTRAS}/`} component={Extras} />
          <Route
            exact
            path={`${url}/${PAGES.CONTACT}/`}
            render={renderProps => (
              <Contact
                {...renderProps}
                streamlineInquiry={streamlineInquiry}
                inquiryHasMismatches={inquiryHasMismatches}
              />
            )}
          />
          <Route
            path={`${url}/${PAGES.PASSWORD}/`}
          // conditional redirection
            render={renderProps => (
              !userAuthenticated
                ? (
                  <Password
                    streamlineInquiry={streamlineInquiry}
                    {...renderProps}
                  />
                )
                : <Redirect to={`${url}/${PAGES.CONTACT}/`} />
            )}
          />
          <Route exact path={`${url}/${PAGES.MISMATCHES}/`} component={Mismatches} />
          <Route
            exact
            path={`${url}/${PAGES.MATCH}/`}
            render={renderProps => {
              if (inquiryHasMismatches) {
                return (
                  <TrySomethingElse
                    inquiryHasInstabookListings={inquiryHasInstabookListings}
                    {...renderProps}
                  />
                );
              }
              if (inquiryHasBroadcastBoats) {
                return (
                  <SimilarListings
                    inquiryHasInstabookListings={inquiryHasInstabookListings}
                    {...renderProps}
                  />
                );
              }

              return <InstabookListings {...renderProps} />;
            }}
          />
          <Route
            exact
            path={`${url}/${PAGES.INSTABOOK}/`}
            render={renderProps => (
              <InstabookListings
                {...renderProps}
              />
            )}
          />
          <Route exact path={`${url}/${PAGES.WARNING}/`} component={CommunicationWarning} />
          <Route exact path={`${url}/${PAGES.DONE}/`} component={InquirySent} />
          {/* These redirects are here for convenience's sake, and exist because the
            naming of fields and pages is slightly different. These make routing around
            the mismatches page easier. */}
          <Redirect from={`${url}/availability/`} to={`${url}/dates/${search}`} exact />
          <Redirect from={`${url}/capacity/`} to={`${url}/group/${search}`} exact />
          <Route
            render={renderProps => (
              <PageNotFound
                {...renderProps}
                classNameModifier="modal"
              />
            )}
          />
        </Switch>
      </Root>
    </BookingMotivatorsProvider>
  );
};

const decorators = [
  connect(mapStateToProps),
];

export default decorateComponent(BookingRoutes, decorators);
