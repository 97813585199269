import React, { FC } from 'react';
import s from './CaptainTemplate.module.scss';
import { getClassNameFor } from '../../helpers';
import { ListingCaptainOption } from '../../utils/getListingCaptainOption';
import { CAPTAIN_OPTION_KEYS } from '../../constants';
import Svg from '../Svg';

const captainOptionMap = {
  [CAPTAIN_OPTION_KEYS.PROVIDED]: 'captain-provided',
  [CAPTAIN_OPTION_KEYS.ARRANGED_SEPARATELY]: 'captain-separated',
  [CAPTAIN_OPTION_KEYS.NOT_PROVIDED]: 'captain-not-provided',
};

type Props = {
  classNameModifier?: string;
  selectedOption?: Pick<ListingCaptainOption, 'key'>;
};

const CaptainTemplate: FC<Props> = ({ children, classNameModifier, selectedOption }) => {
  const showIcon = selectedOption && captainOptionMap[selectedOption?.key];
  return (
    <div className={s.root}>
      {showIcon && (
        <Svg
          href={`#${captainOptionMap[selectedOption?.key]}`}
          className={getClassNameFor(s, 'icon', classNameModifier)}
        />
      )}
      <div className={getClassNameFor(s, 'content', classNameModifier)}>{children}</div>
    </div>
  );
};

export default CaptainTemplate;
