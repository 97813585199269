import React from 'react';

import { EXTERNAL_PATHS } from 'src/common/constants';
import type { PaymentAddon as PaymentAddonType } from 'src/types/trips/PaymentAddon';
import { completePriceFormatter } from 'src/common/utils/currency';
import PaymentAddon from '../PaymentAddon';
import s from './PaymentAddons.module.scss';

type PaymentAddonsProps = {
  addons: PaymentAddonType[];
  isOwner: boolean;
};

const PaymentAddons: React.FC<PaymentAddonsProps> = ({
  addons,
  isOwner,
}) => (
  <div className={s.root}>
    {addons?.map(({
      description: descriptionForRenter,
      expiry_date_UTC,
      formatted_renter_subtotal,
      hash_id,
      pk,
      status,
      status_date_UTC,
      sub_type_label,
      transaction,
      thread,
    }) => {
      const amounts = transaction?.amounts;
      const description = amounts?.description ?? descriptionForRenter;
      const formattedAmount = isOwner ? completePriceFormatter(
        amounts.subtotal,
        amounts.owner_currency.code,
        amounts.owner_currency.symbol,
      ) : formatted_renter_subtotal as string;
      const subTypeLabel = amounts?.sub_type_label ?? sub_type_label;
      const paymentCategory = `${subTypeLabel}${description ? `: ${description}` : ''}`;
      const summaryLink = isOwner
        ? `${EXTERNAL_PATHS.CHARGE_ADD_ON}summary/${thread}/${pk}/`
        : undefined;
      const paymentLink = `${EXTERNAL_PATHS.CHARGE_ADD_ON}${thread}/${hash_id}/`;

      return (
        <PaymentAddon
          key={pk}
          expiryDate={expiry_date_UTC}
          lastActivityDate={status_date_UTC}
          paymentAmount={formattedAmount}
          paymentCategory={paymentCategory}
          paymentLink={paymentLink}
          summaryLink={summaryLink}
          status={status}
          userRole={isOwner ? 'owner' : 'renter'}
        />
      );
    })}
  </div>
);

export default PaymentAddons;
