import React from 'react';
import PropTypes from 'prop-types';

import { FieldPrefixContext } from '../FormSection';

const PrefixedLabel = ({ htmlFor, ...props }) => (
  <FieldPrefixContext.Consumer>
    {prefix => (
      <label
        htmlFor={`${prefix}.${htmlFor}`}
        {...props}
      />
    )}
  </FieldPrefixContext.Consumer>
);

PrefixedLabel.propTypes = {
  htmlFor: PropTypes.string.isRequired,
};

export default PrefixedLabel;
