import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import useLoggedInRedirect from 'src/auth/hooks/useLoggedInRedirect';
import { TITLE } from '../../../../common/constants';
import Button from '../../../../common/components/Button';
import AgreementContent from '../../../../common/components/AgreementContent';
import s from '../Register/Register.module.scss';

const title = TITLE.AGREEMENT;

const Agreement: FC<AgreementProps> = ({
  submitting,
  isError,
}) => {
  useLoggedInRedirect(isError);
  return (
    <div className={s.container}>
      <Helmet>
        <title lang="en">
          {title}
        </title>
      </Helmet>

      <AgreementContent />

      <Button
        submitting={submitting}
        type="submit"
        fullWidth
      >
        Accept
      </Button>
    </div>
  );
};

type AgreementProps = {
  submitting?: boolean;
  isError?: boolean;
};

export default Agreement;
