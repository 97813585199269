import React from 'react';
import { useTranslation } from 'src/common/hooks';
import RadioButtonField, { parseTrueFalseValue } from 'src/common/components/fields/RadioButton';
import { FIELD_NAMES } from '../constants';

const LiabilityAndDamageCoverage: React.FC = () => {
  const { t } = useTranslation('insurance');

  return (
    <fieldset>
      <legend>
        {t('liabilityTitle')}
      </legend>
      <p>
        {t('liabilityDescription')}
      </p>
      <label>
        <RadioButtonField
          name={FIELD_NAMES.liability_coverage}
          parse={parseTrueFalseValue}
          value
        />{' '}
        {t('liabilityYes')}
      </label>
      <br />
      <label>
        <RadioButtonField
          name={FIELD_NAMES.liability_coverage}
          parse={parseTrueFalseValue}
          value={false}
        />{' '}
        {t('liabilityNo')}
      </label>
    </fieldset>
  );
};

export default LiabilityAndDamageCoverage;
