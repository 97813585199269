import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { appendRoute, preserveSearch } from '../../../../common/utils/routing';
import { PAGES } from '../../../constants';
import UserBlock from '../UserBlock';

const BookingCancelledMessage = ({
  children,
  location,
  renterName,
  trip,
}) => (
  <UserBlock user={trip.get('renter')}>
    <strong>
      {children}
    </strong>
    {' '}
    You can send a new offer with an updated date or {' '}
    <Link to={preserveSearch(appendRoute(location.pathname, PAGES.MESSAGES), location)}>
      contact {renterName}
    </Link>
    {' '}
    for more options.
  </UserBlock>
);

BookingCancelledMessage.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  renterName: PropTypes.string.isRequired,
  trip: PropTypes.object.isRequired,
};

export default BookingCancelledMessage;
