import { List, Map } from 'immutable';
import type { ImmutableInquiry } from '../types/inquiry/Inquiry';

// Check whether inquiry contains any mismatches
export const hasMismatches = (inquiry: ImmutableInquiry) => !inquiry.get('mismatches', Map()).isEmpty();

// Check whether inquiry contains any instabook listings
export const hasInstabookListings = (inquiry: ImmutableInquiry) => !inquiry.get('instabook_listings', List()).isEmpty();

export const hasBroadcastBoats = (inquiry: ImmutableInquiry) => !inquiry.get('broadcast_boats', List()).isEmpty();

/**
 * @param values - The object or array to remove empty string values from
 * @return returns the object or array with empty string fields removed
 */
export function removeEmptyFields(values: string[]): string[];
export function removeEmptyFields<T extends Record<string, string>>(values: T): Partial<T>;
export function removeEmptyFields(values: string[] | Record<string, string>) {
  if (Array.isArray(values)) {
    return values.filter(value => /\S/.test(value));
  }

  return Object.keys(values)
    .reduce((filtered, key) => {
      if (/\S/.test(values[key])) {
        // eslint-disable-next-line no-param-reassign
        filtered[key] = values[key];
      }
      return filtered;
    }, {} as Record<string, string>);
}

/**
 * This is used to ensure that empty values from a form are never set to null
 * See https://github.com/final-form/react-final-form/issues/130
 * @param value - the value received from form field
 * @return unchanged value
 */
export const parse = (value: string): string => value;
