import React, {
  FC, Fragment, ReactNode, useCallback, useMemo, useState,
} from 'react';
import classNames from 'classnames';
import { getClassNameFor } from 'src/common/helpers';
import styles from './NestedMenu.module.scss';
import Icon, { IconIds } from '../IconDS22';
import UniversalLink from '../UniversalLink';

export type WithLabel = {
  key: string;
  label: string;
  to?: string;
  icon?: IconIds;
  openInNewTab?: boolean;
  children?: NestedMenuItem[];
  sectionDivider?: boolean;
};

export type WithComponent = {
  id: string;
  component?: ReactNode;
  sectionDivider?: boolean;
};

export type NestedMenuItem = WithLabel | WithComponent;

export type NestedMenuProps = {
  items: NestedMenuItem[];
  variant?: 'dropdown' | 'sidebar';
};

const traverseItems = (
  key: string,
  items: NestedMenuItem[],
  callback: (item: WithLabel, parent: WithLabel) => void,
  parent?: WithLabel,
) => {
  items.forEach((item) => {
    if ('key' in item) {
      callback(item, parent || item);
      if (item.children) {
        traverseItems(key, item.children, callback, item);
      }
    }
  });
};

const NestedMenu: FC<NestedMenuProps> = ({
  items,
  variant,
}) => {
  const [activeItem, setActiveItem] = useState<WithLabel>(items[0] as WithLabel);
  const handleClick = useCallback((item: WithLabel) => {
    setActiveItem(item);
  }, []);
  const onBackClick = useCallback(() => {
    traverseItems(activeItem.key, items, (item, parent) => {
      if (item.key === activeItem.key) {
        setActiveItem(parent);
      }
    });
  }, [items, activeItem.key]);
  const activeItemChildren = useMemo(() => activeItem?.children || [], [activeItem]);
  const rootKey = (items[0] as WithLabel).key;
  const atRoot = useMemo(() => activeItem.key === rootKey, [activeItem, rootKey]);
  const title = activeItem.label;

  return (
    <div className={getClassNameFor(styles, 'root', variant)}>
      {!atRoot && (
        <button type="button" className={styles.backTitle} onClick={onBackClick}>
          <Icon id="arrow-left" />
          {title}
        </button>
      )}
      <ul className={styles.list}>
        {activeItemChildren.map((item) => (
          <Fragment key={'key' in item ? item.key : item.id}>
            {'key' in item && item.children && (
              <li className={getClassNameFor(styles, 'item', classNames({ sectionDivider: item.sectionDivider }))}>
                <button type="button" onClick={() => handleClick(item)}>
                  {item.icon && <Icon id={item.icon} />}
                  {item.label}
                  <Icon id="caret-right" size="s" />
                </button>
              </li>
            )}
            {'key' in item && item.to && (
              <li className={getClassNameFor(styles, 'item', classNames({ sectionDivider: item.sectionDivider }))}>
                <UniversalLink
                  to={item.to}
                  openInNewTab={item.openInNewTab}
                >
                  {item.icon && <Icon id={item.icon} />}
                  {item.label}
                </UniversalLink>
              </li>
            )}
            {'component' in item && (
              <li className={getClassNameFor(styles, 'item', classNames({ sectionDivider: item?.sectionDivider }))}>
                <div className={styles.componentWrapper}>
                  {item.component}
                </div>
              </li>
            )}
          </Fragment>
        ))}
      </ul>
    </div>
  );
};

export default NestedMenu;
