import { useDispatch } from 'react-redux';
import type { AnyAction } from 'redux';
import type { ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import type { ReduxState } from 'src/types/reduxState';

/**
 * Type for Redux dispatch function that handles both standard actions and thunks
 */
export type AppDispatch = ThunkDispatch<ReduxState, ThunkMiddleware, AnyAction>;
/**

/**
 * Custom hook that returns typed dispatch function
 * @returns Typed dispatch function that handles both actions and thunks
 */
const useAppDispatch = () => useDispatch<AppDispatch>();

export default useAppDispatch;
