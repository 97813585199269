import React, { FC } from 'react';
import Icon from 'src/common/components/IconDS22';
import { ImmutableTrip } from 'src/types/trips/Trips';
import Card from 'src/inbox/components/presentation/Card';
import s from './DirectBookingCard.module.scss';

type DirectBookingProps = {
  trip: ImmutableTrip;
};

const DirectBooking: FC<DirectBookingProps> = ({ trip }) => {
  const renterFirstName = trip.getIn(['renter', 'first_name']);
  const renterEmail = trip.getIn(['renter', 'email']);

  return (
    <div className={s.root}>
      <h2 className={s.title}>Direct Booking</h2>
      <Card>
        <div className={s.card}>
          <div className={s.user}>
            <Icon className={s.icon} id="person" size="l" />
            <p>{renterFirstName}</p>
          </div>
          <p>{renterEmail}</p>
        </div>
      </Card>
    </div>
  );
};

export default DirectBooking;
