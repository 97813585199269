import React, { FC, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import classNames from 'classnames';
import NotificationMessage from 'src/common/components/NotificationMessage';
import { getClassNameFor } from '../../../common/helpers';
import { BackLink } from '../../../common/components/BackButton';
import Button, { ButtonProps } from '../../../common/components/Button';
import s from './CTA.module.scss';

type CTALinkProps = {
  children: ReactNode;
  classNameModifier?: string;
  disabled?: boolean;
  'data-test'?: string;
} & LinkProps;

export const CTALink: FC<CTALinkProps> = (
  ({ children, classNameModifier, disabled, ...restProps }: CTALinkProps) => (
    <Link
      className={getClassNameFor(s, 'button', classNames(classNameModifier, { disabled }))}
      {...restProps}
    >
      {children}
    </Link>
  )
);

type CTABackLinkProps = Parameters<typeof BackLink>[0];
export const CTABackLink: FC<CTABackLinkProps> = (props) => (
  <BackLink
    classNameModifier="inbox"
    {...props}
  />
);
type CTAExternalLinkProps = {
  children: ReactNode;
  classNameModifier?: string;
};
export const CTAExternalLink: FC<CTAExternalLinkProps> = (
  ({ children, classNameModifier, ...restProps }: CTAExternalLinkProps) => (
    <a
      className={getClassNameFor(s, 'button', classNameModifier)}
      {...restProps}
    >
      {children}
    </a>
  )
);

export const CTAButton: FC<ButtonProps> = ({ classNameModifier = '', ...restProps }) => (
  <Button
    classNameModifier={`inbox CTA ${classNameModifier}`}
    fullWidth
    {...restProps}
  />
);

type CTAErrorProps = {
  children: ReactNode;
};
export const CTAError: FC<CTAErrorProps> = (
  ({ children }: CTAErrorProps) => (
    <div className={s.notificationWrapper}>
      <NotificationMessage
        classNameModifier="centered ctaValidationError"
        outdent={false}
      >
        {children}
      </NotificationMessage>
    </div>
  )
);

type CTAWrapperProps = {
  children: ReactNode;
};
export const CTAWrapper: FC<CTAWrapperProps> = (
  ({ children }: CTAWrapperProps) => (
    <div className={s.ctaContentWrapper}>
      {children}
    </div>
  )
);
