import React, { FC } from 'react';

import { getClassNameFor } from '../../helpers';
import Svg from '../Svg';
import s from './BoatPlaceholderImage.module.scss';

const BoatPlaceholderImage: FC<Props> = ({ classNameModifier = '' }) => (
  <div className={getClassNameFor(s, 'root', classNameModifier)}>
    {classNameModifier.includes('avatar') && (
      <Svg
        href="#boat-icon"
        className={s.image}
      />
    )}
  </div>
);

type Props = {
  classNameModifier?: string;
};

export default BoatPlaceholderImage;
