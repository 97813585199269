/* eslint-disable import/prefer-default-export */
export const fullName = user => user && `${user.get?.('first_name') ?? user.first_name ?? ''} ${user.get?.('last_name') ?? user.last_name ?? ''}`;
export const nameWithInitial = user => {
  let name = '';
  if (user) {
    const firstName = user.get?.('first_name') ?? user.first_name ?? '';
    const lastName = user.get?.('last_name') ?? user.last_name ?? '';
    const lastInitial = lastName ? ` ${lastName[0]}` : '';
    name += firstName;
    name += lastInitial;
  }
  return name;
};
