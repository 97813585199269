import React, { FC, useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, AnyObject } from 'react-final-form';
import moment from 'moment';
import differenceInDays from 'date-fns/differenceInDays';
import classNames from 'classnames';

import { AdHocEventFormState, AdhocCalendarEvent } from 'src/calendar/types';
import { FullBoatDetail } from 'src/types/boat/BoatDetail';
import { toast } from 'react-toastify';

import Button from 'src/common/components/Button';
import { trackEvent } from 'src/common/tracking';
import {
  CALENDAR_DATE_FORMAT,
  GA_EVENT_ACTION_PREFIX,
  GA_EVENT_OPTIONS,
  UNAVAILABLE as UNAVAILABLE_EVENTS,
  UNAVAILABLE_EDITOR_END_DATE as END_DATE,
  UNAVAILABLE_EDITOR_LISTING_IDS as LISTING_IDS,
  UNAVAILABLE_EDITOR_REPEAT_END_DATE as REPEAT_END_DATE,
  UNAVAILABLE_EDITOR_REPEAT_FREQUENCY as REPEAT_FREQUENCY,
  UNAVAILABLE_EDITOR_SELECT_ALL_LISTINGS as SELECT_ALL_LISTINGS,
  UNAVAILABLE_EDITOR_START_DATE as START_DATE,
  UNAVAILABLE_EDITOR_START_TIME as START_TIME,
  UNAVAILABLE_EDITOR_END_TIME as END_TIME,
  UNAVAILABLE_EDITOR_ALL_DAY as ALL_DAY,
} from '../../constants';
import { open, close } from '../../../common/ducks/zippy';
import { newTZDate } from '../../helpers';
import Modal from '../../../common/components/Modal';
import FormError from '../../../common/components/FormError';
import FormButtons from '../../../common/components/FormButtons';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import Notification from '../../../common/components/Notification';
import EventDetailsContent from '../EventDetailsContent';
import EventDetailsHeader from '../EventDetailsHeader';
import EventDetailsFooter from '../EventDetailsFooter';
import { useBoats } from '../../../common/hooks';
import useCalendarFocus from '../../hooks/useCalendarFocus';
import {
  useCalendarContext,
  useListingsFilterContext,
  useUnavailableEditorContext,
} from '../../hooks';
import Icon from '../../../common/components/IconDS22';

import s from './UnavailableEditor.module.scss';
import UnavailableTimeFields from '../UnavailableTimeFields';
import UnavailableEditorSection from '../UnavailableEditorSection';
import UnavailableListingList from '../UnavailableListingList';
import UnavailableDateFields from '../UnavailableDateFields';
import UnavailableRepeatField from '../UnavailableRepeatField';
import formDecorators from './decorators';

export const prepareAdHocEventDataForAPI = (values: AdHocEventFormState): AdhocCalendarEvent => ({
  id: values.id,
  all_day: values.all_day,
  type: 'unavailable',
  event_date: values.event_date,
  event_end_date: values.event_end_date,
  frequency: (values.frequency && values.repeat_count > 1)
    ? values.frequency : null,
  repetitions_end_date: (values.frequency && values.repeat_count > 1)
    ? values.repetitions_end_date : null,
  boat_ids: values.boat_ids,
  event_start_time: !values.all_day && values.event_start_time
    ? values.event_start_time
    : undefined,
  event_end_time: !values.all_day && values.event_end_time ? values.event_end_time : undefined,
});

export const adHocEventInitialValues = (
  adHocEvent: AdhocCalendarEvent | null,
  boats: FullBoatDetail[],
  today: string,
  selectedDate: string,
  filteredListings: string[],
): AdHocEventFormState => {
  const useSelectedDate = (
    selectedDate && differenceInDays(newTZDate(selectedDate), newTZDate(today)) >= 0
  );
  const date = useSelectedDate ? selectedDate : today;

  const initialListingIds = () => {
    if (filteredListings.length > 0) {
      // If any filters are set, find the primary id of the matching hashed id
      // Filtered listings use hash id, but we want to pass the primary id to form
      const result: number[] = [];
      filteredListings.forEach(listing => {
        const match = boats.find(boat => boat.id === listing);
        if (match?.boat_id) {
          result.push(match.boat_id);
        }
      });
      return result;
    }
    if (boats.length === 1) {
      return boats.map(b => b.boat_id);
    }
    return adHocEvent?.[LISTING_IDS] ?? boats.map(b => b.boat_id);
  };
  return {
    id: adHocEvent?.id,
    [START_DATE]: adHocEvent?.[START_DATE] ?? date,
    event_end_date: adHocEvent?.[END_DATE] ?? date,
    frequency: adHocEvent?.calendar_events && adHocEvent?.calendar_events?.length > 1
      ? adHocEvent?.[REPEAT_FREQUENCY]
      : undefined,
    repetitions_end_date: adHocEvent?.[REPEAT_END_DATE] ?? date,
    repeat_count: adHocEvent?.calendar_events?.length ?? 1,
    [SELECT_ALL_LISTINGS]: adHocEvent?.[LISTING_IDS]?.length === boats.length,
    [LISTING_IDS]: initialListingIds(),
    [START_TIME]: adHocEvent?.event_start_time ?? '',
    [END_TIME]: adHocEvent?.event_end_time ?? '',
    [ALL_DAY]: adHocEvent?.[ALL_DAY] as boolean ?? true,
  };
};

const validate = (values: AdHocEventFormState): AnyObject => {
  const errors: AnyObject = {};
  const boatIds = values.boat_ids.length;
  if (!boatIds) {
    errors.formError = 'Select a listing to mark as unavailable.';
    if (!values.all_day && (!values.event_start_time || !values.event_end_time)) {
      errors.formError = 'Please select the time and listing to proceed.';
    }
  } else if (!values.all_day) {
    if (!values.event_start_time) {
      errors.event_start_time = 'Select time';
      errors.formError = 'Please select the time you want to block.';
    } else if (!values.event_end_time) {
      errors.event_end_time = 'Select time';
      errors.formError = 'Please select the time you want to block.';
    }
    if (
      values.event_start_time
      && values.event_end_time
      && values.event_start_time >= values.event_end_time) {
      errors.formError = 'End time should be after start time';
    }
  }

  return errors;
};

const today = moment().format(CALENDAR_DATE_FORMAT);

type Props = {
  modalName: string;
  onOpenModal?: () => void;
};

const UnavailableEditor:FC<Props> = ({
  modalName,
  onOpenModal = () => {},
}) => {
  const dispatch = useDispatch();
  const openModal = useCallback(() => dispatch(open(modalName)), [dispatch, modalName]);
  const closeModal = useCallback(() => dispatch(close(modalName)), [dispatch, modalName]);
  const { boats, boatsIndexedById } = useBoats();
  const formRef = useRef<HTMLFormElement | null>(null);
  const {
    replaceAdHocEvents,
    statusFilters,
    changeStatusFilter,
  } = useCalendarContext();
  const {
    activeFilters: filteredListings,
    addListingFilters,
  } = useListingsFilterContext();
  const {
    adHocEvent,
    isLoading,
    isSaving,
    error,
    saveAdHocEvent,
    deleteAdHocEvent,
    resetAdHocEvent,
  } = useUnavailableEditorContext();
  const { selectedDate } = useCalendarFocus();

  const initialValues = useMemo(
    () => adHocEventInitialValues(adHocEvent, boats, today, selectedDate, filteredListings),
    [adHocEvent, boats, selectedDate, filteredListings],
  );

  const showNotification = () => {
    toast.dismiss();
    toast(
      <Notification heading="Blocked time deleted successfully." />,
      { autoClose: 5000, className: s.notification },
    );
  };

  const modalLabel = 'Block Time';
  const editorDecorators = formDecorators;

  return (
    <div className={s.root}>
      <Button
        type="button"
        onClick={() => {
          resetAdHocEvent();
          openModal();
          onOpenModal();
          trackEvent(`${GA_EVENT_ACTION_PREFIX.UNAVAILABLE} Open`, {
            ...GA_EVENT_OPTIONS,
            event_label: 'New',
          });
        }}
        classNameModifier="noMargin"
        fullWidth
      >
        <Icon id="clock" size="m" />
        {modalLabel}
      </Button>

      <Modal
        classNameModifier={classNames(
          'calendar',
          'unavailableEditor',
          {
            alignVertically: isLoading,
            singleBoat: boats.length === 1,
          },
        )}
        label={modalLabel}
        name={modalName}
        disableOverlayClick
      >
        {isLoading ? (
          <LoadingSpinner>
            Loading Data
          </LoadingSpinner>
        ) : (
          <>
            <EventDetailsHeader
              classNameModifier="unavailableEditor"
              title={modalLabel}
              description={boats.length === 1
                ? (
                  <span className={s.boatDescription}>
                    Prevent booking inquiries to{' '}
                    <strong>{boats[0].shortname || boats[0].headline}</strong>
                    {' '}for this period.
                  </span>
                )
                : 'Prevent booking inquiries for this period.'}
            >
              {adHocEvent?.id && (
                <button
                  type="button"
                  className={s.deleteButton}
                  onClick={() => {
                    if (adHocEvent.id) {
                      deleteAdHocEvent(
                        adHocEvent.id,
                        adHocEventId => {
                          closeModal();
                          showNotification();
                          replaceAdHocEvents(adHocEventId);
                          trackEvent(`${GA_EVENT_ACTION_PREFIX.UNAVAILABLE} Delete`, {
                            ...GA_EVENT_OPTIONS,
                          });
                        },
                      );
                    }
                  }}
                >
                  Delete
                </button>
              )}
            </EventDetailsHeader>

            <Form<AdHocEventFormState>
              keepDirtyOnReinitialize
              initialValues={initialValues}
              onSubmit={values => {
                saveAdHocEvent(
                  prepareAdHocEventDataForAPI(values),
                  (adHocEventId, updatedCalendarEvents) => {
                    closeModal();
                    replaceAdHocEvents(adHocEventId, updatedCalendarEvents);
                  },
                );
                trackEvent(
                  `${GA_EVENT_ACTION_PREFIX.UNAVAILABLE} Save`,
                  {
                    ...GA_EVENT_OPTIONS,
                    event_label: adHocEvent?.id ? 'Existing' : 'New',
                  },
                );

                if (statusFilters.length > 0 && !statusFilters.includes(UNAVAILABLE_EVENTS)) {
                  changeStatusFilter('add', UNAVAILABLE_EVENTS);
                }

                if (filteredListings.length > 0) {
                  const listingIdsToAdd = values[LISTING_IDS]
                    // Map numeric listing IDs to hashed IDs
                    // because listings filter uses hashed IDs
                    .map(listingId => boatsIndexedById[listingId].id)
                    .filter(listingId => !filteredListings.includes(listingId));
                  addListingFilters(listingIdsToAdd);
                }
              }}
              decorators={editorDecorators}
              validate={validate}
            >
              {({ handleSubmit, errors, submitFailed }) => {
                const formError = error || (submitFailed && errors ? errors.formError : '');

                return (
                  <form
                    noValidate
                    className={s.form}
                    onSubmit={handleSubmit}
                    ref={formRef}
                  >
                    <EventDetailsContent classNameModifier="unavailableEditor">
                      <UnavailableEditorSection
                        title="Blocked Time"
                        description="Blocked period for each selected date."
                        classNameModifier="time"
                      >
                        <UnavailableTimeFields />
                      </UnavailableEditorSection>

                      <UnavailableEditorSection title="Dates">
                        <UnavailableDateFields />
                      </UnavailableEditorSection>

                      <UnavailableEditorSection
                        title="Repeats"
                        classNameModifier="repeats"
                      >
                        <UnavailableRepeatField formRef={formRef} />
                      </UnavailableEditorSection>

                      {boats.length > 1 && (
                        <UnavailableEditorSection
                          title="Listings"
                          description="Select listings to set unavailable periods."
                        >
                          <UnavailableListingList listings={boats} />
                        </UnavailableEditorSection>
                      )}
                    </EventDetailsContent>

                    <FormError
                      error={formError && (
                        <div className={s.formError}>
                          <Icon id="info" />
                          <span>{formError}</span>
                        </div>
                      )}
                      className={s.error}
                    />
                    <EventDetailsFooter>

                      <FormButtons
                        classNameModifier="unavailableEditor"
                        submitting={isSaving}
                        closeModal={closeModal}
                      />
                    </EventDetailsFooter>

                  </form>
                );
              }}
            </Form>
          </>
        )}
      </Modal>
    </div>
  );
};

export default UnavailableEditor;
