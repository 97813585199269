import React from 'react';
import { Redirect as ReactRouterRedirect, Route, RouteComponentProps } from 'react-router-dom';
import { LocationDescriptor } from 'history';

type RedirectProps = {
  from?: string;
  statusCode?: number;
  to: LocationDescriptor<unknown>;
  exact?: boolean;
  strict?: boolean;
  push?: boolean;
};

const Redirect: React.FC<RedirectProps> = ({ from, statusCode, ...restProps }) => (
  <Route
    path={from}
    render={({ staticContext }: RouteComponentProps) => {
      if (staticContext) {
        // Set the statusCode so that it can be used for the redirect.
        // eslint-disable-next-line no-param-reassign
        staticContext.statusCode = statusCode;
      }
      return (
        <ReactRouterRedirect {...restProps} />
      );
    }}
  />
);

export default Redirect;
