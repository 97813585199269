import React, { FC } from 'react';

import { getClassNameFor } from '../../helpers';
import s from './ProgressBar.module.scss';

type ProgressBarProps = {
  progress: number;
  classNameModifier?: string;
};

const ProgressBar: FC<ProgressBarProps> = ({ progress, classNameModifier }) => {
  const style = {
    width: `${progress}%`,
  };

  return (
    <div className={getClassNameFor(s, 'root', classNameModifier)}>
      <div className={s.progress} style={style} />
    </div>
  );
};

export default ProgressBar;
