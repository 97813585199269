import React, { FC, MouseEvent } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../helpers';
import { validateRedirect } from '../../utils/routing';

import s from './CloseButton.module.scss';
import Icon from '../IconDS22';

/**
 * Requires `backUrl` or `onClick`.
 * Requires `className` or `classNameModifier`.
 */
type CloseButtonProps = {
  positionAbsolute?: boolean;
} & (
  | {
    backUrl: string;
    className?: never;
    classNameModifier?: string;
    onClick?: never;
  }
  | {
    backUrl?: never;
    className: string;
    classNameModifier?: never;
    onClick: (() => void) | ((e: MouseEvent<HTMLButtonElement>) => void);
  }
  | {
    backUrl: string;
    className: string;
    classNameModifier?: never;
    onClick?: never;
  }
  | {
    backUrl?: never;
    className?: never;
    classNameModifier?: string;
    onClick: (() => void) | ((e: MouseEvent<HTMLButtonElement>) => void);
  });

const CloseButton: FC<CloseButtonProps> = ({
  backUrl,
  className,
  classNameModifier,
  onClick,
  positionAbsolute = true,
}) => (
  <div className={className || getClassNameFor(s, 'root', classNames(classNameModifier, { positionAbsolute }))}>
    {backUrl && (
      <a
        href={validateRedirect(backUrl)}
        aria-label="Close"
        className={getClassNameFor(s, 'link', classNameModifier)}
      >
        <Icon id="x" size="l" />
      </a>
    )}
    {onClick && (
      <button
        type="button"
        onClick={onClick}
        aria-label="Close"
        className={getClassNameFor(s, 'button', classNameModifier)}
      >
        <Icon id="x" size="l" />
      </button>
    )}
  </div>
);

export default CloseButton;
