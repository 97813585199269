import React, { FC } from 'react';
import classNames from 'classnames';

import { useRouteMatch } from 'react-router-dom';
import { formatDate } from 'src/common/utils/dateHelpers';
import { getClassNameFor } from '../../../common/helpers';
import ScrollToMe from '../../../common/components/ScrollToMe';
import {
  CALENDAR_DATE_FORMAT_DATE_FNS,
  CALENDAR_DAY_NAME,
  CALENDAR_DAY_NUMBER,
} from '../../constants';
import { PATHS } from '../../../common/constants';
import { todayDate } from '../../helpers';
import { useCalendarContext } from '../../hooks';
import useCalendarFocus from '../../hooks/useCalendarFocus';

import TodayDivider from '../TodayDivider';
import s from './DayCard.module.scss';

export type DayCardProps = {
  day: Date;
  hasEvents: boolean;
};

/**
 * Provides a container for the day's events
 *
 * - Has support for scrolling to the selected date
 * - Has support for highlighting the card when it is the selected date
 * - Has support for sticky date positioning
 */
const DayCard: FC<DayCardProps> = ({
  day,
  hasEvents,
  children,
}) => {
  const dayName = formatDate(day, CALENDAR_DAY_NAME, {});
  const dayNumber = formatDate(day, CALENDAR_DAY_NUMBER);
  const dateTimeAttr = formatDate(day, CALENDAR_DATE_FORMAT_DATE_FNS);
  const {
    selectedDate,
    renderKey,
    focusedThreadId,
    focusedAdHocEventId,
    focusedInstabookId,
  } = useCalendarFocus();
  const { instabookEnabled } = useCalendarContext();

  const today = todayDate();
  const isSelectedDate = selectedDate === dateTimeAttr;
  const isPast = day < today;
  const isToday = day.getTime() === todayDate().getTime();
  const isInstabook = useRouteMatch(PATHS.INSTABOOK);

  return (
    <>
      {isSelectedDate && !focusedThreadId && !focusedAdHocEventId && !focusedInstabookId && (
        <ScrollToMe
          key={`scroll-to-day-${dateTimeAttr}-${renderKey ? 1 : 0}`}
          classNameModifier={`calendarDayAnchor${isToday ? 'Today' : ''}`}
        />
      )}
      {isToday && (
        <TodayDivider classNameModifier={classNames({ instabook: isInstabook })} />
      )}
      {hasEvents && (
        <div className={getClassNameFor(s, 'root', classNames({ past: isPast }))}>
          <div className={getClassNameFor(s, 'card', classNames({ instabook: isInstabook }))}>
            <dt
              className={getClassNameFor(
                s,
                'date',
                classNames({
                  withInstabook: instabookEnabled,
                  selectedDate: isSelectedDate,
                  isFuture: !isPast,
                  instabook: isInstabook,
                }),
              )}
            >
              <time
                dateTime={dateTimeAttr}
                data-test="DateIndicator"
              >
                <span className={s.dayName}>{dayName}</span>
                <span className={s.dayNumber}>{dayNumber}</span>
              </time>
            </dt>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default DayCard;
