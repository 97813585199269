import React from 'react';

import s from './EditableTripDetails.module.scss';

type EditableTripDetailsProps = {
  children: React.ReactNode;
};

const EditableTripDetails: React.FC<EditableTripDetailsProps> = ({
  children,
}) => (
  <div className={s.root}>
    {children}
  </div>
);

export default EditableTripDetails;
