import React, { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { UserAgent } from '@quentin-sommer/react-useragent';

import { LocationDescriptor } from 'history';
import { BackLink } from '../../../../common/components/BackButton';
import inboxBackground from '../../../../img/inbox-background.svg';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import type { ReduxState } from '../../../../types/reduxState';
import s from './MessagePanel.module.scss';

type MessagePanelProps = {
  cta?: ReactNode;
  children: ReactNode;
  backLocation?: LocationDescriptor;
  loading?: boolean;
  error?: string;
};

const mapStateToProps = (
  { loading }: ReduxState,
) => ({
  loading: loading.get('status'),
});

const MessagePanel: FC<MessagePanelProps> = ({
  cta,
  children,
  backLocation,
  loading,
  error,
}) => (
  <div
    className={s.root}
    style={{ backgroundImage: `url(${inboxBackground})` }}
  >
    {backLocation && (
      <BackLink
        to={backLocation}
        classNameModifier="mobile-aib"
      />
    )}
    <div className={s.content}>
      {!loading ? (
        !error && children
      ) : (
        <UserAgent
          mobile
        >
          <LoadingSpinner />
        </UserAgent>
      )}
    </div>
    {cta}
  </div>
);

const connectedComponent = connect(mapStateToProps)(MessagePanel);

export default connectedComponent;
