import React, { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';

import useGrecaptcha from 'src/auth/hooks/useGrecaptcha';
import Button from 'src/common/components/Button';
import EmailField from 'src/common/components/fields/EmailField';
import FormError from 'src/common/components/FormError';
import { PATHS } from 'src/common/constants';
import { combineValidators, rffValidators } from 'src/common/validation';
import apiFetch from 'src/core/fetch';
import s from './ForgotPasswordSend.module.scss';

const validate = combineValidators([
  rffValidators.required({ message: 'An email is required.' }),
  rffValidators.email({ message: "That doesn't look like a valid email." }),
]);

const title = 'Forgot your Password?';

const resetPasswordCall = async (values: Record<string, string>) => {
  try {
    await apiFetch('/auth/password/reset/', {
      method: 'POST',
      body: JSON.stringify(values),
    });
  } catch (err) {
    // Ignore backend response and go to the next page
  }
};

const ForgotPassword: FC = () => {
  const { push } = useHistory();

  const resetPassword = useCallback(
    (values: Record<string, string>) => resetPasswordCall(values).then(
      () => push(PATHS.PASSWORD_FORGOT_SUBMITTED),
    ),
    [push],
  );

  const onSubmit = useGrecaptcha('password_reset', resetPassword);

  return (
    <div className={s.container}>
      <Helmet>
        <title lang="en">{title}</title>
      </Helmet>

      <div className={s.intro}>
        <h1 className={s.title}>{title}</h1>
        <div className={s.message}>
          <p>{"Not a problem! We'll email you a reset link."}</p>
        </div>
      </div>

      <Form
        onSubmit={onSubmit}
        validateOnBlur
      >
        {({ handleSubmit, submitting, submitError }) => (
          <form
            method="POST"
            onSubmit={handleSubmit}
            className={s.form}
          >
            <FormError error={submitError} />
            <EmailField
              name="email"
              autoFocus
              className={s.input}
              validate={validate}
            />

            <div className={s.actionGroup}>
              <Button
                type="submit"
                submitting={submitting}
                fullWidth
              >
                Send Email
              </Button>
              <div className={s.account}>
                {"Don't have an account? "}
                <Link
                  to={PATHS.REGISTER}
                  className={s.link}
                >
                  Create Account.
                </Link>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default ForgotPassword;
