import { FC, useEffect } from 'react';

const toggleBodyOverflow = (isDisabled: boolean): void => {
  if (!document) return;
  const { body } = document;
  body.classList.toggle('disableScroll', isDisabled);
};

/**
 * Keeps track of how many times the scroll lock is rendered so that
 * once the count reaches 0, the scroll lock can be removed.
 */
let globalScrollLockCount = 0;

/**
 * Disables scrolling the body while this component is rendered.
 */
const ScrollLock: FC = () => {
  useEffect(() => {
    globalScrollLockCount += 1;
    toggleBodyOverflow(true);
    return () => {
      globalScrollLockCount -= 1;
      if (globalScrollLockCount === 0) {
        toggleBodyOverflow(false);
      }
    };
  }, []);

  return null;
};

export default ScrollLock;
