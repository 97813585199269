import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { Map } from 'immutable';

import { useTripDetails } from 'src/inbox/hooks';
import { ImmutableTrip } from '../../../types/trips/Trips';
import { MOMENT_FORMAT } from '../../../common/constants';
import { TRIP_STATES, WITH_CAPTAIN_LABEL } from '../../constants';
import {
  getClassNameFor,
} from '../../../common/helpers';
import { guestsString } from '../../helpers';
import getCaptainedCopy from '../../../common/utils/getCaptainedCopy';
import { getOffer } from '../../../common/utils/reduxStoreSelectors';
import { captainedGetter } from '../cards/Captain/selector';
import { dateGetter } from '../cards/Dates/selector';
import { guestsGetter, sumGuests } from '../cards/GroupSize/selector';
import NotificationMessage from '../../../common/components/NotificationMessage';
import Svg from '../../../common/components/Svg';
import OnlineStatus from '../OnlineStatus';

import s from './TripCardDetails.module.scss';
import useCaptainData from '../../../common/hooks/useCaptainData';

type Props = {
  trip: ImmutableTrip;
  name: string;
  disabled: boolean;
  disabledString: string;
  classNameModifier?: string;
  recipientIsOnline: boolean;
  isOwner: boolean;
};

const TripCardDetails: FC<Props> = ({
  children,
  disabled = false,
  disabledString,
  name,
  trip,
  classNameModifier,
  recipientIsOnline,
  isOwner,
}) => {
  const offer = useSelector(getOffer);
  const captained = captainedGetter({ trip, offer });
  const departMoment = moment(dateGetter({ trip, offer }));

  const { tripLengthString } = useTripDetails(trip);
  const dateTimeString = `${departMoment.format(MOMENT_FORMAT.DATE)} • ${tripLengthString}`;
  const hasCalendarConflicts = trip.get('has_calendar_conflicts');
  const tripState = trip.get('state', Map()).toJS().state;
  const isOfferCancelled = (
    tripState === TRIP_STATES.OWNER_OFFER_CANCELLED
    || tripState === TRIP_STATES.OWNER_OFFER_AUTO_CANCELLED
  );
  const isOfferExpired = (
    tripState === TRIP_STATES.OWNER_OFFER_EXPIRED
    || tripState === TRIP_STATES.OWNER_CUSTOM_OFFER_EXPIRED
  );

  const { shortHeading: instabookCaptainedHeading } = getCaptainedCopy(
    captained,
    trip.get('captain_cost_included'),
  );

  const { captainOptionHeading } = useCaptainData(trip);

  const captainedLabel = trip.get('is_instabook')
    ? instabookCaptainedHeading
    : captainOptionHeading.shortLabel;

  const ownerActionRequired = isOwner && captainedLabel === WITH_CAPTAIN_LABEL
    && !captainOptionHeading.key;

  return (
    <div
      data-test="TripCardDetails"
      className={getClassNameFor(s, 'root', `${disabled && 'disabled'} ${classNameModifier}`)}
    >
      {disabled ? (
        <NotificationMessage
          classNameModifier="alignTop gray"
          outdent={false}
        >
          <ul className={s.info}>
            <li className={s.disabledTitle}>{disabledString}</li>
            <li>{name}</li>
            <li>{dateTimeString}</li>
          </ul>
        </NotificationMessage>
      ) : (
        <>
          <div className={s.titleWrap}>
            {name && (
              <h4 className={getClassNameFor(s, 'name', classNameModifier)}>
                {name}
                {__DEV__ && <OnlineStatus>{recipientIsOnline ? 'online' : 'offline'}</OnlineStatus>}
              </h4>
            )}
            {hasCalendarConflicts && !isOfferCancelled && !isOfferExpired && (
              <Svg
                href="#exclamation-icon"
                className={s.icon}
              />
            )}
          </div>
          <time
            dateTime={departMoment.format(MOMENT_FORMAT.VALUE)}
            className={getClassNameFor(s, 'dateTime', classNameModifier)}
          >
            {dateTimeString}
          </time>
          <div className={s.wrapper}>
            <ul className={s.info}>
              <li>{guestsString(sumGuests(guestsGetter({ trip, offer })))}</li>
              {captained !== null && (
                <li
                  className={getClassNameFor(
                    s,
                    'captainStatus',
                    classNames({ ownerActionRequired }),
                  )}
                >
                  {captainedLabel}
                </li>
              )}
            </ul>
            {children}
          </div>
        </>
      )}
    </div>
  );
};

export default TripCardDetails;
