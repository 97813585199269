import React, { FunctionComponent, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import { format } from 'date-fns';
import { EXTERNAL_PATHS } from '../../../common/constants';
import { getCanonicalForm } from '../../../common/currencies';
import { getCurrencies } from '../../../common/utils/reduxStoreSelectors';
import {
  INSTABOOK_EDITOR_DATES_FIELD,
  INSTABOOK_EDITOR_TRIP_DURATION_FIELD,
  INSTABOOK_FLEXIBLE_DEPARTURE_TIME_START,
  INSTABOOK_FLEXIBLE_DEPARTURE_TIME_END,
  INSTABOOK_FIXED_DEPARTURE_TIMES,
  INSTABOOK_DEPARTURE_TIME_FLEXIBLE,
  INSTABOOK_PUBLISHED_FIELD,
  INSTABOOK_STATES,
  INSTABOOK_WARNINGS,
  CALENDAR_DATE_FORMAT_DATE_FNS,
} from '../../constants';
import { useInstabookEditorContext, useInstabookWarning } from '../../hooks';
import { getTimeToBook } from '../../instabook';

import NotificationMessage from '../../../common/components/NotificationMessage';
import InstabookFormButtons from '../InstabookFormButtons';
import InstabookEditorHeader from '../InstabookEditorHeader';
import InstabookHeaderInfo from '../InstabookHeaderInfo';
import InstabookTripDetails from '../InstabookTripDetails';
import InstabookListingSelector from '../InstabookListingSelector';
import InstabookDepartureTimes from '../InstabookDepartureTimes';
import InstabookAvailabilityDates from '../InstabookAvailabilityDates';
import InstabookTripDuration from '../InstabookTripDuration';
import InstabookCaptainOption from '../InstabookCaptainOption';
import InstabookAdditionalInformation from '../InstabookAdditionalInformation';
import InstabookPrice from '../InstabookPrice';
import InstabookDraftPublish from '../InstabookDraftPublish';
import EventDetailsFooter from '../EventDetailsFooter';

import { InstabookEvent, InstabookStatus, InstabookWarning } from '../../types';

import s from './InstabookEditor.module.scss';

type Props = {
  captainCostEnabled?: boolean;
  values: any;
  openTimeEditor: () => void;
  closeModal: () => void;
  selectedInstabookDate?: Date | null;
};

const getStatus = (
  warning: InstabookWarning,
  instabook: InstabookEvent | null,
): InstabookStatus => {
  if (warning && warning !== INSTABOOK_WARNINGS.PARTIALLY_UNAVAILABLE_TIMES
    && instabook?.published) {
    return INSTABOOK_STATES.NOT_BOOKABLE;
  }
  if (!instabook) {
    return undefined;
  }
  return instabook?.published ? INSTABOOK_STATES.PUBLISHED : INSTABOOK_STATES.DRAFT;
};

const InstabookEditor: FunctionComponent<Props> = ({
  captainCostEnabled,
  values,
  openTimeEditor,
  closeModal,
  selectedInstabookDate,
}) => {
  const currencies = useSelector(getCurrencies);
  const {
    boat,
    instabook,
    instabookEditorBodyRef,
    instabookEditorContentRef,
  } = useInstabookEditorContext();

  const timeToBook = useMemo(
    () => (instabook?.date_UTC ? getTimeToBook(instabook?.date_UTC, instabook?.expiry_UTC) : 0),
    [instabook?.date_UTC, instabook?.expiry_UTC],
  );

  const dates = values[INSTABOOK_EDITOR_DATES_FIELD];

  const firstTripDate = dates[0];

  const formattedTripDate = format(
    selectedInstabookDate || firstTripDate,
    CALENDAR_DATE_FORMAT_DATE_FNS,
  );
  const warning = useInstabookWarning(
    formattedTripDate,
    boat?.id as string,
    timeToBook,
    instabook?.id,
  );

  const isMultiInstabook = instabook && instabook?.instabook_trip_dates?.length > 1;

  const { hasValidationErrors, submitFailed } = useFormState();

  const listingCurrency = useMemo(
    // Unpublished boat doesn't have the currency field, so we get it from the instabook
    () => getCanonicalForm(boat?.currency || instabook?.currency, currencies),
    [boat?.currency, currencies, instabook?.currency],
  );

  const showWarning = (!isMultiInstabook && warning
    && warning !== INSTABOOK_WARNINGS.PARTIALLY_UNAVAILABLE_TIMES)
    || (isMultiInstabook && warning && warning === INSTABOOK_WARNINGS.UNPUBLISHED);

  const copy = useMemo(
    () => ({
      [INSTABOOK_WARNINGS.UNPUBLISHED]: (
        <>
          Editing is disabled because the
          {' '}
          <a href={EXTERNAL_PATHS.ADMIN_LISTINGS}>listing is unpublished</a>.
        </>
      ),
      [INSTABOOK_WARNINGS.DEPARTURE]: `This trip is not bookable because the departure time is in less than ${timeToBook} hours.`,
      [INSTABOOK_WARNINGS.PAST]: 'This trip is not bookable because the date has passed.',
      [INSTABOOK_WARNINGS.UNAVAILABLE]:
        'This trip is not bookable because the date is unavailable in your calendar.',
      [INSTABOOK_WARNINGS.UNAVAILABLE_DAY]:
        'This trip is not bookable because the departure times are unavailable in your calendar.',
      [INSTABOOK_WARNINGS.UNAVAILABLE_TIMES]:
        'This trip is not bookable because the departure times are unavailable in your calendar.',
    }),
    [timeToBook],
  );

  const status = useMemo(
    () => getStatus(warning, instabook),
    [warning, instabook],
  );
  const boatIsActive = !!boat?.active;

  return (
    <div className={s.root}>
      <InstabookEditorHeader
        title="Instabook Trip"
        status={status}
      >
        <InstabookHeaderInfo />
      </InstabookEditorHeader>
      <div
        className={s.body}
        ref={instabookEditorBodyRef}
      >
        <div
          className={s.content}
          ref={instabookEditorContentRef}
        >
          {showWarning && (
            <NotificationMessage
              outdent={false}
              classNameModifier="gray instabook alignTop"
            >
              {copy[warning]}
            </NotificationMessage>
          )}
          <InstabookTripDetails withTitle>
            <InstabookListingSelector boat={boat} />
            <InstabookTripDuration disabled={!boatIsActive} />
            <InstabookDepartureTimes
              openTimeEditor={openTimeEditor}
              duration={values[INSTABOOK_EDITOR_TRIP_DURATION_FIELD]}
              flexible={values[INSTABOOK_DEPARTURE_TIME_FLEXIBLE]}
              flexibleTimes={[
                values[INSTABOOK_FLEXIBLE_DEPARTURE_TIME_START],
                values[INSTABOOK_FLEXIBLE_DEPARTURE_TIME_END],
              ]}
              fixedTimes={values[INSTABOOK_FIXED_DEPARTURE_TIMES] || []}
              disabled={!boatIsActive}
            />
            <InstabookCaptainOption
              boat={boat}
              disabled={!boatIsActive}
            />
            <InstabookAdditionalInformation disabled={!boatIsActive} />
          </InstabookTripDetails>

          <InstabookPrice
            captainCostEnabled={captainCostEnabled}
            currency={listingCurrency}
            boat={boat}
          />
          <InstabookAvailabilityDates
            boatId={boat?.id}
            disabled={!boatIsActive}
          />

          {boat && <InstabookDraftPublish disabled={!boatIsActive} />}
        </div>
      </div>
      <EventDetailsFooter>
        {submitFailed && hasValidationErrors && (
          <div className={s.formError}>
            {values[INSTABOOK_PUBLISHED_FIELD] ? (
              <>Please fill out all required fields to publish this trip, or save it as a draft.</>
            ) : (
              <>Please add captain selection to save this as a draft.</>
            )}
          </div>
        )}
        <InstabookFormButtons
          submitting={false}
          submitText={values[INSTABOOK_PUBLISHED_FIELD] ? 'Save & Publish' : 'Save Draft'}
          closeModal={closeModal}
          canSubmit={boatIsActive}
        />
      </EventDetailsFooter>
    </div>
  );
};

export default InstabookEditor;
