import React from 'react';

import NotificationMessage from '../../../../common/components/NotificationMessage';

const OfferModifiedWarning = () => (
  <NotificationMessage
    classNameModifier="alignTop largerFontSize largerBottomMargin notSendChanges"
    outdent={false}
  >
    You have made changes to this offer that have not been sent. <br />
    Please select {'"Create Offer"'} when you are ready to send your changes.
  </NotificationMessage>
);

export default OfferModifiedWarning;
