import { MutableRefObject, useCallback, useRef } from 'react';
import { CALENDAR_MONTH_LONG, CALENDAR_MONTH_SHORT, CALENDAR_YEAR_LONG } from '../constants';
import { formatDate, newTZDate } from '../../common/utils/dateHelpers';

const formatMonthTitleInnerHtml = (month: Date) => {
  const long = formatDate(month, `${CALENDAR_MONTH_LONG} ${CALENDAR_YEAR_LONG}`);
  const short = formatDate(month, `${CALENDAR_MONTH_SHORT} ${CALENDAR_YEAR_LONG}`);
  return `<span>${long}</span><span>${short}</span>`;
};

const useDropdownMonth = (activeMonthRef: MutableRefObject<string>) => {
  const dropdownMonthRef = useRef<HTMLElement>(null);

  const setDropdownMonth = useCallback((newMonth: string) => {
    if (activeMonthRef.current !== newMonth) {
      // eslint-disable-next-line no-param-reassign
      activeMonthRef.current = newMonth;
      if (dropdownMonthRef.current) {
        dropdownMonthRef.current.innerHTML = formatMonthTitleInnerHtml(newTZDate(newMonth));
      }
    }
  }, [activeMonthRef]);

  return {
    dropdownMonthRef,
    dropdownMonth: formatMonthTitleInnerHtml(newTZDate(activeMonthRef.current)),
    setDropdownMonth,
  };
};
export default useDropdownMonth;
