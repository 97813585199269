import React, { FC, ReactNode } from 'react';
import s from './DurationSnippet.module.scss';
import Icon from '../IconDS22';

type DurationSnippetProps = {
  children: ReactNode;
  isNight?: boolean;
};

const DurationSnippet: FC<DurationSnippetProps> = ({
  children,
  isNight = false,
}) => (
  <div className={s.root}>
    <div className={s.icon}>
      {isNight ? (
        <Icon id="moon" size="xl" />
      ) : (
        <Icon id="duration" size="xl" />
      )}
    </div>
    <div className={s.content}>
      {children}
    </div>
  </div>
);

export default DurationSnippet;
