import React, { FC } from 'react';

import { TripState } from '../../../types/trips/Trips';
import { getClassNameFor } from '../../helpers';
import s from './TripStateIndicatorInbox.module.scss';

type Props = {
  classNameModifier?:string;
  tripState: TripState;
  unread: boolean;
};

const TripStateIndicatorInbox: FC<Props> = ({
  classNameModifier = '',
  tripState,
  unread,
}) => {
  const modifiers = [
    classNameModifier,
    unread ? 'unread' : '',
    tripState?.state ?? '',
  ].join(' ');

  return (
    <li className={getClassNameFor(s, 'root', modifiers)}>
      {tripState?.badge ?? ''}
    </li>
  );
};

export default TripStateIndicatorInbox;
