import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { trackEvent } from 'src/common/tracking';
import { scrollPageToBottom } from '../../../../common/helpers';
import ToggleDecorator from '../../../../common/components/ToggleDecorator';
import Tooltip from '../../../../common/components/Tooltip';
import Card from '../../presentation/Card';
import cardStyles from '../../presentation/Card/Card.module.scss';
import CustomOfferExpiryFieldset from './CustomOfferExpiryFieldset';
import styles from './CustomOfferExpiryCard.module.scss';

// Import multiple css modules into a component
const s = {
  ...cardStyles,
  ...styles,
};

const trackTooltipOpening = isOpen => isOpen && trackEvent('Interact with Info Icon', {
  event_category: 'Custom Offer Expiry',
});

const CustomOfferExpiryCard = ({
  hoursUntilTripStart,
  isOpen,
  setIsOpen,
  setOfferExpiryDate,
  trip,
  tripStartDate,
  timezoneOffsetInHours,
}) => {
  const renterName = trip.getIn(['renter', 'first_name']);
  const toggleTouched = useRef(false);

  useEffect(() => {
    if (isOpen && toggleTouched.current) {
      scrollPageToBottom();
    }
  }, [isOpen, toggleTouched]);

  const toggleHandler = useCallback(() => {
    const newToggleState = !isOpen;
    setIsOpen(newToggleState);
    if (!toggleTouched.current) {
      toggleTouched.current = true;
    }
    trackEvent(`Toggle ${newToggleState ? 'on' : 'off'}`, {
      event_category: 'Custom Offer Expiry',
    });
  }, [isOpen, setIsOpen]);

  return (
    <Card
      className={s.root}
      classNameModifier="withLargeRightPadding hoverEffect"
    >
      <h3 className={s.title}>
        <label
          className={s.label}
          htmlFor="coeToggle"
        >
          Custom Offer Expiry
          <Tooltip onToggle={trackTooltipOpening}>
            Set a custom time when you want your offer to expire.
            We will notify {renterName} that your offer is available for a limited time.
          </Tooltip>
        </label>
        <ToggleDecorator className={s.toggle}>
          <input
            id="coeToggle"
            type="checkbox"
            checked={isOpen}
            onChange={toggleHandler}
          />
        </ToggleDecorator>
      </h3>

      {isOpen && (
        <CustomOfferExpiryFieldset
          hoursUntilTripStart={hoursUntilTripStart}
          setOfferExpiryDate={setOfferExpiryDate}
          tripStartDate={tripStartDate}
          trip={trip}
          timezoneOffsetInHours={timezoneOffsetInHours}
        />
      )}
    </Card>
  );
};

CustomOfferExpiryCard.propTypes = {
  hoursUntilTripStart: PropTypes.number,
  timezoneOffsetInHours: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setOfferExpiryDate: PropTypes.func.isRequired,
  trip: PropTypes.object.isRequired,
  tripStartDate: PropTypes.instanceOf(Date).isRequired,
};

export default CustomOfferExpiryCard;
