import React, {
  ReactNode,
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import type { EmblaOptionsType, UseEmblaCarouselType } from 'embla-carousel-react';
import useMediaQuery, { sharedMediaQueries } from '../hooks/useMediaQuery';

type CarouselContextType = {
  emblaRef: UseEmblaCarouselType[0];
  scrollNext: () => void;
  scrollPrev: () => void;
  canScrollNext: boolean;
  canScrollPrev: boolean;
  selectedIndex: number;
  slidesLength: number;
};

export const CarouselContext = createContext<CarouselContextType | undefined>(undefined);

export type CarouselOptions = {
  emblaOptions?: EmblaOptionsType;
  children: ReactNode | Function;
};
export const CarouselProvider: React.FC<CarouselOptions> = ({
  children,
  emblaOptions,
}) => {
  const mediaM = useMediaQuery(sharedMediaQueries.m);

  const options: EmblaOptionsType = useMemo(() => ({
    loop: false,
    slidesToScroll: 1,
    containScroll: 'trimSnaps', // to prevent final card mobile from scrolling further than it needs;
    align: mediaM ? 'start' : 'center', // medium needs start, else `slidesToScroll: "auto"` will show scropped side-cards; mobile needs center, else the scroll will end without padding // medium+ uses grid, so this useMediaQuery will not cause layout shift
    ...emblaOptions,
  }), [emblaOptions, mediaM]);

  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  useEffect(() => {
    /**
    * Embla does not automatically reinit on options changes https://www.embla-carousel.com/api/methods/#reinit
    * Embla does automatically reinit in other cases, such as when container or slides size changes https://www.embla-carousel.com/api/options/#watchresize
    * Event listeners, such as `emblaApi.on('scroll)` persist between reInit
    * */
    emblaApi?.reInit(options);
  }, [emblaApi, options]);

  const [canScrollNext, setCanScrollNext] = useState(false);
  const [canScrollPrev, setCanScrollPrev] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [slidesLength, setSlidesLength] = useState(0);

  const scrollNext = useCallback(() => emblaApi?.scrollNext(), [emblaApi]);
  const scrollPrev = useCallback(() => emblaApi?.scrollPrev(), [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;

    const onScroll = () => {
      setCanScrollNext(emblaApi.canScrollNext());
      setCanScrollPrev(emblaApi.canScrollPrev());
      setSelectedIndex(emblaApi.selectedScrollSnap());
      setSlidesLength(emblaApi.slideNodes().length);
    };

    emblaApi.on('scroll', onScroll);

    onScroll(); // on mount
  }, [emblaApi]);

  const value = useMemo(() => ({
    emblaRef,
    scrollNext,
    scrollPrev,
    canScrollNext,
    canScrollPrev,
    selectedIndex,
    slidesLength,
  }), [
    emblaRef,
    scrollNext,
    scrollPrev,
    canScrollNext,
    canScrollPrev,
    selectedIndex,
    slidesLength,
  ]);

  return (
    <CarouselContext.Provider value={value}>
      {
        typeof children === 'function'
          // optionally return a value as render prop; avoids issue of useCarousel() above provider
          // else consumers must create a sub-component just to useCarousel()
          ? (children as Function)(selectedIndex)
          : children
      }
    </CarouselContext.Provider>
  );
};

export const useCarousel = (): CarouselContextType => {
  const context = useContext(CarouselContext);
  if (!context) {
    throw new Error('useCarousel must be used within a CarouselProvider');
  }
  return context;
};
