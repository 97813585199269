import React, { FC } from 'react';
import Rating from 'react-rating';

import { getClassNameFor, noop } from '../../../helpers';
import StarIcon from '../../StarIcon';
import s from './RatingInput.module.scss';

const textRatings = [
  '',
  'Terrible',
  'Bad',
  'Okay',
  'Good',
  'Awesome',
];

const RatingInput: FC<RatingInputProps> = ({
  readonly,
  classNameModifier,
  emptySymbol = <StarIcon />,
  extraClassName = '',
  fullSymbol = <StarIcon classNameModifier="full" />,
  initialRating = 0,
  input = {
    onChange: noop,
    value: 0,
    name: '',
  },
  meta = {
    error: '',
    touched: false,
  },
  withTextRating = true,
}) => {
  const { onChange, value, name } = input;
  const { error, touched } = meta;
  const rating = value || initialRating;

  return (
    <div className={`${getClassNameFor(s, 'root', classNameModifier)} ${extraClassName}`}>
      {withTextRating && (
        <div className={getClassNameFor(s, 'textRating', classNameModifier)}>
          {textRatings[Number(rating)]}
        </div>
      )}
      <Rating
        // @ts-ignore this id doesn't seem to be accepted by react-rating
        id={name}
        readonly={readonly}
        emptySymbol={emptySymbol}
        fullSymbol={fullSymbol}
        initialRating={Number(rating)}
        onChange={rate => {
          onChange(rate > 5 ? 5 : rate);
        }}
      />
      {touched && error && (
        <label
          htmlFor={name}
          className={s.error}
        >
          {error}
        </label>
      )}
    </div>
  );
};

type RatingInputProps = {
  emptySymbol?: JSX.Element;
  fullSymbol?: JSX.Element;
  withTextRating?: boolean;
  input?: {
    onChange: (rate: number) => void;
    value: number;
    name: string;
  };
  meta?: {
    error: string;
    touched: boolean;
  };
  classNameModifier: string;
  extraClassName?: string;
  readonly: boolean;
  initialRating?: number;
};

export default RatingInput;
