import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ImmutableTrip } from '../../../../types/trips/Trips';
import { getOffer, getUserBoats } from '../../../../common/utils/reduxStoreSelectors';
import { open } from '../../../../common/ducks/zippy';
import {
  GUEST_FIELDS,
  GUESTS_FIELD,
  FORMS,
  UNSENT_CHANGES_WARNING,
  REQUESTED_CHANGES_WARNING,
} from '../../../constants';
import { modified as tripModified, offerModified, userIsRenter } from '../../../helpers';
import EditButton from '../../EditButton';
import Modal from '../../../../common/components/Modal';
import NotificationMessage from '../../../../common/components/NotificationMessage';
import GroupSizeSnippet from '../../../../common/components/GroupSizeSnippet';
import GroupSizeDetails from '../../../../common/components/GroupSizeDetails';
import Card from '../../presentation/Card';
import GroupSizeForm from './form';
import cardStyles from '../../presentation/Card/Card.module.scss';
import { offerGuestSelector } from './selector';
import styles from './GroupSizeCard.module.scss';

export const CAPACITY_WARNING = 'If the group is too large please provide your capacity.';

// Import multiple css modules into a component
const s = {
  ...cardStyles,
  ...styles,
};

type Props = {
  trip: ImmutableTrip;
  readOnly?: boolean;
  checkTripModification?: boolean;
  requestedChanges?: boolean;
};

export const BareGroupSizeCard: FC<Props> = ({
  trip,
  readOnly = false,
  checkTripModification = false,
  requestedChanges = false,
}) => {
  const offer = useSelector(getOffer);
  const userBoats = useSelector(getUserBoats);
  const dispatch = useDispatch();
  const openModal = () => dispatch(open(FORMS.GROUP_SIZE));
  const { guests, overCapacity } = offerGuestSelector({ offer, trip, userBoats });
  const offerIsModified = offerModified(offer, trip, GUEST_FIELDS);
  const tripIsModified = checkTripModification && tripModified(trip, [GUESTS_FIELD]);
  const modified = offerIsModified || tripIsModified;
  const displayCapacityWarning = overCapacity && !readOnly && !offerIsModified;
  const isOwner = !userIsRenter(trip);

  return (
    <Card
      highlighted={modified}
      classNameModifier={!readOnly ? 'hoverEffect' : ''}
    >
      {offerIsModified && (
        <NotificationMessage outdent={false}>
          {UNSENT_CHANGES_WARNING}
        </NotificationMessage>
      )}
      {requestedChanges && tripIsModified && !offerIsModified && (
        <NotificationMessage outdent={false}>
          {REQUESTED_CHANGES_WARNING}
        </NotificationMessage>
      )}
      {displayCapacityWarning && (
        <NotificationMessage outdent={false}>
          {CAPACITY_WARNING}
        </NotificationMessage>
      )}
      <GroupSizeSnippet>
        <h3 className={s.title}>
          Group Size
        </h3>
        <GroupSizeDetails
          guests={guests}
          readOnly={readOnly}
          classNameModifier={modified ? 'modified' : ''}
          withTooltip={isOwner}
        />
      </GroupSizeSnippet>

      {!readOnly && (
        <>
          <EditButton openModal={openModal} />
          <Modal
            name={FORMS.GROUP_SIZE}
            label="Group Size Form"
          >
            <GroupSizeForm
              initialValues={guests.toJS()}
              modified={offerIsModified}
            />
          </Modal>
        </>
      )}
    </Card>
  );
};

export default BareGroupSizeCard;
