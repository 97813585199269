import { createRef, useCallback, useEffect, useMemo, useState } from 'react';

import useCaptainData from 'src/common/hooks/useCaptainData';
import type { ImmutableOffer } from 'src/types/offer/Offer';
import type { ImmutableTrip } from 'src/types/trips/Trips';
import { PICKUP_TIME_FIELD } from '../constants';
import { getTripValue } from '../helpers';

type Errors = {
  captain?: boolean;
  pickupTime?: boolean;
};

const useOffer = (trip: ImmutableTrip, offer?: ImmutableOffer, isEditOffer?: boolean) => {
  const { captainOptionHeading, showCaptainConflict } = useCaptainData(trip, offer, isEditOffer);
  const captainError = !captainOptionHeading.key || showCaptainConflict;
  const captainErrorRef = createRef<HTMLDivElement>();
  const pickupTime = getTripValue(offer, trip, PICKUP_TIME_FIELD);
  const pickupTimeErrorRef = createRef<HTMLDivElement>();

  const validateOffer = useCallback(() => {
    const errors: Errors = {};
    if (captainError) {
      errors.captain = true;
    }
    if (!pickupTime) {
      errors.pickupTime = true;
    }
    return errors;
  }, [captainError, pickupTime]);

  const [errors, setErrors] = useState(validateOffer);
  const [validationPaused, setValidationPaused] = useState(true);
  const hasErrors = Object.keys(errors).length > 0;
  const handleCreateOfferClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    setValidationPaused(false);
    if (hasErrors) {
      event.preventDefault();
      // When only captain option missing, scroll to CaptainCard to show error
      if (errors.captain && !errors.pickupTime && captainErrorRef.current) {
        captainErrorRef.current?.scrollIntoView({ behavior: 'smooth' });
      // When only departure time missing, scroll to TimeCard to show error
      } else if (errors.pickupTime && pickupTimeErrorRef.current) {
        pickupTimeErrorRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [captainErrorRef, errors, hasErrors, pickupTimeErrorRef]);

  useEffect(() => {
    setErrors(validateOffer());
  }, [validateOffer]);

  return useMemo(() => ({
    captainErrorRef,
    errors,
    hasErrors: hasErrors && !validationPaused,
    handleCreateOfferClick,
    pickupTimeErrorRef,
  }), [
    validationPaused,
    captainErrorRef,
    errors,
    hasErrors,
    handleCreateOfferClick,
    pickupTimeErrorRef,
  ]);
};

export default useOffer;
