import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { stringify, parse } from 'query-string';

import { decorateComponent, rffSubmitResponse } from '../../../common/helpers';
import apiFetch from '../../../core/fetch';
import { open, close } from '../../../common/ducks/zippy';
import Modal from '../../../common/components/Modal';
import FormError from '../../../common/components/FormError';
import FormButtons from '../../../common/components/FormButtons';
import { getTripsCall } from '../../ducks/trips';
import s from './CancelActiveInquiriesModal.module.scss';

const modalName = 'cancel-active-inquiries';

const stripKey = (key, search) => stringify({
  ...parse(search),
  [key]: undefined,
});
const mapStateToProps = ({ trips }) => ({
  activeTripsCount: trips.getIn(['aggregates', 'active_inquiries_count'], 0),
});

const CancelActiveInquiriesModal = ({
  activeTripsCount,
  dispatch,
}) => {
  const location = useLocation();
  const { search } = location;
  const { filter } = useMemo(() => parse(search), [search]);
  const { replace } = useHistory();
  useEffect(
    () => {
      const timer = setTimeout(() => dispatch(open(modalName)), 3000);
      return () => clearTimeout(timer);
    },
    // TODO: figure out if this should be an empty array
    [dispatch],
  );
  const closeAndRedirect = useCallback(
    () => {
      replace({
        ...location, search: stripKey('show-cancel-inquiries', location.search),
      });
      dispatch(close(modalName));
    },
    [dispatch, location, replace],
  );
  const onSubmit = useCallback(
    () => apiFetch('/trips/cancel_active/', { method: 'PATCH' })
      .then(rffSubmitResponse(() => {
        closeAndRedirect();
        dispatch(getTripsCall({ filter }));
      })),
    [closeAndRedirect, dispatch, filter],
  );
  return activeTripsCount > 0 && (
    <Modal
      name={modalName}
      label="Cancel Dialogue"
    >
      <Form
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitError, submitting }) => (
          <form
            onSubmit={handleSubmit}
            className={s.root}
          >
            <h3 className={s.title}>
              Booking Confirmed!
            </h3>
            <p className={s.message}>
              As a courtesy to other listing owners would you like to cancel
              {` your ${activeTripsCount} other active inquir${activeTripsCount > 1 ? 'ies' : 'y'}? `}
              We’ll politely let them know that you are no longer interested.
            </p>
            <FormError error={submitError} />
            <FormButtons
              classNameModifier="inbox"
              submitText="Yes"
              cancelText="No"
              submitting={submitting}
              closeModal={closeAndRedirect}
            />
          </form>
        )}
      </Form>
    </Modal>
  );
};

CancelActiveInquiriesModal.propTypes = {
  activeTripsCount: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const decorators = [
  connect(mapStateToProps),
];

export default decorateComponent(CancelActiveInquiriesModal, decorators);
