import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PATHS } from '../common/constants';
import NotFoundContent from '../common/components/NotFoundContent';
import Root from './components/Root';
import {
  Login,
  ForgotPasswordSend,
  ForgotPasswordSubmitted,
  ForgotPasswordConfirm,
  Otp,
  ChooseMFAMethod,
  MFARecovery,
} from './components/pages';
import RegisterForm from './components/form/Register';

export const BareAuthRoutes = props => (
  <Root {...props}>
    <Switch>
      <Route exact path={PATHS.LOGIN} component={Login} />
      <Route exact path={PATHS.OTP} component={Otp} />
      <Route
        exact
        path={PATHS.CHOOSE_MFA_METHOD}
        component={ChooseMFAMethod}
      />
      <Route exact path={PATHS.MFA_RECOVERY} component={MFARecovery} />
      <Route path={PATHS.REGISTER} component={RegisterForm} />
      <Route exact path={PATHS.PASSWORD_FORGOT_SUBMITTED} component={ForgotPasswordSubmitted} />
      <Route exact path={PATHS.PASSWORD_FORGOT_CONFIRM} component={ForgotPasswordConfirm} />
      <Route exact path={PATHS.PASSWORD_FORGOT} component={ForgotPasswordSend} />
      <Route render={renderProps => <NotFoundContent {...renderProps} classNameModifier="modal" />} />
    </Switch>
  </Root>
);

export default BareAuthRoutes;
