import React, { useCallback, useMemo, useRef, useState, createContext, useContext, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fromJS } from 'immutable';

import apiFetch from '../../core/fetch';
import { unpackApiError } from '../../common/helpers';
import { useBoats } from '../../common/hooks';
import captureException from '../../common/utils/captureException';
import { fetchBoat, setBoat } from '../../common/ducks/boat';
import type { BoatDetail } from '../../types/boat/BoatDetail';
import type { InstabookDefinition, InstabookEvent } from '../types/Instabook';
import captureUnsuccessfulResponses from '../captureUnsuccessfulResponses';
import { useCalendarContext } from './useCalendar';
import useInstabookListingSelector from './useInstabookListingSelector';
import useCalendarFocus from './useCalendarFocus';
import useInstabookPrice from './useInstabookPrice';

const INSTABOOK_API_BASE_URL = '/instabook/trips/';

const unsuccessfulResponseCapturer = captureUnsuccessfulResponses(
  'Instabook Editor Unsuccessful API Response',
);

const getInstabookCall = async (instabookId: number): Promise<InstabookEvent> => apiFetch(
  `${INSTABOOK_API_BASE_URL}${instabookId}/`,
)
  .then(unsuccessfulResponseCapturer)
  .then(r => r.json());

const deleteInstabookCall = async (instabookId: number): Promise<Response> => apiFetch(
  `${INSTABOOK_API_BASE_URL}${instabookId}/`,
  { method: 'DELETE' },
)
  .then(unsuccessfulResponseCapturer);

const persistInstabookCall = async (instabook: InstabookDefinition): Promise<Response> => {
  const url = instabook.id ? `${INSTABOOK_API_BASE_URL}${instabook.id}/` : INSTABOOK_API_BASE_URL;
  const method = instabook.id ? 'PATCH' : 'POST';
  return apiFetch(url, { method, body: JSON.stringify(instabook) })
    .then(unsuccessfulResponseCapturer);
};

const useInstabookEditor = () => {
  const { boatsIndexedById } = useBoats();
  const { refresh, updateInstabookTrips } = useCalendarContext();
  const { setCalendarFocus } = useCalendarFocus();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState('');
  const [instabook, setInstabook] = useState<InstabookEvent | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const selectedInstabookId = useRef(0);
  const [boatId, setBoatId] = useState<string>();
  const instabookEditorModalContentRef = useRef<HTMLDivElement | null>(null);
  const instabookEditorBodyRef = useRef<HTMLDivElement | null>(null);
  const instabookEditorContentRef = useRef<HTMLDivElement | null>(null);

  const {
    instabookListing,
    setInstabookListing,
  } = useInstabookListingSelector();

  /**
  * The selected listing to use for the instabook
  */
  const boat: BoatDetail | null = useSelector((state: any) => (!state.boat.isEmpty()
    ? state.boat.toJS()
    : null));

  const dispatch = useDispatch();

  const loadBoat = useCallback(
    (id?: string) => {
      if (id && id !== boatId) {
        const instabookBoat = boatsIndexedById[id];
        if (instabookBoat?.active) {
          dispatch(fetchBoat(id));
        } else {
          dispatch(setBoat(fromJS(instabookBoat)));
        }
        setBoatId(id);
      }
    },
    [boatId, boatsIndexedById, dispatch],
  );

  /** Loads the instabook definition using the provided id */
  const loadInstabook = useCallback(async (instabookId: number) => {
    selectedInstabookId.current = instabookId;
    setIsLoading(true);
    try {
      const response = await getInstabookCall(instabookId);
      // Only set the response if it is still the event we are interested in.
      if (selectedInstabookId.current === instabookId) {
        setInstabook(response);
        loadBoat(response.boat_id);
      }
      setIsLoading(false);
      setIsLoaded(true);
    } catch (err) {
      setIsLoading(false);
      setError('An unexpected error occurred');
      captureException(err);
    }
  }, [loadBoat]);

  /** Deletes the currently selected instabook */
  const deleteInstabook = useCallback(async () => {
    if (!selectedInstabookId.current) {
      return;
    }
    setIsDeleting(true);
    try {
      await deleteInstabookCall(selectedInstabookId.current);
      updateInstabookTrips(selectedInstabookId.current);
      refresh();
      setIsDeleting(false);
      setInstabook(null);
    } catch (err) {
      setError('An unexpected error occurred');
      setIsDeleting(false);
      setInstabook(null);
      captureException(err);
    }
  }, [refresh, updateInstabookTrips]);

  /** Saves the instabook definition using the provided id */
  const saveInstabook = useCallback(
    async (instabookDefinition: InstabookDefinition, successAction?: () => void): Promise<void> => {
      setError('');
      setIsSaving(true);
      try {
        const response = await persistInstabookCall(instabookDefinition);

        if (!response.ok) {
          setIsSaving(false);
          const { detail } = await unpackApiError(response);
          if (detail) {
            setError(detail);
          }
        } else {
          const updatedInstabookDefinition: InstabookEvent = await response.json();
          updateInstabookTrips(selectedInstabookId.current, [updatedInstabookDefinition]);
          refresh();
          setCalendarFocus({
            selectedDate: updatedInstabookDefinition.date,
            focusedInstabookId: updatedInstabookDefinition.id,
            scrollToEvent: true,
          });
          setIsSaving(false);
          setInstabook(null);
          if (successAction) successAction();
        }
      } catch (err) {
        setError('An unexpected error occurred');
        setIsSaving(false);
        setInstabook(null);
        captureException(err);
      }
    },
    [refresh, setCalendarFocus, updateInstabookTrips],
  );

  const resetInstabook = useCallback(() => {
    setInstabook(null);
    instabookEditorModalContentRef.current = null;
    instabookEditorBodyRef.current = null;
    instabookEditorContentRef.current = null;
  }, []);

  const {
    onInstabookPriceChange,
    priceDetails: instabookPriceDetails,
    setPriceDetails: setInstabookPriceDetails,
    priceError: instabookPriceError,
  } = useInstabookPrice(boat);

  return useMemo(() => ({
    loadInstabook,
    saveInstabook,
    deleteInstabook,
    resetInstabook,
    isSaving,
    isLoaded,
    isLoading,
    isDeleting,
    error,
    instabook,
    instabookListing,
    setInstabookListing,
    // `instabookListing` is needed to "nullify" the boat if no listing is selected within the
    // selector to prevent rendering the boat-related components (captain and price), which can
    // refer to the incorrect boat in the redux state and lead to UI inconsistency in this case.
    // Because the instabook listing is the minimal boat details and gets selected immediately,
    // an additional check on the id's is required to prevent the stale boat being pulled from
    // redux state, while the new boat is still being fetched.
    boat: (instabookListing && instabookListing?.id === boat?.id) ? boat : null,
    loadBoat,
    instabookEditorModalContentRef,
    instabookEditorBodyRef,
    instabookEditorContentRef,
    onInstabookPriceChange,
    instabookPriceDetails,
    setInstabookPriceDetails,
    instabookPriceError,
  }), [
    loadInstabook,
    saveInstabook,
    deleteInstabook,
    resetInstabook,
    isSaving,
    isLoaded,
    isLoading,
    isDeleting,
    error,
    instabook,
    instabookListing,
    setInstabookListing,
    boat,
    loadBoat,
    instabookEditorModalContentRef,
    instabookEditorBodyRef,
    instabookEditorContentRef,
    onInstabookPriceChange,
    instabookPriceDetails,
    setInstabookPriceDetails,
    instabookPriceError,
  ]);
};

type UseInstabookEditor = ReturnType<typeof useInstabookEditor>;

const noop = () => Promise.resolve();

const InstabookEditorContext = createContext<UseInstabookEditor>({
  boat: null,
  error: '',
  instabook: null,
  isDeleting: false,
  isLoaded: false,
  isLoading: false,
  isSaving: false,
  loadInstabook: noop,
  saveInstabook: noop,
  resetInstabook: noop,
  deleteInstabook: noop,
  loadBoat: noop,
  instabookListing: null,
  setInstabookListing: noop,
  instabookEditorModalContentRef: { current: null },
  instabookEditorBodyRef: { current: null },
  instabookEditorContentRef: { current: null },
  instabookPriceDetails: undefined,
  setInstabookPriceDetails: noop,
  instabookPriceError: undefined,
  onInstabookPriceChange: Object.assign(() => {}, {
    cancel: () => {},
    flush: () => {},
  }),
});

export const useInstabookEditorContext = () => useContext(InstabookEditorContext);

export const InstabookEditorProvider: FC = ({ children }) => (
  <InstabookEditorContext.Provider value={useInstabookEditor()}>
    {children}
  </InstabookEditorContext.Provider>
);
