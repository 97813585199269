/**
 * Will call function `fn` upto `retryAttempts` times, defaulting to the
 * `fallback` if none of the retries succeeded.
 * @param retryAttempts The number of times the function can be retried.
 * @param fn The asynchronous function to be executed. The function should be
 * able to be called idempotently.
 * @param fallback The fallback function to call once the retry attempts have
 * been depleted.
 */
const retry = <Result, FallbackResponse = void>(
  retryAttempts: number,
  fn: () => Promise<Result>,
  fallback = () => Promise.resolve(),
): Promise<Result | FallbackResponse | void> => {
  if (retryAttempts) {
    return fn().catch(() => retry(retryAttempts - 1, fn, fallback));
  }

  return fallback();
};

export default retry;
