import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { PATHS } from '../../../common/constants';
import { getClassNameFor } from '../../../common/helpers';
import { useBoats } from '../../../common/hooks';
import StatusFilter from '../StatusFilter';
import ListingsFilter from '../ListingsFilter';
import s from './Filters.module.scss';

const Filters = ({ classNameModifier = '' }) => {
  const { allBoats } = useBoats();
  return (
    <div className={getClassNameFor(s, 'root', `${classNameModifier}`)}>
      {allBoats.length > 1 && (
        <ListingsFilter
          classNameModifier={classNameModifier}
        />
      )}
      <Route exact path={PATHS.CALENDAR}>
        <StatusFilter
          classNameModifier={classNameModifier}
        />
      </Route>
    </div>
  );
};

Filters.propTypes = {
  classNameModifier: PropTypes.string,
};

export default Filters;
