import React, { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import { TITLE, META } from '../../constants';
import IntlSEOMeta from '../IntlSEOMeta';
import NoscriptMessage from '../NoscriptMessage';
import BareLayout from './BareLayout';
import GdprCompliance from '../GdprCompliance';
import SeamlessAppReload from '../SeamlessAppReload';
import AppNotification from '../AppNotification';

type LayoutProps = {
  children: ReactNode;
  classNameModifier?: string;
};

const Layout: FC<LayoutProps> = ({ children, classNameModifier }) => (
  <BareLayout classNameModifier={classNameModifier}>
    <Helmet
      defaultTitle={`${TITLE.DEFAULT} | ${TITLE.PROJECT}`}
      titleTemplate={`%s | ${TITLE.PROJECT}`}
    >
      <meta name="description" content={META.DESCRIPTION.DEFAULT} />
      {/* This is a thing that tells Bing that we are who we say we are */}
      <meta name="msvalidate.01" content="FD387DB8BE9E3AC7D5083CE6D4021C20" />
    </Helmet>
    <IntlSEOMeta />
    <NoscriptMessage />
    {children}
    <GdprCompliance />
    <SeamlessAppReload />
    <AppNotification />
  </BareLayout>
);

export default Layout;
