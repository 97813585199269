import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { compose } from 'lodash/fp';
import { FormApi } from 'final-form';
import { close } from '../../../../common/ducks/zippy';
import { BOAT_FIELD, FORMS } from '../../../constants';
import { clearFields, patchOffer } from '../../../ducks/offer';
import FormButtons from '../../../../common/components/FormButtons';
import ClearButton from '../../../../common/components/ClearButton';
import VesselInput from './input';
import s from './VesselForm.module.scss';
import { ImmutableBoat, ImmutableBoats } from '../../../../types/boat/BoatDetail';
import { fetchBoatAndSetMap, getBoatMap } from '../../../../common/ducks/boatMap';
import getListingCaptainOption from '../../../../common/utils/getListingCaptainOption';
import serializeCaptainOptions from '../../../utils/serializeCaptainOptions';
import { ImmutableTrip } from '../../../../types/trips/Trips';
import { setBoat } from '../../../../common/ducks/boat';

type VesselForm = FormApi<Record<string, any>, Partial<Record<string, any>>>;

type Props = {
  trip: ImmutableTrip;
  userBoats: ImmutableBoats;
  modified: boolean;
  initialValues: { boat: string };
};

export const BareVesselForm: FC<Props> = ({ userBoats, modified, initialValues, trip }) => {
  const dispatch = useDispatch();
  const boatMap = useSelector(getBoatMap);
  const patchTheOffer = compose(dispatch, patchOffer);
  const getBoat = compose(dispatch, fetchBoatAndSetMap);
  const clearLocalOfferFields = useCallback(
    () => dispatch(clearFields([BOAT_FIELD, 'captained', 'captain_cost_included'])),
    [dispatch],
  );
  const closeModal = () => dispatch(close(FORMS.VESSEL));

  const setOfferPayload = useCallback(
    (boat: ImmutableBoat) => {
      /*
       * If the boat select has only 1 captain option then prepopulate
       * the offer with `captained` and `captain_cost` in the redux state.
       */
      const bareboat = boat.get('bareboat');
      const captainOption = boat.get('captain_option');
      const listingCaptainOptions = getListingCaptainOption(
        bareboat as boolean,
        captainOption as string,
      );
      if (listingCaptainOptions?.length === 1) {
        const option = listingCaptainOptions[0];
        const payload = serializeCaptainOptions(option.key);
        dispatch(patchOffer(payload));
      } else {
        dispatch(clearFields(['captained', 'captain_cost_included']));
      }
    },
    [dispatch],
  );

  const clearLocalVessel = useCallback(
    (form: VesselForm) => {
      form.reset();
      const formState = form.getState();
      const boatId = formState.values.boat;
      const tripBoatId = trip.getIn(['boat', 'id']);
      if (tripBoatId !== boatId && boatMap[tripBoatId]) {
        const boat = boatMap[tripBoatId];
        dispatch(setBoat(boat));
      }
      clearLocalOfferFields();
    },
    [boatMap, clearLocalOfferFields, dispatch, trip],
  );

  return (
    <Form
      onSubmit={async ({ boat: boatId }, { getState }) => {
        if (getState().dirty) {
          patchTheOffer({
            // Need to do this manually because of needing to submit an object
            // for boat, rather than just the id
            boat: { id: boatId },
          });
          const boat = await getBoat(boatId);
          setOfferPayload(boat);
        }
        closeModal();
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit, dirty, form }) => (
        <form
          onSubmit={handleSubmit}
          className={s.root}
        >
          <h3 className={s.title}>{'Suggest a different \n listing'}</h3>
          <ClearButton
            disabled={!(dirty || modified)}
            onClick={() => clearLocalVessel(form)}
            classNameModifier="vessel"
          />
          <Field
            name="boat"
            component={VesselInput}
            userBoats={userBoats}
            trip={trip}
          />

          <FormButtons
            classNameModifier="inbox"
            closeModal={closeModal}
          />
        </form>
      )}
    </Form>
  );
};

export default BareVesselForm;
