import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Route, Switch, matchPath, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { parse } from 'query-string';
import Media from 'react-media';
import Headroom from 'react-headroom';
import classNames from 'classnames';

import { ReduxState } from 'src/types/reduxState';
import { useAppDispatch, usePrevious } from 'src/common/hooks';
import { Landing } from '../pages';
import TripRoutes from '../TripRoutes';
import { TITLE, MEDIA_QUERIES } from '../../../common/constants';
import { PAGES } from '../../constants';
import { getClassNameFor } from '../../../common/helpers';
import { firstTripRedirectThunk } from '../../helpers';
import Layout from '../../../common/components/Layout';
import Header from '../../../common/components/Header';
import SvgDefs from '../../../common/components/SvgDefs';
import Sidebar from '../Sidebar';
import { getTripsCall, getNewFilterSetCall } from '../../ducks/trips';
import { getBoatsCall } from '../../../common/ducks/user';
import { setLoading, setLoadingComplete, setError } from '../../../common/ducks/loading';
import s from './Inbox.module.scss';
import images from '../../images.svg';
import PresenceIdle from '../PresenceIdle';
import handlePin from '../../../common/utils/handlePin';

const PAGES_WITHOUT_SIDEBAR = [
  PAGES.REVIEW_BOOKING,
  PAGES.INSURANCE,
];

const InboxRoot: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOwner = useSelector((state: ReduxState) => state.user.get('owner_approved'));

  const { url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { pathname, search } = location;
  const prevProps = usePrevious({ location });

  const setLoadingTrips = useCallback(() => dispatch(setLoading()), [dispatch]);
  const finishLoading = useCallback(() => dispatch(setLoadingComplete()), [dispatch]);
  const loadingError = useCallback((error: string) => dispatch(setError(error)), [dispatch]);

  const getBoats = useCallback((params) => dispatch(getBoatsCall(params)), [dispatch]);
  const getTrips = useCallback((params) => dispatch(getTripsCall(params)), [dispatch]);
  const getNewFilterSet = useCallback(
    (params) => dispatch(getNewFilterSetCall(params)),
    [dispatch],
  );

  const firstRedirect = useCallback(
    () => dispatch(firstTripRedirectThunk(history, location)),
    [dispatch, history, location],
  );

  useEffect(() => {
    const { filter } = parse(search);
    // If the inbox is already loaded and the user navigates away,
    // e.g. to authenticate,
    // this logic is going to run again, but the loading flag won't
    // be set. This makes sure that it will be set when the component
    // is setting up.
    setLoadingTrips();
    const tripParameters = { ...(filter && { filter }) };

    Promise.all([
      getTrips(tripParameters),
      getBoats({
        page_size: 100,
        minimal: true,
        show_removed: false,
      }),
    ])
      .then(firstRedirect)
      .then(finishLoading)
      .catch(() => loadingError('There was an error loading the inbox.'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Logic to get new trips when filters change
    const { search: oldSearch } = prevProps?.location ?? {};
    const { search: newSearch } = location;
    const { filter: oldFilter } = parse(oldSearch);
    const { filter: newFilter } = parse(newSearch);

    if (oldFilter !== newFilter) {
      // oldFilter is undefined on initial filter selection
      setLoadingTrips();
      getNewFilterSet(newFilter ? { filter: newFilter } : {})
        .then(firstRedirect)
        .then(finishLoading)
        .catch(() => loadingError('There was an error loading the new trips.'));
    }
  }, [
    firstRedirect,
    finishLoading,
    getNewFilterSet,
    loadingError,
    location,
    prevProps?.location,
    setLoadingTrips,
  ]);

  const isThread = matchPath<{ tripId?: string }>(pathname, {
    path: `${url}/:tripId`,
  })?.params.tripId;

  return (
    <Layout classNameModifier="inbox">
      <Helmet>
        <title lang="en">{TITLE.AI}</title>
      </Helmet>
      <div className={getClassNameFor(s, 'header', classNames('mobile', { hide: isThread }))}>
        <Header
          classNameModifier="bordered fixed fixedOnMobile"
          navigationVariant="owner-calendar-inbox"
          withSearchBar={!isOwner}
        />
      </div>
      <div className={getClassNameFor(s, 'header', 'desktop')}>
        <Headroom
          disableInlineStyles
          onPin={() => handlePin('pin')}
          onUnpin={() => handlePin('unpin')}
          onUnfix={() => handlePin('unfix')}
        >
          <Header
            classNameModifier="bordered"
            navigationVariant="owner-calendar-inbox"
            withSearchBar={!isOwner}
          />
        </Headroom>
      </div>
      <SvgDefs href={images} />
      <PresenceIdle />
      <div className={s.root}>
        {!PAGES_WITHOUT_SIDEBAR.some(page => pathname.endsWith(`/${page}/`)) && (
          <Sidebar />
        )}
        <Switch>
          <Route path={`${url}/:tripId`} component={TripRoutes} />
          <Media
            query={MEDIA_QUERIES.DESKTOP}
            defaultMatches
            render={() => <Route path={url} component={Landing} />}
          />
        </Switch>
      </div>
    </Layout>
  );
};

export default InboxRoot;
