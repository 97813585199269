import React, { FC, useMemo } from 'react';
import { AvailabilityBookingSetting } from '../../types';
import {
  AVAILABLE_SETTINGS_MAP,
  OPTION_BLOCK_TIME,
  OPTION_BLOCK_DAY,
  OPTION_REMAINS_AVAILABLE,
} from '../../constants';
import Dropdown, { DropdownOption } from '../../../common/components/DropdownDS22';

export type AvailabilityDropdownProps = {
  handleOnSelect: (selectedOption: DropdownOption) => void;
  selectedOption?: AvailabilityBookingSetting;
  disabled?: boolean;
  submitting?: boolean;
};

const AvailabilityDropdown: FC<AvailabilityDropdownProps> = ({
  handleOnSelect,
  selectedOption,
  disabled,
  submitting,
}) => {
  const dropdownOptions = useMemo(() => [
    {
      id: OPTION_BLOCK_TIME,
      dropdownLabel: 'Block Time Only',
      selectedLabel: 'Block Time Only',
    },
    {
      id: OPTION_BLOCK_DAY,
      dropdownLabel: 'Block Whole Day',
      selectedLabel: 'Block Whole Day',
    },
    {
      id: OPTION_REMAINS_AVAILABLE,
      dropdownLabel: 'Remains Available',
      selectedLabel: 'Remains Available',
    },
  ], []);

  const getDefaultOption = (setting: AvailabilityBookingSetting) => {
    const defaultKey = Object.keys(AVAILABLE_SETTINGS_MAP).find(
      (key) => AVAILABLE_SETTINGS_MAP[key as keyof typeof AVAILABLE_SETTINGS_MAP] === setting,
    );
    const defaultOption = dropdownOptions.find((option) => option.id === defaultKey);
    return defaultOption;
  };

  return (
    <Dropdown
      defaultOption={selectedOption !== null ? getDefaultOption(selectedOption!) : null}
      onChange={handleOnSelect}
      options={dropdownOptions}
      variant="white"
      instructionLabel="Select Option"
      disabled={disabled}
      submitting={submitting}
    />
  );
};

export default AvailabilityDropdown;
