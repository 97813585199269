import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { EXTERNAL_PATHS } from '../../constants';
import { getClassNameFor } from '../../helpers';
import { clearNotFound } from '../../ducks/loading';
import Svg from '../Svg';
import s from './NotFoundContent.module.scss';

type NotFoundContentProps = {
  classNameModifier?: 'modal';
  staticContext?: {
    notFound?: true;
  };
};

const NotFoundContent: FC<NotFoundContentProps> = ({
  classNameModifier,
  staticContext,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (staticContext) {
      // eslint-disable-next-line no-param-reassign
      staticContext.notFound = true;
    }
  }, [staticContext]);

  /*
    * This solves the problem of getting stuck on a 404 page,
    * because in order to display a 404 page from the backend
    * when the issue is that a particular ID isn't found, rather
    * than the route just being wrong, you have to put something
    * in redux to indicate that there's a notFound. That then
    * needs to be cleared on navigation, because otherwise it's
    * still going to be short-circuiting the routing. This adds
    * a location listener, and operates under the assumption that
    * when the location changes, the user intends to leave the 404 page
    * for an existing page.
    */
  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(clearNotFound());
    });
    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  }, [dispatch, history]);

  return (
    <main className={getClassNameFor(s, 'root', classNameModifier)}>
      <Svg
        href="#404"
        className={s.icon}
      />
      <h1 className={s.title}>
        Oh no!
      </h1>
      <h3 className={s.description}>
        You’ve sailed into uncharted waters — this page doesn’t seem to exist.
      </h3>
      <h2 className={s.subTitle}>
        Try returning to the <a className={s.link} href="/">Homepage</a>
        {' or '}
        <a className={s.link} href={EXTERNAL_PATHS.CONTACT_US}>Contact our Support Team</a>
        {' if you need help.'}
      </h2>
    </main>
  );
};

export default NotFoundContent;
