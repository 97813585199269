import React, { FC } from 'react';

import { NEW_TAB_ATTRIBUTES } from '../../constants';
import s from './ExternalLink.module.scss';

const ExternalLink: FC<Props> = ({
  to,
  children,
  className = s.root,
  openInNewTab = true,
  ...restProps
}) => (
  <a
    href={to}
    className={className}
    {...(openInNewTab ? NEW_TAB_ATTRIBUTES : {})}
    {...restProps}
  >
    {children}
  </a>
);

type Props = {
  to: string;
  className?: string;
  openInNewTab?: boolean;
};

export default ExternalLink;
