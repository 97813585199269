import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import { useRouteMatch } from 'react-router-dom';
import { PATHS } from '../../../common/constants';
import { getClassNameFor } from '../../../common/helpers';
import { startOfMonth, todayDate } from '../../helpers';

import s from './NoEvents.module.scss';

type NoEventsProps = {
  date: Date;
};

const NoEvents: FC<NoEventsProps> = ({ date }) => {
  const isPresent = useMemo(() => date >= startOfMonth(todayDate()), [date]);
  const isInstabook = useRouteMatch(PATHS.INSTABOOK);

  return (
    <div className={classNames({ [s.present]: isPresent })}>
      <div className={getClassNameFor(s, 'card', classNames({ instabook: isInstabook }))}>
        <h1 className={getClassNameFor(s, 'message', classNames({ instabook: isInstabook }))}>
          No events to display
        </h1>
      </div>
    </div>
  );
};

export default NoEvents;
