import React, { FC, ReactNode } from 'react';

import { getClassNameFor } from '../../helpers';
import Svg from '../Svg';
import s from './DateTimeSnippet.module.scss';

type DateTimeSnippetProps = {
  type: 'date' | 'time';
  children: ReactNode;
  classNameModifier?: string;
};

const DateTimeSnippet: FC<DateTimeSnippetProps> = ({
  type,
  children,
  classNameModifier,
}) => (
  <div className={s.root}>
    <Svg
      href={type === 'date' ? '#icon-calendar' : '#old-icon-time'}
      className={getClassNameFor(s, 'icon', [type, classNameModifier].join(' '))}
    />
    <div className={s.content}>
      {children}
    </div>
  </div>
);

export default DateTimeSnippet;
