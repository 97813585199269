import React from 'react';
import { Link } from 'react-router-dom';
import { Location } from 'history';

import { ImmutableTrip } from 'src/types/trips/Trips';
import {
  PATHS,
} from '../../../common/constants';
import { preserveSearch, appendRoute } from '../../../common/utils/routing';
import { PAGES, TRIP_STATES } from '../../constants';
import ReservationCancelledMessage from '../presentation/ReservationCancelledMessage';
import UserBlock from '../presentation/UserBlock';
import OfferSentMessage from '../OfferSentMessage';
import RenterChangesRequestedMessage from '../RenterChangesRequestedMessage';
import RenterCustomOfferExpiredMessage from '../RenterCustomOfferExpiredMessage';

const {
  OWNER_OFFER_SENT,
  OWNER_OFFER_EDITED, // cancelled and resent
  OWNER_CHANGES_REQUESTED,
  OWNER_INQUIRY_CANCELLED,
  OWNER_INQUIRY_LAPSED,
  OWNER_RESERVATION_CONFIRMED,
  OWNER_TRIP_COMPLETE_REVIEW_PENDING,
  OWNER_TRIP_COMPLETE_REVIEW_WAITING,
  OWNER_TRIP_COMPLETE_REVIEW_DONE,
  OWNER_TRIP_MOVED,

  RENTER_DIRECT_INQUIRY,
  RENTER_MATCHING_INQUIRY,
  RENTER_CHANGES_REQUESTED,
  RENTER_OFFER_RECEIVED,
  RENTER_OFFER_CANCELLED,
  RENTER_OFFER_AUTO_CANCELLED,
  RENTER_OFFER_EDITED,
  RENTER_OFFER_EXPIRED,
  RENTER_CUSTOM_OFFER_EXPIRED,
  RENTER_INQUIRY_DECLINED,
  RENTER_INQUIRY_CANCELLED,
  RENTER_INQUIRY_LAPSED,
  RENTER_RESERVATION_CONFIRMED,
  RENTER_RESERVATION_CANCELLED_BY_OWNER,
  RENTER_RESERVATION_CANCELLED_BY_RENTER,
  RENTER_TRIP_COMPLETE_REVIEW_PENDING,
  RENTER_TRIP_COMPLETE_REVIEW_WAITING,
  RENTER_TRIP_COMPLETE_REVIEW_DONE,
  RENTER_TRIP_MOVED,
} = TRIP_STATES;

const content = {
  title: {
    [OWNER_OFFER_SENT]: 'Offer Sent',
    [OWNER_OFFER_EDITED]: 'Offer Sent',
    [OWNER_INQUIRY_CANCELLED]: 'Inquiry Canceled',
    [OWNER_INQUIRY_LAPSED]: 'Lapsed',
    [OWNER_RESERVATION_CONFIRMED]: 'Booking Confirmed',
    [OWNER_TRIP_COMPLETE_REVIEW_PENDING]: 'Trip Complete',
    [OWNER_TRIP_COMPLETE_REVIEW_WAITING]: 'Trip Complete',
    [OWNER_TRIP_COMPLETE_REVIEW_DONE]: 'Trip Complete',
    [OWNER_TRIP_MOVED]: 'Booking Canceled',

    [RENTER_DIRECT_INQUIRY]: 'Inquiry Sent',
    [RENTER_MATCHING_INQUIRY]: 'Inquiry Sent',
    [RENTER_OFFER_RECEIVED]: 'Ready to Book',
    [RENTER_OFFER_CANCELLED]: 'Offer Canceled',
    [RENTER_OFFER_EDITED]: 'Ready to Book',
    [RENTER_OFFER_EXPIRED]: 'Offer Expired',
    [RENTER_INQUIRY_DECLINED]: 'Inquiry Declined',
    [RENTER_INQUIRY_CANCELLED]: 'Inquiry Canceled',
    [RENTER_INQUIRY_LAPSED]: 'Inquiry Lapsed',
    [RENTER_RESERVATION_CONFIRMED]: 'Booking Confirmed',
    [RENTER_RESERVATION_CANCELLED_BY_OWNER]: 'Booking Canceled',
    [RENTER_RESERVATION_CANCELLED_BY_RENTER]: 'Booking Canceled',
    [RENTER_TRIP_COMPLETE_REVIEW_PENDING]: 'Trip Complete',
    [RENTER_TRIP_COMPLETE_REVIEW_WAITING]: 'Trip Complete',
    [RENTER_TRIP_COMPLETE_REVIEW_DONE]: 'Trip Complete',
    [RENTER_TRIP_MOVED]: 'Booking Canceled',
  },

  message: {
    [OWNER_OFFER_SENT]: trip => (
      <OfferSentMessage trip={trip}>
        <strong>
          Your offer to {trip.getIn(['renter', 'first_name'])} has been sent and can now be booked.
        </strong>
        {' '}
        We will remind them to get back to you soon.
      </OfferSentMessage>
    ),
    [OWNER_OFFER_EDITED]: trip => (
      <OfferSentMessage trip={trip}>
        <strong>
          Your offer to {trip.getIn(['renter', 'first_name'])} has been re-sent and can now be booked.
        </strong>
        {' '}
        Your previous offer was canceled. We will remind them to get back to you soon.
      </OfferSentMessage>
    ),
    [OWNER_CHANGES_REQUESTED]: trip => (
      <UserBlock user={trip.get('renter')}>
        <strong>
          {trip.getIn(['renter', 'first_name'], 'Renter')} requested changes to your offer.
        </strong>
        {' '}
        Please review the requested changes and create an offer to respond.
      </UserBlock>
    ),
    [OWNER_INQUIRY_CANCELLED]: trip => (
      <UserBlock
        classNameModifier="bold"
        user={trip.get('renter')}
      >
        {trip.getIn(['renter', 'first_name'])} canceled this booking inquiry.
      </UserBlock>
    ),
    [OWNER_INQUIRY_LAPSED]: (trip, location) => (
      <UserBlock user={trip.get('renter')}>
        <strong>
          Your inquiry from {trip.getIn(['renter', 'first_name'])} expired because the date has passed.
        </strong>
        {' '}
        Always respond to inquiries quickly to avoid penalties to your ranking.
        You can
        {' '}
        <Link to={preserveSearch(appendRoute(location.pathname, PAGES.EDIT), location)}>
          send an offer with an updated date
        </Link>
        {' '}
        or archive this inquiry.
      </UserBlock>
    ),
    [OWNER_TRIP_COMPLETE_REVIEW_PENDING]: () => 'Would you like to send a thank you note to your customer?',
    [OWNER_TRIP_MOVED]: (trip, location) => (
      <ReservationCancelledMessage
        location={location}
        trip={trip}
      />
    ),

    [RENTER_DIRECT_INQUIRY]: trip => (
      <UserBlock user={trip.get('owner')}>
        <strong>
          {trip.getIn(['owner', 'first_name'])} will be prompted to get back to you soon.
        </strong>
      </UserBlock>
    ),

    [RENTER_MATCHING_INQUIRY]: trip => (
      <UserBlock user={trip.get('owner')}>
        <strong>
          {trip.getIn(['owner', 'first_name'])} will be prompted to get back to you soon.
        </strong>
        {' '}
        You selected this as a similar listing when you sent an inquiry.
      </UserBlock>
    ),

    [RENTER_CHANGES_REQUESTED]: trip => (
      <RenterChangesRequestedMessage trip={trip} />
    ),

    [RENTER_INQUIRY_CANCELLED]: trip => (
      <UserBlock user={trip.get('owner')}>
        <strong>
          You canceled your booking inquiry with {trip.getIn(['owner', 'first_name'])}.
        </strong>
        {' '}
        You can
        {' '}
        <Link
          to={{
            pathname: `${PATHS.INQUIRY}${trip.getIn(['boat', 'id'])}/`,
            search: `?return=${trip.getIn(['boat', 'listing_url'])}`,
          }}
        >
          send a new inquiry
        </Link>
        {' '}
        if you change your mind.
      </UserBlock>
    ),
    [RENTER_INQUIRY_DECLINED]: (trip, location) => (
      <UserBlock user={trip.get('owner')}>
        <strong>
          {trip.getIn(['owner', 'first_name'])} declined your inquiry.
        </strong>
        {' '}
        <Link
          to={preserveSearch(appendRoute(location.pathname, PAGES.MESSAGES), location)}
        >
          Send a message
        </Link>
        {' '}
        or a
        {' '}
        <Link
          to={{
            pathname: `${PATHS.INQUIRY}${trip.getIn(['boat', 'id'])}/`,
            search: `?return=${trip.getIn(['boat', 'listing_url'])}`,
          }}
        >
          new inquiry
        </Link>
        {' '}
        to discuss other booking options.
      </UserBlock>
    ),

    [RENTER_OFFER_CANCELLED]: (trip, location) => (
      <UserBlock user={trip.get('owner')}>
        <strong>
          {trip.getIn(['owner', 'first_name'])} canceled the previous offer.
        </strong>
        {' '}
        <Link
          to={preserveSearch(appendRoute(location.pathname, PAGES.MESSAGES), location)}
        >
          Send a message
        </Link>
        {' '}
        or a
        {' '}
        <Link
          to={{
            pathname: `${PATHS.INQUIRY}${trip.getIn(['boat', 'id'])}/`,
            search: `?return=${trip.getIn(['boat', 'listing_url'])}`,
          }}
        >
          new inquiry
        </Link>
        {' '}
        to discuss other booking options, or cancel your
        inquiry if you are no longer interested in this trip.
      </UserBlock>
    ),
    [RENTER_OFFER_AUTO_CANCELLED]: trip => {
      const ownerName = trip.getIn(['owner', 'first_name']);
      return (
        <UserBlock user={trip.get('owner')}>
          <>
            <strong>
              Your offer from {ownerName} was canceled because another customer
              {' '}
              confirmed a booking that coincides with your trip.
            </strong>
            {' '}
            Try asking {ownerName} for another offer.
          </>
        </UserBlock>
      );
    },
    [RENTER_OFFER_EXPIRED]: (trip, location) => (
      <UserBlock user={trip.get('owner')}>
        <strong>
          Your offer from {trip.getIn(['owner', 'first_name'])} expired because the date has now passed.
        </strong>
        {' '}
        <Link
          to={preserveSearch(appendRoute(location.pathname, PAGES.MESSAGES), location)}
        >
          Send a message
        </Link>
        {' '}
        to request a new offer or
        cancel your inquiry if you are no longer interested.
      </UserBlock>
    ),
    [RENTER_CUSTOM_OFFER_EXPIRED]: (trip, location) => (
      <RenterCustomOfferExpiredMessage
        location={location}
        trip={trip}
      />
    ),
    [RENTER_RESERVATION_CANCELLED_BY_OWNER]: (trip, location) => (
      <UserBlock user={trip.get('owner')}>
        <strong>
          {trip.getIn(['owner', 'first_name'])} canceled this reservation.
        </strong>
        {' '}
        <Link
          to={preserveSearch(appendRoute(location.pathname, PAGES.MESSAGES), location)}
        >
          Send a message
        </Link>
        {' '}
        to request a new offer.
        A refund will be processed if applicable.
      </UserBlock>
    ),
    [RENTER_RESERVATION_CANCELLED_BY_RENTER]: (trip, location) => (
      <UserBlock user={trip.get('owner')}>
        <strong>
          You canceled your reservation with {trip.getIn(['owner', 'first_name'])}.
        </strong>
        {' '}
        <Link
          to={preserveSearch(appendRoute(location.pathname, PAGES.MESSAGES), location)}
        >
          Send a message
        </Link>
        {' '}
        to request a new offer.
        A refund will be processed if applicable.
      </UserBlock>
    ),
    [RENTER_TRIP_COMPLETE_REVIEW_PENDING]: () => 'Would you like to leave a review and send a thank you note to the listing owner?',
    [RENTER_TRIP_MOVED]: (trip, location) => (
      <ReservationCancelledMessage
        location={location}
        trip={trip}
      />
    ),
    [RENTER_INQUIRY_LAPSED]: (trip, location) => (
      <UserBlock user={trip.get('owner')}>
        <strong>
          Your inquiry with {trip.getIn(['owner', 'first_name'])} expired because the date has now passed.
        </strong>
        {' '}
        <Link
          to={preserveSearch(appendRoute(location.pathname, PAGES.MESSAGES), location)}
        >
          Send a message
        </Link>
        {' '}
        or a
        {' '}
        <Link
          to={{
            pathname: `${PATHS.INQUIRY}${trip.getIn(['boat', 'id'])}/`,
            search: `?return=${trip.getIn(['boat', 'listing_url'])}`,
          }}
        >
          new inquiry
        </Link>
        , or cancel your inquiry if you are no longer interested in this trip.
      </UserBlock>
    ),
  },
};

export const getMessage = (trip: ImmutableTrip, location: Location) => {
  const tripState = trip.getIn(['state', 'state']);
  const message = content.message[tripState];
  return message && message(trip, location);
};

export default content;
