import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';

import { Guests } from '../../../../types/trips/Trips';
import { FORMS, GUEST_FIELDS } from '../../../constants';
import { clearFields, patchOffer } from '../../../ducks/offer';
import { rffGroupSizeValidator as validate } from '../../../../common/validation';
import { modalMapDispatchToProps } from '../../../submission';
import GroupSizeFieldset from '../../../../common/components/fieldsets/GroupSize';
import FormError from '../../../../common/components/FormError';
import ClearButton from '../../../../common/components/ClearButton';
import FormButtons from '../../../../common/components/FormButtons';
import s from './GroupSizeForm.module.scss';

type Props = {
  modified: boolean;
  initialValues: Guests;
};

export const BareGroupSizeForm: FC<Props> = ({
  modified,
  initialValues,
}) => {
  const dispatch = useDispatch();
  const { closeModal } = modalMapDispatchToProps(FORMS.GROUP_SIZE)(dispatch);
  const clearLocalOfferFields = () => dispatch(clearFields(GUEST_FIELDS));
  const patchTheOffer = (values: Guests) => dispatch(patchOffer(values));

  return (
    <Form<Guests>
      onSubmit={(values, { getState }) => {
        if (getState().dirty) {
          patchTheOffer(values);
        }
        closeModal();
      }}
      validate={validate}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        dirty,
        submitting,
        error,
        values,
        form: { reset },
      }) => {
        const total = Object.values(values)
          .filter(val => !!val)
          .reduce((a, b) => a + b, 0);
        return (
          <form onSubmit={handleSubmit} className={s.root}>
            <h3 className={s.title}>
              Provide your capacity
            </h3>
            <GroupSizeFieldset
              total={total}
              classNameModifier="inbox"
            >
              <ClearButton
                disabled={!(dirty || modified)}
                onClick={() => {
                  reset();
                  clearLocalOfferFields();
                }}
              />
            </GroupSizeFieldset>
            <FormError error={error} />
            <FormButtons
              classNameModifier="inbox"
              submitting={submitting}
              closeModal={closeModal}
            />
          </form>
        );
      }}
    </Form>
  );
};

export default BareGroupSizeForm;
