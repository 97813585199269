import { useCallback, useState } from 'react';

type AhoyTrackingInfo = {
  ahoyId: string;
  ahoyMsgId: string;
  messageId: string;
};

type UseUnreadMessageStore = {
  addUnreadMessage: (
    threadId: string,
    ahoyId: string,
    ahoyMsgId: string,
    messageId: string,
  ) => void;
  removeUnreadMessages: (threadId: string) => void;
  getUnreadMessagesForThread: (threadId: string) => AhoyTrackingInfo[];
};

const INITIAL_MESSAGE_AHOY_ID_PAIRS: Record<string, AhoyTrackingInfo[]> = {};

const useUnreadMessageStore = (): UseUnreadMessageStore => {
  const [unreadMessages, setUnreadMessages] = useState(INITIAL_MESSAGE_AHOY_ID_PAIRS);

  const getUnreadMessagesForThread = useCallback((
    threadId: string,
  ) => unreadMessages[threadId] || [], [unreadMessages]);

  const addUnreadMessage = useCallback((
    threadId: string,
    ahoyId: string,
    ahoyMsgId: string,
    messageId: string,
  ) => {
    setUnreadMessages(prevUnreadMessages => {
      const current = prevUnreadMessages[threadId] || [];
      const threadMessageAhoyIdPairs = [
        ...current, {
          ahoyId,
          ahoyMsgId,
          messageId,
        },
      ];
      return {
        ...prevUnreadMessages,
        [threadId]: threadMessageAhoyIdPairs,
      };
    });
  }, []);

  const removeUnreadMessages = useCallback((threadId: string) => {
    setUnreadMessages(prevUnreadMessages => {
      const copyPrevUnreadMessages = { ...prevUnreadMessages };
      delete copyPrevUnreadMessages[threadId];
      return copyPrevUnreadMessages;
    });
  }, []);

  return {
    addUnreadMessage,
    removeUnreadMessages,
    getUnreadMessagesForThread,
  };
};

export default useUnreadMessageStore;
