import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const render = () => null;
export const scrollPageToTop = () => window.scrollTo(0, 0);

class TopScroll extends Component {
  componentDidMount = scrollPageToTop;

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      scrollPageToTop();
    }
  }

  render = render;
}

TopScroll.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(TopScroll);
