import React from 'react';
import { Field, type FieldProps } from 'react-final-form';

import RadioButtonInput from '../../inputs/RadioButton';

type Value = string | number | boolean;

export const parseTrueFalseValue = (value: Value) => value === 'true';

type RadioButtonProps = FieldProps<Value, any>;

const RadioButton: React.FC<RadioButtonProps> = fieldProps => (
  <Field
    component={RadioButtonInput}
    type="radio"
    {...fieldProps}
  />
);

export default RadioButton;
