import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import classNames from 'classnames';
import { PATHS, TITLE } from '../../../common/constants';
import { useBoats } from '../../../common/hooks';
import { getBoatsCall } from '../../../common/ducks/user';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import Layout from '../../../common/components/Layout';
import Header from '../../../common/components/Header';
import SvgDefs from '../../../common/components/SvgDefs';
import CalendarWrapper from '../CalendarWrapper';
import {
  CalendarProvider,
  EventDetailsProvider,
  ListingsFilterProvider,
  UnavailableEditorProvider,
} from '../../hooks';

import imagesSvg from './images.svg';
import { InstabookEditorProvider } from '../../hooks/useInstabookEditor';

/**
 * @type {React.FC<CalendarPageProps>}
 */
const CalendarPage = () => {
  const [overlayOpen, setOverlayOpen] = React.useState(false);
  const { boats } = useBoats();
  const dispatch = useDispatch();

  useEffect(() => {
    // TODO: perhaps we should store the loaded state separately on the redux state
    // Only load the boats if they have not been loaded
    if (boats.length === 0) {
      dispatch(getBoatsCall({
        page_size: 100,
        minimal: true,
        show_removed: false,
      }));
    }
  }, [boats.length, dispatch]);
  const instabookMatch = useRouteMatch(PATHS.INSTABOOK);
  const pageTitle = instabookMatch ? TITLE.INSTABOOK : TITLE.CALENDAR;

  return (
    <>
      <SvgDefs href={imagesSvg} />
      <Layout>
        <Helmet>
          <title lang="en">
            {pageTitle}
          </title>
        </Helmet>

        <Header
          classNameModifier={classNames('bordered', 'fixed', { overlayOpen })}
          navigationVariant="owner-calendar-inbox"
          withSearchBar={false}
        />
        <ListingsFilterProvider>
          <CalendarProvider
            fallback={<LoadingSpinner classNameModifier="fullHeight">Loading Data</LoadingSpinner>}
          >
            <UnavailableEditorProvider>
              <InstabookEditorProvider>
                <EventDetailsProvider>
                  <CalendarWrapper toggleMobileMenu={(value) => setOverlayOpen(value)} />
                </EventDetailsProvider>
              </InstabookEditorProvider>
            </UnavailableEditorProvider>
          </CalendarProvider>
        </ListingsFilterProvider>
      </Layout>
    </>
  );
};

export default CalendarPage;

/**
 * @typedef {import('./types').CalendarPageProps} CalendarPageProps
 */
