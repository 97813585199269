import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../../common/helpers';

import s from './InstabookTripDetails.module.scss';

type InstabookTripDetailsProps = {
  withTitle?: boolean;
};
const InstabookTripDetails: FunctionComponent<InstabookTripDetailsProps> = (
  { children, withTitle = false },
) => (
  <div className={s.root}>
    {withTitle && (
      <h2 className={s.title}>
        Trip Details
      </h2>
    )}
    <div className={getClassNameFor(s, 'card', classNames({ withTitle }))}>
      {children}
    </div>
  </div>
);

export default InstabookTripDetails;
