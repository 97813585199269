import React, { useMemo } from 'react';

import type { ImmutableTrip } from 'src/types/trips/Trips';
import useCustomOfferExpiry from '../../hooks/useCustomOfferExpiry';
import { userIsRenter } from '../../../inbox/helpers';
import s from './CustomOfferExpiry.module.scss';
import Icon from '../IconDS22';

type CustomOfferExpiryProps = {
  trip?: ImmutableTrip;
};

const CustomOfferExpiry: React.FC<CustomOfferExpiryProps> = ({
  trip,
}) => {
  const isRenter = useMemo(() => userIsRenter(trip), [trip]);
  const renterName = useMemo(() => trip?.getIn(['renter', 'first_name']), [trip]);
  const {
    confirmationPeriod,
    formattedExpirationDateInBoatTimezone,
    formattedExpirationDateInBrowserTimeZone,
    offerExpired,
    renderTimeZoneInfo,
  } = useCustomOfferExpiry(trip);
  const getUserRoleDependantFormattedExpirationDate = (forRenter: boolean) => (forRenter
    // Output expiry date for renter in browser time zone
    ? formattedExpirationDateInBrowserTimeZone
    // Output expiry date for owner in boat time zone
    : formattedExpirationDateInBoatTimezone);

  return (
    <div className={s.root}>
      <Icon
        className={s.icon}
        id="clock"
        size="m"
      />
      <h6 className={s.title}>
        {`Offer Expire${offerExpired ? 'd on' : 's'} `}
        {renderTimeZoneInfo
          /* Owners see a date in the title in the boat timezone
             while renters see it in their browser timezone */
          ? getUserRoleDependantFormattedExpirationDate(isRenter)
          : formattedExpirationDateInBrowserTimeZone}
      </h6>
      <div className={s.description}>
        {renderTimeZoneInfo && (
          <p className={s.dateInBoatTimezone}>
            {/* Renters see a date here in the boat timezone
                while owners see it in their browser timezone */}
            {getUserRoleDependantFormattedExpirationDate(!isRenter)}
          </p>
        )}
        {!offerExpired && (
          <p className={s.validForInfo}>
            The offer is valid for a limited time.
            {` ${isRenter ? 'You have' : `${renterName} has`} ${confirmationPeriod} `}
            to confirm this booking before it expires.
          </p>
        )}
      </div>
    </div>
  );
};

export default CustomOfferExpiry;
