import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import FormError from 'src/common/components/FormError';
import useLoggedInRedirect from 'src/auth/hooks/useLoggedInRedirect';
import RegisterFormFieldset from 'src/auth/components/form/Register/fieldset';
import s from './Register.module.scss';

const title = 'Create Account';

const Register: FC<RegisterProps> = ({
  error,
  dirtySinceLastSubmit,
}) => {
  useLoggedInRedirect();

  return (
    <div className={s.container}>
      <Helmet>
        <title lang="en">
          {title}
        </title>
      </Helmet>

      <h1 className={s.title}>{title}</h1>

      {!dirtySinceLastSubmit && <FormError error={error} />}

      <RegisterFormFieldset />

    </div>
  );
};

type RegisterProps = {
  dirtySinceLastSubmit?: boolean;
  error?: string;
};

export default Register;
