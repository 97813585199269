/* eslint-disable react/prop-types */
import React, { FC, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import ReactModal from 'react-modal';
import { Map } from 'immutable';

import { getClassNameFor } from '../../helpers';
import { close } from '../../ducks/zippy';
import CloseButton from '../CloseButton';
import s from './Modal.module.scss';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import Title from './Title';
import Description from './Description';

const mapStateToProps = (
  { zippies }: { zippies: Map<string, boolean> },
  { modalOpen, name }: { modalOpen: boolean; name: string; closeModal: () => void },
) => ({
  modalOpen: modalOpen || zippies.get(name),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { name, closeModal }: { name: string; closeModal: () => void },
) => ({
  closeModal: () => (typeof closeModal === 'function' ? closeModal() : dispatch(close(name))),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const modalModifiers = [
  'alignVertically',
  'calendar', // from BlockTime "unavailableEditor" and "InstabookEditor" modals
  'captainOption',
  'customOfferExpiry',
  'dates',
  'instabookListingSelector',
  'instabookTips',
  'locationModal',
  'menuItems',
  'nestedMenu',
  'photo',
  'singleBoat',
  'unavailableEditor',
] as const;

export type ModalProps = PropsFromRedux & {
  id?: string;
  name: string;
  modalOpen: boolean;
  closeModal: () => void;
  onAfterOpen?: () => void;
  label: string;
  noExit?: boolean;
  classNameModifier?: string;
  extraClassName?: string;
  disableOverlayClick?: boolean;
  shouldReturnFocusAfterClose?: boolean;
  contentRef?: (node: HTMLDivElement) => void;
};

export type CompoundModal = FC<ModalProps> & {
  Header: typeof Header;
  Body: typeof Body;
  Footer: typeof Footer;
  Title: typeof Title;
  Description: typeof Description;
};

const Modal: CompoundModal = ({
  id,
  modalOpen,
  closeModal,
  onAfterOpen = () => { },
  label,
  children,
  classNameModifier,
  extraClassName = '',
  noExit = false,
  disableOverlayClick = false,
  shouldReturnFocusAfterClose = true,
  contentRef,
}) => {
  useEffect(() => {
    ReactModal.setAppElement('#app');
  }, []);
  const shouldCloseOnOverlayClick = !noExit && !disableOverlayClick;
  const shouldCloseOnEsc = !noExit && !disableOverlayClick;
  return (
    <ReactModal
      id={id}
      isOpen={modalOpen}
      contentLabel={label}
      onRequestClose={closeModal}
      onAfterOpen={onAfterOpen}
      className={`${getClassNameFor(s, 'content', classNameModifier)} ${extraClassName}`}
      overlayClassName={getClassNameFor(s, 'overlay', classNameModifier)}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      contentRef={contentRef}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
    >
      {children}
      {!noExit && (
        <CloseButton
          classNameModifier={classNameModifier}
          onClick={closeModal}
        />
      )}
    </ReactModal>
  );
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.Title = Title;
Modal.Description = Description;

const connectedComponent = connector(Modal);

export default connectedComponent;
