/**
 * Validates if a string represents a valid USA ZIP code.
 * Accepts both 5-digit (e.g., "12345") and 5+4 format (e.g., "12345-6789").
 *
 * @param value - The string to validate as a ZIP code
 * @returns {undefined | boolean} Returns true if valid or empty, false if invalid
 *
 * @example
 * isValidUsaZipCode("12345")      // returns true
 * isValidUsaZipCode("12345-6789") // returns true
 * isValidUsaZipCode("1234")       // returns false
 * isValidUsaZipCode("")           // returns true
 */
const isValidUsaZipCode = (value: string) => {
  // The regex is specifically designed for US ZIP codes.
  // It matches both 5-digit ZIP codes and the extended 5+4 format.
  const usZipRegex = /^\d{5}(?:-\d{4})?$/;
  return usZipRegex.test(value);
};

export default isValidUsaZipCode;
