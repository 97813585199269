import React, { FC } from 'react';

import { getBoatHeadline } from '../../../common/helpers';
import { FullBoatDetail } from '../../../types/boat/BoatDetail';

import s from './InstabookListingHeader.module.scss';

type Boat = Pick<FullBoatDetail, 'photo_url' | 'headline'>;

export type InstabookListingHeaderProps = {
  boat: Boat;
};

const InstabookListingHeader: FC<InstabookListingHeaderProps> = ({ boat }) => (
  <div className={s.root}>
    <h3 className={s.headline}>
      {getBoatHeadline(boat)}
    </h3>
  </div>
);

export default InstabookListingHeader;
