import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '../../presentation/Card';
import { modified as tripModified, offerModified } from '../../../helpers';
import { open } from '../../../../common/ducks/zippy';
import { BOAT_FIELD, FORMS, UNSENT_CHANGES_WARNING } from '../../../constants';
import Vessel from '../../Vessel';
import EditButton from '../../EditButton';
import Modal from '../../../../common/components/Modal';
import NotificationMessage from '../../../../common/components/NotificationMessage';
import VesselForm from './form';
import { offerBoatSelector } from './selector';
import { ImmutableTrip } from '../../../../types/trips/Trips';
import { getBoat, getOffer, getUser } from '../../../../common/utils/reduxStoreSelectors';

type Props = {
  trip: ImmutableTrip;
  checkTripModification?: boolean;
  readOnly?: boolean;
};

const VesselCard: FC<Props> = ({ checkTripModification, trip, readOnly }) => {
  const user = useSelector(getUser);
  const offer = useSelector(getOffer);
  const boat = useSelector(getBoat);
  const { userBoats } = offerBoatSelector({ user, offer, trip });
  const dispatch = useDispatch();
  const openModal = () => dispatch(open(FORMS.VESSEL));
  const tripIsModified = checkTripModification && tripModified(trip, [BOAT_FIELD]);
  const offerIsModified = offerModified(offer, trip, [BOAT_FIELD]);
  const modified = offerIsModified || tripIsModified;

  return (
    <Card
      highlighted={modified}
      classNameModifier={!readOnly ? 'hoverEffect' : ''}
    >
      {offerIsModified && (
        <NotificationMessage outdent={false}>
          {UNSENT_CHANGES_WARNING}
        </NotificationMessage>
      )}
      <Vessel
        trip={trip}
        vessel={boat}
        classNameModifier={modified ? 'modified' : ''}
      />

      {!readOnly && userBoats.size > 1 && (
        <>
          <EditButton openModal={openModal} />
          <Modal
            name={FORMS.VESSEL}
            label="Vessel Form"
          >
            <VesselForm
              trip={trip}
              userBoats={userBoats}
              modified={offerIsModified}
              initialValues={{
                boat: boat.get('id'),
              }}
            />
          </Modal>
        </>
      )}
    </Card>
  );
};

export default VesselCard;
