import PropTypes from 'prop-types';
import React from 'react';

import { getClassNameFor } from '../../helpers';
import Avatar from '../Avatar';
import Quote from '../Quote';
import Svg from '../Svg';
import s from './Comment.module.scss';

const Comment = ({ title, children, user, classNameModifier }) => (
  <div className={getClassNameFor(s, 'root', classNameModifier)}>
    {typeof user === 'object' ? (
      <Avatar
        user={user}
      />
    ) : (
      <Svg
        href="#dolphin-small"
        className={s.icon}
      />
    )}
    <div className={s.content}>
      <h3 className={s.subTitle}>
        {title}
      </h3>
      <Quote classNameModifier="pre-line">
        {children}
      </Quote>
    </div>
  </div>
);

Comment.propTypes = {
  user: PropTypes.object,
  title: PropTypes.node,
  children: PropTypes.node,
  classNameModifier: PropTypes.string,
};

export default Comment;
