import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import isInternalLink from 'src/common/utils/isInternalLink';
import ExternalLink from '../ExternalLink';

type Props = {
  activeClassName?: string;
  className?: string;
  to: string;
  openInNewTab?: boolean;
  'data-test'?: string;
};

const UniversalLink: FC<Props> = ({
  children,
  className,
  activeClassName,
  openInNewTab = false,
  to,
  'data-test': dataTest,
}) => (isInternalLink(to) ? (
  <NavLink
    to={to}
    className={className}
    activeClassName={activeClassName}
    data-test={dataTest}
  >
    {children}
  </NavLink>
) : (
  <ExternalLink
    to={to}
    className={className}
    openInNewTab={openInNewTab}
    data-test={dataTest}
  >
    {children}
  </ExternalLink>
));

export default UniversalLink;
