import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useHistory } from 'react-router-dom';

import Icon from 'src/common/components/IconDS22';
import { unpackApiError } from '../../../../common/helpers';
import { ReduxState } from '../../../../types/reduxState';
import Button from '../../../../common/components/Button';
import { MFAMethod } from '../../../../types/common/MFAMethods';
import { resendMFACodeAction, setMFAMethod } from '../../../../common/ducks/user';
import { PATHS } from '../../../../common/constants';
import FormError from '../../../../common/components/FormError';

import s from './ChooseMFAMethod.module.scss';

const title = 'Select Authentication Method';
const subtitle = 'Please select an Authentication method to secure your account.';
const methodLabels = {
  totp: 'Authenticator App',
  sms: 'SMS Token',
  push: 'Push Notification',
};

const ChooseMFAMethod: FC = () => {
  const dispatch = useDispatch<ThunkDispatch<ReduxState, {}, Action>>();
  const { push } = useHistory();
  const currentMFAMethods = useSelector<ReduxState, MFAMethod[] | null | undefined>(({ user }) => user.get('userMFAMethods', null));
  const [isSubmitting, setSubmitting] = useState(false);
  const [resendError, setResendError] = useState<string | null>(null);
  const onMethodClick = useCallback(async (method: MFAMethod) => {
    dispatch(setMFAMethod(method));
    if (method !== 'totp') {
      setSubmitting(true);
      dispatch(resendMFACodeAction({ method }))
        .then(() => {
          setSubmitting(false);
          if (resendError) setResendError(null);
          push(PATHS.OTP);
        })
        .catch(async (error) => {
          setSubmitting(false);
          const { detail } = await unpackApiError(error);
          setResendError(detail || 'Error resending code');
        });
    } else {
      push(PATHS.OTP);
    }
  }, [dispatch, push, resendError]);

  useEffect(() => {
    if (!(currentMFAMethods && currentMFAMethods.length)) {
      push(PATHS.LOGIN);
    }
  }, [currentMFAMethods, push]);
  return (
    <div className={s.container}>
      <Helmet>
        <title lang="en">{title}</title>
      </Helmet>
      <div className={s.wrapper}>
        <div className={s.headerCopy}>
          <h1 className={s.title}>{title}</h1>
          <p className={s.message}>{subtitle}</p>
        </div>
        <FormError error={resendError} />
        <div className={s.buttonContainer}>
          {currentMFAMethods && currentMFAMethods.length > 1 && currentMFAMethods.map(option => (
            <Button
              fullWidth
              classNameModifier="tertiary"
              key={option}
              submitting={option === 'sms' && isSubmitting}
              disabled={option === 'sms' && isSubmitting}
              type="button"
              onClick={() => { onMethodClick(option); }}
            >
              {methodLabels[option]}
              <Icon id={option === 'sms' ? 'chat' : 'qr-code'} size="l" />
            </Button>
          ))}
        </div>
      </div>
      <p className={s.support}>Having trouble? <a href="mailto:support@getmyboat.com">Contact support</a></p>
    </div>
  );
};

export default ChooseMFAMethod;
