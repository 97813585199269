import React, { FC, ReactNode, Ref } from 'react';

import { getClassNameFor } from '../../helpers';

import s from './FilterContainer.module.scss';

type FilterContainerProps = {
  children: ReactNode;
  classNameModifier?: string;
  filterContainerRef?: Ref<HTMLDivElement>;
};

const FilterContainer: FC<FilterContainerProps> = ({
  classNameModifier,
  children,
  filterContainerRef,
}) => (
  <div
    className={getClassNameFor(s, 'root', classNameModifier)}
    ref={filterContainerRef}
  >
    {children}
  </div>
);

export default FilterContainer;
