import { useCallback, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { trackEvent } from 'src/common/tracking';
import { PATHS } from '../../constants';

/**
 * Extracts the threadId from an inbox path
 */
export const extractThreadIdFromPath = (path: string): string => {
  if (path.includes(PATHS.INBOX)) {
    // e.g. `/inbox/threadId/messages`
    return path.split('/')[2] ?? '';
  }
  return '';
};

/**
 * Returns true if both the current and previous paths are related to the same inbox thread
 * @param {string} currentPath
 * @param {string} previousPath
 * @return {boolean}
 */
const inboxStatefulRoutes = (currentPath: string, previousPath: string = '') => {
  if (currentPath.includes(PATHS.INBOX) && previousPath.includes(PATHS.INBOX)) {
    const previousThreadId = extractThreadIdFromPath(previousPath);
    const currentThreadId = extractThreadIdFromPath(currentPath);
    return previousThreadId === currentThreadId;
  }
  return false;
};

/**
 * List of predicates that should be evaluated before reloading on navigation.
 */
const STATEFUL_ROUTES_CHECKS = [
  inboxStatefulRoutes,
  // Covers the booking inquiry flow
  (path: string) => path.includes(PATHS.INQUIRY),
  // Covers the account creation flow
  (path: string) => path.includes(PATHS.REGISTER),
];

export const isStatefulRoute = (
  currentPath: string,
  previousPath?: string,
): boolean => STATEFUL_ROUTES_CHECKS.some(
  predicate => predicate(currentPath, previousPath),
);

/**
 * Reload app upon navigation if there is a new version available.
 */
const useSeamlessAppReload = (
  shouldReload: boolean,
  gaEventCategory: string,
  gaEventLabel: string,
) => {
  const history = useHistory();
  const { pathname: initialPathname } = useLocation();
  const previousPath = useRef(initialPathname);

  const reloadIfNewVersionIsAvailable = useCallback(({ pathname }) => {
    if (typeof window !== 'undefined' && shouldReload && !isStatefulRoute(pathname, previousPath.current)) {
      trackEvent('Reloading On Navigation', {
        event_category: gaEventCategory,
        event_label: gaEventLabel,
      });
      window.location.reload();
    }
    previousPath.current = pathname;
  }, [shouldReload, gaEventCategory, gaEventLabel]);

  useEffect(
    () => history.listen(reloadIfNewVersionIsAvailable),
    [history, reloadIfNewVersionIsAvailable],
  );
};

export default useSeamlessAppReload;
