import React, { FC, useEffect } from 'react';

import { trackEvent } from 'src/common/tracking';
import NotificationMessage from '../../../common/components/NotificationMessage';

type InPageWarningProps = {
  message: string;
};

const InPageWarning: FC<InPageWarningProps> = ({ message }) => {
  useEffect(() => {
    trackEvent('Display Warning', {
      event_category: 'Booking Inquiry',
      event_label: message,
    });
  }, [message]);

  return (
    <NotificationMessage
      classNameModifier="alignTop"
      outdent={false}
    >
      {`${message} `}
      Keep going anyway and we will find you something else.
    </NotificationMessage>
  );
};

export default InPageWarning;
