import React, { FC } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { PAGES } from '../../../constants';
import { BackButton } from '../../../../common/components/BackButton';
import { siblingRoute } from '../../../../common/utils/routing';
import CloseButton from '../../../../common/components/CloseButton';
import ExperienceForm from '../../form/Experience';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import s from './Experience.module.scss';

const nextPage = PAGES.EXTRAS;

const Experience: FC = () => {
  const location = useLocation();
  const { goBack } = useHistory();
  const { search, pathname } = location;
  // TODO: pull this logic out? Make siblingRoute preserve search by default?
  const nextPath = siblingRoute(pathname, nextPage) + search;
  const returnUrl = getReturnUrl(location);
  return (
    <div className={s.container}>
      <Helmet>
        <title>
          Experience
        </title>
      </Helmet>
      <h1 className={s.title}>
        Captain not included.
      </h1>
      <div className={s.message}>
        <p>
          For trips without a captain or guide please provide some information about your vessel
          operator’s relevant experience and qualifications.
          You can <Link to={nextPath}>skip this step</Link> if experience is not really necessary.
        </p>
      </div>
      <ExperienceForm />
      <BackButton onClick={goBack} />
      <CloseButton backUrl={returnUrl} />
    </div>
  );
};

export default Experience;
