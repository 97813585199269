import captureException from 'src/common/utils/captureException';

export { configureScope } from '@sentry/browser';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchCatch = (response: Response | unknown) => {
  if (response instanceof Response) {
    captureException(new Error('Response Thrown'), {
      extra: {
        url: response.url,
        status: response.status,
        type: response.type,
      },
    });
  }
  // TODO: make this return useful information.
};
