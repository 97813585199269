import { CAPTAIN_OPTION_KEYS } from '../../common/constants';

const serializeCaptainOptions = (captainOption: string) => {
  const options: { captained: boolean; captain_cost_included: boolean | null } = {
    captained: false,
    captain_cost_included: null,
  };

  switch (captainOption) {
    case CAPTAIN_OPTION_KEYS.ARRANGED_SEPARATELY: {
      // Case when captain arranged separately
      options.captained = true;
      options.captain_cost_included = false;
      break;
    }
    case CAPTAIN_OPTION_KEYS.NOT_PROVIDED: {
      // Case when captain is not provided
      options.captained = false;
      options.captain_cost_included = null;
      break;
    }
    default: {
      // Case when captain is provided
      options.captained = true;
      options.captain_cost_included = true;
      break;
    }
  }

  return options;
};

export default serializeCaptainOptions;
