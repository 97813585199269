import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';

import ExternalLink from '../../../../common/components/ExternalLink';
import { PATHS } from '../../../../common/constants';
import { nextUrl, authRedirect } from '../../../../common/utils/routing';
import useLoggedInRedirect from '../../../hooks/useLoggedInRedirect';
import LoginForm from '../../form/Login';
import s from './Login.module.scss';

const title = 'Sign in';
const Login: FC = () => {
  const { search, hash } = useLocation();
  const { push } = useHistory();
  const nextPage = useCallback(
    () => {
      authRedirect(nextUrl(search, hash), push);
    },
    [hash, push, search],
  );
  const onSubmitSuccess = () => nextPage();
  useLoggedInRedirect();

  return (
    <div className={s.container}>
      <Helmet>
        <title lang="en">{title}</title>
      </Helmet>

      <h1 className={s.title}>{title}</h1>

      <LoginForm
        signUpPath={`${PATHS.REGISTER}${search}`}
        onSubmitSuccess={onSubmitSuccess}
      />

      <p className={s.message}>
        This site is protected by reCAPTCHA and the Google
        {' '}
        <ExternalLink to="https://policies.google.com/privacy">
          Privacy Policy
        </ExternalLink>
        {' and '}
        <ExternalLink to="https://policies.google.com/terms">
          Terms of Service
        </ExternalLink>
        {' '}
        apply.
      </p>
    </div>
  );
};

export default Login;
