import { captureMessage as sentryCaptureMessage } from '@sentry/browser';
import type { CaptureContext } from '@sentry/types';

const captureMessage = (message: string, captureContext: CaptureContext) => {
  if (__SENTRY_DSN__) {
    sentryCaptureMessage(message, captureContext);
  } else {
    // This is for development only
    // eslint-disable-next-line no-console
    console.info(message, captureContext);
  }
};

export default captureMessage;
