import React, { FC } from 'react';
import classNames from 'classnames';
import { addMonths, subMonths } from 'date-fns';

import { BOOKING_MOTIVATOR_STATE } from '../../constants';
import { getClassNameFor } from '../../helpers';
import { newTZDate, todayDate, tomorrowDate } from '../../utils/dateHelpers';

import { useMediaQuery, sharedMediaQueries } from '../../hooks';
import useBookingMotivatorsContext from '../../hooks/useBookingMotivators';
import { MAX_NEXT_MONTHS_INSTABOOK_OWNER, MAX_PREVIOUS_MONTHS } from '../../../calendar/hooks';

import Button from '../Button';
import Svg from '../Svg';
import ReactDayPicker from '../ReactDayPicker';

import s from './ReactDayPickerWrapper.module.scss';

type ReactDayPickerWrapperProps = {
  allowUnavailableDateSelection?: boolean;
  backHandler?: (prevDates: Date[]) => void;
  classNameModifier?: string;
  continueHandler?: () => void;
  formValueDates: Date[];
  selectedDays: Date[] | [];
  setSelectedDays: (days: Date[] | []) => void;
};

const { UNAVAILABLE } = BOOKING_MOTIVATOR_STATE;

const ReactDayPickerWrapper: FC<ReactDayPickerWrapperProps> = ({
  allowUnavailableDateSelection,
  backHandler,
  classNameModifier,
  continueHandler,
  formValueDates,
  selectedDays,
  setSelectedDays,
}) => {
  const {
    bookingMotivators,
  } = useBookingMotivatorsContext();

  const isMediumOrLarger = useMediaQuery(sharedMediaQueries.m);

  const highlightedDays: Date[] = [];
  const blockedDays: Date[] = [];

  // Loop through bookingMotivators object and separate dates based on motivator
  // This date-picker requires array structure for each modifier set
  Object.keys(bookingMotivators).forEach(key => {
    if (`${bookingMotivators[key]}` === UNAVAILABLE) {
      blockedDays.push(newTZDate(key));
    } else {
      highlightedDays.push(newTZDate(key));
    }
  });

  const handleOnSelect = (days: Date[] | []) => setSelectedDays(days);
  const isSelectedDaysEmpty = selectedDays === undefined || selectedDays.length < 1;

  const hasPastDates = selectedDays.some(d => d < todayDate());
  const hasFutureDates = selectedDays.some(d => d > todayDate());

  const futureDate = addMonths(new Date(), MAX_NEXT_MONTHS_INSTABOOK_OWNER);
  const pastYears = subMonths(new Date(), hasPastDates ? MAX_PREVIOUS_MONTHS : 0);

  const defaultMonth = selectedDays[0] ?? todayDate();

  return (
    <div className={getClassNameFor(s, 'root', classNameModifier)}>
      <ReactDayPicker
        mode="multiple"
        defaultMonth={defaultMonth}
        selectedDays={selectedDays}
        onSelect={handleOnSelect}
        fromDate={pastYears}
        toDate={futureDate}
        modifiers={{ highlighted: highlightedDays, blocked: blockedDays }}
        disabled={{ before: tomorrowDate() }}
        numberOfMonths={isMediumOrLarger ? 2 : 1}
        setSelectedDays={setSelectedDays}
        isSelectedDaysEmpty={isSelectedDaysEmpty}
        hasPastDates={hasPastDates}
      />
      <div className={s.buttonsWrapper}>
        {!hasFutureDates && (
          <p className={s.warning}>Please select at least one future date to proceed.</p>
        )}
        <div className={s.buttons}>
          <Button
            type="button"
            classNameModifier="secondary paired"
            onClick={() => {
              if (backHandler) {
                backHandler(formValueDates);
              }
            }}
            fullWidth
          >
            <Svg
              className={s.iconBack}
              href="#icon-back"
            />
            Back
          </Button>
          <Button
            type="button"
            classNameModifier={classNames('paired', classNameModifier)}
            disabled={!allowUnavailableDateSelection || isSelectedDaysEmpty || !hasFutureDates}
            onClick={() => {
              if (continueHandler && selectedDays) {
                continueHandler();
              }
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReactDayPickerWrapper;
