import React, { FC, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import moment, { Moment, Duration as MomentDuration } from 'moment';

import { BOOKING_MOTIVATOR_STATE } from '../../constants';
import { Keys as BookingMotivatorKeys } from '../../types';
import { INSTABOOK_EDITOR_DATE_FIELD } from '../../../calendar/constants';
import { DATES } from '../../../booking-inquiry/constants';
import { getClassNameFor } from '../../helpers';
import { getDatesInRange } from '../../../calendar/helpers';
import { formatDate, newTZDate } from '../../utils/dateHelpers';
import useBookingMotivatorsContext from '../../hooks/useBookingMotivators';
import DurationReturn from '../../../booking-inquiry/components/DurationReturn';
import BookingMotivators from '../BookingMotivators';
import DatePicker from '../DatePicker';
import Button from '../Button';
import s from './DatePickerPanel.module.scss';
import { ALTERNATIVE_DATE_FIELD, ALTERNATIVE_RETURN_DATE_FIELD } from '../../../inbox/constants';
import Icon from '../IconDS22';

const { UNAVAILABLE } = BOOKING_MOTIVATOR_STATE;

type DatePickerPanelProps = {
  allowUnavailableDateSelection?: boolean;
  backHandler?: () => void;
  continueHandler?: () => void;
  fieldName: BookingMotivatorKeys;
  open?: boolean;
  tripLength?: MomentDuration;
  portalId?: string;
  classNameModifier?: string;
  isAlternativeDate?: boolean;
  isAlternativeReturnDate?: boolean;
  renderMotivators?: boolean;
  isDayBlocked?: (day: Moment) => boolean;
  isNight?: boolean;
  initialDate?: Moment;
};

const DatePickerPanel: FC<DatePickerPanelProps> = ({
  allowUnavailableDateSelection = false,
  backHandler,
  continueHandler,
  fieldName,
  open = false,
  tripLength,
  portalId,
  isAlternativeDate = false,
  isAlternativeReturnDate = false,
  classNameModifier,
  renderMotivators = true,
  isDayBlocked: dayIsBlocked,
  isNight = false,
  initialDate,
}) => {
  const {
    canGoBack,
    getMotivators,
    handleDateChange,
    handleMonthChange,
    isDayHighlighted,
    isOutsideRange,
    renderCalendarDay,
    setEditingAltDate,
    blockedTimes,
    loading,
    state: {
      [fieldName]: { date, intervalMotivators },
      [ALTERNATIVE_DATE_FIELD]: { date: alternativeDate },
      [ALTERNATIVE_RETURN_DATE_FIELD]: { date: alternativeReturnDate },
      ...restFields
    },
  } = useBookingMotivatorsContext();

  const [{ date: selectedDate1 }, { date: selectedDate2 }] = Object.values(restFields);

  useEffect(() => {
    if (open && (isAlternativeDate || isAlternativeReturnDate)) {
      const editingAltDate = isAlternativeDate
        ? ALTERNATIVE_DATE_FIELD
        : ALTERNATIVE_RETURN_DATE_FIELD;
      setEditingAltDate(editingAltDate);
    }
  }, [open, isAlternativeDate, isAlternativeReturnDate, setEditingAltDate]);

  const isDayBlocked = useCallback(
    (day: Moment): boolean => [selectedDate1, selectedDate2].some(
      (blockedDate) => blockedDate && day.isSame(blockedDate, 'day'),
    ) || !!dayIsBlocked?.(day),
    [dayIsBlocked, selectedDate1, selectedDate2],
  );

  const datesInRange = getDatesInRange(
    alternativeDate,
    alternativeReturnDate,
    tripLength?.asDays() ?? 0,
  );

  let motivators = intervalMotivators;
  if (isAlternativeDate || isAlternativeReturnDate) {
    motivators = datesInRange.flatMap(getMotivators);
  }

  const isOutsideAlternativeRange = useCallback(
    (dateMoment) => {
      if (moment.isMoment(dateMoment)) {
        dateMoment.isAfter(moment(alternativeReturnDate), 'day');
      } else {
        moment(dateMoment).isAfter(moment(alternativeReturnDate), 'day');
      }
    },
    [alternativeReturnDate],
  );

  const isOutsideAlternativeReturnRange = useCallback(
    (dateMoment) => {
      if (moment.isMoment(dateMoment)) {
        dateMoment.isBefore(moment(alternativeDate), 'day');
      } else {
        moment(dateMoment).isBefore(moment(alternativeDate), 'day');
      }
    },
    [alternativeDate],
  );

  let outsideRange = isOutsideRange;

  if (isAlternativeDate && alternativeReturnDate) {
    outsideRange = (d) => isOutsideAlternativeRange(d) || isOutsideRange(d);
  }
  if (isAlternativeReturnDate) {
    outsideRange = (d) => isOutsideAlternativeReturnRange(d) || isOutsideRange(d);
  }

  let dayHighlighted = (dateMoment: Moment) => isDayHighlighted(dateMoment.toDate(), fieldName);

  if (isAlternativeDate || isAlternativeReturnDate) {
    dayHighlighted = (dateMoment) => isDayHighlighted(dateMoment.toDate(), fieldName)
      || datesInRange.some((d) => moment(d).isSame(dateMoment, 'day'));
  }

  let initialTitle = 'Select your preferred date.';
  if (fieldName === DATES.SECONDARY || fieldName === DATES.TERTIARY) {
    initialTitle = 'Select an additional option for your trip date.';
  } else if (fieldName === ALTERNATIVE_DATE_FIELD) {
    initialTitle = 'Select your new preferred date.';
  } else if (fieldName === ALTERNATIVE_RETURN_DATE_FIELD) {
    initialTitle = 'Select your new return date.';
  }

  const fallbackTitle = useMemo(() => {
    switch (fieldName) {
      case DATES.PRIMARY: {
        return 'Preferred Date';
      }
      case INSTABOOK_EDITOR_DATE_FIELD: {
        return 'Date this trip can be booked';
      }
      default:
        return 'Alternative Date';
    }
  }, [fieldName]);

  const renderCalendarInfo = () => (
    renderMotivators && (
      <BookingMotivators
        initialTitle={initialTitle}
        modifier="withinDatePicker"
        selectedDate={date}
        selectedMotivators={motivators}
        duration={tripLength?.asDays() ?? 0}
        blockedTimes={blockedTimes}
        loading={loading}
      />
    ));

  const content = (
    <div className={getClassNameFor(s, 'root', classNames(classNameModifier, { open }))}>
      {open && (
        <>
          {!portalId && (
            <>
              <h2 className={s.title}>
                {(date && formatDate(newTZDate(date), 'd MMM yyyy')) || fallbackTitle}
              </h2>

              <DurationReturn
                classNameModifier="withinDatePicker"
                date={date}
                tripLength={tripLength}
                isNight={isNight}
              />
            </>
          )}
          <div className={getClassNameFor(s, 'datePickerWrapper', classNames(classNameModifier))}>
            <DatePicker
              isDayBlocked={isDayBlocked}
              isDayHighlighted={dayHighlighted}
              isOutsideRange={(dateMoment: Moment) => outsideRange(dateMoment.toDate())}
              noNavPrevButton={!canGoBack}
              onDateChange={(dateMoment: Moment | null) => (
                handleDateChange(dateMoment && dateMoment.toDate(), fieldName)
              )}
              onNextMonthClick={(dateMoment: Moment) => handleMonthChange(dateMoment.toDate())}
              onPrevMonthClick={(dateMoment: Moment) => handleMonthChange(dateMoment.toDate())}
              renderDayContents={(dateMoment: Moment) => renderCalendarDay(dateMoment.toDate())}
              renderCalendarInfo={renderCalendarInfo}
              initialDate={initialDate}
            />
          </div>

          <div className={s.buttons}>
            <Button
              type="button"
              classNameModifier="secondary"
              onClick={() => backHandler && backHandler()}
              fullWidth
            >
              <Icon id="caret-left" />
              Back
            </Button>
            <Button
              type="button"
              classNameModifier={classNames('paired', classNameModifier)}
              disabled={!allowUnavailableDateSelection && motivators.includes(UNAVAILABLE)}
              onClick={() => continueHandler && continueHandler()}
              data-test="continue"
              fullWidth
            >
              Continue
            </Button>
          </div>
        </>
      )}
    </div>
  );

  const targetEl = typeof window !== 'undefined' && portalId ? document.getElementById(portalId) : null;

  if (targetEl) {
    return createPortal(content, targetEl);
  }

  return content;
};

export default DatePickerPanel;
