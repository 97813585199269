import type { Currency } from 'src/types/common/Currencies';
import { DEFAULT_CURRENCY } from '../currencies';

export const SET_REQUEST_CURRENCY = 'common/currency/SET_REQUEST_CURRENCY';

export const setRequestCurrency = (currency: Currency) => (
  { type: SET_REQUEST_CURRENCY, currency }
);

type Action = ReturnType<typeof setRequestCurrency>;

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (requestCurrency = DEFAULT_CURRENCY, action: Action) => {
  switch (action.type) {
    case SET_REQUEST_CURRENCY:
      return action.currency;
    default:
      return requestCurrency;
  }
};
