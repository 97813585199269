import React, { FC } from 'react';

import { getClassNameFor } from '../../helpers';
import SpanButton from '../SpanButton';

import s from './ClearButton.module.scss';

type ClearButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  classNameModifier?: string;
};

const ClearButton: FC<ClearButtonProps> = ({
  children = 'Clear changes',
  classNameModifier = '',
  onClick,
  disabled = false,
}) => (
  <SpanButton
    className={getClassNameFor(s, 'root', classNameModifier)}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </SpanButton>
);

export default ClearButton;
