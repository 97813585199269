import React, { FC, useCallback } from 'react';
import { getClassNameFor } from 'src/common/helpers';
import { format } from 'date-fns';
import { newTZDate } from 'src/calendar/helpers';
import { useDispatch } from 'react-redux';
import { sharedMediaQueries, useMediaQuery } from 'src/common/hooks';
import Icon from 'src/common/components/IconDS22';
import ReactDayPicker from 'src/common/components/ReactDayPicker';
import Modal from 'src/common/components/Modal';
import { open, close } from 'src/common/ducks/zippy';
import styles from './DateField.module.scss';

type Props = {
  id: string;
  value: string;
  fromDate?: Date;
  toDate?: Date;
  onDateSelect: (date: string) => void;
  placeholder?: string;
  dateFormat?: string;
  outputDateFormat?: string;
  classNameModifier?: 'unavailableEditor';
  showIcon?: boolean;
};

const DateField:FC<Props> = ({
  id,
  value,
  fromDate,
  toDate,
  placeholder = 'Preferred date',
  dateFormat = 'MM/dd/yyyy',
  outputDateFormat = 'MM/dd/yyyy',
  onDateSelect,
  classNameModifier = '',
  showIcon = false,
}) => {
  const dispatch = useDispatch();
  const openModal = useCallback(() => dispatch(open(id)), [dispatch, id]);
  const closeModal = useCallback(() => dispatch(close(id)), [dispatch, id]);

  const isMediumScreen = useMediaQuery(sharedMediaQueries.m);

  const tzDate = newTZDate(value);
  const formattedValue = format(newTZDate(value), dateFormat);
  return (
    <div className={getClassNameFor(styles, 'root', classNameModifier)}>
      {showIcon && <Icon className={styles.icon} id="calendar" size="l" />}
      <input
        readOnly
        className={styles.input}
        placeholder={placeholder}
        value={formattedValue}
        onClick={openModal}
      />
      <Modal name={id} closeModal={closeModal} classNameModifier="calendar sizeSmall">
        <div className={styles.wrapper}>
          <ReactDayPicker
            mode="single"
            numberOfMonths={isMediumScreen ? 2 : 1}
            fromDate={fromDate}
            toDate={toDate}
            defaultMonth={tzDate}
            selectedDays={[tzDate]}
            onSelect={(date) => {
              if (date) {
                onDateSelect(format(date as Date, outputDateFormat));
                closeModal();
              }
            }}
            hasPastDates={false}
            showClearAllButton={false}
            withShadow={false}
          />
        </div>
      </Modal>
    </div>
  );
};

export default DateField;
