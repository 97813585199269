import { useSelector } from 'react-redux';
import { ReduxState } from '../../types/reduxState';

export const FEATURES = [
  'insurance',
  'instaspike--inquiry-flow',
] as const;

export type Feature = (typeof FEATURES)[number];

const getFeatureFlags = (state: ReduxState): Feature[] => state.featureFlags;

function useFeatureFlag(feature: Feature): boolean {
  const featureFlags = useSelector(getFeatureFlags);
  return featureFlags.includes(feature);
}

export default useFeatureFlag;
