export const DATES = {
  PRIMARY: 'preferred_date1',
  SECONDARY: 'preferred_date2',
  TERTIARY: 'preferred_date3',
} as const;

export const PAGES = {
  AGREEMENT: 'agreement',
  CAPTAIN: 'captain',
  CONTACT: 'contact',
  DATES: 'dates',
  DONE: 'done',
  DURATION: 'duration',
  EXPERIENCE: 'experience',
  EXTRAS: 'extras',
  GROUP: 'group',
  MATCH: 'match',
  INSTABOOK: 'instabook',
  MISMATCHES: 'mismatches',
  PASSWORD: 'password',
  TIME: 'time',
  WARNING: 'warning',
};

export const BROADCAST_BOATS_FIELD = 'broadcast_boats';
export const INSTABOOK_LISTINGS_FIELD = 'instabook_listings';
export const INQUIRY_ID_FIELD = 'inquiryId';

export const SAVED_INQUIRY_KEY = 'saved_inquiry';
export const SEARCH_INQUIRY_LATEST_KEY = 'search_inquiry_latest';
