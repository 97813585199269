import React, { FC, ReactNode } from 'react';
import { getClassNameFor } from '../../../common/helpers';
import s from './FilterButton.module.scss';

type Props = {
  children: ReactNode;
  classNameModifier?: string;
  handleClick: () => void;
};
const FilterButton: FC<Props> = ({
  handleClick,
  classNameModifier,
  children,
}) => (
  <button
    type="button"
    className={getClassNameFor(s, 'root', classNameModifier)}
    onClick={handleClick}
    data-test="FilterButton"
  >
    {children}
  </button>
);

export default FilterButton;
