import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';

import { apiFetchBase } from 'src/core/fetch';
import { fetchCatch } from 'src/core/sentry';
import type { User } from 'src/types/user/User';

export type UseApproveCaptain = {
  userDetails: Partial<User>;
};

const ALLOWED_PARAMS = ['email', 'first_name', 'last_name', 'phone', 'user_id'];

/**
 * Returns the user's details from the `/boats/approve_captain/` endpoint
 */
const useApproveCaptain = (): UseApproveCaptain => {
  const { search } = useLocation();
  const { hashedId } = parse(search);
  const [userDetails, setUserDetails] = useState<Partial<User>>({});

  const getUserDetailsFromHashedIdParam = useCallback(async () => apiFetchBase(
    `/boats/approve_captain/${hashedId}/`,
    { method: 'PATCH' },
  ), [hashedId]);

  useEffect(() => {
    if (hashedId) {
      getUserDetailsFromHashedIdParam()
        .then(async (response) => {
          if (response.ok) {
            const data = await response.json();
            setUserDetails(Object.fromEntries(Object.entries(data)
              // Pass only allowed params for initialValues
              .filter(([key, value]) => ALLOWED_PARAMS.includes(key) && value)));
          }
          return response;
        })
        .catch(fetchCatch);
    }
  }, [getUserDetailsFromHashedIdParam, hashedId]);

  return {
    userDetails,
  };
};

export default useApproveCaptain;
