import React, { useCallback } from 'react';
import { AnyObject, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch } from 'react-redux';
import { compose } from 'lodash/fp';
import moment from 'moment';

import {
  FORMS,
  PICKUP_TIME_FIELD,
  RETURN_TIME_FIELD,
  TIME_FIELDS,
  TRIP_LENGTH_FIELD,
} from '../../../constants';
import { modalMapDispatchToProps } from '../../../submission';
import { clearFields, patchOffer } from '../../../ducks/offer';

import TimeField from '../../../../common/components/fields/TimeField';
import { MOMENT_FORMAT } from '../../../../common/constants';
import DateTime from '../../DateTime';
import ClearButton from '../../../../common/components/ClearButton';
import FormButtons from '../../../../common/components/FormButtons';
import s from './TimeForm.module.scss';

export const ERROR_MESSAGES = {
  RETURN_TIME_THE_SAME: 'Return time can\'t be the same as depart time!',
  RETURN_TIME_IS_BEFORE: 'Return time can\'t be before the depart time!',
};

type TimeFormValues = {
  pickup_time?: string;
  return_time?: string;
};

type BareTimeFormProps = {
  modified: boolean;
  isMultiDay?: boolean;
  tripLengthMoment: moment.Duration;
  initialValues: TimeFormValues;
  timeFormat?: string;
};

export const BareTimeForm: React.FC<BareTimeFormProps> = ({
  modified,
  isMultiDay,
  tripLengthMoment,
  timeFormat,
  initialValues,
}) => {
  const dispatch = useDispatch();
  const clearLocalOfferFields = () => dispatch(clearFields([
    TRIP_LENGTH_FIELD,
    ...TIME_FIELDS,
  ]));
  const { closeModal } = modalMapDispatchToProps(FORMS.TIME)(dispatch);
  const patchTheOffer = compose(dispatch, patchOffer);

  const validate = ({
    [PICKUP_TIME_FIELD]: departTime,
    [RETURN_TIME_FIELD]: returnTime,
  }: TimeFormValues) => {
    const errors: AnyObject = {};
    const departTimeMoment = moment(departTime, timeFormat);
    const returnTimeMoment = moment(departTime, timeFormat).add(tripLengthMoment);

    if (!departTime) {
      errors[PICKUP_TIME_FIELD] = 'Please enter depart time';
    }
    if (!returnTime) {
      errors[RETURN_TIME_FIELD] = 'Please enter return time';
    }
    if (!isMultiDay) {
      if (returnTimeMoment.isSame(departTimeMoment)) {
        errors[RETURN_TIME_FIELD] = ERROR_MESSAGES.RETURN_TIME_THE_SAME;
      }
      if (returnTimeMoment.isBefore(departTimeMoment)) {
        errors[RETURN_TIME_FIELD] = ERROR_MESSAGES.RETURN_TIME_IS_BEFORE;
      }
    }

    return errors;
  };
  const onSubmit = useCallback((values: TimeFormValues) => {
    // If it's single day, we want to patch duration as well.
    if (!isMultiDay) {
      const pickupTime = values[PICKUP_TIME_FIELD];
      const returnTime = values[RETURN_TIME_FIELD];
      // This logic is all to make sure that we get a positive number of hours for our duration.
      const timeDiff = moment(returnTime, timeFormat).diff(moment(pickupTime, timeFormat));
      const diffDuration = moment.duration(timeDiff);
      // If the diff ends up being negative, we add a day
      const tripLength = ((timeDiff > 0) ? diffDuration : diffDuration.add(24, 'hours')).toJSON();
      // This feels kinda tortured.
      // We want to patch duration, unless the duration is the same.
      patchTheOffer((
        tripLengthMoment.as('minutes') === diffDuration.as('minutes')
          ? values
          : { ...values, [TRIP_LENGTH_FIELD]: tripLength }
      ));
    } else {
      // Otherwise we'll just patch things
      patchTheOffer(values);
    }
    // When we're done close the modal
    closeModal();
  }, [isMultiDay, closeModal, timeFormat, patchTheOffer, tripLengthMoment]);

  return (
    <Form<TimeFormValues>
      validate={validate}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, dirty, form: { reset, change } }) => (
        <form onSubmit={handleSubmit} className={s.root}>
          <h3 className={s.title}>
            Edit Start & End Time
          </h3>
          <div className={s.fieldset}>
            <ClearButton
              disabled={!(dirty || modified)}
              onClick={() => {
                reset();
                clearLocalOfferFields();
              }}
            />
            <DateTime
              classNameModifier="timeForm"
              departHeading="Start"
              returnHeading="End"
              formatString={MOMENT_FORMAT.TIME}
              departDateTime={(
                <TimeField
                  valid
                  name={PICKUP_TIME_FIELD}
                />
              )}
              returnDateTime={(
                <TimeField
                  autoFocus={null}
                  valid
                  name={RETURN_TIME_FIELD}
                />
              )}
            />
            <OnChange name={PICKUP_TIME_FIELD}>
              {(pickupTime, previousPickupTime) => {
                if (!isMultiDay && (pickupTime !== previousPickupTime)) {
                  const calculatedReturnTime = moment(pickupTime, timeFormat)
                    .add(tripLengthMoment)
                    .format(timeFormat);
                  change(RETURN_TIME_FIELD, calculatedReturnTime);
                }
              }}
            </OnChange>
          </div>
          <FormButtons
            classNameModifier="inbox"
            closeModal={closeModal}
          />
        </form>
      )}
    </Form>
  );
};

export default BareTimeForm;
