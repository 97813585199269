import React, { FC } from 'react';
import moment, { Moment } from 'moment';

import { getClassNameFor } from '../../../common/helpers';
import { MOMENT_FORMAT } from '../../../common/constants';
import s from './DateTime.module.scss';

// TODO: refactor so that this is just a structural wrapper, and takes two children,
// which are displayed in the requisite positions.
// The polymorphism of how it treats moments vs strings vs components isn't easy
// to follow.

type Props = {
  formatString?: string;
  title?: string;
  departDateTime: Moment;
  returnDateTime: Moment;
  classNameModifier?: string;
  departHeading?: string;
  returnHeading?: string;
};

const DateTime: FC<Props> = ({
  title,
  departDateTime,
  returnDateTime,
  formatString = MOMENT_FORMAT.DATE,
  classNameModifier = '',
  departHeading = 'Depart',
  returnHeading = 'Return',
}) => (
  <>
    {title && (
      <h3 className={s.subTitle}>
        {title}
      </h3>
    )}
    <dl className={s.values}>
      {departHeading && (
        <dt className={getClassNameFor(s, 'term', ['depart', classNameModifier].join(' '))}>
          {departHeading}
        </dt>
      )}
      <dd className={getClassNameFor(s, 'description', ['depart', classNameModifier].join(' '))}>
        {moment.isMoment(departDateTime)
          ? (
            <time
              dateTime={departDateTime.format(MOMENT_FORMAT.VALUE)}
              data-test="depart-date-label"
            >
              {departDateTime.format(formatString)}
            </time>
          )
          : departDateTime}
      </dd>
      {returnHeading && (
        <dt className={getClassNameFor(s, 'term', ['return', classNameModifier].join(' '))}>
          {returnHeading}
        </dt>
      )}
      <dd className={getClassNameFor(s, 'description', ['return', classNameModifier].join(' '))}>
        {moment.isMoment(returnDateTime)
          ? (
            <time
              dateTime={returnDateTime.format(MOMENT_FORMAT.VALUE)}
              data-test="return-date-label"
            >
              {returnDateTime.format(formatString)}
            </time>
          )
          : returnDateTime}
      </dd>
    </dl>
  </>
);

export default DateTime;
