import React from 'react';

import s from './TripDetailsTitle.module.scss';

const TripDetailsTitle = () => (
  <h2 className={s.root}>
    TRIP DETAILS
  </h2>
);

export default TripDetailsTitle;
