import React from 'react';

import Icon from 'src/common/components/IconDS22';
import s from './InstabookBookingLabel.module.scss';

const InstabookBookingLabel = () => (
  <div className={s.root}>
    <div className={s.icon}>
      <Icon
        id="instabook-filled"
        size="xl"
      />
    </div>
    <h4 className={s.title}>
      Instant Booking
    </h4>
    <p className={s.text}>
      This booking was made via Book Now
    </p>
  </div>
);

export default InstabookBookingLabel;
