import React, { FC } from 'react';

import { ImmutableGuests } from '../../../types/trips/Trips';
import {
  getClassNameFor,
  formatGroupSize as format,
} from '../../helpers';
import { TOOLTIP } from '../../constants';
import { GUEST_FIELDS, GUESTS_FIELD } from '../../../inbox/constants';
import Tooltip from '../Tooltip';
import s from './GroupSizeDetails.module.scss';

// Takes a map with guests in it and sums them
export const totalGuests = (mapWithGuests: ImmutableGuests) => (
  mapWithGuests.filter((value, key) => !!key && GUEST_FIELDS.includes(key))
    .reduce((a, b) => a + b, 0)
);

type Props = {
  guests: ImmutableGuests;
  readOnly?: boolean;
  classNameModifier?: string;
  withTooltip?: boolean;
};

const GroupSizeDetails: FC<Props> = ({
  guests,
  readOnly,
  classNameModifier,
  withTooltip = false,
}) => {
  const numberOfGuests = totalGuests(guests);
  // TODO: deleting here is a hack around the implementation of `formatGroupSize`,
  // and the fact that we don't want the total to go into the breakdown. There's
  // a better way to approach this.
  // See https://github.com/getmyboat/react-universal/pull/8422#discussion_r875735649
  // @ts-ignore
  const breakdown = guests.delete('total').filter((x: number) => x > 0).map(format);
  const breakdownString = breakdown.join(', ');

  return (
    <dl className={getClassNameFor(s, 'root', classNameModifier)}>
      <dt className={getClassNameFor(s, 'total', classNameModifier)}>
        {breakdown.size > 1
          ? format(numberOfGuests, GUESTS_FIELD)
          : breakdownString}
        {!readOnly && withTooltip && (
          <Tooltip classNameModifier="zIndexed">
            {TOOLTIP.CAPACITY}
          </Tooltip>
        )}
      </dt>
      {breakdown.size > 1 && (
        <dd className={s.breakdown}>
          {breakdownString}
        </dd>
      )}
    </dl>
  );
};

export default GroupSizeDetails;
