import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Amounts } from 'src/types/common/Amounts';
import {
  PATHS,
} from '../../../../common/constants';
import { decorateComponent } from '../../../../common/helpers';
import { preserveSearch } from '../../../../common/utils/routing';
import {
  ASK_TO_SEND_NEW_INQUIRY_STATES,
  RENTER_OFFER_EXPIRED_STATES,
} from '../../../constants';
import {
  CaptainCard,
  CommentCard,
  DatesCard,
  DurationCard,
  GroupSizeCard,
  TimeCard,
  VesselCard,
} from '../../cards';
import RenterPriceSection from '../../PriceSection/renter';
import TripPanel from '../../presentation/TripPanel';
import TripMenu from '../../TripMenu';
import TripDetailsTitle from '../../presentation/TripDetailsTitle';
import content, { getMessage } from '../content';
import TopScroll from '../../../../common/components/TopScroll';
import CTA, { CTALink } from '../../CTA';
import DeclineMessages from '../../DeclineMessages';

const mapStateToProps = ({ transaction }) => ({ transaction });

const Booking = ({
  location,
  trip,
  transaction,
}) => {
  const tripState = trip.getIn(['state', 'state']);
  const amounts: Amounts = transaction?.get('amounts')?.toJS()
  || trip?.getIn(['transaction', 'amounts'])?.toJS();
  const cta = (
    <CTA classNameModifier="withSidebar">
      <TripMenu trip={trip} />
      {ASK_TO_SEND_NEW_INQUIRY_STATES.includes(tripState) && (
        <CTALink
          to={{
            pathname: `${PATHS.INQUIRY}${trip.getIn(['boat', 'id'])}/`,
            search: `?return=${trip.getIn(['boat', 'listing_url'])}`,
          }}
        >
          Send a new inquiry
        </CTALink>
      )}
    </CTA>
  );

  return (
    <TripPanel
      trip={trip}
      subheader={content.title[tripState] || trip.getIn(['state', 'badge'])}
      intro={getMessage(trip, location)}
      cta={cta}
      backLocation={preserveSearch(PATHS.INBOX, location)}
      classNameModifier="withoutSideMargins"
    >
      <TopScroll />
      <DeclineMessages trip={trip} />
      {RENTER_OFFER_EXPIRED_STATES.includes(tripState) && (
        <RenterPriceSection
          amounts={amounts}
          trip={trip}
        />
      )}

      <TripDetailsTitle />
      <VesselCard
        readOnly
        trip={trip}
      />
      <DatesCard
        readOnly
        trip={trip}
      />
      <TimeCard
        readOnly
        trip={trip}
      />
      <DurationCard
        readOnly
        trip={trip}
      />
      <GroupSizeCard
        readOnly
        trip={trip}
      />
      <CaptainCard
        readOnly
        trip={trip}
      />
      <CommentCard
        readOnly
        trip={trip}
      />
    </TripPanel>
  );
};

Booking.propTypes = {
  location: PropTypes.object,
  trip: PropTypes.object,
  transaction: PropTypes.object,
};

const decorators = [
  connect(mapStateToProps),
];

export default decorateComponent(Booking, decorators);
