import { BrowserOptions, Event, EventHint, init, setContext, Integrations } from '@sentry/browser';

/**
 * A middleware function called before sending an event to Sentry
 * @param event The error or message event generated by the Sentry SDK.
 * @param hint May contain additional information about the original exception.
 * @param [error] The original exception if available
 * @returns Returning `null` will prevent the event from being sent
 */
type BeforeSendHandler = (
  event: Event,
  hint: EventHint,
  error?: unknown
) => Event | null;

/**
 * A list of middleware functions to apply to the event before submitting it to Sentry.
 */
const beforeSendHandlers: BeforeSendHandler[] = [
  (event, hint, error) => {
    // Filter out grecaptcha's 'Timeout' string error.
    // https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
    if (error === 'Timeout') {
      return null;
    }
    return event;
  },
];

/**
 * Filter events to apply to the event before submitting it to Sentry.
 * @see https://docs.sentry.io/error-reporting/configuration/filtering/?platform=javascript#event-hints
 */
const beforeSend: BrowserOptions['beforeSend'] = (event, hint): Event | null => {
  // See https://docs.sentry.io/error-reporting/configuration/filtering/?platform=javascript#event-hints
  const error = hint.originalException;
  // This says "if any of the beforeSendHandlers return null, then return null"
  return beforeSendHandlers.reduce(
    (accumulator: Event | null, handler: BeforeSendHandler) => (
      accumulator && handler(event, hint, error)
    ),
    event,
  );
};

export default function initSentry() {
  // Set up Sentry error handling
  init({
    dsn: __SENTRY_DSN__,
    beforeSend,
    ignoreErrors: [
      // These are apparently benign errors:
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      'ResizeObserver',
      // This is for localstorage/sessionstorage errors that we've been
      // seeing. We don't use sessionStorage directly, so this shouldn't
      // be an issue.
      'Access is denied for this document',
    ],
    integrations: [
      new Integrations.InboundFilters({
        allowUrls: [__SENTRY_ALLOW_URLS_REGEX__],
      }),
    ],
  });
}

let hasSetRequestId = false;

/**
 * Sets the Request ID in the sentry context for client side errors
 */
export function setRequestId(requestId: string): void {
  // Only set the request id context once
  if (typeof window !== 'undefined' && !hasSetRequestId) {
    setContext('Request ID', {
      'x-request-id': requestId,
    });
    hasSetRequestId = true;
  }
}
