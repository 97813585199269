import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';

import type { Amounts } from 'src/types/common/Amounts';
import { useOffer } from 'src/inbox/hooks';
import useCustomOfferExpiry from '../../../../common/hooks/useCustomOfferExpiry';
import { CAPTAINED_FIELD, PAGES, OWNER_OFFER_EXPIRED_STATES } from '../../../constants';
import { preserveSearch } from '../../../../common/utils/routing';
import TripPanel from '../../presentation/TripPanel';
import {
  DurationCard,
  DatesCard,
  GroupSizeCard,
  CaptainCard,
  CommentCard,
  TimeCard,
  VesselCard,
  DirectBookingCard,
} from '../../cards';
import TopScroll from '../../../../common/components/TopScroll';
import content, { getMessage } from './content';
import CTA, { CTALink, CTAWrapper } from '../../CTA';
import TripDetailsTitle from '../../presentation/TripDetailsTitle';
import TripMenu from '../../TripMenu';
import OfferSummary from '../../OfferSummary';
import OwnerPriceSection from '../../PriceSection/owner';
import DeclineMessages from '../../DeclineMessages';
import DateUnavailable from '../../DateUnavailable';
import { ImmutableTrip } from '../../../../types/trips/Trips';
import { ReduxState } from '../../../../types/reduxState';
import { getOffer } from '../../../../common/utils/reduxStoreSelectors';
import { getBoatMap } from '../../../../common/ducks/boatMap';
import { PATHS } from '../../../../common/constants';
import OfferError from '../../OfferError';

const getTransaction = ({ transaction }: ReduxState) => transaction.toJS();

type SendOfferProps = {
  trip: ImmutableTrip;
};

const SendOffer: FC<SendOfferProps> = ({ trip }) => {
  const location = useLocation();
  const { url } = useRouteMatch();
  const boatMap = useSelector(getBoatMap);
  const offer = useSelector(getOffer);
  const transaction = useSelector(getTransaction);
  const amounts: Amounts = transaction?.amounts || trip?.getIn(['transaction', 'amounts'])?.toJS();
  const { renderCustomExpiry } = useCustomOfferExpiry(trip);
  const {
    captainErrorRef,
    hasErrors,
    handleCreateOfferClick: handleCTAClick,
    pickupTimeErrorRef,
  } = useOffer(trip, offer);

  const tripState = trip.getIn(['state', 'state']);
  const captained = trip.get(CAPTAINED_FIELD);
  const isDirectBooking = trip.get('direct_booking', false);

  let { boat } = trip.toJS();
  if (offer) {
    const offerBoatId = offer.getIn(['boat', 'id'], '');
    const tripBoatId = boat.id;
    if (offerBoatId && tripBoatId !== offerBoatId && boatMap[offerBoatId]) {
      boat = boatMap[offerBoatId].toJS();
    }
  }

  const cta = (
    <CTA
      classNameModifier="withSidebar"
      withWarning={hasErrors}
    >
      {hasErrors && (<OfferError trip={trip} />)}
      <CTAWrapper>
        <TripMenu trip={trip} />
        <CTALink
          to={preserveSearch(`${url}${PAGES.PRICE}/`, location)}
          onClick={handleCTAClick}
          data-test="create-offer-button"
        >
          Create Offer
        </CTALink>
      </CTAWrapper>
    </CTA>
  );

  return (
    <TripPanel
      trip={trip}
      subheader={content.title[tripState] || trip.getIn(['state', 'badge'])}
      intro={getMessage({ trip, offer, location })}
      cta={cta}
      backLocation={preserveSearch(PATHS.INBOX, location)}
      classNameModifier="withoutSideMargins"
    >
      <TopScroll />
      <DeclineMessages trip={trip} />
      <DateUnavailable trip={trip} />
      {isDirectBooking && <DirectBookingCard trip={trip} />}
      {OWNER_OFFER_EXPIRED_STATES.includes(tripState) && (
        <OwnerPriceSection
          amounts={amounts}
          readOnly
          renderCustomExpiry={renderCustomExpiry}
          trip={trip}
        >
          <OfferSummary amounts={amounts} />
        </OwnerPriceSection>
      )}
      <TripDetailsTitle />
      <VesselCard trip={trip} />
      <DatesCard trip={trip} />
      <TimeCard
        errorRef={pickupTimeErrorRef}
        trip={trip}
      />
      <DurationCard trip={trip} />
      <GroupSizeCard trip={trip} />
      {captained !== null && (
        <CaptainCard
          errorRef={captainErrorRef}
          trip={trip}
        />
      )}
      <CommentCard trip={trip} />
    </TripPanel>
  );
};

export default SendOffer;
