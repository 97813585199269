import React, { FC } from 'react';

import { getClassNameFor } from '../../../common/helpers';
import s from './FilterListContainer.module.scss';

const FilterListContainer: FC<Props> = ({
  children,
  classNameModifier,
}) => (
  <ul className={getClassNameFor(s, 'root', classNameModifier)}>
    {children}
  </ul>
);

type Props = {
  classNameModifier?: string;
};

export default FilterListContainer;
