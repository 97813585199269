import React, { FC } from 'react';

import YesNoFields from '../YesNoFields';
import Thumb from './Thumb';
import s from './RecommendationFields.module.scss';

type RecommendationFieldsProps = {
  name: string;
};

const RecommendationFields: FC<RecommendationFieldsProps> = (fieldProps) => (
  <YesNoFields
    rootClassName={s.root}
    YesComponent={Thumb}
    NoComponent={Thumb}
    {...fieldProps}
  />
);

export default RecommendationFields;
