import { useCallback, useRef } from 'react';
import apiFetch from '../../core/fetch';
import { PaginatedResults } from '../../types/utils';
import loadAllPages from '../utils/loadAllPages';

// Although the API has support for `web`, `global` and `mobile` flags,
// this functionality only caters for `web`.

type Flag = {
  id: number;
  client_type: 'web' | 'global';
  flag_name: string;
  status: boolean;
  date_modified: string;
  date_created: string;
};

type SetFlagArgs = {
  id?: number;
  name: string;
  status: boolean;
};

const USER_NOT_AUTHENTICATED = 'User not authenticated';
const FLAGS_ENDPOINT = '/user/flag/';

const unpack = <T>(response: Response): Promise<T> => {
  if (response.status === 403) {
    throw new Error(USER_NOT_AUTHENTICATED);
  }
  if (!response.ok) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw response;
  }
  return response.json();
};

const getFlagLoader = async (page: number) => {
  const response = await apiFetch(`${FLAGS_ENDPOINT}?page=${page}`);
  return unpack<PaginatedResults<Flag>>(response);
};

const getFlagsCall = async (): Promise<Flag[]> => {
  const allResults = await loadAllPages<PaginatedResults<Flag>, Flag>(getFlagLoader);
  return allResults.filter(flag => flag.client_type === 'web');
};

const createFlagCall = async ({ name, status }: SetFlagArgs) => {
  const response = await apiFetch(FLAGS_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify(
      {
        client_type: 'web',
        flag_name: name,
        status,
      },
    ),
  });
  return unpack<Flag>(response);
};

const updateFlagCall = async ({ id, name, status }: Required<SetFlagArgs>) => {
  const response = await apiFetch(`${FLAGS_ENDPOINT}${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(
      {
        client_type: 'web',
        flag_name: name,
        status,
      },
    ),
  });
  return unpack<Flag>(response);
};

const hasId = <T extends { id?: number }>(x: T): x is T & { id: number } => (
  x.id != null
);

const setFlagCall = async (flag: SetFlagArgs): Promise<Flag> => {
  if (hasId(flag)) {
    return updateFlagCall(flag);
  }
  return createFlagCall(flag);
};

const useFlags = () => {
  const flagsRef = useRef<null | Flag[]>(null);
  const getFlag = useCallback(async (flagName: string) => {
    if (flagsRef.current == null) {
      // load the flags if they haven't been loaded
      flagsRef.current = await getFlagsCall();
    }
    return flagsRef.current?.find(f => f.flag_name === flagName)?.status ?? false;
  }, []);

  const setFlag = useCallback(async (flagName: string, flagStatus: boolean) => {
    if (flagsRef.current == null) {
      // load the flags if they haven't been loaded
      flagsRef.current = await getFlagsCall();
    }

    let existingFlag: Flag | undefined;
    if (Array.isArray(flagsRef.current)) {
      existingFlag = flagsRef.current.find(f => f.flag_name === flagName);
    }

    const updatedFlag = await setFlagCall({
      name: flagName,
      status: flagStatus,
      id: existingFlag?.id,
    });

    // update flags cache
    if (Array.isArray(flagsRef.current)) {
      const flagIndex = flagsRef.current.findIndex(f => f.flag_name === flagName);
      if (flagIndex > -1) {
        flagsRef.current[flagIndex] = updatedFlag;
      } else {
        flagsRef.current.push(updatedFlag);
      }
    }

    return updatedFlag;
  }, []);

  return ({
    getFlag,
    setFlag,
  });
};

export default useFlags;
