import React, { FunctionComponent } from 'react';

import { getClassNameFor } from '../../helpers';
import Button from '../Button';
import s from './FormButtons.module.scss';

type BareFormButtonsProps = {
  closeModal: () => void;
  submitting?: boolean;
  classNameModifier?: string;
  onSubmit?: () => void;
  submitText?: string;
  cancelText?: string;
};

export const BareFormButtons: FunctionComponent<BareFormButtonsProps> = ({
  closeModal,
  submitting,
  children,
  classNameModifier,
  onSubmit,
  submitText = 'Done',
  cancelText = 'Cancel',
}) => (
  <div className={getClassNameFor(s, 'root', classNameModifier)}>
    {children}
    <Button
      type="reset"
      onClick={closeModal}
      classNameModifier={`${classNameModifier} secondary`}
      fullWidth
    >
      {cancelText}
    </Button>
    <Button
      type={onSubmit ? 'button' : 'submit'}
      onClick={onSubmit}
      submitting={submitting}
      classNameModifier={classNameModifier}
      fullWidth
    >
      {submitText}
    </Button>
  </div>
);

export default BareFormButtons;
