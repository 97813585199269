import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';

import {
  PATHS,
} from '../../../../common/constants';
import { preserveSearch } from '../../../../common/utils/routing';
import { TRIP_STATES, INQUIRY_CANCELLED_STATES } from '../../../constants';
import {
  CaptainCard,
  CommentCard,
  DatesCard,
  DurationCard,
  GroupSizeCard,
  TimeCard,
  VesselCard,
  DirectBookingCard,
} from '../../cards';
import TripDetailsTitle from '../../presentation/TripDetailsTitle';
import TripMenu from '../../TripMenu';
import TripPanel from '../../presentation/TripPanel';
import Card from '../../presentation/Card';
import TripDetailComment from '../../TripDetailComment';
import content, { getMessage } from '../content';
import CTA, { CTAExternalLink } from '../../CTA';
import ArchiveButton from '../../ArchiveButton';

const Cancelled = ({
  trip,
  location,
}) => {
  const tripState = trip.getIn(['state', 'state']);
  const boatId = trip.getIn(['boat', 'id']);
  const listingUrl = trip.getIn(['boat', 'listing_url']);
  const inquiryUrl = `/s/inquiry/${boatId}?return=${listingUrl}`;
  const archived = trip.get('archived');
  const isDirectBooking = trip.get('direct_booking', false);
  const cta = (
    <CTA classNameModifier="withSidebar">
      {tripState === TRIP_STATES.RENTER_INQUIRY_CANCELLED ? (
        <>
          <TripMenu
            trip={trip}
          />
          <CTAExternalLink href={inquiryUrl}>
            Send a new inquiry
          </CTAExternalLink>
        </>
      ) : (
        <ArchiveButton
          archived={archived}
          trip={trip}
        >
          {`${archived ? 'Restore' : 'Archive'} this Inquiry`}
        </ArchiveButton>
      )}
    </CTA>
  );

  const inquiryCancelMessage = trip.get('inquiry_cancel_message') || Map();
  const inquiryCancelMessageContent = inquiryCancelMessage.get('content');
  const showInquiryCancelMessage = inquiryCancelMessageContent
    && INQUIRY_CANCELLED_STATES.includes(tripState);

  return (
    <TripPanel
      trip={trip}
      subheader={content.title[tripState]}
      intro={getMessage(trip)}
      cta={cta}
      backLocation={preserveSearch(PATHS.INBOX, location)}
      classNameModifier="withoutSideMargins"
    >
      {showInquiryCancelMessage && (
        <Card>
          <TripDetailComment
            user={trip.get('renter')}
            date={inquiryCancelMessage.get('date_created')}
          >
            {inquiryCancelMessageContent}
          </TripDetailComment>
        </Card>
      )}
      {isDirectBooking && <DirectBookingCard trip={trip} />}
      <TripDetailsTitle />
      <VesselCard
        readOnly
        trip={trip}
      />
      <DatesCard
        readOnly
        trip={trip}
      />
      <TimeCard
        readOnly
        trip={trip}
      />
      <DurationCard
        readOnly
        trip={trip}
      />
      <GroupSizeCard trip={trip} readOnly />
      <CaptainCard trip={trip} readOnly />
      <CommentCard trip={trip} readOnly />
    </TripPanel>
  );
};

Cancelled.propTypes = {
  trip: PropTypes.object,
  location: PropTypes.object,
};

export default Cancelled;
