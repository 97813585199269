import React, { FC } from 'react';

import { getClassNameFor } from '../../helpers';
import ExternalLink from '../ExternalLink';
import LogoImage from './LogoImage';
import s from './Logo.module.scss';

type LogoProps = {
  classNameModifier?: string;
  noLink?: boolean;
};

const Logo: FC<LogoProps> = ({
  classNameModifier,
  noLink = false,
}) => {
  const className = getClassNameFor(s, 'root', classNameModifier);

  return (noLink ? (
    <span className={className}>
      <LogoImage classNameModifier={classNameModifier} />
    </span>
  ) : (
    <ExternalLink
      to="/"
      className={className}
      openInNewTab={false}
    >
      <LogoImage classNameModifier={classNameModifier} />
    </ExternalLink>
  ));
};

export default Logo;
