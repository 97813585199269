import React, { FC, MutableRefObject } from 'react';
import { FieldInputProps } from 'react-final-form';

import Media from 'react-media';
import { BoatDetail } from '../../../types/boat/BoatDetail';
import { getBoatHeadline, getClassNameFor } from '../../../common/helpers';
import FilterContainer from '../../../common/components/FilterContainer';
import FilterListContainer from '../FilterListContainer';
import Checkbox from '../../../common/components/inputs/Checkbox';
import RadioButton from '../../../common/components/inputs/RadioButton';
import UnpublishedListingSeparator from '../../../common/components/UnpublishedListingSeparator';
import ListingsDropdownItem from '../ListingsDropdownItem';
import CloseButton from '../../../common/components/CloseButton';
import Button from '../../../common/components/Button';

import s from './ListingsDropdown.module.scss';
import { MEDIA_QUERIES } from '../../../common/constants';

type Input = Partial<FieldInputProps<string, HTMLInputElement>>;

type Props = {
  control: 'checkbox' | 'radio';
  title?: string;
  selectAllInputProps?: {
    indeterminate?: boolean;
    input: Input;
  };
  activeBoats: BoatDetail[];
  unpublishedBoats?: BoatDetail[];
  getInputProps: (boatHashId: BoatDetail['id'], boatId: BoatDetail['boat_id']) => {
    input: Input;
  };
  classNameModifier?: string;
  filterContainerRef?: MutableRefObject<HTMLDivElement | null>;
  mobileActionToggle?: () => void;
};

const ListingsDropdown: FC<Props> = ({
  activeBoats,
  unpublishedBoats = [],
  classNameModifier = '',
  control,
  getInputProps,
  selectAllInputProps,
  title,
  filterContainerRef,
  mobileActionToggle,
}) => {
  const Control = control === 'radio' ? RadioButton : Checkbox;

  return (
    <FilterContainer
      classNameModifier={classNameModifier}
      filterContainerRef={filterContainerRef}
    >
      { title && (
        <>
          <h3 className={s.title}>
            {title}
          </h3>
          {mobileActionToggle && (
            <Media
              queries={{
                small: MEDIA_QUERIES.NOT_DESKTOP,
              }}
            >
              {(matches) => (
                matches.small && (
                  <CloseButton
                    className={getClassNameFor(s, 'closeButton')}
                    onClick={() => mobileActionToggle()}
                  />
                )
              )}
            </Media>
          )}
        </>
      )}
      <FilterListContainer>
        {selectAllInputProps && (
          <ListingsDropdownItem
            control={<Control {...selectAllInputProps} />}
          >
            <span className={s.selectAll}>Select All</span>
          </ListingsDropdownItem>
        )}
        {activeBoats.map((boat) => (
          <ListingsDropdownItem
            key={boat.id}
            control={(
              <Control
                {...getInputProps(boat.id, boat.boat_id)}
              />
            )}
            classNameModifier={classNameModifier}
          >
            <strong>
              {getBoatHeadline(boat)}
            </strong>
          </ListingsDropdownItem>
        ))}
        {unpublishedBoats.length > 0 && <UnpublishedListingSeparator />}
        {unpublishedBoats.map((boat) => (
          <ListingsDropdownItem
            key={boat.id}
            control={<Control {...getInputProps(boat.id, boat.boat_id)} />}
            classNameModifier="unpublished"
          >
            <strong>
              {getBoatHeadline(boat)}
            </strong>
          </ListingsDropdownItem>
        ))}
      </FilterListContainer>
      {mobileActionToggle && (
        <Media
          queries={{
            small: MEDIA_QUERIES.NOT_DESKTOP,
          }}
        >
          {(matches) => (
            matches.small && (
              <div className={getClassNameFor(s, 'mobileAction')}>
                <Button type="button" onClick={mobileActionToggle}>
                  Done
                </Button>
              </div>
            )
          )}
        </Media>
      )}
    </FilterContainer>
  );
};

export default ListingsDropdown;
