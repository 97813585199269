import React, { FC } from 'react';

import { formatDate } from 'src/common/utils/dateHelpers';
import { CALENDAR_TODAY_DATE_DIVIDER } from '../../constants';
import { todayDate } from '../../helpers';
import useTodayTripWire from '../../hooks/useTodayTripWire';
import { getClassNameFor } from '../../../common/helpers';
import s from './TodayDivider.module.scss';

type TodayDividerProps = {
  classNameModifier?: string;
};

const TodayDivider: FC<TodayDividerProps> = ({ classNameModifier }) => {
  const { ref } = useTodayTripWire();
  const today = formatDate(todayDate(), CALENDAR_TODAY_DATE_DIVIDER, {});

  return (
    <div
      ref={ref}
      className={s.root}
    >
      <div className={getClassNameFor(s, 'today', classNameModifier)}>
        Today {today}
      </div>
    </div>
  );
};

export default TodayDivider;
