import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import Icon from 'src/common/components/IconDS22';
import { sharedMediaQueries, useMediaQuery } from 'src/common/hooks';
import { EXTERNAL_PATHS } from 'src/common/constants';
import { getClassNameFor } from '../../helpers';

import ActionsDropdown from '../ActionsDropdown';
import { type DropdownOption } from '../DropdownDS22';
import NavDropdown from '../NavDropdown';
import SubNavigation from './SubNavigation';

import s from './Navigation.module.scss';
import UnauthenticatedNavItems from './UnauthenticatedNavItems';
import { ImmutableUser } from '../../../types/user/User';
import configItems, { type NestedNavigationConfigType } from './config';
import NavItem from './NavItem';
import NavModal from '../NavModal';
import { WithLabel } from '../NestedMenu';

type ScreenSizeWrapperProps = {
  screenSizeWrapper: 'm' | 'mDown';
  children: ReactNode;
};

const ScreenSizeWrapper: FC<ScreenSizeWrapperProps> = ({ screenSizeWrapper, children }) => (
  <div className={getClassNameFor(s, 'screenSizeWrapper', classNames({ [screenSizeWrapper]: true }))}>
    {children}
  </div>
);

type Props = {
  user: ImmutableUser;
  classNameModifier?: string;
};
const Navigation: FC<Props> = ({ classNameModifier = '', user }) => {
  const isMediumAndUpScreenSize = useMediaQuery(sharedMediaQueries.m);
  const isLoggedIn = user.get('loggedIn');
  const isOwner = user.get('owner_approved');
  const isCaptain = user.get('is_captain');
  const modifiers = [classNameModifier, 'mainNav', isLoggedIn ? 'authenticated' : 'anonymous'];
  const modifiersString = modifiers.filter(Boolean).join(' ');

  let menuItems = isCaptain
    ? configItems.filter(
      (config) => config.isHeaderNavItem?.owner && (config.roles == null || config.roles.includes('captain')),
    )
    : configItems.filter(
      (config) => config.isHeaderNavItem?.renter && (config.roles == null || config.roles.includes('renter')),
    );

  if (isOwner) {
    menuItems = configItems.filter(
      (config) => config.isHeaderNavItem?.owner && (config.roles == null || config.roles.includes('owner')),
    );
  }

  const chargeSelectHandler = useCallback((selectedOption: DropdownOption) => {
    const { location } = window;
    if (selectedOption?.id === 'direct-booking') {
      location.assign(EXTERNAL_PATHS.CREATE_DIRECT_BOOKING);
    } else if (selectedOption?.id === 'add-on-payment') {
      location.assign(EXTERNAL_PATHS.CHARGE_ADD_ON);
    }
  }, []);

  const chargeDropdownOptions: DropdownOption[] = useMemo(() => [
    {
      dropdownLabel: 'Direct Booking',
      icon: isMediumAndUpScreenSize ? undefined : 'credit-card-arrow',
      id: 'direct-booking',
    },
    {
      dropdownLabel: 'Add-On Payment',
      id: 'add-on-payment',
      icon: isMediumAndUpScreenSize ? undefined : 'credit-card-check',
    },
  ], [isMediumAndUpScreenSize]);

  return (
    <nav className={getClassNameFor(s, 'root', classNameModifier)}>
      <ul className={getClassNameFor(s, 'menu', classNameModifier)}>
        {isLoggedIn && (
          <>
            {isOwner && (
              <li className={getClassNameFor(s, 'item', `${modifiers}`)}>
                <ActionsDropdown
                  dropdownPosition="right"
                  label={(
                    <>
                      <Icon id="credit-card-charge" size="l" />
                      <span className={s.chargeLabel}>
                        Charge
                      </span>
                    </>
                  )}
                  handleSelect={chargeSelectHandler}
                  options={chargeDropdownOptions}
                  showCaret={false}
                  variant="topNav"
                />
              </li>
            )}
            {menuItems.map((data: NestedNavigationConfigType) => {
              const screenSizeModifiers = data.isHeaderNavItem?.[isOwner ? 'owner' : 'renter']?.join(' ');
              const iconOnlyScreenSizeModifiers = data.iconOnlyScreenSizes?.[isOwner ? 'owner' : 'renter']?.map((size) => `iconOnly_${size}`).join(' ');
              if (!screenSizeModifiers) return null;
              const asNestedMenuItem = data as WithLabel;
              return (
                <NavItem
                  key={data.key}
                  classNameModifier={`${iconOnlyScreenSizeModifiers} ${modifiersString} ${screenSizeModifiers}`}
                  to={asNestedMenuItem.to}
                  icon={asNestedMenuItem.icon}
                  openInNewTab={data.openInNewTab}
                >
                  {asNestedMenuItem.label}
                </NavItem>
              );
            })}
          </>
        )}
        {!isLoggedIn && <UnauthenticatedNavItems classNameModifier={modifiersString} />}

        <li
          className={getClassNameFor(
            s,
            'item',
            [...modifiers, 'dropdown'].filter(Boolean).join(' '),
          )}
        >
          {(
            <ScreenSizeWrapper screenSizeWrapper="mDown">
              <NavModal
                classNameModifier="menuItems nestedMenu"
                heading={(
                  isLoggedIn && (
                    <Icon
                      id="menu"
                      size="l"
                      className={s.hamburger}
                    />
                  )
                )}
              >
                <SubNavigation nestedMenuVariant="sidebar" />
              </NavModal>
            </ScreenSizeWrapper>
          )}
          {(
            <ScreenSizeWrapper screenSizeWrapper="m">
              <NavDropdown
                className={getClassNameFor(s, 'link', modifiersString)}
                classNameModifier="nestedMenu"
                data-test="MainMenuDropDown"
                aria-label="Main menu"
                withChevron={false}
                heading={(
                  isLoggedIn && (
                    <Icon
                      id="menu"
                      size="l"
                      className={s.hamburger}
                    />
                  )
                )}
              >
                <SubNavigation />
              </NavDropdown>
            </ScreenSizeWrapper>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
