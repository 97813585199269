import React, { FC } from 'react';
import { Form } from 'react-final-form';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { compose } from 'lodash/fp';

import { rffTripLengthValidator as validate } from '../../../../common/validation';
import { FORMS, TRIP_LENGTH_FIELD, DEFAULT_DAY_TRIP_HOURS } from '../../../constants';
import { clearFields, patchOffer } from '../../../ducks/offer';
import ClearButton from '../../../../common/components/ClearButton';
import FormError from '../../../../common/components/FormError';
import { modalMapDispatchToProps } from '../../../submission';
import DurationFieldset from '../../../../common/components/fieldsets/Duration';
import FormButtons from '../../../../common/components/FormButtons';
import s from './DurationForm.module.scss';

type DurationValues = {
  days: number;
  hours: number;
  minutes: number;
};

type Props = {
  initialValues: DurationValues;
  modified: boolean;
  hasDayPricing?: boolean;
};

export const getUpdatedValues = (
  values: DurationValues,
  initialValues: DurationValues,
  hasDayPricing: boolean = false,
) => {
  let updatedValues = null;

  if (hasDayPricing && values.days !== initialValues.days) {
    // Days are getting changed. We need to reduce new day count by 1
    const newDayCount = values.days - 1;
    if (values.days > 1) {
      // Only days OR hours/minutes can be changed at a time
      // Retain same hours/minutes on new duration to not overwrite
      const { hours, minutes } = initialValues;
      updatedValues = { days: newDayCount, hours, minutes };
    } else {
      // If changed to 1-day trip, update hours to default
      updatedValues = { days: newDayCount, hours: DEFAULT_DAY_TRIP_HOURS, minutes: 0 };
    }
  }

  return updatedValues ?? values;
};

const DurationForm: FC<Props> = ({
  modified,
  initialValues,
  hasDayPricing,
}) => {
  const dispatch = useDispatch();
  const clearLocalOfferFields = () => dispatch(clearFields([TRIP_LENGTH_FIELD]));
  const patchTheOffer = compose(dispatch, patchOffer);
  const { closeModal } = modalMapDispatchToProps(FORMS.DURATION)(dispatch);
  const multiDayLabel = hasDayPricing ? 'Days' : 'Nights';

  // Multiday trips are mix of days and hours. 1 Day and 8 Hours would be equivalent of '2 Days'
  const dayCount = hasDayPricing && initialValues.hours
    ? initialValues.days + 1
    : initialValues.days;

  // Ensure that day-based trips can allow for hour and minute editing
  const modifiedInitialValues = initialValues.days > 0
    ? {
      days: dayCount,
      hours: 0,
      minutes: 0,
    }
    : initialValues;

  return (
    <Form
      validate={validate}
      initialValues={modifiedInitialValues}
      onSubmit={(values, { getState }) => {
        if (getState().dirty) {
          patchTheOffer({
            [TRIP_LENGTH_FIELD]: moment.duration(
              getUpdatedValues(values, initialValues, hasDayPricing),
            ).toJSON(),
          });
        }
        closeModal();
      }}
    >
      {({ handleSubmit, dirty, error, form: { reset } }) => (
        <form onSubmit={handleSubmit} className={s.root}>
          <h3 className={s.title}>
            Edit Duration
          </h3>
          <DurationFieldset
            classNameModifier="inbox"
            multiDayHeader="Multi-day"
            multiDayLabel={multiDayLabel}
          >
            <ClearButton
              disabled={!(dirty || modified)}
              onClick={() => {
                reset();
                clearLocalOfferFields();
              }}
            />
          </DurationFieldset>

          <FormError error={error} />
          <FormButtons
            classNameModifier="inbox"
            closeModal={closeModal}
          />
        </form>
      )}
    </Form>
  );
};

export default DurationForm;
