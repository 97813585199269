import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import Icon from 'src/common/components/IconDS22';
import useApproveCaptain from 'src/common/hooks/useApproveCaptain';
import { PATHS } from '../../../../common/constants';
import FormError from '../../../../common/components/FormError';
import Input from '../../../../common/components/inputs/Input';
import EmailField from '../../../../common/components/fields/EmailField';
import Button from '../../../../common/components/Button';
import { rffValidators, combineValidators } from '../../../../common/validation';
import { rffSubmitResponse } from '../../../../common/helpers';
import { loginAction } from '../../../../common/ducks/user';
import useGrecaptcha from '../../../hooks/useGrecaptcha';
import { ReduxState } from '../../../../types/reduxState';
import s from './LoginForm.module.scss';

const emailValidate = combineValidators([
  rffValidators.required({ message: 'Please enter an email address' }),
  rffValidators.email({ message: 'Please enter a valid email address' }),
]);
const passwordValidate = rffValidators.required({ message: 'Please enter a password' });

type HistoryPush = (path: string, state?: unknown) => void;
/**
 * Directs the user to the 2FA screens when the API indicates that 2FA is required.
 */
const handle2FA = (push: HistoryPush, search: string) => async (response: Response) => {
  const responseClone = response.clone();
  const errors = await responseClone.json();
  if (errors['2fa_required']) {
    push(`${PATHS.OTP}${search}`);
  }
  return rffSubmitResponse()(response);
};

type LoginFormProps = {
  signUpPath: string;
  onSubmitSuccess: () => void;
};

const LoginForm: FC<LoginFormProps> = ({ signUpPath, onSubmitSuccess }) => {
  const dispatch = useDispatch<ThunkDispatch<ReduxState, {}, Action>>();
  const { push } = useHistory();
  const { search } = useLocation();
  const { userDetails } = useApproveCaptain();

  const login = useCallback(
    async (values: { email: string; password: string }) => (
      dispatch(loginAction(values)).then(onSubmitSuccess).catch(handle2FA(push, search))
    ),
    [dispatch, onSubmitSuccess, push, search],
  );

  const onSubmit = useGrecaptcha('login', login);

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  return (
    <Form
      initialValues={{ email: userDetails?.email }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitError, submitting }) => (
        <form
          method="POST"
          onSubmit={handleSubmit}
          className={s.root}
        >
          <FormError error={submitError} />
          <fieldset>
            <EmailField
              name="email"
              validate={emailValidate}
            />
            <div className={s.passwordWrapper}>
              <Field
                name="password"
                placeholder="Password"
                type={isPasswordVisible ? 'text' : 'password'}
                validate={passwordValidate}
                component={Input}
              />
              <button
                className={s.visiblePasswordButton}
                type="button"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                <Icon id={isPasswordVisible ? 'eye' : 'eye-slash'} />
              </button>
            </div>
          </fieldset>

          <div className={s.actionGroup}>
            <Link
              to={PATHS.PASSWORD_FORGOT}
              className={s.forgotPassword}
            >
              Forgot your password?
            </Link>
            <Button
              type="submit"
              submitting={submitting}
              fullWidth
            >
              Sign In
            </Button>
            <div className={s.account}>
              {"Don't have an account? "}
              <Link
                to={signUpPath}
                className={s.link}
              >
                Create Account.
              </Link>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

export default LoginForm;
