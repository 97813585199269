import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import Icon from 'src/common/components/IconDS22';
import { PATHS } from '../../../common/constants';
import { getClassNameFor } from '../../../common/helpers';
import { preserveSearch } from '../../../common/utils/routing';
import { PAGES } from '../../constants';
import s from './TripControls.module.scss';
import TypingIndicator from '../TypingIndicator';
import { usePubSubContext } from '../../../pubsub/hook/usePubsub';

const TripControls = ({ trip, hasUnreadMessages, hasPendingTripChanges }) => {
  const { isThreadTyping } = usePubSubContext();
  const location = useLocation();
  const { state, pathname } = location;
  // Explicit destructuring here because `state` will be undefined if
  // nothing's been stuck in it
  const cameFromEdit = state && state.cameFromEdit;
  const tripId = trip.get('pk');
  const messagesModifier = hasUnreadMessages ? 'messageNotification' : '';
  const hasUnreadStateChange = trip.get('has_unseen_state_change', false);
  const tripModifier = (hasPendingTripChanges || hasUnreadStateChange) ? 'messageNotification' : '';

  // TODO: this would really be better done with a route match
  // NOTE:
  // All of the following logic is to support the following:
  // When the user goes from the edit offer page (/inbox/pk/edit)
  // to the messages tab, we want them to land back on the edit page
  // when they go back to the booking tab.
  // However, we can't just ask whether there's something in the offer,
  // because the offer canceled and offer lapsed pages both allow
  // for editing in place, and we want them to land back on those pages
  // when they go back to the booking tab.
  // This is why we're using location state - it allows us to keep track
  // of where we just came from without explicitly looking back through
  // the history stack, and we're fine with it being ephemeral, because
  // if the user reloads the page on the messages tab, their offer edits
  // are going to get blown away anyway.
  // Also NOTE - putting this here because it's interesting and I don't know
  // where else to put it - reloading the page doesn't blow away the HTML5
  // history stack.  You can still use the back button to traverse it. weeeeeird.
  const currentlyEditing = pathname.includes('/edit');
  // If they came from the edit page, we'll send them back to the edit page.
  const bookingPathSegment = cameFromEdit ? '/edit' : '';
  const bookingPath = `${PATHS.INBOX}${tripId}${bookingPathSegment}/`;
  const messagesLocationSansState = preserveSearch(`${PATHS.INBOX}${tripId}/${PAGES.MESSAGES}/`, location);
  // If they're on the edit page, make note of it in the state
  const messagesLocation = currentlyEditing
    ? { ...messagesLocationSansState, state: { cameFromEdit: true } }
    : messagesLocationSansState;
  const currentlyOnMessages = pathname.includes(PAGES.MESSAGES);

  return (
    <ul className={s.root}>
      <li className={getClassNameFor(s, 'item', tripModifier)}>
        <NavLink
          to={preserveSearch(bookingPath, location)}
          className={s.link}
          activeClassName={s.link_active}
          isActive={(match, { pathname: currentPathname }) => (
            !currentPathname.includes(PAGES.MESSAGES)
          )}
        >
          <Icon id="trip-details" size="l" />
          <div className={s.tabTitle}>TRIP DETAILS</div>
        </NavLink>
      </li>
      <li className={getClassNameFor(s, 'item', messagesModifier)}>
        <NavLink
          to={messagesLocation}
          className={s.link}
          activeClassName={s.link_active}
        >
          <Icon id="chat" size="l" />
          {(!currentlyOnMessages && isThreadTyping(tripId)) && (
            <TypingIndicator classNameModifier="tripControl" />
          )}
          <div className={s.tabTitle}>MESSAGES</div>
        </NavLink>
      </li>
    </ul>
  );
};

TripControls.propTypes = {
  trip: PropTypes.object,
  hasUnreadMessages: PropTypes.bool.isRequired,
  hasPendingTripChanges: PropTypes.bool,
};

export default TripControls;
