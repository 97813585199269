import React, { FC, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parse, stringify } from 'query-string';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { trackEvent } from 'src/common/tracking';
import { getIsLoggedIn, logoutCall } from '../../ducks/user';

import s from './SignInOrSwitchAccounts.module.scss';
import SignInLink from '../SignInLink';
import { PATHS } from '../../constants';

/**
 * Renders a message and link to sign in or switch accounts.
 */
const SignInOrSwitchAccounts: FC = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const location = useLocation();
  const { push } = useHistory();
  const query = parse(location.search);
  const loginSearch = stringify({ ...query, next: location.pathname });
  const loginPath = `${PATHS.LOGIN}?${loginSearch}`;

  const goToLogin = (event: MouseEvent) => {
    // This needs to be handled separately because of the loggedIn
    // redirect on the login page. Since the logout call is asynchronous,
    // if you just call it as onClick, it doesn't happen quickly enough,
    // and the page transitions to the login and back before the logout
    // call completes.
    event.preventDefault();
    dispatch(logoutCall()).then(() => {
      push(loginPath);
    });
  };

  return isLoggedIn ? (
    <p>
      {'Not you? '}
      <Link
        to={loginPath}
        className={s.link}
        onClick={goToLogin}
      >
        Sign In
      </Link>
      {' with a different account.'}
    </p>
  ) : (
    <p>
      {'Have an account with Getmyboat? '}
      <SignInLink
        onClick={(event: MouseEvent<HTMLAnchorElement>) => {
          event.preventDefault();
          trackEvent('send_inquiry_s8_user', { p1: 'Sign In' });
          push(loginPath);
        }}
        loginPath={loginPath}
      />.
    </p>
  );
};

export default SignInOrSwitchAccounts;
