import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';

import { rffSubmitResponse } from '../../../../common/helpers';
import { BROADCAST_BOATS_FIELD, PAGES } from '../../../constants';
import { matchingSubmit } from '../../../submit';
import useOnSubmitLocationChange from '../../../../common/hooks/useOnSubmitLocationChange';
import { BackButton } from '../../../../common/components/BackButton';
import { validateRedirect } from '../../../../common/utils/routing';
import {
  getBoat, getBroadcastBoats, getInquiryDetails,
  getNextInquiryPage, getSimilarListingsInitialValues,
} from '../../../../common/utils/reduxStoreSelectors';
import FormError from '../../../../common/components/FormError';
import SimilarListingsPanel from '../../SimilarListings';
import CloseButton from '../../../../common/components/CloseButton';
import Button from '../../../../common/components/Button';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import s from '../SimilarListings/SimilarListings.module.scss';

type TrySomethingElseProps = {
  inquiryHasInstabookListings?: boolean;
};

const TrySomethingElse: FC<TrySomethingElseProps> = ({ inquiryHasInstabookListings }) => {
  const location = useLocation();
  const { goBack, push } = useHistory();
  const boat = useSelector(getBoat);
  const inquiry = useSelector(getInquiryDetails);
  const initialValues = useSelector(getSimilarListingsInitialValues);
  const broadcastBoats = useSelector(getBroadcastBoats);
  const nextPage = useSelector(getNextInquiryPage);
  const returnUrl = getReturnUrl(location);
  const onSubmitSuccess = useOnSubmitLocationChange(
    inquiryHasInstabookListings ? PAGES.INSTABOOK : nextPage,
  );

  const onSubmit = useCallback(
    values => matchingSubmit({ inquiry, boat })(values)
      .then(onSubmitSuccess)
      .catch(rffSubmitResponse()),
    [boat, onSubmitSuccess, inquiry],
  );

  return (
    <div className={s.container}>
      <Helmet>
        <title>
          Try something else
        </title>
      </Helmet>
      <div className={s.message}>
        <h1 className={s.title}>
          {"Let's try something else"}
        </h1>
        <p>
          It looks like the listing you selected can’t help with parts of
          your inquiry. We can send your request to these similar listings instead.
        </p>
      </div>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitError, values }) => (
          <form onSubmit={handleSubmit} className={s.listingForm}>
            <FormError error={submitError} className={s.error} />
            <SimilarListingsPanel
              broadcastBoats={broadcastBoats}
            />
            <Button
              onClick={() => push(validateRedirect(returnUrl))}
              type="button"
              classNameModifier="paired gray"
            >
              Cancel Inquiry
            </Button>
            <Button
              type="submit"
              classNameModifier="paired"
              // Indicates that all checkboxes are deselected
              disabled={(
                values[BROADCAST_BOATS_FIELD] == null || values[BROADCAST_BOATS_FIELD]?.length === 0
              )}
            >
              Continue
            </Button>
          </form>
        )}
      </Form>
      <CloseButton backUrl={returnUrl} />
      <BackButton onClick={goBack} />
    </div>
  );
};

export default TrySomethingElse;
