import { createSelector } from 'reselect';
import moment, { Moment } from 'moment';

import { MOMENT_FORMAT } from '../../../../common/constants';
import { formatTime, getTimeFormat } from '../../../../common/utils/time';
import { testTimeInputSupport } from '../../../../common/hooks/useTimeInput';
import {
  PREFERRED_DATE_FIELD,
  TIME_FIELDS,
  TRIP_LENGTH_FIELD,
} from '../../../constants';
import { tripGetters, offerGetters } from '../../../selectors';

// These are trip selectors
const timeFields = [
  PREFERRED_DATE_FIELD,
  ...TIME_FIELDS,
  TRIP_LENGTH_FIELD,
];

const timeInputIsSupported = testTimeInputSupport();
const timeFormatString = getTimeFormat(timeInputIsSupported);
const momentFormatString = `${MOMENT_FORMAT.VALUE} ${timeFormatString}`;
const tripTimeGetters = tripGetters(timeFields);
const offerTimeGetters = offerGetters(timeFields);

export const calculateTripTimes = (
  // TODO: null handling
  preferredDateString: string,
  // eslint-disable-next-line @typescript-eslint/default-param-last
  pickupTimeString: string,
  returnTimeString: null | string,
  durationString: string,
) => {
  const tripLengthMoment = moment.duration(durationString);
  const isMultiDay = tripLengthMoment.asDays() >= 1;
  let pickupTime: string = formatTime('09:00', timeFormatString);

  let returnTime: string | Moment = isMultiDay
    ? pickupTime
    : moment(`${preferredDateString} ${pickupTime}`, momentFormatString)
      .add(tripLengthMoment)
      .format(timeFormatString);

  const values = {
    tripLengthMoment,
    isMultiDay,
    timeFormatString,
    timeInputIsSupported,
  };

  if (!pickupTimeString) {
    return {
      departMoment: null,
      pickupTime,
      returnMoment: null,
      returnTime,
      ...values,
    };
  }

  const departMoment = moment(
    `${preferredDateString} ${pickupTimeString}`,
    momentFormatString,
  );

  let returnMoment = departMoment.clone().add(tripLengthMoment);

  if (returnTimeString !== null && isMultiDay) {
    // Note here:
    // The reason this is different than TimeCard is because the timecard
    // doesn't care about the return date, but the consumer of this component
    // might, so we want to preserve the date, which is slightly more verbose.
    returnMoment = moment(
      `${returnMoment.format(MOMENT_FORMAT.VALUE)} ${returnTimeString}`,
      momentFormatString,
    );
  }
  pickupTime = departMoment.format(timeFormatString);
  returnTime = returnMoment && returnMoment.format(timeFormatString);

  return {
    departMoment,
    pickupTime,
    returnMoment,
    returnTime,
    ...values,
  };
};

// Trip version
export const tripTimeSelector = createSelector(
  tripTimeGetters,
  calculateTripTimes,
);
export const offerTimeSelector = createSelector(
  offerTimeGetters,
  calculateTripTimes,
);
