import { Mutator } from 'final-form';
import { INSTABOOK_CAPTAINED_FIELD, INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD } from '../../constants';

type InstabookCaptainOptions = {
  [INSTABOOK_CAPTAINED_FIELD]: boolean;
  [INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD]: boolean;
};

type InstabookMutator = Mutator<InstabookCaptainOptions>;

export const setCaptainProvided: InstabookMutator = (_args, state, { changeValue }) => {
  changeValue(state, INSTABOOK_CAPTAINED_FIELD, () => true);
  changeValue(state, INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD, () => true);
};

export const setCaptainArrangedSeparately: InstabookMutator = (_args, state, { changeValue }) => {
  changeValue(state, INSTABOOK_CAPTAINED_FIELD, () => true);
  changeValue(state, INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD, () => false);
};

export const setCaptainNotProvided: InstabookMutator = (_args, state, { changeValue }) => {
  changeValue(state, INSTABOOK_CAPTAINED_FIELD, () => false);
  changeValue(state, INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD, () => false);
};

export const unselectCaptainOptions: InstabookMutator = (_args, state, { changeValue }) => {
  changeValue(state, INSTABOOK_CAPTAINED_FIELD, () => undefined);
  changeValue(state, INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD, () => undefined);
};
