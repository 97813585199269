import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { SAVED_INQUIRY_KEY } from 'src/booking-inquiry/constants';
import { ThunkDispatch } from 'redux-thunk';
import { ReduxState } from 'src/types/reduxState';
import { Action } from 'redux';
import { logoutThunk } from '../../ducks/user';
import { getClassNameFor } from '../../helpers';
import { homepageRedirect } from '../../utils/routing';
import IconDS22 from '../IconDS22';
import s from './Navigation.module.scss';

type Props = {
  className?: string;
};

const LogoutButton: FC<Props> = ({ className = '' }) => {
  const dispatch = useDispatch<ThunkDispatch<ReduxState, {}, Action>>();

  const logout = useCallback(async () => {
    const redirectToHome = await dispatch(logoutThunk());
    localStorage.removeItem(SAVED_INQUIRY_KEY);
    if (redirectToHome) {
      homepageRedirect();
    }
  }, [dispatch]);

  return (
    <button
      onClick={logout}
      className={className}
      type="button"
    >
      <IconDS22
        id="logout"
        size="m"
        className={getClassNameFor(s, 'iconDS22', 'subNav logout nestedMenu')}
      />
      Sign Out
    </button>
  );
};

export default LogoutButton;
