import { List } from 'immutable';
import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BoatDetail } from '../../types/boat/BoatDetail';
import { ReduxState } from '../../types/reduxState';

export type BoatDetailsLookup = Record<number | string, BoatDetail>;

export type UseBoats = {
  allBoats: BoatDetail[];
  boats: BoatDetail[];
  unpublishedBoats: BoatDetail[];
  boatsIndexedById: BoatDetailsLookup;
  firstBoatId: number;
};

const emptyList = List();

/**
 * Returns the user's boats from the redux store
 */
const useBoats = (): UseBoats => {
  const allBoats = useSelector<ReduxState, BoatDetail[]>(
    state => state.user.get('boats', List())?.toJS() ?? emptyList,
  );
  const boats = useMemo<BoatDetail[]>(() => allBoats.filter(boat => boat.active), [allBoats]);
  const firstBoatId = useMemo<number>(() => boats[0]?.boat_id ?? 0, [boats]);
  const unpublishedBoats = useMemo<BoatDetail[]>(
    () => allBoats.filter(boat => !boat.active),
    [allBoats],
  );
  /**
   * The boats are indexed by both the hashed id and the numeric primary key id
   */
  const boatsIndexedById = useMemo<BoatDetailsLookup>(() => ({
    ...keyBy(allBoats, boat => boat.boat_id),
    ...keyBy(allBoats, boat => boat.id),
  }), [allBoats]);

  return {
    allBoats,
    boats,
    unpublishedBoats,
    boatsIndexedById,
    firstBoatId,
  };
};

export default useBoats;
