import React, { FC } from 'react';
import { ImmutableTrip } from 'src/types/trips/Trips';
import DirectBookingGuestMessage from './DirectBookingGuestMessage';
import Messages from '.';

type MessagesSwitcherProps = {
  trip: ImmutableTrip;
  location: any;
  match: any;
};

const MessagesSwitcher: FC<MessagesSwitcherProps> = (props) => {
  const renterIsGuest = props.trip.getIn(['renter', 'is_guest']);
  return (
    renterIsGuest ? <DirectBookingGuestMessage {...props} /> : <Messages {...props} />
  );
};

export default MessagesSwitcher;
