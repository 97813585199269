import { buildURL } from 'react-imgix';

/**
 * Removes invalid width and height params.
 * These invalid params cause the appended width and height parameters to be
 * ignored, resulting in really large images being loaded which degrades the
 * overall performance of the site.
 *
 * This is a temporary workaround until the backend changes can be made and
 * the listings can be completely re-indexed into elasticsearch.
 * @see https://getmyboat.slack.com/archives/G0WKAM32S/p1626272592033500
 * @param {string} src
 */
const stripBadHeightAndWidthParams = src => {
  if (src.includes('?') && src.includes('=None')) {
    const [domain, ...queryString] = src.split('?');
    return `${domain}?${queryString.join('').split('&').filter(s => !['h=None', 'w=None'].includes(s)).join('&')}`;
  }
  return src;
};

/**
 * @param {string} src
 * @returns {string}
 */
export const pointAtImgix = src => {
  // eslint-disable-next-line no-param-reassign
  src = stripBadHeightAndWidthParams(src);

  // NOTE: the "correct" way to do this would be to use the
  // URL api, but since that's not available on IE or on node 8,
  // we're doing this hackery instead.
  if (src.startsWith(__ASSETS_CDN__)) {
    return src.replace(__ASSETS_CDN__, __IMGIX_URL__);
  }
  if (src.startsWith('/')) {
    return `${__IMGIX_URL__}${src}`;
  }
  return src;
};

export const defaultImgixProps = {
  q: 50,
  lossless: false,
  fit: 'clip',
  auto: ['format', 'compress'],
};

// When added to the imgix params of an image,
// these produce a blurry blocky version of the
// image that's much smaller in terms of kb.
export const lqipParams = {
  blur: 200,
  px: 16,
};

// TODO; see if there's a less obfuscated way to do his logic.
export const buildImgixUrl = (src, options = {}) => (
  buildURL(pointAtImgix(src), {
    ...defaultImgixProps,
    ...options,
    auto: [...defaultImgixProps.auto, ...(options.auto || [])],
  })
);

export const imgixParamsForLightbox = {
  w: typeof window !== 'undefined'
    // 90% of viewport width the same as `90vw` passed as width prop to Lightbox
    ? Math.round(document.body.clientWidth * 0.9)
    // Fallback to some meaningful size for mobiles
    : 400,
};
