import React, { FC, useCallback } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'lodash/fp';

import useOffer from 'src/inbox/hooks/useOffer';
import { offerIsEmpty } from 'src/inbox/helpers';
import OfferModifiedWarning from '../../presentation/OfferModifiedWarning';
import { PATHS } from '../../../../common/constants';
import { preserveSearch, parentRoute } from '../../../../common/utils/routing';
import { CAPTAINED_FIELD, PAGES } from '../../../constants';
import TripPanel from '../../presentation/TripPanel';
import {
  CaptainCard,
  CommentCard,
  DurationCard,
  DatesCard,
  GroupSizeCard,
  TimeCard,
  VesselCard,
  DirectBookingCard,
} from '../../cards';
import TopScroll from '../../../../common/components/TopScroll';
import CTA, { CTALink, CTAWrapper } from '../../CTA';
import OfferSentMessage from '../../OfferSentMessage';
import TripDetailsTitle from '../../presentation/TripDetailsTitle';
import TripMenu from '../../TripMenu';
import { clearOffer } from '../../../ducks/offer';
import { ImmutableTrip } from '../../../../types/trips/Trips';
import { getOffer } from '../../../../common/utils/reduxStoreSelectors';
import { getBoatMap } from '../../../../common/ducks/boatMap';
import { setBoat } from '../../../../common/ducks/boat';
import OfferError from '../../OfferError';

type Props = {
  trip: ImmutableTrip;
};

const EditOffer: FC<Props> = ({ trip }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const clearTheOffer = compose(dispatch, clearOffer);
  const { url } = useRouteMatch();
  const offer = useSelector(getOffer);
  const boatMap = useSelector(getBoatMap);
  const captained = trip.get(CAPTAINED_FIELD);
  const baseTripUrl = parentRoute(url);
  const isDirectBooking = trip.get('direct_booking', false);

  let { boat } = trip.toJS();
  if (offer) {
    const offerBoatId = offer.getIn(['boat', 'id'], '');
    const tripBoatId = boat.id;
    if (offerBoatId && tripBoatId !== offerBoatId && boatMap[offerBoatId]) {
      boat = boatMap[offerBoatId].toJS();
    }
  }
  const {
    captainErrorRef,
    handleCreateOfferClick,
    hasErrors,
    pickupTimeErrorRef,
  } = useOffer(trip, offer, true);

  const cancelEdit = useCallback(() => {
    const previousBoatId = trip.getIn(['boat', 'id']);
    const previousBoat = boatMap[previousBoatId];
    dispatch(setBoat(previousBoat));
    clearTheOffer();
  }, [boatMap, clearTheOffer, dispatch, trip]);

  const cta = (
    <CTA
      classNameModifier="withSidebar"
      withWarning={hasErrors}
    >
      {hasErrors && (<OfferError trip={trip} />)}
      <CTAWrapper>
        <TripMenu trip={trip} />
        <CTALink
          to={preserveSearch(`${baseTripUrl}${PAGES.PRICE}/`, location)}
          onClick={handleCreateOfferClick}
        >
          Create Offer
        </CTALink>
      </CTAWrapper>
    </CTA>
  );

  return (
    <TripPanel
      trip={trip}
      subheader="Edit Offer"
      intro={(
        <>
          {!offerIsEmpty(offer) && <OfferModifiedWarning />}
          <OfferSentMessage trip={trip}>
            <strong>You are editing your offer to {trip.getIn(['renter', 'first_name'])}.</strong>{' '}
            If you don’t want to change your offer you can{' '}
            <Link
              onClick={cancelEdit}
              to={preserveSearch(baseTripUrl, location)}
            >
              cancel edits and return to your previous offer
            </Link>
            .
          </OfferSentMessage>
        </>
      )}
      cta={cta}
      backLocation={preserveSearch(PATHS.INBOX, location)}
      classNameModifier="withoutSideMargins"
    >
      <TopScroll />
      {isDirectBooking && <DirectBookingCard trip={trip} />}
      <TripDetailsTitle />
      <VesselCard trip={trip} />
      <DatesCard trip={trip} />
      <TimeCard
        errorRef={pickupTimeErrorRef}
        trip={trip}
      />
      <DurationCard trip={trip} />
      <GroupSizeCard trip={trip} />
      {captained !== null && (
        <CaptainCard
          errorRef={captainErrorRef}
          trip={trip}
          isEditOffer
        />
      )}
      <CommentCard trip={trip} />
    </TripPanel>
  );
};

export default EditOffer;
