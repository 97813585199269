import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { isPaymentMethodSet } from 'src/common/utils/reduxStoreSelectors';
import { ImmutableTrip } from 'src/types/trips/Trips';
import { getClassNameFor } from '../../../common/helpers';
import UserBlock from '../presentation/UserBlock';
import PayoutLink from '../PayoutLink';
import s from './OfferSentMessage.module.scss';

type OfferSentMessageProps = {
  children: React.ReactNode;
  trip: ImmutableTrip;
};

const OfferSentMessage: React.FC<OfferSentMessageProps> = ({
  children,
  trip,
}) => {
  const hasPayoutInfo = useSelector(isPaymentMethodSet);

  return (
    <UserBlock user={trip.get('renter')}>
      <p className={getClassNameFor(s, 'message', classNames({ withoutPayout: !hasPayoutInfo }))}>
        {children}
      </p>
      {!hasPayoutInfo && (
        <p className={s.payoutInfo}>
          PS. We don’t have your payout information yet.
          Would you like to {' '}
          <PayoutLink>
            provide it now
          </PayoutLink>
          {' '} so we can transfer you funds?
        </p>
      )}
    </UserBlock>
  );
};

export default OfferSentMessage;
