import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Share } from 'react-facebook';

import { EXTERNAL_PATHS, NEW_TAB_ATTRIBUTES } from 'src/common/constants';
import { ReduxState } from 'src/types/reduxState';
import { ImmutableTrip } from 'src/types/trips/Trips';
import Button from 'src/common/components/Button';
import { getPaymentAddons, isPaymentMethodSet } from 'src/common/utils/reduxStoreSelectors';
import { fullName } from 'src/common/utils/user';
import { useCaptainSelectorContext } from 'src/inbox/providers/CaptainSelectorProvider';
import {
  isChargeEligible,
  userIsRenter,
} from '../../helpers';
import CaptainSelector from '../CaptainSelector';
import BookingConflicts from '../presentation/BookingConflicts';
import UserInfo from '../UserInfo';
import TripMenu from '../TripMenu';
import TripDetails from '../TripDetails';
import CTA from '../CTA';
import CtaWidget from '../CtaWidget';
import InstabookBookingLabel from '../InstabookBookingLabel';
import DateUnavailable from '../DateUnavailable';
import PaymentAddons from '../PaymentAddons';
import PayoutLink from '../PayoutLink';
import s from './ConfirmedBooking.module.scss';
import AddToCalendar from '../AddToCalendar';

const getHostname = ({ loading }: ReduxState) => loading.get('hostname');
const getTransaction = ({ transaction }: ReduxState) => transaction;

type Props = {
  trip: ImmutableTrip;
};

const ConfirmedBooking: FC<Props> = ({
  trip,
}) => {
  const {
    captain,
    captainSelectorEnabled,
    captainSelectorOpen,
    openCaptainSelector,
    closeCaptainSelector,
  } = useCaptainSelectorContext();
  const hostname = useSelector(getHostname);
  const transaction = useSelector(getTransaction);
  const shareUrl = `http${__DEV__ ? '' : 's'}://${hostname}${trip.getIn(['boat', 'listing_url'])}`;
  const isRenter = userIsRenter(trip);
  const quote = `I just ${!isRenter ? 'got another booking' : 'booked this'} on Getmyboat, the world's largest marketplace for boat rentals and water experiences.`;
  const paymentAddons = useSelector(getPaymentAddons);
  const hasPayoutInfo = useSelector(isPaymentMethodSet);
  const renderAddOnCharge = !isRenter && isChargeEligible(trip);

  return (
    <div className={s.root}>
      <UserInfo
        classNameModifier="bookingConfirmedScreen"
        size="medium"
        trip={trip}
      />
      {captainSelectorEnabled && (
        <CtaWidget
          title={captain ? 'Captain' : 'Select Captain'}
          description={captain ? (
            <>
              {fullName(captain)}
              <br />
              {captain.email}
            </>
          ) : 'To receive the Captain payment.'}
          onClick={openCaptainSelector}
          iconId={!captain ? 'captain-plus' : undefined}
          withEditIcon
          user={captain}
        />
      )}
      {captainSelectorEnabled && (
        <CaptainSelector
          closeModal={closeCaptainSelector}
          modalOpen={captainSelectorOpen}
          selectedCaptainId={captain?.id}
          tripId={trip.get('pk')}
        />
      )}
      {renderAddOnCharge && (
        <CtaWidget
          title="Add-On Charge"
          description="Send a payment request for additional services, equipment, and more."
          href={`${EXTERNAL_PATHS.CHARGE_ADD_ON}price/?trip=${trip.get('pk')}`}
          iconId="credit-card-charge"
        />
      )}

      {!isRenter && (
        <DateUnavailable
          trip={trip}
          classNameModifier="fixedWidth"
        />
      )}
      {!isRenter && (
        <BookingConflicts conflictingTrips={trip.get('conflicting_trips')} />
      )}

      {!!paymentAddons?.length && (
        <PaymentAddons
          addons={paymentAddons}
          isOwner={!isRenter}
        />
      )}

      {!isRenter && trip.get('is_instabook') && <InstabookBookingLabel />}

      <TripDetails
        trip={trip}
        transaction={transaction}
      />

      <AddToCalendar trip={trip} />

      {!isRenter && !hasPayoutInfo && (
        <p className={s.payoutInfo}>
          Please
          {' '}
          <PayoutLink>
            set up your payout information
          </PayoutLink>
          {' '}
          so we can transfer your funds when this booking is complete.
        </p>
      )}

      <CTA classNameModifier="withSidebar">
        <TripMenu trip={trip} />
        <Share
          mobileIframe
          href={shareUrl}
          quote={quote}
          hashtag="#boattrip"
        >
          {({ handleClick }: { handleClick: () => void }) => (
            <Button
              type="button"
              onClick={handleClick}
              {...NEW_TAB_ATTRIBUTES}
              data-test="share-with-friends"
              classNameModifier="noMargin"
              fullWidth
            >
              Share
            </Button>
          )}
        </Share>
      </CTA>
    </div>
  );
};

export default ConfirmedBooking;
