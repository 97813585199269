import React, { useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { HotKeys } from 'react-hotkeys';

type DropdownProps = {
  children: (
    options: {
      toggle: ()=> void;
      isOpen: boolean;
      open: () => void;
      close: () => void;
    }) => React.JSX.Element;
  className?: string;
  disableOnClickOutside?: boolean;
  initiallyOpen?: boolean;
  ignoreClass?: string;
  'data-test'?: string;
  onToggle?: (arg: boolean) => void;
};

const Dropdown: React.FC<DropdownProps> = ({
  children,
  className,
  // Note: this means that the prop is called
  // "disableOnClickOutside",
  // but it's called "disabled" within this component.
  disableOnClickOutside: disabled = false,
  initiallyOpen = false,
  // This tells react-cool-onclickoutside to ignore
  // certain elements during the event loop
  ignoreClass,
  'data-test': dataTest,
  onToggle,
}) => {
  const [openState, setOpen] = useState(initiallyOpen);
  const domRef = useOnclickOutside(
    () => setOpen(false),
    { disabled, ignoreClass },
  ) as unknown as React.RefObject<HTMLElement>;

  const toggle = () => setOpen(open => !open);
  const open = () => setOpen(true);
  const close = () => setOpen(false);

  useEffect(() => {
    if (typeof onToggle === 'function') {
      onToggle(openState);
    }
  }, [openState, onToggle]);

  return (
    <HotKeys
      handlers={{ close }}
      keyMap={{ close: 'esc' }}
      className={className}
      innerRef={domRef}
      data-test={dataTest}
    >
      {/* This looks wonky, but it basically means "call the children function
          as a component with the given props */}
      {children({
        toggle,
        isOpen: openState,
        open,
        close,
      })}
    </HotKeys>
  );
};

export default Dropdown;
