import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';

import getSessionData from 'src/instabook/utils/getSessionData';
import bookInstabook from 'src/instabook/utils/bookInstabook';
import { SessionData } from 'src/instabook/types';
import CloseButton from '../../../../common/components/CloseButton';
import FormError from '../../../../common/components/FormError';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { unpackApiError } from '../../../../common/helpers';
import useCheckout from '../../../../checkout/hooks/useCheckout';
import captureException from '../../../../common/utils/captureException';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import s from './RedirectToPaymentPage.module.scss';

const RedirectToPaymentPage: FC = () => {
  const { push } = useHistory();
  const location = useLocation();
  const returnUrl = getReturnUrl(location);
  const sessionData = useMemo(() => getSessionData(returnUrl), [returnUrl]);

  const { checkout, submitError } = useCheckout();
  const [errorMessage, setErrorMessage] = useState<string>();

  const processPaymentForInstabook = useCallback(async () => {
    try {
      const trip = await bookInstabook(sessionData as SessionData);
      await checkout(
        trip.pk,
        'instabook',
        returnUrl,
        () => {
          const sessionKey = returnUrl.split('sessionKey=').pop();
          if (sessionKey) {
            sessionStorage.setItem(sessionKey, JSON.stringify({
              ...sessionData,
              redirectBack: true,
            }));
          }
        },
      );
    } catch (err) {
      if (err instanceof Response) {
        setErrorMessage((await unpackApiError(err)).detail);
      } else {
        setErrorMessage('An unexpected error occurred');
        captureException(err);
      }
    }
  }, [checkout, returnUrl, sessionData]);

  useEffect(() => {
    if (sessionData && !sessionData.redirectBack) {
      processPaymentForInstabook();
    } else {
      // Redirect the user back to the instabook details page if they
      // land on this page and don't have the form data populated.
      // This handles the situation where the users uses the browser's
      // back button on the stripe checkout page.
      window.location.href = returnUrl;
    }
  }, [
    processPaymentForInstabook,
    push,
    returnUrl,
    sessionData]);

  return (
    <div className={s.container}>
      <Helmet>
        <title>Redirecting to Checkout</title>
      </Helmet>

      {(errorMessage || submitError) ? (
        <FormError
          error={errorMessage || submitError}
        />
      ) : (
        <>
          <h1 className={s.title}>Redirecting to Checkout</h1>
          <div className={s.loading}>
            <LoadingSpinner />
          </div>
        </>
      )}
      <CloseButton backUrl={returnUrl} />
    </div>
  );
};

export default RedirectToPaymentPage;
