import React, { FC, useCallback, useMemo } from 'react';
import { Form, Field } from 'react-final-form';
import { Map } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { HotKeys } from 'react-hotkeys';

import { useFeatureFlag } from 'src/common/hooks';
import type { ImmutableBoats } from '../../../../types/boat/BoatDetail';
import type { ReduxState } from '../../../../types/reduxState';
import { rffSubmitResponse } from '../../../../common/helpers';
import {
  getBoat, getInquiry, getInquiryHasMismatches,
} from '../../../../common/utils/reduxStoreSelectors';
import { PAGES } from '../../../constants';
import { trackEvent } from '../../../../common/tracking';
import { parse } from '../../../helpers';
import useOnSubmitLocationChange from '../../../../common/hooks/useOnSubmitLocationChange';
import { rffFormSubmit } from '../../../submit';
import { inspectComments, getBroadcastBoats, getInstabookListings } from '../../../ducks/inquiry';
import FormError from '../../../../common/components/FormError';
import TextArea from '../../../../common/components/inputs/TextArea';
import Button from '../../../../common/components/Button';
import s from './ExtrasForm.module.scss';

// Helper strings for google analytics events
const category = 'Booking Inquiry';
const action = 'Similar Listings';
const cantBeSubmitted = 'Can not be submitted';
const canBeBroadcasted = 'Can be broadcasted';

type ExtraValues = {
  comment: string;
};

const getExtraInitialValues = ({ inquiry }: ReduxState): ExtraValues => ({
  comment: inquiry.getIn(['details', 'comment'], ''),
});
const getInquiryMismatches = ({ inquiry }: ReduxState) => inquiry.get('mismatches', Map());

const nextPage = PAGES.CONTACT;
const ExtrasForm: FC = () => {
  const isInstabookListingsEnabled = useFeatureFlag('instaspike--inquiry-flow');
  const dispatch = useDispatch();
  const initialValues = useSelector(getExtraInitialValues);
  const boat = useSelector(getBoat);
  const inquiry = useSelector(getInquiry);
  const inquiryHasMismatches = useSelector(getInquiryHasMismatches);
  const mismatches = useSelector(getInquiryMismatches);
  const onSubmitSuccess = useOnSubmitLocationChange(nextPage);
  const inquiryPayload = useMemo(() => ({
    ...inquiry.get('details')
      .filter(d => d !== '')
      .toJS(),
    boat: boat.get('id'),
  }), [inquiry, boat]);
  const onSubmit = useCallback(
    (values: ExtraValues, form) => {
      rffFormSubmit(dispatch)(values, form);
      const { comment } = values;
      trackEvent('send_inquiry_s7_needs', {
        p1: comment.length ? 'Yes' : 'No',
      });
      return Promise.all(
        [
          dispatch(inspectComments(values.comment)),
          isInstabookListingsEnabled && dispatch(getInstabookListings(inquiryPayload)),
          dispatch(getBroadcastBoats(inquiryPayload)).then((boats: ImmutableBoats) => {
            if (inquiryHasMismatches) {
              const mismatchString = mismatches.keySeq().join(' ');
              if (boats.isEmpty()) {
              // There are no boats to broadcast to.
                trackEvent(action, {
                  event_category: category,
                  event_label: `${cantBeSubmitted} (${mismatchString}) | Can not be broadcasted`,
                });
              } else {
                trackEvent(action, {
                  event_category: category,
                  event_label: `${cantBeSubmitted} (${mismatchString}) | ${canBeBroadcasted}`,
                });
              }
            } else {
              trackEvent(action, {
                event_category: category,
                event_label: `Can be submitted | ${canBeBroadcasted}'`,
              });
            }
          }),
        ],
      )
        .then(onSubmitSuccess)
      // This takes care of unhappy responses and returns the appropriate
      // errors. It's maybe a slight misuse, because the arg is typically
      // the onSubmitSuccess logic, but this should work for now.
        .catch(rffSubmitResponse());
    },
    [
      dispatch,
      inquiryHasMismatches,
      mismatches,
      inquiryPayload,
      isInstabookListingsEnabled,
      onSubmitSuccess,
    ],
  );
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, submitError, submitting, values: { comment } }) => (
        <form onSubmit={handleSubmit} className={s.root}>
          <HotKeys
            handlers={{ submit: handleSubmit }}
            keyMap={{ submit: ['ctrl+enter', 'meta+enter'] }}
            tabIndex={undefined}
          >
            <div className={s.formGroup}>
              <Field
                autoFocus
                name="comment"
                placeholder="Comments (optional)"
                component={TextArea}
                parse={parse}
              />
            </div>
            {submitError && <FormError error={submitError} />}
            <div className={s.actionGroup}>
              <div className={s.formGroup}>
                <Button
                  type="submit"
                  submitting={submitting}
                  data-test={comment && comment.length > 0 ? 'next' : 'skip'}
                  fullWidth
                >
                  {comment && comment.length > 0 ? 'Next' : 'Skip'}
                </Button>
              </div>
            </div>
          </HotKeys>
        </form>
      )}
    </Form>
  );
};

export default ExtrasForm;
