import React, { FC, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import { ReduxState } from '../../../../types/reduxState';
import Svg from '../../../../common/components/Svg';
import MessagePanel from '../../presentation/MessagePanel';
import { PATHS } from '../../../../common/constants';
import { logoutThunk } from '../../../../common/ducks/user';

import s from './UserNotAuthorized.module.scss';

const UserNotAuthorized: FC = () => {
  const dispatch = useDispatch<ThunkDispatch<ReduxState, {}, AnyAction>>();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const onLogout = useCallback(() => {
    dispatch(logoutThunk()).then(() => {
      push(`${PATHS.LOGIN}?next=${pathname}`);
    });
  }, [push, dispatch, pathname]);
  return (
    <MessagePanel>
      <Svg
        href="#sailing-boat"
        className={s.boatIcon}
      />
      <p className={s.messageText}>
        Oh no! You&apos;re sailing adrift.
        You are not authorized to access this page. Please
        {' '}
        <button className={s.logoutButton} type="button" onClick={onLogout}>logout</button>
        {' '}
        and login again with the correct credentials.
      </p>
    </MessagePanel>
  );
};

export default UserNotAuthorized;
