import { FORM_ERROR } from 'final-form';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import captureException from '../../common/utils/captureException';
import loadRecaptchaSdk, { RECAPTCHA_COOKIE_NAME } from '../../common/utils/loadRecaptchaSdk';
import retry from '../../common/utils/retry';

const grecaptchaFormError = {
  [FORM_ERROR]: 'There was an error with reCAPTCHA. Please reload the page and try again.',
} as const;

type Grecaptcha = typeof grecaptcha;
/**
 * @see https://cloud.google.com/recaptcha-enterprise/docs/actions
 */
type GrecaptchaAction = 'login' | 'password_reset' | 'signup';

/**
 * Wraps the provided callback with recaptcha.
 * @param action The type of action being performed.
 * @param callback The callback to wrap with recaptcha.
 * @returns The wrapped callback to be used for the onSubmit action.
 */
const useGrecaptcha = <T extends Record<string, string>>(
  action: GrecaptchaAction,
  callback: (values: T) => Promise<unknown>,
) => {
  const [grecaptcha, setGrecaptcha] = useState<Grecaptcha | null>(null);

  // Load recaptcha when the hook is mounted
  useEffect(() => {
    loadRecaptchaSdk(({ grecaptcha: grecaptchaObject }: any) => setGrecaptcha(grecaptchaObject));
  }, []);

  // Sets the required cookie that the backend uses for recaptcha verification.
  const renewRecaptchaToken = useCallback(
    async () => grecaptcha?.enterprise.execute(__RECAPTCHA_API_KEY__, { action }).then(
      (token: string) => {
        Cookies.set(RECAPTCHA_COOKIE_NAME, token);
      },
      (reason: unknown) => {
        throw reason;
      },
    ),
    [action, grecaptcha?.enterprise],
  );

  // Wrapper that calls the required recaptcha functionality
  // without exposing unnecessary complexity.
  const withRecaptcha = useCallback(
    async (values: T) => {
      if (!grecaptcha) {
        return grecaptchaFormError;
      }

      return retry(1, async () => {
        await renewRecaptchaToken();
        return callback(values);
      }).catch((err) => {
        captureException(err);
        return grecaptchaFormError;
      });
    },
    [grecaptcha, renewRecaptchaToken, callback],
  );

  return withRecaptcha;
};

export default useGrecaptcha;
