import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';

import { formatDate } from 'src/common/utils/dateHelpers';
import { BoatDetail } from '../types/boat/BoatDetail';
import {
  INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD,
  INSTABOOK_CAPTAINED_FIELD,
  INSTABOOK_DEPARTURE_TIME_FLEXIBLE,
  INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_FIELD,
  INSTABOOK_EDITOR_DATE_FIELD,
  INSTABOOK_EDITOR_DATES_FIELD,
  INSTABOOK_EDITOR_TRIP_DURATION_FIELD,
  INSTABOOK_EDITOR_TRIP_TITLE_FIELD,
  INSTABOOK_FIXED_DEPARTURE_TIMES,
  INSTABOOK_FLEXIBLE_DEPARTURE_TIME_END,
  INSTABOOK_FLEXIBLE_DEPARTURE_TIME_START,
  INSTABOOK_INITIAL_FLEXIBLE_DEPARTURE_TIME_END,
  INSTABOOK_INITIAL_FLEXIBLE_DEPARTURE_TIME_START,
  INSTABOOK_MIN_DURATION,
  INSTABOOK_PRICE_FIELD,
  INSTABOOK_PUBLISHED_FIELD,
  CALENDAR_DATE_FORMAT_DATE_FNS,
  INSTABOOK_CAPTAIN_PRICE_FIELD,
} from './constants';
import { newTZDate, todayDate } from './helpers';
import { InstabookDefinition } from './types/Instabook';

export type InstabookFormState = {
  id?: number;
  [INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD]?: boolean;
  [INSTABOOK_CAPTAINED_FIELD]?: boolean;
  [INSTABOOK_DEPARTURE_TIME_FLEXIBLE]: boolean;
  [INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_FIELD]: string;
  [INSTABOOK_EDITOR_DATE_FIELD]: Date;
  [INSTABOOK_EDITOR_DATES_FIELD]: Date[];
  [INSTABOOK_EDITOR_TRIP_DURATION_FIELD]: number;
  [INSTABOOK_EDITOR_TRIP_TITLE_FIELD]: string;
  [INSTABOOK_FIXED_DEPARTURE_TIMES]: { id?: number; startTime: string }[];
  [INSTABOOK_FLEXIBLE_DEPARTURE_TIME_END]: string;
  [INSTABOOK_FLEXIBLE_DEPARTURE_TIME_START]: string;
  [INSTABOOK_PRICE_FIELD]: number;
  [INSTABOOK_CAPTAIN_PRICE_FIELD]: number;
  [INSTABOOK_PUBLISHED_FIELD]: boolean;
};

/**
 * Formats hours as HH:mm:ss
 */
const formatTripDuration = (hours: number) => `${`${hours}`.padStart(2, '0')}:00:00`;

/**
 * Builds trip title as per current design
 * TODO: update according to final design
 */
const formatTripTitle = (hours: number, currencySymbol: string, price: number) => (
  `${hours} Hours - ${currencySymbol}${price}`
);

const getInstabookTripTimes = (
  values: InstabookFormState,
): InstabookDefinition['instabook_trip_times'] => {
  const fixed = values[INSTABOOK_FIXED_DEPARTURE_TIMES];
  const isFlexible = values[INSTABOOK_DEPARTURE_TIME_FLEXIBLE];

  if (!isFlexible && fixed && fixed.length === 0) {
    return [];
  }

  if (fixed && fixed.length > 0) {
    return fixed.map(time => ({
      min_start_time: time.startTime,
      max_start_time: time.startTime,
    }));
  }

  return [{
    min_start_time: values[INSTABOOK_FLEXIBLE_DEPARTURE_TIME_START],
    max_start_time: values[INSTABOOK_FLEXIBLE_DEPARTURE_TIME_END],
  }];
};

export const prepareInstabookDataForAPI = (
  values: InstabookFormState,
  boat: BoatDetail,
): InstabookDefinition => {
  const captainCostEnabled = !!values[INSTABOOK_CAPTAINED_FIELD]
  && !values[INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD];
  return {
    id: values.id,
    boat_id: boat.id,
    date: formatDate(new Date(values[INSTABOOK_EDITOR_DATE_FIELD]), CALENDAR_DATE_FORMAT_DATE_FNS),
    dates: values[INSTABOOK_EDITOR_DATES_FIELD].map(date => (
      formatDate(date, CALENDAR_DATE_FORMAT_DATE_FNS)
    )),
    trip_length: formatTripDuration(values[INSTABOOK_EDITOR_TRIP_DURATION_FIELD]),
    captain_cost_included: values[INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD],
    captained: values[INSTABOOK_CAPTAINED_FIELD],
    subtotal: values[INSTABOOK_PRICE_FIELD],
    captain_subtotal: captainCostEnabled ? values[INSTABOOK_CAPTAIN_PRICE_FIELD] : 0,
    currency: boat.currency!,
    title: formatTripTitle(
      values[INSTABOOK_EDITOR_TRIP_DURATION_FIELD],
      boat.currency!,
      values[INSTABOOK_PRICE_FIELD],
    ),
    additional_information: values[INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_FIELD] ?? '',
    instabook_trip_times: getInstabookTripTimes(values),
    [INSTABOOK_PUBLISHED_FIELD]: values[INSTABOOK_PUBLISHED_FIELD],
  };
};
export const parseTripDuration = (trip: string): number => {
  const [hours] = trip.split(':');
  return (hours && Number(hours)) || 0;
};

const hasFlexibleTripTimes = (instabookDefinition?: InstabookDefinition | null) => {
  if (!instabookDefinition) {
    return false;
  }
  if (instabookDefinition?.instabook_trip_times?.length !== 1) {
    return false;
  }

  const tripTimes = instabookDefinition?.instabook_trip_times[0];
  return tripTimes.min_start_time !== tripTimes.max_start_time;
};

const getPreselectedDate = (maybeDate?: string | null): Date => {
  const minDate = addDays(todayDate(), 2);
  const selectedDate = typeof maybeDate === 'string' && maybeDate && newTZDate(maybeDate);
  const isValid = selectedDate && differenceInDays(selectedDate, minDate) >= 0;
  return isValid ? selectedDate : minDate;
};

export const getInitialInstabookValues = (
  instabook: InstabookDefinition | null,
  selectedDate: string | null,
): InstabookFormState => {
  const isFlexible = hasFlexibleTripTimes(instabook);
  const date = getPreselectedDate(selectedDate);
  return {
    id: instabook?.id,
    [INSTABOOK_EDITOR_TRIP_TITLE_FIELD]:
      instabook?.title ?? '',
    [INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_FIELD]:
      instabook?.additional_information?.trim() ?? '',
    [INSTABOOK_EDITOR_DATE_FIELD]:
      instabook?.date ? newTZDate(instabook?.date) : date,
    [INSTABOOK_EDITOR_DATES_FIELD]:
      instabook?.instabook_trip_dates
        ? instabook?.instabook_trip_dates.map(d => newTZDate(d.date))
        : [date],
    [INSTABOOK_EDITOR_TRIP_DURATION_FIELD]:
      instabook?.trip_length
        ? parseTripDuration(instabook?.trip_length ?? '')
        : INSTABOOK_MIN_DURATION,
    [INSTABOOK_DEPARTURE_TIME_FLEXIBLE]:
      isFlexible,
    [INSTABOOK_CAPTAINED_FIELD]:
      instabook?.captained,
    [INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD]:
      instabook?.captain_cost_included,
    [INSTABOOK_FLEXIBLE_DEPARTURE_TIME_START]:
      isFlexible
        ? instabook?.instabook_trip_times[0].min_start_time
        ?? INSTABOOK_INITIAL_FLEXIBLE_DEPARTURE_TIME_START
        : INSTABOOK_INITIAL_FLEXIBLE_DEPARTURE_TIME_START,
    [INSTABOOK_FLEXIBLE_DEPARTURE_TIME_END]:
      isFlexible
        ? instabook?.instabook_trip_times[0].max_start_time
        ?? INSTABOOK_INITIAL_FLEXIBLE_DEPARTURE_TIME_END
        : INSTABOOK_INITIAL_FLEXIBLE_DEPARTURE_TIME_END,
    [INSTABOOK_FIXED_DEPARTURE_TIMES]:
      isFlexible
        ? []
        : instabook?.instabook_trip_times?.map(
          (tripTime, index) => ({
            id: index,
            startTime: tripTime.min_start_time,
          }),
        ) ?? [],
    [INSTABOOK_PRICE_FIELD]:
      instabook?.subtotal ?? 0,
    [INSTABOOK_CAPTAIN_PRICE_FIELD]:
      instabook?.captain_subtotal ?? 0,
    [INSTABOOK_PUBLISHED_FIELD]:
      instabook?.[INSTABOOK_PUBLISHED_FIELD] ?? true,
  };
};

export const getTimeToBook = (
  instabookTripDate: string,
  instabookTripExpiryDate: string,
): number => differenceInHours(
  new Date(instabookTripDate),
  new Date(instabookTripExpiryDate),
);
