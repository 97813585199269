import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import {
  TITLE,
  SUPPORT_URLS,
} from '../../constants';
import { setLoadingComplete } from '../../ducks/loading';
import Layout from '../Layout';
import Header from '../Header';
import MessagePanel from '../../../inbox/components/presentation/MessagePanel';

const SuspendedPage = () => {
  const dispatch = useDispatch();

  useEffect(
    () => { dispatch(setLoadingComplete()); },
    [dispatch],
  );

  return (
    <Layout>
      <Helmet>
        <title lang="en">
          {TITLE.SUSPENDED}
        </title>
      </Helmet>

      <Header
        withNav={false}
        withSearchBar={false}
        classNameModifier="fixed bordered"
      />
      <MessagePanel>
        <h1>
          We need to gather some more information before you proceed.
        </h1>
        <p>
          We will send you an email with instructions about what to do next if needed.
          {' If you have any questions please '}
          <a
            href={SUPPORT_URLS.REQUEST}
            target="_blank"
            rel="noopener noreferrer"
          >
            contact support
          </a>.
        </p>
      </MessagePanel>
    </Layout>
  );
};

export default SuspendedPage;
