import { useMemo } from 'react';
import { format, formatDistanceToNowStrict, isPast } from 'date-fns';

import type { ImmutableTrip } from 'src/types/trips/Trips';
import { calculateTimezoneOffsetInHours, convertToTimeZone } from '../utils/time';
import { CUSTOM_OFFER_EXPIRY_STATES } from '../../inbox/constants';
import useTimeZones from './useTimeZones';

const useCustomOfferExpiry = (trip?: ImmutableTrip) => {
  const tripState = trip?.getIn(['state', 'state']);
  const { boatTimeZoneId, userTimeZoneId } = useTimeZones(trip);

  const timezoneOffsetInHours = useMemo(
    () => calculateTimezoneOffsetInHours(userTimeZoneId, boatTimeZoneId),
    [boatTimeZoneId, userTimeZoneId],
  );

  const renderTimeZoneInfo = timezoneOffsetInHours !== 0;
  const userTimeZoneLabel = renderTimeZoneInfo ? ` (${userTimeZoneId})` : '';
  const boatTimeZoneLabel = renderTimeZoneInfo ? ` (${boatTimeZoneId})` : '';
  const hasCustomExpiry = trip?.get('has_custom_expiry') ?? false;
  const expirationDateTimeString = trip?.get('offer_expiry_date_UTC');

  const expirationDateInBrowserTimezone = useMemo(
    () => expirationDateTimeString && new Date(expirationDateTimeString),
    [expirationDateTimeString],
  );

  const expirationDateInBoatTimezone = useMemo(
    () => expirationDateTimeString && convertToTimeZone(expirationDateTimeString, boatTimeZoneId),
    [boatTimeZoneId, expirationDateTimeString],
  );

  const offerExpired = useMemo(
    () => expirationDateInBrowserTimezone && isPast(expirationDateInBrowserTimezone),
    [expirationDateInBrowserTimezone],
  );

  const formattedExpirationDateInBrowserTimeZone = useMemo(
    () => expirationDateInBrowserTimezone
      && `${format(expirationDateInBrowserTimezone, "MMMM d 'at' h:mm aa")}${userTimeZoneLabel}`,
    [expirationDateInBrowserTimezone, userTimeZoneLabel],
  );

  const formattedExpirationDateInBoatTimezone = useMemo(
    () => expirationDateInBoatTimezone
      && `${format(expirationDateInBoatTimezone, "MMMM d 'at' h:mm aa")}${boatTimeZoneLabel}`,
    [boatTimeZoneLabel, expirationDateInBoatTimezone],
  );

  const confirmationPeriod = useMemo(
    () => expirationDateInBrowserTimezone && formatDistanceToNowStrict(
      expirationDateInBrowserTimezone,
    ),
    [expirationDateInBrowserTimezone],
  );

  const renderCustomExpiry = useMemo(
    () => hasCustomExpiry && CUSTOM_OFFER_EXPIRY_STATES.includes(tripState),
    [hasCustomExpiry, tripState],
  );

  return {
    confirmationPeriod,
    expirationDateInBoatTimezone,
    expirationDateInBrowserTimezone,
    formattedExpirationDateInBoatTimezone,
    formattedExpirationDateInBrowserTimeZone,
    hasCustomExpiry,
    offerExpired,
    renderCustomExpiry,
    renderTimeZoneInfo,
    timezoneOffsetInHours,
  };
};

export default useCustomOfferExpiry;
