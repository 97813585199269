import React, { useEffect, useState, type FC } from 'react';
import fetch from 'isomorphic-fetch';
import { captureException } from '@sentry/browser';

type SvgDefsProps = {
  href: string;
};

// Takes care of making SVG defs available to the DOM
// via fetch, which gets around the fact that SVGs
// don't support CORS.
//
const SvgDefs: FC<SvgDefsProps> = ({ href }) => {
  const [svg, setSvg] = useState<string>('');

  useEffect(() => {
    let mounted = true;
    // TODO: add some defensiveness around XSS, since this component is basically
    // an XSS machine.

    // attempt to fetch the SVG
    // no-cors makes it an opaque request, which allows it to work
    // across domains.
    const svgPromise = fetch(href)
      // Retry once upon failure
      .catch(() => fetch(href))
      // If it fails again, return false so that we know not to try and unpack it.
      .catch(() => false);
    // TODO: come up with a more elegant way of handling the SVG response if it succeeds
    // and skipping it if it fails.
    svgPromise.then((response: boolean | Response) => {
      if (!response) {
        // This block handles the network failure case, since we want to not log
        // the sentry error if it's just a network failure.
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw response;
      }
      if (response instanceof Response) {
        return response.text();
      }
      throw new Error('Invalid response type');
    })
      .then((svgContent: string) => {
        if (mounted) {
          setSvg(svgContent);
        }
      })
      .catch(error => {
        if (!error) {
          // This indicates a network failure, which we don't care that much about.
          return;
        }
        // Else log the error with sentry
        captureException(error, { tags: { note: 'SVG attachment failed' } });
      });
    return () => {
      mounted = false;
    };
  }, [href]);

  return (
    <div
      style={{
        overflow: 'hidden',
        width: 0,
        height: 0,
      }}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};

export default SvgDefs;
