import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { ImmutableTrip } from 'src/types/trips/Trips';
import { getOffer } from 'src/common/utils/reduxStoreSelectors';
import Card from '../../presentation/Card';
import {
  modified as tripModified,
  offerModified,
} from '../../../helpers';
import { offerTimeSelector } from './selectors';
import { open } from '../../../../common/ducks/zippy';
import {
  FORMS,
  REQUESTED_CHANGES_WARNING,
  TIME_FIELDS,
  UNSENT_CHANGES_WARNING,
} from '../../../constants';
import { MOMENT_FORMAT } from '../../../../common/constants';
import DateTime from '../../DateTime';
import EditButton from '../../EditButton';
import DateTimeSnippet from '../../../../common/components/DateTimeSnippet';
import NotificationMessage from '../../../../common/components/NotificationMessage';
import cardStyles from '../../presentation/Card/Card.module.scss';
import styles from './TimeCard.module.scss';
import TimeModal from './modal';

// Import multiple css modules into a component
const s = {
  ...cardStyles,
  ...styles,
};

type TimeCardProps = {
  errorRef?: React.RefObject<HTMLDivElement>;
  trip: ImmutableTrip;
  readOnly?: boolean;
  checkTripModification?: boolean;
  requestedChanges?: boolean;
};

const TimeCard: React.FC<TimeCardProps> = ({
  errorRef,
  trip,
  readOnly,
  // TODO: change how this works
  checkTripModification,
  requestedChanges,
}) => {
  const offer = useSelector(getOffer);
  const {
    departMoment,
    returnMoment,
  } = offerTimeSelector({ offer, trip });
  const dispatch = useDispatch();
  const openModal = () => dispatch(open(FORMS.TIME));
  const noTimeProvided = !(departMoment || returnMoment);
  const offerIsModified = offerModified(offer, trip, TIME_FIELDS);
  const tripIsModified = checkTripModification && tripModified(trip, TIME_FIELDS);
  const modified = offerIsModified || tripIsModified;
  const title = noTimeProvided ? 'Departure and return times to be determined.' : '';

  return (
    <Card
      highlighted={modified}
      classNameModifier={!readOnly ? 'hoverEffect' : ''}
    >
      {offerIsModified && (
        <NotificationMessage outdent={false}>
          {UNSENT_CHANGES_WARNING}
        </NotificationMessage>
      )}
      {requestedChanges && tripIsModified && !offerIsModified && (
        <NotificationMessage outdent={false}>
          {REQUESTED_CHANGES_WARNING}
        </NotificationMessage>
      )}
      {!readOnly && noTimeProvided && (
        <button
          type="button"
          onClick={openModal}
          className={s.suggest}
        >
          <NotificationMessage
            outdent={false}
            rootRef={errorRef}
          >
            Please suggest depart time to send an offer.
          </NotificationMessage>
        </button>
      )}
      <DateTimeSnippet type="time">
        <DateTime
          formatString={MOMENT_FORMAT.TIME}
          title={title}
          departDateTime={departMoment}
          returnDateTime={returnMoment}
          classNameModifier={modified ? 'modified' : ''}
        />
      </DateTimeSnippet>
      {!readOnly && (
        <>
          <EditButton openModal={openModal} />
          <TimeModal trip={trip} />
        </>
      )}
    </Card>
  );
};

export default TimeCard;
