import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useForm, useFormState } from 'react-final-form';

import { getBinaryKeyForCaptainOption } from 'src/common/utils/getListingCaptainOption';
import { FullBoatDetail } from '../../../types/boat/BoatDetail';
import { getClassNameFor } from '../../../common/helpers';
import Dropdown from '../../../common/components/Dropdown';
import FilterContainer from '../../../common/components/FilterContainer';
import ToggleButtonIcon from '../../../common/components/ToggleButton/Icon';
import type { InstabookFormState } from '../../instabook';
import {
  INSTABOOK_CAPTAINED_ERROR,
  INSTABOOK_CAPTAINED_FIELD,
  INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD,
  INSTABOOK_CAPTAIN_PRICE_FIELD,
} from '../../constants';
import { CAPTAIN_OPTION_KEYS } from '../../../common/constants';
import { useInstabookEditorContext } from '../../hooks';
import CaptainOptionInfo from './CaptainOptionInfo';
import CaptainOptionWarning from './CaptainOptionWarning';
import CaptainOptionItem from './CaptainOptionItem';
import CaptainTemplate from '../../../common/components/CaptainTemplate';
import getCaptainedCopy from '../../../common/utils/getCaptainedCopy';

import s from './InstabookCaptainOption.module.scss';

const MESSAGING = {
  notSelected: {
    id: 'initial',
    key: 'initial',
    heading: 'What type of charter is this?',
    onChange: 'unselectCaptainOptions',
  },
  captainProvided: {
    id: 'captainProvided',
    key: CAPTAIN_OPTION_KEYS.PROVIDED,
    onChange: 'setCaptainProvided',
    ...getCaptainedCopy(true, true),
  },
  captainArrangedSeparately: {
    id: 'captainArrangedSeparately',
    key: CAPTAIN_OPTION_KEYS.ARRANGED_SEPARATELY,
    onChange: 'setCaptainArrangedSeparately',
    ...getCaptainedCopy(true, false),
  },
  captainNotProvided: {
    id: 'captainNotProvided',
    key: CAPTAIN_OPTION_KEYS.NOT_PROVIDED,
    onChange: 'setCaptainNotProvided',
    ...getCaptainedCopy(false, false),
  },
};

const getSelectedOption = (values: InstabookFormState) => {
  if (values[INSTABOOK_CAPTAINED_FIELD] && values[INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD]) {
    return MESSAGING.captainProvided;
  }
  if (values[INSTABOOK_CAPTAINED_FIELD] === false) {
    return MESSAGING.captainNotProvided;
  }
  if (values[INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD] === false) {
    return MESSAGING.captainArrangedSeparately;
  }
  return MESSAGING.notSelected;
};

type InstabookCaptainOptionProps = {
  boat: null | FullBoatDetail;
  disabled: boolean;
};

const InstabookCaptainOption: FC<InstabookCaptainOptionProps> = ({ boat, disabled }) => {
  const { instabook } = useInstabookEditorContext();
  const { change, mutators } = useForm();
  const { values, errors, submitFailed } = useFormState<InstabookFormState>();
  const [currentBoat, setCurrentBoat] = useState<null | FullBoatDetail>(null);

  const options = useMemo(() => {
    const key = getBinaryKeyForCaptainOption(
      boat?.bareboat as boolean,
      boat?.captain_option as string,
    );
    const lookupMap = {
      // '001'
      1: [MESSAGING.captainNotProvided],
      // '010'
      2: [MESSAGING.captainArrangedSeparately],
      // '011'
      3: [MESSAGING.captainArrangedSeparately, MESSAGING.captainNotProvided],
      // '100'
      4: [MESSAGING.captainProvided],
      // '101'
      5: [MESSAGING.captainProvided, MESSAGING.captainNotProvided],
      // '110'
      6: [MESSAGING.captainProvided, MESSAGING.captainArrangedSeparately],
      // '111'
      7: [
        MESSAGING.captainProvided,
        MESSAGING.captainArrangedSeparately,
        MESSAGING.captainNotProvided,
      ],
    };
    return lookupMap[key];
  }, [boat]);

  const [selectedOption, setSelectedOption] = useState(() => getSelectedOption(values));

  const onOptionSelect = useCallback(
    (option, closeDropdown) => {
      setSelectedOption(option);
      mutators[option.onChange]();
      closeDropdown?.();
      if (option?.id !== MESSAGING.captainArrangedSeparately.id) {
        change(INSTABOOK_CAPTAIN_PRICE_FIELD, 0);
      }
    },
    [mutators, change],
  );

  useEffect(() => {
    if (boat && !currentBoat) {
      setCurrentBoat(boat);
    }
  }, [boat, currentBoat]);

  // Reset selectedOptions when the listing changes
  useEffect(() => {
    if (currentBoat && boat && (currentBoat.id !== boat.id)) {
      onOptionSelect(MESSAGING.notSelected, null);
      setCurrentBoat(boat);
    }
    let option = getSelectedOption(values);
    if (option.id === MESSAGING.notSelected.id && options?.length === 1) {
      [option] = options;
    }
    if (selectedOption.id !== option.id) {
      onOptionSelect(option, null);
    }
  }, [values, boat, options, onOptionSelect, selectedOption.id, currentBoat]);

  const showWarning = selectedOption !== MESSAGING.notSelected
    && (instabook?.captain_conflict || !!errors[INSTABOOK_CAPTAINED_ERROR]);
  const displayAsDropdown = values[INSTABOOK_CAPTAINED_FIELD] == null || showWarning;

  return boat ? (
    <div className={s.root}>
      <CaptainTemplate
        selectedOption={selectedOption}
        classNameModifier="instabookTripEditor"
      >
        <span className={s.label}>Charter Type</span>
        <CaptainOptionInfo />
      </CaptainTemplate>
      {options.length > 1 || displayAsDropdown ? (
        <Dropdown className={s.dropdownContainer}>
          {({ isOpen, toggle, close }) => (
            <>
              <button
                type="button"
                onClick={toggle}
                disabled={disabled}
                className={getClassNameFor(s, 'button', classNames({ error: showWarning }))}
              >
                <span className={s.description}>{selectedOption.heading}</span>
                <ToggleButtonIcon
                  isOpen={isOpen}
                  classNameModifier="instabook"
                />
              </button>
              {isOpen && (
                <FilterContainer classNameModifier="instabookEditor">
                  {options.map((option) => (
                    <CaptainOptionItem
                      key={option.id}
                      heading={option.heading}
                      description={option.body}
                      onClick={() => onOptionSelect(option, close)}
                      checked={selectedOption.id === option.id}
                    />
                  ))}
                </FilterContainer>
              )}
            </>
          )}
        </Dropdown>
      ) : (
        <div className={s.noOption}>
          <span className={s.description}>{selectedOption.heading}</span>
        </div>
      )}
      {((submitFailed && errors[INSTABOOK_CAPTAINED_ERROR] != null) || showWarning) && (
        <CaptainOptionWarning>{errors[INSTABOOK_CAPTAINED_ERROR]}</CaptainOptionWarning>
      )}
    </div>
  ) : null;
};

export default InstabookCaptainOption;
