import React, { FC, useCallback, useMemo } from 'react';
import moment, { Duration as MomentDuration, Moment } from 'moment';
import classNames from 'classnames';
import { FieldInputProps, FieldMetaState, useForm } from 'react-final-form';
import { Keys as BookingMotivatorKeys } from '../../types';
import { getClassNameFor } from '../../helpers';
import { newTZDate } from '../../utils/dateHelpers';
import useBookingMotivatorsContext from '../../hooks/useBookingMotivators';
import Svg from '../Svg';
import DatePickerPanel from '../DatePickerPanel';
import ReactDayPickerWrapper from '../ReactDayPickerWrapper';

import s from './DatePickerWithBookingMotivators.module.scss';

const DatePickerWithBookingMotivators: FC<Props> = ({
  allowUnavailableDateSelection,
  disabled,
  showClearDate,
  input: { name: fieldName, onBlur, onChange, onFocus, value },
  meta: { active, error, touched },
  placeholder,
  tripLength,
  portalId,
  withIcon = true,
  classNameModifier,
  isAlternativeDate,
  isAlternativeReturnDate,
  renderMotivators,
  isDayBlocked,
  DatePickerWrapper = null,
  datePickerWrapperLabel,
  isUsingReactDayPicker,
  selectedDays = [],
  setSelectedDays = () => {},
  isNight = false,
}) => {
  const {
    handleDateChange,
    state: { [fieldName]: fieldData },
  } = useBookingMotivatorsContext();

  const date = fieldData?.date ?? null;
  const initialDate = value ? moment(value) : undefined;
  const { getState } = useForm();
  // Get unformatted value from the form state
  const { values: { [fieldName]: formValue } } = getState();

  const back = useCallback(
    (prevDates: Date[]) => {
      if (prevDates) {
        // Reset form data and the local state to form state values
        onChange(prevDates);
        setSelectedDays(prevDates);
      } else {
        // Reset selected date back to field value stored in the form state
        // Moment is still needed here to pass correct date format
        handleDateChange(formValue && moment(formValue), fieldName);
      }
      onBlur();
    },
    [fieldName, formValue, handleDateChange, onChange, onBlur, setSelectedDays],
  );

  const save = useCallback(
    () => {
      if (date && typeof date === 'string') {
        onChange(newTZDate(date));
      } else if (selectedDays) {
        // This is for ReactDayPicker, just pass in selectedDays data directly to form
        onChange(selectedDays);
      }
      onBlur();
    },
    [date, onBlur, onChange, selectedDays],
  );

  const clear = useCallback(
    () => {
      onChange('');
      handleDateChange(null, fieldName);
    },
    [fieldName, handleDateChange, onChange],
  );

  const rootClassName = useMemo(
    () => getClassNameFor(s, 'root', classNames({ withValue: !!formValue })),
    [formValue],
  );

  const datePickerPanel = (
    <DatePickerPanel
      allowUnavailableDateSelection={allowUnavailableDateSelection}
      portalId={portalId}
      backHandler={back}
      continueHandler={save}
      fieldName={fieldName}
      open={active}
      tripLength={tripLength}
      isAlternativeDate={isAlternativeDate}
      isAlternativeReturnDate={isAlternativeReturnDate}
      classNameModifier={classNameModifier}
      renderMotivators={renderMotivators}
      isDayBlocked={isDayBlocked}
      isNight={isNight}
      initialDate={initialDate}
    />
  );

  const reactDayPickerWrapper = (
    <ReactDayPickerWrapper
      allowUnavailableDateSelection={allowUnavailableDateSelection}
      backHandler={back}
      classNameModifier={classNameModifier}
      continueHandler={save}
      formValueDates={formValue}
      selectedDays={selectedDays}
      setSelectedDays={setSelectedDays}
    />
  );

  const renderDatePicker = () => {
    let datePickerToRender = datePickerPanel;

    if (isUsingReactDayPicker) {
      datePickerToRender = reactDayPickerWrapper;
    }

    if (DatePickerWrapper) {
      return (
        <DatePickerWrapper
          datePickerWrapperLabel={datePickerWrapperLabel}
          selectedDays={selectedDays}
        >
          {datePickerToRender}
        </DatePickerWrapper>
      );
    }

    return datePickerToRender;
  };

  return (
    <div className={rootClassName}>
      <div className={getClassNameFor(s, 'inputWrapper', classNames(classNameModifier, { disabled }))}>
        {withIcon && (
          <label
            htmlFor={fieldName}
            className={s.label}
          >
            <Svg
              className={s.icon}
              href="#icon-calendar"
            />
          </label>
        )}

        <input
          disabled={disabled}
          className={getClassNameFor(s, 'input', classNameModifier)}
          id={fieldName}
          name={fieldName}
          readOnly
          onFocus={onFocus}
          placeholder={placeholder}
          value={value}
        />

        {showClearDate && value && (
          <button
            type="button"
            className={getClassNameFor(s, 'clearButton', classNameModifier)}
            onClick={clear}
          >
            ×
          </button>
        )}
      </div>

      {touched && error && (
        <label
          htmlFor={fieldName}
          className={s.error}
        >
          {error}
        </label>
      )}
      {renderDatePicker()}
    </div>
  );
};

type Props = {
  allowUnavailableDateSelection?: boolean;
  disabled?: boolean;
  showClearDate?: boolean;
  input: FieldInputProps<string, HTMLInputElement> & { name: BookingMotivatorKeys };
  meta: FieldMetaState<string>;
  placeholder?: string;
  tripLength?: MomentDuration;
  portalId?: string;
  withIcon?: boolean;
  classNameModifier?: string;
  isAlternativeDate?: boolean;
  isAlternativeReturnDate?: boolean;
  renderMotivators?: boolean;
  isDayBlocked?: (day: Moment) => boolean;
  DatePickerWrapper?: FC<{ datePickerWrapperLabel?: string }>;
  datePickerWrapperLabel?: string;
  isUsingReactDayPicker?: boolean;
  selectedDays?: Date[] | [];
  setSelectedDays?: (days: Date[] | []) => void;
  isNight?: boolean;
};

export default DatePickerWithBookingMotivators;
