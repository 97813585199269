import React, { FC } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { AdHocEventFormState } from 'src/calendar/types';
import {
  CALENDAR_DATE_FORMAT_DATE_FNS,
  DATE_FORMAT_FNS,
  UNAVAILABLE_EDITOR_END_DATE as END_DATE,
  MAX_NEXT_MONTHS,
  UNAVAILABLE_EDITOR_START_DATE as START_DATE,
  UNAVAILABLE_EDITOR_REPEAT_END_DATE as REPEAT_END_DATE,
} from 'src/calendar/constants';

import DateField from 'src/common/components/inputs/DateField';
import { addMonths } from 'date-fns';
import { maxDate, newTZDate } from 'src/calendar/helpers';
import s from './UnavailableDateFields.module.scss';

const UnavailableDateFields: FC = () => {
  const form = useForm();
  const {
    initialValues: { [START_DATE]: initialStartDate },
    values: { [START_DATE]: startDate, [END_DATE]: endDate },
  } = useFormState<AdHocEventFormState>();
  const maxEndDate = addMonths(newTZDate(initialStartDate as string), MAX_NEXT_MONTHS);
  return (
    <div className={s.root}>
      <div className={s.field}>
        <label
          className={s.label}
          htmlFor={START_DATE}
        >
          From
        </label>
        <Field name={START_DATE}>
          {({ input }) => {
            const changeStartDate = (value: string) => {
              input.onChange(value);
              const newMaxDate = endDate && maxDate(value, endDate);
              if (newMaxDate) {
                form.change(REPEAT_END_DATE, newMaxDate);
                form.change(END_DATE, newMaxDate);
              }
            };
            return (
              <DateField
                id={START_DATE}
                value={input.value}
                toDate={maxEndDate}
                dateFormat={DATE_FORMAT_FNS}
                outputDateFormat={CALENDAR_DATE_FORMAT_DATE_FNS}
                onDateSelect={changeStartDate}
                classNameModifier="unavailableEditor"
                showIcon
              />
            );
          }}
        </Field>
      </div>
      <div className={s.field}>
        <label
          className={s.label}
          htmlFor={END_DATE}
        >
          To
        </label>
        <Field name={END_DATE}>
          {({ input }) => {
            const changeEndDate = (value: string) => {
              input.onChange(value);
              const newMaxDate = endDate && maxDate(value, endDate);
              if (newMaxDate) {
                form.change('repetitions_end_date', newMaxDate);
              }
            };
            return (
              <DateField
                id={END_DATE}
                value={input.value}
                fromDate={startDate ? newTZDate(startDate) : undefined}
                toDate={maxEndDate}
                dateFormat={DATE_FORMAT_FNS}
                outputDateFormat={CALENDAR_DATE_FORMAT_DATE_FNS}
                onDateSelect={changeEndDate}
                classNameModifier="unavailableEditor"
                showIcon
              />
            );
          }}
        </Field>
      </div>
    </div>
  );
};

export default UnavailableDateFields;
