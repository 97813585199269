import React, { useEffect, useState, FC, useCallback } from 'react';
import { Switch, Route, useRouteMatch, RouteProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { parse } from 'query-string';
import { compose } from 'lodash/fp';

import CaptainSelectorProvider from 'src/inbox/providers/CaptainSelectorProvider';
import {
  PAGES,
  COMPLETE_STATES,
  CONFIRMED_STATES,
  INQUIRY_CANCELLED_STATES,
  LAPSED_STATES,
  BOOK_NOW_STATES,
  OFFER_SENT_STATES,
  MOVED_STATES,
  ASK_TO_SEND_OFFER,
  TRIP_STATES,
} from '../../constants';
import { isUserBanned, isUserSuspended, userIsRenter } from '../../helpers';
import {
  AccountBanned,
  AccountSuspended,
  Booking,
  BookNow,
  Cancelled,
  Complete,
  Confirmed,
  EditOffer,
  Insurance as InsurancePage,
  Lapsed,
  MessagesSwitcher,
  Moved,
  OfferSent,
  OwnerChangesRequested,
  Price,
  RenterChangesRequested,
  ReviewBooking,
  SendOffer,
  TripLoading,
  TripNotFound,
  UserNotAuthorized,
} from '../pages';
import { getTrip } from '../../../common/utils/reduxStoreSelectors';
import { getMessagesCall } from '../../ducks/messages';
import delay from '../../../common/utils/delay';
import { getTripCall, updateTrip } from '../../ducks/trips';
import { getBoatReviewCall, getRenterReviewCall } from '../../ducks/reviews';
import { clearTransaction, getTransactionCall } from '../../ducks/transaction';
import { ImmutableTrip } from '../../../types/trips/Trips';
import { clearOffer } from '../../ducks/offer';
import { clearPaymentAddons, getPaymentAddonsCall } from '../../ducks/paymentAddons';
import getInboxRouteInfo from '../../../pubsub/util/getInboxRouteInfo';
import { fetchBoatAndSetMap } from '../../../common/ducks/boatMap';

const { OWNER_CHANGES_REQUESTED, RENTER_CHANGES_REQUESTED } = TRIP_STATES;

const passTrip = (trip: ImmutableTrip) => (Page: any) => (
  (routeProps: RouteProps) => (
    <Page
      {...routeProps}
      trip={trip}
    />
  )
);

type MatchParams = {
  tripId: string;
};

const Trip: FC = () => {
  const [loading, setLoading] = useState(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const dispatch = useDispatch();
  const getTheTrip = compose(dispatch, getTripCall);
  const getPaymentAddons = compose(dispatch, getPaymentAddonsCall);
  const clearOutPaymentAddons = compose(dispatch, clearPaymentAddons);
  const getMessages = compose(dispatch, getMessagesCall);
  const getTransaction = compose(dispatch, getTransactionCall);
  const flushTransaction = compose(dispatch, clearTransaction);
  const getRenterReview = compose(dispatch, getRenterReviewCall);
  const getBoatReview = compose(dispatch, getBoatReviewCall);
  const clearOutOffer = compose(dispatch, clearOffer);
  const updateTheTrip = compose(dispatch, updateTrip);
  const getBoatDetails = compose(dispatch, fetchBoatAndSetMap);

  const match = useRouteMatch<MatchParams>();
  const url = match?.url;
  const tripId = match?.params.tripId as string;
  const trip = useSelector(getTrip(tripId));
  const tripState = trip?.getIn(['state', 'state']);

  const updateTripDetails = useCallback(
    async (id) => {
      setLoading(true);

      const qs = parse(window.location.search);
      // This delay is to reduce the likelyhood of the frontend requesting the trip state before the
      // stripe webhook has completed being processed on the backend.
      const delayDuration = qs.session_id != null ? 1500 : 0;
      // Messages are gotten first...
      const messagePromise = getMessages(id)
        .then((promise: any) => delay(delayDuration, promise))
        .then(async (response: any) => {
          setIsUnauthorized(false);
          return response;
        })
        .catch((response: any) => {
          setIsUnauthorized(response.status === 401);
        });

      const tripInfo = await getTheTrip(id);
      const boatId: string = tripInfo.get('boat').get('id');
      const tripHasCharges = tripInfo.getIn(['state', 'has_charges']);
      const isRenter = userIsRenter(tripInfo);
      const boatCurrency = tripInfo.getIn(['listing_currency', 'code']);

      // TODO: implement a better catch here.
      // Which clears out the unreads, which means that when we get the
      // trip a second time, it shows up with everything read.
      const boatPromise = messagePromise.then(
        // Only pass along the boatCurrency if user is not the renter
        () => getBoatDetails(boatId, !isRenter && boatCurrency),
      );
      // const tripPromise = messagePromise.then(() => getTheTrip(id));
      // How do these work again? Do they throw on 404? Why do they not cause issues here?
      const renterReviewPromise = messagePromise.then(() => getRenterReview(id));
      const boatReviewPromise = messagePromise.then(() => getBoatReview(id));
      // This can be done independently of the trip fetching
      const transactionPromise = getTransaction(id).catch((response: { status: number }) => {
        // This just means the offer doesn't have a subtotal/currency
        if (response.status !== 400) {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw response;
        }
      });

      const paymentAddonsPromise = tripHasCharges
        ? getPaymentAddons(id, isRenter)
        : Promise.resolve();

      // Clear previously set transaction
      flushTransaction();

      // Execute all of them, short-circuit if one of them fails,
      // and squelch the error.
      Promise.all([
        messagePromise,
        boatPromise,
        renterReviewPromise,
        boatReviewPromise,
        transactionPromise,
        paymentAddonsPromise,
      ])
        .catch(() => {})
        .finally(() => setLoading(false));
    },
    [
      flushTransaction,
      getBoatDetails,
      getBoatReview,
      getMessages,
      getRenterReview,
      getTheTrip,
      getTransaction,
      getPaymentAddons,
    ],
  );

  useEffect(() => {
    clearOutOffer();
    clearOutPaymentAddons();
    updateTripDetails(tripId);

    // Reset unseen state change when the user is on the trip that receives a state change
    const { view } = getInboxRouteInfo();
    const hasUnseenStateChanges = trip?.get('has_unseen_state_change', false);
    if (hasUnseenStateChanges && view === null) {
      const updatedTrip = trip?.update('has_unseen_state_change', () => false);
      updateTheTrip(updatedTrip);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId]);

  if (isUnauthorized) {
    return <UserNotAuthorized />;
  }

  if (isEmpty(trip)) {
    return <TripNotFound />;
  }

  if (loading) {
    return <TripLoading />;
  }

  const passThisTrip = passTrip(trip);

  return (
    <CaptainSelectorProvider value={trip}>
      <Switch>
        <Route
          path={`${url}/${PAGES.PRICE}/`}
          render={passThisTrip(Price)}
        />
        <Route
          path={`${url}/${PAGES.EDIT}/`}
          render={passThisTrip(EditOffer)}
        />
        <Route
          path={`${url}/${PAGES.MESSAGES}/`}
          render={passThisTrip(MessagesSwitcher)}
        />
        <Route
          path={`${url}/${PAGES.REVIEW_BOOKING}/`}
          render={passThisTrip(ReviewBooking)}
        />
        <Route
          path={`${url}/${PAGES.INSURANCE}/`}
          render={passThisTrip(InsurancePage)}
        />
        {COMPLETE_STATES.includes(tripState) && <Route render={passThisTrip(Complete)} />}
        {CONFIRMED_STATES.includes(tripState) && <Route render={passThisTrip(Confirmed)} />}
        {INQUIRY_CANCELLED_STATES.includes(tripState) && <Route render={passThisTrip(Cancelled)} />}
        {LAPSED_STATES.includes(tripState) && <Route render={passThisTrip(Lapsed)} />}
        {BOOK_NOW_STATES.includes(tripState) && <BookNow trip={trip} />}
        {tripState === OWNER_CHANGES_REQUESTED && (
          <Route render={passThisTrip(OwnerChangesRequested)} />
        )}
        {tripState === RENTER_CHANGES_REQUESTED && (
          <Route render={passThisTrip(RenterChangesRequested)} />
        )}
        {OFFER_SENT_STATES.includes(tripState) && <Route render={passThisTrip(OfferSent)} />}
        {MOVED_STATES.includes(tripState) && <Route render={passThisTrip(Moved)} />}
        {ASK_TO_SEND_OFFER.includes(tripState) && <Route render={passThisTrip(SendOffer)} />}
        {isUserBanned(trip) && <Route render={passThisTrip(AccountBanned)} />}
        {isUserSuspended(trip) && <Route render={passThisTrip(AccountSuspended)} />}
        {/* TODO: divide Booking component */}
        <Route render={passThisTrip(Booking)} />
      </Switch>
    </CaptainSelectorProvider>
  );
};

export default Trip;
