import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  ReactNode,
} from 'react';
import { Link } from 'react-router-dom';

import { getClassNameFor } from '../../helpers';
import s from './BackButton.module.scss';
import Icon from '../IconDS22';

type BackProps = {
  className?: string;
  children?: ReactNode;
  classNameModifier?: string;
};

type GeneralBackProps = {
  Component: FC<BackProps>;
  componentClassName: string;
  classNameModifier?: string;
  children?: ReactNode;
};

export const GeneralBack = ({
  // Provided by HOC
  Component,
  componentClassName,
  // Provided by instance
  classNameModifier = '',
  children = 'Back',
  ...restProps
}: GeneralBackProps) => (
  <div className={getClassNameFor(s, 'root', classNameModifier)}>
    <Component
      className={getClassNameFor(s, componentClassName, classNameModifier)}
      {...restProps}
    >
      <Icon id="caret-left" size="l" />
      <span className={s.word}>
        <span className={s.text}>
          {children}
        </span>
      </span>
    </Component>
  </div>
);

// These two "components" are needed because we want to
// be able to pass a component into GeneralBack, but
// "button" and "a" aren't recognized as components, and
// React doesn't export them as such. This works around that.
type ButtonProps = {
  children?: ReactNode;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
const Button: FC<ButtonProps> = props => <button {...props} type="button" />;

type AnchorProps = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;
// eslint-disable-next-line jsx-a11y/anchor-has-content
const A: FC<AnchorProps> = props => <a {...props} />;

export const BackButton: FC<BackProps & ButtonProps> = (props: ButtonProps) => (
  <GeneralBack Component={Button} componentClassName="button" {...props} />
);

type LinkProps = BackProps & Parameters<typeof Link>[0];
export const BackLink: FC<LinkProps > = (props: any) => (
  <GeneralBack Component={Link} componentClassName="link" {...props} />
);

export const BackExternalLink: FC<AnchorProps> = (props: AnchorProps) => (
  <GeneralBack Component={A} componentClassName="link" {...props} />
);
