import classNames from 'classnames';
import React, {
  FC, ReactNode,
} from 'react';
import { useField } from 'react-final-form';
import Icon from 'src/common/components/IconDS22';
import { getClassNameFor } from 'src/common/helpers';
import styles from './InputWrapper.module.scss';
import InputError from '../InputError';

type Props = {
  children: ReactNode;
  name: string;
  classNameModifier?: string;
  showIcon?: boolean;
  showErrorOnTouch?: boolean;
  /**
   * Show warning icon next to error message
   */
  showErrorMessageIcon?: boolean;
  highlightBorder?: boolean;
  preventErrorMessageLayoutShift?: boolean;
  centerErrorMessage?: boolean;
  manualError?: string;
};
const InputWrapper: FC<Props> = ({
  children,
  name,
  classNameModifier = '',
  showIcon = false,
  showErrorMessageIcon = false,
  highlightBorder = true,
  /**
   * Show error message only after user touched the field.
   */
  showErrorOnTouch = false,
  preventErrorMessageLayoutShift = true,
  centerErrorMessage = false,
  manualError,
}) => {
  const {
    meta: {
      dirtySinceLastSubmit, error, submitError, touched,
    },
  } = useField(name);
  const hasError = showErrorOnTouch ? !!error && !!touched : !!error;
  const hasSubmitError = !dirtySinceLastSubmit && submitError;
  return (
    <div
      className={getClassNameFor(
        styles,
        'root',
        classNames(
          {
            error: (hasError || hasSubmitError) && highlightBorder,
            centerErrorMessage,
          },
          classNameModifier,
        ),
      )}
    >
      {children}
      {(hasError || hasSubmitError || manualError) && (
        <>
          {showIcon && (
            <Icon
              id="warning-filled"
              className={getClassNameFor(styles, 'icon', classNameModifier)}
            />
          )}
          <InputError
            preventLayoutShift={preventErrorMessageLayoutShift}
            showErrorMessageIcon={showErrorMessageIcon}
            centered={centerErrorMessage}
          >
            {manualError || error || submitError}
          </InputError>
        </>
      )}
    </div>
  );
};

export default InputWrapper;
