import React, { FC, useCallback, useState } from 'react';
import { Field, Form, type FieldRenderProps } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import Button from 'src/common/components/Button';
import Icon from 'src/common/components/IconDS22';
import Modal from 'src/common/components/Modal';
import { BackButton } from 'src/common/components/BackButton';
import EmailField from 'src/common/components/fields/EmailField';
import Input from 'src/common/components/inputs/Input';
import { firstNameValidate, lastNameValidate } from 'src/common/components/fieldsets/ContactDetails';
import Notification from 'src/common/components/Notification';
import { combineValidators, rffValidators } from 'src/common/validation';
import { apiFetchBase } from 'src/core/fetch';
import { fetchCatch } from 'src/core/sentry';
import { CAPTAIN_HASH_URLS } from 'src/inbox/constants';
import PhoneInput from 'src/common/components/PhoneInput';
import type { LinkedCaptains } from 'src/types/boat/LinkedCaptains';
import { trackEvent } from 'src/common/tracking';
import s from './CaptainSelector.module.scss';

export const emailValidate = combineValidators([
  rffValidators.required({ message: 'Please provide an email address.' }),
  rffValidators.email({ message: 'Invalid email address' }),
]);

type CaptainInviteFormProps = {
  getCaptains: () => Promise<LinkedCaptains>;
  tripId: number;
};

type CaptainInviteFormValues = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string;
};

const CaptainInviteForm: FC<CaptainInviteFormProps> = ({
  getCaptains,
  tripId,
}) => {
  const { push } = useHistory();
  const [captainName, setCaptainName] = useState(null);
  const [inviteSent, setInviteSent] = useState(false);
  const goBack = () => {
    getCaptains()
      .then(() => push(CAPTAIN_HASH_URLS.SELECT));
    trackEvent('captain_invite_close');
  };

  const inviteCaptainCall = useCallback(async (formData: CaptainInviteFormValues) => apiFetchBase(
    `/trips/${tripId}/invite_captain/`,
    {
      method: 'PATCH',
      body: JSON.stringify(formData),
    },
  ), [tripId]);

  const onSubmit = useCallback(
    (values, form) => inviteCaptainCall(values)
      .then(async (response) => {
        if (response.ok) {
          setCaptainName(values.first_name);
          setInviteSent(true);
          form.restart();
          trackEvent('captain_invite_sent');
        }
        return response;
      })
      .catch(fetchCatch),
    [inviteCaptainCall],
  );

  return (
    <Form<CaptainInviteFormValues>
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitting, submitError }) => (
        <form
          className={s.root}
          onSubmit={handleSubmit}
        >
          <Modal.Header>
            <BackButton onClick={goBack} />
            <h5 className={s.heading}>
              <Icon id="captain" />
              Invite Captain
            </h5>
          </Modal.Header>
          <Modal.Body>
            <fieldset className={s.fieldset}>
              {inviteSent && (
                <div className={s.notification}>
                  <Notification
                    heading={`Invite Sent to ${captainName}`}
                    message="Send another invite to keep adding captains."
                  />
                </div>
              )}
              <p className={s.description}>
                We’ll email instructions and a magic link to sign up. Your captains can be
                selected once they’ve been successfully registered and linked to your account.
              </p>
              <EmailField
                name="email"
                label="Email*"
                placeholder=""
                validate={emailValidate}
                classNameModifier="noMargin"
              />
              <Field
                type="text"
                name="first_name"
                label="First Name*"
                component={Input as React.ComponentType<FieldRenderProps<string, HTMLElement, any>>}
                validate={firstNameValidate}
                classNameModifier="noMargin"
              />
              <Field
                type="text"
                name="last_name"
                label="Last Name*"
                component={Input as React.ComponentType<FieldRenderProps<string, HTMLElement, any>>}
                validate={lastNameValidate}
                classNameModifier="noMargin"
              />
              <Field
                name="phone"
                label="Phone Number"
                component={PhoneInput}
                classNameModifier="noMargin"
              />
            </fieldset>
          </Modal.Body>
          <Modal.Footer>
            {submitError && (
              <div className={s.error}>
                {submitError}
              </div>
            )}
            <Button
              type="reset"
              onClick={goBack}
              classNameModifier="secondary noMargin"
            >
              Done
            </Button>
            <Button
              type="submit"
              classNameModifier="noMargin"
              disabled={submitting}
              submitting={submitting}
            >
              Send Invite
            </Button>
          </Modal.Footer>
        </form>
      )}
    </Form>
  );
};

export default CaptainInviteForm;
