import React, { FC, ReactNode, useMemo } from 'react';
import { DayPickerSingleDateController as DayPicker } from 'react-dates';
import 'react-dates/initialize';
import moment, { Moment } from 'moment';

import { useMediaQuery, sharedMediaQueries } from '../../hooks';
import s from './DatePicker.module.scss';

const DatePicker: FC<Props> = ({
  isDayBlocked,
  isDayHighlighted,
  isOutsideRange,
  noNavPrevButton,
  onDateChange,
  onNextMonthClick,
  onPrevMonthClick,
  renderCalendarInfo,
  renderDayContents,
  initialDate,
}) => {
  const sizeM = useMediaQuery(sharedMediaQueries.m);
  const numberOfMonths = sizeM ? 2 : 1;

  const handleInitialVisibleMonth = useMemo(() => (
    initialDate ? () => initialDate : () => moment()
  ), [initialDate]);

  return (
    <div className={s.root}>
      <DayPicker
        daySize={40} // also aligns table headers and space between months
        noNavPrevButton={noNavPrevButton}
        numberOfMonths={numberOfMonths}
        onDateChange={onDateChange}
        onNextMonthClick={onNextMonthClick}
        onPrevMonthClick={onPrevMonthClick}
        renderCalendarInfo={renderCalendarInfo}
        renderDayContents={renderDayContents}
        isDayBlocked={isDayBlocked}
        isOutsideRange={isOutsideRange}
        isDayHighlighted={isDayHighlighted}
        hideKeyboardShortcutsPanel
        initialVisibleMonth={handleInitialVisibleMonth}
        focused
      />
    </div>
  );
};

type Props = {
  isDayBlocked?: (date: Moment) => boolean;
  isDayHighlighted?: (date: Moment) => boolean;
  isOutsideRange?: (date: Moment) => boolean;
  noNavPrevButton?: boolean;
  onDateChange: (date: Moment | null) => void;
  onNextMonthClick?: (date: Moment) => void;
  onPrevMonthClick?: (date: Moment) => void;
  renderCalendarInfo?: () => ReactNode;
  renderDayContents?: (date: Moment) => ReactNode;
  initialDate?: Moment;
};

export default DatePicker;
