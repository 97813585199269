import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../../common/helpers';
import { getPastPresentFutureBooleans, newTZDate, startOfMonth, todayDate } from '../../helpers';
import useScrollingMonthUpdate from '../../hooks/useScrollingMonthUpdate';
import Agenda from '../Agenda';
import s from './CalendarMonthAgendaView.module.scss';
import { GenericEvent } from '../../types';
import { isCalendarEvent, isUnavailableEvent } from '../../utils/calendar';

export type CalendarMonthAgendaViewProps = {
  events: GenericEvent[];
  startDate: string;
};

const CalendarMonthAgendaView: FC<CalendarMonthAgendaViewProps> = ({ events, startDate }) => {
  const firstDayOfMonth = useMemo(() => startOfMonth(newTZDate(startDate)), [startDate]);
  const { isPast, isFuture, isPresent } = useMemo(
    () => getPastPresentFutureBooleans(newTZDate(startDate), todayDate()),
    [startDate],
  );

  const filteredEvents = useMemo(
    () => events.filter(event => isCalendarEvent(event) || isUnavailableEvent(event)),
    [events],
  );

  const ref = useScrollingMonthUpdate<HTMLDivElement>(startDate);

  return (
    <div ref={ref}>
      <Agenda
        className={classNames(
          getClassNameFor(s, 'root', classNames({ past: isPast || isPresent })),
          classNames({
            currentMonth: isPresent,
            pastMonth: isPast,
            futureMonth: isFuture,
          }),
        )}
        events={filteredEvents}
        date={firstDayOfMonth}
      />
    </div>
  );
};

export default CalendarMonthAgendaView;
