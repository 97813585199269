import React, {
  useCallback,
  useReducer,
  useState,
  useContext,
  createContext,
  useMemo,
  useEffect,
  useRef,
  FC,
  ReactNode,
} from 'react';
import { stringify } from 'query-string';
import { keyBy } from 'lodash';
import { useRouteMatch } from 'react-router-dom';
import { formatDate } from 'src/common/utils/dateHelpers';
import apiFetch from '../../core/fetch';
import {
  EVENT_TYPES,
  STATE_TO_EVENT_TYPE_LOOKUP,
  UNAVAILABLE,
  INSTABOOK,
  CALENDAR_DATE_FORMAT_DATE_FNS,
} from '../constants';
import { useListingsFilterContext } from './useListingsFilter';
import useCalendarFocus from './useCalendarFocus';
import useBoats, { BoatDetailsLookup } from '../../common/hooks/useBoats';
import useDropdownMonth from './useDropdownMonth';
import {
  addMonths,
  differenceInMonths,
  newTZDate,
  startOfMonth,
  subMonths,
  todayDate,
  endOfMonth,
  serializeInstabookEvents,
} from '../helpers';
import { PATHS } from '../../common/constants';
import captureException from '../../common/utils/captureException';
import captureMessage from '../../common/utils/captureMessage';
import {
  CalendarEvent,
  CalendarMonthData,
  GenericEvent,
  GetInstabookAllResponse,
  InstabookEvent,
  UnavailableEvent,
} from '../types';
import { BoatDetail, FullBoatDetail } from '../../types/boat/BoatDetail';
import {
  isInstabookEvent,
  isCalendarEvent,
  isUnavailableEvent,
  sortByDate,
  groupByMonth,
} from '../utils/calendar';
import loadAllPages from '../../common/utils/loadAllPages';

type UseCalendar = {
  /** Tracks if the required boat data has been loaded to be merge with the event data */
  initialised: boolean;
  events: CalendarMonthData[];
  next: {
    canLoad: boolean;
    isLoading: boolean;
    loadMore: () => Promise<void>;
  };
  previous: {
    canLoad: boolean;
    isLoading: boolean;
    loadMore: () => Promise<void>;
  };
  replaceAdHocEvents: (adHocEventId: number, newEvents?: CalendarEvent[]) => void;
  updateInstabookTrips: (instabookId: number, updatedEvents?: GenericEvent[]) => void;
  updateBlockingEvent: (blockEvents: CalendarEvent[]) => void;
  changeStatusFilter: (type: StatusFilterActionType, filterName: string) => void;
  activeMonthRef: React.MutableRefObject<string>;
  dropdownMonthRef: React.MutableRefObject<HTMLSpanElement | null>;
  /** HTML string */
  dropdownMonth: string;
  setDropdownMonth: (date: string) => void;
  setActiveMonth: (date: string) => void;
  refresh: () => Promise<void>;
  statusFilters: string[];
  handleStatusFilterChange: (event: { target: { checked: boolean; name: string } }) => void;
  handleShowAllStatusFilters: (event: { target: { checked: boolean } }) => void;
  instabookEnabled: boolean;
  instabookWarningCache: React.MutableRefObject<Map<string, string | undefined>>;
  getInstabooksOnFilterChange: (boatHashId: string) => Promise<void>;
};

const calendarApiBaseUrl = '/calendar/events/';
const instabookApiBaseUrl = '/instabook/all_for_dates/';
const USER_NOT_AUTHENTICATED = 'User not authenticated';

export const MAX_PREVIOUS_MONTHS = 12;
export const MAX_NEXT_MONTHS = 6;
export const MAX_NEXT_MONTHS_INSTABOOK_OWNER = 12;

const redirectToLogin = () => window.location.assign(`${PATHS.LOGIN}?next=${PATHS.CALENDAR}`);

type StatusFilterAction =
  | {
    type: 'add' | 'remove';
    filterName: string;
  }
  | {
    type: 'showAll' | 'reset';
  };

type StatusFilterActionType = StatusFilterAction['type'];
type StatusFilterState = string[];

const statusFilterReducer = (
  activeFilters: StatusFilterState,
  action: StatusFilterAction,
): StatusFilterState => {
  switch (action.type) {
    case 'add':
      return [...activeFilters, action.filterName];
    case 'remove':
      return activeFilters.filter((activeFilterName) => activeFilterName !== action.filterName);
    case 'showAll':
      return EVENT_TYPES.filter((type) => type !== INSTABOOK);
    case 'reset':
      return [];
    default:
      throw new Error();
  }
};

type Period = 'month' | 'week' | 'day';

const getCalendarUrl = (startDate: string, period: Period = 'month') => (
  `${calendarApiBaseUrl}?${stringify({ period, start_date: startDate })}`
);

const getInstabookUrl = (
  startDate: string,
  page: number,
  /** Required to avoid database pressure from owners with many listings */
  boatId: number,
): string => {
  const queryParams = stringify({
    start_date: startDate,
    end_date: formatDate(endOfMonth(newTZDate(startDate)), CALENDAR_DATE_FORMAT_DATE_FNS),
    page,
    page_size: 50,
    ...(boatId && { boat_id: boatId }),
  });

  return `${instabookApiBaseUrl}?${queryParams}`;
};

/**
 * Creates a page loader for instabook events for the specified month.
 */
const createInstabookLoader = (
  startDate: string,
  boatId: number,
) => (
  async (page: number): Promise<GetInstabookAllResponse> => {
    const response = await apiFetch(getInstabookUrl(startDate, page, boatId));
    if (response.status === 403) {
      throw new Error(USER_NOT_AUTHENTICATED);
    }
    if (!response.ok) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw response;
    }
    return response.json();
  }
);

/**
 * Loads all the pages for instabook events for the specified month.
 */
const getInstabookEvents = async (
  startDate: string,
  boatId: number,
): Promise<InstabookEvent[]> => (
  loadAllPages(createInstabookLoader(startDate, boatId))
);

type GetEventOptions = {
  startDate: string;
  period: Period;
  loadActivities?: boolean;
  loadInstabooks?: boolean;
  boatId?: number;
};

/**
 * Loads all the calendar events for the specified month
 */
const getCalendarEvents = async ({
  startDate,
  period,
}: GetEventOptions): Promise<(CalendarEvent | UnavailableEvent)[]> => {
  const url = getCalendarUrl(startDate, period);
  const response = await apiFetch(url);
  if (response.status === 403) {
    throw new Error(USER_NOT_AUTHENTICATED);
  }
  if (!response.ok) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw response;
  }
  return response.json();
};

const getEvents = async ({
  startDate,
  period,
  loadActivities,
  loadInstabooks,
  boatId,
}: GetEventOptions): Promise<CalendarMonthData> => {
  const result: CalendarMonthData = {
    startDate,
    events: [],
  };

  const fetchPromises: Promise<GenericEvent[]>[] = [];
  if (loadActivities) {
    fetchPromises.push(getCalendarEvents({ startDate, period }));
  }
  if (loadInstabooks && boatId) {
    fetchPromises.push(getInstabookEvents(startDate, boatId));
  }

  const eventsArray = await Promise.all(fetchPromises);
  eventsArray.forEach((events) => {
    result.events.push(...events);
  });

  return result;
};

type CurriedSorter = <T extends Record<string, any>>(key: keyof T) => (a: T, b: T) => 1 | -1;
const sortAscendingBy: CurriedSorter = (key) => (a, b) => (a[key] > b[key] ? 1 : -1);

type DateSorter = <T extends { startDate: string }[]>(list: T) => T;
const sortByStartDate: DateSorter = (list) => list.sort(sortAscendingBy('startDate'));

const addBoatData = (boatsIndexedById: BoatDetailsLookup) => (
  (event: GenericEvent): GenericEvent & { boat: BoatDetail } => ({
    ...event,
    // The fallback is primarily to cater for calendar events related to non-active boats.
    boat: boatsIndexedById[event.boat_id] ?? {},
  })
);

type EventTypeOverrider = <T extends GenericEvent>(event: T) => T;
const overrideEventTypeBasedOnState: EventTypeOverrider = (event) => ({
  ...event,
  event_type:
    isCalendarEvent(event) || isUnavailableEvent(event)
      ? STATE_TO_EVENT_TYPE_LOOKUP[event?.state?.state!] ?? event.event_type
      : undefined,
});

export const mergeUpdatedAdHocCalendarEvents = (
  boatsIndexedById: BoatDetailsLookup,
  adHocEventId: number | undefined,
  updatedEvents: CalendarEvent[],
) => (state: CalendarMonthData[]): CalendarMonthData[] => {
  const eventsWithBoatData = updatedEvents
    .map(overrideEventTypeBasedOnState)
    .map(addBoatData(boatsIndexedById));
  const groupedByMonth = groupByMonth(eventsWithBoatData);
  return sortByStartDate(
    state.map((month) => ({
      ...month,
      events: month.events
        // Remove any items that are being replaced by the updates
        .filter(
          (event) => !adHocEventId
            || !(isUnavailableEvent(event) && event.adhoc_calendar_event_id === adHocEventId),
        )
        .concat(groupedByMonth[month.startDate] ?? []),
    })),
  );
};

export const mergeUpdatedInstabookTrips = (
  boatsIndexedById: BoatDetailsLookup,
  instabookId: number | undefined,
  updatedEvents: GenericEvent[],
) => (state: CalendarMonthData[]): CalendarMonthData[] => {
  const eventsWithBoatData = updatedEvents
    .map(overrideEventTypeBasedOnState)
    .map(addBoatData(boatsIndexedById));
  const groupedByMonth = groupByMonth(eventsWithBoatData);
  return sortByStartDate(
    state.map((month) => ({
      ...month,
      events: month.events
        // Remove any items that are being replaced by the updates
        .filter((event) => (
          !instabookId || !(isInstabookEvent(event) && event.id === instabookId)
        ))
        .concat(groupedByMonth[month.startDate] ?? []),
    })),
  );
};

export const updatedCalendarEvents = (
  boatsIndexedById: BoatDetailsLookup,
  updatedEvents: GenericEvent[],
) => (
  (state: CalendarMonthData[]): CalendarMonthData[] => {
    const eventIdsToRemove = keyBy(updatedEvents, (updatedEvent) => updatedEvent.id);
    const eventsWithBoatData = updatedEvents
      .map(overrideEventTypeBasedOnState)
      .map(addBoatData(boatsIndexedById));
    const groupedByMonth = groupByMonth(eventsWithBoatData);

    return sortByStartDate(
      state.map((month) => ({
        ...month,
        events: month.events
          // Remove the event being replaced by the update
          .filter((event) => !eventIdsToRemove[event.id])
          .concat(groupedByMonth[month.startDate] ?? [])
          .sort(sortByDate),
      })),
    );
  }
);

/**
 * @param month YYYY-MM-DD
 */
export const isWithinCalendarConstraints = (month: string) => {
  const diff = differenceInMonths(startOfMonth(newTZDate(month)), startOfMonth(todayDate()));
  const maxNextMonths = MAX_NEXT_MONTHS_INSTABOOK_OWNER;
  return diff >= -MAX_PREVIOUS_MONTHS && diff <= maxNextMonths;
};

/**
 * @param date YYYY-MM-DD
 */
const startOf = (date: string) => (
  formatDate(startOfMonth(newTZDate(date)), CALENDAR_DATE_FORMAT_DATE_FNS)
);

/**
 * @param month YYYY-MM-DD
 */
const nextMonth = (month: string) => (
  formatDate(addMonths(startOfMonth(newTZDate(month)), 1), CALENDAR_DATE_FORMAT_DATE_FNS)
);

/**
 * @param month YYYY-MM-DD
 */

const previousMonth = (month: string) => (
  month && formatDate(subMonths(startOfMonth(newTZDate(month)), 1), CALENDAR_DATE_FORMAT_DATE_FNS)
);

type Head = <T>(list: T[]) => T;
const head: Head = (list) => list[0];

type Tail = <T>(list: T[]) => T;
const tail: Tail = (list) => list[list.length - 1];

/**
 * Tracks which thread_id's have incomplete details.
 * This is to reduce the number of duplicate exceptions logged to sentry
 * during longer calendar sessions.
 */
const incompleteEvents = new Set<number>();

/**
 * Returns `true` if the event has the required properties.
 * Logs an exception with sentry when the properties are missing and returns `false`.
 */
const flagIncompleteEvents = (event: GenericEvent) => {
  if (isUnavailableEvent(event) || isInstabookEvent(event)) {
    return true;
  }

  if (isCalendarEvent(event) && event.thread_id && event.renter) {
    return true;
  }

  if (typeof event.thread_id === 'number' && !incompleteEvents.has(event.thread_id)) {
    incompleteEvents.add(event.thread_id);
    captureMessage(`Incomplete calendar event details: thread_id ${event.thread_id}`, {
      contexts: {
        eventDetails: event,
      },
    });
  }

  return false;
};

type Loader = {
  loadMonth: (startDate: string, boatId: number) => Promise<void>;
  isLoading: boolean;
};

/**
 * @param loader
 * @param startDate YYYY-MM-DD
 */
const useCanLoadDecorator = (
  loader: Loader,
  startDate: string,
  boatId: number,
) => {
  const loadMore = useCallback(async () => {
    loader.loadMonth(startDate, boatId);
  }, [boatId, loader, startDate]);

  const canLoad = isWithinCalendarConstraints(startDate);

  return useMemo(
    () => ({
      canLoad,
      loadMore,
      isLoading: loader.isLoading,
    }),
    [canLoad, loadMore, loader.isLoading],
  );
};

// This maintains a list of which months have been initialised to prevent multiple API calls per
// month. This is used because the data loading can be triggered by either the waypoints on the
// rendered page or by the mini cal's month changing.
const monthsLoaded = new Set<string>();
const instabookMonthsLoaded = new Set<string>();

type EventMerger = (data: CalendarMonthData, boatHashId: string) => void;
const useLoadCalendarMonth = (
  mergeEvents: EventMerger,
  loadedEvents: CalendarMonthData[],
  initialised: boolean,
  {
    isInstabookView,
    allBoats,
  }: { isInstabookView: boolean; allBoats: FullBoatDetail[] },
) => {
  const [isLoading, setIsLoading] = useState(false);
  const loadMonth = useCallback(
    async (
      startDate: string,
      boatId: number,
    ) => {
      const activityLoaded = monthsLoaded.has(startDate);
      const instabookLoaded = instabookMonthsLoaded.has(startDate);
      const loadActivities = !activityLoaded;
      const loadInstabooks = isInstabookView && !instabookLoaded;
      const shouldLoadData = loadActivities || loadInstabooks;

      if (!isLoading && shouldLoadData && isWithinCalendarConstraints(startDate)) {
        if (loadActivities) {
          monthsLoaded.add(startDate);
        }
        if (loadInstabooks) {
          instabookMonthsLoaded.add(startDate);
        }
        setIsLoading(true);
        try {
          const data = await getEvents({
            startDate,
            period: 'month',
            loadActivities,
            loadInstabooks,
            boatId,
          });
          if (initialised) {
            const boatHashId = allBoats.find((boat) => boat.boat_id === boatId)?.id || '';
            mergeEvents(data, boatHashId);
          } else {
            loadedEvents.push(data);
          }
        } catch (err) {
          if (err instanceof Error && err.message === USER_NOT_AUTHENTICATED) {
            redirectToLogin();
          } else {
            captureException(err);
            if (loadActivities) {
              monthsLoaded.delete(startDate);
            }
            if (loadInstabooks) {
              instabookMonthsLoaded.delete(startDate);
            }
          }
        }
        setIsLoading(false);
      }
    },
    [allBoats, initialised, isInstabookView, isLoading, loadedEvents, mergeEvents],
  );

  return useMemo(
    () => ({
      loadMonth,
      isLoading,
    }),
    [loadMonth, isLoading],
  );
};

const useCalendar = (): UseCalendar => {
  const isInstabookView = !!useRouteMatch(PATHS.INSTABOOK);
  const initialData: CalendarMonthData[] = [];
  const loadedEvents = useRef<CalendarMonthData[]>([]);

  const { allBoats, boatsIndexedById, firstBoatId, boats } = useBoats();
  const boatsIndexedByIdRef = useRef(boatsIndexedById);
  boatsIndexedByIdRef.current = boatsIndexedById;

  const instabookEnabled = allBoats.some((boat) => boat.instabook_enabled);

  /**
   * Tracks if the required boat data has been loaded to be merge with the event data
   */
  const [initialised, setInitialised] = useState(allBoats.length > 0);
  const [events, setEvents] = useState(initialData);
  const [statusFilters, dispatch] = useReducer(statusFilterReducer, []);

  const changeStatusFilter = useCallback(
    (type: StatusFilterActionType, filterName: string) => (
      dispatch({
        type,
        filterName,
      })
    ),
    [dispatch],
  );

  const { setCalendarFocus, selectedDate } = useCalendarFocus();

  const handleStatusFilterChange = useCallback(
    ({ target: { checked, name: filterName } }) => {
      changeStatusFilter(checked ? 'add' : 'remove', filterName);
      // Reset the scroll position to focus on the selected date
      setCalendarFocus({ selectedDate });
    },
    [changeStatusFilter, selectedDate, setCalendarFocus],
  );

  const handleShowAllStatusFilters = useCallback(
    ({ target: { checked } }) => (
      dispatch({
        type: checked ? 'showAll' : 'reset',
      })
    ),
    [dispatch],
  );

  const filterEventsByStatus = useCallback(
    (event: GenericEvent) => statusFilters.length === 0
      || (isCalendarEvent(event) && statusFilters.includes(event.event_type as string))
      || (isUnavailableEvent(event)
        && statusFilters.includes(UNAVAILABLE)
        && event.block_all_day === true),
    [statusFilters],
  );

  const {
    activeFilters: listingFilters,
    setActiveFilters,
  } = useListingsFilterContext();
  const selectedBoatId = allBoats.find((boat) => boat.id === listingFilters[0])?.boat_id ?? 0;

  const filterEventsByListing = useCallback(
    (event: GenericEvent) => listingFilters.length === 0
      || (isCalendarEvent(event) && listingFilters.includes(event.boat_ref))
      || (isUnavailableEvent(event) && listingFilters.includes(event.boat_ref))
      || (isInstabookEvent(event) && listingFilters.includes(event.boat_id)),
    [listingFilters],
  );

  const filteredEvents = useMemo(
    () => events.map(({ events: monthEvents, startDate }) => ({
      startDate,
      events: monthEvents.filter((event) => (
        isInstabookView
          ? filterEventsByListing(event)
          : filterEventsByStatus(event) && filterEventsByListing(event)
      )),
    })),
    [events, filterEventsByListing, filterEventsByStatus, isInstabookView],
  );

  const mergeEvents = useCallback((
    data: CalendarMonthData,
    boatHashId: string,
  ) => {
    setEvents((state) => {
      // Filter out existing months data apart from the current month.
      const monthsState = state.filter((month) => month.startDate !== data.startDate);

      /**
       * Filter out instabook events that are not related to the selected boat.
       */
      const filteredMonthsState = monthsState.map((month) => ({
        startDate: month.startDate,
        events: month.events.filter(
          (event) => {
            const isInstabook = isInstabookEvent(event);
            const hasSelectedBoatId = isInstabook && event.boat_id === boatHashId;
            return !isInstabook || (hasSelectedBoatId);
          },
        ),
      }));

      /**
       * This block of code helps to retain the calendar events when switching between
       * instabook and activities view.
       */
      const currentMonthState = state.filter((month) => month.startDate === data.startDate);
      // Remove previous listing's instabook trips.
      let filteredCurrentMonthEvents:GenericEvent[] = [];
      if (currentMonthState.length && isInstabookView) {
        filteredCurrentMonthEvents = currentMonthState[0].events.filter(
          (event) => !isInstabookEvent(event),
        );
      }

      return sortByStartDate([
        ...filteredMonthsState,
        {
          ...data,
          // Replace all the events for this month
          events: serializeInstabookEvents([...data.events, ...filteredCurrentMonthEvents])
            .map((event) => (
              addBoatData(boatsIndexedByIdRef.current)(overrideEventTypeBasedOnState(event))
            ))
            .filter(flagIncompleteEvents),
        },
      ]);
    });
  }, [isInstabookView]);

  const previousMonthDate = previousMonth(head(events)?.startDate);
  const nextMonthDate = selectedDate && events.length === 0
    ? startOf(selectedDate)
    : nextMonth(tail(events)?.startDate);

  const monthLoader = useLoadCalendarMonth(
    mergeEvents,
    loadedEvents.current,
    initialised,
    {
      isInstabookView,
      allBoats,
    },
  );

  const next = useCanLoadDecorator(monthLoader, nextMonthDate, selectedBoatId);
  const previous = useCanLoadDecorator(monthLoader, previousMonthDate, selectedBoatId);

  const activeMonthRef = useRef(startOf(selectedDate));

  const { loadMonth } = monthLoader;

  const setActiveMonth = useCallback(
    (date) => {
      activeMonthRef.current = date;
      if (!monthsLoaded.has(date) || !instabookMonthsLoaded.has(date)) {
        requestAnimationFrame(() => {
          loadMonth(date, selectedBoatId);
        });
      }
    },
    [loadMonth, selectedBoatId],
  );

  const mergeEventsOnRefresh = useCallback((data: CalendarMonthData) => {
    setEvents((state) => sortByStartDate([
      ...state.filter((month) => month.startDate !== data.startDate),
      {
        ...data,
        // Replace all the events for this month
        events: serializeInstabookEvents(data.events)
          .map((event) => (
            addBoatData(boatsIndexedByIdRef.current)(overrideEventTypeBasedOnState(event))
          ))
          .filter(flagIncompleteEvents),
      },
    ]));
  }, []);

  const { dropdownMonth, dropdownMonthRef, setDropdownMonth } = useDropdownMonth(activeMonthRef);

  const refresh = useCallback(async () => {
    Promise.all(
      Array.from(monthsLoaded.values()).map(async (startDate: string) => {
        try {
          const data = await getEvents(
            {
              startDate,
              period: 'month',
              loadActivities: true,
              loadInstabooks: isInstabookView,
              boatId: selectedBoatId,
            },
          );
          mergeEventsOnRefresh(data);
        } catch (err) {
          if (err instanceof Error && err.message === USER_NOT_AUTHENTICATED) {
            redirectToLogin();
          } else {
            captureException(err);
          }
        }
      }),
    );
  }, [isInstabookView, mergeEventsOnRefresh, selectedBoatId]);

  const replaceAdHocEvents = useCallback(
    (adHocEventId: number, updatedEvents: CalendarEvent[] = []) => {
      setEvents(
        mergeUpdatedAdHocCalendarEvents(boatsIndexedByIdRef.current, adHocEventId, updatedEvents),
      );
    },
    [],
  );

  const updateInstabookTrips = useCallback(
    (instabookId: number, updatedEvents: GenericEvent[] = []) => {
      setEvents(
        mergeUpdatedInstabookTrips(
          boatsIndexedByIdRef.current,
          instabookId,
          serializeInstabookEvents(updatedEvents),
        ),
      );
    },
    [],
  );

  const updateBlockingEvent = useCallback(
    (updatedEvents: CalendarEvent[]) => {
      setEvents(updatedCalendarEvents(boatsIndexedByIdRef.current, updatedEvents));
      refresh();
    },
    [refresh],
  );

  const instabookWarningCache = useRef(new Map<string, string | undefined>());

  const getInstabooksOnLoad = useCallback(async (boatId: number) => {
    if (isInstabookView) {
      try {
        instabookMonthsLoaded.clear();
        const promises: Promise<void>[] = [];
        events.forEach(({ startDate }) => promises.push(loadMonth(
          startDate,
          boatId,
        )));
        await Promise.all(promises);
      } catch (error) {
        captureException(error);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstabookView]);

  const getInstabooksOnFilterChange = useCallback(async (boatHashId: string) => {
    const boatId = allBoats.find((boat) => boat.id === boatHashId)?.boat_id;
    if (boatId) {
      await getInstabooksOnLoad(boatId);
    }
  }, [allBoats, getInstabooksOnLoad]);

  useEffect(() => {
    getInstabooksOnLoad(firstBoatId);
  }, [firstBoatId, getInstabooksOnLoad]);

  useEffect(() => {
    if (isInstabookView && firstBoatId) {
      const hashId = allBoats.find((boat) => boat.boat_id === firstBoatId)?.id;
      if (hashId && !listingFilters.length) {
        setActiveFilters([hashId]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBoats]);

  useEffect(() => {
    if (allBoats.length > 0) {
      setInitialised(true);
      const hashId = allBoats.find((boat) => boat.boat_id === firstBoatId)?.id || '';
      loadedEvents.current.map((data) => mergeEvents(data, hashId));
      loadedEvents.current = [];
    }
  }, [allBoats, allBoats.length, firstBoatId, mergeEvents]);

  useEffect(() => {
    const loadActivities = !monthsLoaded.has(activeMonthRef.current);
    const loadInstabooks = isInstabookView && !instabookMonthsLoaded.has(activeMonthRef.current);
    const shouldLoad = (loadActivities || loadInstabooks) && selectedBoatId > 0;
    if (allBoats.length > 0 && shouldLoad) {
      loadMonth(
        activeMonthRef.current,
        selectedBoatId,
      );
    }
  }, [
    allBoats.length,
    selectedBoatId,
    boats,
    isInstabookView,
    loadMonth,
  ]);

  useEffect(() => {
    // Clear the cache completely when events are updated, keeps complexity low
    instabookWarningCache.current?.clear();
  }, [events]);

  /**
   * This useEffect clears out `monthsLoaded` and `instabookMonthsLoaded`
   * on dismount because these `Set`s are defined outside the hook.
   */
  useEffect(
    () => () => {
      monthsLoaded.clear();
      instabookMonthsLoaded.clear();
    },
    [],
  );

  return {
    activeMonthRef,
    initialised,
    dropdownMonthRef,
    dropdownMonth,
    setDropdownMonth,
    setActiveMonth,
    refresh,
    next,
    previous,
    events: filteredEvents,
    statusFilters,
    changeStatusFilter,
    handleStatusFilterChange,
    handleShowAllStatusFilters,
    replaceAdHocEvents,
    updateBlockingEvent,
    updateInstabookTrips,
    instabookEnabled,
    instabookWarningCache,
    getInstabooksOnFilterChange,
  };
};

const noop = () => Promise.resolve();

const CalendarContext = createContext<UseCalendar>({
  activeMonthRef: { current: '' },
  initialised: false,
  dropdownMonthRef: { current: null },
  dropdownMonth: '',
  setDropdownMonth: noop,
  setActiveMonth: noop,
  refresh: noop,
  next: {
    canLoad: false,
    isLoading: false,
    loadMore: noop,
  },
  previous: {
    canLoad: false,
    isLoading: false,
    loadMore: noop,
  },
  events: [],
  statusFilters: [],
  changeStatusFilter: noop,
  handleStatusFilterChange: noop,
  handleShowAllStatusFilters: noop,
  replaceAdHocEvents: noop,
  updateBlockingEvent: noop,
  updateInstabookTrips: noop,
  instabookEnabled: false,
  instabookWarningCache: { current: new Map() },
  getInstabooksOnFilterChange: noop,
});

export const useCalendarContext = () => useContext(CalendarContext);

type CalendarProviderProps = {
  fallback: ReactNode;
};

export const CalendarProvider: FC<CalendarProviderProps> = ({ children, fallback }) => {
  const calendar = useCalendar();
  return (
    <CalendarContext.Provider value={calendar}>
      {calendar.initialised ? children : fallback}
    </CalendarContext.Provider>
  );
};
