import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { getUser } from 'src/common/utils/reduxStoreSelectors';
import { getClassNameFor } from '../../helpers';
import LogoutButton from './LogoutButton';
import s from './Navigation.module.scss';
import configItems, {
  addOn,
  captainAndOwnerConfigItemsOrdered,
  captainConfigItemsOrdered,
  directBooking,
  renterConfigItemsOrdered,
  type NestedNavigationConfigType,
} from './config';
import Avatar from '../Avatar';
import NestedMenu from '../NestedMenu';

type SubNavigationProps = {
  nestedMenuVariant?: 'dropdown' | 'sidebar';
};

const SubNavigation: FC<SubNavigationProps> = ({ nestedMenuVariant }) => {
  const user = useSelector(getUser);
  const isCaptain = user.get('is_captain');
  const isOwner = user.get('owner_approved');
  const isCaptainAndOwner = isCaptain && isOwner;
  const isRenter = !isCaptain && !isOwner;
  let authedMenuItems = [];

  authedMenuItems = isCaptain
    ? configItems.filter((config) => config.roles == null || config.roles.includes('captain'))
    : configItems.filter((config) => config.roles == null || config.roles.includes('renter'));
  if (isOwner) {
    authedMenuItems = configItems.filter((config) => config.roles == null || config.roles.includes('owner'));
  }

  if (isRenter) {
    authedMenuItems = [...renterConfigItemsOrdered];
  }
  if (isCaptain) {
    authedMenuItems = [...captainConfigItemsOrdered];
  }
  if (isCaptainAndOwner) {
    authedMenuItems = [...captainAndOwnerConfigItemsOrdered];
  }

  const nestedAuthMenuItems = [
    {
      key: '',
      label: '',
      children: [
        {
          id: 'avatar',
          component: (
            <div className={s.avatar}>
              <Avatar user={user.toJS() ?? {}} size="default" />
              <span>
                {user.get('first_name') ?? ''}
                {' '}
                {user.get('last_name') ?? ''}
              </span>
            </div>
          ),
          sectionDivider: true,
        },
        ...(isOwner ? [
          {
            id: 'charge-heading',
            component: (
              <h6 className={s.chargeHeading}>
                Charge
              </h6>
            ),
          },
          directBooking,
          addOn,
        ] : []),
        ...authedMenuItems,
        {
          id: 'logout',
          component: (
            <LogoutButton
              className={getClassNameFor(s, 'link', 'nestedMenu button')}
            />
          ),
        },
      ],
    }] as NestedNavigationConfigType[];

  return (
    <NestedMenu
      items={nestedAuthMenuItems}
      variant={nestedMenuVariant}
    />
  );
};

export default SubNavigation;
