import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import {
  PATHS,
} from '../../../../common/constants';
import s from './TripNotFound.module.scss';
import Svg from '../../../../common/components/Svg';
import MessagePanel from '../../presentation/MessagePanel';

const TripNotFound: FC = () => (
  <MessagePanel>
    <Svg
      href="#sailing-boat"
      className={s.boatIcon}
    />
    <h4 className={s.messageHeading}>
      NO TRIP SELECTED
    </h4>
    <p className={s.messageText}>
      Please select a trip conversation from the list in your
      {' '}
      <Link to={PATHS.INBOX}>inbox</Link>.
    </p>
  </MessagePanel>
);

export default TripNotFound;
