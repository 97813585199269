import { fromJS } from 'immutable';
import { ImmutableMap } from 'src/types/ImmutableMap';

// app/reducer/action
export const SET_LOADING = 'common/loading/SET_LOADING';
export const SET_ERROR = 'common/loading/SET_ERROR';
export const SET_LOADING_COMPLETE = 'common/loading/SET_LOADING_COMPLETE';
export const SET_DOMAIN = 'common/loading/SET_DOMAIN';
export const CLEAR_DOMAIN = 'common/loading/CLEAR_DOMAIN';
export const SET_HOSTNAME = 'common/loading/SET_HOSTNAME';
export const CLEAR_HOSTNAME = 'common/loading/CLEAR_HOSTNAME';
export const SET_NOT_FOUND = 'common/loading/SET_NOT_FOUND';
export const SET_NOT_FOUND_BUT_REDIRECT = 'common/loading/SET_NOT_FOUND_BUT_REDIRECT';
export const CLEAR_NOT_FOUND = 'common/loading/CLEAR_NOT_FOUND';
export const SET_500_ERROR = 'common/loading/SET_500_ERROR';

export const setLoading = () => ({ type: SET_LOADING });
export const setError = (error: string) => ({ type: SET_ERROR, error });
export const setLoadingComplete = () => ({ type: SET_LOADING_COMPLETE });
export const setDomain = (domain: string) => ({ type: SET_DOMAIN, domain });
export const clearDomain = () => ({ type: CLEAR_DOMAIN });
export const setHostname = (hostname: string) => ({ type: SET_HOSTNAME, hostname });
export const clearHostname = () => ({ type: CLEAR_HOSTNAME });
export const setNotFound = () => ({ type: SET_NOT_FOUND });
export const setNotFoundButRedirect = (redirectUrl: string, statusCode: number) => (
  { type: SET_NOT_FOUND_BUT_REDIRECT, redirectUrl, statusCode }
);
export const clearNotFound = () => ({ type: CLEAR_NOT_FOUND });
export const set500Error = () => ({ type: SET_500_ERROR });

type LoadingState = {
  '500_error'?: boolean;
  error?: string;
  domain?: string;
  hostname?: string;
  notFound?: boolean;
  status: boolean;
  statusCode?: number;
  redirectUrl?: string;
};
export type ImmutableLoadingState = ImmutableMap<LoadingState>;

type LoadingAction = Partial<LoadingState> & {
  type:
  | typeof SET_LOADING
  | typeof SET_LOADING_COMPLETE
  | typeof SET_DOMAIN
  | typeof CLEAR_DOMAIN
  | typeof SET_ERROR
  | typeof SET_HOSTNAME
  | typeof CLEAR_HOSTNAME
  | typeof SET_NOT_FOUND
  | typeof SET_NOT_FOUND_BUT_REDIRECT
  | typeof CLEAR_NOT_FOUND
  | typeof SET_500_ERROR
  ;
};

const initialLoadingState = fromJS({ status: false });

// Reducer
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (loading: ImmutableLoadingState = initialLoadingState, action: LoadingAction) => {
  switch (action.type) {
    case SET_LOADING:
      return loading.set('status', true);
    case SET_LOADING_COMPLETE:
      return loading.set('status', false);
    case SET_ERROR:
      return loading
        .set('status', false)
        .set('error', action.error);
    // TODO: Put these in a place that makes more sense.
    // This is a lazy decision driven by my desire to not add
    // another reducer just for this state.
    case SET_DOMAIN:
      return loading.set('domain', action.domain);
    case CLEAR_DOMAIN:
      return loading.delete('domain');
    case SET_HOSTNAME:
      return loading.set('hostname', action.hostname);
    case CLEAR_HOSTNAME:
      return loading.delete('hostname');
    case SET_NOT_FOUND:
      return loading.set('notFound', true);
    case SET_NOT_FOUND_BUT_REDIRECT:
      return loading
        .set('notFound', true)
        .set('statusCode', action.statusCode)
        .set('redirectUrl', action.redirectUrl);
    case CLEAR_NOT_FOUND:
      return loading.delete('notFound');
    case SET_500_ERROR:
      return loading.set('500_error', true);
    default:
      return loading;
  }
};
