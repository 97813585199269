import { Currencies, Currency } from './types';

const USD: Currency = {
  symbol: '$',
  code: 'USD',
  precision: 2,
};

export const DEFAULT_CURRENCY = USD;

export const getCanonicalForm = (currencyCode: string, currencies: Currencies): Currency => (
  currencies?.payin_currencies?.find(({ code }) => code === currencyCode)
  || currencies?.payout_currencies?.find(({ code }) => code === currencyCode)
  || DEFAULT_CURRENCY
);

export const getCurrencyByCode = (
  currencyCode: string,
  currencies: Currencies,
): Currency | undefined => (
  currencies?.payin_currencies?.find((currency: Currency) => currency.code === currencyCode)
);
