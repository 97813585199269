import type { Dispatch } from 'redux';
import type { FormProps } from 'react-final-form';
import { ImmutableInquiryDetails } from 'src/types/inquiry/Inquiry';
import { ImmutableSearchBoatDetails } from 'src/types/boat/BoatDetail';
import { trackEvent } from 'src/common/tracking';
import apiFetch from '../core/fetch';
import gtag from '../common/gtag';
import { BROADCAST_BOATS_FIELD, PAGES } from './constants';
import { updateInquiry } from './ducks/inquiry';
import { removeEmptyFields } from './helpers';

// I kinda don't like this function.
export const rffFormSubmit = (
  dispatch: Dispatch,
  success: () => void = () => { },
): FormProps['onSubmit'] => (values, { getState }) => {
  if (getState().dirty) {
    dispatch(updateInquiry(values));
  }
  success();
};

const streamlineSubmitLabel = 'Yes Direct | No Broadcast';

type CommonSubmitPayload = {
  inquiry: ImmutableInquiryDetails;
  boat: ImmutableSearchBoatDetails;
  values?: Record<string, unknown>;
  eventLabel?: string;
};

type CommonSubmit = ({ inquiry, boat, values, eventLabel }: CommonSubmitPayload)
=> Promise<Response>;
export const commonSubmit: CommonSubmit = ({ inquiry,
  boat,
  values,
  eventLabel }) => (
  apiFetch(
    '/inquiries/',
    {
      method: 'POST',
      body: JSON.stringify(removeEmptyFields({
        ...inquiry.toJS(),
        ...values,
        boat: boat.get('id'),
      })),
    },
  ).then(response => {
    if (!response.ok) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw response;
    }
    trackEvent('Submitted', {
      event_category: 'Booking Inquiry',
      event_label: eventLabel,
    });
    gtag('event', 'conversion', {
      send_to: 'AW-991706362/s7vFCMqK-qEBEPr58NgD',
    });

    if (typeof window !== 'undefined' && window.pintrk) {
      window.pintrk('track', 'addtocart', {
        product_id: boat.get('id'),
      });
    }
    return response;
  })
);

type SubmitBroadcastBoatsPayload = {
  boats: string[];
  inquiryId: string;
};
type SubmitBroadcastBoats = ({
  boats,
  inquiryId,
}: SubmitBroadcastBoatsPayload) => Promise<Response>;

export const submitBroadcastBoats: SubmitBroadcastBoats = ({ boats, inquiryId }) => (
  apiFetch(
    `/inquiries/${inquiryId}/broadcast/`,
    {
      method: 'POST',
      body: JSON.stringify({
        boat_hashed_ids: boats,
      }),
    },
  ).then(response => {
    if (!response.ok) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw response;
    }
    return response;
  })
);

// This is the submit that's used when there are no boats to broadcast
// to and no mismatches in the inquiry. It happens earlier, and the
// matching listings part is entirely skipped.
export const streamlineSubmit = ({ inquiry, boat }) => values => commonSubmit({
  inquiry,
  boat,
  values,
  eventLabel: streamlineSubmitLabel,
});

export const streamlineNextPage = inquiry => {
  const warn = inquiry.get('communications_warning');
  return warn ? PAGES.WARNING : PAGES.DONE;
};

// This is the submit that's used on the matching listings page.
export const matchingSubmit = ({ inquiry, boat }) => values => {
  const { [BROADCAST_BOATS_FIELD]: broadcastBoats } = values;
  const broadcastOnly = inquiry.get('broadcast_only');
  const isDirect = broadcastOnly ? 'No' : 'Yes';
  const isBroadcast = (broadcastBoats.length === 0) ? 'No' : 'Yes';
  const eventLabel = `${isDirect} Direct | ${isBroadcast}  Broadcast`;
  return commonSubmit({ inquiry, boat, values, eventLabel });
};
