import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, useHistory, useLocation } from 'react-router-dom';
import { Form } from 'react-final-form';
import { HotKeys } from 'react-hotkeys';
import { Helmet } from 'react-helmet-async';

import { PASSWORD_LENGTH, PATHS } from '../../../../common/constants';
import { rffSubmitResponse } from '../../../../common/helpers';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import {
  getBoat,
  getInquiry,
  getInquiryHasMismatches,
  getUser,
} from '../../../../common/utils/reduxStoreSelectors';
import useGrecaptcha from '../../../../auth/hooks/useGrecaptcha';
import useOnSubmitLocationChange from '../../../../common/hooks/useOnSubmitLocationChange';
import { streamlineSubmit, streamlineNextPage } from '../../../submit';
import { registerCall } from '../../../../common/ducks/user';
import { PAGES } from '../../../constants';
import { BackButton } from '../../../../common/components/BackButton';
import Button from '../../../../common/components/Button';
import CloseButton from '../../../../common/components/CloseButton';
import AgreementContent from '../../../../common/components/AgreementContent';
import CreatePassword from '../../../../common/components/fieldsets/CreatePassword';
import FormError from '../../../../common/components/FormError';
import s from './Password.module.scss';

type PasswordProps = {
  match: { url: string };
  streamlineInquiry?: boolean;
};

const Password: FC<PasswordProps> = ({ match: { url }, streamlineInquiry }) => {
  const { goBack } = useHistory();
  const location = useLocation();
  const { search } = location;
  const returnUrl = getReturnUrl(location);
  const user = useSelector(getUser);
  const boat = useSelector(getBoat);
  const dispatch = useDispatch();
  const inquiry = useSelector(getInquiry);
  const inquiryHasMismatches = useSelector(getInquiryHasMismatches);

  // NOTE: this is like this because the hook goes to the sibling page, where we want it
  // to go to a child page.
  // All of this indirection is designed to preserve the progress bar on the top of the page.
  // TODO: refactor that to be less magical.
  const gotoAgreement = useOnSubmitLocationChange(`${PAGES.PASSWORD}/${PAGES.AGREEMENT}`);
  const onNonStreamlineSubmitSuccess = useOnSubmitLocationChange(
    inquiryHasMismatches ? PAGES.MISMATCHES : PAGES.MATCH,
  );
  const onStreamlineSubmitSuccess = useOnSubmitLocationChange(streamlineNextPage(inquiry));

  const register = useCallback(
    (values: Record<string, string>) => {
      const registerData = {
        email: user.get('email'),
        first_name: user.get('first_name'),
        last_name: user.get('last_name'),
        phone: user.get('phone'),
        marketing_consent: user.get('marketing_consent'),
        terms_agreed: true,
        ...values,
      };
      return dispatch(registerCall(registerData))
        .then(() => {
          if (streamlineInquiry) {
            return streamlineSubmit({ inquiry: inquiry.get('details'), boat })(values);
          }
          return undefined;
        })
        .then(streamlineInquiry ? onStreamlineSubmitSuccess : onNonStreamlineSubmitSuccess)
        .catch(rffSubmitResponse());
    },
    [
      boat,
      dispatch,
      inquiry,
      streamlineInquiry,
      user,
      onStreamlineSubmitSuccess,
      onNonStreamlineSubmitSuccess,
    ],
  );

  const onSubmit = useGrecaptcha('signup', register);

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting, submitError, valid, form: { getRegisteredFields, blur } }) => {
        const agreementTransition = () => {
          getRegisteredFields().forEach(blur);
          if (valid) {
            gotoAgreement();
          }
        };
        const title = 'Password';

        return (
          <form
            method="POST"
            className={s.container}
            onSubmit={handleSubmit}
          >
            {/* password form */}
            <Route
              // TODO: this is a bit clumsy. I'd rather use relative pathing or something like that,
              // but I don't think we have access to that yet.
              exact
              path={url}
              render={() => (
                <>
                  <Helmet>
                    <title>{title}</title>
                  </Helmet>
                  <h1 className={s.title}>{title}</h1>
                  <div className={s.message}>
                    <p>
                      {'This will create an account with Getmyboat so you can read and respond'
                        + ` to messages from owners. Passwords must be at least ${PASSWORD_LENGTH} characters.`}
                    </p>
                    <p>
                      {'Have an account with Getmyboat? '}
                      <Link
                        to={`${PATHS.LOGIN}${search}`}
                        className={s.link}
                      >
                        Sign In
                      </Link>
                      .
                    </p>
                  </div>
                  <HotKeys
                    handlers={{ submit: agreementTransition }}
                    keyMap={{ submit: ['enter'] }}
                    className={s.form}
                  >
                    <CreatePassword autoFocus />

                    <Button
                      type="button"
                      onClick={agreementTransition}
                      fullWidth
                    >
                      Create Account
                    </Button>
                  </HotKeys>
                </>
              )}
            />
            {/* agreement form */}
            <Route
              exact
              path={`${url}/${PAGES.AGREEMENT}/`}
              render={() => (
                <>
                  <AgreementContent />

                  <FormError error={submitError} />

                  <Button
                    type="submit"
                    submitting={submitting}
                    fullWidth
                  >
                    Accept
                  </Button>
                  <BackButton onClick={goBack} />
                  <CloseButton backUrl={returnUrl} />
                </>
              )}
            />
            <BackButton onClick={goBack} />
            <CloseButton backUrl={returnUrl} />
          </form>
        );
      }}
    </Form>
  );
};

export default Password;
