import React from 'react';
import PropTypes from 'prop-types';

import StarIcon from '../../../../../common/components/StarIcon';
import RatingInput from '../../../../../common/components/inputs/RatingInput';
import ReviewPhotos from '../ReviewPhotos';
import s from '../../ReviewItem.module.scss';

const RatedMessage = ({
  boatHeadline,
  review,
}) => {
  const publicReview = review.get('public_review');

  return (
    <>
      <RatingInput
        readonly
        initialRating={review.get('rating')}
        emptySymbol={<StarIcon classNameModifier="small" />}
        fullSymbol={<StarIcon classNameModifier="small full" />}
        classNameModifier="oneLine"
      />
      {publicReview && (
        <p className={s.content}>
          {publicReview}
        </p>
      )}

      <ReviewPhotos
        // Change the shape of photos list item to match the shape of communityPhotos
        photos={review.get('photos').map(photoUrl => ({
          description: boatHeadline,
          src: photoUrl,
        }))}
      />
    </>
  );
};

RatedMessage.propTypes = {
  boatHeadline: PropTypes.string.isRequired,
  review: PropTypes.object.isRequired,
};

export default RatedMessage;
