import React from 'react';
import PropTypes from 'prop-types';

import RatingInput from '../../../../../common/components/inputs/RatingInput';
import s from './ReviewCategory.module.scss';

const ReviewCategory = ({
  title,
  rating,
  comment,
}) => (
  <div className={s.root}>
    <h6 className={s.title}>
      {title}
    </h6>
    <RatingInput
      readonly
      initialRating={rating}
    />
    <p className={s.comment}>
      {comment}
    </p>
  </div>
);

ReviewCategory.propTypes = {
  title: PropTypes.string.isRequired,
  rating: PropTypes.number,
  comment: PropTypes.string,
};

export default ReviewCategory;
