import React, { FC } from 'react';
import classNames from 'classnames';

import { Amounts } from 'src/types/common/Amounts';
import { ImmutableTrip } from '../../../types/trips/Trips';
import CustomOfferExpiry from '../../../common/components/CustomOfferExpiry';
import Card from '../presentation/Card';
import TermsAndCancellation from '../TermsAndCancellation';
import CostBreakdown from '../CostBreakdown';

type OwnerPriceSectionProps = {
  amounts?: Amounts;
  listingUrl?: string;
  trip?: ImmutableTrip;
  readOnly?: boolean;
  highlighted?: boolean;
  renderCustomExpiry?: boolean;
  classNameModifier?: string;
};

const OwnerPriceSection: FC<OwnerPriceSectionProps> = ({
  amounts,
  listingUrl,
  trip,
  highlighted,
  readOnly,
  children,
  renderCustomExpiry = false,
  classNameModifier = '',
}) => (
  <>
    {children}

    {amounts?.price_sections?.map((priceSection) => (
      <CostBreakdown
        key={priceSection.id}
        priceSection={priceSection}
      />
    ))}

    {renderCustomExpiry && readOnly && (
      <Card
        highlighted={highlighted}
        classNameModifier={classNames(
          classNameModifier,
          { hoverEffect: !readOnly },
          'withZeroPadding',
        )}
      >
        <CustomOfferExpiry
          trip={trip}
        />
      </Card>
    )}

    {amounts?.cancellation_policy && (
      <Card classNameModifier={classNames(classNameModifier, 'termsAndCancellation')}>
        <TermsAndCancellation
          currency={amounts?.owner_currency}
          deposit={amounts?.listing_deposit}
          listingUrl={listingUrl}
          cancellationPolicy={amounts?.cancellation_policy}
        />
      </Card>
    )}
  </>
);

export default OwnerPriceSection;
