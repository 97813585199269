import moment from 'moment';
import type { ImmutableTrip } from 'src/types/trips/Trips';
import {
  singleDayFormat,
  multidayFormat,
} from '../../common/helpers';
import { MOMENT_FORMAT } from '../../common/constants';
import {
  PICKUP_TIME_FIELD,
  PREFERRED_DATE_FIELD,
  RETURN_TIME_FIELD,
  TRIP_LENGTH_FIELD,
} from '../constants';

const useTripDetails = (trip: ImmutableTrip) => {
  // Configure tripLengthString first, as Duration is required field in inquiry
  const tripLengthMoment = moment.duration(trip.get(TRIP_LENGTH_FIELD));
  const isMultiDay = tripLengthMoment.asDays() >= 1;
  const isDay = trip.get('has_day_pricing');
  const isOnlyDay = isDay && !trip.get('has_hour_pricing');

  // Multiday trips are mix of days and hours. 1 Day and 8 Hours would be equivalent of '2 Days'
  const days = isMultiDay && isDay
    ? Math.ceil(tripLengthMoment.asDays())
    : Math.floor(tripLengthMoment.asDays());

  const tripLengthString = days || isOnlyDay
    ? multidayFormat(days, isDay, isOnlyDay)
    : singleDayFormat(tripLengthMoment);

  // Determine if trip includes depart time
  const hasTripDepartTime = !!trip.get(PICKUP_TIME_FIELD);

  // Configure departure time and date
  const departDate = trip.get(PREFERRED_DATE_FIELD);
  const departTime = hasTripDepartTime ? `T${trip.get(PICKUP_TIME_FIELD) || '09:00:00'}` : null;
  const departMoment = departTime ? moment(`${departDate}${departTime}`) : moment(`${departDate}`);
  const dateTimeString = `${departMoment.format(MOMENT_FORMAT.DATE)} • ${tripLengthString}`;

  // Configure return time and date
  const requestedReturnTime = trip.get(RETURN_TIME_FIELD);

  let returnMoment;
  returnMoment = departMoment.clone().add(tripLengthMoment);

  if (requestedReturnTime !== null && isMultiDay) {
    // Note here:
    // The reason this is different than TimeCard is because the timecard
    // doesn't care about the return date, but the consumer of this component
    // might, so we want to preserve the date, which is slightly more verbose.
    returnMoment = moment(`${returnMoment?.format('YYYY MM DD')} ${requestedReturnTime}`, 'YYYY MM DD HH:mm:ss');
  }

  return {
    days,
    departMoment,
    returnMoment,
    dateTimeString,
    tripLengthString,
    hasTripDepartTime,
    tripLengthMoment,
  };
};

export default useTripDetails;
