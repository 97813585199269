import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getSelectedBoat } from '../../inbox/helpers';

const useTimeZones = trip => {
  const {
    offer,
    user,
  } = useSelector(state => ({
    offer: state.offer,
    user: state.user,
  }));
  const boat = getSelectedBoat(offer, trip, user.get('boats'));

  const userTimeZoneId = useMemo(() => (
    // TimeZone from browser
    Intl.DateTimeFormat()?.resolvedOptions()?.timeZone
    // TimeZone from user account as a fallback
    || user.get('timezone_identifier')
  ), [user]);

  const boatTimeZoneId = useMemo(() => {
    let timezoneId = boat.get('timezone_id');

    // If the boat's timezone isn't set, we default to the user's timezone
    if (!timezoneId) {
      timezoneId = userTimeZoneId;
    }

    return timezoneId;
  }, [boat, userTimeZoneId]);

  return {
    boatTimeZoneId,
    userTimeZoneId,
  };
};

export default useTimeZones;
