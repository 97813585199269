import { BEST_OF } from '../constants';

/**
 * Extracts the year of the latest best of award from the array of awards
 * @param awards an array of awards owner has won
 * @returns the year of the best of award or undefined
 */

const getYearOfBestOfAward = (awards: string[] = []): string | undefined => awards
  ?.find?.((award) => award === BEST_OF)
  ?.split?.('_')[2];

export default getYearOfBestOfAward;
