import React, { FC, ReactNode, useEffect } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';

import { TITLE } from '../../../common/constants';
import { PAGES } from '../../constants';
import Layout from '../../../common/components/Layout';
import { getClassNameFor, decorateComponent } from '../../../common/helpers';
import Header from '../../../common/components/Header';
import WizardProgressBar from '../../../common/components/WizardProgress';
import { setLoadingComplete } from '../../../common/ducks/loading';

import s from './Inquiry.module.scss';

const mapDispatchToProps = {
  finishLoading: setLoadingComplete,
};

type InquiryRootProps = {
  children: ReactNode;
  finishLoading: () => void;
};

const InquiryRoot: FC<InquiryRootProps> = ({
  finishLoading,
  children,
}) => {
  const { isExact, url } = useRouteMatch();
  useEffect(() => {
    finishLoading();
  }, [finishLoading]);

  const classNameModifier = classNames(
    'modal with-blue-gradient-background',
    {
      welcome: isExact,
    },
  );

  return (
    <Layout classNameModifier={classNameModifier}>
      <Header
        withNav={false}
        withSearchBar={false}
        classNameModifier={classNameModifier}
      />
      <div className={getClassNameFor(s, 'modal', classNameModifier)}>
        <Helmet
          defaultTitle={`${TITLE.INQUIRY} | ${TITLE.PROJECT}`}
          titleTemplate={`${TITLE.INQUIRY}: %s | ${TITLE.PROJECT}`}
        />
        {/* Don't render progress bar on welcome & inquiry sent screens */}
        <Route path={[`${url}/${PAGES.DONE}/`, url]} exact>
          {({ match: nonProgressPage }) => (
            !nonProgressPage && (
              <WizardProgressBar
                routes={[
                  // These are only the pages where we render the progress bar, in
                  // the order that they appear.
                  PAGES.DURATION,
                  PAGES.DATES,
                  PAGES.TIME,
                  PAGES.GROUP,
                  PAGES.CAPTAIN,
                  PAGES.EXPERIENCE,
                  PAGES.EXTRAS,
                  PAGES.CONTACT,
                  PAGES.PASSWORD,
                  PAGES.MATCH,
                  PAGES.WARNING,
                  PAGES.INSTABOOK,
                ]}
                classNameModifier="modal"
              />
            )
          )}
        </Route>
        {children}
      </div>
    </Layout>
  );
};

const decorators = [
  connect(null, mapDispatchToProps),
];

export default decorateComponent(InquiryRoot, decorators);
