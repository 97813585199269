import type { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { fetchCatch } from 'src/core/sentry';
import { apiFetchThunk } from 'src/common/ducks/fetch';
import type { LinkedCaptains } from 'src/types/boat/LinkedCaptains';
import type { ReduxState } from 'src/types/reduxState';

// Actions
export const SET_CAPTAINS = 'inbox/captains/SET_CAPTAINS';
export const CLEAR_CAPTAINS = 'inbox/captains/CLEAR_CAPTAINS';

// Reducer
const captainsReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: Partial<LinkedCaptains> = {},
  action: any,
) => {
  switch (action.type) {
    case SET_CAPTAINS:
      return action.captains;
    case CLEAR_CAPTAINS:
      return {};
    default:
      return state;
  }
};

// Action creators
export const setCaptains = (captains: LinkedCaptains) => ({
  type: SET_CAPTAINS,
  captains,
});

export const clearLinkedCaptains = () => ({ type: CLEAR_CAPTAINS });

type GetLinkedCaptainsCallThunk = (
  ThunkAction<Promise<LinkedCaptains>, ReduxState, {}, Action>
);

export const getLinkedCaptainsCall = (boatId: string): GetLinkedCaptainsCallThunk => (
  (dispatch) => dispatch(apiFetchThunk(`/boats/${boatId}/linked_captains/`))
    .then((response: Response) => {
      if (!response.ok) {
        fetchCatch(response);
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw response;
      }
      return response.json();
    })
    .then((captains: LinkedCaptains) => {
      dispatch(setCaptains(captains));
      return captains;
    })
);

export default captainsReducer;
