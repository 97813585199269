import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { BOOKING_MOTIVATOR_STATE } from '../../constants';
import { getClassNameFor } from '../../helpers';
import Motivator from './Motivator';
import s from './BookingMotivators.module.scss';
import { Motivator as MotivatorType } from '../../types';

const {
  UNAVAILABLE,
  LIMITED_AVAILABILITY,
  IN_DEMAND,
  HIGH_DEMAND,
  UNUSUAL_SIGHTING,
  LOOKS_GOOD,
} = BOOKING_MOTIVATOR_STATE;

const bookingMotivatorMessages = {
  [LOOKS_GOOD]: {
    singular: 'This date is available.',
    plural: 'These dates are available.',
  },
  [UNUSUAL_SIGHTING]: {
    singular: 'This date is popular and usually gets booked early.',
    plural: 'These dates are popular and usually get booked early.',
  },
  [UNAVAILABLE]: {
    singular: 'Please try a different date.',
    plural: 'Some dates in your selection are unavailable. Please try a different start date.',
  },
  [LIMITED_AVAILABILITY]: {
    singular: 'Some departure times on this date are still available.',
    plural: 'Some departure times on these dates are still available.',
  },
  [IN_DEMAND]: {
    singular: 'Others are asking about this available date.',
    plural: 'Others are asking about these available dates.',
  },
  [HIGH_DEMAND]: {
    singular: 'Several others are asking about this available date.',
    plural: 'Several others are asking about these available dates.',
  },
};

export type Variant = 'modal' | 'landing';

export type BookingMotivatorsProps = {
  minimal?: boolean;
  modifier?: string;
  initialTitle?: string;
  selectedDate?: null | string;
  selectedMotivators?: MotivatorType[];
  duration?: number;
  blockedTimes?: any;
  loading?: boolean;
  variant?: Variant;
};

const BookingMotivators: FC<BookingMotivatorsProps> = ({
  minimal = false,
  modifier = '',
  initialTitle = 'Select a date to check availability.',
  selectedDate,
  selectedMotivators = [],
  duration = 0,
  blockedTimes = [],
  loading,
  variant = 'modal',
}) => {
  const renderSelectDate = !selectedDate && !minimal;
  const singularOrPlural = duration >= 1 ? 'plural' : 'singular';
  const someDateUnavailable = selectedMotivators.includes(UNAVAILABLE);
  const renderUnavailable = !minimal && someDateUnavailable;
  const someDateLimitedAvailability = selectedMotivators.includes(LIMITED_AVAILABILITY);
  const someDateHighDemand = selectedMotivators.includes(HIGH_DEMAND);
  const someDateInDemand = selectedMotivators.includes(IN_DEMAND);
  const someDateUnusualSighting = !someDateUnavailable
    && selectedMotivators.includes(UNUSUAL_SIGHTING);
  const renderLooksGood = (
    !minimal
    && selectedDate
    && !(someDateUnavailable
      || someDateLimitedAvailability
      || someDateHighDemand
      || someDateInDemand
      || someDateUnusualSighting)
  );
  const renderComponent = (renderSelectDate
    || someDateInDemand
    || someDateHighDemand
    || someDateLimitedAvailability
    || renderLooksGood
    || renderUnavailable
    || someDateUnusualSighting
  );

  const isMultiDay = duration > 1;

  const getMainMotivator = useCallback(
    () => {
      if (renderUnavailable) {
        return (
          <Motivator
            title="Unavailable."
            icon="#unavailable"
            minimal={minimal}
            modifier={classNames(modifier, variant)}
            description={bookingMotivatorMessages[UNAVAILABLE][singularOrPlural]}
          />
        );
      }
      if (someDateLimitedAvailability) {
        return (
          <Motivator
            title={isMultiDay ? 'Availability pending.' : 'Limited availability.'}
            icon="#beach-ball"
            minimal={minimal}
            modifier={classNames('limitedAvailability ball', modifier, variant, { minimal })}
            description={isMultiDay ? 'Please discuss with the owner.' : bookingMotivatorMessages[LIMITED_AVAILABILITY][singularOrPlural]}
            blockedTimes={isMultiDay ? [] : blockedTimes}
            loading={loading}
          />
        );
      }
      if (someDateHighDemand) {
        return (
          <Motivator
            title="High Demand."
            icon="#beach-ball"
            minimal={minimal}
            modifier={classNames('highDemand ball', modifier, variant, { minimal })}
            description={bookingMotivatorMessages[HIGH_DEMAND][singularOrPlural]}
          />
        );
      }
      if (someDateInDemand) {
        return (
          <Motivator
            title="In Demand."
            icon="#beach-ball"
            minimal={minimal}
            modifier={classNames('inDemand ball', modifier, variant, { minimal })}
            description={bookingMotivatorMessages[IN_DEMAND][singularOrPlural]}
          />
        );
      }
      return false;
    },
    [
      blockedTimes,
      renderUnavailable,
      someDateLimitedAvailability,
      someDateHighDemand,
      someDateInDemand,
      singularOrPlural,
      minimal,
      modifier,
      isMultiDay,
      loading,
      variant,
    ],
  );

  if (renderComponent) {
    return (
      <div className={s.root}>
        <ul className={getClassNameFor(
          s,
          'list',
          classNames(
            variant,
            modifier,
            {
              minimal,
              blockedTimes: !loading && !isMultiDay && blockedTimes.length > 2,
            },
          ),
        )}
        >
          {renderSelectDate && (
            <Motivator
              title={initialTitle}
              icon="calendar-day"
              minimal={minimal}
              modifier={classNames('selectDate', modifier, variant)}
            />
          )}

          {getMainMotivator()}

          {renderLooksGood && (
            <Motivator
              title="Looks Good."
              icon="#looks-good"
              minimal={minimal}
              modifier={classNames(modifier, variant)}
              description={bookingMotivatorMessages[LOOKS_GOOD][singularOrPlural]}
            />
          )}
          {someDateUnusualSighting && (
            <Motivator
              title="Unusual Sighting!"
              icon="#unusual-sighting"
              minimal={minimal}
              modifier={classNames('unusualSighting', modifier, variant, { minimal })}
              description={bookingMotivatorMessages[UNUSUAL_SIGHTING][singularOrPlural]}
            />
          )}
        </ul>
      </div>
    );
  }
  return null;
};

export default BookingMotivators;
