import PropTypes from 'prop-types';
import React from 'react';
import LinkifyIt from 'linkify-it';

import UniversalLink from '../../../common/components/UniversalLink';
import { getClassNameFor } from '../../../common/helpers';
import s from './MessageContent.module.scss';

const addLinks = contents => {
  const linkify = new LinkifyIt();

  const matches = linkify.match(contents);
  if (!matches) {
    return contents;
  }

  const elements = [];
  let lastIndex = 0;
  matches.forEach(match => {
    if (match.index > lastIndex) {
      elements.push(contents.substring(lastIndex, match.index));
    }

    const parsedUrl = new URL(match.url);
    const isFromZendesk = parsedUrl.origin.includes('zendesk');

    elements.push(
      <UniversalLink
        key={match.index}
        to={isFromZendesk ? match.url : parsedUrl.pathname}
        openInNewTab={isFromZendesk}
      >{match.text}
      </UniversalLink>,
    );

    lastIndex = match.lastIndex;
  });

  if (contents.length > lastIndex) {
    elements.push(contents.substring(lastIndex));
  }

  return elements;
};

const MessageContent = ({ ownModifier, children, time, dateTimeString }) => (
  <div
    data-test="message-content"
    className={getClassNameFor(s, 'root', ownModifier)}
  >
    {addLinks(children)}
    <time
      className={s.time}
      dateTime={dateTimeString}
    >
      {time}
    </time>
  </div>
);

MessageContent.propTypes = {
  ownModifier: PropTypes.string,
  children: PropTypes.string,
  time: PropTypes.string,
  dateTimeString: PropTypes.string,
};

export default MessageContent;
