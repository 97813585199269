import { useCallback, useMemo, useState } from 'react';

export type UseFilters<T> = {
  filters: T[];
  activeFilters: T[];
  addListingFilters: (items: T[]) => void;
  toggleFilter: (filter: T) => void;
  toggleAllFilters: () => void;
  clearFilters: () => void;
  selectAllFilters: () => void;
  setActiveFilters: (filters: T[]) => void;
};

const useFilters = <T>(filters: T[]): UseFilters<T> => {
  const [activeFilters, setActiveFilters] = useState<T[]>([]);

  /** Toggle a specific tag */
  const toggleFilter = useCallback(
    (filter: T) => {
      if (activeFilters.includes(filter)) {
        setActiveFilters(activeFilters.filter(x => x !== filter));
      } else {
        setActiveFilters(activeFilters.concat(filter));
      }
    },
    [activeFilters, setActiveFilters],
  );

  /**
   * Toggle all items on and then off.
   * It will toggle everything on before toggling everything off.
   */
  const toggleAllFilters = useCallback(
    () => (activeFilters.length === filters.length
      ? setActiveFilters([]) // Only toggle all items off if all are active
      : setActiveFilters(filters)),
    [activeFilters.length, filters],
  );

  /** Add new listing IDs to active filter */
  const addListingFilters = useCallback(
    /**
     * @param {T[]} listingIds
     */
    listingIds => setActiveFilters(activeFilters.concat(listingIds)),
    [activeFilters, setActiveFilters],
  );

  const clearFilters = useCallback(() => setActiveFilters([]), []);
  const selectAllFilters = useCallback(() => setActiveFilters(filters), [filters]);

  return useMemo(() => ({
    addListingFilters,
    filters,
    activeFilters,
    toggleFilter,
    toggleAllFilters,
    clearFilters,
    selectAllFilters,
    setActiveFilters,
  }), [
    addListingFilters,
    activeFilters,
    clearFilters,
    filters,
    selectAllFilters,
    toggleAllFilters,
    toggleFilter,
    setActiveFilters,
  ]);
};

export default useFilters;
