export const PAGE_TEXT = {
  title: 'Captain is Optional',
  description: 'You decide if you would like to book with or without a captain.',
};

export const captainFields = [
  {
    key: 'withCaptain',
    name: 'captain_required',
    value: true,
    title: 'With Captain',
    text: 'A captain is included in the price or otherwise arranged to host and operate the trip.',
    icon: '#icon-tick',
  },
  {
    key: 'noCaptain',
    name: 'captain_required',
    value: false,
    title: 'No Captain',
    text: 'You will provide or act as the qualified operator.',
    icon: '#icon-cross',
  },
];
