import { useEffect, useRef } from 'react';
import { isMobile } from '../helpers';

const useFocusByRefOnMount = <T extends HTMLElement>(): React.MutableRefObject<T> => {
  const ref = useRef<T>(null as unknown as T);
  useEffect(
    () => {
      if (!isMobile()) {
        ref?.current?.focus();
      }
    },
    [],
  );
  return ref;
};

export default useFocusByRefOnMount;
