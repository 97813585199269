import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import type { Decorator } from 'final-form';
import createDecorator from 'final-form-calculate';

import Button from 'src/common/components/Button';
import { trackEvent } from 'src/common/tracking';
import { BookingMotivatorsProvider } from '../../../common/hooks/useBookingMotivators';
import Modal from '../../../common/components/Modal';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { open, close } from '../../../common/ducks/zippy';
import {
  GA_EVENT_ACTION_PREFIX,
  INSTABOOK_EDITOR_MODAL_NAME as modalName,
  INSTABOOK_FIXED_DEPARTURE_TIMES,
  INSTABOOK_FIXED_DEPARTURE_TIMES_ERROR,
  INSTABOOK_DEPARTURE_TIME_FLEXIBLE,
  INSTABOOK_PRICE_FIELD,
  INSTABOOK_CAPTAINED_FIELD,
  INSTABOOK_CAPTAINED_ERROR,
  INSTABOOK_PUBLISHED_FIELD,
  INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_FIELD,
  INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_ERROR,
  INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_LENGTH_ERROR,
  INSTABOOK_FLEXIBLE_DEPARTURE_TIME_END,
  INSTABOOK_FLEXIBLE_DEPARTURE_TIME_START,
  INSTABOOK_CAPTAIN_PRICE_FIELD,
  INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD,
} from '../../constants';

import { useListingsFilterContext } from '../../hooks';
import { useInstabookEditorContext } from '../../hooks/useInstabookEditor';
import useCalendarFocus from '../../hooks/useCalendarFocus';

import InstabookEditor from '../InstabookEditor';
import InstabookDepartureTimesEditor from '../InstabookDepartureTimesEditor';
import {
  setCaptainProvided,
  setCaptainNotProvided,
  setCaptainArrangedSeparately,
  unselectCaptainOptions,
} from '../InstabookCaptainOption/mutators';

import { getInitialInstabookValues, prepareInstabookDataForAPI, type InstabookFormState } from '../../instabook';

import hasFixedTimeDuplicates from '../../utils/hasFixedTimeDuplicates';
import FormError from '../../../common/components/FormError';
import timeStringToDate from '../../../instabook/utils/timeStringToDate';
import Icon from '../../../common/components/IconDS22';

import s from './InstabookEditorWrapper.module.scss';

type InstabookEditorWrapperProps = {
  onOpenModal?: () => void;
  selectedInstabookDate?: Date | null;
};

const InstabookEditorWrapper: FC<InstabookEditorWrapperProps> = ({
  onOpenModal,
  selectedInstabookDate,
}) => {
  const [editingTimes, setEditingTimes] = useState(false);
  const {
    boat,
    loadBoat,
    instabookListing,
    instabook,
    isLoading,
    resetInstabook,
    saveInstabook,
    instabookEditorModalContentRef,
    error: apiError,
    onInstabookPriceChange,
  } = useInstabookEditorContext();
  const { selectedDate } = useCalendarFocus();
  const { activeFilters, addListingFilters } = useListingsFilterContext();

  const costBreakdownUpdater = useMemo(() => createDecorator({
    field: [INSTABOOK_PRICE_FIELD, INSTABOOK_CAPTAIN_PRICE_FIELD],
    updates: (_value, _name, allValues) => {
      onInstabookPriceChange(allValues as InstabookFormState);
      // Return {} means values unchanged
      return {};
    },
  }), [onInstabookPriceChange]) as Decorator<InstabookFormState>;

  useEffect(() => {
    if (instabookListing?.id && instabookListing.id !== boat?.id) {
      loadBoat(instabookListing.id);
    }
  }, [boat?.id, instabookListing?.id, loadBoat]);

  const dispatch = useDispatch();

  const openTimeEditor = useCallback(() => setEditingTimes(true), [setEditingTimes]);

  const closeTimeEditor = useCallback(() => setEditingTimes(false), [setEditingTimes]);

  const openModal = useCallback(() => {
    if (onOpenModal) onOpenModal();
    dispatch(open(modalName));
  }, [dispatch, onOpenModal]);

  const closeModal = useCallback(() => {
    if (editingTimes) closeTimeEditor();
    dispatch(close(modalName));
    resetInstabook();
  }, [editingTimes, closeTimeEditor, dispatch, resetInstabook]);

  const initialValues = useMemo(
    () => getInitialInstabookValues(instabook, selectedDate),
    [instabook, selectedDate],
  );

  const captainCostEnabled = useRef(false);

  const validate = useCallback(
    ({
      [INSTABOOK_FIXED_DEPARTURE_TIMES]: fixedTimes,
      [INSTABOOK_DEPARTURE_TIME_FLEXIBLE]: isFlexible,
      [INSTABOOK_PRICE_FIELD]: price,
      [INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD]: captainCostIncluded,
      [INSTABOOK_CAPTAIN_PRICE_FIELD]: captainPrice,
      [INSTABOOK_CAPTAINED_FIELD]: captained,
      [INSTABOOK_PUBLISHED_FIELD]: published,
      [INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_FIELD]: tripDescription,
      [INSTABOOK_FLEXIBLE_DEPARTURE_TIME_START]: flexibleStart,
      [INSTABOOK_FLEXIBLE_DEPARTURE_TIME_END]: flexibleEnd,
    }) => {
      const errors: Record<string, string> = {};
      const flexibleStartDate = timeStringToDate(flexibleStart).getTime();
      const flexibleEndDate = timeStringToDate(flexibleEnd).getTime();
      captainCostEnabled.current = !!captained && !captainCostIncluded;

      if (published) {
        // If the instabook is to be published, price must be set
        if (!price) {
          errors[INSTABOOK_PRICE_FIELD] = 'Please set the price.';
        }
        if (captainCostEnabled.current && captainPrice < 1) {
          errors[INSTABOOK_CAPTAIN_PRICE_FIELD] = 'Please set the price.';
        }
        if (!isFlexible && fixedTimes.length === 0) {
          errors[INSTABOOK_FIXED_DEPARTURE_TIMES_ERROR] = 'Please provide departure times';
        }
      }

      if (fixedTimes && hasFixedTimeDuplicates(fixedTimes)) {
        errors[INSTABOOK_FIXED_DEPARTURE_TIMES_ERROR] = 'Duplicate times are not allowed.';
      }

      if (isFlexible && flexibleStartDate > flexibleEndDate) {
        errors[INSTABOOK_FLEXIBLE_DEPARTURE_TIME_START] = 'The minimum start time cannot be after the maximum start time.';
        errors[INSTABOOK_FLEXIBLE_DEPARTURE_TIME_END] = 'The maximum start time cannot be before the minimum start time.';
      }

      if (boat?.active) {
        if (captained == null) {
          errors[INSTABOOK_CAPTAINED_ERROR] = 'Please select an option.';
        } else if ((captained && !boat?.captained) || (!captained && !boat?.bareboat)) {
          errors[INSTABOOK_CAPTAINED_ERROR] = 'This captain selection has been removed from this listing.';
        }
      }

      if (!tripDescription) {
        errors[
          INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_ERROR
        ] = 'Please provide a trip description.';
      }

      if (tripDescription && tripDescription.trim().length > 1000) {
        errors[
          INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_LENGTH_ERROR
        ] = `${tripDescription.length} > 1000 characters`;
      }

      return errors;
    },
    [boat],
  );

  const onSubmit = useCallback(
    (values) => {
      if (boat) {
        saveInstabook(prepareInstabookDataForAPI(values, boat), closeModal).then(() => {
          // Add instabook listing into listings filter only if filter is not empty
          // and instabook listing isn't included into the filter
          if (activeFilters.length > 0 && !activeFilters.includes(boat.id)) {
            addListingFilters([boat.id]);
          }
        });
      }
    },
    [boat, saveInstabook, activeFilters, closeModal, addListingFilters],
  );

  return (
    <div className={s.root}>
      <Button
        type="button"
        classNameModifier="instabook-green noMargin"
        onClick={() => {
          openModal();
          trackEvent(`${GA_EVENT_ACTION_PREFIX.INSTABOOK} Open`, {
            event_category: 'Instabook',
            event_label: 'New',
          });
        }}
        fullWidth
      >
        <Icon
          id="instabook-filled"
          size="m"
          className={s.buttonIcon}
        />
        Add Instabook
      </Button>
      <BookingMotivatorsProvider boatId={boat?.id}>
        <Modal
          disableOverlayClick
          label="Instabook Trip"
          name={modalName}
          classNameModifier={classNames('calendar', 'instabook', { alignVertically: isLoading })}
          closeModal={editingTimes ? closeTimeEditor : closeModal}
          contentRef={(node: HTMLDivElement) => {
            instabookEditorModalContentRef.current = node;
          }}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <Form
              decorators={[costBreakdownUpdater]}
              initialValues={initialValues}
              onSubmit={onSubmit}
              validate={validate}
              mutators={{
                ...arrayMutators,
                setCaptainProvided,
                setCaptainNotProvided,
                setCaptainArrangedSeparately,
                unselectCaptainOptions,
              }}
            >
              {({ handleSubmit, errors, values, form: { mutators } }) => (
                <form
                  className={s.form}
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <InstabookEditor
                    captainCostEnabled={captainCostEnabled.current}
                    values={values}
                    openTimeEditor={openTimeEditor}
                    closeModal={closeModal}
                    selectedInstabookDate={selectedInstabookDate}
                  />
                  <InstabookDepartureTimesEditor
                    show={editingTimes}
                    // @ts-ignore TODO: Mutators type seems to be buggy?
                    mutators={mutators}
                    values={values}
                    errors={errors}
                    closeTimeEditor={closeTimeEditor}
                  />
                  <FormError error={apiError.length && apiError} />
                </form>
              )}
            </Form>
          )}
        </Modal>
      </BookingMotivatorsProvider>
    </div>
  );
};

export default InstabookEditorWrapper;
