import React, { CSSProperties, FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import bgConfetti from 'src/img/calendar-bg-confetti.svg';
import { getClassNameFor } from 'src/common/helpers';
import { sharedMediaQueries, useMediaQuery } from 'src/common/hooks';

import s from './EventDetailsHeaderConfetti.module.scss';

const colors = ['#fdecff', '#ffe0dd', '#c0fff3', '#e5fbff', '#e5fbff'];
const FADE_OUT_DURATION = 800;

/**
 * Generates a random integer inclusive of the min and max values
 */
function getRandomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export type Props = {
  /**
   * The number of particles to render
   */
  particleCount?: number;
  /**
   * The width of the container - this is used to position the particles
   */
  width?: number;
  /**
   * The duration of the animation in milliseconds
   */
  duration?: number;
};

type Particle = {
  id: number;
  color: string;
  shape: number;
  // x position of point 1 of the bezier curve
  p1x: number;
  // y position of point 1 of the bezier curve
  p1y: number;
  // x position of control point 1 of the bezier curve
  cp1x: number;
  // y position of control point 1 of the bezier curve
  cp1y: number;
  // As above but for point 2 and control point 2
  p2x: number;
  p2y: number;
  cp2x: number;
  cp2y: number;
  // The starting rotation of the particle
  rotateStart: string;
  // The ending rotation of the particle
  rotateEnd: string;
};

const generateParticles = (count: number, width: number) => {
  const parts: Particle[] = [];
  for (let i = 0; i < count; i += 1) {
    const startPosX = getRandomInteger(-10, width);
    parts.push({
      id: i,
      color: colors[getRandomInteger(0, colors.length)],
      shape: getRandomInteger(0, 4),
      p1x: startPosX,
      p1y: 0,
      cp1x: getRandomInteger(startPosX - 20, startPosX),
      cp1y: 161,
      p2x: getRandomInteger(startPosX - 40, startPosX),
      p2y: 390,
      cp2x: getRandomInteger(startPosX - 40, startPosX + 40),
      cp2y: 214,
      rotateStart: `${getRandomInteger(-10, 10)}deg`,
      rotateEnd: `${getRandomInteger(-50, 50)}deg`,
    });
  }
  return parts;
};

const EventDetailsHeaderConfetti: FC<Props> = ({
  particleCount = 55,
  width = 690,
  duration = 3500,
}) => {
  const [particles, setParticles] = useState<Particle[]>();
  const [isShowing, setIsShowing] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const prefersReducedMotion = useMediaQuery(sharedMediaQueries.prefersReducedMotion);

  useEffect(() => {
    setParticles(generateParticles(particleCount, width));

    let fadeOutTimeout: ReturnType<typeof setTimeout>;
    let stopPlayingTimeout: ReturnType<typeof setTimeout>;

    if (!prefersReducedMotion) {
      fadeOutTimeout = setTimeout(() => {
        setIsShowing(false);
      }, duration - FADE_OUT_DURATION);

      stopPlayingTimeout = setTimeout(() => {
        setIsPlaying(false);
      }, duration);
    }

    return () => {
      clearTimeout(fadeOutTimeout);
      clearTimeout(stopPlayingTimeout);
    };
  }, [particleCount, width, duration, prefersReducedMotion]);

  useEffect(() => {
    // If the user has reduced motion enabled, stop the animations
    if (prefersReducedMotion) {
      setIsPlaying(false);
      setIsShowing(false);
    }
  }, [prefersReducedMotion]);

  return (
    <div className={s.root}>
      {isPlaying && !prefersReducedMotion && (
        <div className={getClassNameFor(s, 'confettiContainer', classNames({ hide: !isShowing }))}>
          {isPlaying && particles && particles.map((particle) => (
            <div
              key={particle.id}
              className={classNames(s.confetti, s[`confetti-shape-${particle.shape}`])}
              style={{
                backgroundColor: particle.color,
                '--pid': particle.id,
                '--p1x': particle.p1x,
                '--cp1x': particle.cp1x,
                '--p2x': particle.p2x,
                '--cp2x': particle.cp2x,
                '--rotateStart': particle.rotateStart,
                '--rotateEnd': particle.rotateEnd,
              } as CSSProperties}
            />
          ))}
        </div>
      )}

      <div className={getClassNameFor(s, 'bgImage', classNames({ show: !isShowing }))}>
        <img src={bgConfetti} alt="Confetti" />
      </div>
    </div>
  );
};

export default EventDetailsHeaderConfetti;
