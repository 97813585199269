export const TRIP_STATES = {
  OWNER_DIRECT_INQUIRY: 'owner_direct_inquiry',
  OWNER_MATCHING_INQUIRY: 'owner_matching_inquiry',
  OWNER_OFFER_SENT: 'owner_offer_sent',
  OWNER_OFFER_CANCELLED: 'owner_offer_cancelled',
  OWNER_OFFER_AUTO_CANCELLED: 'owner_offer_auto_cancelled',
  OWNER_OFFER_EDITED: 'owner_offer_edited',
  OWNER_OFFER_EXPIRED: 'owner_offer_expired',
  OWNER_CUSTOM_OFFER_EXPIRED: 'owner_custom_offer_expired',
  OWNER_INQUIRY_DECLINED: 'owner_inquiry_declined',
  OWNER_INQUIRY_CANCELLED: 'owner_inquiry_cancelled',
  OWNER_INQUIRY_LAPSED: 'owner_inquiry_lapsed',
  OWNER_RESERVATION_CONFIRMED: 'owner_reservation_confirmed',
  OWNER_RESERVATION_CANCELLED_BY_OWNER: 'owner_reservation_cancelled_by_owner',
  OWNER_RESERVATION_CANCELLED_BY_RENTER: 'owner_reservation_cancelled_by_renter',
  OWNER_TRIP_COMPLETE_REVIEW_PENDING: 'owner_trip_complete_review_pending',
  OWNER_TRIP_COMPLETE_REVIEW_WAITING: 'owner_trip_complete_review_waiting',
  OWNER_TRIP_COMPLETE_REVIEW_DONE: 'owner_trip_complete_review_done',
  OWNER_TRIP_MOVED: 'owner_trip_moved',
  OWNER_BROKEN_STATE: 'owner_broken_state',
  OWNER_CHANGES_REQUESTED: 'owner_changes_requested',

  RENTER_DIRECT_INQUIRY: 'renter_direct_inquiry',
  RENTER_MATCHING_INQUIRY: 'renter_matching_inquiry',
  RENTER_OFFER_RECEIVED: 'renter_offer_received',
  RENTER_OFFER_CANCELLED: 'renter_offer_cancelled',
  RENTER_OFFER_AUTO_CANCELLED: 'renter_offer_auto_cancelled',
  RENTER_OFFER_EDITED: 'renter_offer_edited',
  RENTER_OFFER_EXPIRED: 'renter_offer_expired',
  RENTER_CUSTOM_OFFER_EXPIRED: 'renter_custom_offer_expired',
  RENTER_INQUIRY_DECLINED: 'renter_inquiry_declined',
  RENTER_INQUIRY_CANCELLED: 'renter_inquiry_cancelled',
  RENTER_INQUIRY_LAPSED: 'renter_inquiry_lapsed',
  RENTER_RESERVATION_CONFIRMED: 'renter_reservation_confirmed',
  RENTER_RESERVATION_CANCELLED_BY_OWNER: 'renter_reservation_cancelled_by_owner',
  RENTER_RESERVATION_CANCELLED_BY_RENTER: 'renter_reservation_cancelled_by_renter',
  RENTER_TRIP_COMPLETE_REVIEW_PENDING: 'renter_trip_complete_review_pending',
  RENTER_TRIP_COMPLETE_REVIEW_WAITING: 'renter_trip_complete_review_waiting',
  RENTER_TRIP_COMPLETE_REVIEW_DONE: 'renter_trip_complete_review_done',
  RENTER_TRIP_MOVED: 'renter_trip_moved',
  RENTER_BROKEN_STATE: 'renter_broken_state',
  RENTER_CHANGES_REQUESTED: 'renter_changes_requested',

  // These states are the same irrespective of the requesting user being the renter / owner
  YOU_BEEN_BANNED: 'you_been_banned',
  THEY_BEEN_BANNED: 'they_been_banned',
  YOU_BEEN_SUSPENDED: 'you_been_suspended',
  THEY_BEEN_SUSPENDED: 'they_been_suspended',
};

export const OWNER_NEW_INQUIRY_STATES = [
  TRIP_STATES.OWNER_DIRECT_INQUIRY,
  TRIP_STATES.OWNER_MATCHING_INQUIRY,
];

export const BOOK_NOW_STATES = [
  TRIP_STATES.RENTER_OFFER_RECEIVED,
  TRIP_STATES.RENTER_OFFER_EDITED,
];
export const CONFIRMED_STATES = [
  TRIP_STATES.OWNER_RESERVATION_CONFIRMED,
  TRIP_STATES.RENTER_RESERVATION_CONFIRMED,
];
export const OWNER_COMPLETE_STATES = [
  TRIP_STATES.OWNER_TRIP_COMPLETE_REVIEW_PENDING,
  TRIP_STATES.OWNER_TRIP_COMPLETE_REVIEW_WAITING,
  TRIP_STATES.OWNER_TRIP_COMPLETE_REVIEW_DONE,
];
export const RENTER_COMPLETE_STATES = [
  TRIP_STATES.RENTER_TRIP_COMPLETE_REVIEW_PENDING,
  TRIP_STATES.RENTER_TRIP_COMPLETE_REVIEW_WAITING,
  TRIP_STATES.RENTER_TRIP_COMPLETE_REVIEW_DONE,
];
export const COMPLETE_STATES = [
  ...OWNER_COMPLETE_STATES,
  ...RENTER_COMPLETE_STATES,
];
export const INQUIRY_CANCELLED_STATES = [
  TRIP_STATES.RENTER_INQUIRY_CANCELLED,
  TRIP_STATES.OWNER_INQUIRY_CANCELLED,
];
export const OFFER_SENT_STATES = [
  TRIP_STATES.OWNER_OFFER_SENT,
  TRIP_STATES.OWNER_OFFER_EDITED,
];
export const MOVED_STATES = [
  TRIP_STATES.RENTER_TRIP_MOVED,
  TRIP_STATES.OWNER_TRIP_MOVED,
];
export const LAPSED_STATES = [
  TRIP_STATES.RENTER_INQUIRY_LAPSED,
  TRIP_STATES.OWNER_INQUIRY_LAPSED,
];

// Trips with these statuses can't send messages.
export const MESSAGE_BLACKLIST_STATES = [
  ...OWNER_NEW_INQUIRY_STATES,
  ...INQUIRY_CANCELLED_STATES,
  ...MOVED_STATES,
];

export const OWNER_OFFER_EXPIRED_STATES = [
  TRIP_STATES.OWNER_OFFER_EXPIRED,
  TRIP_STATES.OWNER_CUSTOM_OFFER_EXPIRED,
];

export const OWNER_OFFER_STATES = [
  ...OFFER_SENT_STATES,
  TRIP_STATES.OWNER_OFFER_CANCELLED,
  TRIP_STATES.OWNER_OFFER_AUTO_CANCELLED,
  ...OWNER_OFFER_EXPIRED_STATES,
];

export const ASK_TO_SEND_OFFER = [
  ...OWNER_NEW_INQUIRY_STATES,
  TRIP_STATES.OWNER_INQUIRY_DECLINED,
  ...OWNER_OFFER_STATES,
  TRIP_STATES.OWNER_RESERVATION_CANCELLED_BY_RENTER,
  TRIP_STATES.OWNER_RESERVATION_CANCELLED_BY_OWNER,
];

export const ASK_TO_SEND_NEW_INQUIRY_STATES = [
  TRIP_STATES.RENTER_INQUIRY_CANCELLED,
  TRIP_STATES.RENTER_INQUIRY_DECLINED,
  TRIP_STATES.RENTER_OFFER_CANCELLED,
  TRIP_STATES.RENTER_OFFER_AUTO_CANCELLED,
  TRIP_STATES.RENTER_RESERVATION_CANCELLED_BY_OWNER,
  TRIP_STATES.RENTER_RESERVATION_CANCELLED_BY_RENTER,
];

export const INQUIRY_DECLINE_STATES = [
  TRIP_STATES.RENTER_INQUIRY_DECLINED,
  TRIP_STATES.OWNER_INQUIRY_DECLINED,
];

export const OFFER_CANCELLED_STATES = [
  TRIP_STATES.RENTER_OFFER_CANCELLED,
  TRIP_STATES.OWNER_OFFER_CANCELLED,
];

export const RESERVATION_CANCELLED_STATES = [
  TRIP_STATES.OWNER_RESERVATION_CANCELLED_BY_OWNER,
  TRIP_STATES.OWNER_RESERVATION_CANCELLED_BY_RENTER,
  TRIP_STATES.RENTER_RESERVATION_CANCELLED_BY_OWNER,
  TRIP_STATES.RENTER_RESERVATION_CANCELLED_BY_RENTER,
  TRIP_STATES.OWNER_TRIP_MOVED,
  TRIP_STATES.RENTER_TRIP_MOVED,
];

export const REVIEW_PENDING_STATES = [
  TRIP_STATES.OWNER_TRIP_COMPLETE_REVIEW_PENDING,
  TRIP_STATES.RENTER_TRIP_COMPLETE_REVIEW_PENDING,
];

export const REVIEW_WAITING_STATES = [
  TRIP_STATES.OWNER_TRIP_COMPLETE_REVIEW_WAITING,
  TRIP_STATES.RENTER_TRIP_COMPLETE_REVIEW_WAITING,
];

export const REVIEW_DONE_STATES = [
  TRIP_STATES.OWNER_TRIP_COMPLETE_REVIEW_DONE,
  TRIP_STATES.RENTER_TRIP_COMPLETE_REVIEW_DONE,
];

export const RENTER_NEW_OFFER = [
  TRIP_STATES.RENTER_OFFER_RECEIVED,
  TRIP_STATES.RENTER_OFFER_EDITED,
];

export const RENTER_OFFER_EXPIRED_STATES = [
  TRIP_STATES.RENTER_OFFER_EXPIRED,
  TRIP_STATES.RENTER_CUSTOM_OFFER_EXPIRED,
];

export const CUSTOM_OFFER_EXPIRY_STATES = [
  ...OFFER_SENT_STATES,
  ...BOOK_NOW_STATES,
  TRIP_STATES.OWNER_CUSTOM_OFFER_EXPIRED,
  TRIP_STATES.RENTER_CUSTOM_OFFER_EXPIRED,
];

export const PREFERRED_DATE_FIELD = 'preferred_date';
export const TRIP_END_DATE_FIELD = 'trip_ends_at_UTC';
export const ALTERNATIVE_DATE_FIELD = 'alternative_date';
export const ALTERNATIVE_RETURN_DATE_FIELD = 'alternative_return_date';
export const BOAT_FIELD = 'boat';
export const DATES_FIELDS = [
  PREFERRED_DATE_FIELD,
  ALTERNATIVE_RETURN_DATE_FIELD,
  ALTERNATIVE_DATE_FIELD,
];
export const GUESTS_FIELD = 'guests';
export const SUGGESTED_DATES_FIELD = 'suggested_dates';
export const PICKUP_TIME_FIELD = 'pickup_time';
export const RETURN_TIME_FIELD = 'return_time';
export const TIME_FIELDS = [
  PICKUP_TIME_FIELD,
  RETURN_TIME_FIELD,
];
export const TRIP_LENGTH_FIELD = 'trip_length';
export const CAPTAINED_FIELD = 'captained';
export const CAPTAIN_COST_INCLUDED_FIELD = 'captain_cost_included';
export const CAPTAIN_OPTION_IS_AUTO_PATCHED_FIELD = 'captainOptionIsAutoPatched';

// Fields to check for trip details modifications
export const TRIP_FIELDS = [
  BOAT_FIELD,
  CAPTAINED_FIELD,
  GUESTS_FIELD,
  ...TIME_FIELDS,
  PREFERRED_DATE_FIELD,
  TRIP_LENGTH_FIELD,
];

export const HAS_DAY_PRICING = 'has_day_pricing';
export const HAS_NIGHT_PRICING = 'has_night_pricing';
export const DEFAULT_DAY_TRIP_HOURS = 8;

export const FORMS = {
  CAPTAIN: 'captain',
  DATES: 'dates',
  DURATION: 'duration',
  GROUP_SIZE: 'groupSize',
  TIME: 'time',
  VESSEL: 'vessel',
};

export const PAGES = {
  EDIT: 'edit',
  MESSAGES: 'messages',
  PRICE: 'price',
  REVIEW_BOOKING: 'review-booking',
  INSURANCE: 'insurance',
};

export const UNSENT_CHANGES_WARNING = 'Unsent Changes';
export const REQUESTED_CHANGES_WARNING = 'Requested Changes';
export const WITH_CAPTAIN_LABEL = 'With Captain';

export const GUEST_FIELDS = ['adults', 'children', 'seniors', 'infants'];

export const PHOTO_PREVIEW_MODAL_NAME = 'photo-preview';

export const OFFER_EXPIRY_DATE_FIELD = 'expiry_date';
export const OFFER_EXPIRY_TIME_FIELD = 'expiry_time';
export const OFFER_EXPIRY_HOURS_FIELD = 'expiry_hours';

export const OFFER_EXPIRY_TIME_FORMAT = 'HH:mm';
export const OFFER_EXPIRY_DATE_FORMAT = 'yyyy-MM-dd\'T\'HH:mm';

export const PAYMENT_STATUS = {
  CANCELED: 'canceled',
  CONFIRMED: 'confirmed',
  EXPIRED: 'expired',
  PENDING: 'pending',
  REFUNDED: 'refunded',
} as const;

export const CAPTAIN_HASH_URLS = {
  INVITE: '/captain/invite',
  SELECT: '/captain/select',
};
