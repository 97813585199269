import React from 'react';
import PropTypes from 'prop-types';

import { getClassNameFor } from '../../helpers';
import LazyImg from '../LazyImg';
import s from './PhotosGrid.module.scss';

const PhotosGrid = ({
  boatHeadline,
  classNameModifier,
  openPhoto,
  photos,
}) => !photos.isEmpty() && (
  <ol className={getClassNameFor(s, 'root', classNameModifier)}>
    {photos.map(({ description, src }, index) => (
      <li
        key={src}
        className={s.item}
      >
        <a
          href={src}
          onClick={event => typeof openPhoto === 'function' && openPhoto(event, index)}
        >
          <LazyImg
            src={src}
            alt={description || boatHeadline}
            className={s.image}
            width={172}
            sizes={null}
            imgixProps={{
              fit: 'crop',
              w: 172,
              h: 103,
            }}
          />
        </a>
      </li>
    ))}
  </ol>
);

PhotosGrid.propTypes = {
  boatHeadline: PropTypes.string,
  classNameModifier: PropTypes.string,
  openPhoto: PropTypes.func,
  photos: PropTypes.object.isRequired,
};

export default PhotosGrid;
