import React, { FC, ReactElement } from 'react';

import { getClassNameFor } from '../../../common/helpers';
import s from './ListingsDropdownItem.module.scss';

type Props = {
  control: ReactElement;
  classNameModifier?: string;
};

const ListingsDropdownItem: FC<Props> = ({
  children,
  control,
  classNameModifier = '',
}) => (
  <li className={getClassNameFor(s, 'root', classNameModifier)}>
    <label className={getClassNameFor(s, 'label', classNameModifier)}>
      {control}
      {children}
    </label>
  </li>
);

export default ListingsDropdownItem;
