/* NOTE:
 *
 * This is basically an implementation of the ALLOWED_HOSTS
 * concept from Django:
 * https://docs.djangoproject.com/en/2.0/ref/settings/#allowed-hosts
 *
 * isSameDomain mirrors the implementation of is_same_domain:
 * https://github.com/django/django/blob/master/django/utils/http.py#L266
 *
 * validateHost comes from validate_host:
 * https://github.com/django/django/blob/master/django/http/request.py#L567
 *
 * Test are also borrowed from the Django source.
 *
 * This file uses module.exports and simpler syntax
 * to get around the fact that when you run `babel-node`
 * (as the start.js file does), it doesn't compile everything
 * in the tree; it only compiles the first file referenced.
 * That means that this file needs to use features implemented
 * in Node 6.
*/
export const isSameDomain = (host: string, pattern: string): boolean => {
  const lowerPattern = pattern.toLowerCase();
  // TODO: make this more clear.
  return (
    (
      lowerPattern[0] === '.'
        && (
          host.endsWith(lowerPattern)
          || host === lowerPattern.slice(1)
        )
    )
      || lowerPattern === host
  );
};

export const validateHost = (host: string, allowedHosts: string[]): boolean => (
  allowedHosts.some(pattern => pattern === '*' || isSameDomain(host, pattern))
);
