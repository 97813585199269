import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { TripState } from 'src/types/trips/Trips';
import { getClassNameFor } from 'src/common/helpers';
import IconDS22 from 'src/common/components/IconDS22';

import { TRIP_STATES } from 'src/inbox/constants';
import s from './EventShell.module.scss';

type EventShellProps = {
  children: ReactNode;
  className?: string;
  classNameModifier?: string;
  conflicted?: boolean;
  disabled?: boolean;
  eventType: 'adHoc' | 'booking_inquiry' | 'offer' | 'reservation';
  groupId: string;
  handleClick: () => void;
  time: ReactNode;
  title: string;
  multiDayDuration?: string;
  tripStateIndicator?: ReactNode;
  unavailable?: ReactNode;
  tripState?: TripState;
};

const EventShell: FC<EventShellProps> = ({
  children,
  className = '',
  classNameModifier = '',
  conflicted,
  disabled,
  eventType,
  groupId,
  handleClick,
  title,
  time,
  multiDayDuration,
  tripStateIndicator,
  tripState,
  unavailable,
}) => {
  const isOfferCancelled = tripState?.state === TRIP_STATES.OWNER_OFFER_CANCELLED
    || tripState?.state === TRIP_STATES.OWNER_OFFER_AUTO_CANCELLED;

  const isOfferExpired = tripState?.state === TRIP_STATES.OWNER_CUSTOM_OFFER_EXPIRED
    || tripState?.state === TRIP_STATES.OWNER_OFFER_EXPIRED;

  const shouldDisplayConflict = conflicted && !isOfferCancelled && !isOfferExpired;

  return (
    <dd
      className={classNames(getClassNameFor(s, 'root', `${eventType} ${classNameModifier}`), className)}
      data-focus-event-id={groupId}
      data-test="EventShell"
    >
      {children}
      <button
        className={s.button}
        disabled={disabled}
        onClick={handleClick}
        type="button"
        data-test="EventShellButton"
      >
        {unavailable}
        <div className={s.eventCard}>
          <ul className={s.details}>
            <li
              className={getClassNameFor(s, 'time', classNameModifier)}
              data-test="EventShellTime"
            >
              {time}
            </li>
            {tripStateIndicator}
            <hr className={getClassNameFor(s, 'lineBreak', classNameModifier)} />
            <li
              className={s.title}
              data-test="EventShellTitle"
            >
              {title}
            </li>
            {multiDayDuration && (
              <li
                className={s.multiDayDuration}
                data-test="EventShellMultiDayIndicator"
              >
                <IconDS22 id="calendar-events" />
                {multiDayDuration}
              </li>
            )}
          </ul>
          {shouldDisplayConflict && (
            <IconDS22 id="warning" className={s.warningIcon} />
          )}
        </div>
      </button>
    </dd>
  );
};

export default EventShell;
