import React, { FC, ReactNode, useMemo } from 'react';
import Imgix from 'react-imgix';
import LazyLoad from 'react-lazyload';

import { ImgixParams } from '../../../types/imgix';
import { defaultImgixProps, pointAtImgix, lqipParams } from '../../utils/imgix';

const noop = () => { };

export type LazyImgProps = {
  height: number | string;
  width?: number;
  offset?: number;
  scrollContainer?: string;
  overflow?: boolean;
  src: string;
  alt: string;
  imgixProps: ImgixParams;
  q?: number;
  lossless?: boolean;
  disableLQIP?: boolean;
  sizes?: string;
  placeholder?: ReactNode;
  lazy?: boolean;
  onError?: () => void;
  loading?: 'eager' | 'lazy';
  className?: string;
};

const LazyImg: FC<LazyImgProps> = ({
  height,
  width,
  offset,
  scrollContainer,
  overflow = false,
  src,
  alt,
  imgixProps,
  q = defaultImgixProps.q,
  lossless = defaultImgixProps.lossless,
  disableLQIP = false,
  sizes = '100vw',
  placeholder,
  lazy = true,
  onError = noop,
  loading = 'lazy',
  ...otherProps
}) => {
  const url = pointAtImgix(src);
  const auto = useMemo(() => (imgixProps && defaultImgixProps.auto) || [], [imgixProps]);
  const customParams = useMemo(() => ({
    q,
    lossless,
    fit: defaultImgixProps.fit,
    ...imgixProps,
    auto: [...defaultImgixProps.auto, ...auto].join(','),
  }), [auto, imgixProps, lossless, q]);
  const htmlAttributes = useMemo(() => ({ alt, loading, onError }), [alt, loading, onError]);
  const imgixParams = useMemo(() => ({ ...customParams, ...lqipParams }), [customParams]);

  // Some LQIP magic. Read up here:
  // https://blog.imgix.com/demos/lqip-demo.html
  // tl;dr: Use imgix magic to render a very low quality version of
  // the picture on first render, which will be replaced on lazy load.
  const placeholderElement = useMemo(() => placeholder || (!disableLQIP && (
    <Imgix
      src={url}
      sizes={sizes}
      width={width}
      htmlAttributes={htmlAttributes}
      imgixParams={imgixParams}
      // This provides a pretty big performance gain on the backend,
      // since in a page like the homepage the backend would be generating
      // somewhere in the neighborhood of 500 srcset urls otherwise.
      // Additionally, it isn't needed on a placeholder that's supposed to
      // be low-quality.
      disableSrcSet
      {...otherProps}
    />
  )), [width, disableLQIP, htmlAttributes, imgixParams, otherProps, placeholder, sizes, url]);

  const img = useMemo(() => (
    <Imgix
      src={url}
      sizes={sizes}
      width={width}
      htmlAttributes={htmlAttributes}
      imgixParams={customParams}
      {...otherProps}
    />
  ), [width, customParams, htmlAttributes, otherProps, sizes, url]);

  if (!lazy) {
    return img;
  }

  // Force Imgix to use the width as the primary determinant of
  // image size. Since we're using 'clip' as our fit method, this
  // should provide the correct image size.
  return (
    <LazyLoad
      once
      height={height}
      offset={offset}
      scrollContainer={scrollContainer}
      placeholder={placeholderElement}
      overflow={overflow}
    >
      {img}
    </LazyLoad>
  );
};

export default LazyImg;
