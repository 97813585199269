import { USER_ROLES } from '../../../../common/constants';

const { RENTER, OWNER } = USER_ROLES;

export const REVIEW_PAGES = {
  START: 'start',
  CATEGORIES: 'categories',
  PRIVATE_NOTE: 'private_note',
  PUBLIC_REVIEW: 'public_review',
  PHOTOS: 'photos',
  REVIEW_SHARE: 'review_share',
  FEEDBACK: 'feedback',
  NPS: 'nps',
  THANKS: 'thanks',
};
const {
  START,
  CATEGORIES,
  PRIVATE_NOTE,
  PUBLIC_REVIEW,
  PHOTOS,
  REVIEW_SHARE,
  FEEDBACK,
  NPS,
  THANKS,
} = REVIEW_PAGES;

const genericPath = {
  [PUBLIC_REVIEW]: {
    back: PRIVATE_NOTE,
    next: PHOTOS,
  },
  [PHOTOS]: {
    next: REVIEW_SHARE,
  },
  [REVIEW_SHARE]: {
    back: PHOTOS,
    next: FEEDBACK,
  },
  [FEEDBACK]: {
    back: REVIEW_SHARE,
    next: NPS,
  },
  [NPS]: {
    back: FEEDBACK,
    next: THANKS,
  },
};

const reviewRoutes = {
  [RENTER]: {
    ...genericPath,
    [START]: {
      next: CATEGORIES,
    },
    [CATEGORIES]: {
      back: START,
      next: PRIVATE_NOTE,
    },
    [PRIVATE_NOTE]: {
      back: CATEGORIES,
      next: PUBLIC_REVIEW,
    },
  },

  [OWNER]: {
    ...genericPath,
    [START]: {
      next: PRIVATE_NOTE,
    },
    [PRIVATE_NOTE]: {
      back: START,
      next: PUBLIC_REVIEW,
    },
    [FEEDBACK]: {
      back: PHOTOS,
      next: NPS,
    },
  },
};

export const FIELDS = {
  RATING: 'rating',
  RECOMMENDATION: 'recommendation',
  PRIVATE_NOTE: 'private_note',
  PUBLIC_REVIEW: 'public_review',
  GETMYBOAT_REVIEW: 'getmyboat_review',
  NET_PROMOTER_SCORE: 'net_promoter_score',
  COMMENT: 'comment', // For the old review form
  LISTING_ACCURACY: 'listing_accuracy',
  DEPARTURE_AND_RETURN: 'departure_and_return',
  VESSEL_AND_EQUIPMENT: 'vessel_and_equipment',
  COMMUNICATION: 'communication',
  VALUE: 'value',
  ITINERARY_AND_EXPERIENCE: 'itinerary_and_experience',
};

export default reviewRoutes;
