import React, { FC } from 'react';
import { FieldInputProps } from 'react-final-form';

import Radio from 'src/common/components/Radio';

const RadioButton: FC<Props> = ({
  disabled,
  id,
  input,
}) => (
  <Radio
    disabled={disabled}
    id={id}
    // If RadioButtonInput is rendered by a Field, it has `input.type` field
    // otherwise we set type prop manually
    type="radio"
    {...input}
  />
);

export type Props = {
  disabled?: boolean;
  id?: string;
  input: Partial<FieldInputProps<string, HTMLInputElement>>;
};

export default RadioButton;
