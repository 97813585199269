import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { PATHS } from '../../../common/constants';
import { getClassNameFor } from '../../../common/helpers';
import s from './CalendarNav.module.scss';

type Props = {
  handleActivityClick?: () => void;
  handleInstabookClick?: () => void;
};

const CalendarNav: FC<Props> = ({ handleActivityClick, handleInstabookClick }) => (
  <ul className={getClassNameFor(s, 'root')}>
    <li className={s.item}>
      <NavLink
        exact
        to={PATHS.CALENDAR}
        className={s.link}
        activeClassName={s.link_active}
        onClick={handleActivityClick}
      >
        Activity
      </NavLink>
    </li>
    <li>
      <NavLink
        to={PATHS.INSTABOOK}
        className={s.link}
        activeClassName={s.link_active}
        onClick={handleInstabookClick}
      >
        Instabook
      </NavLink>
    </li>
  </ul>
);

export default CalendarNav;
