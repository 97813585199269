// Actions
// -----------------------------------------------------------------------------
export const SET_MOBILE_NATIVE_WEBVIEW = 'common/mobileNativeWebView/SET_MOBILE_NATIVE_WEBVIEW';

// Action creators
// -----------------------------------------------------------------------------
export const setMobileNativeWebView = (mobileNativeWebView: boolean) => (
  { type: SET_MOBILE_NATIVE_WEBVIEW, mobileNativeWebView } as const
);

type Actions = ReturnType<typeof setMobileNativeWebView>;

// Reducers
// -----------------------------------------------------------------------------
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (mobileNativeWebView: boolean = false, action: Actions) => {
  switch (action.type) {
    case SET_MOBILE_NATIVE_WEBVIEW:
      return action.mobileNativeWebView;
    default:
      return mobileNativeWebView;
  }
};
