import React, { FC, ReactNode } from 'react';
import { useSelect, UseSelectState, UseSelectStateChangeOptions } from 'downshift';
import classNames from 'classnames';
import Icon, { type IconIds } from '../IconDS22';
import { getClassNameFor } from '../../helpers';

import s from './Dropdown.module.scss';

export type DropdownOption = {
  /**
   * A unique ID for the option.
   */
  id: string;
  /**
   * Optional icon to display on dropdown item
   */
  icon?: IconIds;
  /**
   * The label that displays when the option is selected.
   */
  selectedLabel?: string;
  /**
   * The label that displays in the dropdown.
   */
  dropdownLabel: string;
  /**
   * Disable dropdown option
   */
  disabled?: boolean;
  option?: Record<string, unknown>;
} | null | undefined;

export const variants = ['default', 'white', 'whiteCompact', 'topNav'] as const;
export type Variant = typeof variants[number];

export const modes = ['select', 'menu'] as const;
type Mode = typeof modes[number];

export const dropdownPositions = ['left', 'right'] as const;
export type DropdownPosition = typeof dropdownPositions[number];

export type DropdownProps = {
  /**
   * A unique id that is used to identify the toggle button. Has a default of `dropdown` which
   * will generate the id of `dropdown-toggle-button`
   */
  id?: string;
  /**
   * An array of DropdownOptions that is set as the options in the Dropdown
   */
  options: DropdownOption[];
  /**
   * The label that describes the dropdown.
   * This will be hidden for users, but readable by screenreaders.
   */
  label?: string;
  /**
   * The default option that should be set if no option is selected.
   */
  defaultOption?: DropdownOption;
  /**
   * Text that displays when no option is selected, and no defaultOption is set
   */
  instructionLabel?: ReactNode;
  /**
   * Handler function that gets called when a new option is selected
   * @param option The DropdownOption that is set as the currentOption
   */
  onChange: (option: DropdownOption) => void;
  /**
   * A custom render function that will be used to render the selectedItem in the Dropdown button
   * @param option The DropdownOption that will be rendered
   * @returns A ReactNode
   */
  selectedItemRenderer?: (option: DropdownOption) => React.ReactNode;
  /**
   * The mode of the component. By default, it acts like a <select>, but can be switched to
   * menu mode, which disallows selection of items.
   */
  mode?: Mode;
  /**
   * The style variant. By default, the button has a gray background.
   * The white variant has a white background.
   */
  variant?: Variant;
  /**
   * The alignment position of the dropdown, relative to the button.
   */
  dropdownPosition?: DropdownPosition;
  /**
   * The disabled state of the dropdown.
   */
  disabled?: boolean;
  /**
   * The submitting state of the dropdown, which will show a loading spinner.
   */
  submitting?: boolean;
  fullWidth?: boolean;
  scrollable?: boolean;
  displayCaret?: boolean;
};

const itemToString = (item: DropdownOption): string => item?.dropdownLabel || '';

const Dropdown: FC<DropdownProps> = ({
  id = 'dropdown',
  options,
  defaultOption,
  onChange,
  selectedItemRenderer,
  label,
  instructionLabel = 'Please select...',
  mode = 'select',
  variant = 'default',
  dropdownPosition = 'left',
  disabled = false,
  submitting = false,
  fullWidth = false,
  scrollable = false,
  displayCaret,
}) => {
  const handleSelectedItemChange = (item: DropdownOption | null) => {
    onChange(item);
  };

  const showCaret = displayCaret || variant === 'default' || variant === 'white';

  const stateReducer = (
    state: UseSelectState<DropdownOption>,
    actionAndChanges: UseSelectStateChangeOptions<DropdownOption>,
  ) => {
    const { type, changes } = actionAndChanges;
    switch (type) {
      case useSelect.stateChangeTypes.ItemClick:
      case useSelect.stateChangeTypes.MenuKeyDownEnter:
        /**
         * If the component is in `menu` mode, make sure we always do a handleSelectedItemChange
         * call when an item is selected, even if the selectedItem didn't change.
         * If in `select` mode, we stick to the default of only calling this when the
         * selectedItem changes.
         * This is the recommended way to enable menu-like behaviour:
         * https://github.com/downshift-js/downshift/issues/793#issuecomment-626195214
         */
        if (mode === 'menu' && state.selectedItem === changes.selectedItem) {
          handleSelectedItemChange(changes.selectedItem);
        }
        return changes;
      default:
        return changes; // Otherwise, business as usual.
    }
  };

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useSelect({
    id,
    items: options,
    itemToString,
    defaultSelectedItem: defaultOption,
    stateReducer,
    onSelectedItemChange: ({ selectedItem: newItem }) => handleSelectedItemChange(newItem),
  });

  const renderSelectedItem = () => {
    if (mode === 'menu') {
      return instructionLabel;
    }
    return selectedItemRenderer
      ? selectedItemRenderer(selectedItem || defaultOption)
      : selectedItem?.selectedLabel || instructionLabel;
  };

  return (
    <div className={getClassNameFor(s, 'root', classNames(variant))}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      {label && (
        <label {...getLabelProps()} className={s.label}>
          {label}
        </label>
      )}
      <button
        type="button"
        className={getClassNameFor(s, 'button', classNames(variant, { submitting, fullWidth }))}
        disabled={disabled || submitting}
        {...getToggleButtonProps()}
      >
        <span className={getClassNameFor(s, 'buttonLabel', classNames(variant))}>
          {renderSelectedItem()}
        </span>
        {showCaret
        && (
          <span className={s.buttonCaret}>
            {!submitting && <Icon id={isOpen ? 'caret-up' : 'caret-down'} size="s" />}
          </span>
        )}
      </button>
      <ul
        className={getClassNameFor(s, 'dropdown', classNames(variant, { show: isOpen, scrollable, fullWidth }, dropdownPosition))}
        {...getMenuProps()}
      >
        {isOpen
          && options.map((item, index) => (
            <li
              className={getClassNameFor(
                s,
                'dropdownItem',
                classNames(variant, {
                  highlighted: highlightedIndex === index,
                  selected: mode === 'select' && selectedItem === item,
                  disabled: item?.disabled,
                }),
              )}
              key={item?.id}
              {...getItemProps({
                item,
                index,
                disabled: item?.disabled,
              })}
            >
              {item?.icon && <Icon id={item.icon} size="l" />}
              {item?.dropdownLabel}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Dropdown;
