const HEADER_PINNED_CLASS_NAME = 'with-pinned-header';
const HEADER_UNPINNED_CLASS_NAME = 'with-unpinned-header';
const HEADER_UNFIXED_CLASS_NAME = 'with-unfixed-header';

const handlePin = (action: 'pin' | 'unpin' | 'unfix') => {
  const { body } = document;

  switch (action) {
    case 'pin':
      body.classList.add(HEADER_PINNED_CLASS_NAME);
      body.classList.remove(HEADER_UNPINNED_CLASS_NAME, HEADER_UNFIXED_CLASS_NAME);
      break;
    case 'unpin':
      body.classList.add(HEADER_UNPINNED_CLASS_NAME);
      body.classList.remove(HEADER_PINNED_CLASS_NAME, HEADER_UNFIXED_CLASS_NAME);
      break;
    case 'unfix':
      document.body.classList.add(HEADER_UNFIXED_CLASS_NAME);
      document.body.classList.remove(HEADER_PINNED_CLASS_NAME, HEADER_UNPINNED_CLASS_NAME);
      break;
    default:
  }
};

export default handlePin;
