import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { IMPERSONATION_COOKIE_NAME } from '../../pubsub/constants';
import AuthProxy, { broadcastImpersonate } from './AuthProxy';

/**
 * Refresh the authentication state when a login, logout, impersonate or
 * impersonate-end event occurs on another tab.
 */
const useAuthSync = (checkUserSession: () => void): void => {
  useEffect(
    () => {
      const authProxy = AuthProxy.getInstance();
      authProxy.on('login', () => { checkUserSession(); });
      authProxy.on('logout', () => { checkUserSession(); });
      authProxy.on('impersonate', () => { checkUserSession(); });
      authProxy.on('impersonate-end', ({ redirect }) => { window.location.href = redirect; });

      const isImpersonationSession = !!Cookies.get(IMPERSONATION_COOKIE_NAME);
      if (isImpersonationSession) {
        broadcastImpersonate();
      }
    },
    [checkUserSession],
  );
};

export default useAuthSync;
