import React, { FC } from 'react';
import classNames from 'classnames';
import format from 'date-fns/format';
import { TIME_12 } from 'src/common/constants';
import { PAYMENT_STATUS } from 'src/inbox/constants';
import type { PaymentAddon } from 'src/types/trips/PaymentAddon';
import { getClassNameFor } from 'src/common/helpers';
import Button from 'src/common/components/Button';
import s from './PaymentAddon.module.scss';

export type PaymentAddonProps = {
  status: PaymentAddon['status'];
  paymentAmount: string;
  paymentCategory: string;
  paymentLink: string;
  expiryDate: string;
  lastActivityDate: string;
  userRole: 'owner' | 'renter';
  summaryLink?: string;
};

const PaymentAddon: FC<PaymentAddonProps> = ({
  status,
  paymentAmount,
  paymentCategory,
  expiryDate,
  lastActivityDate,
  userRole,
  summaryLink,
  paymentLink,
}) => {
  const formattedExpiryDate = format(new Date(expiryDate), 'dd/MM/yy');
  const formattedLastActivityDate = format(new Date(lastActivityDate), `dd MMM yyyy ${TIME_12}`);
  const Root = summaryLink ? 'a' : 'div';

  return (
    <Root
      className={getClassNameFor(s, 'root', classNames(status, userRole))}
      href={summaryLink}
    >
      <ul className={getClassNameFor(s, 'list', 'price')}>
        <li className={s.paymentCategory}>{paymentCategory}</li>
        <li className={s.paymentAmount}>{paymentAmount}</li>
      </ul>
      <ul className={s.list}>
        <li className={s.status}>
          {status === PAYMENT_STATUS.PENDING && userRole === 'renter' ? (
            <Button
              href={paymentLink}
              className={s.button}
              classNameModifier="small"
            >
              Pay
            </Button>
          ) : (
            status
          )}
        </li>
        <li className={s.date}>
          <time dateTime={userRole === 'owner' ? lastActivityDate : expiryDate}>
            {userRole === 'owner'
              ? formattedLastActivityDate
              : `Expires ${formattedExpiryDate}`}
          </time>
        </li>
      </ul>
    </Root>
  );
};

export default PaymentAddon;
