import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { nextUrl, authRedirect } from '../../common/utils/routing';
import { ReduxState } from '../../types/reduxState';

const useLoggedInRedirect = (runEffects: boolean = true) => {
  const { push } = useHistory();
  const { search, hash } = useLocation();
  const loggedIn = useSelector<ReduxState, boolean>(
    ({ user }) => Boolean(user.get('loggedIn', false)),
  );
  useEffect(
    () => {
      if (runEffects && loggedIn) {
        authRedirect(nextUrl(search, hash), push);
      }
    },
    [loggedIn, push, search, hash, runEffects],
  );
};

export default useLoggedInRedirect;
