import { FIELDS } from '../../pages/Complete/reviewsConfig';

const {
  LISTING_ACCURACY,
  DEPARTURE_AND_RETURN,
  VESSEL_AND_EQUIPMENT,
  COMMUNICATION,
  VALUE,
  ITINERARY_AND_EXPERIENCE,
} = FIELDS;

const categories = [
  {
    title: 'Listing Accuracy',
    name: LISTING_ACCURACY,
  },
  {
    title: 'Departure & Return',
    name: DEPARTURE_AND_RETURN,
  },
  {
    title: 'Vessel & Equipment',
    name: VESSEL_AND_EQUIPMENT,
  },
  {
    title: 'Communication',
    name: COMMUNICATION,
  },
  {
    title: 'Value',
    name: VALUE,
  },
  {
    title: 'Itinerary & Experience',
    name: ITINERARY_AND_EXPERIENCE,
  },
];

export default categories;
