import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import classNames from 'classnames';
import { MOMENT_FORMAT } from 'src/common/constants';
import { getClassNameFor } from 'src/common/helpers';
import Avatar from 'src/common/components/Avatar';
import MessageContent from '../MessageContent';
import ContactInfoWarning from '../ContactInfoWarning';
import s from './Message.module.scss';

const Message = ({
  avatarStyle,
  message,
  currentUser,
  showDate = false,
}) => {
  const timeStamp = moment(message.get('date_created')).format(`D MMM Y, ${MOMENT_FORMAT.TIME}`);
  const time = moment(message.get('date_created')).format(MOMENT_FORMAT.TIME);
  const messageUser = message.get('user');
  const isOwn = currentUser.get('id') === messageUser.get('id');
  const ownModifier = isOwn ? 'own' : '';
  const messageContent = message.get('content').trim();
  const showContactInfoWarning = message.get('was_obfuscated', false);

  return (
    <li className={getClassNameFor(s, 'root', classNames({ showDate }))}>
      {showDate && (
        <h2 className={s.subject}>
          {`${message.get('subject')}. ${timeStamp}`}
        </h2>
      )}
      <div className={getClassNameFor(s, 'content', ownModifier)}>
        {messageContent.length > 0 && [
          <MessageContent
            key="content"
            ownModifier={ownModifier}
            time={time}
            dateTimeString={message.get('date_created')}
          >
            {messageContent}
          </MessageContent>,
          <Avatar
            key="Avatar"
            user={messageUser.toJS()}
            classNameModifier={`message ${ownModifier}`}
            style={avatarStyle}
          />,
        ]}
      </div>
      {showContactInfoWarning
        && <ContactInfoWarning classNameModifier="messages" />}
    </li>
  );
};

Message.propTypes = {
  avatarStyle: PropTypes.object,
  message: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  showDate: PropTypes.bool,
};

export default Message;
