import React, { FC } from 'react';
import classNames from 'classnames';

import { PATHS, EXTERNAL_PATHS } from '../../constants';
import { getClassNameFor } from '../../helpers';

import NavDropdown from '../NavDropdown';
import SignInLink from '../SignInLink';
import helpMenuItems from './helpMenuItems';
import NavItem from './NavItem';
import s from './Navigation.module.scss';

type Props = {
  classNameModifier?: string;
};
const UnauthenticatedNavItems: FC<Props> = ({ classNameModifier = '' }) => {
  const itemClassName = getClassNameFor(s, 'item', classNameModifier);
  const linkClassName = getClassNameFor(s, 'link', classNameModifier);

  return (
    <>
      <NavItem
        to={EXTERNAL_PATHS.ADMIN_LISTINGS}
        classNameModifier={classNameModifier}
      >
        Create a Listing
      </NavItem>
      <li className={itemClassName}>
        <NavDropdown
          className={linkClassName}
          classNameModifier="help"
          heading="Help"
          aria-label="Help"
          data-test="HelpDropDown"
        >
          {helpMenuItems.map(({ text, url }) => (
            <NavItem
              to={url}
              key={text}
              openInNewTab
              classNameModifier={['subNav', 'anonymous', 'helpMenu'].join(' ')}
            >
              {text}
            </NavItem>
          ))}
        </NavDropdown>
      </li>
      <li className={classNames(itemClassName, s.item_login)}>
        <SignInLink className={linkClassName} />
      </li>
      <NavItem
        to={PATHS.REGISTER}
        classNameModifier={`${classNameModifier} signup`}
      >
        Create Account
      </NavItem>
    </>
  );
};

export default UnauthenticatedNavItems;
