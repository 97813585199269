import React, { FC, useCallback } from 'react';
import type { Action } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter, Route } from 'react-router-dom';

import Modal from 'src/common/components/Modal';
import { trackEvent } from 'src/common/tracking';
import { CAPTAIN_HASH_URLS } from 'src/inbox/constants';
import type { ReduxState } from 'src/types/reduxState';
import { getBoat } from 'src/common/utils/reduxStoreSelectors';
import { getLinkedCaptainsCall } from 'src/inbox/ducks/linkedCaptains';
import CaptainSelectorForm from './CaptainSelectorForm';
import CaptainInviteForm from './CaptainInviteForm';

const CAPTAIN_SELECTOR_MODAL_NAME = 'captainSelector';

type CaptainSelectorProps = {
  closeModal: () => void;
  modalOpen: boolean;
  selectedCaptainId?: number;
  tripId: number;
};

const CaptainSelector: FC<CaptainSelectorProps> = ({
  closeModal,
  modalOpen,
  selectedCaptainId,
  tripId,
}) => {
  const dispatch = useDispatch<ThunkDispatch<ReduxState, {}, Action>>();
  const boatId = useSelector(getBoat).get('id');
  const getCaptains = useCallback(
    () => dispatch(getLinkedCaptainsCall(boatId)),
    [boatId, dispatch],
  );
  const onCloseModal = useCallback(() => {
    closeModal();
    trackEvent('captain_selector_close_no_submit');
  }, [closeModal]);

  return (
    <Modal
      classNameModifier={CAPTAIN_SELECTOR_MODAL_NAME}
      closeModal={onCloseModal}
      modalOpen={modalOpen}
      name={CAPTAIN_SELECTOR_MODAL_NAME}
    >
      <HashRouter>
        <Route path={CAPTAIN_HASH_URLS.SELECT}>
          <CaptainSelectorForm
            closeModal={closeModal}
            getCaptains={getCaptains}
            selectedCaptainId={selectedCaptainId}
            tripId={tripId}
          />
        </Route>
        <Route path={CAPTAIN_HASH_URLS.INVITE}>
          <CaptainInviteForm
            getCaptains={getCaptains}
            tripId={tripId}
          />
        </Route>
      </HashRouter>
    </Modal>
  );
};

export default CaptainSelector;
