import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { TRIP_STATES } from 'src/inbox/constants';
import { CalendarAction } from '../../type/CalendarAction';
import { ImmutableTrip } from '../../../types/trips/Trips';
import NotificationMessage from '../../../common/components/NotificationMessage';
import { tripTimeSelector } from '../cards/Time/selectors';
import { getActions } from '../TripMenu/actions';

import s from './DateUnavailable.module.scss';

const OPEN_IN_CALENDAR_ACTION = 'open in calendar';

type Props = {
  trip: ImmutableTrip;
  classNameModifier?: string;
};

const DateUnavailable: FC<Props> = ({ trip, classNameModifier = '' }) => {
  const dispatch = useDispatch();

  const tripState = trip.getIn(['state', 'state']);
  const isOfferCancelled = (
    tripState === TRIP_STATES.OWNER_OFFER_CANCELLED
    || tripState === TRIP_STATES.OWNER_OFFER_AUTO_CANCELLED
  );
  const isOfferExpired = (
    tripState === TRIP_STATES.OWNER_OFFER_EXPIRED
    || tripState === TRIP_STATES.OWNER_CUSTOM_OFFER_EXPIRED
  );
  const hasCalendarConflicts = trip.get('has_calendar_conflicts');
  const { isMultiDay } = tripTimeSelector(trip);
  const calendarAction = getActions(tripState).find(
    (action) => action.name === OPEN_IN_CALENDAR_ACTION,
  ) as CalendarAction;

  const modifiers = [
    classNameModifier,
    'alignTop',
    'largerFontSize',
    'largerBottomMargin',
    'indent',
    'inbox',
  ].join(' ');

  const getWarningText = () => {
    if (isMultiDay) {
      return 'Some periods are marked as unavailable by other events in your calendar.';
    }
    return 'This period is marked as unavailable by another event in your calendar.';
  };

  if (hasCalendarConflicts) {
    return (
      <NotificationMessage
        outdent={false}
        classNameModifier={modifiers}
        variant={isOfferCancelled || isOfferExpired ? 'info' : 'warning'}
      >
        {getWarningText()}{' '}
        <Link
          to={calendarAction.url}
          onClick={() => calendarAction.onClick(dispatch, trip)}
          className={s.inlineLink}
        >
          {calendarAction.title}
        </Link>
      </NotificationMessage>
    );
  }

  return null;
};

export default DateUnavailable;
