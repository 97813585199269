import React, { FC, MutableRefObject } from 'react';

import { getClassNameFor } from '../../helpers';
import ToggleButtonIcon from './Icon';

import s from './ToggleButton.module.scss';

export const modifiers = [
  'bookingConflicts',
  'calendarDropdown',
  'centered',
  'instabook',
  'haToggleButton',
] as const;
type ClassNameModifiers = typeof modifiers[number];

const ToggleButton: FC<ToggleButtonProps> = ({
  children,
  isOpen,
  onClick,
  classNameModifier,
  disabled,
  id,
  buttonRef,
}) => (
  <button
    id={id}
    type="button"
    onClick={onClick}
    className={getClassNameFor(s, 'root', classNameModifier)}
    disabled={disabled}
    data-test="ToggleButton"
    ref={buttonRef}
  >
    {children}
    <ToggleButtonIcon
      isOpen={isOpen}
    />
  </button>
);

export type ToggleButtonProps = {
  isOpen: boolean;
  onClick: () => void;
  classNameModifier?: ClassNameModifiers;
  disabled?: boolean;
  id?: string;
  buttonRef?: MutableRefObject<HTMLButtonElement | null>;
};

export default ToggleButton;
