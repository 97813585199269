import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';

import Quote from '../../../common/components/Quote';
import { showContactInfoWarning } from '../../helpers';
import { EXPERIENCE_MAP } from '../../../common/constants';
import ContactInfoWarning from '../ContactInfoWarning';
import s from './Experience.module.scss';

const Experience = ({ trip }) => {
  const renterCommentField = 'watercraft_experience_description';
  const experience = trip.getIn(['renter', 'boating_experience']) || Map();
  const description = experience.get(renterCommentField);
  const timeSpentOnWater = experience.get('days_logged_on_water');
  const qualifications = EXPERIENCE_MAP
    // Make sure that we only grab the certifications that the renter has
    .filter((val, key) => experience.get(key));
  const qualificationsString = experience.get('recognized_qualifications_description')
    || (
      qualifications.size > 0
        ? qualifications.join(', ')
        : 'None supplied'
    );

  return (
    <>
      {description && (
        <>
          <h4 className={s.subtitle}>
            RELEVANT EXPERIENCE
          </h4>
          <Quote classNameModifier="qualifications">
            {description}
          </Quote>
        </>
      )}
      {!!timeSpentOnWater && (
        <p className={s.snippet}>
          Time spent on water: {timeSpentOnWater} days
        </p>
      )}
      {qualificationsString && (
        <>
          <h4 className={s.subtitle}>
            RECOGNIZED QUALIFICATIONS
          </h4>
          <Quote classNameModifier="qualifications">
            {qualificationsString}
          </Quote>
        </>
      )}
      {description && showContactInfoWarning(trip, renterCommentField)
        && <ContactInfoWarning />}
    </>
  );
};

Experience.propTypes = {
  trip: PropTypes.object.isRequired,
};

export default Experience;
