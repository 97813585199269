import React, { FC } from 'react';

import {
  NEW_TAB_ATTRIBUTES,
  SUPPORT_EMAIL,
} from '../../constants';

type ContactSupportProps = {
  className?: string;
  subject?: string;
};
const ContactSupport: FC<ContactSupportProps> = ({
  className = '',
  subject = '',
}) => (
  <a
    href={`mailto:${SUPPORT_EMAIL}${subject ? `?subject=${subject}` : ''}`}
    className={className}
    {...NEW_TAB_ATTRIBUTES}
  >
    {SUPPORT_EMAIL}
  </a>
);

export default ContactSupport;
