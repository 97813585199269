import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { parse, stringify } from 'query-string';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';

import { trackEvent } from 'src/common/tracking';
import type { ReduxState } from '../../../../types/reduxState';
import { PAGES } from '../../../constants';
import { getClassNameFor } from '../../../../common/helpers';
import { siblingRoute } from '../../../../common/utils/routing';
import { getBoat } from '../../../../common/utils/reduxStoreSelectors';
import apiFetch from '../../../../core/fetch';
import { updateInquiry, setInquiryMismatch, deleteInquiryMismatch } from '../../../ducks/inquiry';
import Button from '../../../../common/components/Button';
import { BackButton } from '../../../../common/components/BackButton';
import { parseTrueFalseValue as formParse } from '../../../../common/components/fields/RadioButton';
import InPageWarning from '../../InPageWarning';
import CloseButton from '../../../../common/components/CloseButton';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import Svg from '../../../../common/components/Svg';
import { PAGE_TEXT, captainFields } from './content';

import s from './Captain.module.scss';

type CaptainedValues = {
  captain_required: boolean;
};

const getCaptainedInitialValues = ({ inquiry }: ReduxState): CaptainedValues => {
  const captainRequired: boolean = inquiry.getIn(['details', 'captain_required'], null);
  return {
    captain_required: captainRequired,
  };
};
const getCaptainedMismatch = ({ inquiry }: ReduxState) => inquiry.getIn(['mismatches', 'captain']);

const checkCaptained = (boatId: string, captained: boolean) => (
  apiFetch(`/boats/${boatId}/captained/?captained=${captained}`)
);

const Captain: FC = () => {
  const location = useLocation();
  const { goBack, replace, push } = useHistory();
  const dispatch = useDispatch();
  const { search, pathname } = location;
  const returnUrl = getReturnUrl(location);
  const boat = useSelector(getBoat);
  const initialValues = useSelector(getCaptainedInitialValues);
  const mismatch = useSelector(getCaptainedMismatch);
  const [activeSelection, setActiveSelection] = useState(initialValues);

  const onSubmit = useCallback(
    (values: CaptainedValues, { getState, initialize }) => {
      const { captain_required: captainRequired } = values;
      // Sort out where we're going next
      const { nextPage, ...restQuery } = parse(search);
      // Send the user to experience if they said they don't need a captain
      // otherwise send them to the page from nextPage query param if it is set
      // or to the extras page
      const nextPageTarget = captainRequired ? (nextPage || PAGES.EXTRAS) : PAGES.EXPERIENCE;
      const action = nextPage ? replace : push;
      // TODO: abstract this?
      // TODO: Make this use an object
      const nextPath = `${siblingRoute(pathname, nextPageTarget?.toString())}?${stringify(restQuery)}`;
      trackEvent('send_inquiry_s6_captain', { p1: captainRequired ? 'With Captain' : 'No Captain' });
      dispatch(updateInquiry(values));
      if (getState().pristine
        // If there's a mismatch, this means the user has decided to click
        // "continue anyway" and we want to short-circuit.
        && mismatch) {
        trackEvent('Continue Past SBI Warning', {
          event_category: 'Booking Inquiry',
          event_label: 'for Captained',
        });
        return action(nextPath);
      }
      type CaptainedError = {
        detail: string;
      };
      return checkCaptained(boat.get('id'), captainRequired)
        .then((response) => {
          if (!response.ok) {
            // This resets the form to "pristine"
            initialize(values);
            response.json().then(({ detail }:CaptainedError) => {
              dispatch(setInquiryMismatch({ captain: detail }));
            });
          }
          // I don't really like this. It's unlikely to
          // fail, but it's quite procedural...
          dispatch(deleteInquiryMismatch('captain'));
          action(nextPath);
        });
    },
    [
      boat,
      dispatch,
      mismatch,
      pathname,
      push,
      replace,
      search,
    ],
  );

  const handleFieldSelect = (value: boolean) => setActiveSelection({ captain_required: value });

  return (
    <div className={s.container}>
      <Helmet>
        <title>
          Captain
        </title>
      </Helmet>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          submitting,
          pristine,
          values: { captain_required: captainRequired },
        }) => {
          const showWarning = mismatch && pristine && !submitting;
          return (
            <form onSubmit={handleSubmit} className={s.root}>
              <h1 className={s.title}>
                {PAGE_TEXT.title}
              </h1>
              <p className={s.description}>{PAGE_TEXT.description}</p>
              <fieldset className={s.fieldset}>
                {captainFields.map((option) => (
                  <label
                    data-test="fieldLabel"
                    className={getClassNameFor(s, 'label', classNames({
                      isChecked: option.value === activeSelection.captain_required,
                    }))}
                    key={option.key}
                    onClick={() => handleFieldSelect(option.value)}
                    onKeyDown={() => handleFieldSelect(option.value)}
                    role="presentation"
                  >
                    <div className={s.iconWrapper}>
                      <Svg href="#captain-icon" className={s.captainIcon} />
                      <Svg href={option.icon} className={s.statusIcon} />
                    </div>

                    <Field
                      name={option.name}
                      value={option.value}
                      className={s.input}
                      component="input"
                      type="radio"
                      parse={formParse}
                    />
                    <strong className={s.labelTitle}>
                      {option.title}
                    </strong>
                    <span className={s.labelText}>
                      {option.text}
                    </span>
                  </label>
                ))}
              </fieldset>
              {showWarning && <InPageWarning message={mismatch} />}
              <div className={s.actionGroup}>
                <Button
                  type="submit"
                  submitting={submitting}
                  disabled={captainRequired === null}
                  data-test="next"
                  fullWidth
                >
                  {showWarning ? 'Continue Anyway' : 'Next'}
                </Button>
              </div>
            </form>
          );
        }}
      </Form>
      <BackButton onClick={goBack} />
      <CloseButton backUrl={returnUrl} />
    </div>
  );
};

export default Captain;
