import { PaginatedResults } from '../../types/utils';

type PageLoader<T extends PaginatedResults = PaginatedResults> = (page: number) => Promise<T>;
type ExtractEntity<T extends PaginatedResults> = T['results'][number];

const loadAllPages = async <T extends PaginatedResults = PaginatedResults, E = ExtractEntity<T>>(
  loader: PageLoader<T>,
): Promise<E[]> => {
  const page1: PaginatedResults<E> = await loader(1);
  const { results } = page1;

  const fetches: Promise<T>[] = [];

  if (page1.count > page1.results.length) {
    const totalPages = Math.ceil(page1.count / page1.results.length);

    for (let i = 2; i <= totalPages; i += 1) {
      fetches.push(loader(i));
    }
  }

  const responses = await Promise.all<PaginatedResults<E>>(fetches);

  responses.forEach(response => {
    results.push(...response.results);
  });

  return results;
};

export default loadAllPages;
