import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import IHeartMediaTag from 'src/common/3rdParty/iheartmedia';
import Button from 'src/common/components/Button';
import { PATHS } from '../../../../common/constants';
import CloseButton from '../../../../common/components/CloseButton';
import Svg from '../../../../common/components/Svg';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import { removeFlaggedFields } from '../../../ducks/inquiry';
import { trackEventFacebook } from '../../../../common/tracking/facebook';
import s from './InquirySent.module.scss';

const InquirySent: FC = () => {
  const location = useLocation();
  const returnUrl = getReturnUrl(location);
  const dispatch = useDispatch();

  useEffect(
    () => {
      trackEventFacebook('SubmitApplication');
      dispatch(removeFlaggedFields());
    },
    [dispatch],
  );

  return (
    <div className={s.container}>
      <Helmet>
        <title>
          Done
        </title>
      </Helmet>
      <h1 className={s.title}>
        Inquiry Sent!
      </h1>
      <Svg
        href="#dolphin1"
        className={s.icon}
      />
      <div className={s.contactText}>
        <p>
          {'We’ll contact you with options for your \n trip soon.'}
        </p>
      </div>

      <CloseButton backUrl={returnUrl} />
      <div className={s.actionButtons}>
        <Button
          href={PATHS.INBOX}
          classNameModifier="primary"
          fullWidth
        >
          { 'Done' }
        </Button>
      </div>
      {/* Trigger the signup event for iHeartMedia */}
      <IHeartMediaTag event="signup" />
    </div>
  );
};

export default InquirySent;
