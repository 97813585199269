import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { getClassNameFor } from '../../helpers';

import s from './LoadingSpinner.module.scss';

const DefaultContent = (<>Just a sec...</>);

export type Props = {
  children?: ReactNode;
  classNameModifier?: string;
  variant?: 'xl' | 'l' | 'm' | 's' | 'xs';
  spinnerOnly?: boolean;
  background?: boolean;
};

const LoadingSpinner: FC<Props> = ({
  children = DefaultContent,
  classNameModifier = '',
  variant = 'xl',
  spinnerOnly = false,
  background = false,
}) => (
  <div className={getClassNameFor(s, 'root', classNames(classNameModifier, variant))}>
    <div className={getClassNameFor(s, 'spinnerWrapper', classNames({ background }))}>
      <div className={getClassNameFor(s, 'spinner', variant)} />
    </div>
    {!spinnerOnly && <span className={getClassNameFor(s, 'title', variant)}>{children}</span>}
  </div>
);

export default LoadingSpinner;
