import { close } from '../common/ducks/zippy';

// eslint-disable-next-line import/prefer-default-export
export const modalMapDispatchToProps = modalName => dispatch => {
  const closeModal = () => dispatch(close(modalName));
  return {
    closeModal,
    onSubmitSuccess: closeModal,
  };
};
