import React, { FC, RefObject, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { patchOffer } from 'src/inbox/ducks/offer';
import serializeCaptainOptions from 'src/inbox/utils/serializeCaptainOptions';
import { ImmutableTrip } from '../../../../types/trips/Trips';
import { getClassNameFor } from '../../../../common/helpers';
import { getOffer } from '../../../../common/utils/reduxStoreSelectors';
import getCaptainedCopy from '../../../../common/utils/getCaptainedCopy';
import Card from '../../presentation/Card';
import { modified as tripModified, offerModified, offerIsEmpty } from '../../../helpers';
import { open } from '../../../../common/ducks/zippy';
import { CAPTAIN_OPTION_IS_AUTO_PATCHED_FIELD, CAPTAINED_FIELD, UNSENT_CHANGES_WARNING } from '../../../constants';
import { INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD } from '../../../../calendar/constants';
import EditButton from '../../EditButton';
import NotificationMessage from '../../../../common/components/NotificationMessage';
import Experience from '../../Experience';
import cardStyles from '../../presentation/Card/Card.module.scss';
import styles from './CaptainCard.module.scss';
import { CAPTAIN_OPTION_KEYS } from '../../../../common/constants';
import CaptainTemplate from '../../../../common/components/CaptainTemplate';
import useCaptainData from '../../../../common/hooks/useCaptainData';
import CaptainModal from './modal';

// Import multiple css modules into a component
const s = {
  ...cardStyles,
  ...styles,
};

const ERRORS = {
  CONFLICT: 'The Captain charter type conflicts with this Listing. Please select a valid charter type.',
  UNSET: 'Please select a captain charter type',
};

const captainedFields = [CAPTAINED_FIELD, 'captain_cost_included'];
const modalName = 'captain-modify';

type Props = {
  trip: ImmutableTrip;
  readOnly?: boolean;
  checkTripModification?: boolean;
  isEditOffer?: boolean;
  errorRef?: RefObject<HTMLDivElement>;
};

const CaptainCard: FC<Props> = ({
  trip, readOnly, checkTripModification, isEditOffer = false, errorRef,
}) => {
  const dispatch = useDispatch();
  const patchTheOffer = compose(dispatch, patchOffer);
  const offer = useSelector(getOffer);
  const captained = offer.get(CAPTAINED_FIELD) ?? trip.get(CAPTAINED_FIELD, true);
  const isInstabook = trip.get('is_instabook');

  const {
    captainOptionHeading,
    showCaptainConflict,
    listingCaptainOptions,
    selectedCaptainOption,
  } = useCaptainData(trip, offer, isEditOffer);

  const showCaptainChoice = !captainOptionHeading.key;
  let showExperience = false;
  if (captainOptionHeading.key === CAPTAIN_OPTION_KEYS.NOT_PROVIDED) {
    showExperience = true;
  }

  const openModal = () => dispatch(open(modalName));
  const offerIsModified = (
    !offerIsEmpty(offer)
    && !offer.get(CAPTAIN_OPTION_IS_AUTO_PATCHED_FIELD)
    && offerModified(offer, trip, captainedFields));
  const tripIsModified = checkTripModification && tripModified(trip, captainedFields);
  const modified = offerIsModified || tripIsModified;
  const captainCostIncluded = trip.get(INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD);
  const isCaptainOptionAutoPatched = listingCaptainOptions?.length === 1
    && listingCaptainOptions[0]?.key === selectedCaptainOption;
  const disabled = readOnly || isCaptainOptionAutoPatched;

  const { heading: instabookCaptainedHeading, body: instabookCaptainedBody } = getCaptainedCopy(
    captained,
    captainCostIncluded,
  );

  useEffect(() => {
    if (listingCaptainOptions?.length === 1) {
      patchTheOffer({
        [CAPTAIN_OPTION_IS_AUTO_PATCHED_FIELD]: true,
        ...serializeCaptainOptions(listingCaptainOptions[0].key),
      });
    }
  }, [listingCaptainOptions, patchTheOffer]);

  return trip.get(CAPTAINED_FIELD) !== null ? (
    <Card
      highlighted={modified}
      classNameModifier={!disabled ? 'hoverEffect' : ''}
    >
      {/* Render notifications only when trip is editable */}
      {!disabled && (
        showCaptainChoice
        || (!showCaptainChoice && showCaptainConflict)
        || (offerIsModified && !showCaptainConflict))
      && (
        <NotificationMessage
          outdent={false}
          rootRef={errorRef}
        >
          {showCaptainChoice
            ? ERRORS.UNSET
            : showCaptainConflict && ERRORS.CONFLICT}
          {offerIsModified && !showCaptainConflict && UNSENT_CHANGES_WARNING}
        </NotificationMessage>
      )}

      {offerModified(offer, trip, captainedFields) && isCaptainOptionAutoPatched && !readOnly && (
        <NotificationMessage
          outdent={false}
          rootRef={errorRef}
        >
          {UNSENT_CHANGES_WARNING}
        </NotificationMessage>
      )}

      <CaptainTemplate selectedOption={captainOptionHeading}>
        <p className={getClassNameFor(s, 'details', modified ? 'modified' : '')}>
          {isInstabook ? instabookCaptainedHeading : captainOptionHeading.heading}
        </p>
        <p className={s.snippet}>
          {isInstabook ? instabookCaptainedBody : captainOptionHeading.subHeading}
        </p>
        {showExperience && <Experience trip={trip} />}
      </CaptainTemplate>

      {!disabled && (
        <>
          <EditButton openModal={openModal} />
          <CaptainModal
            isEditOffer={isEditOffer}
            modalName={modalName}
            trip={trip}
          />
        </>
      )}
    </Card>
  ) : null;
};

export default CaptainCard;
