import PropTypes from 'prop-types';
import React from 'react';

import { PATHS, SUPPORT_URLS } from '../../../../common/constants';
import MessagePanel from '../../presentation/MessagePanel';
import { preserveSearch } from '../../../../common/utils/routing';
import CTA from '../../CTA';
import ArchiveButton from '../../ArchiveButton';

const AccountSuspended = ({
  trip,
  location,
}) => {
  const archived = trip.get('archived');
  const cta = (
    <CTA classNameModifier="withSidebar">
      <ArchiveButton
        archived={archived}
        trip={trip}
      >
        {`Move ${archived ? 'from' : 'to'} Archive`}
      </ArchiveButton>
    </CTA>
  );
  return (
    <MessagePanel
      backLocation={preserveSearch(PATHS.INBOX, location)}
      cta={cta}
    >
      <p>
        We’ve temporarily put this conversation on hold while we gather more information.
      </p>
      <p>
        {'Getmyboat occasionally does this to ensure quality of service and adherence to our '}
        <a
          href={SUPPORT_URLS.COMMUNITY_GUIDELINES}
          target="_blank"
          rel="noopener noreferrer"
        >
          community guidelines
        </a>.
        {' We’ll be in touch soon with an update. If you have any questions please '}
        <a
          href={SUPPORT_URLS.REQUEST}
          target="_blank"
          rel="noopener noreferrer"
        >
          contact support
        </a>.
      </p>
    </MessagePanel>
  );
};

AccountSuspended.propTypes = {
  trip: PropTypes.object,
  location: PropTypes.object,
};

export default AccountSuspended;
