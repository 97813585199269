import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { groupBy } from 'lodash';

import { formatDate } from 'src/common/utils/dateHelpers';
import { trackEvent } from 'src/common/tracking';
import { open } from '../../../common/ducks/zippy';
import { useBoats } from '../../../common/hooks';
import { getBoatHeadline } from '../../../common/helpers';

import {
  CALENDAR_DATE_FORMAT_DATE_FNS,
  GA_EVENT_ACTION_PREFIX,
  GA_EVENT_OPTIONS,
} from '../../constants';
import { getStartDate, isSameDay, orderCalenderEvents, todayDate } from '../../helpers';
import { useUnavailableEditorContext } from '../../hooks';
import useCalendarFocus from '../../hooks/useCalendarFocus';
import { isInstabookEvent, isUnavailableEvent, groupByBoat } from '../../utils/calendar';
import { CalendarEvent, GenericEvent, UnavailableEvent } from '../../types';
import AdHocEvents from '../AdHocEvents';
import EventWrapper from '../EventWrapper';
import DayCard from '../DayCard';

import s from './Day.module.scss';

type BoatIdentifier = string | number;

export type DayProps = {
  events: GenericEvent[];
  day: Date;
};

const Day: FC<DayProps> = ({ day, events }) => {
  const thisDayEvents = useMemo(() => events.filter(
    event => isSameDay(getStartDate(event), day) && !isInstabookEvent(event),
  ) as (CalendarEvent | UnavailableEvent)[], [day, events]);

  const eventsGroupedByBoat = useMemo(
    () => groupByBoat(
      orderCalenderEvents(thisDayEvents),
    ) as Record<string, (CalendarEvent | UnavailableEvent)[]>,
    [thisDayEvents],
  );

  const boatIds = useMemo(() => Object.keys(eventsGroupedByBoat), [eventsGroupedByBoat]);

  const adHocEvents = useMemo(() => thisDayEvents.filter(
    event => isUnavailableEvent(event),
  ) as UnavailableEvent[], [thisDayEvents]);

  const groupedAdHocEvents = useMemo(() => Object.values(groupBy(
    adHocEvents,
    event => event.adhoc_calendar_event_id,
  )), [adHocEvents]);

  const dateTimeAttr = formatDate(day, CALENDAR_DATE_FORMAT_DATE_FNS);
  const dispatch = useDispatch();
  const {
    selectedDate,
    focusedThreadId,
    scrollToEvent,
  } = useCalendarFocus();
  const isSelectedDate = selectedDate === dateTimeAttr;
  const isPast = day < todayDate();

  const { loadAdHocEvent } = useUnavailableEditorContext();
  const openUnavailableEditor = useCallback((adHocEventId: number) => {
    loadAdHocEvent(adHocEventId);
    dispatch(open('unavailableEditor'));
    trackEvent(`${GA_EVENT_ACTION_PREFIX.UNAVAILABLE} Open`, {
      ...GA_EVENT_OPTIONS,
      event_label: 'Existing',
    });
  }, [dispatch, loadAdHocEvent]);

  const { boats, boatsIndexedById } = useBoats();

  const hasEvents = thisDayEvents.length > 0;

  return (
    <DayCard
      day={day}
      hasEvents={hasEvents}
    >
      {boatIds.map((boatId: BoatIdentifier) => {
        const boat = boatsIndexedById[boatId];
        const headline = getBoatHeadline(boat);
        return (
          <div key={boatId} className={s.root}>
            <h3 className={s.boatHeadline}>{headline}</h3>
            {eventsGroupedByBoat[boatId].map((event) => {
              if (event.adhoc_calendar_event_id) {
                const groupedAdHocEvent = groupedAdHocEvents.find(
                  group => group[0].adhoc_calendar_event_id === event.adhoc_calendar_event_id,
                );
                if (groupedAdHocEvent) {
                  return (
                    <AdHocEvents
                      key={`${groupedAdHocEvent[0].event_date}-${groupedAdHocEvent[0].id}`}
                      disabled={boats.length === 0}
                      events={groupedAdHocEvent}
                      openUnavailableEditor={openUnavailableEditor}
                      isSelectedDate={isSelectedDate}
                    />
                  );
                }
              }
              return (
                <EventWrapper
                  key={`${event.event_date}_${event.thread_id}`}
                  event={event}
                  dateTimeAttr={dateTimeAttr}
                  focusedThreadId={focusedThreadId}
                  isPast={isPast}
                  isSelectedDate={isSelectedDate}
                  scrollToEvent={scrollToEvent}
                />
              );
            })}
          </div>
        );
      })}
    </DayCard>
  );
};

export default Day;
