import React, { ComponentType, FC } from 'react';
import { Field, useField } from 'react-final-form';

import { getClassNameFor } from '../../../helpers';
import { parseTrueFalseValue } from '../../fields/RadioButton';
import s from './YesNoFields.module.scss';

type ChoiceComponentProps = {
  htmlFor: string;
};

const DefaultChoiceComponent: FC<ChoiceComponentProps> = ({ htmlFor, children }) => (
  <label
    className={s.label}
    htmlFor={htmlFor}
  >
    {children}
  </label>
);

const DefaultYes: FC<ChoiceComponentProps> = ({ htmlFor }) => (
  <DefaultChoiceComponent htmlFor={htmlFor}>Yes</DefaultChoiceComponent>
);

const DefaultNo: FC<ChoiceComponentProps> = ({ htmlFor }) => (
  <DefaultChoiceComponent htmlFor={htmlFor}>No</DefaultChoiceComponent>
);

type YesNoFieldProps = {
  rootClassName?: string;
  classNameModifier?: string;
  name: string;
  validate?: (value: boolean) => string | undefined;
  YesComponent?: ComponentType<ChoiceComponentProps>;
  NoComponent?: ComponentType<ChoiceComponentProps>;
  isYesTrueOnDefault?: boolean;
};

const YesNoField: FC<YesNoFieldProps> = ({
  rootClassName = 'root',
  classNameModifier = '',
  YesComponent = DefaultYes,
  NoComponent = DefaultNo,
  isYesTrueOnDefault = false,
  ...fieldProps
}) => {
  const { name } = fieldProps;
  const yesId = `${name}-yes`;
  const noId = `${name}-no`;
  const { meta: { error, touched } } = useField(name);
  const className = classNameModifier
    ? getClassNameFor(s, rootClassName, classNameModifier)
    : rootClassName;

  const conditionalProps = () => isYesTrueOnDefault && { initialValue: true };
  return (
    <div className={className}>
      <Field
        component="input"
        type="radio"
        value // true
        parse={parseTrueFalseValue}
        className={s.input}
        id={yesId}
        {...conditionalProps()}
        {...fieldProps}
      />
      <YesComponent htmlFor={yesId} />
      <Field
        component="input"
        type="radio"
        value={false}
        parse={parseTrueFalseValue}
        className={s.input}
        id={noId}
        {...fieldProps}
      />
      <NoComponent htmlFor={noId} />
      {error && touched && (
        <div className={s.error}>
          {error}
        </div>
      )}
    </div>
  );
};

export default YesNoField;
