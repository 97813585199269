import React, { FC, ReactNode } from 'react';

import { getClassNameFor } from '../../helpers';
import s from './Header.module.scss';

type BareHeaderProps = {
  children?: ReactNode;
  extraChildren?: ReactNode;
  classNameModifier?: string;
};

const BareHeader: FC<BareHeaderProps> = ({
  children,
  extraChildren,
  classNameModifier,
}) => (
  <div className={getClassNameFor(s, 'root', classNameModifier)}>
    {extraChildren}
    <div className={getClassNameFor(s, 'innerWrapper', `${classNameModifier}`)}>
      {children}
    </div>
  </div>
);

export default BareHeader;
