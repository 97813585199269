import React, { Component, ComponentType, ErrorInfo, ReactNode } from 'react';
import captureException from 'src/common/utils/captureException';

export type ErrorBoundaryProps = {
  children: React.ReactNode;
  fallbackUI: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { body } = document;
    captureException(error, {
      extra: {
        errorInfo,
        body: {
          height: body.clientHeight,
          width: body.clientWidth,
        },
        window: {
          height: window.innerHeight,
          width: window.innerWidth,
        },
      },
    });
  }

  render() {
    const { hasError } = this.state;
    const { children, fallbackUI } = this.props;
    return hasError
      ? fallbackUI
      : children;
  }
}

type WithErrorBoundary<Props extends {} = {}> = (component: ComponentType<Props>) => (
  (props: Props) => JSX.Element
);

export const withErrorBoundary = (fallbackUI: ReactNode = null): WithErrorBoundary => (
  (WrappedComponent) => (props) => (
    <ErrorBoundary
      fallbackUI={fallbackUI}
    >
      <WrappedComponent {...props} />
    </ErrorBoundary>
  ));

export default ErrorBoundary;
