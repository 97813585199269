import React from 'react';
import { fromJS } from 'immutable';
import classNames from 'classnames';

import { PATHS, TITLE } from '../../../common/constants';
import { formatDate, newTZDate } from '../../../common/utils/dateHelpers';
import { CONFIRMED_STATES, TRIP_STATES } from '../../../inbox/constants';
import { tripTimeSelector } from '../../../inbox/components/cards/Time/selectors';
import { CTALink } from '../../../inbox/components/CTA';
import ContactSupport from '../../../common/components/ContactSupport';
import ErrorMessage from '../../../common/components/FormError';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import Modal from '../../../common/components/Modal';
import NotificationMessage from '../../../common/components/NotificationMessage';
import TripDetails from '../../../inbox/components/TripDetails';
import UserInfo from '../../../inbox/components/UserInfo';
import { CALENDAR_EVENT_DETAILS_DATE, EVENT_DETAILS_MODAL_NAME, INQUIRY, OFFER, RESERVATION } from '../../constants';
import useBoatAvailabilityDropdown from '../../hooks/useBoatAvailabilityDropdown';
import { useEventDetailsContext } from '../../hooks/useEventDetails';
import EventDetailsContent from '../EventDetailsContent';
import EventDetailsHeader from '../EventDetailsHeader';
import EventDetailsFooter from '../EventDetailsFooter';
import Button from '../../../common/components/Button';
import AvailabilityDropdown from '../AvailabilityDropdown';
import EventDetailsHeaderConfetti from '../EventDetailsHeaderConfetti';

import s from './EventDetails.module.scss';
import { AvailabilityBookingSetting } from '../../types';

const {
  OWNER_OFFER_CANCELLED,
  OWNER_OFFER_AUTO_CANCELLED,
  OWNER_CUSTOM_OFFER_EXPIRED,
  OWNER_OFFER_EXPIRED,
  OWNER_RESERVATION_CONFIRMED,
} = TRIP_STATES;

const eventTypeToDateFieldMap = {
  [INQUIRY]: ['booking_inquiry_date'],
  [OFFER]: ['offer_sent_date'],
  [OWNER_OFFER_CANCELLED]: ['cancel_message', 'date_created'],
  [RESERVATION]: ['reservation_confirmation_date'],
};

// The copy for confirmed bookings has been updated to "Booking Confirmed" in the UI
const eventStateToTitleMap = {
  [OWNER_RESERVATION_CONFIRMED]: 'Booking Confirmed',
};

const EventDetails = () => {
  const {
    closeModal,
    trip,
    event,
    isLoading: tripLoading,
    loadingError: tripLoadingError,
    isPast,
  } = useEventDetailsContext();

  const { selectAvailability, isSelecting, selectedOption } = useBoatAvailabilityDropdown(
    `${trip.get('pk')}`,
    event?.block_type as AvailabilityBookingSetting,
  );
  const eventState = event?.state?.state || '';
  const isCancelled = eventState === OWNER_OFFER_CANCELLED;
  const isAutoCancelled = eventState === OWNER_OFFER_AUTO_CANCELLED;
  const isExpired = eventState === OWNER_OFFER_EXPIRED || eventState === OWNER_CUSTOM_OFFER_EXPIRED;
  const eventType = event?.event_type;
  const eventTypeLookup = isCancelled ? OWNER_OFFER_CANCELLED : eventType;

  const dateField = eventTypeLookup ? eventTypeToDateFieldMap[eventTypeLookup] : [''];
  /** @type {string} */
  const dateString = dateField && dateField.length ? trip.getIn(dateField) : '';
  const { isMultiDay } = tripTimeSelector(trip);
  const isInquiry = eventType === INQUIRY;
  const isOffer = eventType === OFFER;
  const isBooking = eventType === RESERVATION;
  const pastModifier = classNames({ past: isPast });
  const title = (eventStateToTitleMap[eventState]) || event?.state?.badge;

  const getWarningText = () => {
    if (isMultiDay) {
      return 'Some periods are marked as unavailable by other events in your calendar.';
    }
    return 'This period is marked as unavailable by another event in your calendar.';
  };

  return (
    <Modal
      classNameModifier={classNames('calendar', {
        alignVertically: tripLoading || tripLoadingError,
      })}
      label="Event Details"
      name={EVENT_DETAILS_MODAL_NAME}
    >
      {tripLoading ? (
        <LoadingSpinner />
      ) : !tripLoadingError && !trip.isEmpty() && (
        <>
          {eventState === OWNER_RESERVATION_CONFIRMED && (
            <div className={s.confettiWrapper}>
              <EventDetailsHeaderConfetti />
            </div>
          )}

          <EventDetailsHeader
            classNameModifier={classNames(eventType, eventState, pastModifier)}
            title={title}
            description={dateString && (
              <>
                {isInquiry && !isCancelled && 'Received '}
                {isOffer && 'Sent '}
                {isBooking && 'Paid on '}
                <time
                  dateTime={dateString}
                >
                  {formatDate(newTZDate(dateString), CALENDAR_EVENT_DETAILS_DATE, {})}
                </time>
              </>
            )}
          />

          <EventDetailsContent>
            <UserInfo
              closeModal={closeModal}
              classNameModifier="event"
              size="large"
              trip={trip}
              withContactDetails={CONFIRMED_STATES.includes(eventState)}
            />

            {isBooking && (
              <div className={s.unavailableDropdown}>
                <AvailabilityDropdown
                  handleOnSelect={(selectedItem) => selectAvailability(selectedItem)}
                  selectedOption={selectedOption}
                  disabled={isSelecting || isPast}
                  submitting={isSelecting}
                />
              </div>
            )}

            {event?.conflicted && (
              <NotificationMessage
                outdent={false}
                classNameModifier="alignTop largerFontSize largerBottomMargin"
                variant={(isCancelled || isAutoCancelled || isExpired) ? 'info' : 'warning'}
              >
                {getWarningText()}
              </NotificationMessage>
            )}

            <TripDetails
              renderComments={false}
              trip={trip}
              transaction={trip.get('transaction') || fromJS({})}
            />
            {isBooking && (
              <ContactSupport
                classNameModifier="event"
              />
            )}
          </EventDetailsContent>

          <EventDetailsFooter>
            <Button
              type="reset"
              classNameModifier="calendar secondary"
              onClick={closeModal}
              fullWidth
            >
              Close
            </Button>
            <CTALink
              onClick={closeModal}
              to={`${PATHS.INBOX}${trip.get('pk')}/`}
            >
              Open in {TITLE.AI}
            </CTALink>
          </EventDetailsFooter>
        </>
      )}
      {tripLoadingError && (
        <ErrorMessage
          className={s.error}
          error={tripLoadingError}
        />
      )}
    </Modal>
  );
};

export default EventDetails;
