import React, { FC, ReactNode } from 'react';

import s from './Unavailable.module.scss';

export type UnavailableProps = {
  time?: ReactNode;
  tripState?: ReactNode;
};

const Unavailable: FC<UnavailableProps> = ({ time, tripState }) => (
  <div className={s.root}>
    <span className={s.time}>
      {time}
    </span>
    {tripState}
  </div>
);

export default Unavailable;
