import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trip } from 'src/types/trips/Trips';
import { fromJS } from 'immutable';
import apiFetch from 'src/core/fetch';
import captureException from 'src/common/utils/captureException';
import { unpackApiError } from 'src/common/helpers';
import useDirectBookingParam from './useDirectBookingParam';

type CheckoutSessionSuccess = {
  url: string;
  id: string;
};

type UseDirectBookingApi = {
  /** `true` if a direct booking hash id is in the url query string */
  isDirectBooking: boolean;
  /** `true` once the required data had been loaded */
  isLoading: boolean;
  /** the error `message` of either failed /trips/ fetches */
  errorMessage?: string;
  /** Initial values for final-form */
  initialValues?: {
    terms_agreed: boolean;
    first_name: string;
    last_name: string;
    email_obfuscated: string;
    phone: string;
    user_id: number;
  };
  /**
   * Fetches the checkout session url
   * and redirects the user to the checkout without needing to expose implementation details
   * */
  checkoutRedirect: () => void;
};

const useDirectBookingApi = (): UseDirectBookingApi => {
  const { directBookingId, isDirectBooking } = useDirectBookingParam();
  const [isLoading, setIsLoading] = useState<boolean>(isDirectBooking);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [data, setData] = useState<null | Trip>(null);

  useEffect(() => {
    if (!directBookingId) return;

    const directBookingCall = async () => {
      try {
        const directBookingUrl = `trips/guest_booking/${directBookingId}`;
        const res = await apiFetch(directBookingUrl);
        if (res.status === 200) {
          const successData = await res.json();
          setData(successData);
        } else {
          const failData = await unpackApiError(res);
          setErrorMessage(failData.detail);
        }
        setIsLoading(false);
      } catch (e) {
        captureException(new Error('Error getting guest_booking'), {
          extra: { e },
        });
        setIsLoading(false);
      }
    };
    directBookingCall();
  }, [directBookingId]);

  const initialValues = useMemo(
    () => {
      if (isDirectBooking && data) {
        const renter = fromJS(data?.renter);
        return {
          terms_agreed: true,
          first_name: renter.get('first_name'),
          last_name: renter.get('last_name'),
          email_obfuscated: renter.get('email'),
          phone: renter.get('phone'),
          user_id: renter.get('id'),
        };
      }
      return undefined;
    },
    [data, isDirectBooking],
  );

  const checkoutRedirect = useCallback(async () => {
    const checkoutSessionUrl = `trips/guest_booking/${directBookingId}/checkout-session/`;
    const res = await apiFetch(checkoutSessionUrl);
    if (res.status === 200) {
      const checkoutData = await res.json() as CheckoutSessionSuccess;
      if (checkoutData?.url) {
        window.location.assign(checkoutData.url);
      }
      return;
    }
    // res can be ok, but still have failure data in respone
    const failData = await unpackApiError(res);
    throw new Error(JSON.stringify(failData));
  }, [directBookingId]);

  return {
    isDirectBooking,
    isLoading,
    errorMessage,
    initialValues,
    checkoutRedirect,
  };
};

export default useDirectBookingApi;
