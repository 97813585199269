import React, { FC } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../helpers';
import s from './CheckDecorator.module.scss';

const CheckDecorator: FC<Props> = ({
  children,
  disabled,
}) => (
  <span className={s.root}>
    {children}
    <span className={getClassNameFor(s, 'checkMark', classNames({ disabled }))} />
  </span>
);

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
};

export default CheckDecorator;
