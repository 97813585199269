import React, {
  useCallback, useState, useMemo, createContext, useContext, useReducer, FC,
} from 'react';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { parse } from 'tinyduration';
import { useSelector } from 'react-redux';
import add from 'date-fns/add';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isAfter from 'date-fns/isAfter';
import isDate from 'date-fns/isDate';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import format from 'date-fns/format';
import { DATES } from '../../booking-inquiry/constants';
import apiFetch from '../../core/fetch';
import captureException from '../utils/captureException';
import { BOOKING_MOTIVATOR_STATE } from '../constants';
import {
  CALENDAR_DATE_FORMAT,
  CALENDAR_DATE_FORMAT_DATE_FNS as DATE_FORMAT,
  INSTABOOK_EDITOR_DATE_FIELD,
} from '../../calendar/constants';
import { newTZDate, startOfMonth } from '../../calendar/helpers';
import AvailabilityDay from '../components/BookingMotivators/AvailabilityDay';
import { ALTERNATIVE_DATE_FIELD, ALTERNATIVE_RETURN_DATE_FIELD } from '../../inbox/constants';
import { DateState, BookingMotivatorsState, BookingMotivatorsAction, DateString, EditingAltState, UseBookingMotivators, Motivator, BlockedTime } from '../types';
import { ImmutableTrip } from '../../types/trips/Trips';
import { noop } from '../helpers';
import { formatDate } from '../utils/dateHelpers';
import useBookingMotivatorsData from './useBookingMotivatorData';

const {
  UNAVAILABLE,
  LIMITED_AVAILABILITY,
  IN_DEMAND,
  HIGH_DEMAND,
  UNUSUAL_SIGHTING,
} = BOOKING_MOTIVATOR_STATE;

const initialDateState: DateState = {
  date: undefined,
  motivators: [],
  interval: undefined,
  intervalMotivators: [],
};

const initialState: BookingMotivatorsState = {
  [DATES.PRIMARY]: initialDateState,
  [DATES.SECONDARY]: initialDateState,
  [DATES.TERTIARY]: initialDateState,
  [ALTERNATIVE_DATE_FIELD]: initialDateState,
  [ALTERNATIVE_RETURN_DATE_FIELD]: initialDateState,
  [INSTABOOK_EDITOR_DATE_FIELD]: initialDateState,
};

const getBlockedTimes = async (
  boatId: string | undefined,
  date: DateString,
): Promise<BlockedTime[]> => {
  const url = `/boats/${boatId}/blocked-times/?date=${date}`;
  const response = await apiFetch(url);
  if (response.status === 200) {
    return response.json();
  }
  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw response;
};

/**
 * Loads the booking motivators for the specified boatId
 */
const useBookingMotivators = (
  boatId?: string,
  hasNightPricing?: boolean,
): UseBookingMotivators => {
  const initialEditingAltDate = null;
  const [editingAltDate, setEditingAltDate] = useState<EditingAltState>(initialEditingAltDate);
  const [blockedTimes, setBlockedTimes] = useState<BlockedTime[]>([]);
  const [loading, setLoading] = useState(false);

  const {
    bookingMotivators,
    setActiveMonth,
    canGoBack,
  } = useBookingMotivatorsData(boatId);

  const tripLengthString = useSelector(
    ({ inquiry }: { inquiry: ImmutableTrip }) => inquiry.getIn(['details', 'trip_length']),
  );

  const getIntervalModifiers = useCallback(
    (date?: null | DateString): {
      interval?: { start: Date; end: Date };
      intervalMotivators: Motivator[];
    } => {
      if (!date) {
        return { interval: undefined, intervalMotivators: [] };
      }
      const tripLength = tripLengthString && parse(tripLengthString);
      const numberOfDays = tripLength ? Math.floor(tripLength.days) : 0;
      const departDate = newTZDate(date);
      const daysToBeAdded = (hasNightPricing || numberOfDays === 0)
        ? numberOfDays : numberOfDays - 1;
      const returnDate = add(departDate, { days: daysToBeAdded });
      const interval = ((isDate(departDate) && isDate(returnDate)) || undefined)
        && ({ start: departDate, end: returnDate });
      const datesRange = interval
        ? eachDayOfInterval(interval).map(d => formatDate(d))
        : [];
      const intervalMotivators = Object.entries(bookingMotivators)
        .filter(([key]) => datesRange.includes(key))
        .map(([, value]) => value[0]);

      return {
        interval,
        intervalMotivators,
      };
    },
    [bookingMotivators, hasNightPricing, tripLengthString],
  );

  const getMotivators = useCallback(
    (date: DateString): Motivator[] => bookingMotivators[date] ?? [],
    [bookingMotivators],
  );

  /**
   * Gets motivators data for specific date */
  const getDateMotivators = useCallback(
    (date: null | DateString): DateState => ({
      date,
      motivators: date ? getMotivators(date) : [],
      ...getIntervalModifiers(date),
    }),
    [getIntervalModifiers, getMotivators],
  );

  const loadBlockedTimes = useCallback(async (
    date: string,
  ): Promise<void> => {
    const hasBookingMotivator = bookingMotivators[date];
    const hasLimitedAvailability = bookingMotivators[date]?.includes(LIMITED_AVAILABILITY);
    if (!hasBookingMotivator || !hasLimitedAvailability) {
      setBlockedTimes([]);
    }

    setLoading(true);
    try {
      const allBlockedTimesArray = await getBlockedTimes(boatId, date);
      setBlockedTimes(allBlockedTimesArray);
    } catch (error) {
      captureException(error);
      setBlockedTimes([]);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 700); // Delay of 0.7 seconds
    }
  }, [boatId, bookingMotivators]);

  const reducer = (
    state: BookingMotivatorsState,
    action: BookingMotivatorsAction,
  ): BookingMotivatorsState => {
    switch (action.type) {
      case `set${DATES.PRIMARY}`:
        return { ...state, [DATES.PRIMARY]: getDateMotivators(action.date) };
      case `set${DATES.SECONDARY}`:
        return { ...state, [DATES.SECONDARY]: getDateMotivators(action.date) };
      case `set${DATES.TERTIARY}`:
        return { ...state, [DATES.TERTIARY]: getDateMotivators(action.date) };
      case `set${ALTERNATIVE_DATE_FIELD}`:
        return { ...state, [ALTERNATIVE_DATE_FIELD]: getDateMotivators(action.date) };
      case `set${ALTERNATIVE_RETURN_DATE_FIELD}`:
        return { ...state, [ALTERNATIVE_RETURN_DATE_FIELD]: getDateMotivators(action.date) };
      case `set${INSTABOOK_EDITOR_DATE_FIELD}`:
        return { ...state, [INSTABOOK_EDITOR_DATE_FIELD]: getDateMotivators(action.date) };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const today = Date.now();

  const isDayHighlighted = useCallback(
    (dateObject: Date, dateField: keyof typeof state = DATES.PRIMARY): boolean => {
      const dateToCheck = newTZDate(format(dateObject, DATE_FORMAT));
      const { date, interval } = state[dateField];
      const isDayWithinMultidayInterval = !!interval && isWithinInterval(dateToCheck, interval);

      return format(dateToCheck, DATE_FORMAT) === date || isDayWithinMultidayInterval;
    },
    [state],
  );

  // Use of Moment is deprecated, but is still needed in this case to allow 'today' to be selected
  // This function is used in react-dates calendar instance
  const isOutsideRange = useCallback(
    (dateObject: Date): boolean => moment(dateObject).isBefore(today, 'day'),
    [today],
  );

  const renderCalendarDay = useCallback(
    (dateObject: Date) => {
      const motivators = getMotivators(formatDate(dateObject));
      const alternativeDate = state[ALTERNATIVE_DATE_FIELD].date;
      const alternativeReturnDate = state[ALTERNATIVE_RETURN_DATE_FIELD].date;
      const startDate = alternativeDate
        && editingAltDate === ALTERNATIVE_DATE_FIELD
        && isSameDay(dateObject, new Date(alternativeDate));
      const endDate = alternativeReturnDate
        && editingAltDate === ALTERNATIVE_RETURN_DATE_FIELD
        && isSameDay(dateObject, new Date(alternativeReturnDate));

      const modifiers = classNames({
        active: isSameDay(dateObject, today) || isAfter(dateObject, today),
        unavailable: motivators.includes(UNAVAILABLE),
        limitedAvailability: motivators.includes(LIMITED_AVAILABILITY),
        inDemand: motivators.includes(IN_DEMAND),
        highDemand: motivators.includes(HIGH_DEMAND),
        unusualSighting: motivators.includes(UNUSUAL_SIGHTING),
        startDate,
        endDate,
      });

      return (
        <AvailabilityDay classNameModifier={modifiers}>
          {formatDate(dateObject, 'd')}
        </AvailabilityDay>
      );
    },
    [getMotivators, today, editingAltDate, state],
  );

  const handleMonthChange = useCallback(
    (dateObject: Date) => setActiveMonth(
      formatDate(startOfMonth(dateObject)),
    ),
    [setActiveMonth],
  );

  // Use of Moment is deprecated, but is still used by Inquiry form date, until form is replaced
  const handleDateChange = useCallback(
    (date: null | Date | Moment, dateField: string = DATES.PRIMARY) => {
      const formattedDate = date && (moment.isMoment(date)
        ? moment(date)?.format(CALENDAR_DATE_FORMAT)
        : formatDate(date)
      );
      if (formattedDate) {
        loadBlockedTimes(formattedDate);
      }
      dispatch({ type: `set${dateField}`, date: formattedDate });
    },
    [loadBlockedTimes],
  );

  return useMemo(() => ({
    bookingMotivators,
    blockedTimes,
    loading,
    canGoBack,
    getMotivators,
    handleDateChange,
    handleMonthChange,
    isDayHighlighted,
    isOutsideRange,
    renderCalendarDay,
    setEditingAltDate,
    state,
  }), [
    bookingMotivators,
    blockedTimes,
    loading,
    canGoBack,
    getMotivators,
    handleDateChange,
    handleMonthChange,
    isDayHighlighted,
    isOutsideRange,
    renderCalendarDay,
    setEditingAltDate,
    state,
  ]);
};

const initialContext = {
  bookingMotivators: {},
  blockedTimes: [],
  loading: false,
  canGoBack: true,
  getMotivators: () => [],
  handleDateChange: noop,
  handleMonthChange: noop,
  isDayHighlighted: () => false,
  isOutsideRange: () => false,
  renderCalendarDay: () => null,
  setEditingAltDate: noop,
  state: initialState,
};

const BookingMotivatorContext = createContext<UseBookingMotivators>(initialContext);

const useBookingMotivatorsContext = () => useContext(BookingMotivatorContext);

export const BookingMotivatorsProvider: FC<{ boatId?: string; hasNightPricing?: boolean }> = (
  { boatId, children, hasNightPricing },
) => {
  const bookingMotivators = useBookingMotivators(boatId, hasNightPricing);
  return (
    <BookingMotivatorContext.Provider value={bookingMotivators}>
      {children}
    </BookingMotivatorContext.Provider>
  );
};

export default useBookingMotivatorsContext;
