import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import { HotKeys } from 'react-hotkeys';

import { fullName } from 'src/common/utils/user';
import { TITLE } from '../../../../common/constants';
import { getReviewUrl } from '../../../helpers';
import TextArea from '../../../../common/components/inputs/TextArea';
import FormError from '../../../../common/components/FormError';
import TripPanel from '../../presentation/TripPanel';
import CTA, { CTABackLink, CTAButton } from '../../CTA';
import Card from '../../presentation/Card';
import { REVIEW_PAGES, FIELDS } from '../../pages/Complete/reviewsConfig';
import content from './content';
import s from '../ReviewStep.module.scss';

const { PUBLIC_REVIEW } = FIELDS;

const PublicReview = ({
  trip,
  role,
  backLocation,
  submitting,
  handleSubmit,
  submitError,
  progressBar,
}) => {
  const location = useLocation();
  const backUrl = getReviewUrl(REVIEW_PAGES.PUBLIC_REVIEW, role, 'back', location);
  const cta = (
    <CTA classNameModifier="withSidebar">
      <CTABackLink to={backUrl} />
      <CTAButton
        type="button"
        submitting={submitting}
        onClick={handleSubmit}
      >
        Publish
      </CTAButton>
    </CTA>
  );

  const renter = trip.get('renter');
  const renterName = fullName(renter);
  const owner = trip.get('owner');
  const ownerName = fullName(owner);
  const { title, subTitle, placeholder } = content(role, renterName, ownerName);

  return (
    <TripPanel
      trip={trip}
      subheader={TITLE.REVIEW}
      cta={cta}
      backLocation={backLocation}
    >
      <Card classNameModifier="review">
        {progressBar}
        <h3 className={s.title}>
          {title}
        </h3>
        <h4 className={s.subTitle}>
          {subTitle}
        </h4>
        <FormError error={submitError} />
        <HotKeys
          handlers={{ submit: handleSubmit }}
          keyMap={{ submit: ['ctrl+enter', 'meta+enter'] }}
        >
          <Field
            autoFocus
            placeholder={placeholder}
            name={PUBLIC_REVIEW}
            component={TextArea}
          />
        </HotKeys>
      </Card>
    </TripPanel>
  );
};

PublicReview.propTypes = {
  trip: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  backLocation: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitError: PropTypes.string,
  progressBar: PropTypes.node,
};

export default PublicReview;
