import captureException from '../common/utils/captureException';

/**
 * Inspect fetch responses and capture exceptions for non-successful
 * api calls to get improved visibility of what errors user's encounter
 */
const captureUnsuccessfulResponses = (errorMessage: string) => (
  async (response: Response) => {
    if (!response.ok) {
      // Clone the response to ensure handlers downstream can still access the response body
      const body = await response.clone().text();
      captureException(errorMessage, {
        extra: {
          url: response.url,
          status: response.status,
          body,
        },
      });
    }
    return response;
  }
);

export default captureUnsuccessfulResponses;
