import { RefObject, useCallback, useEffect, useRef } from 'react';

// Hundredth increments from 0 to 1
const highResThreshold = Array.from({ length: 101 }, (_, i) => i / 100);

type UseIntersectionObserverOptions<T extends HTMLElement> = {
  ref: RefObject<T>;
  callback: () => void;
  threshold?: IntersectionObserverInit['threshold'];
  root?: IntersectionObserverInit['root'];
  rootMargin?: IntersectionObserverInit['rootMargin'];
};

const useIntersectionObserver = <T extends HTMLElement>({
  ref,
  callback,
  threshold = highResThreshold,
  root = null,
  rootMargin = undefined,
}: UseIntersectionObserverOptions<T>) => {
  const observer = useRef<IntersectionObserver>();

  const updateEntry = useCallback<IntersectionObserverCallback>(entries => {
    entries.forEach(entry => {
      if (entry && entry.rootBounds
        && entry.rootBounds?.top >= entry?.boundingClientRect?.top
        && entry.rootBounds?.top <= entry?.boundingClientRect?.bottom
      ) {
        callback();
      }
    });
  }, [callback]);

  useEffect(() => {
    const node = ref.current;
    if (!node) {
      return () => { };
    }

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(updateEntry, {
      threshold,
      root,
      rootMargin,
    });

    const { current: currentObserver } = observer;
    currentObserver.observe(node);

    return () => {
      currentObserver.disconnect();
    };
  }, [ref, root, rootMargin, threshold, updateEntry]);
};
export default useIntersectionObserver;
