import { stringify } from 'query-string';
import { useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';

import { unpackApiError } from '../../common/helpers';
import useMounted from '../../common/hooks/useMounted';
import captureException from '../../common/utils/captureException';
import apiFetch from '../../core/fetch';
import { BoatDetail } from '../../types/boat/BoatDetail';
import { InstabookCalculate } from '../../types/instabook/Calculate';
import type { InstabookFormState } from '../instabook';

const useInstabookPrice = (boat: BoatDetail | null) => {
  const [priceDetails, setPriceDetails] = useState<InstabookCalculate>();
  const [priceError, setPriceError] = useState<string>();

  const mounted = useMounted();

  const fetchPriceDetails = useCallback(
    async (subtotal: number, captain_subtotal: number) => {
      if (!boat?.id) {
        return;
      }
      try {
        const response = await apiFetch(`/instabook/calculate/?${stringify({
          boat_id: boat?.id,
          subtotal,
          captain_subtotal,
        })}`);

        if (!response.ok) {
          const error = await unpackApiError(response);
          if (mounted.current) {
            setPriceDetails(undefined);
            setPriceError(error.detail || 'There was an error getting updated price details.');
          }
        } else {
          const details = await response.json();
          if (mounted.current) {
            setPriceError(undefined);
            setPriceDetails(details);
          }
        }
      } catch (exception) {
        if (mounted.current) {
          setPriceDetails(undefined);
          setPriceError('An unexpected error occurred');
        }
        captureException(exception);
      }
    },
    [boat?.id, mounted],
  );

  const onInstabookPriceChange = useMemo(() => debounce(
    ({ subtotal, captain_subtotal }: InstabookFormState) => {
      // Reset priceError to allow regular validation error appear
      setPriceError(undefined);
      if (subtotal > 0) {
        fetchPriceDetails(subtotal, captain_subtotal);
      } else {
        // Reset price details if price is set to 0
        setPriceDetails(undefined);
      }
    },
    500,
  ), [fetchPriceDetails, setPriceDetails, setPriceError]);

  return {
    onInstabookPriceChange,
    priceDetails,
    setPriceDetails,
    priceError,
  };
};

export default useInstabookPrice;
