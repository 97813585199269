/* eslint-disable no-underscore-dangle */

/*
 * This file contains various wrappers around the Siftscience javascript
 * snippet, since the [typical usage](https://siftscience.com/developers/docs/javascript/javascript-api)
 * depends on global state and creating a script tag with stuff inside of
 * it, both of which are a pain in Reactland.
 *
 * The library itself works a lot like the old google analytics library.
 * You'll notice that we stick an array on the global scope and then push
 * more arrays onto it. The siftscience library loads and runs separately,
 * and then looks for that array and sends the events to sift.
 */

import { EXTERNAL_PATHS } from '../constants';

/**
 * Keeps track of whether the script has been created
 */
let initialized = false;

/**
 * Loads the Siftscience script.
 *
 * The script loading is deferred if the current route is the search route.
 * This is so that it doesn't negatively impact the load performance of the
 * search pages. Once the user navigates, the script will be included and all
 * previously captured events will be send.
 */
const loadSiftScript = () => {
  if (initialized || window.location.pathname.startsWith(EXTERNAL_PATHS.SEARCH)) {
    return;
  }

  initialized = true;
  const e = document.createElement('script');
  e.src = 'https://cdn.siftscience.com/s.js';
  e.async = 1;
  document.body.appendChild(e);
};

export function push(event) {
  // This should only run on the frontend.
  if (typeof window !== 'undefined') {
    if (!window._sift) {
      window._sift = [];
    }
    window._sift.push(event);

    loadSiftScript();
  }
}

export function setSiftUser(id, sessionId) {
  push(['_setUserId', id]);
  push(['_setSessionId', sessionId]);
}

// As far as sift is concerned, sending empty strings
// constitutes clearing out the user.
export const clearSiftUser = () => setSiftUser('', '');

export function initialize(apiKey) {
  push(['_setAccount', apiKey]);
  push(['_trackPageview']);
}

export const SiftScience = { initialize, push };
