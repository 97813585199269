import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Field } from 'react-final-form';

import { TITLE } from '../../../../common/constants';
import {
  getClassNameFor,
} from '../../../../common/helpers';
import { getReviewUrl } from '../../../helpers';
import RatingInput from '../../../../common/components/inputs/RatingInput';
import FormError from '../../../../common/components/FormError';
import TripPanel from '../../presentation/TripPanel';
import CTA, { CTABackLink, CTAButton, CTALink } from '../../CTA';
import Card from '../../presentation/Card';
import { REVIEW_PAGES, FIELDS } from '../../pages/Complete/reviewsConfig';
import s from '../ReviewStep.module.scss';

const { NET_PROMOTER_SCORE } = FIELDS;

const emptySymbol = [1, 2, 3, 4, 5].map(n => (
  <div className={s.npsItem}>
    {n}
  </div>
));
const fullSymbol = [1, 2, 3, 4, 5].map(n => (
  <div className={getClassNameFor(s, 'npsItem', 'full')}>
    {n}
  </div>
));

const NPS = ({
  trip,
  role,
  backLocation,
  submitting,
  handleSubmit,
  dirty,
  submitError,
  progressBar,
}) => {
  const location = useLocation();
  const backUrl = getReviewUrl(REVIEW_PAGES.NPS, role, 'back', location);
  const nextUrl = getReviewUrl(REVIEW_PAGES.NPS, role, 'next', location);
  const cta = (
    <CTA classNameModifier="withSidebar">
      <CTABackLink to={backUrl} />
      {dirty ? (
        <CTAButton
          type="button"
          submitting={submitting}
          onClick={handleSubmit}
          data-test="next"
        >
          Next
        </CTAButton>
      ) : (
        <CTALink to={nextUrl}>
          Skip
        </CTALink>
      )}
    </CTA>
  );

  return (
    <TripPanel
      trip={trip}
      subheader={TITLE.REVIEW}
      cta={cta}
      backLocation={backLocation}
    >
      <Card classNameModifier="review">
        {progressBar}
        <h3 className={s.title}>
          How likely are you to recommend Getmyboat to others?
        </h3>
        <h4 className={s.subTitle}>
          Please select from 1 to 5.
        </h4>
        <FormError error={submitError} />
        <div className={s.recommend}>
          <Field
            name={NET_PROMOTER_SCORE}
            component={RatingInput}
            withTextRating={false}
            emptySymbol={emptySymbol}
            fullSymbol={fullSymbol}
            classNameModifier="nps"
          />
          <div className={s.recommendLikelihood}>
            Not at all likely
          </div>
          <div className={s.recommendLikelihood}>
            Extremely likely
          </div>
        </div>
      </Card>
    </TripPanel>
  );
};

NPS.propTypes = {
  trip: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  backLocation: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dirty: PropTypes.bool,
  submitError: PropTypes.string,
  progressBar: PropTypes.node,
};

export default NPS;
