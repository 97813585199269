import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { add, format, set } from 'date-fns';

import { ImmutableTrip } from '../../../../types/trips/Trips';
import { INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD } from '../../../../calendar/constants';
import {
  GUESTS_FIELD,
  PICKUP_TIME_FIELD,
  RESERVATION_CANCELLED_STATES,
  RETURN_TIME_FIELD,
  TRIP_LENGTH_FIELD,
} from '../../../constants';
import {
  formatGroupSize,
  getClassNameFor,
  multidayFormat,
  singleDayFormat,
} from '../../../../common/helpers';
import { getUserBoats, getOffer } from '../../../../common/utils/reduxStoreSelectors';
import { getSelectedBoat, getTripStartDate, getTripValue, userIsRenter } from '../../../helpers';
import getCaptainedCopy from '../../../../common/utils/getCaptainedCopy';
import useCaptainData from '../../../../common/hooks/useCaptainData';
import Avatar from '../../../../common/components/Avatar';
import { totalGuests } from '../../../../common/components/GroupSizeDetails';
import Card from '../../presentation/Card';
import { offerGuestSelector } from '../GroupSize/selector';
import { captainedGetter } from '../Captain/selector';
import s from './BookingInfoCard.module.scss';

const TIME_FORMAT = 'h:mm aa';
const START_DATE_FORMAT = 'EEEE, MMMM dd, u';

type Props = {
  classNameModifier?: string;
  trip: ImmutableTrip;
};

const BookingInfoCard: FC<Props> = ({ classNameModifier = '', trip }) => {
  const offer = useSelector(getOffer);
  const userBoats = useSelector(getUserBoats);
  const otherUser = useMemo(() => trip.get(userIsRenter(trip) ? 'owner' : 'renter'), [trip]);

  const pickupTime = getTripValue(offer, trip, PICKUP_TIME_FIELD);
  const returnTime = offer.get(RETURN_TIME_FIELD) ?? trip.get(RETURN_TIME_FIELD);
  const tripLength = offer.get(TRIP_LENGTH_FIELD) ?? trip.get(TRIP_LENGTH_FIELD);
  const tripLengthMoment = moment.duration(tripLength);
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  const tripDuration = tripLengthMoment._data;

  const isMultiDay = tripLengthMoment.asDays() >= 1;
  const isDay = trip.get('has_day_pricing');
  const isOnlyDay = isDay && !trip.get('has_hour_pricing');

  // Multiday trips are mix of days and hours. 1 Day and 8 Hours would be equivalent of '2 Days'
  const days = isMultiDay && isDay
    ? Math.ceil(tripLengthMoment.asDays())
    : Math.floor(tripLengthMoment.asDays());

  const tripLengthString = days || isOnlyDay
    ? multidayFormat(days, isDay, isOnlyDay)
    : singleDayFormat(tripLengthMoment);

  // const { returnMoment, departMoment } = offerTimeSelector({ offer, trip });

  const tripStartDate = useMemo(() => getTripStartDate({ offer, trip }), [offer, trip]);

  const tripEndDate = useMemo(() => {
    let endDate = add(tripStartDate, tripDuration);
    // If return time is set we want to add it to tripEndDate
    if (returnTime) {
      const [hours, minutes] = returnTime.split(':');
      endDate = set(endDate, { hours, minutes });
    }
    return endDate;
  }, [returnTime, tripDuration, tripStartDate]);

  const { guests } = offerGuestSelector({ offer, trip, userBoats });
  const formattedGroupSize = formatGroupSize(totalGuests(guests), GUESTS_FIELD);

  const captained = captainedGetter({ offer, trip });
  const boat = getSelectedBoat(offer, trip, userBoats).toJS();
  const captainCostIncluded = trip.get(INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD);
  const tripState = trip.getIn(['state', 'state']);

  const { captainOptionHeading } = useCaptainData(trip, offer);

  const captainedString = useMemo(
    () => (
      trip.get('is_instabook') && !RESERVATION_CANCELLED_STATES.includes(tripState)
        ? getCaptainedCopy(captained, captainCostIncluded).shortHeading
        : captainOptionHeading.shortLabel
    ),
    [captainCostIncluded, captained, captainOptionHeading.shortLabel, trip, tripState],
  );

  return (
    <Card>
      <h3 className={s.title}>Your booking with {otherUser.get('first_name')}</h3>
      <div className={s.wrapper}>
        <div className={s.avatars}>
          <Avatar
            classNameModifier="bookingInfo user"
            size="large"
            user={otherUser.toJS()}
          />
          <Avatar
            isBoat
            classNameModifier="bookingInfo boat"
            size="large"
            user={{
              ...boat,
              has_photo: !!boat?.photo_url,
              first_name: boat?.headline,
            }}
          />
        </div>
        <ul className={s.content}>
          {/**
            We want to include departMoment and returnMoment instead for the times.
            This is needed for the depart and return times to remain consistent with rest of cards
           */}
          <li className={getClassNameFor(s, 'date', classNameModifier)}>
            {format(tripStartDate, START_DATE_FORMAT)}
          </li>
          <li>
            {pickupTime
              ? `${format(tripStartDate, TIME_FORMAT)} - ${format(tripEndDate, TIME_FORMAT)}`
              : 'Departure TBD'}
          </li>
          <li>{tripLengthString}</li>
          <li>
            {formattedGroupSize} • {captainedString}
          </li>
        </ul>
      </div>
    </Card>
  );
};

export default BookingInfoCard;
