import React, { FC } from 'react';

import { getClassNameFor } from 'src/common/helpers';
import styles from './WithOverlay.module.scss';

export type WithOverlayProps = {
  text: string;
  classNameModifier?: string;
};

const WithOverlay: FC<WithOverlayProps> = ({
  text, classNameModifier = 'full',
}) => (
  <div className={getClassNameFor(styles, 'root', classNameModifier)}>
    <div className={getClassNameFor(styles, 'overlay', classNameModifier)}>
      <span className={getClassNameFor(styles, 'callout', classNameModifier)}>{text}</span>
    </div>
  </div>
);

export default WithOverlay;
