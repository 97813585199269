import React from 'react';
import { Link } from 'react-router-dom';

import s from './RequestChangesIntro.module.scss';

type RequestChangesIntroProps = {
  modified: boolean;
  reviewBookingLink: {
    pathname: string;
    search: string;
  };
};

const RequestChangesIntro: React.FC<RequestChangesIntroProps> = ({
  modified,
  reviewBookingLink,
}) => (
  <p className={s.root}>
    You can request changes to your trip date, depart/return time or duration
    by editing the details below.
    {!modified && (
      <>
        {' Or, if you are happy to proceed you can '}
        <Link
          to={reviewBookingLink}
          className={s.link}
        >
          book now
        </Link>.
      </>
    )}
  </p>
);

export default RequestChangesIntro;
