import React, { FC, ReactNode } from 'react';

import s from './FormError.module.scss';

type FormErrorProps = {
  className?: string;
  error?: ReactNode;
};

const FormError: FC<FormErrorProps> = ({ error = null, className = s.root }) => (error ? (
  <div className={className}>
    {error}
  </div>
) : null);

export default FormError;
