import React, { FC } from 'react';
import styles from './ListingCardOverview.module.scss';
import Icon from '../IconDS22';

export type Props = {
  heading: string;
  guests?: string;
};

const ListingCardOverview: FC<Props> = ({
  guests, heading,
}) => (
  <div className={styles.root}>
    <div className={styles.overview}>
      {heading && (
        <span className={styles.heading}>
          {heading}
        </span>
      )}
      {heading && guests && (' · ')}
      {guests && (
        <span className={styles.guests}>
          {`${guests} guests`}
        </span>
      )}
    </div>
    <Icon
      id="heart"
      size="s"
    />
  </div>
);

export default ListingCardOverview;
