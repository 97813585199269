import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import {
  INQUIRY_DECLINE_STATES,
  OFFER_CANCELLED_STATES,
  RESERVATION_CANCELLED_STATES,
} from '../../constants';
import Card from '../presentation/Card';
import TripDetailComment from '../TripDetailComment';

const Index = ({ trip }) => {
  const tripState = trip.getIn(['state', 'state']);

  const inquiryDeclineMessage = trip.get('decline_message') || Map();
  const inquiryDeclineMessageContent = inquiryDeclineMessage.get('content');
  const showInquiryDeclineMessage = inquiryDeclineMessageContent
    && INQUIRY_DECLINE_STATES.includes(tripState);

  const offerCancelMessage = trip.get('cancel_message') || Map();
  const offerCancelMessageContent = offerCancelMessage.get('content');
  const showOfferCancelMessage = offerCancelMessageContent
    && OFFER_CANCELLED_STATES.includes(tripState);

  const reservationCancelMessage = trip.get('reservation_cancel_message') || Map();
  const reservationCancelMessageContent = reservationCancelMessage.get('content');
  const showReservationCancelMessage = reservationCancelMessageContent
    && RESERVATION_CANCELLED_STATES.includes(tripState);

  return (
    <>
      {showInquiryDeclineMessage && (
        <Card>
          <TripDetailComment
            user={trip.get('owner')}
            date={inquiryDeclineMessage.get('date_created')}
          >
            {inquiryDeclineMessageContent}
          </TripDetailComment>
        </Card>
      )}
      {showOfferCancelMessage && (
        <Card>
          <TripDetailComment
            user={trip.get('owner')}
            date={offerCancelMessage.get('date_created')}
          >
            {offerCancelMessageContent}
          </TripDetailComment>
        </Card>
      )}
      {showReservationCancelMessage && (
        <Card>
          <TripDetailComment
            user={reservationCancelMessage.get('user')}
            date={reservationCancelMessage.get('date_created')}
          >
            {reservationCancelMessageContent}
          </TripDetailComment>
        </Card>
      )}
    </>
  );
};

Index.propTypes = {
  trip: PropTypes.object.isRequired,
};

export default Index;
