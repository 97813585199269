import React, { FC, ReactNode, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Icon from 'src/common/components/IconDS22';
import { ImmutableTrip } from '../../../../types/trips/Trips';

import { PATHS } from '../../../../common/constants';
import { CONFIRMED_STATES, PAGES } from '../../../constants';
import { getClassNameFor } from '../../../../common/helpers';
import { useMediaQuery, sharedMediaQueries } from '../../../../common/hooks';
import NotificationMessage from '../../../../common/components/NotificationMessage';
import SubHeader from '../../SubHeader';
import s from './TripPanel.module.scss';

type Props = {
  renderAs?: 'form' | 'main';
  trip: ImmutableTrip;
  intro?: ReactNode;
  subheader?: ReactNode;
  warning?: ReactNode;
  cta?: ReactNode;
  classNameModifier?: string;
  backLocation?: {
    search: string;
    pathname: string;
  };
  withTripControls?: boolean;
  hasPendingTripChanges?: boolean;
};

const TripPanel: FC<Props> = ({
  trip,
  subheader,
  intro,
  warning,
  children,
  cta,
  classNameModifier,
  backLocation,
  withTripControls,
  renderAs: Root = 'main',
  hasPendingTripChanges,
  ...restProps
}) => {
  const tripState = trip.getIn(['state', 'state']);
  const bookingConfirmed = useMemo(() => CONFIRMED_STATES.includes(tripState), [tripState]);
  const sizeM = useMediaQuery(sharedMediaQueries.m);
  const isMessagesPage = useRouteMatch(`${PATHS.INBOX}:tripId/${PAGES.MESSAGES}/`);

  return (
    <Root
      className={getClassNameFor(s, 'root', classNameModifier)}
      {...restProps}
    >
      <SubHeader
        trip={trip}
        backLocation={backLocation}
        classNameModifier={classNameModifier}
        withTripControls={withTripControls}
        hasPendingTripChanges={hasPendingTripChanges}
      >
        {sizeM && bookingConfirmed && !isMessagesPage && (
          <Icon id="check-circle" size="xl" />
        )}
        {subheader}
      </SubHeader>
      {bookingConfirmed && !isMessagesPage && (
        <div className={s.waves} />
      )}
      <div className={s.innerWrapper}>
        <div className={getClassNameFor(s, 'content', classNameModifier)}>
          {intro && (
            <div className={getClassNameFor(s, 'message', classNameModifier)}>
              {intro}
            </div>
          )}
          {warning && (
            <NotificationMessage
              outdent={false}
              classNameModifier={`tripPanel alignTop ${classNameModifier}`}
            >
              {warning}
            </NotificationMessage>
          )}
          {children}
        </div>
      </div>
      {cta}
    </Root>
  );
};

export default TripPanel;
