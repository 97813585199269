import React, { FC, useState } from 'react';

import { userIsRenter } from 'src/inbox/helpers';
import { ImmutableTrip } from 'src/types/trips/Trips';
import { FullBoatDetail, ImmutableSearchBoatDetails } from 'src/types/boat/BoatDetail';
import { getClassNameFor, getBoatHeadline } from 'src/common/helpers';
import BoatPlaceholderImage from 'src/common/components/BoatPlaceholderImage';
import { NEW_TAB_ATTRIBUTES } from 'src/common/constants';
import s from './Vessel.module.scss';

type Props = {
  title?: string;
  vessel: ImmutableSearchBoatDetails;
  classNameModifier?: string;
  trip?: ImmutableTrip;
};

const Vessel: FC<Props> = ({ title, vessel, classNameModifier, trip }) => {
  const [imageLoadFailed, setImageLoadFailed] = useState(false);
  const className = getClassNameFor(s, 'root', classNameModifier);
  const isOwner = !userIsRenter(trip);
  const vesselObj: FullBoatDetail = vessel.toJS();
  const headline = isOwner ? getBoatHeadline(vesselObj) : vessel.get('headline');
  const url = vessel.get('listing_url');
  const photoUrl = vessel.get('photo_url');

  return (
    <div className={className}>
      <a
        href={url}
        {...NEW_TAB_ATTRIBUTES}
      >
        {photoUrl && !imageLoadFailed ? (
          <img
            src={photoUrl}
            alt={headline}
            onError={() => setImageLoadFailed(true)}
            className={getClassNameFor(s, 'image', classNameModifier)}
          />
        ) : (
          <BoatPlaceholderImage classNameModifier="vessel" />
        )}
      </a>
      <div className={getClassNameFor(s, 'content', classNameModifier)}>
        {title && <h3 className={s.subTitle}>{title}</h3>}
        <a
          href={url}
          className={getClassNameFor(s, 'link', classNameModifier)}
          {...NEW_TAB_ATTRIBUTES}
        >
          {headline}
        </a>
      </div>
    </div>
  );
};

export default Vessel;
