import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { PATHS } from '../../../../common/constants';
import { preserveSearch } from '../../../../common/utils/routing';
import { userIsRenter } from '../../../helpers';
import {
  CaptainCard,
  CommentCard,
  DatesCard,
  DurationCard,
  GroupSizeCard,
  TimeCard,
  VesselCard,
} from '../../cards';
import ArchiveButton from '../../ArchiveButton';
import TripMenu from '../../TripMenu';
import TripPanel from '../../presentation/TripPanel';
import content, { getMessage } from '../content';
import CTA, { CTALink } from '../../CTA';
import TripDetailsTitle from '../../presentation/TripDetailsTitle';

const Lapsed = ({
  trip,
  location,
}) => {
  const tripState = trip.getIn(['state', 'state']);
  const isRenter = userIsRenter(trip);
  const archived = trip.get('archived');
  const cta = (
    <CTA classNameModifier="withSidebar">
      {isRenter ? (
        <>
          <TripMenu trip={trip} />
          <CTALink
            to={{
              pathname: `${PATHS.INQUIRY}${trip.getIn(['boat', 'id'])}/`,
              search: `?return=${trip.getIn(['boat', 'listing_url'])}`,
            }}
          >
            Send a new inquiry
          </CTALink>
        </>
      ) : (
        <ArchiveButton
          archived={archived}
          trip={trip}
        >
          {`${archived ? 'Unarchive' : 'Archive'} this Inquiry`}
        </ArchiveButton>
      )}
    </CTA>
  );

  return (
    <TripPanel
      trip={trip}
      subheader={content.title[tripState]}
      intro={getMessage(trip, location)}
      cta={cta}
      backLocation={preserveSearch(PATHS.INBOX, location)}
      classNameModifier="withoutSideMargins"
    >
      <TripDetailsTitle />
      <VesselCard
        readOnly
        trip={trip}
      />
      <DatesCard
        readOnly
        trip={trip}
      />
      <TimeCard
        readOnly
        trip={trip}
      />
      <DurationCard
        readOnly
        trip={trip}
      />
      <GroupSizeCard
        readOnly
        trip={trip}
      />
      <CaptainCard
        readOnly
        trip={trip}
      />
      <CommentCard
        readOnly
        trip={trip}
      />
    </TripPanel>
  );
};

Lapsed.propTypes = {
  trip: PropTypes.object,
  location: PropTypes.object,
};

export default connect()(Lapsed);
