import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { PATHS } from '../../../common/constants';
import { CONFIRMED_STATES, PAGES } from '../../constants';
import { getClassNameFor, noop } from '../../../common/helpers';
import { getOtherParty } from '../../helpers';
import { fullName } from '../../../common/utils/user';
import Avatar, { AvatarSizes } from '../../../common/components/Avatar';
import Dropdown from '../../../common/components/Dropdown';
import s from './UserInfo.module.scss';
import { ImmutableTrip } from '../../../types/trips/Trips';

type Props = {
  classNameModifier?: string;
  permanentlyOpen?: boolean;
  size?: AvatarSizes;
  trip: ImmutableTrip;
  withContactDetails?: boolean;
  closeModal?: () => void;
};

const UserInfo: FC<Props> = ({
  classNameModifier = '',
  closeModal = noop,
  permanentlyOpen = false,
  size = 'extraLarge',
  trip,
  withContactDetails = true,
}) => {
  const tripState = trip.getIn(['state', 'state']);
  const user = getOtherParty(trip);
  const name = CONFIRMED_STATES.includes(tripState) ? fullName(user) : user.get('first_name');
  const phone = user.get('phone');
  const email = user.get('email');
  const { search } = useLocation();

  return (
    <Dropdown
      className={getClassNameFor(s, 'root', classNameModifier)}
      disableOnClickOutside
    >
      {({ toggle, isOpen }) => (
        <>
          <Avatar
            user={user.toJS()}
            size={size}
          />
          <div className={s.content}>
            <h2 className={getClassNameFor(s, 'name', classNameModifier)}>
              {name}
            </h2>
            {(!permanentlyOpen && withContactDetails) && (
              <button
                type="button"
                onClick={toggle}
                className={s.button}
              >
                Contact Details
              </button>
            )}
            {(isOpen || permanentlyOpen) && withContactDetails && (
              <ul className={s.contactDetails}>
                <li>
                  <a
                    href={`tel:${phone}`}
                    className={getClassNameFor(s, 'link', `${classNameModifier} phone`)}
                  >
                    {phone}
                  </a>
                </li>
                <li>
                  <a
                    href={`mailto:${email}`}
                    className={getClassNameFor(s, 'link', `${classNameModifier} email`)}
                  >
                    {email}
                  </a>
                </li>
                <li>
                  <Link
                    to={`${PATHS.INBOX}${trip.get('pk')}/${PAGES.MESSAGES}/${search}`}
                    className={getClassNameFor(s, 'link', classNames('sendMessage', classNameModifier))}
                    onClick={closeModal}
                  >
                    Send a Message
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </>
      )}
    </Dropdown>
  );
};

export default UserInfo;
