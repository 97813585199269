import React from 'react';
import s from './UnstyledButton.module.scss';

type UnstyledButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

const UnstyledButton = ({
  children,
  onClick = () => {},
}: UnstyledButtonProps) => (
  <button
    type="button"
    className={s.root}
    onClick={onClick}
  >
    {children}
  </button>
);

export default UnstyledButton;
