import React from 'react';
import { connect, useSelector } from 'react-redux';
import type { Action } from 'redux';
import { OnChange } from 'react-final-form-listeners';
import moment from 'moment';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Field } from 'react-final-form';

import { getPaymentAddons } from 'src/common/utils/reduxStoreSelectors';
import type { ImmutableTrip } from 'src/types/trips/Trips';
import type { ImmutableBoatReview, ImmutableBoatReviews } from 'src/types/reviews/Reviews';
import { useCaptainSelectorContext } from 'src/inbox/providers/CaptainSelectorProvider';
import type { ReduxState } from 'src/types/reduxState';
import type { ThunkDispatch } from 'redux-thunk';
import {
  SUPPORT_URLS,
  MOMENT_FORMAT,
} from '../../../../common/constants';
import {
  decorateComponent,
  formatGroupSize as format,
} from '../../../../common/helpers';
import { usePhotoUploader } from '../../../../common/hooks';
import { close, open } from '../../../../common/ducks/zippy';
import { fullName } from '../../../../common/utils/user';
import {
  BOAT_FIELD,
  GUESTS_FIELD,
  PHOTO_PREVIEW_MODAL_NAME,
  PREFERRED_DATE_FIELD,
} from '../../../constants';
import { getOtherParty, userIsRenter } from '../../../helpers';
import {
  appendRoute,
  preserveSearch,
} from '../../../../common/utils/routing';
import {
  getBoatReviewCall,
  getRenterReviewCall,
  patchBoatReviewCall,
  patchRenterReviewCall,
} from '../../../ducks/reviews';
import Avatar from '../../../../common/components/Avatar';
import SvgDefs from '../../../../common/components/SvgDefs';
import Svg from '../../../../common/components/Svg';
import TopScroll from '../../../../common/components/TopScroll';
import ReviewItem from '../../ReviewItem';
import DetailsDropdown from '../../DetailsDropdown';
import { totalGuests } from '../../../../common/components/GroupSizeDetails';
import RatingInput from '../../../../common/components/inputs/RatingInput';
import RecommendationFields from '../../../../common/components/fieldsets/RecommendationFields';
import TripPanel from '../../presentation/TripPanel';
import CTA, { CTAButton, CTALink } from '../../CTA';
import TripMenu from '../../TripMenu';
import PhotoUploader from '../../PhotoUploader';
import Card from '../../presentation/Card';
import { REVIEW_PAGES, FIELDS } from '../../pages/Complete/reviewsConfig';
import ChargeBookingDetails from '../../ChargeBookingDetails';
import CaptainSelector from '../../CaptainSelector';
import CtaWidget from '../../CtaWidget';
import PaymentAddons from '../../PaymentAddons';
import imagesSvg from './images.svg';
import s from './NewReviewCard.module.scss';
import content from '../../pages/content';

const {
  RATING,
  RECOMMENDATION,
  PRIVATE_NOTE,
  PUBLIC_REVIEW,
  LISTING_ACCURACY,
  DEPARTURE_AND_RETURN,
  VESSEL_AND_EQUIPMENT,
  COMMUNICATION,
  VALUE,
  ITINERARY_AND_EXPERIENCE,
} = FIELDS;

const supportLink = (
  <a
    href={SUPPORT_URLS.REQUEST}
    target="_blank"
    rel="noopener noreferrer"
    className={s.link}
  >
    contact Getmyboat support
  </a>
);

const categoriesFields = [
  LISTING_ACCURACY,
  `${LISTING_ACCURACY}_comment`,
  DEPARTURE_AND_RETURN,
  `${DEPARTURE_AND_RETURN}_comment`,
  VESSEL_AND_EQUIPMENT,
  `${VESSEL_AND_EQUIPMENT}_comment`,
  COMMUNICATION,
  `${COMMUNICATION}_comment`,
  VALUE,
  `${VALUE}_comment`,
  ITINERARY_AND_EXPERIENCE,
  `${ITINERARY_AND_EXPERIENCE}_comment`,
];

const mapDispatchToProps = (dispatch: ThunkDispatch<ReduxState, {}, Action>) => ({
  openModal: () => dispatch(open(PHOTO_PREVIEW_MODAL_NAME)),
  closeModal: () => dispatch(close(PHOTO_PREVIEW_MODAL_NAME)),
  patchBoatReview: (
    id: string,
    pk: string,
    values: Record<string, any>,
  ) => dispatch(patchBoatReviewCall(id, pk, values)),
  patchRenterReview: (id: string, pk: string, values: Record<string, any>) => (
    dispatch(patchRenterReviewCall(id, pk, values))
  ),
  fetchBoatReview: (id: string) => dispatch(getBoatReviewCall(id)),
  fetchRenterReview: (id: string) => dispatch(getRenterReviewCall(id)),
});

type NewReviewCardProps = {
  trip: ImmutableTrip;
  reviews: ImmutableBoatReviews;
  reviewPending: boolean;
  reviewWaiting: boolean;
  tripState: string;
  backLocation: {};
  openModal: () => void;
  closeModal: () => void;
  patchBoatReview: (id: string, pk: string, values: Record<string, any>) => void;
  patchRenterReview: () => void;
  fetchBoatReview: (pk: string) => Promise<ImmutableBoatReviews>;
  fetchRenterReview: (pk: string) => Promise<void>;
};

const NewReviewCard: React.FC<NewReviewCardProps> = ({
  trip,
  reviews,
  reviewPending,
  reviewWaiting,
  backLocation,
  tripState,
  patchBoatReview,
  patchRenterReview,
  fetchBoatReview,
  fetchRenterReview,
  openModal,
  closeModal,
}) => {
  const { push } = useHistory();
  const location = useLocation();
  const {
    captain,
    captainSelectorEnabled,
    captainSelectorOpen,
    openCaptainSelector,
    closeCaptainSelector,
  } = useCaptainSelectorContext();
  const { pathname } = location;
  const nextPath = preserveSearch(appendRoute(pathname, `review/${REVIEW_PAGES.START}`), location);

  const otherUser = getOtherParty(trip);
  const otherUserName = fullName(otherUser);

  const preferredDate = trip.get(PREFERRED_DATE_FIELD);
  const preferredDateMoment = moment(preferredDate);
  const preferredDateFormatted = preferredDateMoment.format(MOMENT_FORMAT.DATE);

  const boat = trip.get(BOAT_FIELD);
  const boatLocation = boat.get('city') || boat.get('state') || boat.get('country');
  const boatLocationString = boatLocation ? `in ${boatLocation}` : '';
  const guests = trip.get(GUESTS_FIELD);
  const numberOfGuests = totalGuests(guests);

  // Renter's review
  // TODO: why is this returned as an array? Do we know that that's going to refer
  // to the correct review?
  const renterReview = reviews.getIn(['renter', 'results', 0]);
  const renterRecommendation = renterReview?.get(RECOMMENDATION);
  const publicRenterReviewLeft = (
    renterReview?.get(PUBLIC_REVIEW)
    || typeof renterRecommendation === 'boolean'
  );
  const privateRenterReviewLeft = renterReview?.get(PRIVATE_NOTE);
  const renterReviewLeft = publicRenterReviewLeft || privateRenterReviewLeft;
  const positiveRenterRecommendation = renterRecommendation === true;
  const negativeRenterRecommendation = renterRecommendation === false;

  // Boat's review
  const boatReview = reviews.getIn(['boat', 'results', 0]);
  const boatRating = boatReview?.get(RATING);
  const publicBoatReviewLeft = (
    boatReview?.get(PUBLIC_REVIEW)
    || boatRating
  );
  const privateBoatReviewLeft = boatReview?.get(PRIVATE_NOTE);
  const boatCategoriesReviewLeft = categoriesFields.some(field => boatReview?.get(field));
  const boatReviewLeft = (
    publicBoatReviewLeft
    || privateBoatReviewLeft
    || boatCategoriesReviewLeft
  );
  const positiveBoatRating = boatRating >= 3;
  const negativeBoatRating = boatRating < 3;

  const renderPublicFeedbackSection = !reviewPending && (
    (publicBoatReviewLeft && positiveBoatRating)
    || (publicRenterReviewLeft && positiveRenterRecommendation)
  );
  const renderPrivateFeedbackSection = !reviewPending && (
    privateBoatReviewLeft
    || boatCategoriesReviewLeft
    || privateRenterReviewLeft
    || (publicBoatReviewLeft && negativeBoatRating)
    || (publicRenterReviewLeft && negativeRenterRecommendation)
  );

  const isRenter = userIsRenter(trip);
  const isOwner = !isRenter;
  // TODO: refactor
  const getTitle = () => {
    const firstName = otherUser.get('first_name');
    const name = isRenter ? otherUserName : firstName;
    // If owner has not reviewed renter OR
    // If renter has not reviewed owner
    let title = `How was your experience with ${name}?`;

    // If owner has reviewed renter and is waiting to be reviewed OR
    // if renter has reviewed owner and is waiting to be reviewed
    if (
      (isRenter && boatReviewLeft && !renterReviewLeft)
      || (isOwner && renterReviewLeft && !boatReviewLeft)
    ) {
      title = `${name} has been prompted to leave a review or feedback.`;
    } else if (
      // If review done and has private and public reviews
      (
        isRenter && privateRenterReviewLeft
        && publicRenterReviewLeft && positiveRenterRecommendation
      ) || (
        isOwner && privateBoatReviewLeft
        && publicBoatReviewLeft && positiveBoatRating
      ) || (
        // If review done and has public review only
        isRenter && !privateRenterReviewLeft
        && publicRenterReviewLeft && positiveRenterRecommendation
      ) || (
        isOwner && !privateBoatReviewLeft && publicBoatReviewLeft && publicBoatReviewLeft
      )
    ) {
      title = `${firstName} left a public review for your trip. Congratulations!`;
      // If review done and there's only private review or negative public review
    } else if (
      (isRenter && !publicRenterReviewLeft && privateRenterReviewLeft)
      || (isRenter && publicRenterReviewLeft && negativeRenterRecommendation)
      || (isOwner && !publicBoatReviewLeft && privateBoatReviewLeft)
      || (isOwner && publicBoatReviewLeft && negativeBoatRating)
    ) {
      title = `${firstName} left private feedback about your trip.`;
    }

    return title;
  };
  const patchReview = (keys: any) => {
    const patchTheReview = isRenter ? patchBoatReview : patchRenterReview;
    const review: ImmutableBoatReview = isRenter ? boatReview : renterReview;
    return patchTheReview(trip.get('pk').toString(), review.get('id').toString(), { s3_keys: keys });
  };
  const getReviews = (pk: string) => (isRenter ? fetchBoatReview(pk) : fetchRenterReview(pk));
  const {
    images,
    submitting,
    handleSubmit,
    resetState,
    ...restUploaderProps
  } = usePhotoUploader({
    onSubmitSuccess: () => getReviews(trip.get('pk').toString()),
    onUploadSuccess: patchReview,
    openModal,
    closeModal,
  });
  const cta = (
    <CTA classNameModifier="withSidebar">
      {!images.length && (
        <TripMenu trip={trip} />
      )}
      {(reviewPending || reviewWaiting) && (
        <CTALink
          to={nextPath}
          data-test="addReviewBtn"
        >
          Add Review
        </CTALink>
      )}
      {!!images.length && (
        <>
          <CTAButton
            type="reset"
            classNameModifier="secondary"
            onClick={resetState}
          >
            Cancel
          </CTAButton>
          <CTAButton
            type="button"
            submitting={submitting}
            onClick={handleSubmit}
          >
            Save Photos
          </CTAButton>
        </>
      )}
    </CTA>
  );

  const renderReviewItem = (review, reviewType: 'categories' | 'private' | 'public') => (
    <ReviewItem
      key={`${reviewType}_${review.get('id')}`}
      review={review}
      hidden={reviewWaiting}
      trip={trip}
      reviewCategory={reviewType}
    />
  );

  const paymentAddons = useSelector(getPaymentAddons);

  return (
    <TripPanel
      trip={trip}
      subheader={content.title[tripState]}
      cta={cta}
      backLocation={backLocation}
    >
      <TopScroll />
      {captainSelectorEnabled && (
        <CtaWidget
          title={captain ? 'Captain' : 'Select Captain'}
          description={captain ? (
            <>
              {fullName(captain)}
              <br />
              {captain.email}
            </>
          ) : 'To receive the Captain payment.'}
          onClick={openCaptainSelector}
          iconId={!captain ? 'captain-plus' : undefined}
          withEditIcon
          user={captain}
        />
      )}
      {captainSelectorEnabled && (
        <CaptainSelector
          closeModal={closeCaptainSelector}
          modalOpen={captainSelectorOpen}
          selectedCaptainId={captain?.id}
          tripId={trip.get('pk')}
        />
      )}
      {!!paymentAddons?.length && (
        <>
          <ChargeBookingDetails
            isOwner={isOwner}
            trip={trip}
          />
          <PaymentAddons
            addons={paymentAddons}
            isOwner={isOwner}
          />
        </>
      )}
      <Card classNameModifier="review">
        <div className={s.avatars}>
          <Avatar
            isBoat
            user={{
              first_name: boat.get('headline'),
              photo_url: boat.get('photo_url'),
              has_photo: !!boat.get('photo_url'),
            }}
            size="large"
          />
          <Avatar
            user={otherUser.toJS()}
            size="large"
          />
        </div>

        <h3 className={s.subTitle}>
          {getTitle()}
        </h3>

        <ul className={s.details}>
          <li>
            {`${preferredDateFormatted} ${boatLocationString}`}
          </li>
          <li>
            {format(numberOfGuests, GUESTS_FIELD)}
          </li>
        </ul>

        {((isRenter && positiveBoatRating) || (isOwner && positiveRenterRecommendation)) && (
          <>
            <SvgDefs href={imagesSvg} />
            <h4 className={s.addPhotosTitle}>
              Add photos to show your appreciation and help future guests.
            </h4>
            <PhotoUploader
              images={images}
              submitting={submitting}
              handleSubmit={handleSubmit}
              {...restUploaderProps}
            >
              <Svg
                href="#boat-illustration"
                className={s.illustration}
              />
              <Svg
                href="#deck"
                className={s.illustration}
              />
              <Svg
                href="#inflatable-flamingo"
                className={s.illustration}
              />
            </PhotoUploader>
          </>
        )}

        {(reviewPending || reviewWaiting)
          && (isRenter
            ? (
              <>
                <Field
                  name={RATING}
                  component={RatingInput}
                  classNameModifier="centered"
                />
                {/* NOTE: This is done with an onChange
                rather than an onClick because the onClick
                handler of react-rating seems to intercept or
                supersede its onChange handler, which means that
                the value isn't correctly registered before the page
                transitions. This should work around that. */}
                <OnChange name={RATING}>
                  {() => push(nextPath)}
                </OnChange>
              </>
            )
            : (
              <RecommendationFields
                name={RECOMMENDATION}
                onClick={() => push(nextPath)}
              />
            )
          )}
        {renderPublicFeedbackSection && (
          <>
            <h6 className={s.sectionHeading}>
              PUBLIC REVIEW
            </h6>
            <ul className={s.reviews}>
              {publicBoatReviewLeft && positiveBoatRating
                && renderReviewItem(boatReview, 'public')}
              {publicRenterReviewLeft && positiveRenterRecommendation
                && renderReviewItem(renterReview, 'public')}
            </ul>
          </>
        )}

        {renderPrivateFeedbackSection && (
          <>
            <h6 className={s.sectionHeading}>
              PRIVATE FEEDBACK
            </h6>
            <ul className={s.reviews}>
              {publicBoatReviewLeft && negativeBoatRating
                && renderReviewItem(boatReview, 'public')}
              {privateBoatReviewLeft
                && renderReviewItem(boatReview, 'private')}
              {boatCategoriesReviewLeft
                && renderReviewItem(boatReview, 'categories')}
              {publicRenterReviewLeft && negativeRenterRecommendation
                && renderReviewItem(renterReview, 'public')}
              {privateRenterReviewLeft
                && renderReviewItem(renterReview, 'private')}
            </ul>
          </>
        )}

        {reviewWaiting && (
          <p className={s.details}>
            {`${otherUser.get('first_name')} left feedback for you. `}
            <Link
              to={nextPath}
              className={s.link}
            >
              Add your review
            </Link>
            {' to see it.'}
          </p>
        )}

        <p className={s.support}>
          Please {supportLink} if you have any feedback or questions about this trip or rental.
        </p>
        <p className={s.bookingId}>
          {`Booking ID: ${trip.get('reservation')}`}
        </p>

        <DetailsDropdown trip={trip} />
      </Card>
    </TripPanel>
  );
};

const decorators: any[] = [
  connect(null, mapDispatchToProps),
];

export default decorateComponent(NewReviewCard, decorators);
