import React from 'react';
import PropTypes from 'prop-types';

import { buildImgixUrl, imgixParamsForLightbox } from '../../../../../common/utils/imgix';
import Lightbox from '../../../../../common/components/Lightbox';
import PhotosGrid from '../../../../../common/components/PhotosGrid';

const ReviewPhotos = ({
  photos,
}) => !photos.isEmpty() && (
  <Lightbox
    images={photos.map(({ src }) => ({
      src: buildImgixUrl(src, imgixParamsForLightbox),
    })).toJS()}
    backdropClosesModal
    width="90vw"
  >
    {({ open }) => (
      <PhotosGrid
        openPhoto={open}
        photos={photos}
        classNameModifier="review"
      />
    )}
  </Lightbox>
);

ReviewPhotos.propTypes = {
  photos: PropTypes.object.isRequired,
};

export default ReviewPhotos;
