import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from 'src/common/helpers';
import { CALENDAR_MONTH_LONG, CALENDAR_YEAR_LONG } from 'src/calendar/constants';
import { formatDate } from 'src/common/utils/dateHelpers';
import s from './MonthTitle.module.scss';

export type MonthTitleProps = {
  date: Date;
};

const MonthTitle: FC<MonthTitleProps> = ({ date }) => {
  const [stuck, setStuck] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const month = formatDate(date, CALENDAR_MONTH_LONG, {});
  const year = formatDate(date, CALENDAR_YEAR_LONG);

  useEffect(() => {
    const currentTitle = ref.current;
    const observer = new IntersectionObserver(
      // Checks top positioning of element, set in css
      // Used to detect overlap, when new month needs to take place
      ([e]) => setStuck(e.intersectionRatio < 1),
      { threshold: [1] },
    );

    if (currentTitle) {
      observer.observe(currentTitle);
    }

    return () => {
      if (currentTitle) {
        observer.unobserve(currentTitle);
      }
    };
  }, [ref]);

  return (
    <div ref={ref} className={getClassNameFor(s, 'root', classNames({ isStuck: stuck }))}>
      <div className={s.inner}>
        {month}
        <span className={s.year}>
          {year}
        </span>
      </div>
    </div>
  );
};

export default MonthTitle;
