import React, { FC } from 'react';
import { Field } from 'react-final-form';
import useDirectBookingParam from 'src/auth/hooks/useDirectBookingParam';
import EmailField from '../../fields/EmailField';
import Input from '../../inputs/Input';
import PhoneInput from '../../PhoneInput';
import { rffValidators, combineValidators } from '../../../validation';
import s from './ContactDetails.module.scss';

export const firstNameValidate = rffValidators.required({ message: 'First name?' });
export const lastNameValidate = rffValidators.required({ message: 'Last name?' });
export const emailValidate = combineValidators([
  rffValidators.required({ message: 'Please provide an email address.' }),
  rffValidators.email({ message: 'Invalid email address' }),
]);
export const phoneValidate = combineValidators([
  rffValidators.required({ message: 'Please provide a phone number.' }),
  rffValidators.phone({ message: "That doesn't look like a valid phone number." }),
  rffValidators.asyncPhoneValidation({
    messageFn: (message) => <span>{message}</span>,
  }),
]);

type ContactDetailsProps = {
  isLoggedIn?: boolean;
};

const EmailFields = ({ isDirectBooking, isLoggedIn }: {
  isDirectBooking: boolean;
  isLoggedIn?: boolean;
}) => {
  if (isLoggedIn) {
    // If the user is logged in, we don't want to run the validation
    // (especially the one that throws if the email address already exists)
    return (
      <EmailField
        name="email"
        disabled
      />
    );
  }
  if (isDirectBooking) {
    return (
      <>
        <EmailField
          name="email_obfuscated"
          disabled
        />
        <EmailField
          name="email"
          placeholder="Confirm email"
        />
      </>
    );
  }
  return (
    <EmailField
      name="email"
      validate={emailValidate}
      validateFields={[]}
    />
  );
};

const ContactDetails: FC<ContactDetailsProps> = ({ isLoggedIn }) => {
  const { isDirectBooking } = useDirectBookingParam();
  return (
    <>
      <div className={s.fieldGroup}>
        <Field
          name="first_name"
          component={Input}
          validate={firstNameValidate}
          validateFields={[]}
          autoFocus
          id="firstName"
          placeholder="First Name"
          type="text"
          classNameModifier="half"
        />
        <Field
          name="last_name"
          component={Input}
          validate={lastNameValidate}
          validateFields={[]}
          id="lastName"
          placeholder="Last Name"
          type="text"
          classNameModifier="half"
        />
      </div>
      <EmailFields
        isLoggedIn={isLoggedIn}
        isDirectBooking={isDirectBooking}
      />
      <Field
        name="phone"
        component={PhoneInput}
        placeholder="Phone number"
        validate={phoneValidate}
        validateFields={[]}
      />
    </>
  );
};

export default ContactDetails;
