import React, { FC, KeyboardEvent, SyntheticEvent } from 'react';

const activatingKeyCodes = [13, 32];

type Props = {
  onClick: (event: SyntheticEvent) => void;
  onBlur?: (event: SyntheticEvent) => void;
  disabled?: boolean;
  className?: string;
  tabIndex?: number;
};

/**
 * This is intended to be a span that is fully accessible as a button,
 * both for keyboard users and screen readers.
 * @see https://www.deque.com/blog/accessible-aria-buttons
 */
const SpanButton: FC<Props> = ({
  children,
  disabled = false,
  onClick,
  className,
  tabIndex = 0,
  ...restProps
}) => {
  const onEnterOrSpace = (event: KeyboardEvent<HTMLSpanElement>) => {
    const target = event.target as HTMLSpanElement;
    if (activatingKeyCodes.includes(event.keyCode)
      && target.getAttribute('aria-disabled') === 'false') {
      onClick(event);
      event.preventDefault();
    }
  };

  return (
    <span
      role="button"
      tabIndex={tabIndex}
      onClick={onClick}
      className={className}
      aria-disabled={disabled}
      onKeyDown={onEnterOrSpace}
      {...restProps}
    >
      {children}
    </span>
  );
};

export default SpanButton;
