// Actions
// -----------------------------------------------------------------------------
export const SET_FEATURE_FLAGS = 'common/featureFlags/SET_FEATURE_FLAGS';

// Action creators
// -----------------------------------------------------------------------------
export const setFeatureFlags = (featureFlags: string[]) => (
  { type: SET_FEATURE_FLAGS, featureFlags } as const
);

type Actions = ReturnType<typeof setFeatureFlags>;

// Reducers
// -----------------------------------------------------------------------------
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (featureFlags: string[] = [], action: Actions) => {
  switch (action.type) {
    case SET_FEATURE_FLAGS:
      return action.featureFlags;
    default:
      return featureFlags;
  }
};
