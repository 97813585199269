import React, { FC } from 'react';
import type { Location } from 'history';
import { useSelector } from 'react-redux';
import { Redirect, Route, type RouteComponentProps } from 'react-router-dom';
import { parse, stringify } from 'query-string';

import { getUser } from 'src/common/utils/reduxStoreSelectors';
import { PATHS } from '../constants';

type BareLoginRequiredRouteProps = {
  component: React.ComponentType<any>;
  exact?: boolean;
  location?: Location;
  path?: string;
};

/*
 * Ripped from here: https://reacttraining.com/react-router/web/example/auth-workflow
 */
const BareLoginRequiredRoute: FC<BareLoginRequiredRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const loggedIn = useSelector(getUser).get('loggedIn');

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => (
        loggedIn === false ? (
          <Redirect
            to={{
              pathname: PATHS.LOGIN,
              search: stringify({
                ...parse(props.location.search),
                next: props.location.pathname,
              }),
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      )}
    />
  );
};

export default BareLoginRequiredRoute;
