import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { FieldPrefixContext } from '../../FormSection';

const PrefixedField = ({ name, ...props }) => (
  <FieldPrefixContext.Consumer>
    {prefix => (
      <Field
        name={`${prefix}.${name}`}
        {...props}
      />
    )}
  </FieldPrefixContext.Consumer>
);
PrefixedField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default PrefixedField;
