import React, { FC, Fragment, useMemo } from 'react';
import classNames from 'classnames';
import ScrollToMe from '../../../common/components/ScrollToMe';
import { getClassNameFor } from '../../../common/helpers';
import { useBoats } from '../../../common/hooks';
import { toDateString, getPastPresentFutureBooleans, newTZDate, todayDate } from '../../helpers';
import useCalendarFocus from '../../hooks/useCalendarFocus';
import useMonthRangeDates from '../../hooks/useMonthRangeDates';
import useScrollingMonthUpdate from '../../hooks/useScrollingMonthUpdate';
import { CalendarEvent, CalendarMonthData, GenericEvent, UnavailableEvent } from '../../types';
import {
  groupByBoat,
  groupByDay,
  isCalendarEvent,
  isInstabookEvent,
  isUnavailableEvent,
  sortInstabookEventsByTime,
} from '../../utils/calendar';
import InstabookCalendarCard from '../InstabookCalendarCard';
import InstabookListingHeader from '../InstabookListingHeader';
import MonthTitle from '../MonthTitle';
import DayCard from '../DayCard';
import NoEvents from '../NoEvents';

import s from './InstabookMonthAgendaView.module.scss';

// The MiniEvents have been removed from the MVP scope, so nothing will be rendered for now.
const MiniEvent: FC<{ event: CalendarEvent | UnavailableEvent }> = () => null;

export type InstabookMonthAgendaViewProps = CalendarMonthData & {
  setSelectedInstabookDate: React.Dispatch<React.SetStateAction<Date | null>>;
};

const InstabookMonthAgendaView: FC<InstabookMonthAgendaViewProps> = ({
  events,
  startDate,
  setSelectedInstabookDate,
}) => {
  const { boatsIndexedById } = useBoats();
  const today = todayDate();

  // TODO: When MiniEvents are displayed for Calendar and Unavailable events on the instabook view,
  // this filtering should be updated accordingly.
  const filteredEvents = useMemo(() => events.filter(isInstabookEvent), [events]);

  const { groupedEvents } = useMemo(() => {
    const groupedByDay = groupByDay(filteredEvents);
    const groupedByBoat: Record<string, Record<string, GenericEvent[]>> = {};
    Object.keys(groupedByDay)
      .sort()
      .forEach((day) => {
        groupedByBoat[day] = groupByBoat(sortInstabookEventsByTime(groupedByDay[day]));
      });
    return {
      groupedEvents: groupedByBoat,
    };
  }, [filteredEvents]);

  const monthHasEvents = filteredEvents.length > 0;
  const startDateAsDate = useMemo(() => newTZDate(startDate), [startDate]);
  const days = useMonthRangeDates(startDateAsDate);

  const { selectedDate, scrollToEvent } = useCalendarFocus();

  const ref = useScrollingMonthUpdate<HTMLDListElement>(startDate);

  const monthBooleans = getPastPresentFutureBooleans(startDateAsDate, today);
  return (
    <dl
      ref={ref}
      className={classNames(
        getClassNameFor(
          s,
          'root',
          classNames({
            past: monthBooleans.isPast || monthBooleans.isPresent,
          }),
        ),
        {
          futureMonth: monthBooleans.isFuture,
          pastMonth: monthBooleans.isPast,
          currentMonth: monthBooleans.isPresent,
        },
      )}
    >
      <MonthTitle date={startDateAsDate} />
      {days.map((date) => {
        const isPast = today > date;
        const isPresentOrFuture = today <= date;
        const day = toDateString(date);
        const isSelectedDate = selectedDate === day;
        const daysEvents = groupedEvents[day] ?? {};
        const boatIds = Object.keys(daysEvents);
        const hasEvents = Object.keys(daysEvents).length > 0;
        return (
          <div
            key={day}
            className={getClassNameFor(
              s,
              'day',
              classNames({
                past: isPast,
                future: isPresentOrFuture,
              }),
            )}
          >
            <DayCard
              day={date}
              hasEvents={hasEvents}
            >
              {boatIds.map((boatId) => {
                const boat = boatsIndexedById[boatId];
                return (
                  <dd
                    key={boatId}
                    className={s.boatGroup}
                    onClick={() => setSelectedInstabookDate(date)}
                    onKeyDown={() => setSelectedInstabookDate(date)}
                    role="presentation"
                  >
                    <InstabookListingHeader boat={boat} />
                    <div className={s.boatContent}>
                      {daysEvents[boatId].map((item) => (
                        isCalendarEvent(item) || isUnavailableEvent(item) ? (
                          <MiniEvent
                            event={item}
                            key={`calendar-${item.id}-${
                              item.thread_id ?? item.adhoc_calendar_event_id
                            }`}
                          />
                        ) : (
                          <Fragment key={`instabook-${item.id}`}>
                            {isSelectedDate && scrollToEvent && (
                              <ScrollToMe
                                key={`scroll-to-event-${day}-${item.id}`}
                                classNameModifier="calendarEventAnchor"
                              />
                            )}
                            <div className={s.calendarCard}>
                              <InstabookCalendarCard
                                event={item}
                                boat={boat}
                              />
                            </div>
                          </Fragment>
                        )))}
                    </div>
                  </dd>
                );
              })}
            </DayCard>
          </div>
        );
      })}
      {!monthHasEvents && <NoEvents date={startDateAsDate} />}
    </dl>
  );
};

export default InstabookMonthAgendaView;
