import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import { fetchCatch } from 'src/core/sentry';
import type { ReduxState } from '../../types/reduxState';
import { apiFetchThunk } from './fetch';

export const SET_COUNTRY_CODE = 'common/currency/SET_COUNTRY_CODE';

export const setCountryCode = (countryCode: string) => ({ type: SET_COUNTRY_CODE, countryCode });

type CountryCodeAction = ReturnType<typeof setCountryCode>;

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (countryCode = '', action: CountryCodeAction) => {
  switch (action.type) {
    case SET_COUNTRY_CODE:
      return action.countryCode;
    default:
      return countryCode;
  }
};

type GetCountryCodeCallThunk = ThunkAction<Promise<void>, ReduxState, {}, Action>;

export const getCountryCodeCall = (): GetCountryCodeCallThunk => async (dispatch) => {
  const url = '/auth/location/';
  await dispatch(apiFetchThunk(url))
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .then((data: { country_code: string }) => {
      dispatch(setCountryCode(data.country_code));
    })
    .catch(fetchCatch);
};
