import React, { useRef } from 'react';

import { INSTABOOK_CAPTAIN_PRICE_FIELD } from 'src/calendar/constants';
import Card from '../presentation/Card';
import PriceItem from '../PriceItem';
import styles from './PriceEditor.module.scss';

type PriceEditorProps = {
  amountFieldRef?: React.MutableRefObject<HTMLInputElement>;
  currencySymbol: string;
  captainCostEnabled?: boolean;
};

const PriceEditor: React.FC<PriceEditorProps> = ({
  amountFieldRef,
  currencySymbol,
  captainCostEnabled,
}: PriceEditorProps) => {
  const captainAmountFieldRef = useRef<HTMLInputElement>();

  return (
    <Card>
      <div className={styles.root}>
        <PriceItem
          amountFieldRef={amountFieldRef}
          currencySymbol={currencySymbol}

        >
          <strong className={styles.label}>
            Base Cost
          </strong>
        </PriceItem>

        {captainCostEnabled && (
          <PriceItem
            amountFieldRef={captainAmountFieldRef}
            currencySymbol={currencySymbol}
            fieldName={INSTABOOK_CAPTAIN_PRICE_FIELD}
          >
            <strong className={styles.label}>
              Captain Cost
            </strong>
          </PriceItem>
        )}
      </div>
    </Card>
  );
};

export default PriceEditor;
