import React, { FC } from 'react';
import flagIcons from './flag-icons.svg';

export const countryCodes = [
  'AE',
  'AR',
  'AU',
  'BR',
  'CA',
  'CH',
  'CN',
  'CR',
  'DK',
  'EU',
  'GB',
  'HK',
  'HR',
  'ID',
  'IL',
  'IN',
  'IS',
  'JP',
  'KE',
  'KR',
  'MA',
  'MX',
  'MY',
  'NO',
  'NZ',
  'PE',
  'PH',
  'PL',
  'RU',
  'SE',
  'SG',
  'TH',
  'TR',
  'TW',
  'US',
  'VN',
  'ZA',
];
export type FlagIconsId = typeof countryCodes[number];

export type Props = {
  id: string;
  className?: string;
  alt?: string;
};

const FlagIcon: FC<Props> = ({
  id,
  className,
  alt,
}) => (
  <svg
    className={className}
  >
    {alt && <title>{alt}</title>}
    <use
      xlinkHref={`${flagIcons}#${id}`}
      aria-hidden="true"
    />
  </svg>
);

export default FlagIcon;
