import React, { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { getBoat, getInquiryDetails, getInstabookListings, getNextInquiryPage } from 'src/common/utils/reduxStoreSelectors';
import Icon from 'src/common/components/IconDS22';
import Carousel from 'src/common/components/Carousel';
import { CarouselProvider } from 'src/common/utils/carouselProvider';
import CarouselButtons from 'src/common/components/CarouselButtons';
import ListingCard from 'src/common/components/ListingCard';
import CloseButton from 'src/common/components/CloseButton';
import { EXTERNAL_PATHS } from 'src/common/constants';
import { SearchQueryParams } from 'src/common/types/SearchQuery';
import { totalGuests } from 'src/common/components/GroupSizeDetails';
import { ImmutableGuests } from 'src/types/trips/Trips';
import Button from 'src/common/components/Button';
import UniversalLink from 'src/common/components/UniversalLink';
import useOnSubmitLocationChange from 'src/common/hooks/useOnSubmitLocationChange';

import styles from './InstabookListings.module.scss';

const InstabookListings: FC = () => {
  const inquiry = useSelector(getInquiryDetails);
  const boat = useSelector(getBoat);
  const listings = useSelector(getInstabookListings);
  const nextPage = useSelector(getNextInquiryPage);
  const params = useMemo(() => new URLSearchParams({
    instabooks: 'true',
    dates: [
      inquiry.get('preferred_date1'),
    ],
    clat: boat.getIn(['location', 'lat']),
    clon: boat.getIn(['location', 'lon']),
    capacity: totalGuests(inquiry as ImmutableGuests).toString(),
    captained: inquiry.get('captain_required') ? 'true' : 'false',
    min_price: boat.get('prices').get(0).get('minimum')?.toString(),
    page: '1',
  } as SearchQueryParams as Record<string, string>), [inquiry, boat]);

  const handleLocationChange = useOnSubmitLocationChange(nextPage);

  return (
    <div className={styles.root}>
      <Helmet>
        <title>
          Instabook Listings
        </title>
      </Helmet>
      <div className={styles.header}>
        <h1 className={styles.title}>
          Book Now
          <Icon id="instabook-filled" size="s" />
        </h1>
      </div>
      <CarouselProvider>
        <div className={styles.carouselWrapper}>
          <div className={styles.carouselHeader}>
            <p className={styles.carouselHeaderCopy}>
              Boat Rentals Available for Instant Booking.
              {' '}
              <UniversalLink
                className={styles.viewAll}
                openInNewTab
                to={`${EXTERNAL_PATHS.SEARCH}?${params.toString()}`}
              >
                View All
              </UniversalLink>
            </p>
            <CarouselButtons
              spacing="together"
              classNameModifier="primary-white"
              invisibleIfBothDisabled
            />
          </div>
          <Carousel>
            {listings.map((listing) => (
              <ListingCard
                key={listing.id}
                listingUrl={listing.listing_url}
                headline={listing.headline}
                breadcrumbs={listing.breadcrumbs}
                category={listing.cat_loc_breadcrumb}
                rating={listing.rating}
                awards={listing.awards}
                isSuperOwner={listing.is_super_owner}
                instantBookingAvailable
                searchPricing={listing.search_price_nested}
                imageSrc={listing.photo_url ?? ''}
                capacity={listing.capacity.toString()}
                classNameModifier="instabook"
                openInNewTab
              />
            ))}
          </Carousel>
        </div>
        <div className={styles.skip}>
          <Button
            type="button"
            onClick={handleLocationChange}
            fullWidth
          >
            Skip
          </Button>
        </div>
      </CarouselProvider>
      <CloseButton backUrl="/" />
    </div>
  );
};

export default InstabookListings;
