import React, { FC } from 'react';
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { EXTERNAL_PATHS } from 'src/common/constants';
import { getIsLoggedIn } from '../common/ducks/user';
import { PAGES } from './constants';
import Root from './components/Root';
import PageNotFound from '../common/components/NotFoundContent';
import ContactPage from './components/pages/ContactPage';
import PasswordPage from './components/pages/PasswordPage';
import RedirectToPaymentPage from './components/pages/RedirectToPaymentPage';

type InstabookRoutesProps = {};

export const InstabookRoutes: FC<InstabookRoutesProps> = () => {
  const { url } = useRouteMatch();
  const { search } = useLocation();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const { boatId } = useParams<{ boatId: string }>();
  return (
    <Switch>
      {/* Redirecting user to new pages */}
      <Route exact path={`${url}/${PAGES.TRIPS}/`}>
        <Redirect to={`${EXTERNAL_PATHS.LISTING}${boatId}/`} />
      </Route>
      <Route
        exact
        path={`${url}/${PAGES.DETAILS}/:instabookId/`}
        render={(renderProps) => {
          const { instabookId } = renderProps.match.params;
          return <Redirect to={`${EXTERNAL_PATHS.BOOK_NOW}${boatId}/${instabookId}`} />;
        }}
      />

      <Route exact path={`${url}/${PAGES.CONTACT}/`}>
        <Root variant="modal">
          <ContactPage />
        </Root>
      </Route>
      <Route
        path={`${url}/${PAGES.PASSWORD}/`}
        render={renderProps => (
          !isLoggedIn ? (
            <Root variant="modal">
              <PasswordPage {...renderProps} />
            </Root>
          ) : (
            <Redirect to={`${url}/${PAGES.CONTACT}/${search}`} />
          ))}
      />
      <Route exact path={`${url}/${PAGES.REDIRECT}/`}>
        <Root variant="modal">
          <RedirectToPaymentPage />
        </Root>
      </Route>
      <Route
        render={renderProps => (
          <Root variant="full">
            <PageNotFound
              {...renderProps}
              classNameModifier="modal"
            />
          </Root>
        )}
      />
    </Switch>
  );
};

export default InstabookRoutes;
