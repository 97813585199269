import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { ARCHIVE_TRIP, UNARCHIVE_TRIP } from '../TripMenu/actions';
import { CTAButton } from '../CTA';

const ArchiveButton = ({
  archived,
  children,
  dispatch,
  trip,
}) => (
  <Route>
    {({ history, location }) => (
      <CTAButton
        classNameModifier="gray"
        onClick={() => (archived
          ? UNARCHIVE_TRIP.onClick(dispatch, trip, { history, location })
          : ARCHIVE_TRIP.onClick(dispatch, trip, { history, location })
        )}
      >
        {children}
      </CTAButton>
    )}
  </Route>
);

ArchiveButton.propTypes = {
  archived: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  trip: PropTypes.object.isRequired,
};

export default connect()(ArchiveButton);
