import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies, { CookieAttributes } from 'js-cookie';
import { useLocation } from 'react-router-dom';

import { COOKIE_POLICY_DATE, EXTERNAL_PATHS } from '../../constants';
import { open, close } from '../../ducks/zippy';
import CookieContent from '../CookieContent';
import ComplianceUpdates from '../ComplianceUpdates';
import { ReduxState } from '../../../types/reduxState';

const cookieName = `${COOKIE_POLICY_DATE}-cookie-policy-acknowledged`;
const cookieAttributes: CookieAttributes = {
  expires: 365 * 10,
  path: '/',
  sameSite: 'Lax',
};

const complianceModalBlacklist = [
  EXTERNAL_PATHS.TERMS,
  EXTERNAL_PATHS.COOKIES_POLICY,
  EXTERNAL_PATHS.PRIVACY_POLICY,
  EXTERNAL_PATHS.MEMBER_INTERFACE,
];

const GdprCompliance: FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const loggedIn = useSelector<ReduxState, boolean | null>(
    ({ user }) => user.get('loggedIn') ?? null,
  );

  const termsAgreed = useSelector<ReduxState, boolean | undefined>(
    ({ user }) => user.get('terms_agreed', false),
  );

  const cookieBannerOpened = useSelector<ReduxState, boolean>(
    ({ zippies }) => zippies.get(cookieName, false),
  );

  const renderModal = loggedIn && !termsAgreed && !complianceModalBlacklist.includes(pathname);
  const renderCookieBanner = (loggedIn !== null && !loggedIn) || !termsAgreed;

  const closePopup = useCallback(() => {
    dispatch(close(cookieName));
    Cookies.set(cookieName, 'true', cookieAttributes);
  }, [dispatch]);

  useEffect(() => {
    if (loggedIn !== null && !loggedIn && !cookieBannerOpened) {
      const cookieIsSet = Cookies.get(cookieName);
      if (!cookieIsSet) {
        dispatch(open(cookieName));
      }
    }
  }, [loggedIn, cookieBannerOpened, dispatch]);

  return (
    <>
      {renderModal && <ComplianceUpdates />}
      {cookieBannerOpened && renderCookieBanner && (
        <CookieContent handleClose={closePopup} />
      )}
    </>
  );
};

export default GdprCompliance;
