import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import Dropdown from '../../../../common/components/Dropdown';
import RatingInput from '../../../../common/components/inputs/RatingInput';
import TextArea from '../../../../common/components/inputs/TextArea';
import s from '../ReviewStep.module.scss';

const Category = ({
  category: { name, title },
  ownerName,
}) => (
  <Dropdown
    key={name}
    className={s.category}
    disableOnClickOutside
  >
    {({ toggle, isOpen }) => (
      <>
        <h5 className={s.categoryHeading}>
          {title}
        </h5>
        <Field
          name={name}
          component={RatingInput}
        />
        {isOpen && (
          <Field
            component={TextArea}
            placeholder={`Add private feedback for ${ownerName}`}
            classNameModifier="rating"
            name={`${name}_comment`}
          />
        )}
        <button
          type="button"
          onClick={toggle}
          className={s.categoryDetails}
        >
          {isOpen ? 'Close private feedback' : 'Add private feedback'}
        </button>
      </>
    )}
  </Dropdown>
);

Category.propTypes = {
  category: PropTypes.object.isRequired,
  ownerName: PropTypes.string.isRequired,
};

export default Category;
