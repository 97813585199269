import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import { HotKeys } from 'react-hotkeys';

import classNames from 'classnames';
import { Location } from 'history';
import { getLoading, getTrips } from 'src/common/utils/reduxStoreSelectors';
import { getClassNameFor } from '../../../common/helpers';
import { PATHS } from '../../../common/constants';
import Dropdown from '../../../common/components/Dropdown';
import FormError from '../../../common/components/FormError';
import ToggleButton from '../../../common/components/ToggleButton';
import s from './TripsFilter.module.scss';

const filters = [
  {
    title: 'All Active',
    action: 'show_all',
    message: 'You have no inquiries.',
  },
  {
    title: 'Upcoming Trip Dates',
    action: 'upcoming',
    message: 'You have no upcoming trips.',
  },
  {
    title: 'Unread',
    action: 'unread',
    message: 'You have no unread inquiries.',
  },
  {
    title: 'Waiting for Offer',
    action: 'waiting_offer',
    message: 'You have no inquiries waiting for an offer.',
  },
  {
    title: 'Open Offers',
    action: 'open_offers',
    message: 'You have no open offers.',
  },
  {
    title: 'Confirmed Bookings ',
    action: 'confirmed',
    message: 'You have no confirmed bookings.',
  },
  {
    title: 'Completed Bookings',
    action: 'completed',
    message: 'You have no completed bookings.',
  },
  {
    title: 'Archive',
    action: 'archived',
    message: 'You have no archived inquiries.',
  },
];

const TripsFilter: React.FC = () => {
  const { status: loading, error } = useSelector(getLoading).toJS();
  const location = useLocation<Location>();
  const trips = useSelector(getTrips).get('results');
  const queryParams = location && parse(location.search);
  const activeFilter = queryParams && queryParams.filter;
  const activeFilterInfo = filters.find(filter => filter.action === activeFilter) || filters[0];
  const emptyMessage = trips.size <= 0;

  return (
    <Dropdown className={s.root}>
      {({ toggle, isOpen, close }) => (
        <>
          <div className={getClassNameFor(s, 'menu', classNames({ open: isOpen }))}>
            <HotKeys
              handlers={{ toggle: event => { event?.preventDefault(); toggle(); } }}
              keyMap={{ toggle: 'space' }}
            >
              <ToggleButton
                isOpen={isOpen}
                onClick={toggle}
              >
                {activeFilterInfo.title}
              </ToggleButton>
            </HotKeys>
            {isOpen && (
              <ul className={getClassNameFor(s, 'content', classNames({ open: isOpen }))}>
                {filters.map(filter => (
                  <li
                    key={filter.action}
                    className={s.item}
                  >
                    {/* Filter links use NavLinks as filtering is done server-side and the
                                and the current trip may disappear, so it returns the user to
                                the dashboard */}
                    <Link
                      to={{
                        pathname: PATHS.INBOX,
                        search: stringify({ filter: filter.action }),
                      }}
                      className={getClassNameFor(s, 'trigger', classNames({ active: filter.action === activeFilter }))}
                      onClick={close}
                    >
                      <span className={s.text}>
                        {filter.title}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          {!loading && emptyMessage && (
            <div className={s.emptyMessage}>
              {error
                ? <FormError error={error} className={s.error} />
                : activeFilterInfo.message}
            </div>
          )}
        </>
      )}
    </Dropdown>
  );
};

export default TripsFilter;
