import { PATHS } from '../constants';

const paths = Object.values(PATHS);
/**
 * Returns true if the path is an internal route that should be handled by react-router
 */
const isInternalLink = (path: string): boolean => (
  paths.includes(path)
  || paths.some((p) => path.startsWith(p))
);

export default isInternalLink;
