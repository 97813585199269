import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';

import { Price } from 'src/types/boat/Price';
import type { ReduxState } from '../../../../types/reduxState';
import apiFetch from '../../../../core/fetch';
import { trackEvent } from '../../../../common/tracking';
import { getClassNameFor, plural } from '../../../../common/helpers';
import { getBoat } from '../../../../common/utils/reduxStoreSelectors';
import { rffTripLengthHourValidator as validateHourly } from '../../../../common/validation';
import { rffTripLengthDayNightValidator as validateMultiDay } from '../../../../common/validation';
import { PAGES } from '../../../constants';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import useOnSubmitLocationChange from '../../../../common/hooks/useOnSubmitLocationChange';
import { setInquiryMismatch, deleteInquiryMismatch, updateInquiry } from '../../../ducks/inquiry';
import Button from '../../../../common/components/Button';
import FormError from '../../../../common/components/FormError';
import InPageWarning from '../../InPageWarning';
import DurationFieldset from '../../../../common/components/fieldsets/Duration';
import CloseButton from '../../../../common/components/CloseButton';
import { BackButton } from '../../../../common/components/BackButton';
import s from './Duration.module.scss';

const GA4_EVENT_SEND_INQUIRY_DURATION = 'send_inquiry_s2_duration';

const getDurationMismatch = ({ inquiry }: ReduxState) => inquiry.getIn(['mismatches', 'duration']);

type TripLengthValues = {
  days: number;
  hours: number;
  minutes: number;
};

export const getTripLengthInitialValues = ({ inquiry }: ReduxState): TripLengthValues => {
  // Getting a 0 in the event of no trip length will
  // mean a zero duration, which will correctly render
  // 0 for days, hours, minutes.
  const tripLength = inquiry.getIn(['details', 'trip_length'], 0);
  const tripDuration = moment.duration(tripLength);

  return {
    days: Math.floor(tripDuration.asDays()),
    hours: tripDuration.hours(),
    minutes: tripDuration.minutes(),
  };
};
const checkDuration = (boatId: string, tripLength: string) => (
  apiFetch(`/boats/${boatId}/duration/?duration=${tripLength}`)
);

// TODO: duration => trip_length to match the rest of the app
export const TripLength: FC = () => {
  const location = useLocation();
  const { goBack } = useHistory();
  const dispatch = useDispatch();
  const returnUrl = getReturnUrl(location);
  const boat = useSelector(getBoat);
  const initialValues = useSelector(getTripLengthInitialValues);
  const mismatch = useSelector(getDurationMismatch);

  const prices: Price[] = boat.get('prices').toJS();
  const hasHourlyPrices = prices && prices.some((price) => price.price_unit === 'hour');
  const hasMultiDayNightPrices = prices && prices.some((price) => (price.price_unit === 'night' || price.price_unit === 'day'));
  const isDay = hasMultiDayNightPrices && prices.some((price) => price.price_unit === 'day');
  const multiDayLabel = hasMultiDayNightPrices && isDay ? 'Days' : 'Nights';
  const minHourlyDuration = hasHourlyPrices
    ? prices.filter((price) => price.price_unit === 'hour').sort()[0].min_unit_quantity
    : undefined;
  const minMultiDayDuration = hasMultiDayNightPrices
    ? prices.filter((price) => (price.price_unit === 'day' || price.price_unit === 'night')).sort()[0].min_unit_quantity
    : undefined;

  const showHours = !!hasHourlyPrices;
  const showMultiDay = !!hasMultiDayNightPrices;

  let validate = validateHourly;
  if (!showHours && showMultiDay) {
    validate = validateMultiDay(isDay);
  }

  const onSubmitSuccess = useOnSubmitLocationChange(PAGES.DATES);

  const onSubmit = useCallback(
    (values, { initialize, getState }) => {
      const tripLengthString = moment.duration(values).toJSON();
      const { pristine } = getState();
      dispatch(updateInquiry({ trip_length: tripLengthString }));
      const { days, hours, minutes } = values;
      const p1 = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
      const p2 = `${days} night${plural(days)}`;
      trackEvent(GA4_EVENT_SEND_INQUIRY_DURATION, {
        p1,
        p2,
      });
      if (pristine
        // If there's a mismatch, this means the user has decided to click
        // "continue anyway" and we want to short-circuit.
        && mismatch) {
        trackEvent('Continue Past SBI Warning', {
          event_category: 'Booking Inquiry',
          event_label: 'for Duration',
        });
        return onSubmitSuccess();
      }

      type DurationError = {
        detail: string;
      };
      return checkDuration(boat.get('id'), tripLengthString)
        .then(response => {
          if (!response.ok) {
            initialize(values);
            return response.json().then(({ detail }: DurationError) => {
              dispatch(setInquiryMismatch({ duration: detail }));
            });
          }

          if (
            days
            && minMultiDayDuration
            && days < minMultiDayDuration) {
            dispatch(setInquiryMismatch({
              duration: `The listing you selected has an hourly minimum of ${minMultiDayDuration} ${isDay ? 'days' : 'nights'}.`,
            }));
            return null;
          }

          if (hours && minHourlyDuration && hours < minHourlyDuration) {
            dispatch(setInquiryMismatch({
              duration: `The listing you selected has an hourly minimum of ${minHourlyDuration} hours.`,
            }));
            return null;
          }

          dispatch(deleteInquiryMismatch('duration'));
          return onSubmitSuccess();
        });
    },
    [
      dispatch,
      mismatch,
      boat,
      onSubmitSuccess,
      minMultiDayDuration,
      minHourlyDuration,
      isDay],
  );
  const title = 'Duration';

  const formattedMinMultiDayDuration = (duration: number | undefined, hasDay: boolean): string => {
    if (!duration) return '';

    const period = hasDay ? 'day' : 'night';
    return `${duration} ${duration > 1 ? `${period}s` : period} min`;
  };

  return (
    <div className={s.container}>
      <Helmet>
        <title>
          {title}
        </title>
      </Helmet>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
      >
        {({ handleSubmit, pristine, submitting, error, touched }) => {
          const showWarning = mismatch && pristine && !submitting;
          const anyTouched = !!touched && Object.values(touched).some(x => x);
          return (
            <form
              onSubmit={handleSubmit}
              className={getClassNameFor(s, 'form', 'inquiry')}
            >
              <h1 className={s.title}>
                {title}
              </h1>
              <div className={s.message}>
                <p>
                  How long do you want your trip or rental to be?
                </p>
              </div>
              <DurationFieldset
                classNameModifier="inquiry"
                showHours={showHours}
                showMultiDays={showMultiDay}
                showMinutes={false}
                multiDayLabel={multiDayLabel}
                multiDayHeader="Multi-day"
                minHourlyDuration={`${minHourlyDuration} ${minHourlyDuration && minHourlyDuration > 1 ? 'hours' : 'hour'} min`}
                minMultiDayDuration={formattedMinMultiDayDuration(minMultiDayDuration, isDay)}
              />
              {anyTouched && (
                <FormError error={error} />
              )}
              {showWarning && <InPageWarning message={mismatch} />}
              <div className={s.durationButton}>
                <Button
                  type="submit"
                  submitting={submitting}
                  data-test="next"
                  fullWidth
                >
                  {showWarning ? 'Continue Anyway' : 'Next'}
                </Button>
              </div>
            </form>
          );
        }}
      </Form>
      <BackButton onClick={goBack} />
      <CloseButton backUrl={returnUrl} />
    </div>
  );
};

export default TripLength;
