import { USER_ROLES } from '../../../../common/constants';

const { OWNER, RENTER } = USER_ROLES;
const content = {
  [OWNER]: {
    title: 'Do you have any photos from the trip?',
    text: 'Send your images from the trip to help share the experience and promote your listing.',
  },
  [RENTER]: {
    title: 'Do you have any photos to add to your review?',
    text: 'Share your photos to show what your experience was like.',
  },
};

export default content;
