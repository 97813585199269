import React from 'react';
import { useSelector } from 'react-redux';

import type { ImmutableTrip } from 'src/types/trips/Trips';
import Modal from 'src/common/components/Modal';
import { getOffer } from 'src/common/utils/reduxStoreSelectors';
import { FORMS, PICKUP_TIME_FIELD, RETURN_TIME_FIELD, TIME_FIELDS } from 'src/inbox/constants';
import { offerModified } from 'src/inbox/helpers';
import { offerTimeSelector } from './selectors';
import TimeForm from './form';

type TimeModalProps = {
  trip: ImmutableTrip;
};

const TimeModal: React.FC<TimeModalProps> = ({ trip }) => {
  const offer = useSelector(getOffer);
  const {
    tripLengthMoment,
    isMultiDay,
    pickupTime,
    timeFormatString,
    returnTime,
  } = offerTimeSelector({ offer, trip });
  const offerIsModified = offerModified(offer, trip, TIME_FIELDS);

  return (
    <Modal
      name={FORMS.TIME}
      label="Time Form"
    >
      <TimeForm
        isMultiDay={isMultiDay}
        modified={offerIsModified}
        initialValues={{
          [PICKUP_TIME_FIELD]: pickupTime,
          [RETURN_TIME_FIELD]: returnTime,
        }}
        timeFormat={timeFormatString}
        tripLengthMoment={tripLengthMoment}
      />
    </Modal>
  );
};

export default TimeModal;
