import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { useForm, useFormState } from 'react-final-form';

import Icon from 'src/common/components/IconDS22';
import { getClassNameFor } from '../../../common/helpers';
import Dropdown from '../../../common/components/Dropdown';

import s from './InstabookOptions.module.scss';
import { useInstabookEditorContext } from '../../hooks/useInstabookEditor';
import { CTAButton } from '../../../inbox/components/CTA';

export type InstabookOptionsProps = {
  closeModal: () => void;
};

const InstabookOptions: FC<InstabookOptionsProps> = ({
  closeModal,
}) => {
  const { reset } = useForm();
  const { pristine } = useFormState();
  const {
    instabook,
    deleteInstabook,
  } = useInstabookEditorContext();

  const onDeleteInstabook = useCallback(() => {
    deleteInstabook().then(() => closeModal());
  }, [closeModal, deleteInstabook]);

  return (
    <Dropdown className={s.root}>
      {({ toggle, isOpen }) => (
        <>
          <CTAButton
            type="button"
            onClick={toggle}
            classNameModifier="gray options secondary"
          >
            Options
            <Icon
              id="caret-up"
            />
          </CTAButton>
          {isOpen && (
            <ul className={s.content}>
              <li className={s.item}>
                <button
                  type="button"
                  className={getClassNameFor(s, 'action', '')}
                  onClick={closeModal}
                >
                  <Icon
                    id="x-circle"
                    size="l"
                    className={s.icon}
                  />
                  <span>{pristine ? 'Close' : 'Close and Discard Changes'}</span>
                </button>
              </li>
              <li className={s.item}>
                <button
                  type="reset"
                  className={getClassNameFor(s, 'action', classNames({ disabled: pristine }))}
                  onClick={() => {
                    reset();
                    toggle();
                  }}
                >
                  <Icon
                    id="minus-circle"
                    size="l"
                    className={s.icon}
                  />
                  <span>Clear Changes</span>
                </button>
              </li>
              <li className={s.item}>
                <button
                  type="button"
                  className={getClassNameFor(s, 'action', classNames({ disabled: !instabook?.id }))}
                  onClick={onDeleteInstabook}
                >
                  <Icon
                    id="trash"
                    size="l"
                    className={s.icon}
                  />
                  <span>Delete Trip</span>
                </button>
              </li>
            </ul>
          )}
        </>
      )}
    </Dropdown>
  );
};

export default InstabookOptions;
