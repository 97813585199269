import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import { withSuffix } from 'src/common/utils/dataAttributeHelpers';
import { getClassNameFor, isMobile } from '../../helpers';
import UniversalLink from '../UniversalLink';

import s from './Button.module.scss';

const noop = () => { };

type BaseButtonProps = {
  value?: any;
  onClick?: () => void;
  autoFocus?: boolean;
  submitting?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  iconOnly?: boolean;
  className?: string;
  classNameModifier?: string;
  'data-test'?: string;
};

type ButtonWithTypeProps = BaseButtonProps & {
  type: 'button' | 'submit' | 'reset';
  href?: never;
};

type ButtonWithHrefProps = BaseButtonProps & {
  href: string;
  type?: never;
};

export type ButtonProps = ButtonWithTypeProps | ButtonWithHrefProps;

const Button: FC<ButtonProps> = ({
  children,
  type,
  value,
  onClick = noop,
  autoFocus,
  submitting,
  disabled,
  fullWidth,
  iconOnly,
  className: classNameExternal,
  classNameModifier,
  href,
  'data-test': dataTest,
}) => {
  const className = useMemo(() => getClassNameFor(s, 'root', classNames(
    classNameModifier,
    {
      wait: submitting,
      fullWidth,
      iconOnly,
      rounded: iconOnly,
      square: iconOnly && classNameModifier?.includes('tertiary'), // only IconButton tertiary can be square
    },
  )), [classNameModifier, submitting, fullWidth, iconOnly]);

  let buttonContent;
  if (submitting && classNameModifier === 'trip message') {
    buttonContent = '';
  } else if (submitting) {
    buttonContent = 'Please Wait...';
  } else {
    buttonContent = children;
  }

  if (href) {
    return (
      <UniversalLink
        to={href}
        className={`${className} ${classNameExternal}`}
        data-test={withSuffix(dataTest, 'button')}
      >
        {buttonContent}
      </UniversalLink>
    );
  }

  return (
    <button
      // The type is provided by the caller.
      // eslint-disable-next-line react/button-has-type
      type={type}
      value={value}
      onClick={onClick}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={!isMobile() && autoFocus}
      className={`${className} ${classNameExternal}`}
      disabled={disabled || submitting}
      data-test={withSuffix(dataTest, 'button')}
    >
      {buttonContent}
    </button>
  );
};

export default Button;
