import React from 'react';
import PropTypes from 'prop-types';
import { HotKeys } from 'react-hotkeys';
import { useLocation, useHistory } from 'react-router-dom';
import { Field } from 'react-final-form';

import { Map } from 'immutable';
import { TITLE } from '../../../../common/constants';
import { getReviewUrl, isNegativeReview } from '../../../helpers';
import TextArea from '../../../../common/components/inputs/TextArea';
import TripPanel from '../../presentation/TripPanel';
import CTA, { CTABackLink, CTALink } from '../../CTA';
import Card from '../../presentation/Card';
import { REVIEW_PAGES, FIELDS } from '../../pages/Complete/reviewsConfig';
import s from '../ReviewStep.module.scss';

const { GETMYBOAT_REVIEW } = FIELDS;

const Feedback = ({
  trip,
  role,
  initialTripLocation,
  reviews,
  values: {
    [GETMYBOAT_REVIEW]: getmyboatReview,
  },
  progressBar,
}) => {
  const { push } = useHistory();
  const location = useLocation();
  const review = reviews.getIn(['boat', 'results']).first() || Map({});
  const rating = review.get('rating');
  const backUrl = (isNegativeReview(rating) || !review) ? initialTripLocation : getReviewUrl(REVIEW_PAGES.FEEDBACK, role, 'back', location);
  const nextUrl = getReviewUrl(REVIEW_PAGES.FEEDBACK, role, 'next', location);

  const goToNextPage = () => push(nextUrl);
  const cta = (
    <CTA classNameModifier="withSidebar">
      <CTABackLink to={backUrl} />
      <CTALink to={nextUrl}>
        {getmyboatReview ? 'Next' : 'Skip'}
      </CTALink>
    </CTA>
  );

  return (
    <TripPanel
      trip={trip}
      subheader={TITLE.REVIEW}
      cta={cta}
      backLocation={backUrl}
    >
      <Card classNameModifier="review">
        {progressBar}
        <h3 className={s.title}>
          Thanks for your review.
          <br />
          How did we do?
        </h3>
        <h4 className={s.subTitle}>
          Do you have any feedback for the Getmyboat team about the service or using the apps?
        </h4>
        <HotKeys
          handlers={{ next: goToNextPage }}
          keyMap={{ next: ['ctrl+enter', 'meta+enter'] }}
        >
          <Field
            autoFocus
            placeholder="Your feedback about Getmyboat."
            name={GETMYBOAT_REVIEW}
            component={TextArea}
          />
        </HotKeys>
      </Card>
    </TripPanel>
  );
};

Feedback.propTypes = {
  trip: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  values: PropTypes.object,
  progressBar: PropTypes.node,
  initialTripLocation: PropTypes.object,
  reviews: PropTypes.object,
};

export default Feedback;
