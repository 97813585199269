import React, { FC } from 'react';
import { Field } from 'react-final-form';

import Svg from '../../../common/components/Svg';
import {
  INSTABOOK_EDITOR_TRIP_DURATION_FIELD,
  INSTABOOK_MAX_DURATION,
  INSTABOOK_MIN_DURATION,
} from '../../constants';
import NumberAddInput from '../../../common/components/inputs/NumberAddInput';

import s from './InstabookTripDuration.module.scss';

type Props = {
  disabled: boolean;
};

const InstabookTripDuration: FC<Props> = ({ disabled }) => (
  <div className={s.root}>
    <label
      htmlFor={INSTABOOK_EDITOR_TRIP_DURATION_FIELD}
      className={s.label}
    >
      <Svg
        href="#trip-duration"
        className={s.icon}
      />
      Trip duration
    </label>
    <div className={s.input}>
      <Field
        disabled={disabled}
        component={NumberAddInput}
        name={INSTABOOK_EDITOR_TRIP_DURATION_FIELD}
        label="Hours"
        minValue={INSTABOOK_MIN_DURATION}
        maxValue={INSTABOOK_MAX_DURATION}
        classNameModifier="instabook smallButtons"
      />
    </div>
    <span className={s.info}>
      {`${INSTABOOK_MAX_DURATION} hours maximum`}
    </span>
  </div>
);

export default InstabookTripDuration;
