import React, { FC, MutableRefObject, useCallback } from 'react';
import { Field, useField } from 'react-final-form';
import { FieldValidator } from 'final-form';
import classNames from 'classnames';

import { getClassNameFor } from '../../../common/helpers';
import Input from '../../../common/components/inputs/Input';
import s from './AmountField.module.scss';

const SUBTOTAL_MAX_LENGTH = 9;
const parse = (value: string): number => Math.abs(Number(value.slice(0, SUBTOTAL_MAX_LENGTH)));
const format = (value: number): string => value?.toString();

type ClassNameModifierProp =
  | 'instabookPrice';

const AmountField: FC<Props> = ({
  classNameModifier = '',
  fieldName = 'subtotal',
  readOnly,
  currencySymbol = '$',
  amountFieldRef,
  shouldBeTouchedToShowError,
  priceError,
  withFieldLevelValidation = true,
}) => {
  const { input: { value: fieldValue }, meta: { error, touched } } = useField(fieldName);
  const modifiers = classNames('amount priceInput', classNameModifier, {
    error: error && touched,
  });
  const style = { width: `${Math.max(1, String(fieldValue).length)}ch` };

  const validate: FieldValidator<number> = useCallback(
    (value, _, meta): string | undefined => {
      if (withFieldLevelValidation && meta?.visited) {
        const message = 'Please provide the total cost for the duration of the trip.';
        const maybeNaN = amountFieldRef?.current?.valueAsNumber;
        if (
          (maybeNaN != null && Number.isNaN(maybeNaN))
          || value <= 0
          || !Number.isInteger(value)
        ) {
          return message;
        }
      }
      return undefined;
    },
    [amountFieldRef, withFieldLevelValidation],
  );

  return (
    <div className={getClassNameFor(s, 'root', modifiers)}>
      <span>
        {currencySymbol}
      </span>
      <Field
        name={fieldName}
        type="number"
        min={1}
        placeholder="0"
        component={Input}
        defaultValue={0}
        disabled={readOnly}
        parse={parse}
        format={format}
        validate={validate}
        classNameModifier={modifiers}
        fieldRef={amountFieldRef}
        shouldBeTouchedToShowError={shouldBeTouchedToShowError}
        externalError={priceError}
        style={style}
      />
    </div>
  );
};

type Props = {
  classNameModifier?: ClassNameModifierProp;
  fieldName?: string;
  readOnly?: boolean;
  currencySymbol?: string;
  amountFieldRef?: MutableRefObject<HTMLInputElement | undefined>;
  shouldBeTouchedToShowError?: boolean;
  priceError?: string;
  withFieldLevelValidation?: boolean;
};

export default AmountField;
