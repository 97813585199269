import { Map } from 'immutable';

export const colorConfig = [
  'blue',
  'purple',
  'green',
  'red',
  'yellow',
];

const sizesConfig = Map({
  // Sizes declared in CSS - width of left and right borders
  small: {
    w: 26,
    h: 26,
  },
  default: {
    w: 36,
    h: 36,
  },
  medium: {
    w: 61,
    h: 61,
  },
  large: {
    w: 86,
    h: 86,
  },
  extraLarge: {
    w: 116,
    h: 116,
  },
});

export default sizesConfig;
