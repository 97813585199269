import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { toast } from 'react-toastify';
import { List } from 'immutable';
import classNames from 'classnames';
import { UserAgent } from '@quentin-sommer/react-useragent';
import { trackEvent } from 'src/common/tracking';
import { TITLE } from '../../../../common/constants';
import { getClassNameFor } from '../../../../common/helpers';
import { getReviewUrl } from '../../../helpers';
import TripPanel from '../../presentation/TripPanel';
import CTA, { CTABackLink, CTALink } from '../../CTA';
import Card from '../../presentation/Card';
import { REVIEW_PAGES } from '../../pages/Complete/reviewsConfig';
import Icon from '../../../../common/components/IconDS22';
import s from '../ReviewStep.module.scss';
import Notification from '../../../../common/components/Notification';
import { ImmutableTrip } from '../../../../types/trips/Trips';
import { ImmutableBoatReview, ImmutableBoatReviews } from '../../../../types/reviews/Reviews';
import captureException from '../../../../common/utils/captureException';

const GA_SHARE_ACTION_PREFIX = 'SR - ';
const GA_SKIP_SHARE_ACTION = `${GA_SHARE_ACTION_PREFIX}Skip`;
const GA_BACK_ACTION = `${GA_SHARE_ACTION_PREFIX}Back`;
const GA_CATEGORY = 'Share Reviews - Links Clicked';

const SHARE_TARGETS = {
  FACEBOOK: 'Facebook',
  TWITTER: 'Twitter',
  LINK: 'Share',
  MOBILE_SHARE: 'MobileShare',
};

type Props = {
  progressBar: React.ReactNode;
  role: string;
  trip: ImmutableTrip;
  reviews: ImmutableBoatReviews;
};

const getWindowFeatures = (width: number, height: number) => {
  const left = (window.screen.width / 2) - ((width / 2) + 10);
  const top = (window.screen.height / 2) - ((height / 2) + 50);
  return `toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,width=${width},height=${height},resizable=1,left=${left},top=${top},screenX=${left},screenY=${top}`;
};

const ReviewShare: FC<Props> = ({
  progressBar,
  trip,
  role,
  reviews,
}) => {
  const location = useLocation();
  const review: ImmutableBoatReview = reviews.getIn(['boat', 'results']).first();
  const nextUrl = getReviewUrl(REVIEW_PAGES.REVIEW_SHARE, role, 'next', location);
  const backUrl = getReviewUrl(REVIEW_PAGES.REVIEW_SHARE, role, 'back', location);
  const cta = (
    <CTA classNameModifier="withSidebar">
      <CTABackLink
        to={backUrl}
        className={s.backButton}
        onClick={() => {
          trackEvent(GA_BACK_ACTION, {
            event_category: GA_CATEGORY,
          });
        }}
      />
      <CTALink
        to={nextUrl}
        className={s.skipButton}
        onClick={() => {
          trackEvent(GA_SKIP_SHARE_ACTION, {
            event_category: GA_CATEGORY,
          });
        }}
      >
        Skip sharing
      </CTALink>
    </CTA>
  );

  const url = review.get('share_link');
  const title = 'Share Review';
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  const twitterUrl = `https://twitter.com/intent/tweet?title=${title}&url=${url}`;
  const listingPhoto: string = trip.getIn(['boat', 'photo_url']);
  const photos = review.get('photos').size ? review.get('photos').slice(0, 4) : List([listingPhoto]);
  const rating = review.get('rating');

  const handleClick = async (target: string) => {
    trackEvent(GA_SHARE_ACTION_PREFIX + target, {
      event_category: GA_CATEGORY,
      value: rating,
    });
    switch (target) {
      case SHARE_TARGETS.FACEBOOK:
        window.open(facebookUrl, 'Facebook', getWindowFeatures(600, 400));
        break;

      case SHARE_TARGETS.TWITTER:
        window.open(twitterUrl, 'Twitter', getWindowFeatures(550, 350));
        break;
      case SHARE_TARGETS.LINK:
        try {
          await navigator.clipboard.writeText(url);
          toast(<Notification heading="Link copied" />);
        } catch (error) {
          captureException(error);
        }
        break;
      case SHARE_TARGETS.MOBILE_SHARE:
        try {
          await navigator.share({ url, text: title, title: 'Getmyboat' });
        } catch (error) {
          captureException(error);
        }
        break;
      default:
        break;
    }
  };

  return (
    <TripPanel
      trip={trip}
      subheader={TITLE.REVIEW}
      cta={cta}
      backLocation={backUrl}
    >
      <Card classNameModifier="review reviewShare">
        {progressBar}
        <h3 className={s.shareTitle}>
          <UserAgent computer>
            Help us <strong>spread the joy</strong> of boating by sharing your boat day photos!
          </UserAgent>
          <UserAgent mobile>
            Help us <strong>spread the joy</strong> of Getmyboat by sharing your
            {' '}
            photos on your social channels.
          </UserAgent>
        </h3>
        <div className={s.shareFrame}>
          <div className={getClassNameFor(s, 'shareFrameImageWrapper', classNames({ triGrid: photos.size === 3 }))}>
            {photos && photos.map((photoUrl) => (
              <img key={photoUrl} src={photoUrl} alt="temp" />
            ))}
          </div>
        </div>
        <ul className={s.shareLinks}>
          <UserAgent computer>
            <li>
              <button type="button" onClick={() => handleClick(SHARE_TARGETS.FACEBOOK)}>
                <Icon id="facebook" size="xl" />
                Share to Facebook
              </button>
            </li>
            <li>
              <button type="button" onClick={() => handleClick(SHARE_TARGETS.TWITTER)}>
                <Icon id="twitter" size="xl" />
                Share to Twitter
              </button>
            </li>
            <li>
              <button type="button" onClick={() => handleClick(SHARE_TARGETS.LINK)}>
                <Icon id="generic-link" size="xl" />
                Copy link to my review
              </button>
            </li>
          </UserAgent>
          <UserAgent mobile>
            <li>
              <button type="button" onClick={() => handleClick(SHARE_TARGETS.MOBILE_SHARE)}>
                <Icon id="share-network" size="xl" />
                Share to social
              </button>
            </li>
          </UserAgent>
        </ul>
      </Card>
    </TripPanel>
  );
};

export default ReviewShare;
