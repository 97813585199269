import { EXTERNAL_PATHS } from 'src/common/constants';
import placeIdSearch from 'src/common/utils/placeIdSearch';

export type SearchByPlaceIdPayload = {
  placeId: string;
  currency?: string;
};

export const searchByPlaceId = async (payload: SearchByPlaceIdPayload) => {
  try {
    const searchRoute = await placeIdSearch(payload);
    if (!searchRoute) {
      return `${EXTERNAL_PATHS.SEARCH}?page=1`;
    }
    // Send adwords conversion for searching
    gtag('event', 'conversion', { send_to: 'AW-991706362/NiIJCOqk4cUBEPr58NgD' });

    const base = window.location.href;
    const { searchParams } = new URL(base);
    const url = new URL(searchRoute, base);
    url.searchParams.delete('bareboat');
    url.searchParams.delete('captained');
    if (searchParams.has('currency')) {
      url.searchParams.append('currency', searchParams.get('currency') as string);
    }
    if (url.searchParams.has('place_id') || url.searchParams.has('location')) {
      url.searchParams.delete('swlon');
      url.searchParams.delete('swlat');
      url.searchParams.delete('nelon');
      url.searchParams.delete('nelat');
      url.searchParams.delete('clon');
      url.searchParams.delete('clat');
    }
    return url.toString();
  } catch (error) {
    return `${EXTERNAL_PATHS.SEARCH}?page=1`;
  }
};

export const isEstablishment = <T extends { types: string[] }>(place: T) => place.types.length === 1 && place.types.includes('establishment');

export const filterPlaces = <T extends { types: string[] }>(places: T[]): T[] => {
  if (Array.isArray(places)) {
    return places.filter((place) => !isEstablishment(place));
  }
  return [];
};
