import React, { createContext, FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import type { Action } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';

import { useCaptainData } from 'src/common/hooks';
import type { ReduxState } from 'src/types/reduxState';
import type { ImmutableTrip } from 'src/types/trips/Trips';
import { CAPTAIN_OPTION_KEYS } from 'src/common/constants';
import type { Captain } from 'src/types/boat/LinkedCaptains';
import { trackEvent } from 'src/common/tracking';
import { userIsRenter } from '../helpers';
import { CAPTAIN_HASH_URLS } from '../constants';
import { clearLinkedCaptains, getLinkedCaptainsCall } from '../ducks/linkedCaptains';

type CaptainSelectorContextType = {
  captain: Captain | null;
  captainSelectorEnabled: boolean;
  captainSelectorOpen: boolean;
  closeCaptainSelector: () => void;
  openCaptainSelector: () => void;
};

const initialContext: CaptainSelectorContextType = {
  captain: null,
  captainSelectorEnabled: false,
  captainSelectorOpen: false,
  closeCaptainSelector: () => {},
  openCaptainSelector: () => {},
};

const CaptainSelectorContext = createContext<CaptainSelectorContextType>(initialContext);

type CaptainSelectorProviderProps = {
  value: ImmutableTrip;
  children: React.ReactNode;
};

const CaptainSelectorProvider: FC<CaptainSelectorProviderProps> = ({ children, value: trip }) => {
  const { push } = useHistory();
  const { hash, pathname, search } = useLocation();
  const dispatch = useDispatch<ThunkDispatch<ReduxState, {}, Action>>();
  const { selectedCaptainOption } = useCaptainData(trip);
  const isOwner = !userIsRenter(trip);
  const captainSelectorEnabled = isOwner
    // If captain cost is set
    && trip.getIn(['transaction', 'amounts', 'captain_amount'], 0) > 0
  && selectedCaptainOption === CAPTAIN_OPTION_KEYS.ARRANGED_SEPARATELY;
  const pathnameWithSearch = useMemo(() => `${pathname}${search}`, [pathname, search]);
  const openCaptainSelector = useCallback(() => {
    push(`${pathnameWithSearch}#${CAPTAIN_HASH_URLS.SELECT}`);
    trackEvent('captain_selector_open');
  }, [pathnameWithSearch, push]);
  const closeCaptainSelector = useCallback(() => {
    push(pathnameWithSearch);
  }, [pathnameWithSearch, push]);
  const boatId = useMemo(() => trip.getIn(['boat', 'id']), [trip]);
  const captain = useMemo(() => trip.get('captain')?.toJS(), [trip]);
  const captainSelectorOpen = useMemo(() => (
    Object.values(CAPTAIN_HASH_URLS)
      .map((url) => `#${url}`)
      .includes(hash)
  ), [hash]);

  useEffect(() => {
    dispatch(clearLinkedCaptains());
    if (boatId && captainSelectorEnabled) {
      dispatch(getLinkedCaptainsCall(boatId));
    }
  }, [boatId, captainSelectorEnabled, dispatch]);

  const contextValue = useMemo(
    () => ({
      captain,
      captainSelectorEnabled,
      captainSelectorOpen,
      closeCaptainSelector,
      openCaptainSelector,
    }),
    [
      captain,
      captainSelectorEnabled,
      captainSelectorOpen,
      closeCaptainSelector,
      openCaptainSelector,
    ],
  );

  return (
    <CaptainSelectorContext.Provider value={contextValue}>
      {children}
    </CaptainSelectorContext.Provider>
  );
};

export default CaptainSelectorProvider;

/**
 * Provides access to the captain selector data and actions
 */
export const useCaptainSelectorContext = (): CaptainSelectorContextType => {
  const context = useContext(CaptainSelectorContext);
  if (context == null) {
    throw new Error('useCaptainSelectorContext must be used within a CaptainSelectorProvider');
  }
  return context;
};
