import { useLocation } from 'react-router-dom';

const useDirectBookingParam = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const directBookingHashedId = params.get('direct_booking');
  return {
    directBookingId: directBookingHashedId,
    isDirectBooking: Boolean(directBookingHashedId),
  };
};

export default useDirectBookingParam;
