import React from 'react';
import { Field } from 'react-final-form';

import { useTimeInput } from '../../../hooks';
import TimeInput from '../../inputs/TimeInput';

const TimeField = fieldProps => {
  const { format, timeInputIsSupported, validate } = useTimeInput();

  return (
    <Field
      // This is here because by default, rff's Field won't
      // rerender when its validate field changes, to allow
      // for anonymous validation functions to be used.
      // This means that in order to force the component to pick
      // up a new validation function, you need to change its key.
      // This will only rerender the component when the validation
      // function changes, as a bonus.
      key={`timeInputIsSupported${timeInputIsSupported}`}
      component={TimeInput}
      format={format}
      validate={validate}
      {...fieldProps}
    />
  );
};

export default TimeField;
