import React from 'react';
import PropTypes from 'prop-types';

import { getClassNameFor } from '../../../../../common/helpers';
import { userIsRenter } from '../../../../helpers';
import ReviewPhotos from '../ReviewPhotos';
import Svg from '../../../../../common/components/Svg';
import s from '../../ReviewItem.module.scss';

const RecommendedMessage = ({
  review,
  trip,
  boatHeadline,
}) => {
  const recommendation = review.get('recommendation');
  const isRenter = userIsRenter(trip);
  const renterName = trip.getIn(['renter', 'first_name']);
  const ownerName = trip.getIn(['owner', 'first_name']);
  const publicReview = review.get('public_review');

  return (
    <>
      <div className={s.recommendation}>
        <Svg
          href="#thumb"
          className={getClassNameFor(s, 'recommendationThumb', !recommendation && 'down')}
        />
        <div className={s.recommendationText}>
          {!isRenter && (recommendation
            ? `You recommended ${renterName}!`
            : `You did not recommend ${renterName}.`)}
          {isRenter && (recommendation
            ? `${ownerName} recommended you!`
            : `${ownerName} did not recommend you.`)}
        </div>
      </div>
      {publicReview && (
        <p className={s.content}>
          {publicReview}
        </p>
      )}
      <ReviewPhotos
        // Change the shape of photos list item to match the shape of communityPhotos
        photos={review.get('photos').map(photoUrl => ({
          description: boatHeadline,
          src: photoUrl,
        }))}
      />
    </>
  );
};

RecommendedMessage.propTypes = {
  review: PropTypes.object.isRequired,
  trip: PropTypes.object.isRequired,
  boatHeadline: PropTypes.string.isRequired,
};

export default RecommendedMessage;
