import React, { FC } from 'react';
import { DISPLAY_DATE_FORMAT_LONG } from 'src/common/constants';
import { formatDate } from 'src/common/utils/dateHelpers';

import s from './InstabookAvailabilityDates.module.scss';

type Props = {
  fieldValue: Date[];
};

const InstabookAvailabilityDatesField: FC<Props> = ({ fieldValue }) => {
  const displayDatesField = () => {
    if (fieldValue.length === 1) {
      const firstDate = fieldValue[0];
      return <p className={s.singleDateString}>{formatDate(firstDate, 'EEEE, MMMM d yyyy', {})}</p>;
    }
    const visibleDateMax = 10;
    const sortedDates = fieldValue.sort((a, b) => a.getTime() - b.getTime());
    const firstTenDates = sortedDates.slice(0, visibleDateMax);
    const remainderCount = sortedDates.slice(visibleDateMax).length;
    return (
      <div className={s.multiDateWrapper}>
        <ul className={s.multiDateList}>
          {firstTenDates.map((date: Date) => (
            <li
              className={s.multiDateItem}
              key={date.toDateString()}
            >
              {formatDate(date, DISPLAY_DATE_FORMAT_LONG, {})}
            </li>
          ))}
        </ul>
        {remainderCount > 0 && <p className={s.remainder}>+ {remainderCount} more dates</p>}
      </div>
    );
  };
  return (
    <div className={s.datesField}>
      {displayDatesField()}
    </div>
  );
};

export default InstabookAvailabilityDatesField;
