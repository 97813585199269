import React from 'react';
import PropTypes from 'prop-types';

import Svg from '../../../common/components/Svg';
import s from './EditButton.module.scss';

const EditButton = ({ openModal }) => (
  <button
    className={s.root}
    onClick={openModal}
    type="button"
    data-test="edit-button"
  >
    <Svg
      href="#card-edit-icon"
      className={s.icon}
    />
  </button>
);

EditButton.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default EditButton;
