import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Media from 'react-media';
import { getClassNameFor } from '../../../common/helpers';

import Checkbox from '../../../common/components/inputs/Checkbox';
import Dropdown from '../../../common/components/Dropdown';
import FilterContainer from '../../../common/components/FilterContainer';
import { useCalendarContext } from '../../hooks';
import FilterButton from '../FilterButton';
import FilterListContainer from '../FilterListContainer';
import filters from './filters';
import CloseButton from '../../../common/components/CloseButton';
import Button from '../../../common/components/Button';
import { MEDIA_QUERIES } from '../../../common/constants';

import s from './StatusFilter.module.scss';

const StatusFilter = ({ classNameModifier = '' }) => {
  const {
    statusFilters,
    handleStatusFilterChange,
    handleShowAllStatusFilters,
  } = useCalendarContext();

  return (
    <Dropdown className={s.root}>
      {({ isOpen, toggle }) => (
        <>
          <FilterButton
            classNameModifier={
              classnames(classNameModifier, {
                open: isOpen,
                active: statusFilters.length > 0,
              })
            }
            handleClick={toggle}
          >
            Types
            {statusFilters.length > 0 && (
              <span className={s.filterCount}>{statusFilters.length}</span>
            )}
          </FilterButton>

          {isOpen && (
            <FilterContainer classNameModifier={classnames('calendar', classNameModifier)}>
              <h3 className={s.title}>
                Select thread types to display.
              </h3>
              <Media
                queries={{
                  small: MEDIA_QUERIES.NOT_DESKTOP,
                }}
              >
                {(matches) => (
                  matches.small && (
                    <CloseButton
                      className={getClassNameFor(s, 'closeButton')}
                      onClick={toggle}
                    />
                  )
                )}
              </Media>
              <FilterListContainer classNameModifier="status">
                <li className={s.item}>
                  <label className={s.label}>
                    <Checkbox
                      indeterminate={statusFilters.length > 0
                        && statusFilters.length < filters.length}
                      input={{
                        checked: statusFilters.length === filters.length,
                        name: 'all',
                        onChange: handleShowAllStatusFilters,
                      }}
                    />
                    <span className={getClassNameFor(s, 'tag', 'selectAll')}>Select All</span>
                  </label>
                </li>
                {filters.map(({ label, name }) => (
                  <li
                    key={name}
                    className={s.item}
                  >
                    <label className={s.label}>
                      <Checkbox
                        input={{
                          checked: statusFilters.includes(name),
                          name,
                          onChange: handleStatusFilterChange,
                        }}
                      />
                      <span className={getClassNameFor(s, 'tag', name)}>
                        {label}
                      </span>
                    </label>
                  </li>
                ))}
              </FilterListContainer>
              <Media
                queries={{
                  small: MEDIA_QUERIES.NOT_DESKTOP,
                }}
              >
                {(matches) => (
                  matches.small && (
                    <div
                      className={getClassNameFor(s, 'mobileAction')}
                    >
                      <Button
                        type="button"
                        onClick={toggle}
                        data-test="done"
                      >

                        Done
                      </Button>
                    </div>
                  )
                )}
              </Media>
            </FilterContainer>
          )}
        </>
      )}
    </Dropdown>
  );
};

StatusFilter.propTypes = {
  classNameModifier: PropTypes.string,
};

export default StatusFilter;
