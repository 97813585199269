import React from 'react';

import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import MessagePanel from '../../presentation/MessagePanel';

const TripLoading = () => (
  <MessagePanel>
    <LoadingSpinner>
      Loading Trip...
    </LoadingSpinner>
  </MessagePanel>
);

export default TripLoading;
