import { useMemo } from 'react';
import { endOfMonth, getDateRange, startOfMonth } from '../helpers';

/**
 * Returns a range of dates that span the month of the date provided.
 */
const useMonthRangeDates = (date: Date) => useMemo(() => {
  const end = endOfMonth(date);
  return getDateRange(startOfMonth(date), end);
}, [date]);

export default useMonthRangeDates;
