import { ReactNode, useCallback, useState } from 'react';
import apiFetch from '../../core/fetch';
import { unpackApiError } from '../../common/helpers';
import captureException from '../../common/utils/captureException';
import { trackCheckout, trackEvent } from '../../common/tracking';

const CHECKOUT_VARIANTS = {
  inquiry: 'checkout',
  instabook: 'instabook checkout',
} as const;

type CheckoutVariant = keyof typeof CHECKOUT_VARIANTS;

const useCheckout = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmissionError] = useState<ReactNode | null>(null);

  const checkout = useCallback(async (
    tripId: number,
    variant: CheckoutVariant,
    cancelUrl?: string,
    beforeRedirect?: () => void,
  ) => {
    const eventCategory = CHECKOUT_VARIANTS[variant];
    trackCheckout(tripId, eventCategory);
    setSubmitting(true);
    let response: Response | null = null;
    try {
      const searchParams = new URLSearchParams();
      if (cancelUrl) {
        searchParams.append('cancel_url', cancelUrl);
      }
      const search = cancelUrl ? `?${searchParams.toString()}` : '';
      response = await apiFetch(`/trips/${tripId}/checkout-session/${search}`);
    } catch (err: unknown) {
      captureException(err);
    }

    if (!response) {
      return;
    }

    if (!response.ok) {
      const { detail } = await unpackApiError(response);
      setSubmissionError(detail);
      setSubmitting(false);
      trackEvent('Redirect to checkout failed', {
        event_category: eventCategory,
        event_label: detail,
      });
      return;
    }
    const body = await response.json();

    trackEvent('Redirect to checkout', {
      event_category: eventCategory,
    });
    beforeRedirect?.();
    window.location.href = body.url;
  }, []);

  return {
    checkout,
    submitting,
    submitError,
  };
};

export default useCheckout;
