import React from 'react';

import s from './NoscriptMessage.module.scss';

const NoscriptMessage = () => (
  <noscript>
    <div className={s.root}>
      <p className={s.para}>
        Getmyboat requires Javascript to work correctly.
        Please make sure your Javascript is enabled, or update your browser.
      </p>
    </div>
  </noscript>
);

export default NoscriptMessage;
