import PropTypes from 'prop-types';
import React from 'react';

import UniversalLink from '../../../common/components/UniversalLink';
import { EXTERNAL_PATHS, SUPPORT_URLS } from '../../../common/constants';
import { getClassNameFor } from '../../../common/helpers';
import s from './ContactInfoWarning.module.scss';

const ContactInfoWarning = ({ classNameModifier }) => (
  <div className={getClassNameFor(s, 'root', classNameModifier)}>
    {'Sharing contact information is against Getmyboat’s '}
    <UniversalLink
      to={EXTERNAL_PATHS.TERMS}
      className={s.link}
    >
      terms of use
    </UniversalLink>.
    {' Repeated attempts may result in account suspension. '}
    <UniversalLink
      to={SUPPORT_URLS.WHY_COMMUNICATE_AND_PAY_THROUGH_GETMYBOAT}
      className={s.link}
      openInNewTab
    >
      Find out more
    </UniversalLink>.
  </div>
);

ContactInfoWarning.propTypes = {
  classNameModifier: PropTypes.string,
};

export default ContactInfoWarning;
