import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { List } from 'immutable';
import { TITLE, USER_ROLES } from '../../../../common/constants';
import { close, open } from '../../../../common/ducks/zippy';
import { decorateComponent, getClassNameFor } from '../../../../common/helpers';
import { usePhotoUploader } from '../../../../common/hooks';

import { patchBoatReviewCall, patchRenterReviewCall } from '../../../ducks/reviews';
import { PHOTO_PREVIEW_MODAL_NAME } from '../../../constants';
import { getReviewUrl } from '../../../helpers';
import {
  REVIEW_PAGES,
} from '../../pages/Complete/reviewsConfig';
import TripPanel from '../../presentation/TripPanel';
import CTA, { CTAButton, CTALink } from '../../CTA';
import Card from '../../presentation/Card';
import PhotoUploader from '../../PhotoUploader';
import content from './content';
import s from '../ReviewStep.module.scss';

const { OWNER, RENTER } = USER_ROLES;

const mapStateToProps = ({ reviews }) => ({
  boatReviews: reviews.getIn(['boat', 'results']),
  renterReviews: reviews.getIn(['renter', 'results']),
});

const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(open(PHOTO_PREVIEW_MODAL_NAME)),
  closeModal: () => dispatch(close(PHOTO_PREVIEW_MODAL_NAME)),
  patchBoatReview: (id, pk, values) => dispatch(patchBoatReviewCall(id, pk, values)),
  patchRenterReview: (id, pk, values) => dispatch(patchRenterReviewCall(id, pk, values)),
});

const Photos = ({
  backLocation,
  boatReviews,
  renterReviews,
  patchBoatReview,
  patchRenterReview,
  openModal,
  closeModal,
  trip,
  role,
  progressBar,
}) => {
  const { push } = useHistory();
  const location = useLocation();
  const isRenter = role === RENTER;
  const review = isRenter ? boatReviews.first() : renterReviews.first();
  const rating = review.get('rating');
  const nextUrl = getReviewUrl((rating >= 3 && isRenter) ? REVIEW_PAGES.PHOTOS : REVIEW_PAGES.REVIEW_SHARE, role, 'next', location);
  const onSubmitSuccess = () => push(nextUrl);
  const patchReview = keys => {
    const patchTheReview = role === RENTER ? patchBoatReview : patchRenterReview;
    return patchTheReview(trip.get('pk'), review.get('id'), { s3_keys: keys });
  };
  const existingImages = useMemo(() => (review.get('photos', List()).toJS()), [review]);

  const {
    images,
    submitting,
    handleSubmit,
    ...restUploaderProps
  } = usePhotoUploader({
    onSubmitSuccess,
    onUploadSuccess: patchReview,
    openModal,
    closeModal,
  });
  const cta = (
    <CTA classNameModifier="withSidebar">
      {images.length
        ? (
          <CTAButton
            type="button"
            submitting={submitting}
            onClick={handleSubmit}
          >
            Publish
          </CTAButton>
        ) : (
          <CTALink to={nextUrl}>
            Skip
          </CTALink>
        )}
    </CTA>
  );

  return (
    <TripPanel
      cta={cta}
      trip={trip}
      subheader={TITLE.REVIEW}
      backLocation={backLocation}
    >
      <Card classNameModifier="review">
        {progressBar}
        <h3 className={s.title}>
          {content[role].title}
        </h3>
        <p className={getClassNameFor(s, 'text', 'bigger')}>
          {content[role].text}
        </p>
        <PhotoUploader
          images={images}
          submitting={submitting}
          handleSubmit={handleSubmit}
          existingImages={existingImages}
          {...restUploaderProps}
        />
      </Card>
    </TripPanel>
  );
};

Photos.propTypes = {
  trip: PropTypes.object.isRequired,
  role: PropTypes.oneOf([
    OWNER,
    RENTER,
  ]).isRequired,
  backLocation: PropTypes.object.isRequired,
  boatReviews: PropTypes.object.isRequired,
  renterReviews: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  patchBoatReview: PropTypes.func.isRequired,
  patchRenterReview: PropTypes.func.isRequired,
  progressBar: PropTypes.node,
};

const decorators = [
  connect(mapStateToProps, mapDispatchToProps),
];

export default decorateComponent(Photos, decorators);
