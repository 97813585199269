import { useCallback, useRef, useState } from 'react';

const useClientRect = <T extends Element>() => {
  const [rect, setRect] = useState<Partial<DOMRect>>({});
  const elementRef = useRef<T>();
  const ref = useCallback((node: T) => {
    if (node != null) {
      setRect(node.getBoundingClientRect());
      elementRef.current = node;
    } else {
      // Reset rect
      setRect({});
    }
  }, []);
  return { element: elementRef.current, rect, ref };
};

export default useClientRect;
