import React, { FC, useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import moment, { Moment, Duration } from 'moment';
import { useDispatch } from 'react-redux';

import { clearFields, patchOffer } from 'src/inbox/ducks/offer';
import { close } from 'src/common/ducks/zippy';
import {
  dateFormat as format,
  dateParse as parse,
  plural,
} from 'src/common/helpers';
import { MOMENT_FORMAT } from 'src/common/constants';
import {
  ALTERNATIVE_DATE_FIELD,
  ALTERNATIVE_RETURN_DATE_FIELD,
  DATES_FIELDS,
  FORMS,
  PREFERRED_DATE_FIELD,
  TRIP_LENGTH_FIELD,
} from 'src/inbox/constants';
import { getDatesWithTripDurationEnd, ordinalSuffix } from 'src/inbox/helpers';

import DateTime from 'src/inbox/components/DateTime';
import ClearButton from 'src/common/components/ClearButton';
import RadioButton from 'src/common/components/fields/RadioButton';
import FormError from 'src/common/components/FormError';
import DateTimeSnippet from 'src/common/components/DateTimeSnippet';
import FormButtons from 'src/common/components/FormButtons';
import useBookingMotivatorsContext from 'src/common/hooks/useBookingMotivators';
import DatePickerWithBookingMotivators from 'src/common/components/DatePickerWithBookingMotivators';
import { UNAVAILABLE } from 'src/calendar/constants';
import { combineValidators, rffValidators } from 'src/common/validation';
import DateInput from 'src/common/components/inputs/DateInput';
import s from './DatesForm.module.scss';

/**
 * Some terminology for this code:
 * "suggested" dates are the dates provided by the renter.
 * "preferred" date is the one selected by the owner.
 * "alternative" date is the one provided by the owner.
 * The preferred date may be either a suggested date or
 * an alternative date.
 */

type InitialFormValues = {
  [PREFERRED_DATE_FIELD]: string | undefined;
  [ALTERNATIVE_DATE_FIELD]: string | undefined;
  [ALTERNATIVE_RETURN_DATE_FIELD]: string;
};

type DatesFormProps = {
  tripLength: Duration;
  firstName: string;
  modified: boolean;
  suggestedDates: string[];
  isRenter: boolean;
  initialValues: InitialFormValues;
  pickupTime?: string;
  modalId?: string;
  withBookingMotivators?: boolean;
  hasDayPricing?: boolean;
};

export const validate = ({ [PREFERRED_DATE_FIELD]: preferredDate }) => (
  !preferredDate
    ? { [ALTERNATIVE_DATE_FIELD]: 'Please pick a date.' }
    : {}
);

/**
 * Returns format in PT{value}H
 */
export const diffDates = (departureDate, returnDate = departureDate): string => (
  departureDate
  && returnDate
  && moment.duration(moment(returnDate).diff(moment(departureDate))).toJSON()
);

/** Returns difference in hours */
export const diffDatesHours = (
  departureDate: string | undefined,
  returnDate = departureDate,
): number => {
  if (departureDate && returnDate) {
    return moment.duration(moment(returnDate).diff(moment(departureDate))).asHours();
  }
  return 0;
};

const decorator = createDecorator({
  // When the depart date datepicker changes,
  field: ALTERNATIVE_DATE_FIELD,
  updates: datePickerValue => (
    // Change the value of the preferred date field (the radio button value)
    // to that value
    datePickerValue !== undefined
      ? { [PREFERRED_DATE_FIELD]: datePickerValue }
      : {}
  ),
});

type DateValues = {
  [PREFERRED_DATE_FIELD]: string;
  [ALTERNATIVE_DATE_FIELD]?: string;
  [ALTERNATIVE_RETURN_DATE_FIELD]?: string;
};

const getAvailableTime = (timeString: string, duration: Duration) => {
  const time = moment(timeString, 'HH:mm:ss');

  const endOfDay = moment(time).endOf('day');
  const remainingTimeInDay = moment.duration(endOfDay.diff(time));

  const availableDuration = remainingTimeInDay.asHours() < duration.asHours()
    ? remainingTimeInDay
    : duration;

  return availableDuration.asHours();
};

export const selectValuesForSubmission = (
  {
    [PREFERRED_DATE_FIELD]: preferredDateString,
    [ALTERNATIVE_DATE_FIELD]: alternativeDateString,
    [ALTERNATIVE_RETURN_DATE_FIELD]: alternativeReturnDateString,
  }: DateValues,
  currentTripDuration: Duration,
  pickupTime?: string,
  hasDayPricing: boolean = false,
) => {
  // Calculate the trip length difference in hours (0 if same-day)
  const tripLengthDifferenceInHours = diffDatesHours(
    alternativeDateString,
    alternativeReturnDateString,
  );
  let updatedTripLengthHours = tripLengthDifferenceInHours;
  const currentTripLengthInHours = currentTripDuration.asHours();

  const availableHoursLeft = pickupTime
    ? Math.floor(getAvailableTime(pickupTime, currentTripDuration))
    : Infinity;

  if (updatedTripLengthHours === 0) {
    // Same-day trip is changing dates
    if (availableHoursLeft >= currentTripDuration.hours()) {
      // Only change date of trip, keep same duration
      updatedTripLengthHours += currentTripDuration.hours();
    } else {
      // Change duration of trip as not enough time left
      updatedTripLengthHours += availableHoursLeft;
    }
  }

  if ((hasDayPricing && updatedTripLengthHours >= 24)) {
    // We are changing day-trip dates or changing from days/nights to hours
    // Add number of hours to new duration to maintain return time.
    updatedTripLengthHours += currentTripDuration.hours();
  }

  const updatedTripLength = (
    updatedTripLengthHours > 0
    && currentTripLengthInHours !== updatedTripLengthHours
  )
    ? { [TRIP_LENGTH_FIELD]: `PT${updatedTripLengthHours}H` }
    : {};

  return {
    ...updatedTripLength,
    [PREFERRED_DATE_FIELD]: moment(preferredDateString).format('YYYY-MM-DD'),
    [ALTERNATIVE_RETURN_DATE_FIELD]: moment(alternativeReturnDateString).format('YYYY-MM-DD'),
  };
};

const dateValidate = rffValidators.required({ message: 'Please select a date.' });

export const BareDatesForm: FC<DatesFormProps> = ({
  firstName,
  tripLength,
  modified,
  suggestedDates,
  isRenter,
  initialValues,
  pickupTime,
  modalId,
  withBookingMotivators,
  hasDayPricing,
}) => {
  const dispatch = useDispatch();
  const closeModal = useCallback(() => dispatch(close(FORMS.DATES)), [dispatch]);
  const suggestedDatesWithEnds = getDatesWithTripDurationEnd(
    (isRenter ? suggestedDates.take(1) : suggestedDates),
    tripLength,
    pickupTime,
  );
  const preferred = isRenter ? 'offer' : 'preferred';

  const {
    getMotivators,
  } = useBookingMotivatorsContext();

  const availabilityValidate = value => (getMotivators(value).includes(UNAVAILABLE)
    ? 'This date is unavailable.'
    : undefined
  );

  const primaryDateValidate = combineValidators([
    dateValidate,
    availabilityValidate,
  ]);

  const dateFormat = value => (value ? moment(value).format('MM/DD/YYYY') : '');
  const showBookingMotivators = isRenter && withBookingMotivators;

  const fieldsToClear = [
    ...DATES_FIELDS,
    ...(Math.floor(tripLength.asDays()) ? [TRIP_LENGTH_FIELD] : []),
  ];

  return (
    <Form
      onSubmit={(values, { getState }) => {
        // TODO: dedupe this logic
        if (getState().dirty) {
          dispatch(patchOffer(selectValuesForSubmission(
            values,
            tripLength,
            pickupTime,
            hasDayPricing,
          )));
        }
        closeModal();
      }}
      initialValues={initialValues}
      decorators={[decorator]}
      validate={validate}
    >
      {({
        handleSubmit,
        values: {
          [ALTERNATIVE_DATE_FIELD]: alternativeDate,
          [ALTERNATIVE_RETURN_DATE_FIELD]: alternativeReturnDate,
        },
        error,
        dirty,
        form: { reset },
        submitting,
      }) => {
        const calculatedReturnDate = (
          alternativeDate
            ? moment(alternativeDate).add(tripLength)
            // Default return date
            : moment(suggestedDatesWithEnds.getIn([0, 1]))
        ).format('L');

        const suggestedDateField = showBookingMotivators ? (
          <Field
            isAlternativeDate
            name={ALTERNATIVE_DATE_FIELD}
            placeholder="Departure"
            format={dateFormat}
            validate={primaryDateValidate}
            tripLength={tripLength}
            component={DatePickerWithBookingMotivators}
            portalId={modalId}
            withIcon={false}
            classNameModifier="alternativeDates"
          />
        ) : (
          <Field
            name={ALTERNATIVE_DATE_FIELD}
            placeholder="Departure"
            shouldBeTouchedToShowError={false}
            format={format}
            parse={parse}
            icon={false}
            isDayBlocked={(day: Moment) => alternativeReturnDate
              && moment(day).isAfter(alternativeReturnDate, 'day')}
            classNameModifier="inbox"
            openDirection="up"
            component={DateInput}
          />
        );

        const suggestedReturnField = showBookingMotivators ? (
          <Field
            isAlternativeReturnDate
            name={ALTERNATIVE_RETURN_DATE_FIELD}
            placeholder={calculatedReturnDate}
            format={dateFormat}
            tripLength={tripLength}
            component={DatePickerWithBookingMotivators}
            portalId={modalId}
            withIcon={false}
            classNameModifier="alternativeDates"
          />
        ) : (
          <Field
            name={ALTERNATIVE_RETURN_DATE_FIELD}
            placeholder={calculatedReturnDate}
            shouldBeTouchedToShowError={false}
            format={format}
            parse={parse}
            icon={false}
            isDayBlocked={(day: Moment) => alternativeDate
              && moment(day).isBefore(alternativeDate, 'day')}
            classNameModifier="inbox return"
            openDirection="up"
            component={DateInput}
          />
        );
        return (
          <form onSubmit={handleSubmit} className={s.root}>
            <h3 className={s.title}>
              {isRenter
                ? 'Edit Trip Date'
                : 'Select Offer Date'}
            </h3>
            <p className={s.message}>
              {isRenter
                ? 'Request a change to your trip date.'
                : `${firstName} provided ${suggestedDates.size} option${plural(suggestedDates.size)} for the requested trip date.`}
            </p>
            <FormError error={error} />
            <div className={`${s.fieldset} ${s.fieldset_wide}`}>
              <ClearButton
                disabled={!(dirty || modified)}
                onClick={() => {
                  reset();
                  dispatch(clearFields(fieldsToClear));
                }}
              />

              {suggestedDatesWithEnds.map((date, index) => (
                <label
                  // In this case there should only be
                  // three dates, so we are okay with
                  // array indices for keys.
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={s.label}
                >
                  <div className={s.radio}>
                    <RadioButton
                      name={PREFERRED_DATE_FIELD}
                      value={moment(date.get(0)).format(MOMENT_FORMAT.VALUE)}
                    />
                  </div>
                  <DateTimeSnippet type="date">
                    <DateTime
                      departHeading="Depart on"
                      title={ordinalSuffix`${firstName}’s ${index} ${index > 0 ? 'alternative' : preferred} date:`}
                      departDateTime={moment(date.get(0))}
                      returnDateTime={moment(date.get(1))}
                    />
                  </DateTimeSnippet>
                </label>
              ))}

              <label className={s.label}>
                <div className={s.radio}>
                  <RadioButton
                    name={PREFERRED_DATE_FIELD}
                    value={alternativeDate || ''}
                  />
                </div>
                <DateTimeSnippet type="date">
                  <DateTime
                    departHeading="Depart on"
                    title={isRenter
                      ? 'New requested date:'
                      : 'Your suggested date:'}
                    departDateTime={suggestedDateField}
                    returnDateTime={suggestedReturnField}
                  />
                </DateTimeSnippet>
              </label>
            </div>
            <FormButtons
              classNameModifier="inbox"
              submitting={submitting}
              closeModal={closeModal}
            />
          </form>
        );
      }}
    </Form>
  );
};

export default BareDatesForm;
