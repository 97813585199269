import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { GUEST_FIELDS, GUESTS_FIELD } from '../../../constants';
import { getSelectedBoat } from '../../../helpers';

export const guestsGetter = ({ trip, offer }) => Map(GUEST_FIELDS
  .map(guestType => [
    guestType,
    offer.get(guestType, trip.getIn([GUESTS_FIELD, guestType])),
  ]));

const capacityGetter = ({ trip, offer, userBoats }) => {
  const selectedBoat = getSelectedBoat(offer, trip, userBoats);
  return selectedBoat.get('capacity');
};

export const sumGuests = guests => guests
  // Make sure there's no null/undefined
  .filter(val => !!val)
  .reduce((a, b) => a + b, 0);

const guestsMunger = (guests, boatCapacity) => {
  const totalGuests = sumGuests(guests);
  const overCapacity = totalGuests > boatCapacity;
  return {
    guests,
    overCapacity,
  };
};

export const offerGuestSelector = createSelector(
  [
    guestsGetter,
    capacityGetter,
  ],
  guestsMunger,
);
