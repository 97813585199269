import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import ScrollToMe from 'src/common/components/ScrollToMe';
import TripStateIndicatorCalendar from 'src/common/components/TripStateIndicatorCalendar';
import { decorateComponent } from 'src/common/helpers';
import useCalendarFocus from 'src/calendar/hooks/useCalendarFocus';
import { UnavailableEvent } from 'src/calendar/types';
import EventShell from '../EventShell';
import Time from '../Time';

export type AdHocEventsProps = {
  events: UnavailableEvent[];
  openUnavailableEditor: (adHocEventId: number) => void;
  disabled: boolean;
  isSelectedDate: boolean;
};

const AdHocEvents: FC<AdHocEventsProps> = ({
  disabled,
  events,
  openUnavailableEditor,
  isSelectedDate,
}) => {
  const singleEvent = events.length === 1;
  const firstEvent = events[0];
  const otherEventsCount = events.length - 1;
  const durationCount = `${firstEvent.day_number}/${firstEvent.total_days}`;
  const getListingCopy = () => {
    if (singleEvent) return '';
    return `+${otherEventsCount} listing${otherEventsCount === 1 ? '' : 's'}`;
  };
  const listingCopy = getListingCopy();
  const adHocEventId = firstEvent.adhoc_calendar_event_id;
  const { selectedDate, focusedAdHocEventId, setCalendarFocus, scrollToEvent } = useCalendarFocus();
  const isFocusedEvent = focusedAdHocEventId === adHocEventId;
  const isFirstOfAdHocGroup = firstEvent.day_number === 1 && firstEvent.event_date === selectedDate;
  const firstEventOfAddHocEventId = isFirstOfAdHocGroup && isFocusedEvent;
  const eventState = {
    state: 'adHoc',
    role: 'adHoc',
    badge: 'Unavailable',
  };

  const handleClick = useCallback(() => {
    setCalendarFocus({ focusedAdHocEventId: adHocEventId });
    openUnavailableEditor(adHocEventId);
  }, [adHocEventId, openUnavailableEditor, setCalendarFocus]);

  return (
    <EventShell
      className={classNames({
        focusedEvent: isFocusedEvent,
      })}
      classNameModifier={classNames({ adHocSingleEvent: singleEvent })}
      disabled={disabled}
      eventType="adHoc"
      handleClick={handleClick}
      groupId={`adHoc-${adHocEventId}`}
      time={<Time event={firstEvent} isUnavailableEvent />}
      multiDayDuration={firstEvent.total_days > 1 ? durationCount : undefined}
      title={listingCopy}
      tripStateIndicator={<TripStateIndicatorCalendar tripState={eventState} />}
    >
      {isSelectedDate && firstEventOfAddHocEventId && scrollToEvent && (
        <ScrollToMe
          key={`scroll-to-event-${selectedDate}-${firstEvent.adhoc_calendar_event_id}`}
          classNameModifier="calendarEventAnchor"
        />
      )}
    </EventShell>
  );
};

export default decorateComponent(AdHocEvents);
