import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';

import {
  TITLE,
  SUPPORT_URLS,
  EXTERNAL_PATHS,
} from '../../constants';
import { setLoadingComplete } from '../../ducks/loading';
import { decorateComponent } from '../../helpers';
import Layout from '../Layout';
import Header from '../Header';
import SupportEmail from '../SupportEmail';
import UniversalLink from '../UniversalLink';
import MessagePanel from '../../../inbox/components/presentation/MessagePanel';

const mapDispatchToProps = {
  finishLoading: setLoadingComplete,
};

const BannedPage = ({ finishLoading }) => {
  useEffect(
    () => { finishLoading(); },
    [finishLoading],
  );

  return (
    <Layout>
      <Helmet>
        <title lang="en">
          {TITLE.BANNED}
        </title>
      </Helmet>

      <Header
        withNav={false}
        withSearchBar={false}
        classNameModifier="fixed bordered"
      />
      <MessagePanel>
        <h1>
          We’ve disabled this account.
        </h1>
        <p>
          To protect our community Getmyboat occasionally
          {' removes users that do not comply with our '}
          <UniversalLink
            to={EXTERNAL_PATHS.TERMS}
          >
            terms of use
          </UniversalLink>
          {' or '}
          <UniversalLink
            to={SUPPORT_URLS.COMMUNITY_GUIDELINES}
            openInNewTab
          >
            community guidelines
          </UniversalLink>.
          {' If you believe this to be an error please email '}
          <SupportEmail
            subject="Account Disabled"
          />
          {' with the subject “Account Disabled”.'}
        </p>
      </MessagePanel>
    </Layout>
  );
};

BannedPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
};

const decorators = [
  connect(null, mapDispatchToProps),
];

export default decorateComponent(BannedPage, decorators);
