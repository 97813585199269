import React, { FC, ReactNode } from 'react';

import s from './Notification.module.scss';
import Svg from '../Svg';

export type NotificationProps = {
  heading?: string;
  message?: string | ReactNode;
  withIcon?: boolean;
};

const Notification: FC<NotificationProps> = ({
  heading,
  message,
  withIcon = false,
}) => (
  <div className={s.root}>
    <div className={s.row}>
      {withIcon && (
        <Svg
          href="#exclamation-icon"
          className={s.icon}
        />
      )}
      {heading && (
        <h6 className={s.title}>
          {heading}
        </h6>
      )}
    </div>
    {message && (
      <p className={s.message}>
        {message}
      </p>
    )}
  </div>
);

export default Notification;
