import React from 'react';

import s from './Landing.module.scss';
import Svg from '../../../../common/components/Svg';
import MessagePanel from '../../presentation/MessagePanel';

const Landing = () => (
  <MessagePanel>
    {/* Redirect to the first trip once we have trips to get to,
          but only on desktop, since on mobile the sidebar is a
          separate "page". */}
    <Svg
      href="#sailing-boat"
      className={s.boatIcon}
    />
    <h4 className={s.messageHeading}>TRIPS</h4>
    <p className={s.messageText}>Use the Trips Tab to manage details of your booking.</p>
    <Svg
      href="#message-icon"
      className={s.messageIcon}
    />
    <h4 className={s.messageHeading}>MESSAGES</h4>
    <p className={s.messageText}>Exchange Messages to fine tune the details.</p>
  </MessagePanel>
);

export default Landing;
