import React, { useCallback, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { MOVED_PAGES, PATHS } from './common/constants';
import { updateUserFromAuth } from './common/ducks/user';
import { useAppDispatch } from './common/hooks';
import useAhoyNotificationTracking from './common/hooks/useAhoyNotificationTracking';
import ErrorBoundary from './common/components/ErrorBoundary';
import LoadingPage from './common/components/LoadingPage';
import Redirect from './common/components/Redirect';
import LoginRequiredRoute from './common/components/LoginRequiredRoute';
import LoadingError from './common/components/LoadingError';
import SvgDefs from './common/components/SvgDefs';
import svgdefs from './img/defs.svg';
import useAuthSync from './auth/hooks/useAuthSync';
import isDarkCode from './common/utils/isDarkCode';
import { getLoading } from './common/utils/reduxStoreSelectors';
import { setupGtag } from './common/gtag';

import AsyncAwesomeInboxRoutes from './inbox/components/Root';
import AsyncInquiryRoutes from './booking-inquiry/routes';
import AsyncInstabookRoutes from './instabook/routes';
import AsyncAuthRoutes from './auth/routes';
import BannedPage from './common/components/Banned';
import SuspendedPage from './common/components/Suspended';
import NewsletterSubscribePage from './common/components/NewsletterSubscribe';
import PageNotFound from './common/components/NotFoundPage';
import TechnicalIssue from './common/components/TechnicalIssuePage';
import CalendarPage from './calendar/components/Root';

/**
 * Forces the page to be reloaded on the current url.
 * Used to resolve pages that have been move out of this app.
 */
const ReloadPage = () => {
  useEffect(() => {
    window.location.reload();
  }, []);
  return null;
};

const Routes = () => {
  const dispatch = useAppDispatch();
  const getUser = useCallback(() => dispatch(updateUserFromAuth()), [dispatch]);
  const loading = useSelector(getLoading);

  useEffect(
    () => {
      getUser();
      setupGtag();
    },
    [getUser],
  );

  useAuthSync(getUser);

  useAhoyNotificationTracking();

  return (
    <>
      <SvgDefs href={svgdefs} />
      <ErrorBoundary fallbackUI={<LoadingError />}>
        <Switch>
          {/* NOTE: conditionally rendering this means that it triggers
            the switch when it's truthy and passes over it otherwise */}
          {loading.get('500_error') ? <Route component={TechnicalIssue} /> : null}
          {loading.get('notFound') && loading.get('redirectUrl') && (
            <Redirect to={loading.get('redirectUrl') as string} statusCode={loading.get('statusCode', 301)} />
          )}
          {loading.get('notFound') ? <Route component={PageNotFound} /> : null}
          <Route path={`${PATHS.INQUIRY}:boatId/`} component={AsyncInquiryRoutes} />
          <Route path={`${PATHS.INSTABOOK_BOOKING}:boatId/`} component={AsyncInstabookRoutes} />
          <Route path={PATHS.AUTH} component={AsyncAuthRoutes} />
          <LoginRequiredRoute path={PATHS.INBOX} component={AsyncAwesomeInboxRoutes} />
          <Route exact path={PATHS.BANNED} component={BannedPage} />
          <Route exact path={PATHS.SUSPENDED} component={SuspendedPage} />
          {/* Legacy redirect. Remove once no longer needed. */}
          <Redirect exact from="/marketing/resubscribe/" to={PATHS.SUBSCRIBE} statusCode={301} />
          <LoginRequiredRoute exact path={PATHS.SUBSCRIBE} component={NewsletterSubscribePage} />
          <LoginRequiredRoute path={PATHS.CALENDAR} component={CalendarPage} />

          {/* Force page reload on pages that have been removed from this app */}
          {Object.values(MOVED_PAGES).map(path => (
            <Route key={path} path={path} component={ReloadPage} />
          ))}

          {isDarkCode() && <Route path="/s/loading/" component={LoadingPage} />}
          {isDarkCode() && <Route path="/s/error/" component={LoadingError} />}
          <Route component={PageNotFound} />
        </Switch>
      </ErrorBoundary>
    </>
  );
};

export default Routes;
