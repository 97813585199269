import React, { FC } from 'react';
import s from './CaptainOptionWarning.module.scss';

type CaptainOptionWarningProps = {};

const CaptainOptionWarning: FC<CaptainOptionWarningProps> = ({ children }) => (
  <div className={s.root}>
    <p>{children}</p>
  </div>
);

export default CaptainOptionWarning;
