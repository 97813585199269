import React, { FC } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm, useFormState } from 'react-final-form';
import { HotKeys } from 'react-hotkeys';
import { PATHS } from 'src/common/constants';
import Button from 'src/common/components/Button';
import ContactDetails from 'src/common/components/fieldsets/ContactDetails';
import CreatePassword from 'src/common/components/fieldsets/CreatePassword';
import MarketingConsent from 'src/common/components/fieldsets/MarketingConsent';
import FormError from 'src/common/components/FormError';
import s from './RegisterForm.module.scss';

// This is only used on the registration form
const RegisterFieldset: FC = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { blur, getRegisteredFields } = useForm();
  const { hasValidationErrors, submitError } = useFormState();

  const handleClick = () => {
    // This is how we "touch" fields to show errors on the fields
    // that haven't been touched yet.
    getRegisteredFields().forEach(blur);
    // TODO: need to make this more specific
    if (!hasValidationErrors) {
      push(`${PATHS.REGISTER_AGREEMENT}${search}`);
    }
  };

  return (
    <HotKeys
      handlers={{ click: handleClick }}
      keyMap={{ click: ['enter'] }}
      className={s.wrapper}
    >
      <div>
        {submitError && (
          <FormError
            error={submitError}
            className={s.error}
          />
        )}
        <ContactDetails />
        <CreatePassword />
        <MarketingConsent />
      </div>
      <div>
        <Button
          type="button"
          onClick={handleClick}
          fullWidth
        >
          Create Account
        </Button>

        <div className={s.account}>
          {'Already have an account? '}
          <Link
            to={`${PATHS.LOGIN}${search}`}
            className={s.link}
          >
            Sign In
          </Link>.
        </div>
      </div>
    </HotKeys>
  );
};

export default RegisterFieldset;
