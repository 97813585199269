import React from 'react';
import { Location } from 'history';
import { connect } from 'react-redux';

import { ReduxState } from 'src/types/reduxState';
import { Amounts } from 'src/types/common/Amounts';
import { Transaction } from 'src/types/common/Transaction';
import { ImmutableTrip } from 'src/types/trips/Trips';
import {
  CaptainCard,
  DurationCard,
  DatesCard,
  TimeCard,
  GroupSizeCard,
  CommentCard,
  VesselCard,
  DirectBookingCard,
} from '../../cards';
import CTA from '../../CTA';
import OwnerPriceSection from '../../PriceSection/owner';
import TripPanel from '../../presentation/TripPanel';
import TripDetailsTitle from '../../presentation/TripDetailsTitle';
import content, { getMessage } from '../content';
import TopScroll from '../../../../common/components/TopScroll';
import { PATHS } from '../../../../common/constants';
import { decorateComponent } from '../../../../common/helpers';
import useCustomOfferExpiry from '../../../../common/hooks/useCustomOfferExpiry';
import { preserveSearch } from '../../../../common/utils/routing';
import { CAPTAINED_FIELD } from '../../../constants';
import OfferSummary from '../../OfferSummary';
import TripMenu from '../../TripMenu';
import DateUnavailable from '../../DateUnavailable';
import TripPaymentLink from '../../TripPaymentLink';

const mapStateToProps = ({ transaction }: ReduxState) => ({ transaction: transaction.toJS() });

type OfferSentProps = {
  trip: ImmutableTrip;
  location: Location;
  transaction: Transaction;
};

const OfferSent: React.FC<OfferSentProps> = ({
  trip,
  location,
  transaction,
}) => {
  const amounts: Amounts = transaction?.amounts || trip?.getIn(['transaction', 'amounts'])?.toJS();
  const tripState = trip.getIn(['state', 'state']);
  const captained = trip.get(CAPTAINED_FIELD);
  const { renderCustomExpiry } = useCustomOfferExpiry(trip);
  const isDirectBooking = trip.get('direct_booking', false);
  const directBookingUrl = trip.get('direct_booking_url', '');
  const cta = (
    <CTA classNameModifier="withSidebar">
      <TripMenu trip={trip} />
    </CTA>
  );

  return (
    <TripPanel
      cta={cta}
      trip={trip}
      subheader={content.title[tripState]}
      intro={getMessage(trip, location)}
      backLocation={preserveSearch(PATHS.INBOX, location)}
      classNameModifier="withoutSideMargins"
    >
      <TopScroll />
      <DateUnavailable trip={trip} />

      {isDirectBooking
        && (
          <>
            {<TripPaymentLink textLink={directBookingUrl as string} />}
            <DirectBookingCard trip={trip} />
          </>
        )}
      <OwnerPriceSection
        amounts={amounts}
        readOnly
        renderCustomExpiry={renderCustomExpiry}
        trip={trip}
      >
        <OfferSummary amounts={amounts} />
      </OwnerPriceSection>
      <TripDetailsTitle />
      <VesselCard
        checkTripModification
        trip={trip}
        readOnly
      />
      <DatesCard
        checkTripModification
        trip={trip}
        readOnly
      />
      {/* TODO fix TS error of TimeCard */}
      <TimeCard
        checkTripModification
        trip={trip}
        readOnly
      />
      <DurationCard
        checkTripModification
        trip={trip}
        readOnly
      />
      <GroupSizeCard
        checkTripModification
        trip={trip}
        readOnly
      />
      {captained !== null && (
        <CaptainCard
          checkTripModification
          trip={trip}
          readOnly
        />
      )}
      <CommentCard
        checkTripModification
        trip={trip}
        readOnly
      />
    </TripPanel>
  );
};

const decorators: any[] = [
  connect(mapStateToProps),
];

export default decorateComponent(OfferSent, decorators);
