import createDecorator from 'final-form-calculate';
import { UNAVAILABLE_EDITOR_END_DATE, UNAVAILABLE_EDITOR_REPEAT_END_DATE, UNAVAILABLE_EDITOR_START_DATE } from 'src/calendar/constants';
import { calculateDurationFNS, repeatEndDateToCountFNS } from 'src/calendar/helpers';
import { AdHocEventFormState } from 'src/calendar/types';

const occurrenceCalculationDecorator = createDecorator(
  {
    field: UNAVAILABLE_EDITOR_START_DATE,
    updates: {
      repeat_count: (
        startDate,
        values: AdHocEventFormState,
      ) => repeatEndDateToCountFNS(startDate, values.repetitions_end_date, values.frequency),
    },
  },
  {
    field: UNAVAILABLE_EDITOR_REPEAT_END_DATE,
    updates: {
      repeat_count: (repeatEndDate, values: AdHocEventFormState) => (
        repeatEndDateToCountFNS(values.event_date, repeatEndDate, values.frequency)
      ),
    },
  },
);

const disableRepetitionsDecorator = createDecorator(
  {
    field: UNAVAILABLE_EDITOR_START_DATE,
    updates: {
      frequency: (startDate, values: AdHocEventFormState) => (
        calculateDurationFNS(startDate, values.event_end_date) > 7 ? null : values.frequency
      ),
    },
  },
  {
    field: UNAVAILABLE_EDITOR_END_DATE,
    updates: {
      frequency: (endDate, values: AdHocEventFormState) => (
        calculateDurationFNS(endDate, values[UNAVAILABLE_EDITOR_START_DATE]) > 7
          ? null
          : values.frequency
      ),
    },
  },
);

const formDecorators = [
  occurrenceCalculationDecorator,
  disableRepetitionsDecorator,
];

export default formDecorators;
