import { Map } from 'immutable';

// app/reducer/action
export const TOGGLE = 'common/zippy/TOGGLE';
export const CLOSE = 'common/zippy/CLOSE';
export const OPEN = 'common/zippy/OPEN';

export const toggle = zippy => ({ type: TOGGLE, zippy });
export const close = zippy => ({ type: CLOSE, zippy });
export const open = zippy => ({ type: OPEN, zippy });

export const getBoundToggle = zippy => (() => toggle(zippy));

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (zippies = Map(), action) => {
  switch (action.type) {
    case TOGGLE:
      return zippies.update(action.zippy, false, value => !value);
    case OPEN:
      return zippies.set(action.zippy, true);
    case CLOSE:
      return zippies.set(action.zippy, false);
    default:
      return zippies;
  }
};
