import React from 'react';

import { SUPPORT_EMAIL } from '../../constants';
import Layout from '../Layout';
import Svg from '../Svg';
import s from './LoadingError.module.scss';

const LoadingErrorPage = () => (
  <Layout>
    <div className={s.root}>
      <Svg
        href="#exclamation-icon"
        className={s.icon}
      />
      <p className={s.message}>
        Something went wrong.
        Please try <a className={s.link} href=".">refreshing the page</a> or <a className={s.link} href="/">go to the home page and try again</a>.
        <br />
        You can also <a className={s.link} href={`mailto:${SUPPORT_EMAIL}`}>contact us</a> for
        assistance or to report a problem.
      </p>
    </div>
  </Layout>
);

export default LoadingErrorPage;
