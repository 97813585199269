import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../../common/helpers';
import { INSTABOOK_STATES } from '../../constants';
import { InstabookStatus } from '../../types';

import s from './InstabookEditorHeader.module.scss';

const copy = {
  [INSTABOOK_STATES.NOT_BOOKABLE]: 'Not bookable',
  [INSTABOOK_STATES.DRAFT]: 'Draft',
  [INSTABOOK_STATES.PUBLISHED]: 'Published',
};

export type InstabookEditorHeaderProps = {
  title: string;
  icon?: ReactNode;
  status?: InstabookStatus;
  classNameModifier?: string;
};

const InstabookEditorHeader: FC<InstabookEditorHeaderProps> = ({
  children,
  status,
  title,
  icon = null,
  classNameModifier = '',
}) => (
  <div className={getClassNameFor(s, 'root', classNames(classNameModifier, { light: (status && status !== INSTABOOK_STATES.PUBLISHED) }))}>
    {status && <span className={s.status}>{copy[status]}</span>}
    <div className={s.titleContainer}>
      {icon}
      <h3 className={s.title}>{title}</h3>
    </div>
    {children}
  </div>
);

export default InstabookEditorHeader;
