import { useReducer } from 'react';

/**
 * Returns a forceUpdate to cause the component to re-render.
 */
const useForceUpdate = () => {
  const [, forceUpdate] = useReducer(value => ((value + 1) % 100), 0);
  return forceUpdate;
};

export default useForceUpdate;
