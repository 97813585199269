import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Icon from 'src/common/components/IconDS22';
import { trackEvent } from 'src/common/tracking';
import { PATHS } from '../../../common/constants';
import { open, close } from '../../../common/ducks/zippy';
import { getMessagesCall } from '../../ducks/messages';
import {
  getClassNameFor,
  rffSubmitResponse,
} from '../../../common/helpers';
import { preserveSearch } from '../../../common/utils/routing';
import { CTAButton } from '../CTA';
import Dropdown from '../../../common/components/Dropdown';
import Modal from '../../../common/components/Modal';
import TripActionForm from '../forms/TripAction';
import {
  getActions,
  getRoleSpecificActions,
  SEND_MESSAGE_ACTION_NAME,
  CANCEL_RESERVATION_ACTION_NAME,
} from './actions';
import s from './TripMenu.module.scss';

import { userIsRenter } from '../../helpers';

const TripMenu = ({
  trip,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const tripState = trip.getIn(['state', 'state']);
  const tripId = trip.get('pk');
  const actions = getRoleSpecificActions(trip);
  const activeActions = getActions(tripState).map(action => action.name);

  return (
    <Dropdown className={s.root}>
      {({ toggle, isOpen }) => (
        <>
          <CTAButton
            onClick={toggle}
            classNameModifier="gray options secondary"
            data-test="trip-menu"
          >
            Options
            <Icon
              id="caret-up"
            />
          </CTAButton>
          {isOpen && (
            <ul className={getClassNameFor(s, 'content', isOpen ? 'open' : '')}>
              {actions.map(action => {
                const Component = action.component;
                const modalName = `tripAction-${action.name}`;
                const destinationPath = `${PATHS.INBOX}${tripId}/${action.destination || ''}`;
                const closeBehavior = () => {
                  dispatch(close(modalName));
                  toggle();
                };
                const disabled = !activeActions.includes(action.name) || action?.isDisabled?.(trip);
                const disabledModifier = disabled ? 'disabled' : '';

                return (
                  <li
                    className={s.item}
                    key={action.name}
                  >
                    <Component
                      to={action.url || preserveSearch(destinationPath, location)}
                      onClick={() => (action.onClick || action.call) && (action.call
                        ? dispatch(open(modalName))
                        : action.onClick(dispatch, trip, { history, location }))}
                      disabled={!!disabled}
                      className={getClassNameFor(s, 'action', disabledModifier)}
                      {...((action.component.displayName || action.component.name) === 'NavLink'
                        ? { activeClassName: s.action_active }
                        : {})}
                    >
                      <Icon
                        id={action.icon}
                        size="l"
                      />
                      <span className={s.text}>
                        <strong className={s.title}>
                          {action.title}
                        </strong>
                        {action.shortDescription && (
                          <em className={s.description}>
                            {action.shortDescription}
                          </em>
                        )}
                      </span>
                    </Component>
                    {action.call && (
                      <Modal
                        name={modalName}
                        label={`${action.title} Form`}
                        extraClassName="ignore-onclickoutside"
                      >
                        <TripActionForm
                          content={{
                            action: action.name,
                            title: action.title,
                            description: action.longDescription || action.shortDescription,
                            submitText: action.submitText,
                            cancelText: action.cancelText,
                            placeholder: action.placeholder,
                          }}
                          closeBehavior={closeBehavior}
                          fieldName={action.name === SEND_MESSAGE_ACTION_NAME
                            ? 'content'
                            : 'message'}
                          onSubmit={values => dispatch(
                            action.call(
                              tripId,
                              {
                                ...values,
                                ...(action.name === SEND_MESSAGE_ACTION_NAME
                                  // add this field into values for send message call
                                  ? { communications: ['email'] }
                                  : {}
                                ),
                              },
                            ),
                          )
                            .then(() => {
                              dispatch(getMessagesCall(tripId));

                              if (action.name === CANCEL_RESERVATION_ACTION_NAME) {
                                trackEvent('Canceled', {
                                  event_category: 'Booking',
                                  event_label: userIsRenter(trip) ? 'Renter' : 'Owner',
                                });
                              }

                              history.replace(
                                preserveSearch(destinationPath, location),
                              );
                              closeBehavior();
                            })
                            .catch(rffSubmitResponse())}
                          withMessageField={action.withMessageField}
                        />
                      </Modal>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </>
      )}
    </Dropdown>
  );
};

TripMenu.propTypes = {
  trip: PropTypes.object.isRequired,
};

export default TripMenu;
