import React, { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import type { ImmutableTrip } from 'src/types/trips/Trips';
import { DropdownOption } from 'src/common/components/DropdownDS22';
import { Amounts } from 'src/types/common/Amounts';
import { getOffer, getTransaction } from 'src/common/utils/reduxStoreSelectors';
import { useAppDispatch } from 'src/common/hooks';
import { trackEvent } from 'src/common/tracking';
import {
  CAPTAINED_FIELD,
  DATES_FIELDS,
  GUEST_FIELDS,
  PAGES,
  TIME_FIELDS,
  TRIP_FIELDS,
  TRIP_LENGTH_FIELD,
} from '../../../constants';
import { modified, offerModified } from '../../../helpers';
import { unpackApiError } from '../../../../common/helpers';
import {
  CaptainCard,
  DurationCard,
  DatesCard,
  TimeCard,
  GroupSizeCard,
  CommentCard,
  VesselCard,
} from '../../cards';
import RenterPriceSection from '../../PriceSection/renter';
import { clearOffer } from '../../../ducks/offer';
import { requestOfferChanges } from '../../../ducks/trips';
import { getMessagesCall } from '../../../ducks/messages';
import { updateTransactionCall } from '../../../ducks/transaction';
import TripDetailsTitle from '../../presentation/TripDetailsTitle';
import TripPanel from '../../presentation/TripPanel';
import UserBlock from '../../presentation/UserBlock';
import content from '../content';
import TopScroll from '../../../../common/components/TopScroll';
import FormError from '../../../../common/components/FormError';
import { PATHS } from '../../../../common/constants';
import ClearButton from '../../../../common/components/ClearButton';
import CTA, { CTALink, CTAButton } from '../../CTA';
import TripMenu from '../../TripMenu';
import { preserveSearch, appendRoute } from '../../../../common/utils/routing';
import NotificationMessage from '../../../../common/components/NotificationMessage';
import EditableTripDetails from '../../EditableTripDetails';
import RequestChangesIntro from '../../RequestChangesIntro';
import s from './BookNow.module.scss';
import { BookingMotivatorsProvider } from '../../../../common/hooks/useBookingMotivators';

import PurchaseEventTestButton from '../../PurchaseEventTestButton';

type BookNowPageProps = {
  trip: ImmutableTrip;
};

const BookNow: React.FC<BookNowPageProps> = ({
  trip,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [error, setError] = useState<undefined | string>();
  const [submitting, setSubmitting] = useState(false);
  const offer = useSelector(getOffer);
  const transaction = useSelector(getTransaction);

  const clearTheOffer = useCallback(() => dispatch(clearOffer()), [dispatch]);
  const onCurrencyChange = useCallback((currencyCode: string) => (
    dispatch(updateTransactionCall(trip.get('pk'), {
      booking_currency: { code: currencyCode },
    }))
  ), [dispatch, trip]);
  const dispatchOfferChangeRequest = useCallback(
    () => dispatch(requestOfferChanges(trip.get('pk'), offer.toJS())),
    [dispatch, offer, trip],
  );
  const getTheMessages = useCallback(
    () => dispatch(getMessagesCall(trip.get('pk'))),
    [dispatch, trip],
  );

  const onCurrencySelection = useCallback((item: DropdownOption) => {
    setError(undefined);
    onCurrencyChange(item?.option?.code as string)
      .catch(() => setError('Unable to change transaction currency.'));
  }, [onCurrencyChange]);

  const requestChanges = useCallback(async () => {
    try {
      setSubmitting(true);
      await dispatchOfferChangeRequest();
      await getTheMessages();
      clearTheOffer();
      trackEvent('Changes Requested', { event_category: 'Offer' });
    } catch (err) {
      const erroredResponse = await unpackApiError(err as Response);
      setError(erroredResponse?.detail);
    } finally {
      setSubmitting(false);
    }
  }, [clearTheOffer, getTheMessages, dispatchOfferChangeRequest]);

  const amounts: Amounts = transaction?.get('amounts')?.toJS()
    || trip?.getIn(['transaction', 'amounts'])?.toJS();
  const captained = trip.get(CAPTAINED_FIELD);
  const ownerName = trip.getIn(['owner', 'first_name']);
  const reviewBookingLink = preserveSearch(
    appendRoute(location.pathname, PAGES.REVIEW_BOOKING),
    location,
  );
  const offerIsModified = offerModified(
    offer,
    trip,
    [
      ...DATES_FIELDS,
      ...TIME_FIELDS,
      ...GUEST_FIELDS,
      TRIP_LENGTH_FIELD,
    ],
  );
  const linkedIntro = (
    <>
      {offerIsModified && (
        <NotificationMessage
          classNameModifier="largerFontSize largerBottomMargin notSendChanges"
          outdent={false}
        >
          Your changes to this inquiry have not yet been sent.
        </NotificationMessage>
      )}
      <UserBlock user={trip.get('owner')}>
        {offerIsModified ? (
          <>
            {' Please select '}
            <ClearButton
              onClick={requestChanges}
              disabled={submitting}
              classNameModifier="inline"
            >
              Send Changes
            </ClearButton>
            {` to confirm your changes with ${ownerName}, or `}
            <ClearButton
              onClick={clearTheOffer}
              classNameModifier="inline"
            >
              clear your changes
            </ClearButton>
            {' to book the previous offer.'}
          </>
        ) : (
          <>
            <strong>
              {ownerName} sent you an offer {modified(trip, TRIP_FIELDS)
                ? ' with some updated details '
                : ' '}
              for your trip.
            </strong>
            {' '}
            <Link
              to={reviewBookingLink}
              className={s.link}
            >
              Book now
            </Link>
            {' '}
            to confirm your trip, or
            {' '}
            <Link
              to={preserveSearch(appendRoute(location.pathname, PAGES.MESSAGES), location)}
              className={s.link}
            >
              send a message
            </Link>
            {' '}
            if you have more questions.
          </>
        )}
      </UserBlock>
    </>
  );

  const cta = (
    <CTA classNameModifier="withSidebar">
      {offerIsModified ? (
        <>
          <CTAButton
            type="reset"
            onClick={clearTheOffer}
            disabled={submitting}
            classNameModifier="gray"
          >
            Clear Changes
          </CTAButton>
          <CTAButton
            type="submit"
            onClick={requestChanges}
            submitting={submitting}
          >
            Send Changes
          </CTAButton>
        </>
      ) : (
        <>
          <TripMenu trip={trip} />
          <CTALink to={reviewBookingLink}>
            Book Now
          </CTALink>
        </>
      )}
    </CTA>
  );

  return (
    <TripPanel
      trip={trip}
      subheader={offerIsModified
        ? 'Request Changes'
        : content.title[trip.getIn(['state', 'state'])]}
      intro={linkedIntro}
      cta={cta}
      backLocation={preserveSearch(PATHS.INBOX, location)}
      classNameModifier="withoutSideMargins"
    >
      <TopScroll />
      {!offerIsModified && (
        <RenterPriceSection
          amounts={amounts}
          trip={trip}
          onCurrencySelection={onCurrencySelection}
        />
      )}
      <FormError error={error} />
      <TripDetailsTitle />
      <EditableTripDetails>
        <RequestChangesIntro
          modified={offerIsModified}
          reviewBookingLink={reviewBookingLink}
        />
        <BookingMotivatorsProvider boatId={trip.getIn(['boat', 'id'])}>
          <DatesCard
            withBookingMotivators
            checkTripModification
            trip={trip}
          />
        </BookingMotivatorsProvider>
        <TimeCard
          checkTripModification
          trip={trip}
        />
        <DurationCard
          checkTripModification
          trip={trip}
        />
        <GroupSizeCard
          checkTripModification
          trip={trip}
        />
      </EditableTripDetails>
      <VesselCard
        checkTripModification
        trip={trip}
        readOnly
      />
      {captained !== null && (
        <CaptainCard
          checkTripModification
          trip={trip}
          readOnly
        />
      )}
      <CommentCard
        checkTripModification
        trip={trip}
        readOnly
      />
      <PurchaseEventTestButton />
    </TripPanel>
  );
};

export default BookNow;
