import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useState } from 'react';
import type { GetItemPropsOptions, GetMenuPropsOptions } from 'downshift';
import type { AutocompletePrediction } from 'src/common/types/helpers/googleMapHelpers';
import { sharedMediaQueries, useMediaQuery } from 'src/common/hooks';
import captureException from 'src/common/utils/captureException';
import { NEAR_ME, RECENT_SEARCHES_KEY } from '../../constants';
import { getClassNameFor } from '../../helpers';
import RecentSearches from '../RecentSearches';
import { type RetrievedRecentSearch } from '../RecentSearches/RecentSearch';
import Svg from '../Svg';
import Icon from '../IconDS22';

import s from './SearchBar.module.scss';

type SearchBarListProps = {
  getItemProps: (props: GetItemPropsOptions<any>)=> any;
  getMenuProps: (props: GetMenuPropsOptions | undefined) => any;
  highlightedIndex: number | null;
  isOpen: boolean;
  places: AutocompletePrediction[];
  close?: () => void;
  selectHighlightedItem: () => void;
};

const SearchBarList:FC<SearchBarListProps> = ({
  getItemProps,
  getMenuProps,
  highlightedIndex,
  isOpen,
  close,
  places,
  selectHighlightedItem,
}) => {
  const [recentSearches, setRecentSearches] = useState<RetrievedRecentSearch[]>([]);
  const isDesktop = useMediaQuery(sharedMediaQueries.m);

  const onScroll = useCallback(() => {
    close?.();
    window.removeEventListener('scroll', onScroll);
  }, [close]);

  useEffect(() => {
    try {
      const recentSearchesString = localStorage.getItem(RECENT_SEARCHES_KEY);
      if (recentSearchesString) {
        const parsedRecentSearches = JSON.parse(recentSearchesString);
        setRecentSearches(parsedRecentSearches);
      }
    } catch (error) {
      captureException(`Failed to read from localStorage:, ${error}`, {});
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('searchOpen');
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll);
    } else {
      document.body.classList.remove('searchOpen');
      window.removeEventListener('scroll', onScroll);
    }

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isOpen, isDesktop, onScroll]);

  return (
    <div className={getClassNameFor(s, 'popover', classNames({ isOpen }))}>
      <div className={s.popoverTop}>
        <button
          type="button"
          onClick={close}
          className={s.close}
        >
          <Icon
            id="x"
            className={s.closeIcon}
          />
        </button>
      </div>
      <ul
        {...getMenuProps({
          className: s.list,
        })}
      >
        {isOpen && places?.map((place, index) => (
          <li
            {...getItemProps({
              key: place.place_id,
              item: place,
              className: getClassNameFor(
                s,
                'listItem',
                classNames({ active: highlightedIndex === index }),
              ),
            })}
          >
            <button type="button" className={s.listButton} onClick={() => selectHighlightedItem()}>
              <span className={s.iconCircle}>
                <Svg
                  href={place.place_id === NEAR_ME ? '#near-me-pin' : '#search-location-pin'}
                  className={getClassNameFor(s, 'icon', 'pin stroked')}
                />
              </span>
              {place.description}
            </button>
          </li>
        ))}
        {recentSearches.length > 0 && (
          <RecentSearches
            searches={recentSearches}
            onClick={() => selectHighlightedItem()}
            getItemProps={getItemProps}
            numPlaces={places.length}
            highlightedIndex={highlightedIndex}
          />
        )}
      </ul>
    </div>
  );
};

export default SearchBarList;
