import React, { FC, MutableRefObject } from 'react';
import styles from './PriceItem.module.scss';
import AmountField from '../AmountField';

type Props = {
  children?: React.ReactNode;
  currencySymbol?: string;
  amountFieldRef?: MutableRefObject<HTMLInputElement | undefined>;
  fieldName?: string;
  withFieldLevelValidation?: boolean;
};

const PriceItem:FC<Props> = ({
  children = 'Your Offer',
  currencySymbol,
  amountFieldRef,
  fieldName,
  withFieldLevelValidation,
}) => (
  <label
    htmlFor={fieldName}
    className={styles.root}
  >
    <span>
      {children}
    </span>
    <AmountField
      currencySymbol={currencySymbol}
      amountFieldRef={amountFieldRef}
      fieldName={fieldName}
      withFieldLevelValidation={withFieldLevelValidation}
    />
  </label>

);

export default PriceItem;
