import PropTypes from 'prop-types';
import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import {
  SUPPORT_PHONE_NUMBER,
} from '../../constants';

const SupportPhoneNumber = ({
  className,
}) => (
  <a
    href={`tel:${SUPPORT_PHONE_NUMBER}`}
    className={className}
  >
    {formatPhoneNumberIntl(SUPPORT_PHONE_NUMBER)}
  </a>
);

SupportPhoneNumber.propTypes = {
  className: PropTypes.string,
};

export default SupportPhoneNumber;
