import React, { FC, MutableRefObject, useRef } from 'react';
import classNames from 'classnames';
import { getClassNameFor } from 'src/common/helpers';
import s from './Radio.module.scss';

const Radio: FC<Props> = ({
  disabled,
  type = 'radio',
  ...restProps
}) => {
  const inputRef: MutableRefObject<null | HTMLInputElement> = useRef(null);
  return (
    <span className={s.root}>
      <input
        ref={inputRef}
        type={type}
        disabled={disabled}
        {...restProps}
      />
      <span className={getClassNameFor(s, 'radio', classNames({ disabled }))} />
    </span>
  );
};

type Props = {
  disabled?: boolean;
  id?: string;
  type: string;
};

export default Radio;
