import React, { FC, Fragment, ReactNode } from 'react';

import Tooltip, { DefaultCTA as TooltipCTA } from '../Tooltip';

type Props = {
  tooltipContent: ReactNode;
  classNameModifier?: string;
};

const PriceTerm: FC<Props> = ({
  children,
  tooltipContent,
  classNameModifier = 'priceCard',
}) => (tooltipContent ? (
  <Tooltip
    classNameModifier={classNameModifier}
    renderCTA={props => (
      <TooltipCTA {...props}>
        {children}
      </TooltipCTA>
    )}
  >
    {tooltipContent}
  </Tooltip>
) : (
  // Keyed fragment allow single expressions
  <Fragment key="key">
    {children}
  </Fragment>
));

export default PriceTerm;
