import { offerIsEmpty } from 'src/inbox/helpers';
import { CAPTAIN_OPTION_KEYS } from '../constants';
import { ImmutableOffer } from '../../types/offer/Offer';
import { ImmutableTrip } from '../../types/trips/Trips';
import type { CaptainOption } from '../hooks/useCaptainData';

const getCaptainInitialValues = (
  trip: ImmutableTrip,
  offer: ImmutableOffer | undefined,
) => {
  let captainOption: CaptainOption = '';

  if (offer && !offerIsEmpty(offer) && offer.has('captained')) {
    const captained = offer.get('captained');
    const captainedCostIncluded = offer.get('captain_cost_included', null);
    if (captained === true && captainedCostIncluded === true) {
      captainOption = CAPTAIN_OPTION_KEYS.PROVIDED;
    } else if (captained === true && captainedCostIncluded === false) {
      captainOption = CAPTAIN_OPTION_KEYS.ARRANGED_SEPARATELY;
    } else if (captained === false) {
      captainOption = CAPTAIN_OPTION_KEYS.NOT_PROVIDED;
    }
  } else {
    const captained = trip.get('captained');
    const captainedCostIncluded = trip.get('captain_cost_included');

    if (captained === true && captainedCostIncluded === true) {
      captainOption = CAPTAIN_OPTION_KEYS.PROVIDED;
    } else if (captained === true && captainedCostIncluded === false) {
      captainOption = CAPTAIN_OPTION_KEYS.ARRANGED_SEPARATELY;
    } else if (captained === false) {
      captainOption = CAPTAIN_OPTION_KEYS.NOT_PROVIDED;
    }
  }

  return captainOption;
};

export default getCaptainInitialValues;
