import { fetchCatch } from 'src/core/sentry';
import type { PaymentAddon } from 'src/types/trips/PaymentAddon';
import { apiFetchThunk } from 'src/common/ducks/fetch';
import { PAYMENT_STATUS } from '../constants';

// Actions
export const SET_PAYMENT_ADDONS = 'inbox/paymentAddons/SET_PAYMENT_ADDONS';
export const CLEAR_PAYMENT_ADDONS = 'inbox/paymentAddons/CLEAR_PAYMENT_ADDONS';

// Reducer
const paymentAddonsReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: PaymentAddon[] = [],
  action: any,
) => {
  switch (action.type) {
    case SET_PAYMENT_ADDONS:
      return action.paymentAddons;
    case CLEAR_PAYMENT_ADDONS:
      return [];
    default:
      return state;
  }
};

// Action creators
export const setPaymentAddons = (paymentAddons: PaymentAddon[]) => ({
  type: SET_PAYMENT_ADDONS,
  paymentAddons,
});
export const clearPaymentAddons = () => ({ type: CLEAR_PAYMENT_ADDONS });

export const getPaymentAddonsCall = (tripId: number, isRenter: boolean) => (
  (dispatch: any) => dispatch(apiFetchThunk(`/trips/${tripId}/owner_addons/${isRenter
    ? `for_renter/?status=${PAYMENT_STATUS.PENDING}`
    : ''}`))
    .then((response: Response) => {
      if (!response.ok) {
        fetchCatch(response);
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw response;
      }
      return response.json();
    })
    .then((paymentAddons: PaymentAddon[]) => {
      dispatch(setPaymentAddons(paymentAddons));
      return paymentAddons;
    })
);

export default paymentAddonsReducer;
