import { Map, fromJS } from 'immutable';
import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import type { ImmutableTransaction } from 'src/types/trips/Trips';
import type { ReduxState } from 'src/types/reduxState';
import type { Transaction } from 'src/types/common/Transaction';
import { handleBadResponse } from 'src/common/helpers';
import { apiFetchThunk } from '../../common/ducks/fetch';

export const SET_TRANSACTION = 'inbox/transaction/SET_TRANSACTION';
export const PATCH_TRANSACTION = 'inbox/transaction/PATCH_TRANSACTION';
export const CLEAR_TRANSACTION = 'inbox/transaction/CLEAR_TRANSACTION';

export const setTransaction = (transaction: Transaction) => ({
  type: SET_TRANSACTION,
  transaction,
});
export const patchTransaction = (fields: Partial<Transaction>) => ({
  type: PATCH_TRANSACTION,
  fields,
});
export const clearTransaction = () => ({ type: CLEAR_TRANSACTION });

type TransactionAction = {
  type: typeof CLEAR_TRANSACTION | typeof SET_TRANSACTION | typeof PATCH_TRANSACTION;
  transaction?: Transaction;
  fields?: Partial<Transaction>;
};
type TransactionThunk = (
  ThunkAction<Promise<ImmutableTransaction>, ReduxState, unknown, Action>
);

export default function transactionReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ImmutableTransaction = Map(),
  action: TransactionAction,
) {
  switch (action.type) {
    case SET_TRANSACTION:
      return fromJS(action.transaction);
    case PATCH_TRANSACTION:
      return state.merge(fromJS(action.fields));
    case CLEAR_TRANSACTION:
      return fromJS({});
    default:
      return state;
  }
}
export const getTransactionCall = (tripId: number): TransactionThunk => (
  dispatch => (
    dispatch(apiFetchThunk(`/trips/${tripId}/transaction/`))
      .then((response: Response) => {
        if (!response.ok) {
          handleBadResponse(response);
        }
        return response.json();
      })
      .then((transaction: Transaction) => {
        dispatch(setTransaction(transaction));
        return fromJS(transaction);
      })
  )
);

export const updateTransactionCall = (
  tripId: number,
  values: Record<string, unknown>,
): TransactionThunk => (
  dispatch => (
    dispatch(apiFetchThunk(
      `/trips/${tripId}/transaction/`,
      {
        method: 'PATCH',
        body: JSON.stringify(values),
      },
    ))
      .then((response: Response) => {
        if (!response.ok) {
          handleBadResponse(response);
        }
        return response.json();
      })
      .then((transaction: Transaction) => {
        dispatch(setTransaction(transaction));
        return fromJS(transaction);
      })
  )
);
