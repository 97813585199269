import React, { FC, ReactNode } from 'react';

import { getClassNameFor } from '../../helpers';
import s from './Layout.module.scss';

type BareLayoutProps = {
  children: ReactNode;
  classNameModifier?: string;
};

const BareLayout: FC<BareLayoutProps> = ({ children, classNameModifier }) => (
  <div className={getClassNameFor(s, 'root', classNameModifier)}>
    {children}
  </div>
);

export default BareLayout;
