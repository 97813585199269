import React, { FC, useEffect } from 'react';
import { useField } from 'react-final-form';

import FormError from 'src/common/components/FormError';
import PriceEditor from 'src/inbox/components/PriceEditor';
import { BoatDetail } from '../../../types/boat/BoatDetail';
import { Currency } from '../../../types/common/Currencies';
import { INSTABOOK_PRICE_FIELD } from '../../constants';
import { useInstabookEditorContext } from '../../hooks';
import { OwnerPriceSection } from '../../../inbox/components/PriceSection';

import s from './InstabookPrice.module.scss';

type Props = {
  captainCostEnabled?: boolean;
  currency: Currency;
  boat: null | BoatDetail;
};

const InstabookPrice: FC<Props> = ({ captainCostEnabled, currency, boat }) => {
  const {
    instabook,
    instabookPriceDetails,
    setInstabookPriceDetails,
    instabookPriceError,
  } = useInstabookEditorContext();

  const { input: {
    onChange: resetPrice,
  } } = useField<number>(INSTABOOK_PRICE_FIELD);

  useEffect(() => {
    if (!boat) {
      resetPrice(0);
      setInstabookPriceDetails(undefined);
    }
  }, [boat, resetPrice, setInstabookPriceDetails]);

  return boat ? (
    <div className={s.root}>
      <h2 className={s.title}>Price</h2>
      <OwnerPriceSection
        amounts={instabookPriceDetails || instabook?.transaction?.amounts}
        classNameModifier="instabook"
        listingUrl={boat.listing_url}
      >
        <PriceEditor
          currencySymbol={currency?.symbol || ''}
          captainCostEnabled={captainCostEnabled}
        />
        {instabookPriceError && (
          <FormError error={instabookPriceError} />
        )}
      </OwnerPriceSection>
    </div>
  ) : null;
};

export default InstabookPrice;
