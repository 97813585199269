import React, { FC } from 'react';
import { getClassNameFor } from '../../../common/helpers';
import s from './CalendarControls.module.scss';

type CalendarControlsProps = {
  classNameModifier?: string;
};

const CalendarControls: FC<CalendarControlsProps> = ({
  children,
  classNameModifier = '',
}) => (
  <div className={getClassNameFor(s, 'root', `${classNameModifier}`)}>
    {children}
  </div>
);

export default CalendarControls;
