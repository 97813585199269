import { PATHS } from '../../common/constants';

type InboxRouteInfo = {
  /**
   * Returns `true` when the current route is in the inbox
   */
  isInbox: boolean;
  /**
   * Returns the active thread id. returns `null` when no thread is active
   */
  threadId: string | null;
  /**
   * Returns the view the inbox is on. Returns `null` when on the detail view.
   *
   * e.g. 'messages', 'price', 'edit', 'review-booking'
   */
  view: string | null;
};

const getInboxRouteInfo = (): InboxRouteInfo => {
  const isInbox = window.location.pathname.startsWith(PATHS.INBOX);
  const data: InboxRouteInfo = {
    isInbox,
    threadId: null,
    view: null,
  };

  if (isInbox) {
    const parts = window.location.pathname.split('/');
    const [, , threadId, view] = parts;
    if (threadId) {
      data.threadId = threadId;
    }
    if (view) {
      data.view = view;
    }
  }
  return data;
};

export default getInboxRouteInfo;
