import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { ToastContainer, cssTransition, CloseButtonProps } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';

import CloseButton from '../CloseButton';

import s from './AppNotification.module.scss';

const slide = cssTransition({
  enter: classNames(s.animated, s.slideIn),
  exit: classNames(s.animated, s.slideOut),
});

const CustomCloseButton: FC<CloseButtonProps> = ({ closeToast }) => (
  <CloseButton
    onClick={closeToast}
    classNameModifier="notification"
  />
);

const AppNotification: FC = () => {
  useEffect(() => {
    injectStyle();
  }, []);

  return (
    <ToastContainer
      limit={1}
      autoClose={false}
      transition={slide}
      className={s.root}
      toastClassName={s.toast}
      bodyClassName={s.body}
      progressClassName={s.progress}
      closeButton={CustomCloseButton}
    />
  );
};

export default AppNotification;
