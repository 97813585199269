import { List, Map } from 'immutable';
import moment from 'moment';
import add from 'date-fns/add';

import type { ImmutableTrip } from 'src/types/trips/Trips';
import type { ImmutableOffer } from 'src/types/offer/Offer';
import { MOMENT_FORMAT, PATHS, USER_ROLES } from '../common/constants';
import { isMobile, noop } from '../common/helpers';
import { formatTime } from '../common/utils/time';
import {
  BOAT_FIELD,
  GUESTS_FIELD,
  MESSAGE_BLACKLIST_STATES,
  PICKUP_TIME_FIELD,
  RETURN_TIME_FIELD,
  TIME_FIELDS,
  TRIP_LENGTH_FIELD,
  TRIP_STATES,
  PREFERRED_DATE_FIELD,
  CAPTAINED_FIELD,
  TRIP_END_DATE_FIELD,
  CAPTAIN_COST_INCLUDED_FIELD,
  CAPTAIN_OPTION_IS_AUTO_PATCHED_FIELD,
  GUEST_FIELDS,
} from './constants';
import { appendRoute, preserveSearch, siblingRoute } from '../common/utils/routing';
import { tripTimeSelector } from './components/cards/Time/selectors';
import reviewRoutes from './components/pages/Complete/reviewsConfig';

const { OWNER, RENTER } = USER_ROLES;

type GetUserRoleFunction = (trip: ImmutableTrip) => 'owner' | 'renter' | undefined;
export const getUserRole: GetUserRoleFunction = trip => trip?.getIn(['state', 'role']);

export const getOtherParty = trip => {
  const userRole = getUserRole(trip);
  return (userRole === OWNER ? trip.get(RENTER) : trip.get(OWNER)) || Map();
};

// Decide whether any of a set of fields has been modified on the trip.
export const modified = (
  trip,
  // If `fields` arg isn't provided, check against all trip fields and field comments
  fields = Object.keys((trip.get('modified') || List()).toJS()),
) => fields.some(field => trip.getIn(['modified', field]));

const compareDurations = (offerValue, tripValue) => (
  moment.duration(offerValue).asMinutes() !== moment.duration(tripValue).asMinutes()
);

export const offerModified = (offer, trip, fields) => offer && fields.some(field => {
  const offerValue = offer.get(field);
  const tripValue = trip.get(field);

  if (field === TRIP_LENGTH_FIELD) {
    return offerValue && compareDurations(offerValue, tripValue);
  }

  if (TIME_FIELDS.includes(field)) {
    const { returnTime } = tripTimeSelector(trip);
    const formattedOfferTime = formatTime(offerValue, MOMENT_FORMAT.TIME_24);

    if (field === PICKUP_TIME_FIELD && tripValue === null) {
      // Return true if offer value is set
      return !!offerValue;
    }
    if (field === RETURN_TIME_FIELD && tripValue === null) {
      // Compare with computed return time
      return offerValue && compareDurations(
        formattedOfferTime,
        formatTime(returnTime, MOMENT_FORMAT.TIME_24),
      );
    }
    return offerValue && compareDurations(formattedOfferTime, tripValue);
  }

  if (field === BOAT_FIELD) {
    return offerValue && offerValue.get('id') !== tripValue.get('id');
  }

  if (GUEST_FIELDS.includes(field)) {
    const { adults, children, seniors, infants } = offer.toJS();
    const offerObj = { adults, children, seniors, infants };
    const { total, ...tripObj } = trip.get(GUESTS_FIELD).toJS();
    return offerValue && JSON.stringify(offerObj) !== JSON.stringify(tripObj);
  }

  if (field === CAPTAINED_FIELD) {
    return offer && offer.has('captained') && offerValue !== tripValue;
  }

  if (field === 'captain_cost_included') {
    return offer && offer.has('captain_cost_included') && offerValue !== tripValue;
  }

  return (
    offer.has(field)
    && offerValue !== null
    && offerValue !== ''
    && offerValue !== tripValue
  );
});

// TODO: These are kind of jank. Clarify if possible.
const renter = Map()
  .set(false, () => 'You requested:')
  .set(true, name => `${name} suggested:`);
const owner = Map()
  .set(false, name => `${name} requested:`)
  .set(true, () => 'You suggested:');
const suggestedOrRequestedMap = Map({ renter, owner });

// Note: this is pulled out as an intermediate function so that it can be
// tested without depending on the shape of a trip.
export const suggestedOrRequested = (userRole, fieldsModified, firstName) => (
  suggestedOrRequestedMap.getIn([userRole, fieldsModified], noop)(firstName)
);

export const allowMessages = state => !MESSAGE_BLACKLIST_STATES.includes(state);

export const guestsString = totalGuests => `${totalGuests} ${totalGuests > 1 ? 'People' : 'Person'}`;

export const showContactInfoWarning = (trip, field) => trip.get('obfuscated', List()).includes(field);

export const isUserSuspended = trip => trip.getIn(['state', 'state']) === TRIP_STATES.THEY_BEEN_SUSPENDED;

export const isUserBanned = trip => trip.getIn(['state', 'state']) === TRIP_STATES.THEY_BEEN_BANNED;

export const userIsRenter = (trip: ImmutableTrip) => getUserRole(trip) === RENTER;

export const getReviewUrl = (page, role, direction, location) => {
  const { pathname } = location;
  const segment = reviewRoutes[role][page][direction];
  const route = segment && siblingRoute(pathname, segment);

  return route && preserveSearch(route, location);
};

const firstTripId = trips => (trips.get('results').first() || Map()).get('pk', '');
const firstTripPath = getState => appendRoute(PATHS.INBOX, firstTripId(getState().trips));
// Yeah, this probably constitutes thunk abuse.
export const firstTripRedirectThunk = (history, location) => (
  // We only want this to happen if there isn't a trip selected.
  (dispatch, getState) => location.pathname === PATHS.INBOX && !isMobile() && history.push(
    preserveSearch(firstTripPath(getState), location),
  ));

export const ordinalSuffix = (strings, ...values) => {
  let suffixedNumber = values[1];

  switch (suffixedNumber) {
    case 0:
      suffixedNumber = '';
      break;
    case 1:
      suffixedNumber += 'st';
      break;
    case 2:
      suffixedNumber += 'nd';
      break;
    case 3:
      suffixedNumber += 'd';
      break;
    default:
    // Nothing
  }

  return `${values[0]}${strings[1]} ${suffixedNumber} ${values[2]} ${strings[3]}`;
};

// The logic here is that if the renter or owner is
// pissed off, we won't invite them to leave a public
// review, so we put logic in place to skip
// the public review step after the private note step.
export const isNegativeReview = (rating, recommendation) => (
  rating < 3 || recommendation === false
);

/**
 * @param {ImmutableMap<Trips>} trips
 * @param {string | number} tripId
 * @returns {Trip}
 */
export const selectTrip = (trips, tripId) => trips
  .get('results')
  .find(result => result.get('pk') === Number(tripId))
  || Map();

export const getDatesWithTripDurationEnd = (
  dates: string[],
  tripLength: moment.Duration,
  pickupTime?: string,
) => (
  dates.map(date => List([
    date,
    moment(`${date} ${pickupTime ?? '00:00:00'}`).add(tripLength).format(MOMENT_FORMAT.VALUE),
  ]))
);

/**
 * Get specific trip value from the offer if there are unsent changes;
 * otherwise, get it from the trip.
 * @param {Map<string, any>} offer
 * @param {Map<string, any>} trip
 * @param {String} fieldName
 * @returns {String | Boolean | Object | Array}
 */
// eslint-disable-next-line @typescript-eslint/default-param-last
export const getTripValue = (offer = Map(), trip, fieldName) => offer.get(
  fieldName,
  trip.get(fieldName), // Fallback to trip value
);

/**
 * Get trip start date with defaulting to the start or the end of the day if pickup time isn't set
 * @param {{ trip: Map<string, any>, offer?: Map<string, any>, defaultsToDayEnd?: boolean }} data
 */
// eslint-disable-next-line @typescript-eslint/default-param-last
export const getTripStartDate = ({ defaultsToDayEnd, offer = Map(), trip }) => {
  const defaultTime = defaultsToDayEnd ? '23:59:59' : '00:00:00';
  const tripStartDate = getTripValue(offer, trip, PREFERRED_DATE_FIELD);
  const tripStartTime = getTripValue(offer, trip, PICKUP_TIME_FIELD) ?? defaultTime;
  return new Date(`${tripStartDate}T${tripStartTime}`);
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const roundToNextMinutes = (date = new Date(), { roundToNext = 15 }) => {
  const minutes = date.getMinutes();
  const roundedMinutes = Math.ceil(minutes / roundToNext) * roundToNext;

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    roundedMinutes,
  );
};

/**
 * Get selected boat from the offer if it was edited or from the trip
 * @param {Map<string, any>} offer
 * @param {Map<string, any>} trip
 * @param {import('../types/boat/BoatDetail').ImmutableBoats} userBoats
 * @returns {import('../types/boat/BoatDetail').ImmutableBoat}
 */
// eslint-disable-next-line @typescript-eslint/default-param-last
export const getSelectedBoat = (offer = Map(), trip, userBoats) => {
  const offerBoatId = offer.getIn([BOAT_FIELD, 'id']);
  const tripBoatId = trip.getIn([BOAT_FIELD, 'id']);
  // If owner selects other boat than boat linked to trip
  return offerBoatId && offerBoatId !== tripBoatId
    // We find and use that boat
    ? userBoats.find(boat => boat.get('id') === offerBoatId)
    : trip.get(BOAT_FIELD);
};

/**
 * @typedef {import('../types/boat/BoatDetail').BoatDetail} BoatDetail
 * @typedef {import('../types/ImmutableMap').ImmutableMap} ImmutableMap
 * @typedef {import('../types/trips/Trips').Trips} Trips
 * @typedef {import('../types/trips/Trips').Trip} Trip
 */

/**
 * Checks if the current date is less than two days since the end date of the trip
 * and if the trip is completed.
 *
 * @param {ImmutableTrip} trip - The immutable trip map.
 * @returns {boolean} Returns `true` if the current date is more than two days since
 * the trip end date, `false` otherwise.
 */
export const isChargeEligible = (trip: ImmutableTrip) => {
  const tripEndDate = trip.get(TRIP_END_DATE_FIELD);
  const today = new Date();
  const dateThreshold = add(new Date(tripEndDate), { days: 2 });
  return today <= dateThreshold;
};

/**
 * Checks if an offer is considered empty.
 *
 * An offer is considered empty if it has no fields after excluding certain fields
 * related to auto-patching. Specifically, if the offer has been auto-patched (indicated
 * by the `CAPTAIN_OPTION_IS_AUTO_PATCHED_FIELD`), the fields `CAPTAINED_FIELD`,
 * `CAPTAIN_COST_INCLUDED_FIELD`, and `CAPTAIN_OPTION_IS_AUTO_PATCHED_FIELD` are
 * excluded from the emptiness check. If none of these conditions apply, the offer is
 * checked for emptiness as is.
 *
 * @param {ImmutableOffer} offer - The offer to check, expected to be an immutable data structure.
 * @returns {boolean} - Returns true if the offer is considered empty, otherwise false.
 */
export const offerIsEmpty = (offer: ImmutableOffer) => {
  // If captain option is auto-patched
  if (offer?.get(CAPTAIN_OPTION_IS_AUTO_PATCHED_FIELD)) {
    return offer
      // Exclude auto-patched fields from the check
      ?.delete(CAPTAINED_FIELD)
      .delete(CAPTAIN_COST_INCLUDED_FIELD)
      .delete(CAPTAIN_OPTION_IS_AUTO_PATCHED_FIELD)
      .isEmpty();
  }
  return offer?.isEmpty();
};
