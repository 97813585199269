import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import moment from 'moment';

import {
  ALTERNATIVE_RETURN_DATE_FIELD,
  PICKUP_TIME_FIELD,
  PREFERRED_DATE_FIELD,
  SUGGESTED_DATES_FIELD,
  TRIP_LENGTH_FIELD,
} from '../../../constants';

export const dateGetter = ({ trip, offer = Map() }) => offer.get(
  PREFERRED_DATE_FIELD,
  trip.get(PREFERRED_DATE_FIELD),
);
export const returnDateGetter = ({ trip, offer = Map() }) => offer.get(
  ALTERNATIVE_RETURN_DATE_FIELD,
  trip.get(ALTERNATIVE_RETURN_DATE_FIELD),
);
export const durationGetter = ({ trip, offer = Map() }) => offer.get(
  TRIP_LENGTH_FIELD,
  trip.get(TRIP_LENGTH_FIELD),
);
export const pickupTimeGetter = ({ trip, offer = Map() }) => offer.get(
  PICKUP_TIME_FIELD,
  trip.get(PICKUP_TIME_FIELD),
);

export const calculateTripDatesAsMoment = (
  preferredDate: string,
  tripLength: string,
  suggestedDates: string[],
  pickupTime: string,
  alternativeReturnDate: string,
) => {
  const preferredDateMoment = moment(`${preferredDate} ${pickupTime ?? '00:00:00'}`);
  const tripLengthMoment = moment.duration(tripLength);
  const returnDateMoment = preferredDateMoment.clone().add(tripLengthMoment);

  return {
    preferredDateMoment,
    returnDateMoment,
    tripLengthMoment,
    suggestedDates,
    preferredDate,
    alternativeReturnDate,
  };
};

export const offerDatesSelector = createSelector(
  dateGetter,
  durationGetter,
  ({ trip }) => trip.get(SUGGESTED_DATES_FIELD, List()),
  pickupTimeGetter,
  returnDateGetter,
  calculateTripDatesAsMoment,
);
