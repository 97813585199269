import React, { FC } from 'react';
import { Field } from 'react-final-form';

import Svg from '../../../../common/components/Svg';
import s from './CaptainForm.module.scss';
import { ListingCaptainOption } from '../../../../common/utils/getListingCaptainOption';

type Props = {
  listingCaptainOptions: ListingCaptainOption[];
};

const BareRegularTripFieldset: FC<Props> = ({ listingCaptainOptions }) => (
  <>
    {listingCaptainOptions.map((option: ListingCaptainOption) => (
      <React.Fragment key={option.id}>
        <Field
          id={option.key}
          name="captainOption"
          value={option.key}
          type="radio"
          className={s.input}
          component="input"
        />
        <label
          className={s.label}
          htmlFor={option.key}
        >
          <div className={s.captainOption}>
            <Svg
              href="#captain-icon"
              className={s.captainOptionIcon}
            />
            <Svg
              href={option.icon}
              className={s.captainOptionInfoIcon}
            />
          </div>

          <div className={s.content}>
            <strong className={s.contentTitle}>{option.heading}</strong>
            <span className={s.contentText}>{option.subHeading}</span>
          </div>
        </label>
      </React.Fragment>
    ))}
  </>
);

export default BareRegularTripFieldset;
