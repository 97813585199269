import React, { FC } from 'react';
import { add, format } from 'date-fns';
import type { ImmutableTrip } from 'src/types/trips/Trips';
import { convertTimeSpanToDuration, newTZDate } from 'src/common/utils/dateHelpers';
import { DISPLAY_DATE_FORMAT_LONG, TIME_12 } from 'src/common/constants';
import Avatar from 'src/common/components/Avatar';
import s from './ChargeBookingDetails.module.scss';

type ChargeBookingDetailsProps = {
  isOwner: boolean;
  trip: ImmutableTrip;
};

const ChargeBookingDetails: FC<ChargeBookingDetailsProps> = ({ isOwner, trip }) => {
  const owner = trip.get('owner').toJS();
  const renter = trip.get('renter').toJS();
  const departureDate = trip.get('preferred_date');
  const startTime = trip.get('pickup_time');
  const duration = trip.get('trip_length');

  const returnDateObject = add(
    new Date(`${departureDate}T${startTime}`),
    convertTimeSpanToDuration(duration),
  );
  const formattedDate = format(newTZDate(departureDate), DISPLAY_DATE_FORMAT_LONG);
  const formattedStartTime = format(new Date(`${departureDate}T${startTime}`), TIME_12);
  const formattedEndTime = format(returnDateObject, TIME_12);
  return (
    <div className={s.root}>
      <div className={s.avatars}>
        <Avatar
          size="medium"
          user={{
            photo_url: owner.photo_url,
            has_photo: !!owner.has_photo,
            first_name: owner.first_name,
            last_name: owner.last_name,
          }}
        />
        <Avatar
          size="medium"
          user={{
            photo_url: renter.photo_url,
            has_photo: !!renter.has_photo,
            first_name: renter.first_name,
            last_name: renter.last_name,
          }}
        />
      </div>
      <div className={s.details}>
        <p>{isOwner ? renter.email : `Your booking with ${owner.first_name}`}</p>
        <p className={s.date}>{formattedDate}</p>
        <p>{formattedStartTime} - {formattedEndTime}</p>
      </div>
    </div>
  );
};

export default ChargeBookingDetails;
