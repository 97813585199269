import React, { FC, useEffect, useRef } from 'react';

import { getClassNameFor } from '../../helpers';

import s from './ScrollToMe.module.scss';

type ScrollToMeProps = {
  classNameModifier?: string;
};

/**
 * This component will attempt to scroll to where this component is located.
 *
 * Use the `key` property to prevent unnecessary scrolling. Keeping the `key` the same value will
 * prevent this component from remounting and triggering undesirable scrolling.
 */
const ScrollToMe: FC<ScrollToMeProps> = ({
  classNameModifier = '',
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView(true);
    }
  }, []);
  return (
    <div
      className={getClassNameFor(s, 'root', classNameModifier)}
      ref={ref}
    />
  );
};

export default ScrollToMe;
