import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Form } from 'react-final-form';
import { toast } from 'react-toastify';

import Avatar from 'src/common/components/Avatar';
import Button from 'src/common/components/Button';
import Modal from 'src/common/components/Modal';
import Notification from 'src/common/components/Notification';
import RadioButton from 'src/common/components/fields/RadioButton';
import { getLinkedCaptains, getUser } from 'src/common/utils/reduxStoreSelectors';
import { apiFetchBase } from 'src/core/fetch';
import { fetchCatch } from 'src/core/sentry';
import { getTripCall } from 'src/inbox/ducks/trips';
import type { ImmutableTrip } from 'src/types/trips/Trips';
import type { Captain, LinkedCaptains } from 'src/types/boat/LinkedCaptains';
import { fullName } from 'src/common/utils/user';
import { trackEvent } from 'src/common/tracking';
import InviteCaptain from '../InviteCaptain';
import s from './CaptainSelector.module.scss';

const CAPTAIN_FIELD_NAME = 'captain_id';

type CaptainSelectorFormProps = {
  closeModal: () => void;
  getCaptains: () => Promise<LinkedCaptains>;
  selectedCaptainId?: number;
  tripId: number;
};

type CaptainSelectorFormValues = {
  [CAPTAIN_FIELD_NAME]: number;
};

const CaptainSelectorForm: FC<CaptainSelectorFormProps> = ({
  closeModal,
  getCaptains,
  selectedCaptainId,
  tripId,
}) => {
  const dispatch = useDispatch();
  const getTrip: (tripId: number) => Promise<ImmutableTrip> = compose(dispatch, getTripCall);
  const {
    boat_captains: boatCaptains = [],
    other_captains: otherCaptains = [],
  } = useSelector(getLinkedCaptains);
  const user = useSelector(getUser).toJS();

  const popUpSelectedCaptain = useCallback((captain: Captain) => (
    (captain.is_default || (captain.id === selectedCaptainId)) ? -1 : 1
  ), [selectedCaptainId]);

  /**
   * Filters out the owner (current user) from a list of captains to avoid duplication.
   * As we add it to the start of the boat_captains (favorites) list.
   *
   * @param { Captain } captain - The captain to check against the current user.
   * @returns { boolean } - Returns true if the captain is not the current user, otherwise false.
   */
  const filterOutOwner = useCallback((captain: Captain): boolean => captain.id !== user.id, [user]);

  const allBoatCaptains = useMemo(
    () => [
      user,
      ...boatCaptains.filter(filterOutOwner).sort(popUpSelectedCaptain),
    ],
    [boatCaptains, filterOutOwner, popUpSelectedCaptain, user],
  );

  const filteredOtherCaptains = useMemo(
    () => otherCaptains.filter(filterOutOwner),
    [otherCaptains, filterOutOwner],
  );

  const initialValues = useMemo(() => ({
    [CAPTAIN_FIELD_NAME]: allBoatCaptains.find(captain => (
      captain.is_default || (captain.id === selectedCaptainId)))?.id,
  }), [allBoatCaptains, selectedCaptainId]);

  const setCaptainCall = useCallback(async (formData: CaptainSelectorFormValues) => apiFetchBase(
    `/trips/${tripId}/change-captain/`,
    {
      method: 'POST',
      body: JSON.stringify(formData),
    },
  ), [tripId]);

  const unsetCaptainCall = useCallback(async () => apiFetchBase(
    `/trips/${tripId}/unset-captain/`,
    { method: 'POST' },
  ), [tripId]);

  const onSubmit = useCallback(
    (values) => {
      trackEvent('captain_selector_submit', {
        // p1: selected captain id
        p1: values[CAPTAIN_FIELD_NAME],
        // p2: captain is self
        ...(values[CAPTAIN_FIELD_NAME] && { p2: values[CAPTAIN_FIELD_NAME] === user.id }),
      });
      return (
        values[CAPTAIN_FIELD_NAME]
          ? setCaptainCall(values)
          : unsetCaptainCall()
      )
        .then(async (response) => {
          const data = await response.json();
          const captainAlreadySetError = response.status === 400 && data.detail;
          toast(
            <Notification
              heading={data.detail}
              withIcon={captainAlreadySetError}
            />,
            { autoClose: 5000 },
          );
          return response;
        })
        .then((response) => {
          if (response.ok) {
            // Refetch captains when other captain added to favorites
            if (filteredOtherCaptains.some(({ id }) => id === values[CAPTAIN_FIELD_NAME])) {
              getCaptains();
            }
            // Close modal when trip is updated
            getTrip(tripId).then(
              () => {
                closeModal();
                trackEvent('captain_selector_close_after_submit');
              },
            );
          }
          return response;
        })
        .catch(fetchCatch);
    },
    [
      closeModal,
      getCaptains,
      getTrip,
      filteredOtherCaptains,
      setCaptainCall,
      tripId,
      unsetCaptainCall,
      user.id,
    ],
  );

  return (
    <Form<CaptainSelectorFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ form, handleSubmit, submitting, submitError }) => (
        <form
          className={s.root}
          onSubmit={handleSubmit}
        >
          <Modal.Header>
            <h5 className={s.heading}>
              Select Captain
            </h5>
            <InviteCaptain />
          </Modal.Header>
          <Modal.Body>
            <h6 className={s.listTitle}>
              Favorites
            </h6>
            <ul className={s.list}>
              {allBoatCaptains.map((captain) => (
                <li key={captain.id}>
                  <label className={s.label}>
                    <Avatar
                      user={captain}
                      size="default"
                      classNameModifier="noMargin"
                    />
                    <span className={s.content}>
                      {captain.id === user.id
                        ? 'Set Yourself as Captain'
                        : (
                          <>
                            {fullName(captain)}
                            <small className={s.email}>
                              {captain.email}
                            </small>
                          </>
                        )}
                    </span>
                    <RadioButton
                      name={CAPTAIN_FIELD_NAME}
                      value={captain.id}
                      parse={(value) => Number(value)}
                    />
                  </label>
                </li>
              ))}
            </ul>
            {filteredOtherCaptains?.length > 0 && (
              <>
                <h6 className={s.listTitle}>
                  All Others
                </h6>
                <ul className={s.list}>
                  {filteredOtherCaptains.map((captain) => (
                    <li key={captain.id}>
                      <label className={s.label}>
                        <Avatar
                          user={captain}
                          size="default"
                          classNameModifier="noMargin"
                        />
                        <span className={s.content}>
                          {captain.first_name} {captain.last_name}
                          <small className={s.email}>
                            {captain.email}
                          </small>
                        </span>
                        <RadioButton
                          name={CAPTAIN_FIELD_NAME}
                          value={captain.id}
                          parse={(value) => Number(value)}
                        />
                      </label>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {submitError && (
              <div className={s.error}>
                {submitError}
              </div>
            )}
            <Button
              type="reset"
              onClick={() => {
                form.change(CAPTAIN_FIELD_NAME, undefined);
                trackEvent('captain_selector_clear');
              }}
              classNameModifier="secondary noMargin"
            >
              Clear
            </Button>
            <Button
              type="submit"
              classNameModifier="noMargin"
              disabled={submitting}
              submitting={submitting}
            >
              Save
            </Button>
          </Modal.Footer>
        </form>
      )}
    </Form>
  );
};

export default CaptainSelectorForm;
