import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'src/common/hooks';
import { FIELD_NAMES } from '../constants';
import TripProtection from '../TripProtection';
import AddressFieldset from '../AddressFieldset';
import LiabilityAndDamageCoverage from '../LiabilityAndDamageCoverage';

type InsuranceFormValues = {
  [K in keyof typeof FIELD_NAMES]?: K extends 'trip_protection' | 'liability_coverage'
    ? boolean
    : string;
};

const InsuranceForm: React.FC = () => {
  const { t } = useTranslation('insurance');

  const onSubmit = (values: InsuranceFormValues) => {
    // eslint-disable-next-line no-console
    console.log(values);
  };

  return (
    <Form<InsuranceFormValues> onSubmit={onSubmit}>
      {({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <h2>{t('title')}</h2>
          <p>{t('intro')}</p>

          <TripProtection />
          <LiabilityAndDamageCoverage />
          {(values.trip_protection || values.liability_coverage) && (
            <>
              <AddressFieldset />
              <br />
              <button
                type="submit"
                disabled={submitting}
              >
                {t('submitButton')}
              </button>
            </>
          )}
        </form>
      )}
    </Form>
  );
};

export default InsuranceForm;
