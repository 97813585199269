/**
 * @param {URL} url
 * @param {string[]} allowedHosts
 * @returns {string}
 */
const getRedirectUrl = (url, allowedHosts) => {
  const { pathname, search } = url;
  const allowedHost = allowedHosts.find(host => host.endsWith('.getmyboat.com'));
  return `${url.protocol}//${allowedHost}${pathname}${search}`;
};

export default getRedirectUrl;
