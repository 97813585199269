const categories: { [key: string]: string } = {
  'category-1': 'Powerboats, Pontoons, & RIBs',
  'category-2': 'Luxury, Events & Large groups',
  'category-3': 'Jetskis & Personal Watercraft',
  'category-4': 'Sailing',
  'category-5': 'Houseboats & Sleepaboards',
  'category-6': 'Tours, Lessons, and Diving',
  'category-7': 'Fishing',
  'category-8': 'Boards & Paddles',
  'category-9': 'Ferries, Taxis, and Cruises',
  'category-10': 'Power',
  'category-11': 'Non Powered Watercraft',
  'category-12': 'Sail',
  'category-13': 'Fishing Boats',
  'category-14': 'Mega Yacht',
  'category-15': 'Water Sports',
  'category-16': 'Lessons',
  'category-17': 'Tours',
  'category-18': 'Pontoon',
  'category-19': 'Jet Ski Rentals',
  'category-20': 'Houseboat',
  'category-21': 'Motor Yacht',
  'category-22': 'Cuddy Cabin/Walk Around',
  'category-23': 'Center Console',
  'category-24': 'Deck Boat',
  'category-25': 'Trawler',
  'category-26': 'Bowrider',
  'category-27': 'Inboard Propulsion',
  'category-28': 'Dinghy',
  'category-29': 'Canal Boat',
  'category-30': 'Passenger Boat',
  'category-31': 'Power Catamaran',
  'category-32': 'RIB',
  'category-33': 'Airboat',
  'category-34': 'Electric Boat',
  'category-35': 'Canoe',
  'category-36': 'Kayak',
  'category-37': 'Paddleboard',
  'category-38': 'Paddle Boat',
  'category-39': 'Row Boat',
  'category-40': 'Raft',
  'category-41': 'Cruising Monohull',
  'category-42': 'Cruising Catamaran',
  'category-43': 'Beach Catamaran',
  'category-44': 'Beach Monohulls',
  'category-45': 'Daysailer',
  'category-46': 'Wind Jammer',
  'category-47': 'Gulet',
  'category-49': 'Sloop',
  'category-50': 'Schooner',
  'category-51': 'Trimaran',
  'category-52': 'Tall Ship',
  'category-53': 'Bass Boat',
  'category-54': 'Jon Boat',
  'category-55': 'Sport Fisherman',
  'category-57': 'Head Boat',
  'category-58': 'Sailing Mega Yacht',
  'category-59': 'Power Mega Yacht',
  'category-60': 'Diving',
  'category-61': 'Surf Charter',
  'category-62': 'Flyboarding',
  'category-63': 'Parasailing',
  'category-64': 'Rafting',
  'category-65': 'Snorkeling',
  'category-66': 'Windsurfing',
  'category-67': 'Kiteboarding',
  'category-68': 'Water Skiing',
  'category-69': 'Wakeboarding',
  'category-70': 'Sailing Lessons',
  'category-71': 'Wakeboarding Lessons',
  'category-72': 'Waterskiing Lessons',
  'category-73': 'SUP Yoga',
  'category-74': 'Powerboat Lessons',
  'category-75': 'Jet Ski Lessons',
  'category-76': 'Surf Lessons',
  'category-77': 'Scuba Lessons',
  'category-78': 'Water Taxi',
  'category-79': 'Jet Ski Tour',
  'category-80': 'Eco Tour',
  'category-81': 'Jet Boat Tour',
  'category-82': 'Wildlife Tour',
  'category-83': 'Whale Watching',
  'category-84': 'Dolphin Tour',
  'category-85': 'Swamp Tour',
  'category-86': 'Dinner Cruise',
  'category-87': 'River Cruise',
  'category-88': 'Sightseeing',
  'category-89': 'Cruising',
  'category-90': 'City Tour',
  'category-1000': 'Yachts',
  'category-1001': 'All Powerboats',
  'category-1002': 'Pontoons',
  'category-1003': 'Deck Boats',
  'category-1004': 'Bowriders',
  'category-1005': 'Center Consoles',
  'category-1006': 'RIBs',
  'category-1007': 'Jet Skis',
  'category-1008': 'Fishing charters',
  'category-1009': 'Houseboats',
  'category-1010': 'Wakeboard boats',
  'category-1011': 'Sailboats',
  'category-1012': 'Catamarans',
  'category-1013': 'Tours',
  'category-1014': 'Lessons',
  'category-1015': 'Diving & Snorkeling',
  'category-1016': 'Boards & Paddles',
  'category-1017': 'Ferries, Taxis and Cruises',
};

export default categories;
