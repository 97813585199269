import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import apiFetch from '../../core/fetch';
import { unpackApiError } from '../../common/helpers';
import { useCalendarContext } from './useCalendar';
import useCalendarFocus from './useCalendarFocus';
import captureException from '../../common/utils/captureException';
import captureUnsuccessfulResponses from '../captureUnsuccessfulResponses';

const CALENDAR_AD_HOC_API_BASE_URL = '/calendar/adhoc-events/';

const unsuccessfulResponseCapturer = captureUnsuccessfulResponses(
  'Unavailable Editor Unsuccessful API Response',
);

/**
 * @param {number} adHocEventId
 */
const getAdHocEventCall = async adHocEventId => apiFetch(
  `${CALENDAR_AD_HOC_API_BASE_URL}${adHocEventId}/`,
)
  .then(unsuccessfulResponseCapturer)
  .then(r => r.json());

/**
 * @param {number} adHocEventId
 */
const deleteAdHocEventCall = async adHocEventId => apiFetch(
  `${CALENDAR_AD_HOC_API_BASE_URL}${adHocEventId}/`,
  { method: 'DELETE' },
)
  .then(unsuccessfulResponseCapturer);

/**
 * @param {AdhocCalendarEvent} adHocEvent
 * @returns {Promise<Response>}
 */
const persistAdHocEventCall = async adHocEvent => {
  const url = adHocEvent.id ? `${CALENDAR_AD_HOC_API_BASE_URL}${adHocEvent.id}/` : CALENDAR_AD_HOC_API_BASE_URL;
  const method = adHocEvent.id ? 'PATCH' : 'POST';
  return apiFetch(url, { method, body: JSON.stringify(adHocEvent) })
    .then(unsuccessfulResponseCapturer);
};

/** @type {UseUnavailableEditor['adHocEvent']} */
const initialAdHocEvent = null;

const useUnavailableEditor = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState('');
  const [adHocEvent, setAdHocEvent] = useState(initialAdHocEvent);
  const [isSaving, setIsSaving] = useState(false);
  const selectedAdHocEventId = useRef(0);
  const { refresh } = useCalendarContext();
  const { setCalendarFocus } = useCalendarFocus();

  /**
   * @type {UseUnavailableEditor['loadAdHocEvent']}
   */
  const loadAdHocEvent = useCallback(async adHocEventId => {
    selectedAdHocEventId.current = adHocEventId;
    setIsLoading(true);
    try {
      const response = await getAdHocEventCall(adHocEventId);
      // Only set the response if it is still the event we are interested in.
      if (selectedAdHocEventId.current === adHocEventId) {
        setAdHocEvent(response);
      }
      setIsLoading(false);
      setIsLoaded(true);
    } catch (err) {
      setIsLoading(false);
      setError('An unexpected error occurred');
      captureException(err);
    }
  }, []);

  /**
   * @type {UseUnavailableEditor['deleteAdHocEvent']}
   */
  const deleteAdHocEvent = useCallback(async (adHocEventId, onAdHocEventDelete) => {
    selectedAdHocEventId.current = adHocEventId;
    setIsDeleting(true);
    try {
      await deleteAdHocEventCall(adHocEventId);
      if (typeof onAdHocEventDelete === 'function') {
        onAdHocEventDelete(adHocEventId);
      }
      refresh();
      setIsDeleting(false);
      setAdHocEvent(null);
    } catch (err) {
      setError('An unexpected error occurred');
      setIsDeleting(false);
      setAdHocEvent(null);
      captureException(err);
    }
  }, [refresh]);

  /**
   * @type {UseUnavailableEditor['saveAdHocEvent']}
   */
  const saveAdHocEvent = useCallback(async (event, onAdHocEventUpdate) => {
    setError('');
    setIsSaving(true);
    try {
      const response = await persistAdHocEventCall(event);
      if (!response.ok) {
        setIsSaving(false);
        const { detail } = await unpackApiError(response);
        setError(detail);
      } else {
        const data = await response.json();
        setCalendarFocus({
          selectedDate: data.event_date,
          focusedAdHocEventId: data.id,
          scrollToEvent: true,
        });
        if (typeof onAdHocEventUpdate === 'function') {
          onAdHocEventUpdate(data.id, data.calendar_events);
        }
        refresh();
        setIsSaving(false);
        setAdHocEvent(null);
      }
    } catch (err) {
      setError('An unexpected error occurred');
      setIsSaving(false);
      setAdHocEvent(null);
      captureException(err);
    }
  }, [setCalendarFocus, refresh]);

  const resetAdHocEvent = useCallback(() => {
    setAdHocEvent(null);
  }, []);

  return useMemo(() => ({
    loadAdHocEvent,
    saveAdHocEvent,
    deleteAdHocEvent,
    resetAdHocEvent,
    isSaving,
    isLoaded,
    isLoading,
    isDeleting,
    error,
    adHocEvent,
  }), [
    loadAdHocEvent,
    saveAdHocEvent,
    deleteAdHocEvent,
    resetAdHocEvent,
    isSaving,
    isLoaded,
    isLoading,
    isDeleting,
    error,
    adHocEvent,
  ]);
};

/** @type {UseUnavailableEditor} */
const initialContext = {
  adHocEvent: null,
};
const UnavailableEditorContext = createContext(initialContext);

export const useUnavailableEditorContext = () => useContext(UnavailableEditorContext);

export const UnavailableEditorProvider = ({ children }) => (
  <UnavailableEditorContext.Provider value={useUnavailableEditor()}>
    {children}
  </UnavailableEditorContext.Provider>
);

UnavailableEditorProvider.propTypes = {
  children: PropTypes.node,
};

/**
 * @typedef {import('../types').AdhocCalendarEvent} AdhocCalendarEvent
 * @typedef {import('../types').UseUnavailableEditor} UseUnavailableEditor
 */
