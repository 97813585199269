import React, { FC, useCallback, useMemo } from 'react';
import { add } from 'date-fns';
import { ics, google, yahoo, outlook } from 'calendar-link';
import { convertTimeSpanToDuration } from 'src/common/utils/dateHelpers';
import { ImmutableTrip } from 'src/types/trips/Trips';
import { trackEvent } from 'src/common/tracking';
import { userIsRenter } from 'src/inbox/helpers';
import { PICKUP_TIME_FIELD, PREFERRED_DATE_FIELD, RETURN_TIME_FIELD, TRIP_LENGTH_FIELD } from 'src/inbox/constants';
import { NEW_TAB_ATTRIBUTES } from 'src/common/constants';
import ContactSupport from 'src/common/components/ContactSupport';

import s from './AddToCalendar.module.scss';

type AddToCalendarProps = {
  trip: ImmutableTrip;
};

type EventLinkKeys = 'Google' | 'Yahoo' | 'Outlook' | 'iCal';

const GA4_EVENT_ADD_TO_CALENDAR = 'add_to_calendar';
const linkForRenter = 'https://getmyboat.zendesk.com/hc/en-us/articles/115000089853-Start-here-Important-information-for-your-trip';
const linkForOwner = 'https://getmyboat.zendesk.com/hc/en-us/articles/115005387108-Start-here-Important-info-to-review-before-you-depart';

const AddToCalendar: FC<AddToCalendarProps> = ({ trip }) => {
  const shortname = trip.getIn(['boat', 'shortname']);
  const headline = trip.getIn(['boat', 'headline']);
  const departTime = `T${trip.get(PICKUP_TIME_FIELD) || '09:00:00'}`;
  const departDate = trip.get(PREFERRED_DATE_FIELD);
  const departDateObj = useMemo(
    () => new Date(`${departDate}${departTime}`),
    [departDate, departTime],
  );
  const tripLength = trip.get(TRIP_LENGTH_FIELD) || '00:00:00';
  const timeSpanAsDuration = convertTimeSpanToDuration(tripLength);
  let returnDateObj = add(departDateObj, timeSpanAsDuration);
  const requestedReturnTime = trip.get(RETURN_TIME_FIELD) || departTime;

  if (requestedReturnTime !== departTime) {
    const returnTime = `T${requestedReturnTime}`;
    returnDateObj = add(new Date(`${departDate}${returnTime}`), timeSpanAsDuration);
  }
  const isRenter = userIsRenter(trip);
  const eventLinks: { [key in EventLinkKeys]: string } = useMemo(() => {
    const event = {
      title: `Getmyboat Booking with ${
        isRenter ? trip.getIn(['owner', 'first_name']) : trip.getIn(['renter', 'first_name'])} - ${shortname || headline}`,
      start: departDateObj.toISOString(),
      end: returnDateObj.toISOString(),
    };
    return {
      Google: google(event),
      Yahoo: yahoo(event),
      Outlook: outlook(event),
      iCal: ics(event),
    };
  }, [departDateObj, headline, returnDateObj, shortname, isRenter, trip]);

  const onClick = useCallback(
    (key: EventLinkKeys) => {
      trackEvent(GA4_EVENT_ADD_TO_CALENDAR, {
        p1: key,
        p2: isRenter ? 'Renter' : 'Owner',
      });
    },
    [isRenter],
  );

  return (
    <div className={s.root}>
      <p className={s.linkRow}>
        Add trip to
        {Object.keys(eventLinks).map((key) => (
          <a
            onClick={() => onClick(key as EventLinkKeys)}
            className={s.link}
            href={eventLinks[key as EventLinkKeys]}
            key={key}
            {...NEW_TAB_ATTRIBUTES}
          >
            {key}
          </a>
        ))}
      </p>
      <p className={s.subtitle}>
        <a
          href={!isRenter ? linkForOwner : linkForRenter}
          className={s.link}
          {...NEW_TAB_ATTRIBUTES}
        >
          {!isRenter
            ? 'Prepare for your booking with our Briefing for Captains and Hosts.'
            : 'Check out the Preparation Guide for trips and rentals.'}
        </a>
      </p>
      <ContactSupport classNameModifier="addToCalendar" />
    </div>
  );
};

export default AddToCalendar;
