import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { compose } from 'lodash/fp';

import { patchOffer } from '../../../ducks/offer';
import { close } from '../../../../common/ducks/zippy';

import FormButtons from '../../../../common/components/FormButtons';
import RegularTripFieldset from './RegularTripFieldset';
import s from './CaptainForm.module.scss';
import { ListingCaptainOption } from '../../../../common/utils/getListingCaptainOption';
import { CAPTAIN_OPTION_KEYS } from '../../../../common/constants';
import serializeCaptainOptions from '../../../utils/serializeCaptainOptions';

const getModalHeading = (options: ListingCaptainOption[]) => {
  const captainProvided = options.some((o) => o.key === CAPTAIN_OPTION_KEYS.PROVIDED);
  const arrangedSeparately = options.some((o) => o.key === CAPTAIN_OPTION_KEYS.ARRANGED_SEPARATELY);
  const notProvided = options.some((o) => o.key === CAPTAIN_OPTION_KEYS.NOT_PROVIDED);

  let heading = 'Captain Options';
  if (captainProvided && arrangedSeparately && !notProvided) {
    heading = 'Captain Choices Available';
  }
  return heading;
};

type Props = {
  modalName: string;
  initialValues: {
    captainOption: string;
  };
  listingCaptainOptions?: ListingCaptainOption[];
};

export const BareCaptainForm: FC<Props> = ({ modalName, initialValues, listingCaptainOptions }) => {
  const dispatch = useDispatch();
  const patchTheOffer = compose(dispatch, patchOffer);
  const closeModal = () => dispatch(close(modalName));

  const modalHeading = listingCaptainOptions
    ? getModalHeading(listingCaptainOptions)
    : '';

  return (
    <Form
      onSubmit={(values, { getState }) => {
        if (getState().dirty) {
          const { captainOption } = values;
          const options = serializeCaptainOptions(captainOption);
          patchTheOffer(options);
        }
        closeModal();
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className={s.root}
        >
          <h3 className={s.title}>{modalHeading}</h3>
          <div className={s.captain}>
            <div className={s.selection}>
              <RegularTripFieldset listingCaptainOptions={listingCaptainOptions} />
            </div>
          </div>
          <FormButtons
            classNameModifier="inbox"
            closeModal={closeModal}
          />
        </form>
      )}
    </Form>
  );
};

export default BareCaptainForm;
