import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { PATHS } from '../../../../common/constants';
import { preserveSearch } from '../../../../common/utils/routing';
import UserBlock from '../UserBlock';

const ReservationCancelledMessage = ({
  location,
  trip,
}) => (
  <UserBlock user={trip.getIn(['reservation_cancel_message', 'user'])}>
    <strong>
      A new offer was sent for this trip.
    </strong>
    {' '}
    The original booking was canceled and a new conversation has been created.
    {' '}
    <Link to={preserveSearch(`${PATHS.INBOX}${trip.get('child')}/`, location)}>
      Go there now to continue with the booking
    </Link>.
  </UserBlock>
);

ReservationCancelledMessage.propTypes = {
  location: PropTypes.object.isRequired,
  trip: PropTypes.object.isRequired,
};

export default ReservationCancelledMessage;
