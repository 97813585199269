import { useCallback, useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * React hook that fires a callback whenever ResizeObserver
 * detects a change to target element's size.
 * @param {React.MutableRefObject | HTMLElement} target
 * @param {() => void)} callback
 * @returns {void}
 */
const useResizeObserver = (target, callback = () => {}) => {
  const observerRef = useRef(null);
  const targetEl = target && 'current' in target ? target.current : target;
  const updater = useCallback(entries => {
    entries.forEach(callback);
  }, [callback]);

  useEffect(() => {
    if (!targetEl) {
      return () => {};
    }
    if (!observerRef.current) {
      observerRef.current = new ResizeObserver(updater);
    }
    const { current: resizeObserver } = observerRef;
    resizeObserver.observe(targetEl);

    return () => {
      resizeObserver.unobserve(targetEl);
    };
  }, [callback, targetEl, updater]);
};

export default useResizeObserver;
