import React, { FC, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import {
  UNAVAILABLE_EDITOR_START_TIME as START_TIME,
  UNAVAILABLE_EDITOR_END_TIME as END_TIME,
  UNAVAILABLE_EDITOR_ALL_DAY,
} from 'src/calendar/constants';
import TimeInput from 'src/common/components/inputs/TimeInput';
import Checkbox from 'src/common/components/inputs/Checkbox';
import { AdHocEventFormState } from 'src/calendar/types';
import classNames from 'classnames';
import { OnFocus } from 'react-final-form-listeners';
import s from './UnavailableTimeFields.module.scss';

const timeFields = [
  {
    name: START_TIME,
    label: 'From',
  },
  {
    name: END_TIME,
    label: 'To',
  },
] as const;

const UnavailableTimeFields: FC = () => {
  const [focusedInput, setFocusedInput] = useState('');
  const {
    values: { all_day: allDay },
  } = useFormState<AdHocEventFormState>();
  const { change } = useForm();

  const handleClearTime = () => {
    change(START_TIME, '');
    change(END_TIME, '');
  };

  return (
    <div className={s.root}>
      <div className={s.timeFields}>
        {
          timeFields.map(({ name, label }) => (
            <div key={`${name}-${allDay}`} className={s.adhocTime}>
              <OnFocus name={name}>
                {() => {
                  change(UNAVAILABLE_EDITOR_ALL_DAY, false);
                  setFocusedInput(name);
                }}
              </OnFocus>
              <Field name={name}>
                {({ input, meta }) => (
                  <>
                    <label className={s.timeLabel} htmlFor={name}>{label}</label>
                    <TimeInput
                      readOnly={allDay}
                      autoFocus={focusedInput === name && !allDay}
                      input={input}
                      meta={meta}
                      showErrorIcon
                      showErrorWhenSubmitFailed
                      classNameModifier={classNames(
                        'adhocEvent',
                        { disabled: allDay },
                        { error: !meta.active && meta.touched && !input.value && !allDay },
                      )}
                    />
                  </>
                )}
              </Field>
            </div>
          ))
        }
      </div>
      <div className={s.divider}>
        <div className={s.line} />
        <span>or</span>
        <div className={s.line} />
      </div>
      <div className={s.checkboxField}>
        <Field name={UNAVAILABLE_EDITOR_ALL_DAY}>
          {({ input }) => (
            <label className={s.checkbox} htmlFor={UNAVAILABLE_EDITOR_ALL_DAY}>
              <Checkbox input={{
                ...input,
                checked: input.value,
                onChange: () => {
                  input.onChange(!input.value);
                  handleClearTime();
                },
              }}
              />
              All day
            </label>

          )}
        </Field>
      </div>
    </div>
  );
};

export default UnavailableTimeFields;
