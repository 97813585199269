import { Trip } from 'src/types/trips/Trips';
import apiFetch from 'src/core/fetch';
import type { SessionData } from '../types';

const bookInstabook = async (data: SessionData): Promise<Trip> => {
  const { formData:
    { guests: {
      adults, seniors, children, infants,
    }, departureTime, ownerMessage, selectedOccasion } } = data;

  const occasionText = selectedOccasion ? `Occasion: ${selectedOccasion}` : '';
  const messageText = ownerMessage ? ` <br/> ${ownerMessage}` : '';
  const inquiryComment = `${occasionText}${messageText}`;

  const response = await apiFetch(
    `/instabook/trips/${data.availabilityId}/book/`,
    {
      method: 'PATCH',
      body: JSON.stringify({
        adults,
        seniors,
        children,
        infants,
        pickup_time: departureTime,
        inquiry_comment: inquiryComment,
      }),
    },
  );
  if (!response.ok) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw response;
  }
  const trip = await response.json();
  return trip;
};

export default bookInstabook;
