const config = {
  /**
   * How long after knowing there is an update available before prompting the user to reload.
   */
  RELOAD_PROMPT_TIMEOUT: 2 * 60 * 1000, // Two minutes
  /**
   * How soon after knowing there is an update available before the automatic reload should kick in.
   */
  AUTO_RELOAD_TIMEOUT: 30 * 60 * 1000, // Half an hour
  /**
   * At what interval the app should check if a new version is available.
   */
  CHECK_VERSION_INTERVAL: 5 * 60 * 1000, // Five minutes
  /**
   * The git sha used to identify the current version,
   */
  CURRENT_VERSION: `${__GIT_COMMIT_SHA__}`,
};

export default config;
