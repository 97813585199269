import React, { useState, useRef, useEffect, useCallback } from 'react';
import { getClassNameFor } from 'src/common/helpers';
import classNames from 'classnames';
import styles from './CodeInput.module.scss';

export type CodeInputProps = {
  length?: number;
  /**
   * When `hasError` is true, focusing on the input clears its value
   * and shifts the focus to the first input field.
   */
  hasError?: boolean;
  centered?: boolean;
  disabled?: boolean;
  /**
   * Auto focuses on the first input when initially rendered.
   */
  autoFocus?: boolean;
  onChange: (value: string) => void;
  onComplete?: () => void;
};

const CodeInput: React.FC<CodeInputProps> = ({
  length = 6,
  hasError,
  centered,
  disabled,
  autoFocus,
  onChange,
  onComplete,
}) => {
  const defaultCode = Array(length).fill('');
  const [code, setCode] = useState<string[]>(defaultCode);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, length);
  }, [length]);

  useEffect(() => {
    if (autoFocus) {
      inputRefs.current[0]?.focus();
    }
  }, [autoFocus]);

  const setInputRef = useCallback((el: HTMLInputElement | null, index: number) => {
    inputRefs.current[index] = el;
  }, []);

  const handleChange = useCallback((index: number, value: string) => {
    // Prevent user from accidentally entering individual numbers greater than 9
    // and prevent negative numbers that can be entered by pressing the down arrow key
    if (Number(value) < 0 || Number(value) > 9 || value.length > 1) {
      return;
    }

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Update the form value managed by the parent component
    onChange(newCode.join(''));

    // Move focus to the next input if the current input is not empty
    if (value && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    // Trigger onComplete if all inputs are filled
    if (onComplete && newCode.every(digit => digit !== '')) {
      onComplete();
    }
  }, [code, length, onChange, onComplete]);

  const handleKeyDown = useCallback((index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
    // Prevent user from entering negative numbers or decimal points.
    if (e.key === '-' || e.key === '.') {
      e.preventDefault();
    }
  }, [code]);

  // When there is an error, clear the code and move focus to the first input
  const handleFocus = useCallback(() => {
    const codeIsEmpty = JSON.stringify(code) === JSON.stringify(defaultCode);
    if (hasError && !codeIsEmpty) {
      onChange(defaultCode.join(''));
      setCode(defaultCode);
      inputRefs.current[0]?.focus();
    }
  }, [hasError, code, defaultCode, onChange]);

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedValue = e.clipboardData.getData('text').split('');
    setCode(pastedValue);

    // Update the form value managed by the parent component
    onChange(pastedValue.join(''));
  };

  return (
    <div className={styles.root}>
      <div className={getClassNameFor(styles, 'inputWrapper', classNames({ centered }))}>
        {Array.from({ length }, (_, index) => (
          <input
            key={index}
            maxLength={1}
            value={code[index]}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={(e) => handlePaste(e)}
            onFocus={handleFocus}
            ref={(el) => setInputRef(el, index)}
            className={styles.input}
            type="number"
            pattern="\d*"
            /** https://html.spec.whatwg.org/multipage/interaction.html#input-modalities:-the-inputmode-attribute */
            inputMode="numeric"
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
};

export default CodeInput;
