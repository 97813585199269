import { useEffect, useRef } from 'react';

/**
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param {() => void} callback
 * @param {number} delay
 * @param {boolean} condition Allows deferring the start of the timeout
 */
const useTimeout = (callback, delay, condition = true) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null && condition) {
      const id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [condition, delay]);
};

export default useTimeout;
