import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { useLocation, useHistory } from 'react-router-dom';

import { TITLE } from '../../../../common/constants';
import { fullName } from '../../../../common/utils/user';
import { rffValidators } from '../../../../common/validation';
import {
  userIsRenter,
  getReviewUrl,
} from '../../../helpers';
import RatingInput from '../../../../common/components/inputs/RatingInput';
import RecommendationFields from '../../../../common/components/fieldsets/RecommendationFields';
import TripPanel from '../../presentation/TripPanel';
import CTA, { CTAButton } from '../../CTA';
import Card from '../../presentation/Card';
import { REVIEW_PAGES, FIELDS } from '../../pages/Complete/reviewsConfig';
import s from '../ReviewStep.module.scss';

const { RATING, RECOMMENDATION } = FIELDS;

const ratingValidator = rffValidators.required({ message: 'Please leave a rating for the other party.' });
const recommendationValidator = rffValidators.defined({ message: 'Please leave a rating for your guest.' });

// TODO: this is two components.
const Start = ({
  trip,
  role,
  backLocation,
  valid,
  form: { blur },
  progressBar,
}) => {
  const location = useLocation();
  const { push } = useHistory();
  const isRenter = userIsRenter(trip);
  const renter = trip.get('renter');
  const renterName = fullName(renter);
  const owner = trip.get('owner');
  const ownerName = fullName(owner);
  const nextUrl = getReviewUrl(REVIEW_PAGES.START, role, 'next', location);
  const cta = (
    <CTA classNameModifier="withSidebar">
      <CTAButton
        onClick={(
          () => {
            // This is how we "touch" fields to show errors on the fields
            // that haven't been touched yet.
            blur(RATING);
            blur(RECOMMENDATION);
            if (valid) {
              push(nextUrl);
            }
          })}
        data-test="next"
      >
        Next
      </CTAButton>
    </CTA>
  );

  return (
    <TripPanel
      trip={trip}
      subheader={TITLE.REVIEW}
      cta={cta}
      backLocation={backLocation}
    >
      <Card classNameModifier="review">
        {progressBar}
        <h3 className={s.title}>
          How was your
          {isRenter
            ? ` trip with ${ownerName}`
            : ` experience hosting ${renterName}`}?
        </h3>
        {isRenter
          ? (
            <Field
              name={RATING}
              component={RatingInput}
              validate={ratingValidator}
              classNameModifier="centered"
            />
          )
          : (
            <RecommendationFields
              name={RECOMMENDATION}
              validate={recommendationValidator}
            />
          )}
      </Card>
    </TripPanel>
  );
};

Start.propTypes = {
  trip: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  backLocation: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  valid: PropTypes.bool,
  progressBar: PropTypes.node,
};

export default Start;
