import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { getClassNameFor } from 'src/common/helpers';
import Vessel from 'src/inbox/components/Vessel';
import Button from 'src/common/components/Button';
import Icon from 'src/common/components/IconDS22';
import s from './input.module.scss';

type Props = FieldRenderProps<any, HTMLElement>;

const VesselInput: FC<Props> = ({ input: { value, onChange }, userBoats, trip }) => {
  const currentBoatIndex = userBoats.findIndex((boat: any) => boat.get('id') === value);
  const currentBoat = userBoats.get(currentBoatIndex);
  // Gets the prev/next, unless it's out of bounds, in which case it just returns the same
  // boat
  const prevBoatId = userBoats.get(currentBoatIndex && currentBoatIndex - 1).get('id');
  const nextBoatId = userBoats.get(currentBoatIndex + 1, currentBoat).get('id');
  return (
    <div className={s.root}>
      <div className={getClassNameFor(s, 'buttonWrapper', 'prev')}>
        <Button
          type="button"
          disabled={prevBoatId === value}
          onClick={() => onChange(prevBoatId)}
          classNameModifier="tertiary small"
          iconOnly
        >
          <Icon id="caret-left" />
        </Button>
      </div>
      <div className={`${s.card} ${s.card_wide}`}>
        <Vessel
          trip={trip}
          vessel={currentBoat}
          classNameModifier="wide editor"
        />
      </div>
      <div className={getClassNameFor(s, 'buttonWrapper', 'next')}>
        <Button
          type="button"
          disabled={nextBoatId === value}
          onClick={() => onChange(nextBoatId)}
          classNameModifier="tertiary small"
          iconOnly
        >
          <Icon id="caret-right" />
        </Button>
      </div>
    </div>
  );
};

export default VesselInput;
