import React, { FC, useCallback, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import classnames from 'classnames';
import Media from 'react-media';
import { Route, useRouteMatch } from 'react-router-dom';
import { startOfMonth } from 'date-fns';
import Button from 'src/common/components/Button';
import { MEDIA_QUERIES, PATHS } from '../../../common/constants';
import { getClassNameFor } from '../../../common/helpers';
import { useBoats } from '../../../common/hooks';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import ScrollToMe from '../../../common/components/ScrollToMe';
import Svg from '../../../common/components/Svg';
import { useCalendarContext, MAX_NEXT_MONTHS, MAX_NEXT_MONTHS_INSTABOOK_OWNER, useCalendarFocus, useListingsFilterContext } from '../../hooks';
import useFocusedEvents from '../../hooks/useFocusedEvents';
import CalendarControls from '../CalendarControls';
import CalendarDropdown from '../CalendarDropdown';
import CalendarMonthAgendaView from '../CalendarMonthAgendaView';
import InstabookMonthAgendaView from '../InstabookMonthAgendaView';
import MiniCal from '../MiniCal';
import EventDetails from '../EventDetails';
import Filters from '../Filters';
import UnavailableEditor from '../UnavailableEditor';
import CalendarNav from '../CalendarNav';
import InstabookEditorWrapper from '../InstabookEditorWrapper';
import Icon from '../../../common/components/IconDS22';
import { toDateString, todayDate, getMaxNextEvents } from '../../helpers';

import s from './Calendar.module.scss';

type CalendarWrapperProps = {
  toggleMobileMenu?: (value: boolean) => void;
};

const CalendarWrapper: FC<CalendarWrapperProps> = ({ toggleMobileMenu }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedInstabookDate, setSelectedInstabookDate] = useState<Date | null>(null);
  const {
    previous,
    next,
    events: months,
    instabookEnabled,
    setActiveMonth,
  } = useCalendarContext();
  const { clearFilters, setActiveFilters } = useListingsFilterContext();
  const { setCalendarFocus } = useCalendarFocus();
  const today = todayDate();
  const cappedMonths = getMaxNextEvents(months, today);

  const { boats, firstBoatId } = useBoats();
  const controlsModifier = classnames({ withTabs: instabookEnabled });
  const ref = useRef<HTMLDivElement>(null);
  useFocusedEvents<HTMLDivElement>(ref);

  const isInstabook = useRouteMatch(PATHS.INSTABOOK);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
    if (toggleMobileMenu) toggleMobileMenu(!menuOpen);
  };

  const jumpToToday = () => {
    const startOfTodaysMonth = startOfMonth(todayDate());
    setActiveMonth(toDateString(startOfTodaysMonth));
    setCalendarFocus({ selectedDate: toDateString(todayDate()) });
    handleMenuToggle();
  };

  const handleActivityClick = useCallback(() => {
    clearFilters();
  }, [clearFilters]);

  const handleInstabookClick = useCallback(() => {
    const hashId = boats.find((boat) => boat.boat_id === firstBoatId)?.id;
    if (hashId) {
      setActiveFilters([hashId]);
    }
  }, [boats, firstBoatId, setActiveFilters]);

  return (
    <div
      className={getClassNameFor(
        s,
        'root',
        classnames({
          withPinnedMessage: boats.length === 0,
        }),
      )}
    >
      <CalendarControls classNameModifier={controlsModifier}>
        {instabookEnabled && (
          <CalendarNav
            handleActivityClick={handleActivityClick}
            handleInstabookClick={handleInstabookClick}
          />
        )}
        <Media
          queries={{
            small: MEDIA_QUERIES.NOT_DESKTOP,
            large: MEDIA_QUERIES.LARGE_DESKTOP,
          }}
        >
          {(matches) => (
            <>
              {matches.small && (
                <>
                  <CalendarDropdown classNameModifier={controlsModifier} />
                  <Filters classNameModifier={isInstabook ? 'instabook' : ''} />
                </>
              )}
              {matches.large && <MiniCal selectedInstabookDate={selectedInstabookDate} />}
            </>
          )}
        </Media>
        {boats.length > 0 ? (
          <Media
            queries={{
              small: MEDIA_QUERIES.NOT_DESKTOP,
            }}
          >
            {(matches) => (
              matches.small && (
                <div className={getClassNameFor(s, 'mobileActionMenu', classnames({ isInstabook }))}>
                  <div className={getClassNameFor(s, 'toggleWrapper', classnames({ menuOpen }))}>
                    <Button
                      type="button"
                      onClick={jumpToToday}
                      classNameModifier="secondary noMargin"
                      fullWidth
                    >
                      <Icon
                        id="arrow-forward"
                        size="m"
                      />
                      Today
                    </Button>

                    <Route
                      exact
                      path={PATHS.CALENDAR}
                    >
                      <UnavailableEditor
                        modalName="unavailableEditor"
                        onOpenModal={handleMenuToggle}
                      />
                    </Route>
                    <Route
                      exact
                      path={PATHS.INSTABOOK}
                    >
                      <InstabookEditorWrapper
                        onOpenModal={handleMenuToggle}
                        selectedInstabookDate={selectedInstabookDate}
                      />
                    </Route>
                  </div>
                  <button
                    type="button"
                    className={s.mobileActionMenuToggle}
                    onClick={handleMenuToggle}
                  >
                    <Icon id={menuOpen ? 'x' : 'dots-three'} size="xl" />
                  </button>
                </div>
              )
            )}
          </Media>
        ) : (
          <p className={getClassNameFor(s, 'message', 'unpublished')}>
            <Svg
              href="#more-info"
              className={s.icon}
            />
            Some features are not available because your listings are unpublished.
          </p>
        )}
      </CalendarControls>
      <div className={s.wrapper}>
        {!previous.canLoad && (
          <p className={s.message}>
            <Svg
              href="#more-info"
              className={s.icon}
            />
            Your Calendar supports up to 12 months history from today’s date.
          </p>
        )}
        {previous.canLoad && !previous.isLoading && <Waypoint onEnter={previous.loadMore} />}
        {previous.canLoad && <LoadingSpinner>Loading Data</LoadingSpinner>}
        {/* The key is used to prevent unneeded remounting and scrolling side-effects */}
        <ScrollToMe key={`scroll-to-me-${months.length > 0 && months[0].startDate}`} />
        <div
          ref={ref}
          className={s.list}
        >
          <Media
            queries={{
              large: MEDIA_QUERIES.LARGE_DESKTOP,
            }}
          >
            {(matches) => (
              matches.large
              && <Filters classNameModifier={isInstabook ? 'instabook' : ''} />
            )}
          </Media>
          <Route
            exact
            path={PATHS.CALENDAR}
          >
            {cappedMonths.map(({ events, startDate }) => (
              <CalendarMonthAgendaView
                key={startDate}
                events={events}
                startDate={startDate}
              />
            ))}
          </Route>
          <Route
            exact
            path={PATHS.INSTABOOK}
          >
            {months.map(({ events, startDate }) => (
              <InstabookMonthAgendaView
                key={startDate}
                events={events}
                startDate={startDate}
                setSelectedInstabookDate={setSelectedInstabookDate}
              />
            ))}
          </Route>
        </div>
        {next.canLoad && next.isLoading ? (
          <LoadingSpinner>Loading...</LoadingSpinner>
        ) : (
          <Waypoint
            onEnter={next.loadMore}
            bottomOffset="-150"
          />
        )}
        {!next.canLoad && (
          <p className={getClassNameFor(s, 'message', 'future')}>
            <Svg
              href="#more-info"
              className={s.icon}
            />
            {`Your Calendar supports events up to ${
              isInstabook ? MAX_NEXT_MONTHS_INSTABOOK_OWNER : MAX_NEXT_MONTHS
            } months from today’s date.`}
          </p>
        )}
      </div>

      <EventDetails />
    </div>
  );
};

export default CalendarWrapper;
