import { useEffect } from 'react';
import useForceUpdate from '../../hooks/useForceUpdate';
import makeFakeSha from '../../utils/makeFakeSha';
import config from './config';

declare global {
  interface Window {
    gmb: GMB;
  }

  interface GMB {
    autoReload?: {
      readonly CHECK_VERSION_INTERVAL: number;
      readonly RELOAD_PROMPT_TIMEOUT: number;
      readonly AUTO_RELOAD_TIMEOUT: number;
      readonly CURRENT_VERSION: string;
      simulateNewVersion: () => void;
    };
  }
}

/**
 * Exposes `gmb.autoReload` on the window object.
 *
 * This can be used to inspect the current config.
 * The `simulateNewVersion` method will reduce the config timings and mutate
 * the current version to simulate new version being detected.
 */
const useAutoReloadDebugHelpers = (): void => {
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.gmb = window.gmb || {};
      window.gmb.autoReload = {
        get CHECK_VERSION_INTERVAL() {
          return config.CHECK_VERSION_INTERVAL;
        },
        get RELOAD_PROMPT_TIMEOUT() {
          return config.RELOAD_PROMPT_TIMEOUT;
        },
        get AUTO_RELOAD_TIMEOUT() {
          return config.AUTO_RELOAD_TIMEOUT;
        },
        get CURRENT_VERSION() {
          return config.CURRENT_VERSION;
        },
        simulateNewVersion: () => {
          config.CHECK_VERSION_INTERVAL = 15000; // 15 seconds
          config.RELOAD_PROMPT_TIMEOUT = 5000; // 5 seconds
          config.AUTO_RELOAD_TIMEOUT = 60000; // 1 minute
          config.CURRENT_VERSION = makeFakeSha();
          forceUpdate();
        },
      };
    }
  }, [forceUpdate]);
};
export default useAutoReloadDebugHelpers;
