import React, { FC } from 'react';
import classNames from 'classnames';
import { getClassNameFor } from '../../../helpers';
import s from './AvailabilityDay.module.scss';

type AvailabilityDayProps = {
  children: React.ReactNode;
  classNameModifier?: string;
};

const AvailabilityDay: FC<AvailabilityDayProps> = ({ children, classNameModifier = '' }) => (
  <div className={getClassNameFor(s, 'root', classNames(classNameModifier))}>
    <div className={s.date}>
      {children}
    </div>
  </div>
);

export default AvailabilityDay;
