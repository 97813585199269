import React, { FC, ReactNode } from 'react';
import { Map } from 'immutable';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ParsedQuery, stringify } from 'query-string';

import { formatDate } from 'src/common/utils/dateHelpers';
import { DATES, PAGES } from '../../constants';
import { durationFormat } from '../../../common/helpers';
import NotificationMessage from '../../../common/components/NotificationMessage';
import DurationSnippet from '../../../common/components/DurationSnippet';
import CaptainSnippet from '../../../common/components/CaptainSnippet';
import GroupSizeSnippet from '../../../common/components/GroupSizeSnippet';
import DateTimeSnippet from '../../../common/components/DateTimeSnippet';
import s from './MismatchesList.module.scss';
import { ImmutableInquiry, ImmutableInquiryDetails } from '../../../types/inquiry/Inquiry';

const MismatchContent: FC<MismatchContentProps> = ({ title, warning }) => (
  <>
    <h2 className={s.subTitle}>
      {title}
    </h2>
    <NotificationMessage
      outdent={false}
      withIcon={false}
    >
      {warning}
    </NotificationMessage>
  </>
);
type MismatchContentProps = {
  title: ReactNode;
  warning: ReactNode;
};

const durationTitle = (inquiryDetails: ImmutableInquiryDetails) => durationFormat(moment.duration(inquiryDetails.get('trip_length')));

const dateFields: string[] = [
  DATES.PRIMARY,
  DATES.SECONDARY,
  DATES.TERTIARY,
];
const availabilityTitle = (inquiryDetails: ImmutableInquiryDetails) => inquiryDetails
  .filter((val, key) => val && key && dateFields.includes(key))
  .map((val => formatDate(new Date(val), 'd MMM y', {}))).join(', ');

const capacityTitle = (inquiryDetails: ImmutableInquiryDetails) => {
  const total = inquiryDetails.filter(
    (val, key) => !!key && ['adults', 'seniors', 'children', 'infants'].includes(key),
  ).reduce((a, b) => a + b);
  return `${total} people`;
};

const captainTitle = (inquiryDetails: ImmutableInquiryDetails) => (
  `${inquiryDetails.get('captain_required') ? 'With' : 'Without'} Captain`
);

type MismatchesListProps = {
  inquiry: ImmutableInquiry;
  boatId: string;
  query: ParsedQuery<string>;
};

const MismatchesList: FC<MismatchesListProps> = ({ inquiry, boatId, query }) => {
  const search = { ...query, nextPage: PAGES.MISMATCHES };
  const mismatches = inquiry.get('mismatches', Map());
  const inquiryDetails = inquiry.get('details');

  return (
    <ul className={s.root}>
      {mismatches.entrySeq().map(([page, warning]) => (
        <li key={page} className={s.item}>
          {page === 'duration' && (
            <DurationSnippet>
              <MismatchContent
                title={durationTitle(inquiryDetails)}
                warning={warning}
              />
            </DurationSnippet>
          )}
          {page === 'availability' && (
            <DateTimeSnippet type="date">
              <MismatchContent
                title={availabilityTitle(inquiryDetails)}
                warning={warning}
              />
            </DateTimeSnippet>
          )}
          {page === 'capacity' && (
            <GroupSizeSnippet>
              <MismatchContent
                title={capacityTitle(inquiryDetails)}
                warning={warning}
              />
            </GroupSizeSnippet>
          )}
          {page === 'captain' && (
            <CaptainSnippet captained={inquiryDetails.get('captain_required')}>
              <MismatchContent
                title={captainTitle(inquiryDetails)}
                warning={warning}
              />
            </CaptainSnippet>
          )}
          <Link
            to={{
              pathname: `/s/inquiry/${boatId}/${page}/`,
              search: stringify(search),
            }}
            replace
            className={s.link}
          >
            Edit
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default MismatchesList;
