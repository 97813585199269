import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';

import { BackButton } from '../../../../common/components/BackButton';
import CloseButton from '../../../../common/components/CloseButton';
import ExtrasForm from '../../form/Extras';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import s from './Extras.module.scss';

const Extras: FC = () => {
  const location = useLocation();
  const { goBack } = useHistory();
  const returnUrl = getReturnUrl(location);
  return (
    <div className={s.container}>
      <Helmet>
        <title>
          Extras
        </title>
      </Helmet>
      <h1 className={s.title}>Anything Else?</h1>
      <div className={s.message}>
        <p>
          Do you have any specific needs or requests that the owner needs to know?
        </p>
      </div>
      <ExtrasForm />
      <BackButton onClick={goBack} />
      <CloseButton backUrl={returnUrl} />
    </div>
  );
};

export default Extras;
