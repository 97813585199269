import React, { FC, ReactNode, useEffect } from 'react';
import { Field, useFormState, useForm } from 'react-final-form';
import createDecorator from 'final-form-calculate';

import {
  multidayFormat,
  getClassNameFor,
} from '../../../helpers';
import NumberAddInput from '../../inputs/NumberAddInput';
import s from './DurationFieldset.module.scss';

// createDecorator might be overwrought (in the sense that we could write our own),
// but it takes care of figuring out which field has changed, which is a decent
// amount of logic.
const durationDecorator = createDecorator(
  {
    field: ['hours', 'minutes', 'days'],
    // NOTE: there might be a simpler way to express this decorator, and I'm open
    // to suggestions. This was the simplest way I found to get it to work.
    updates: (value, name) => {
      switch (name) {
        // These checks prevent unnecessary updates as a result of
        // one field changing another to 0, then that field responding
        // and changing the other to 0.
        case 'hours':
        case 'minutes':
          return (value === 0) ? {} : { days: 0 };
        case 'days':
          return (value === 0) ? {} : { hours: 0, minutes: 0 };
        default:
          return {};
      }
    },
  },
);

type DurationFieldsetProps = {
  classNameModifier?: string;
  children?: ReactNode;
  showMinutes?: boolean;
  showHours?: boolean;
  showMultiDays?: boolean;
  multiDayLabel?: string;
  multiDayHeader?: string;
  minHourlyDuration?: string;
  minMultiDayDuration?: string;
};

const DurationFieldset: FC<DurationFieldsetProps> = ({
  classNameModifier,
  children,
  showHours = true,
  showMinutes = true,
  showMultiDays = true,
  multiDayLabel,
  multiDayHeader = 'Overnight',
  minHourlyDuration,
  minMultiDayDuration,
}) => {
  const { values: { days } } = useFormState({
    subscription: { values: true },
  });

  const multiDayCopyLabel = multiDayLabel || multidayFormat(days, false);

  const form = useForm();
  // This is here rather than on the Form declaration
  // to make it so that you don't have to remember to
  // attach the decorator when the Form that uses this
  // Fieldset is declared.
  useEffect(
    () => durationDecorator(form),
    [form],
  );
  return (
    <div className={getClassNameFor(s, 'root', classNameModifier)}>
      {children}
      {showHours
      && (
        <>
          <h2 className={s.subtitle}>
            Return on same-day
          </h2>
          <Field
            autoFocus
            name="hours"
            component={NumberAddInput}
            label="Hours"
            maxValue={23}
            classNameModifier={`duration hours ${classNameModifier}`}
            data-test="DurationHoursInput"
            inputText={minHourlyDuration}
          />
        </>
      )}
      {showMinutes && (
        <Field
          name="minutes"
          component={NumberAddInput}
          label="Minutes"
          maxValue={59}
          step={5}
          classNameModifier="duration minutes"
          data-test="DurationMinutesInput"
        />
      )}

      {
        showMultiDays && (
          <>
            <h2 className={s.subtitle}>
              {multiDayHeader}
            </h2>
            <Field
              name="days"
              component={NumberAddInput}
              maxValue={999}
              label={multiDayCopyLabel}
              classNameModifier={`duration days ${classNameModifier}`}
              data-test="DurationNightsInput"
              inputText={minMultiDayDuration}
            />
          </>
        )
      }
    </div>
  );
};

export default DurationFieldset;
