import React from 'react';
import { Link } from 'react-router-dom';
import type { Location } from 'history';

import type { ImmutableTrip } from 'src/types/trips/Trips';
import type { ImmutableOffer } from 'src/types/offer/Offer';
import { offerIsEmpty } from 'src/inbox/helpers';
import { PATHS } from '../../../../common/constants';
import { PAGES, TRIP_STATES } from '../../../constants';
import { appendRoute, preserveSearch } from '../../../../common/utils/routing';
import BookingCancelledMessage from '../../presentation/BookingCancelledMessage';
import NewInquiryMessage from '../../presentation/NewInquiryMessage';
import OfferModifiedWarning from '../../presentation/OfferModifiedWarning';
import UserBlock from '../../presentation/UserBlock';

const {
  OWNER_DIRECT_INQUIRY,
  OWNER_MATCHING_INQUIRY,
  OWNER_OFFER_CANCELLED,
  OWNER_OFFER_AUTO_CANCELLED,
  OWNER_OFFER_EXPIRED,
  OWNER_CUSTOM_OFFER_EXPIRED,
  OWNER_INQUIRY_DECLINED,
  OWNER_RESERVATION_CANCELLED_BY_OWNER,
  OWNER_RESERVATION_CANCELLED_BY_RENTER,
} = TRIP_STATES;

const content = {
  title: {
    [OWNER_DIRECT_INQUIRY]: 'Inquiry',
    [OWNER_MATCHING_INQUIRY]: 'Inquiry',
    [OWNER_INQUIRY_DECLINED]: 'Inquiry Declined',
    [OWNER_OFFER_EXPIRED]: 'Offer Expired',
    [OWNER_OFFER_CANCELLED]: 'Offer Canceled',
    [OWNER_RESERVATION_CANCELLED_BY_OWNER]: 'Booking Canceled',
    [OWNER_RESERVATION_CANCELLED_BY_RENTER]: 'Booking Canceled',
  },

  // These are technically components, but they're also not... Might
  // be worth refactoring in the future.
  message: {
    [OWNER_DIRECT_INQUIRY]: ({ trip, offer }: GetMessageProps) => (
      <NewInquiryMessage
        trip={trip}
        offer={offer}
      />
    ),
    [OWNER_MATCHING_INQUIRY]: ({ trip, offer }: GetMessageProps) => (
      <NewInquiryMessage
        trip={trip}
        offer={offer}
      />
    ),
    [OWNER_INQUIRY_DECLINED]: ({ trip, offer }: GetMessageProps) => (
      <>
        {!offerIsEmpty(offer) && <OfferModifiedWarning />}
        <UserBlock
          classNameModifier="bold"
          user={trip.get('renter')}
        >
          You declined this inquiry from {trip.getIn(['renter', 'first_name'])}.
        </UserBlock>
      </>
    ),
    [OWNER_OFFER_EXPIRED]: ({ trip, offer }: GetMessageProps) => {
      const renter = trip.get('renter');
      const renterName = renter.get('first_name');
      return (
        <>
          {!offerIsEmpty(offer) && <OfferModifiedWarning />}
          <UserBlock user={renter}>
            <strong>
              Your offer to {renterName} expired because the date has now passed.
            </strong>
            {' '}
            You can send a new offer with an updated date or contact {renterName} for more options.
          </UserBlock>
        </>
      );
    },
    [OWNER_CUSTOM_OFFER_EXPIRED]: ({ trip, offer, location }: GetMessageProps) => {
      const renter = trip.get('renter');
      const renterName = renter.get('first_name');
      return (
        <>
          {!offerIsEmpty(offer) && <OfferModifiedWarning />}
          <UserBlock user={renter}>
            <strong>
              Your offer to {renterName} expired because its expiry date has now passed.
            </strong>
            {' '}
            <Link to={preserveSearch(appendRoute(location.pathname, PAGES.PRICE), location)}>
              Create a new offer
            </Link>
            {' '}
            or contact {renterName} for more options.
          </UserBlock>
        </>
      );
    },
    [OWNER_OFFER_CANCELLED]: ({ trip, offer }: GetMessageProps) => (
      <>
        {!offerIsEmpty(offer) && <OfferModifiedWarning />}
        <UserBlock user={trip.get('renter')}>
          <strong>
            You canceled your previous offer to {trip.getIn(['renter', 'first_name'])}.
          </strong>
          {' '}
          Please send a new offer to respond to this inquiry.
        </UserBlock>
      </>
    ),
    [OWNER_OFFER_AUTO_CANCELLED]: ({ trip, offer }: GetMessageProps) => {
      const renterName = trip.getIn(['renter', 'first_name']);
      return (
        <>
          {!offerIsEmpty(offer) && <OfferModifiedWarning />}
          <UserBlock user={trip.get('renter')}>
            <>
              <strong>
                Your offer to {renterName} was automatically canceled when
                {' '}
                <Link to={`${PATHS.INBOX}${trip.get('cancelled_by_thread_id')}/`}>
                  this booking
                </Link>
                {' '}
                was confirmed.
              </strong>
              {' '}
              Try sending {renterName} another offer.
            </>
          </UserBlock>
        </>
      );
    },
    [OWNER_RESERVATION_CANCELLED_BY_OWNER]: ({ trip, offer, location }: GetMessageProps) => {
      const renterName = trip.getIn(['renter', 'first_name'], 'Renter');
      return (
        <>
          {!offerIsEmpty(offer) && <OfferModifiedWarning />}
          <BookingCancelledMessage
            location={location}
            renterName={renterName}
            trip={trip}
          >
            You canceled this booking with {renterName}.
          </BookingCancelledMessage>
        </>
      );
    },
    [OWNER_RESERVATION_CANCELLED_BY_RENTER]: ({ trip, offer, location }: GetMessageProps) => {
      const renterName = trip.getIn(['renter', 'first_name'], 'Renter');
      return (
        <>
          {!offerIsEmpty(offer) && <OfferModifiedWarning />}
          <BookingCancelledMessage
            location={location}
            renterName={renterName}
            trip={trip}
          >
            {renterName} canceled this booking.
          </BookingCancelledMessage>
        </>
      );
    },
  },
};

type GetMessageProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  location: Location;
  offer: ImmutableOffer;
  trip: ImmutableTrip;
};

export const getMessage = ({ location, offer, trip }: GetMessageProps): JSX.Element => {
  const tripState: string = trip.getIn(['state', 'state']);
  const message = content.message[tripState];
  return message && message({ trip, offer, location });
};

export default content;
