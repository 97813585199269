import moment from 'moment';

import { add } from 'date-fns';

import { MOMENT_FORMAT } from '../constants';

export const getTimeFormat = timeInputIsSupported => (
  timeInputIsSupported
    ? MOMENT_FORMAT.TIME_24
    : MOMENT_FORMAT.TIME
);

export const formatTime = (time, timeFormat) => time && moment(
  time,
  [MOMENT_FORMAT.TIME_24, MOMENT_FORMAT.TIME],
)
  .format(timeFormat);

/**
 * @param {Date | string} date
 * @param {string} timeZone
 * @returns {Date}
 */
// eslint-disable-next-line @typescript-eslint/default-param-last
export const convertToTimeZone = (date = new Date(), timeZone) => new Date(
  (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone }),
);

/**
 * @param {string} timeZoneA
 * @param {string} timeZoneB
 * @returns {number}
 */
export const calculateTimezoneOffsetInHours = (a, b) => {
  const date = new Date();
  const diff = convertToTimeZone(date, a).getTime() - convertToTimeZone(date, b).getTime();
  return diff / 3600000; // Milliseconds in an hour
};

/**
 * Adjust a date by a numeric offset. Caters partial hours
 * @param {Date} date
 * @param {number} offsetInHours
 * @returns {Date}
 */
export const adjustTimezone = (date, offsetInHours) => {
  const hours = offsetInHours > 0 ? Math.floor(offsetInHours) : Math.ceil(offsetInHours);
  const minutes = (offsetInHours - hours) * 60;
  return add(date, { hours, minutes });
};
