import React, { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import Headroom from 'react-headroom';
import classNames from 'classnames';

import { TITLE } from '../../../common/constants';

import Layout from '../../../common/components/Layout';
import Header from '../../../common/components/Header';
import SvgDefs from '../../../common/components/SvgDefs';
import { getClassNameFor } from '../../../common/helpers';
import handlePin from '../../../common/utils/handlePin';
import defs from './images.svg';

import s from './Instabook.module.scss';

type InstabookRootProps = {
  variant: 'full' | 'modal';
  children?: ReactNode;
};

const InstabookRoot: FC<InstabookRootProps> = ({
  children,
  variant,
}) => {
  const full = variant === 'full';
  const modal = variant === 'modal';
  const classNameModifier = classNames({
    modal,
    'with-blue-gradient-background': modal,
    bordered: full,
  });

  return (
    <>
      <Helmet
        defaultTitle={`${TITLE.INSTABOOK} | ${TITLE.PROJECT}`}
        titleTemplate={`${TITLE.INSTABOOK}: %s | ${TITLE.PROJECT}`}
      />
      <SvgDefs href={defs} />
      <Layout classNameModifier={classNameModifier}>
        {full ? (
          <Headroom
            disableInlineStyles
            onPin={() => handlePin('pin')}
            onUnpin={() => handlePin('unpin')}
            onUnfix={() => handlePin('unfix')}
          >
            <Header
              classNameModifier={classNames(classNameModifier)}
            />
          </Headroom>
        ) : (
          <Header
            classNameModifier={classNames(classNameModifier)}
            withNav={full}
            withSearchBar={full}
          />
        )}
        <div className={getClassNameFor(s, 'root', classNames({ full, modal }))}>
          {children}
        </div>
      </Layout>
    </>
  );
};

export default InstabookRoot;
