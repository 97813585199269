import React, { FC } from 'react';
import { getClassNameFor } from '../../helpers';
import s from './Modal.module.scss';

type BodyProps = {
  children: React.ReactNode;
  classNameModifier?: string;
};

const Body: FC<BodyProps> = ({ children, classNameModifier = '' }) => (
  <div className={getClassNameFor(s, 'body', classNameModifier)}>{children}</div>
);

export default Body;
