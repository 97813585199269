import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../common/components/Modal';
import s from './CustomOfferExpiryIntroModal.module.scss';
import Button from '../../../common/components/Button';

const CustomOfferExpiryIntroModal = ({ modalName, onAccept, onModalClose }) => (
  <Modal
    extraClassName="ignore-onclickoutside"
    label="Custom Offer Expiry Information"
    name={modalName}
    classNameModifier="customOfferExpiry"
    closeModal={onModalClose}
  >
    <div className={s.root}>
      <h3 className={s.title}>
        Create Offers with an Expiry Time
      </h3>
      <p className={s.copy}>
        Use Custom Offer Expiry to encourage customers to decide more promptly about booking,
        so you can confirm the trip, or free it up for others.
      </p>
      <Button
        type="button"
        onClick={onAccept}
        fullWidth
      >
        Try It Now
      </Button>
    </div>
  </Modal>
);

CustomOfferExpiryIntroModal.propTypes = {
  modalName: PropTypes.string,
  onAccept: PropTypes.func,
  onModalClose: PropTypes.func,
};

export default CustomOfferExpiryIntroModal;
