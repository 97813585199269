import React, { FC, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { parse, stringify } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { plural } from 'src/common/helpers';
import { differenceInDays, startOfDay } from 'date-fns';
import { newTZDate } from 'src/calendar/helpers';
import type { ReduxState } from '../../../../types/reduxState';
import { BOOKING_MOTIVATOR_STATE } from '../../../../common/constants';
import { trackEvent } from '../../../../common/tracking';
import { combineValidators, rffValidators } from '../../../../common/validation';
import useOnSubmitLocationChange from '../../../../common/hooks/useOnSubmitLocationChange';
import apiFetch from '../../../../core/fetch';
import { DATES, PAGES } from '../../../constants';
import { updateInquiry, setInquiryMismatch, deleteInquiryMismatch } from '../../../ducks/inquiry';
import { BackButton } from '../../../../common/components/BackButton';
import CloseButton from '../../../../common/components/CloseButton';
import InPageWarning from '../../InPageWarning';
import Button from '../../../../common/components/Button';
import BookingMotivators from '../../../../common/components/BookingMotivators';
import useBookingMotivatorsContext from '../../../../common/hooks/useBookingMotivators';
import DatePickerWithBookingMotivators from '../../../../common/components/DatePickerWithBookingMotivators';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import DateBlock from './DateBlock';
import s from './Dates.module.scss';

const { UNAVAILABLE } = BOOKING_MOTIVATOR_STATE;

const dateValidate = rffValidators.required({ message: 'Please select a date.' });

type DatesValues = {
  [DATES.PRIMARY]?: string;
  [DATES.SECONDARY]?: string;
  [DATES.TERTIARY]?: string;
};

const checkAvailability = (boatId: string, formValues: DatesValues, trip_length: string) => {
  const inputDates = Object.values(formValues);
  const dates = inputDates
    .filter(date => !!date);
  const urlBase = `/boats/${boatId}/availability/`;
  const queryString = stringify({ dates, trip_length });
  return apiFetch(`${urlBase}?${queryString}`);
};

const ClearableDatePickerWithBookingMotivators = (props: any) => (
  <DatePickerWithBookingMotivators
    {...props}
    showClearDate
  />
);

const DatePage: FC = () => {
  const {
    handleDateChange,
    getMotivators,
    state: {
      [DATES.PRIMARY]: { date: primaryDate, intervalMotivators: primaryDateMotivators },
      [DATES.SECONDARY]: { date: secondaryDate, intervalMotivators: secondaryDateMotivators },
      [DATES.TERTIARY]: { date: tertiaryDate, intervalMotivators: tertiaryDateMotivators },
    },
  } = useBookingMotivatorsContext();
  const dispatch = useDispatch();
  const { goBack, replace } = useHistory();
  const location = useLocation();
  const query = parse(location.search);

  const { preferredDate } = query;

  // Save selected date on listing page to keep initialValues stable
  // during the component lifetime to prevent form re-initialization
  // unavailable dates are not passed via query params
  const initialPrimaryDate = useRef(query.preferredDate);

  if (preferredDate && typeof preferredDate === 'string') {
    handleDateChange(moment(preferredDate), DATES.PRIMARY);
    delete query.preferredDate;
    replace({
      search: stringify(query),
    });
  }

  const {
    boat,
    initialValues,
    mismatch,
    tripLengthString,
  } = useSelector((state: ReduxState) => ({
    boat: state.boat,
    initialValues: {
      [DATES.PRIMARY]: state.inquiry.getIn(['details', DATES.PRIMARY])
      || initialPrimaryDate.current,
      [DATES.SECONDARY]: state.inquiry.getIn(['details', DATES.SECONDARY]),
      [DATES.TERTIARY]: state.inquiry.getIn(['details', DATES.TERTIARY]),
    },
    mismatch: state.inquiry.getIn(['mismatches', 'availability']),
    tripLengthString: state.inquiry.getIn(['details', 'trip_length']),
  }));
  const returnUrl = getReturnUrl(location);
  const tripLength = tripLengthString && moment.duration(tripLengthString);

  const prices = boat.get('prices');
  const isNight = prices.some((price) => price?.get('price_unit') === 'night');

  const availabilityValidate = (value: string) => (getMotivators(value).includes(UNAVAILABLE)
    ? 'This date is unavailable.'
    : undefined
  );

  const primaryDateValidate = combineValidators([
    dateValidate,
    availabilityValidate,
  ]);

  const onSubmitSuccess = useOnSubmitLocationChange(PAGES.TIME);

  const onSubmit = useCallback(
    (values: Record<string, string>, { initialize, getState }) => {
      const momentValues = values;
      // This form relies on Moment
      // Datepicker within /calendar has been updated to use date-fns
      // To keep Instabook Multi-Edit project within scope,
      // we will not be updating this form just yet
      // The 'save' function within DatePickerWithBookingMotivators is using date objects
      // However, this api requires the date to be in 'YYYY-MM-DD' format
      // so using Moment to swap back before saving
      Object.keys(momentValues).forEach((key) => {
        if (momentValues[key]) {
          momentValues[key] = moment(momentValues[key]).format('YYYY-MM-DD');
        }
        return momentValues;
      });
      dispatch(updateInquiry(momentValues));
      const {
        preferred_date1: mainDate,
        preferred_date2: altDate1,
        preferred_date3: altDate2,
      } = values;
      const daysToTrip = differenceInDays(startOfDay(newTZDate(mainDate)), startOfDay(new Date()));
      trackEvent('send_inquiry_s3_preferred_date', {
        p1: [mainDate, altDate1, altDate2].filter(x => !!x),
        p2: `${daysToTrip} day${plural(daysToTrip)} to trip`,
      });
      if (getState().pristine
        // If there's a mismatch, this means the user has decided to click
        // "continue anyway" and we want to short-circuit.
        && mismatch) {
        trackEvent('Continue Past SBI Warning', {
          event_category: 'Booking Inquiry',
          event_label: 'for Dates',
        });
        return onSubmitSuccess();
      }
      // TODO: check that this takes `values` as an arg
      type DateUnavailable = {
        detail: string;
      };
      return checkAvailability(boat.get('id'), values, tripLengthString)
        .then(response => {
          if (!response.ok) {
            initialize(values);
            return response.json().then(({ detail }: DateUnavailable) => {
              dispatch(setInquiryMismatch({ availability: detail }));
            });
          }
          dispatch(deleteInquiryMismatch('availability'));
          return onSubmitSuccess();
        });
    },
    [boat, dispatch, mismatch, onSubmitSuccess, tripLengthString],
  );

  const dateFormat = (value: string) => (value ? moment(value).format('D MMM YYYY') : '');

  return (
    <div className={s.container}>
      <Helmet>
        <title>
          Dates
        </title>
      </Helmet>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting, pristine, values }) => {
          const showWarning = mismatch && pristine && !submitting;
          return (
            <form onSubmit={handleSubmit} className={s.form}>
              <h1 className={s.title}>Preferred Date</h1>
              <div className={s.message}>
                <p>Provide the preferred date for your trip in the first <br /> field below.</p>
              </div>

              <div>
                <DateBlock
                  tripLength={tripLength}
                  date={values[DATES.PRIMARY]}
                  isNight={isNight}
                >
                  <Field
                    name={DATES.PRIMARY}
                    placeholder="Preferred date"
                    format={dateFormat}
                    validate={primaryDateValidate}
                    component={ClearableDatePickerWithBookingMotivators}
                    tripLength={tripLength}
                    isNight={isNight}
                  />
                </DateBlock>
                <BookingMotivators
                  minimal
                  selectedDate={primaryDate}
                  selectedMotivators={primaryDateMotivators}
                />
                <DateBlock
                  tripLength={tripLength}
                  date={values[DATES.SECONDARY]}
                  isNight={isNight}
                >
                  <h2 className={s.subtitle} key="title">
                    Other options
                  </h2>
                  <div className={`${s.message} ${s.message_middle}`} key="message">
                    Provide additional dates if your dates are flexible and <br />
                    you have other possible options.
                  </div>
                  <Field
                    name={DATES.SECONDARY}
                    placeholder="Other option"
                    format={dateFormat}
                    validate={availabilityValidate}
                    component={ClearableDatePickerWithBookingMotivators}
                    tripLength={tripLength}
                  />
                </DateBlock>
                <BookingMotivators
                  minimal
                  selectedDate={secondaryDate}
                  selectedMotivators={secondaryDateMotivators}
                />
                <DateBlock
                  tripLength={tripLength}
                  date={values[DATES.TERTIARY]}
                  isNight={isNight}
                >
                  <Field
                    name={DATES.TERTIARY}
                    placeholder="Other option"
                    format={dateFormat}
                    validate={availabilityValidate}
                    component={ClearableDatePickerWithBookingMotivators}
                    tripLength={tripLength}
                  />
                </DateBlock>
                <BookingMotivators
                  minimal
                  selectedDate={tertiaryDate}
                  selectedMotivators={tertiaryDateMotivators}
                />
              </div>

              {showWarning && <InPageWarning message={mismatch} />}
              <Button
                type="submit"
                submitting={submitting}
                data-test="next"
                fullWidth
              >
                {showWarning ? 'Continue Anyway' : 'Next'}
              </Button>
            </form>
          );
        }}
      </Form>
      <BackButton onClick={goBack} />
      <CloseButton backUrl={returnUrl} />
    </div>
  );
};

export default DatePage;
