import { fromJS } from 'immutable';

import { fetchCatch } from 'src/core/sentry';
import { apiFetchThunk } from '../../common/ducks/fetch';

// app/reducer/action
export const SET_REVIEW = 'inbox/reviews/SET_REVIEW';
export const UPDATE_REVIEW = 'inbox/reviews/UPDATE_REVIEW';

export const setBoatReview = review => ({
  type: SET_REVIEW,
  reviewType: 'boat',
  review,
});
export const updateBoatReview = review => ({
  type: UPDATE_REVIEW,
  reviewType: 'boat',
  review,
});
export const setRenterReview = review => ({
  type: SET_REVIEW,
  reviewType: 'renter',
  review,
});
export const updateRenterReview = review => ({
  type: UPDATE_REVIEW,
  reviewType: 'renter',
  review,
});

// Helper functions
// Just for clarity's sake
const INITIAL_REVIEWS_STATE = fromJS({
  renter: { results: [] },
  boat: { results: [] },
});

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (reviews = INITIAL_REVIEWS_STATE, { type, reviewType, review }) => {
  const count = reviews.getIn([reviewType, 'count'], 0);

  switch (type) {
    case SET_REVIEW:
      return review.has('results')
        ? reviews.set(reviewType, review)
        : reviews.setIn([reviewType, 'results', 0], review)
          // Increase count;
          .setIn([reviewType, 'count'], count + 1);
    case UPDATE_REVIEW:
      return reviews.setIn(
        [
          reviewType,
          'results',
          reviews.getIn([reviewType, 'results'])
            .findIndex((currentReview) => currentReview.id === review.id),
        ],
        review,
      );
    default:
      return reviews;
  }
};

const reviewCalls = {
  boatReviewGet: {
    url: pk => `/trips/${pk}/boat-reviews/`,
    action: setBoatReview,
  },
  renterReviewGet: {
    url: pk => `/trips/${pk}/renter-reviews/`,
    action: setRenterReview,
  },
  boatReviewCreate: {
    url: pk => `/trips/${pk}/boat-reviews/`,
    action: setBoatReview,
  },
  renterReviewCreate: {
    url: pk => `/trips/${pk}/renter-reviews/`,
    action: setRenterReview,
  },
  feedbackCreate: {
    url: pk => `/trips/${pk}/customer-feedback/`,
  },
  boatReviewUpdate: {
    url: (pk, id) => `/trips/${pk}/boat-reviews/${id}/`,
    action: updateBoatReview,
    method: 'PATCH',
  },
  renterReviewUpdate: {
    url: (pk, id) => `/trips/${pk}/renter-reviews/${id}/`,
    action: updateRenterReview,
    method: 'PATCH',
  },
};

export const getReviewsCall = call => id => async dispatch => {
  const url = call.url(id);
  const response = await dispatch(apiFetchThunk(url));
  if (!response.ok) {
    fetchCatch(response);
    throw response;
  }
  const review = await response.json().then(fromJS);
  dispatch(call.action(review));
};

const postReviewsCall = call => (id, values) => async dispatch => {
  const url = call.url(id);
  const response = await dispatch(apiFetchThunk(
    url,
    {
      method: 'POST',
      body: JSON.stringify(values),
    },
  ));
  if (!response.ok) {
    fetchCatch(response);
    throw response;
  }
  if (call.action) {
    const review = await response.json().then(fromJS);
    dispatch(call.action(review));
  }
};

const updateReviewsCall = call => (id, pk, values) => async dispatch => {
  const url = call.url(id, pk);
  const response = await dispatch(apiFetchThunk(
    url,
    {
      method: call.method,
      body: JSON.stringify(values),
    },
  ));
  if (!response.ok) {
    fetchCatch(response);
    throw response;
  }
  if (call.action) {
    const review = await response.json().then(fromJS);
    dispatch(call.action(review));
  }
};

export const getRenterReviewCall = getReviewsCall(reviewCalls.renterReviewGet);
export const getBoatReviewCall = getReviewsCall(reviewCalls.boatReviewGet);

export const postBoatReviewCall = postReviewsCall(reviewCalls.boatReviewCreate);
export const postRenterReviewCall = postReviewsCall(reviewCalls.renterReviewCreate);
export const postCustomerFeedbackCall = postReviewsCall(reviewCalls.feedbackCreate);

export const patchBoatReviewCall = updateReviewsCall(reviewCalls.boatReviewUpdate);
export const patchRenterReviewCall = updateReviewsCall(reviewCalls.renterReviewUpdate);
