import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Map, fromJS } from 'immutable';

import { PATHS } from 'src/common/constants';
import { preserveSearch } from 'src/common/utils/routing';
import TripPanel from 'src/inbox/components/presentation/TripPanel';
import Message from 'src/inbox/components/Message';
import joyAvatar from 'src/img/joy-avatar.png';
import s from './Messages.module.scss';

const DirectBookingGuestMessage = ({ location, trip }) => {
  const currentUser = useSelector(state => state.user || Map());

  const guestMessage = fromJS({
    id: 1,
    user: {
      id: 1,
      photo_url: joyAvatar,
      has_photo: true,
    },
    subject: 'Direct Booking',
    date_created: trip.get('offer_sent_date'),
    content: '\n\nHello,\n\nSince this is an external customer, messages will not be available for this customer until they create an account.\n\nThanks,\nGetmyboat\n\n',
  });

  return (
    <TripPanel
      backLocation={preserveSearch(PATHS.INBOX, location)}
      subheader="Messages"
      trip={trip}
    >
      <ul className={s.list}>
        <Message
          message={guestMessage}
          currentUser={currentUser}
        />
      </ul>
    </TripPanel>
  );
};

DirectBookingGuestMessage.propTypes = {
  trip: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default DirectBookingGuestMessage;
