import apiFetch, { Fetcher } from 'src/core/fetch';
import captureException from '../utils/captureException';

/** Response from `/api/v4/auth/user/phone/validate/` */
export type PhoneValidationResponse = { valid: true } | { valid: false; detail: string };

type AsyncValidator = (phone: string) => Promise<string>;

/**
 * Returns an async phone validator with builtin caching
 * @param fetcher Fetch implementation
 * @returns asyncPhoneValidator function
 */
export function makeAsyncPhoneValidator(fetcher: Fetcher): AsyncValidator {
  /**
   * Stores the pending/fulfilled validation api results in a cache
   * that can be used to short circuit unnecessary API calls.
  */
  type ValidationResult = Promise<string>;
  const validationCache: Record<string, undefined | ValidationResult> = {};

  // This returned function is specifically using promise chaining to take
  // advantage of caching the promises.
  return (phone: string): ValidationResult => {
    // Given that the validation results won't change unless the value changes,
    // we implement a cache to avoid unnecessary API calls.
    // Additionally, we store a promise that subsequent calls to this function
    // can share pending or fulfilled promises.
    // Only initiate a new validation API call if the value doesn't exist in the cache.
    if (!validationCache[phone]) {
      // Store the promise to the cache
      validationCache[phone] = fetcher(
        '/auth/user/phone/validate/',
        {
          method: 'POST',
          body: JSON.stringify({
            phone,
          }),
        },
      )
        .then((response) => response.json())
        .then((body: PhoneValidationResponse) => (body.valid === true ? '' : body.detail))
        .catch((err) => {
          captureException(err);
          return '';
        });
    }

    return validationCache[phone];
  };
}

/**
 * Async validator with built in caching.
 * Returns an error message when the number is invalid.
 * Returns an empty string when the number is valid.
 */
export default makeAsyncPhoneValidator(apiFetch);
