import React, { FC, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { trackEvent } from 'src/common/tracking';
import useSeamlessAppReload, { isStatefulRoute } from './useSeamlessAppReload';
import useAppVersion from './useAppVersion';
import useTimeout from '../../hooks/useTimeout';
import config from './config';
import useAutoReloadDebugHelpers from './useAutoReloadDebugHelpers';
import Notification from '../Notification';

import s from './SeamlessAppReload.module.scss';

const GA_AUTO_RELOAD_EVENT_CATEGORY = 'AutoReload';

const SeamlessAppReload: FC = () => {
  useAutoReloadDebugHelpers();
  const { pathname } = useLocation();
  const { currentVersion, latestVersion } = useAppVersion(config.CURRENT_VERSION);
  const eventLabel = latestVersion.slice(0, 7);

  // Reload prompt and auto reload are disabled for stateful routes
  const shouldReload = currentVersion !== latestVersion && !isStatefulRoute(pathname, '');
  const gaEventConfig = useMemo(() => ({ event_category: 'AutoReload', event_label: eventLabel }), [eventLabel]);
  useSeamlessAppReload(shouldReload, GA_AUTO_RELOAD_EVENT_CATEGORY, eventLabel);

  const reloadOnClick = useCallback(() => {
    if (typeof window !== 'undefined') {
      trackEvent('Reload Clicked', gaEventConfig);
      window.location.reload();
    }
  }, [gaEventConfig]);

  const reloadAfterTimeout = useCallback(() => {
    if (typeof window !== 'undefined') {
      trackEvent('Automatic Reloading Timeout', { ...gaEventConfig });
      window.location.reload();
    }
  }, [gaEventConfig]);

  const showReloadPrompt = useCallback(() => {
    trackEvent('Display Reload Prompt', { ...gaEventConfig });
    toast.dismiss();
    toast(
      (
        <Notification
          heading="We’ve made some improvements!"
          message={(
            <>
              {'Please '}
              <button
                type="button"
                onClick={reloadOnClick}
                className={s.reloadButton}
              >
                reload the page
              </button>
              {' when convenient for the best experience.'}
            </>
          )}
        />
      ),
      { autoClose: config.AUTO_RELOAD_TIMEOUT },
    );
  }, [gaEventConfig, reloadOnClick]);

  useTimeout(showReloadPrompt, config.RELOAD_PROMPT_TIMEOUT, shouldReload);
  useTimeout(reloadAfterTimeout, config.AUTO_RELOAD_TIMEOUT, shouldReload);

  return null;
};

export default SeamlessAppReload;
