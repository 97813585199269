import React, { FC } from 'react';
import { TripState } from 'src/types/trips/Trips';
import { getClassNameFor } from 'src/common/helpers';
import s from './TripStateIndicatorCalendar.module.scss';

type Props = {
  classNameModifier?:string;
  tripState: TripState;
};

const TripStateIndicatorCalendar: FC<Props> = ({
  classNameModifier = '',
  tripState,
}) => {
  const modifiers = [
    classNameModifier,
    tripState?.state ?? '',
  ].join(' ');

  return (
    <li
      className={getClassNameFor(s, 'root', modifiers)}
      data-test="TripStateIndicator"
    >
      {tripState?.badge ?? ''}
    </li>
  );
};

export default TripStateIndicatorCalendar;
