import React, { FC, useState } from 'react';

import { ImgixParams } from '../../../../types/imgix';
import { getClassNameFor } from '../../../helpers';
import LazyImg from '../../LazyImg';
import s from './ListingPhoto.module.scss';
import BoatPlaceholderImage from '../../BoatPlaceholderImage';
import UniversalLink from '../../UniversalLink';

// TODO: Remove or revise these as needed.
// This was put in place for the FeaturedListings
// on the homepage, but may not make for other use cases
// (especially if resolutions of pictures change).
const defaultImgixProps: ImgixParams = {
  fit: 'crop',
  w: 300,
  h: 180,
  faces: false,
};

type Props = {
  listingUrl?: string;
  headline: string;
  photoUrl: string;
  imgixProps?: ImgixParams;
  offset?: number;
  classNameModifier?: string;
  openInNewTab?: boolean;
};

const ListingPhoto: FC<Props> = ({
  listingUrl,
  headline,
  photoUrl,
  children,
  imgixProps = defaultImgixProps,
  offset,
  classNameModifier,
  openInNewTab = false,
}) => {
  const [imageLoadFailed, setImageLoadFailed] = useState(false);
  const image = (photoUrl && !imageLoadFailed) ? (
    <LazyImg
      src={photoUrl}
      alt={headline}
      className={getClassNameFor(s, 'image', classNameModifier)}
      sizes={undefined}
      height={180}
      width={300}
      offset={offset}
      onError={() => setImageLoadFailed(true)}
      imgixProps={{ auto: ['enhance'], ...imgixProps }}
    />
  ) : (
    <BoatPlaceholderImage classNameModifier={classNameModifier} />
  );

  return listingUrl ? (
    <UniversalLink
      to={listingUrl}
      className={getClassNameFor(s, 'root', classNameModifier)}
      openInNewTab={openInNewTab}
    >
      {image}
      {children}
    </UniversalLink>
  ) : (
    <div className={getClassNameFor(s, 'root', classNameModifier)}>
      {image}
      {children}
    </div>
  );
};

export default ListingPhoto;
