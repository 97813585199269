import React, { FC, useEffect } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../../common/helpers';
import s from './CTA.module.scss';

const PINNED_CLASS_NAME = 'with-pinned-CTA';
const WARNING_CLASS_NAME = 'with-warning';

type Props = {
  classNameModifier?: string;
  withWarning?: boolean;
};

const CTA: FC<Props> = ({ children, classNameModifier = '', withWarning }) => {
  useEffect(() => {
    document.body.classList.toggle(PINNED_CLASS_NAME, true);
    if (withWarning) {
      document.body.classList.toggle(WARNING_CLASS_NAME);
    }
    return () => {
      document.body.classList.remove(PINNED_CLASS_NAME);
      document.body.classList.remove(WARNING_CLASS_NAME);
    };
  }, [withWarning]);

  const modifiers = classNames(classNameModifier, { withWarning });

  return (
    <div className={getClassNameFor(s, 'root', modifiers)}>
      <div className={getClassNameFor(s, 'wrapper', modifiers)}>
        {children}
      </div>
    </div>
  );
};

export { CTALink, CTAButton, CTAExternalLink, CTABackLink, CTAWrapper, CTAError } from './elements';
export default CTA;
