import React from 'react';

import type { ImmutableTrip } from 'src/types/trips/Trips';
import Dropdown from 'src/common/components/Dropdown';
import ToggleButton from 'src/common/components/ToggleButton';
import TripDetails from '../TripDetails';

import s from './DetailsDropdown.module.scss';

type DetailsDropdownProps = {
  trip: ImmutableTrip;
};

const DetailsDropdown: React.FC<DetailsDropdownProps> = ({ trip }) => (
  <Dropdown className={s.root}>
    {({ isOpen, toggle }) => (
      <>
        <ToggleButton
          onClick={toggle}
          isOpen={isOpen}
          classNameModifier="centered"
        >
          Trip Details
        </ToggleButton>
        {isOpen && (
          <TripDetails
            trip={trip}
            withTooltips={false}
          />
        )}
      </>
    )}
  </Dropdown>
);

export default DetailsDropdown;
