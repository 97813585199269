import React, { FC, useMemo } from 'react';
import classnames from 'classnames';
import { useFormState } from 'react-final-form';
import formatDuration from 'date-fns/formatDuration';
import { useSelector } from 'react-redux';

import { formatDate } from 'src/common/utils/dateHelpers';
import { getCurrencies } from '../../../common/utils/reduxStoreSelectors';
import getCaptainedCopy from '../../../common/utils/getCaptainedCopy';
import { useInstabookEditorContext } from '../../hooks';
import { getCanonicalForm } from '../../../common/currencies';
import {
  INSTABOOK_PRICE_FIELD,
  INSTABOOK_EDITOR_DATES_FIELD,
  INSTABOOK_EDITOR_TRIP_DURATION_FIELD,
  INSTABOOK_CAPTAINED_FIELD,
  INSTABOOK_CAPTAINED_ERROR,
  INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD,
} from '../../constants';
import { InstabookFormState } from '../../instabook';

import s from './InstabookHeaderInfo.module.scss';

type Props = {
  isDatePickerEditorOpen?: boolean;
  selectedDays?: Date[] | [];
};

const InstabookHeaderInfo: FC<Props> = ({ isDatePickerEditorOpen, selectedDays }) => {
  const currencies = useSelector(getCurrencies);
  const { values, errors } = useFormState<InstabookFormState>();
  const { instabook, boat } = useInstabookEditorContext();

  const price = values[INSTABOOK_PRICE_FIELD];
  const duration = values[INSTABOOK_EDITOR_TRIP_DURATION_FIELD] || 0;
  const captained = values[INSTABOOK_CAPTAINED_FIELD];
  const tripLength = formatDuration({ hours: duration });
  const { symbol: currencySymbol } = useMemo(
    // Unpublished boat doesn't have the currency field, so we get it from the instabook
    () => getCanonicalForm(boat?.currency || instabook?.currency, currencies),
    [boat?.currency, currencies, instabook?.currency],
  );
  const captainCopy = getCaptainedCopy(captained, values[INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD]);

  const formattedPrice = useMemo(
    () => currencySymbol
      && `${currencySymbol}${currencySymbol.length > 1 ? ' ' : ''}${price.toLocaleString()}`,
    [currencySymbol, price],
  );

  const dates = selectedDays || values[INSTABOOK_EDITOR_DATES_FIELD];
  const dateCount = dates.length;

  // If the Date Picker Editor is being shown,
  // display the selected date count and increase/decrease upon selection toggle.
  // Otherwise, display the date count if greater than 1,
  // otherwise, display single date as human-readable format.
  const renderDatesText = () => {
    if (isDatePickerEditorOpen) {
      return <strong>{`Selected dates: ${dateCount}`}</strong>;
    }
    return dateCount === 1 ? formatDate(dates[0], 'MMM d yyyy', {}) : `${dateCount} dates`;
  };

  return (
    <p className={s.root}>
      <span className={s.selectedDates}>{renderDatesText()}</span>
      {` • ${tripLength}`}
      {!!boat && (
        <span
          className={classnames({
            [s.unavailable]: !!boat && !!errors[INSTABOOK_CAPTAINED_ERROR],
          })}
        >
          {captained != null && ` • ${captainCopy.shortHeading}`}
        </span>
      )}
      {!!price && (
        <>
          <br />
          Price: {formattedPrice}
        </>
      )}
    </p>
  );
};

export default InstabookHeaderInfo;
