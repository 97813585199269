import React, { FC } from 'react';
import { Field, useField, useFormState } from 'react-final-form';
import classNames from 'classnames';

import { getClassNameFor } from '../../../common/helpers';
import {
  INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_FIELD,
  INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_ERROR,
  INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_LENGTH_ERROR,
} from '../../constants';
import TextArea from '../../../common/components/inputs/TextArea';

import s from './InstabookAdditionalInformation.module.scss';

type InstabookAdditionalInformationProps = {
  disabled: boolean;
};

const placeholder = `Describe the trip to your customers by providing details such as:
• Key itinerary points
• Available or planned activities (swimming, snorkeling, tubing, etc.)
• Inclusions (snacks, watersport equipment, towels, etc.)
• Additional charges
• Rules and requirements
Feel free to highlight what makes this trip unique. Unleash your creativity, but remember, personal contact information isn't part of the adventure!`;

const InstabookAdditionalInformation: FC<InstabookAdditionalInformationProps> = ({
  disabled,
}) => {
  const {
    input: { value },
    meta: { submitFailed },
  } = useField(INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_FIELD);
  const { errors: {
    [INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_ERROR]: validationError,
    [INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_LENGTH_ERROR]: lengthError,
  } } = useFormState();

  const showValidationError = submitFailed && validationError;
  const showLengthError = lengthError;
  const characters = value?.trim()?.length || 0;

  const className = getClassNameFor(s, 'character', classNames({ error: showLengthError || showValidationError }));

  return (
    <div className={s.root}>
      <label
        htmlFor={INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_FIELD}
        className={s.label}
      >
        Trip Description
      </label>
      <Field
        required
        component={TextArea}
        disabled={disabled}
        name={INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_FIELD}
        placeholder={placeholder}
        classNameModifier={classNames('instabook', { instabook_error: showLengthError || showValidationError })}
        renderErrorMessage={false}
      />
      <div className={className}>
        {characters} / 1000 characters
      </div>
      {showValidationError && (
        <label
          htmlFor={INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_FIELD}
          className={s.error}
        >
          {validationError}
        </label>
      )}
    </div>
  );
};

export default InstabookAdditionalInformation;
