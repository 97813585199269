import React, { FC } from 'react';
import { GenericEvent } from 'src/calendar/types';
import Day from 'src/calendar/components/Day';
import MonthTitle from 'src/calendar/components/MonthTitle';
import NoEvents from 'src/calendar/components/NoEvents';
import useMonthRangeDates from 'src/calendar/hooks/useMonthRangeDates';
import s from './Agenda.module.scss';

export type AgendaProps = {
  date: Date;
  events: GenericEvent[];
  className: string;
};

const Agenda: FC<AgendaProps> = ({
  date,
  events,
  className,
}) => {
  const range = useMonthRangeDates(date);
  return (
    <div className={`${s.root} ${className}`}>
      <MonthTitle date={date} />
      <dl className={s.list}>
        {range.map(day => (
          <Day
            events={events}
            key={day.getTime()}
            day={day}
          />
        ))}
      </dl>
      {events.length === 0 && (
        <NoEvents date={date} />
      )}
    </div>
  );
};

export default Agenda;
