import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { decorateComponent } from '../../helpers';
import { open, close } from '../../ducks/zippy';
import { userPatchCall } from '../../ducks/user';
import { EXTERNAL_PATHS, TITLE } from '../../constants';
import UniversalLink from '../UniversalLink';
import Modal from '../Modal';
import Button from '../Button';
import s from './ComplianceUpdates.module.scss';

const modalName = 'compliance-updates';

const mapDispatchToProps = dispatch => ({
  openComplianceModal: () => dispatch(open(modalName)),
  closeComplianceModal: () => dispatch(close(modalName)),
  patchUser: data => dispatch(userPatchCall(data)),
});

class ComplianceUpdates extends Component {
  componentDidMount() {
    this.props.openComplianceModal();
  }

  acceptTerms = () => {
    // TODO: add error handling and submission state to this
    this.props.patchUser({ terms_agreed: true })
      .then(this.props.closeComplianceModal);
  };

  render() {
    return (
      <Modal
        name={modalName}
        label="Compliance Updates modal"
        noExit
      >
        <div className={s.root}>
          <h3 className={s.title}>
            Compliance Updates
          </h3>
          <p className={s.message}>
            {'We\'ve updated our '}
            <UniversalLink
              to={EXTERNAL_PATHS.PRIVACY_POLICY}
              className={s.link}
            >
              {TITLE.PRIVACY_POLICY}
            </UniversalLink>
            {', '}
            <UniversalLink
              to={EXTERNAL_PATHS.MEMBER_INTERFACE}
              className={s.link}
            >
              {TITLE.MEMBER_INTERFACE}
            </UniversalLink>
            {' and '}
            <UniversalLink
              to={EXTERNAL_PATHS.TERMS}
              className={s.link}
            >
              {TITLE.TERMS}
            </UniversalLink>
            {' to address new laws in Europe about data privacy. '}
            Getmyboat protects your personal information.
          </p>
          <Button
            type="button"
            onClick={this.acceptTerms}
            fullWidth
          >
            Accept
          </Button>
        </div>
      </Modal>
    );
  }
}

ComplianceUpdates.propTypes = {
  openComplianceModal: PropTypes.func.isRequired,
  closeComplianceModal: PropTypes.func.isRequired,
  patchUser: PropTypes.func.isRequired,
};

const decorators = [
  connect(null, mapDispatchToProps),
];

export default decorateComponent(ComplianceUpdates, decorators);
