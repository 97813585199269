import React, { FC, ReactNode } from 'react';
import { Map } from 'immutable';
import moment from 'moment';

import type { ImmutableUser } from 'src/types/user/User';
import { fullName } from '../../../common/utils/user';
import Comment from '../../../common/components/Comment';

type TripDetailCommentProps = {
  user?: ImmutableUser;
  date: string;
  children: ReactNode;
};

const TripDetailComment: FC<TripDetailCommentProps> = ({
  user = Map(),
  date,
  children,
}) => {
  const name = fullName(user);
  const title = date
    ? (
      <span>
        {name}
        <time dateTime={date}>
          {moment(date).format('D MMM')}
        </time>
      </span>
    )
    : name;

  return (
    <Comment
      user={user.toJS()}
      title={title}
    >
      {children}
    </Comment>
  );
};

export default TripDetailComment;
