import React, { FC, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';

import { PATHS } from '../../constants';
import s from './SignInLink.module.scss';

type SignInLink = {
  loginPath?: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

const SignInLink: FC<SignInLink> = ({
  loginPath = PATHS.LOGIN,
  className = s.root,
  onClick = () => {},
}) => (
  <Link
    to={loginPath}
    onClick={onClick}
    className={className}
  >
    Sign In
  </Link>
);

export default SignInLink;
