import React, { FC } from 'react';

import type { BoatDetail } from '../../../types/boat/BoatDetail';
import { getClassNameFor, plural } from '../../../common/helpers';
import { BROADCAST_BOATS_FIELD } from '../../constants';
import Checkbox from '../../../common/components/fields/Checkbox';
import ListingCard from '../../../common/components/ListingCard';
import s from './SimilarListings.module.scss';

type SimilarListingsProps = {
  broadcastBoats: BoatDetail[];
};

const SimilarListings: FC<SimilarListingsProps> = ({
  broadcastBoats,
}) => (
  <div
    className={getClassNameFor(
      s,
      'root',
      `${broadcastBoats.length}-item${plural(broadcastBoats.length)}`,
    )}
  >
    {broadcastBoats.map(boat => (
      <label
        key={boat.id}
        className={s.item}
      >
        <Checkbox
          name={BROADCAST_BOATS_FIELD}
          value={boat.id}
        />
        <ListingCard
          breadcrumbs={boat.breadcrumbs}
          category={boat.cat_loc_breadcrumb}
          capacity={boat.capacity.toString()}
          searchPricing={boat.search_price_nested}
          headline={boat.headline}
          listingUrl={boat.listing_url ?? '/'}
          imageSrc={boat.photo_url}
          rating={Math.round(Number(boat.review_average ?? 0))}
          isSuperOwner={boat?.user?.is_super_owner ?? false}
          openInNewTab
        />
      </label>
    ))}
  </div>
);

export default SimilarListings;
