import React, { FC } from 'react';
import { CalendarEvent, UnavailableEvent } from '../../types';

import Event from '../Event';
import Time from '../Time';
import Unavailable from '../Unavailable';
import ScrollToMe from '../../../common/components/ScrollToMe';
import TripStateIndicatorCalendar from '../../../common/components/TripStateIndicatorCalendar';

export type EventWrapperProps = {
  event: CalendarEvent | UnavailableEvent;
  dateTimeAttr: string;
  focusedThreadId: number | undefined;
  isPast: boolean;
  isSelectedDate: boolean;
  scrollToEvent: boolean | undefined;
};

const adHocEventState = {
  state: 'adHoc',
  role: 'adHoc',
  badge: 'Unavailable',
};

const EventWrapper: FC<EventWrapperProps> = ({
  event,
  dateTimeAttr,
  focusedThreadId,
  isPast,
  isSelectedDate,
  scrollToEvent,
}) => {
  const isFocusedThread = focusedThreadId === event.thread_id;
  const firstEventOfFocusedThreadId = isFocusedThread && event.day_number === 1;

  return (
    <Event
      event={event}
      time={<Time event={event} />}
      pastEvent={isPast}
      unavailable={(event.event_type === 'reservation' && event.block_type !== 0 && (
        <Unavailable
          time={<Time event={event!} isUnavailableEvent />}
          tripState={<TripStateIndicatorCalendar tripState={adHocEventState} />}
        />
      ))}
    >
      {isSelectedDate && firstEventOfFocusedThreadId && scrollToEvent && (
        <ScrollToMe
          key={`scroll-to-event-${dateTimeAttr}-${event.id}`}
          classNameModifier="calendarEventAnchor"
        />
      )}
    </Event>
  );
};

export default EventWrapper;
