import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import type { ReduxState } from '../../../../types/reduxState';
import { rffSubmitResponse } from '../../../../common/helpers';
import { userPatchCall, updateUser, getIsLoggedIn } from '../../../../common/ducks/user';
import ContactDetails from '../../../../common/components/fieldsets/ContactDetails';
import MarketingConsent from '../../../../common/components/fieldsets/MarketingConsent';
import FormError from '../../../../common/components/FormError';
import Button from '../../../../common/components/Button';
import CloseButton from '../../../../common/components/CloseButton';
import SignInOrSwitchAccounts from '../../../../common/components/SignInOrSwitchAccounts';
import useOnSubmitLocationChange from '../../../../common/hooks/useOnSubmitLocationChange';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import { PAGES } from '../../../constants';
import s from './ContactPage.module.scss';

// We want initialValues to be undefined
// if there's no data to be mapped into it,
// rather than initializing individual values
// to undefined, which will clear data.
const getContactInitialValues = ({ user }: ReduxState) => (user.get('email')
  ? {
    first_name: user.get('first_name'),
    last_name: user.get('last_name'),
    email: user.get('email'),
    phone: user.get('phone'),
    marketing_consent: user.get('marketing_consent'),
  }
  : undefined);

const ContactPage: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const returnUrl = getReturnUrl(location);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const initialValues = useSelector(getContactInitialValues);
  const onSubmitSuccess = useOnSubmitLocationChange(PAGES.REDIRECT);
  const loggedInTransition = useOnSubmitLocationChange(PAGES.PASSWORD);

  const onSubmit = useCallback(
    (values, { getState }) => {
      const { dirty } = getState();
      // If not logged in, we'll stick their details into the
      // user reducer so that the submit function can
      // send them along with the booking inquiry.
      if (!isLoggedIn) {
        dispatch(updateUser(values));
        return loggedInTransition();
      }

      // We can make these independent calls because if the user is logged in,
      // we don't actually care what order they happen in.
      return Promise.all([
        // If they're logged in and they've changed things,
        // we want to update their contact info on the backend.
        dirty && dispatch(userPatchCall(values)),
        // If the inquiry is streamlined, go ahead and submit.
        // streamlineInquiry && streamlineSubmit({ inquiry: inquiry.get('details'), boat })(values),
      ])
        .then(onSubmitSuccess)
        .catch(rffSubmitResponse());
    },
    [dispatch, isLoggedIn, loggedInTransition, onSubmitSuccess],
  );

  return (
    <div className={s.container}>
      <Helmet>
        <title>
          Contact
        </title>
      </Helmet>
      {isLoggedIn
        ? (<h1 className={s.title}>Confirm Contact Details</h1>)
        : (<h1 className={s.title}>Create Account</h1>)}
      <div className={s.message}>
        <SignInOrSwitchAccounts />
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitError }) => (
          <form
            method="POST"
            onSubmit={handleSubmit}
            className={s.form}
          >
            {submitError && <FormError error={submitError} className={s.error} />}
            <ContactDetails
              isLoggedIn={isLoggedIn}
            />
            <MarketingConsent />

            <Button
              type="submit"
              data-test="next"
              fullWidth
            >
              Next
            </Button>
          </form>
        )}
      </Form>
      <CloseButton backUrl={returnUrl} />
    </div>
  );
};

export default ContactPage;
