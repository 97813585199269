import captureException from './captureException';

/**
 * @param {unknown} err
 * @returns {boolean}
 */
const isChunkLoaderError = err => err instanceof Error && err.name === 'ChunkLoadError';

/**
 * Helper function to retry dynamic imports in case of network failure.
 * @template T The type that is returned by the loader
 * @param {() => Promise<T>} loader The function call import
 * @param {() => Promise<T | undefined>} fallback What to do when loading and retrying fails
 * @returns {Promise<T | undefined>}
 */
const dynamicImport = (
  loader,
  fallback = () => { },
) => {
  let importAttempt = 1;
  /**
   * Assume a network failure and attempt to reload the import.
   * @param {unknown} err
   * @returns {Promise<any>}
   */
  const retry = err => {
    if (isChunkLoaderError(err)) {
      captureException(err, {
        extra: {
          importAttempt,
        },
      });
      importAttempt += 1;
      return loader();
    }
    // This is not the error we're trying to cater for, so propagate it.
    throw err;
  };

  return loader()
    .catch(retry)
    .catch(retry)
    .catch(err => {
      captureException(err, {
        extra: {
          importAttempt: isChunkLoaderError(err) ? importAttempt : null,
        },
      });

      return fallback;
    });
};

export default dynamicImport;
