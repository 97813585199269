import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { HotKeys } from 'react-hotkeys';
import { useLocation, useHistory } from 'react-router-dom';

import { fullName } from 'src/common/utils/user';
import { TITLE } from '../../../../common/constants';
import { getReviewUrl, isNegativeReview } from '../../../helpers';
import TextArea from '../../../../common/components/inputs/TextArea';
import FormError from '../../../../common/components/FormError';
import TripPanel from '../../presentation/TripPanel';
import CTA, { CTABackLink, CTALink, CTAButton } from '../../CTA';
import Card from '../../presentation/Card';
import { REVIEW_PAGES, FIELDS } from '../../pages/Complete/reviewsConfig';
import getContent from './content';
import s from '../ReviewStep.module.scss';

const { RATING, RECOMMENDATION, PRIVATE_NOTE } = FIELDS;

const PrivateNote = ({
  trip,
  role,
  backLocation,
  values: {
    [RECOMMENDATION]: recommendation,
    [PRIVATE_NOTE]: privateNote,
    [RATING]: rating,
  },
  submitting,
  handleSubmit,
  submitError,
  progressBar,
}) => {
  const location = useLocation();
  const { push } = useHistory();
  const backUrl = getReviewUrl(REVIEW_PAGES.PRIVATE_NOTE, role, 'back', location);
  const nextUrl = getReviewUrl(REVIEW_PAGES.PRIVATE_NOTE, role, 'next', location);
  const goToNextPage = () => push(nextUrl);
  // TODO: this validation should probably be on the backend as well.
  const shouldSubmit = isNegativeReview(rating, recommendation);
  const cta = (
    <CTA classNameModifier="withSidebar">
      <CTABackLink to={backUrl} />
      {shouldSubmit
        ? (
          <CTAButton
            type="button"
            submitting={submitting}
            onClick={handleSubmit}
          >
            Publish
          </CTAButton>
        )
        : (
          <CTALink
            to={nextUrl}
            data-test={privateNote ? 'next' : 'skip'}
          >
            {privateNote ? 'Next' : 'Skip'}
          </CTALink>
        )}
    </CTA>
  );

  const renter = trip.get('renter');
  const renterName = fullName(renter);
  const owner = trip.get('owner');
  const ownerName = fullName(owner);

  const isPositiveReview = recommendation;
  const {
    title,
    subTitle,
    text,
    placeholder,
  } = getContent(role, isPositiveReview, renterName, ownerName);

  return (
    <TripPanel
      trip={trip}
      subheader={TITLE.REVIEW}
      cta={cta}
      backLocation={backLocation}
    >
      <Card classNameModifier="review">
        {progressBar}
        <h3 className={s.title}>
          {title}
        </h3>
        <h4 className={s.subTitle}>
          {subTitle}
        </h4>
        <FormError error={submitError} />
        <HotKeys
          handlers={{ next: shouldSubmit ? handleSubmit : goToNextPage }}
          keyMap={{ next: ['ctrl+enter', 'meta+enter'] }}
        >
          <Field
            autoFocus
            placeholder={placeholder}
            name={PRIVATE_NOTE}
            component={TextArea}
          />
        </HotKeys>
        {text && (
          <p className={s.text}>
            {text}
          </p>
        )}
      </Card>
    </TripPanel>
  );
};

PrivateNote.propTypes = {
  trip: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  backLocation: PropTypes.object.isRequired,
  values: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitError: PropTypes.string,
  progressBar: PropTypes.node,
};

export default PrivateNote;
