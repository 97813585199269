import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { Map } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import { Helmet } from 'react-helmet-async';

import { useHistory, useLocation } from 'react-router-dom';
import useFeatureFlag from 'src/common/hooks/useFeatureFlag';
import { unpackApiError } from '../../../../common/helpers';
import useOnSubmitLocationChange from '../../../../common/hooks/useOnSubmitLocationChange';
import Button from '../../../../common/components/Button';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import { BROADCAST_BOATS_FIELD, PAGES } from '../../../constants';
import {
  getBoat, getInquiry, getInquiryHasMismatches,
} from '../../../../common/utils/reduxStoreSelectors';
import { streamlineSubmit, streamlineNextPage, commonSubmit } from '../../../submit';
import { updateInquiry, getBroadcastBoats, getInstabookListings, setInquiryId } from '../../../ducks/inquiry';
import { BackButton } from '../../../../common/components/BackButton';
import CloseButton from '../../../../common/components/CloseButton';
import MismatchesList from '../../Mismatches';
import FormError from '../../../../common/components/FormError';
import s from './Mismatches.module.scss';
import { ReduxState } from '../../../../types/reduxState';

const getNothingMatching = ({ inquiry }: ReduxState): boolean => (
  inquiry.get(BROADCAST_BOATS_FIELD).isEmpty()
);

/*
 * NOTE:
 * All of the extra logic on this page (onSubmit, onSubmitSuccess,
 * the difference between CleanMarkup and MismatchMarkup) is to
 * handle the case where a user:
 * 1. gets to the mismatch page with mismatches
 * 1. fixes those mismatches through the mismatch flow (i.e. jumping
 * directly to the offending page and then back)
 * 1. needs to update the broadcast_boats in light of the fact that
 * the inquiry has changed
 *
 * The last step is what necessitates all of this.
 */

const CleanMarkup: FC = () => (
  <>
    <h1 className={s.title}>
      Ready to Go
    </h1>
    <div className={s.message}>
      <p>
        Your inquiry is now ready to send. Please continue and we will either
        find similar listings or send your inquiry so the listing owner can send
        you a custom price, itinerary and more information. No commitment
        or credit card needed.
      </p>
    </div>
  </>
);

type MismatchMarkupProps = {
  nothingMatching?: boolean;
};

const MismatchMarkup: FC<MismatchMarkupProps> = ({ nothingMatching }) => (
  <>
    <h1 className={s.title}>
      {"Let's try something else"}
    </h1>
    <div className={s.message}>
      <p>
        It looks like the listing you selected can’t help with parts of your inquiry.
      </p>
      {nothingMatching
        ? (
          <p>
            Please edit your inquiry or try a new search.
          </p>
        )
        : (
          <p>
            Please edit your inquiry or continue anyway to select similar listings that can help.
          </p>
        )}
    </div>
  </>
);

const Mismatches: FC = () => {
  const isInstabookListingsEnabled = useFeatureFlag('instaspike--inquiry-flow');
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const inquiry = useSelector(getInquiry);
  const boat = useSelector(getBoat);
  const nothingMatching = useSelector(getNothingMatching);
  const inquiryHasMismatches = useSelector(getInquiryHasMismatches);
  const [error, setError] = useState<ReactNode>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { goBack, push } = history;
  const searchUrl = inquiry.get('search_url');
  const query = parse(location.search);
  const returnUrl = getReturnUrl(location);
  const matchingPageTransition = useOnSubmitLocationChange(PAGES.MATCH);
  const onSubmitSuccess = useOnSubmitLocationChange(streamlineNextPage(inquiry));
  const inquiryDetails = inquiry.get('details');
  const inquiryPayload = useMemo(() => ({
    ...inquiryDetails
      .filter(d => d !== '')
      .toJS(),
    boat: boat.get('id'),
  }), [boat, inquiryDetails]);
  const submitInquiry = useCallback(
    () => {
      setSubmitting(true);
      return Promise.all(
        [
          dispatch(getBroadcastBoats(inquiryPayload)),
          isInstabookListingsEnabled && dispatch(getInstabookListings(inquiryPayload)),
        ],
      )
        .then(([broadcastBoats, instabookListings]) => {
          // If there are broadcastable boats, we want to take them to the matching
          // listings page
          if (!broadcastBoats.isEmpty()) {
            if (!inquiryHasMismatches) {
              return commonSubmit({ boat, inquiry: inquiryDetails })
                .then((response) => {
                  response.json().then(({ id }) => {
                    dispatch(setInquiryId(id));
                  });
                  matchingPageTransition();
                });
            }
            dispatch(updateInquiry(Map({ broadcast_only: inquiryHasMismatches })));
            return matchingPageTransition();
          }
          // If there are no broadcastable listings, we want to submit the inquiry
          // and be done with it.
          return streamlineSubmit({ boat, inquiry: inquiryDetails })({})
            .then(() => {
              if (instabookListings && !instabookListings.isEmpty()) {
                matchingPageTransition();
              } else {
                onSubmitSuccess();
              }
            });
        })
        .catch((response: Response) => {
          unpackApiError(response).then(err => {
            setError(err?.detail ?? err);
            // NOTE: we don't need to set submitting to false on success
            // because it's going to transition away from this page on success
            setSubmitting(false);
          });
        });
    },
    [
      boat,
      dispatch,
      inquiryHasMismatches,
      matchingPageTransition,
      onSubmitSuccess,
      isInstabookListingsEnabled,
      inquiryPayload,
      inquiryDetails,
    ],
  );

  return (
    <div className={s.container}>
      <Helmet>
        <title>
          Mismatches
        </title>
      </Helmet>
      {inquiryHasMismatches
        ? (
          <>
            <MismatchMarkup nothingMatching={nothingMatching} />
            <MismatchesList
              inquiry={inquiry}
              boatId={boat.get('id')}
              query={query}
            />
          </>
        )
        : <CleanMarkup />}
      <div className={s.buttons}>
        <Button
          type="button"
          onClick={goBack}
          classNameModifier="paired gray"
        >
          Go Back
        </Button>
        {nothingMatching && inquiryHasMismatches
          ? (
            <Button
              type="button"
              onClick={() => push(searchUrl)}
              classNameModifier="paired"
            >
              New Search
            </Button>
          )
          : (
            <Button
              type="submit"
              classNameModifier="paired"
              onClick={submitInquiry}
              submitting={submitting}
            >
              Continue
            </Button>
          )}
      </div>
      <FormError error={error} />
      <CloseButton backUrl={returnUrl} />
      <BackButton onClick={goBack} />
    </div>
  );
};

export default Mismatches;
