import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import ActionSheet from 'src/common/components/ActionSheet';
import Dropdown from 'src/common/components/Dropdown';
import DropdownDS22, { DropdownOption, Variant, DropdownPosition } from 'src/common/components/DropdownDS22';
import Icon from 'src/common/components/IconDS22';
import { getClassNameFor } from 'src/common/helpers';
import styles from './ActionsDropdown.module.scss';

export const modes = ['menu', 'select'] as const;

export type ActionsDropdownProps = {
  options: DropdownOption[];
  handleSelect: (item: DropdownOption) => void;
  label?: ReactNode;
  showCaret?: boolean;
  variant?: Variant;
  dropdownPosition?: DropdownPosition;
  mode?: typeof modes[number];
  defaultOption?: DropdownOption;
  classNameModifier?: string;
  disabled?: HTMLButtonElement['disabled'];
  fullWidth?: boolean;
  selectedItemRenderer?: (option: DropdownOption) => React.ReactNode;
};
const ActionsDropdown: FC<ActionsDropdownProps> = ({
  options,
  handleSelect = () => {},
  label = '',
  showCaret = true,
  variant = 'white',
  dropdownPosition = 'left',
  mode = 'menu',
  defaultOption,
  classNameModifier,
  disabled,
  fullWidth,
  selectedItemRenderer,
}) => {
  const initialValue = defaultOption ? defaultOption.dropdownLabel : label;
  const [text, setText] = useState(initialValue);
  const [selectedOption, setSelectedOption] = useState<DropdownOption | undefined>(defaultOption);

  useEffect(() => {
    if (defaultOption) {
      setText(defaultOption.dropdownLabel);
    }
  }, [defaultOption]);

  const handleOnClick = useCallback((option) => {
    handleSelect(option);
    setSelectedOption(option);
    if (mode === 'select') {
      setText(option.dropdownLabel);
    }
  }, [handleSelect, mode]);

  return (
    <div className={getClassNameFor(styles, 'root', classNames(classNameModifier))}>
      <div className={getClassNameFor(styles, 'desktop', variant)}>
        <DropdownDS22
          instructionLabel={text}
          variant={variant}
          dropdownPosition={dropdownPosition}
          mode={mode}
          options={options}
          onChange={handleSelect}
          defaultOption={defaultOption}
          disabled={disabled}
          fullWidth={fullWidth}
          selectedItemRenderer={selectedItemRenderer}
          displayCaret={showCaret}
        />
      </div>
      <Dropdown
        disableOnClickOutside
        className={styles.mobile}
      >
        {({ close, isOpen, toggle }) => (
          <>
            <button
              className={getClassNameFor(styles, 'button', classNames(variant, { fullWidth }))}
              id="actionMenu-button"
              type="button"
              onClick={toggle}
              aria-haspopup="true"
              aria-controls="menu"
              disabled={disabled}
              aria-disabled={disabled}
            >
              {text}
              {showCaret && (
                <span className={styles.buttonCaret}>
                  <Icon
                    id="caret-down"
                    size="s"
                  />
                </span>
              )}
            </button>
            <ActionSheet
              close={close}
              isOpen={isOpen}
            >
              <ul
                id="actionMenu"
                className={styles.list}
                role="menu"
                aria-labelledby="actionMenu-button"
              >
                {options.map((option: DropdownOption) => option && (
                  <li
                    role="presentation"
                    className={getClassNameFor(styles, 'listItem', classNames({
                      disabled: option.disabled,
                      selected: option.id === selectedOption?.id,
                    }))}
                    key={option.id}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        handleOnClick(option);
                        close();
                      }}
                    >
                      {option.icon && <Icon id={option.icon} size="m" />}
                      {option.dropdownLabel}
                    </button>
                  </li>
                ))}
              </ul>
            </ActionSheet>
          </>
        )}
      </Dropdown>
    </div>
  );
};

export default ActionsDropdown;
