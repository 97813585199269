import { useEffect } from 'react';

/**
 * Highlights calendar event cards that are related by adding the `focusedEvent` class directly to
 * the related DOM elements.
 */
const focusEventCard = (event: MouseEvent) => {
  if (
    event.target instanceof HTMLElement
    && event.currentTarget instanceof HTMLElement
    && event.target.dataset?.focusEventId
  ) {
    event.stopPropagation();
    const selectedId = event.target.dataset.focusEventId;
    const container = event.currentTarget;
    const eventCards = container?.querySelectorAll('[data-focus-event-id]');

    eventCards.forEach(eventCard => {
      if (eventCard instanceof HTMLElement) {
        eventCard.classList.toggle('focusedEvent', eventCard.dataset?.focusEventId === selectedId);
      }
    });
  }
};

/**
 * Adds a mouseenter event listener for highlighting related event cards
 * @param ref A reference to the container element
 */
const useFocusedEvents = <T extends HTMLElement>(ref: React.MutableRefObject<T | null>) => {
  const container = ref.current;
  useEffect(() => {
    if (!container) {
      return () => { };
    }

    container.addEventListener('mouseenter', focusEventCard, { capture: true });
    return () => {
      container.removeEventListener('mouseenter', focusEventCard, { capture: true });
    };
  }, [container]);
};

export default useFocusedEvents;
