import React from 'react';
import PropTypes from 'prop-types';

import Svg from '../../../common/components/Svg';
import s from './PhotoInput.module.scss';

const PhotoInput = ({
  inputId,
  label,
  onChange,
}) => (
  <div className={s.root}>
    <label
      htmlFor={inputId}
      className={s.label}
    >
      <Svg
        href="#add-photos"
        className={s.icon}
      />
      {label}
    </label>
    <input
      id={inputId}
      name={inputId}
      type="file"
      accept="image/*"
      multiple
      onChange={({ target }) => {
        const input = target;
        onChange(input.files);
        // Reset the input's value so that the same image can be uploaded again without
        // needing to select a different image first.
        input.value = '';
      }}
      className={s.input}
    />
  </div>
);

PhotoInput.propTypes = {
  inputId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PhotoInput;
