import React, { FC } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../../common/helpers';

import { InstabookEvent } from '../../types';

import CaptainIcon from '../../../common/components/CaptainSnippet/CaptainIcon';
import getCaptainedCopy from '../../../common/utils/getCaptainedCopy';

import s from './InstabookCardCaptained.module.scss';

export type InstabookCardCaptainedProps = {
  captained: InstabookEvent['captained'];
  captainCostIncluded: InstabookEvent['captain_cost_included'];
  conflict: boolean;
};

const InstabookCardCaptained: FC<InstabookCardCaptainedProps> = ({
  captained,
  captainCostIncluded,
  conflict,
}) => (
  <div className={getClassNameFor(s, 'root', classNames({ conflict }))}>
    <CaptainIcon
      captained={captained}
      classNameModifier={classNames('instabook', 'calendarCard', { noCaptain: !captained })}
    />
    <p className={s.text}>
      <span>{getCaptainedCopy(captained, captainCostIncluded).heading}</span>
    </p>
  </div>
);

export default InstabookCardCaptained;
