import React, { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import { parse } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import { PASSWORD_LENGTH, PATHS } from '../../../../common/constants';
import apiFetch from '../../../../core/fetch';
import FormError from '../../../../common/components/FormError';
import Button from '../../../../common/components/Button';
import CreatePassword from '../../../../common/components/fieldsets/CreatePassword';
import { rffSubmitResponse } from '../../../../common/helpers';
import s from './ForgotPasswordConfirm.module.scss';

const ForgotPasswordConfirm: FC = () => {
  const title = 'Change Your Password';
  const { search } = useLocation();
  const { replace } = useHistory();

  const query = parse(search);
  const { uid, token } = query;
  const onSubmit = useCallback(
    ({ password1, password2 }) => (
      apiFetch('/auth/password/reset/confirm/', {
        method: 'POST',
        body: JSON.stringify({
          new_password1: password1,
          new_password2: password2,
          uid,
          token,
        }),
      })
        .then(rffSubmitResponse(() => replace(PATHS.LOGIN)))
    ),
    [token, uid, replace],
  );

  return (
    <div className={s.container}>
      <Helmet>
        <title lang="en">{title}</title>
      </Helmet>

      <div className={s.intro}>
        <h1 className={s.title}>{title}</h1>
        <div className={s.message}>
          <p>Your password needs to be {PASSWORD_LENGTH} characters minimum.</p>
        </div>
      </div>

      <Form
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitError, submitting }) => (
          <form
            method="POST"
            onSubmit={handleSubmit}
            className={s.form}
          >
            <FormError error={submitError} />
            <fieldset>
              <CreatePassword />
            </fieldset>

            <div className={s.actionGroup}>
              <Button
                type="submit"
                submitting={submitting}
                fullWidth
              >
                Set New Password
              </Button>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default ForgotPasswordConfirm;
