import { differenceInWeeks, endOfDay, isPast } from 'date-fns';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SAVED_INQUIRY_KEY, SEARCH_INQUIRY_LATEST_KEY } from 'src/booking-inquiry/constants';
import { toDateString, todayDate } from 'src/calendar/helpers';
import { RecentSearch } from 'src/common/components/RecentSearches/RecentSearch';
import { BOOKING_MOTIVATOR_STATE, RECENT_SEARCH_NAME, RECENT_SEARCHES_KEY } from 'src/common/constants';
import { constructRecentSearchCookie } from 'src/common/utils/recentInquiryCookieData';
import { ImmutableSearchBoatDetails } from 'src/types/boat/BoatDetail';
import { Inquiry } from 'src/types/inquiry/Inquiry';

export const INQUIRY_COOKIE_EXPIRY_IN_DAYS = 1;

type TripDuration = '1-2-hours' | '2-4-hours' | '4-8-hours' | '8-12-hours' | 'multi-day';

type SearchAssistantInquiry = {
  duration?: TripDuration;
  dates?: Date[];
  capacity?: number;
  captained?: 'true' | 'false';
};

export type SavedInquiry = {
  details: Inquiry['details'] & SearchAssistantInquiry;
  saveInquiryDate: Date | string;
  saveInquiryType: 'inquiry';
};

type UseSavedInquiry = {
  savedInquiry: SavedInquiry | null;
  storeInquiryInLocalStorage: (inquiry: Inquiry) => void;
  storeInquiryInCookies: (inquiry: Inquiry) => void;
};

const asBoolean = (value: string | undefined | null): boolean | undefined => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return undefined;
};
const asDuration = (value: TripDuration | undefined | null): string | undefined => {
  if (!value) return undefined;
  if (value === 'multi-day') return 'PT24H';
  const [, hours] = value.split('-');
  if (!hours) return undefined;
  return `PT${hours}H`;
};

const useSavedInquiry = (boat: ImmutableSearchBoatDetails): UseSavedInquiry => {
  const [savedInquiry, setSavedInquiry] = useState<SavedInquiry | null>(null);
  const bookingMotivators = useMemo(() => boat?.get('booking_motivators')?.toJS() || {}, [boat]);
  const isDateExpired = useCallback((date: string) => isPast(endOfDay(new Date(date))), []);
  const isDateUnavailable = useCallback((date: string) => (
    date
    && (bookingMotivators[date]?.includes(BOOKING_MOTIVATOR_STATE.UNAVAILABLE)
    || isDateExpired(date))
  ), [bookingMotivators, isDateExpired]);

  const handleSentInquiry = useCallback((data: SavedInquiry): void => {
    const result = data;
    const {
      saveInquiryDate,
      details: {
        preferred_date1: date1,
        preferred_date2: date2,
        preferred_date3: date3,
      },
    } = result;
    if (
      (differenceInWeeks(todayDate(), new Date(saveInquiryDate)) >= 2)
      || (date1 && isDateExpired(date1))
    ) {
      localStorage.removeItem(SAVED_INQUIRY_KEY);
      return;
    }
    let [mainDate, altDate1, altDate2] = [date1, date2, date3].map((date) => {
      if (date && isDateUnavailable(date)) {
        return undefined;
      }
      return date;
    });
    mainDate = mainDate || altDate1 || altDate2;
    altDate1 = mainDate === altDate1 ? undefined : altDate1;
    altDate2 = mainDate === altDate2 ? undefined : altDate2;
    result.details.preferred_date1 = mainDate;
    result.details.preferred_date2 = altDate1;
    result.details.preferred_date3 = altDate2;
    result.saveInquiryType = 'inquiry';
    setSavedInquiry(result);
  }, [isDateUnavailable, isDateExpired]);

  const handleRecentSearch = useCallback((data: RecentSearch): void => {
    const result = {
      details: {},
      saveInquiryDate: new Date(),
      saveInquiryType: 'inquiry',
    } as SavedInquiry;

    const {
      duration,
      dates,
      capacity: capacityString,
      captained,
    } = data;
    const capacity = parseInt(capacityString ?? '', 10) ?? 0;

    const firstAvailableDate = dates?.find(
      (date) => !isDateUnavailable(toDateString(new Date(date))),
    );
    result.details.preferred_date1 = firstAvailableDate
      ? toDateString(new Date(firstAvailableDate))
      : undefined;

    const maxCapacity = boat?.get('capacity');
    result.details.adults = (capacity && capacity <= maxCapacity) ? capacity : undefined;
    result.details.captain_required = asBoolean(captained);
    result.details.trip_length = asDuration(duration as TripDuration);

    setSavedInquiry(result);
  }, [boat, isDateUnavailable]);

  useEffect(() => {
    const savedInquiryString = localStorage.getItem(SAVED_INQUIRY_KEY);
    const savedRecentSearchString = localStorage.getItem(RECENT_SEARCHES_KEY);
    const savedSearchInquiry = localStorage.getItem(SEARCH_INQUIRY_LATEST_KEY);
    const savedInquiryParsed: SavedInquiry = savedInquiryString && JSON.parse(savedInquiryString);

    // safety check as older data might exist
    if (savedSearchInquiry === 'inquiry' && savedInquiryParsed?.saveInquiryType === 'inquiry' && boat) {
      handleSentInquiry(savedInquiryParsed);
    } else {
      const recent: RecentSearch = (JSON.parse(savedRecentSearchString ?? '[]')?.[0] as RecentSearch
      ) ?? {};
      handleRecentSearch(recent);
    }
  }, [boat, handleSentInquiry, handleRecentSearch]);

  const storeInquiryInLocalStorage = (inquiry: Inquiry) => {
    const updatedInquiry = {
      details: inquiry.details,
      saveInquiryDate: new Date(),
      saveInquiryType: 'inquiry',
    };
    localStorage.setItem(SAVED_INQUIRY_KEY, JSON.stringify(updatedInquiry));
    localStorage.setItem(SEARCH_INQUIRY_LATEST_KEY, 'inquiry');
  };

  const storeInquiryInCookies = (inquiry: Inquiry) => {
    const {
      preferred_date1: date,
      trip_length: duration,
      captain_required: captained,
      adults,
      seniors,
      children,
      infants,
    } = inquiry.details;

    const capacity = [adults, seniors, children, infants]
      .reduce((
        partialSum: number | undefined,
        val: number | undefined,
      ) => (partialSum ?? 0) + (val ?? 0), 0);

    Cookies.set(
      RECENT_SEARCH_NAME,
      constructRecentSearchCookie({
        capacity: `${capacity}` || null,
        dates: date ? [date] : [],
        duration: duration || null,
        captained,
      }),
      { expires: INQUIRY_COOKIE_EXPIRY_IN_DAYS },
    );
  };

  return {
    savedInquiry,
    storeInquiryInLocalStorage,
    storeInquiryInCookies,
  };
};

export default useSavedInquiry;
