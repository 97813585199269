import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import Icon from 'src/common/components/IconDS22';
import { getClassNameFor } from 'src/common/helpers';
import styles from './InputError.module.scss';

type Props = {
  children: ReactNode;
  preventLayoutShift?: boolean;
  /**
   * Show warning icon next to error message
   */
  showErrorMessageIcon?: boolean;
  centered?: boolean;
};
const InputError: FC<Props> = ({
  children,
  preventLayoutShift = true,
  showErrorMessageIcon = false,
  centered = false,
}) => (
  <div className={getClassNameFor(styles, 'errorWrapper', classNames({
    preventLayoutShift,
    showErrorMessageIcon,
    centered,
  }))}
  >
    {showErrorMessageIcon && <Icon id="warning-filled" className={styles.icon} />}
    <span className={getClassNameFor(styles, 'error', classNames({ showErrorMessageIcon }))}>{children}</span>
  </div>
);

export default InputError;
