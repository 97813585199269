import { sortBy } from 'lodash';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import addDays from 'date-fns/addDays';
import add from 'date-fns/add';
import format from 'date-fns/format';
import set from 'date-fns/set';
import isPast from 'date-fns/isPast';
import startOfMonth from 'date-fns/startOfMonth';
import { addWeeks, differenceInWeeks, isEqual, isBefore, isAfter, isWithinInterval } from 'date-fns';
import { TRIP_STATES } from 'src/inbox/constants';
import { BoatDetail } from 'src/types/boat/BoatDetail';
import { formatDate, newTZDate, todayDate, tomorrowDate } from 'src/common/utils/dateHelpers';
import {
  CALENDAR_DATE_FORMAT_DATE_FNS,
  INSTABOOK,
  INSTABOOK_WARNINGS,
  OFFER,
  INQUIRY,
  RESERVATION,
  UNAVAILABLE,
  PARTIALLY_UNAVAILABLE,
  CONFLICTED,
  MAX_NEXT_MONTHS,
} from './constants';
import {
  CalendarEvent,
  CalendarMonthData,
  EventType,
  FixedTimes,
  Frequency,
  GenericEvent,
  InstabookWarning,
} from './types';
import {
  getDate,
  isCalendarEvent,
  isInstabookEvent,
  isMultiInstabookEvent,
  isUnavailableEvent,
} from './utils/calendar';

export { default as subMonths } from 'date-fns/subMonths';
export { default as addMonths } from 'date-fns/addMonths';
export { default as differenceInMonths } from 'date-fns/differenceInMonths';
export { default as isSameDay } from 'date-fns/isSameDay';
export { default as isSameSecond } from 'date-fns/isSameSecond';
export { default as startOfDay } from 'date-fns/startOfDay';
export { default as startOfMonth } from 'date-fns/startOfMonth';
export { default as endOfMonth } from 'date-fns/endOfMonth';

export {
  newTZDate,
  todayDate,
  tomorrowDate,
};

export type AggregatedEventTypes = {
  [date: string]: {
    [k in EventType]?: string
  };
};

const eventIsMiddleDay = (event: GenericEvent): boolean => {
  if (isInstabookEvent(event)) return false;
  if (event.day_number && event.total_days) {
    return event.day_number > 1 && event.day_number < event.total_days;
  }
  return false;
};

const getEventType = (event: GenericEvent): EventType => {
  if (isInstabookEvent(event)) return INSTABOOK;
  if (event.event_type === UNAVAILABLE && event.event_start_time) return PARTIALLY_UNAVAILABLE;
  if (event.event_type) return event.event_type;
  return UNAVAILABLE;
};

const isBlockedAllDay = (event: GenericEvent): boolean => (
  isInstabookEvent(event) ? false : event.block_type === 1
);

const isBlockedByTime = (event: GenericEvent): boolean => (
  isInstabookEvent(event) ? false : event.block_type === 2
);

const isConflicted = (event: GenericEvent): boolean => {
  if (isInstabookEvent(event)) return false;
  const isOfferCanceled = event.state?.state === TRIP_STATES.OWNER_OFFER_AUTO_CANCELLED
    || event.state?.state === TRIP_STATES.OWNER_OFFER_CANCELLED;
  const isOfferExpired = event.state?.state === TRIP_STATES.OWNER_OFFER_EXPIRED
    || event.state?.state === TRIP_STATES.OWNER_CUSTOM_OFFER_EXPIRED;

  return !!event.conflicted && !isOfferCanceled && !isOfferExpired;
};

const isTbdEvent = (event: CalendarEvent): boolean => (
  (event.event_start_time === null && event.total_days === 1)
  || (event.event_start_time === null && event.day_number === 1)
  || (event.event_end_time === null && event.day_number === event.total_days)
);

const getEventStartTime = (event: GenericEvent): string => (
  isInstabookEvent(event) ? '00:00:00' : event.event_start_time || '00:00:00'
);
const getEventEndTime = (event: GenericEvent): string => (
  isInstabookEvent(event) ? '00:00:00' : event.event_end_time || '00:00:00'
);

/**
 * Aggregates which days have the various event types so that we can display the colored dots
 * indicators accordingly.
 */
export const aggregateEvents = (events: GenericEvent[]) => (
  events.reduce<AggregatedEventTypes>((hashmap, event) => {
    const eventDate = getDate(event);
    const eventType = getEventType(event);
    // eslint-disable-next-line no-param-reassign
    hashmap[eventDate] = hashmap[eventDate] || {};
    // eslint-disable-next-line no-param-reassign
    hashmap[eventDate][eventType] = eventType;

    // Calendar Updates Logic
    if (eventType === RESERVATION && isBlockedAllDay(event)) {
      // eslint-disable-next-line no-param-reassign
      hashmap[eventDate][UNAVAILABLE] = UNAVAILABLE;
    }
    if (eventType === RESERVATION && isBlockedByTime(event) && eventIsMiddleDay(event)) {
      // eslint-disable-next-line no-param-reassign
      hashmap[eventDate][UNAVAILABLE] = UNAVAILABLE;
    }
    if (eventType === RESERVATION && isBlockedByTime(event) && !eventIsMiddleDay(event)) {
      // eslint-disable-next-line no-param-reassign
      hashmap[eventDate][PARTIALLY_UNAVAILABLE] = PARTIALLY_UNAVAILABLE;
    }
    if (isConflicted(event)) {
      // eslint-disable-next-line no-param-reassign
      hashmap[eventDate][CONFLICTED] = CONFLICTED;
    }

    return hashmap;
  }, {})
);

export const dayHasEventType = (
  date: string,
  aggregatedEvents: AggregatedEventTypes,
  type: EventType,
) => !!aggregatedEvents?.[date]?.[type];

export const dayHasOnlyUnavailableEvents = (
  dateKey: string,
  aggregatedEvents: AggregatedEventTypes,
) => (
  dayHasEventType(dateKey, aggregatedEvents, OFFER)
  || dayHasEventType(dateKey, aggregatedEvents, RESERVATION)
  || dayHasEventType(dateKey, aggregatedEvents, INQUIRY)
);

export const flattenMonths = (allEvents: CalendarMonthData[]): GenericEvent[] => {
  const initial: GenericEvent[] = [];
  return allEvents.reduce((accumulator, { events }) => accumulator.concat(events), initial);
};

const sortByThreadState = [
  // Reservations
  TRIP_STATES.OWNER_TRIP_COMPLETE_REVIEW_PENDING,
  TRIP_STATES.OWNER_TRIP_COMPLETE_REVIEW_WAITING,
  TRIP_STATES.OWNER_TRIP_COMPLETE_REVIEW_DONE,
  TRIP_STATES.OWNER_RESERVATION_CONFIRMED,
  // Changes Requested
  TRIP_STATES.OWNER_CHANGES_REQUESTED,
  // Offers
  TRIP_STATES.OWNER_OFFER_SENT,
  TRIP_STATES.OWNER_OFFER_EDITED,
  TRIP_STATES.OWNER_OFFER_EXPIRED,
  // Offer Cancelled
  TRIP_STATES.OWNER_OFFER_CANCELLED,
  TRIP_STATES.OWNER_OFFER_AUTO_CANCELLED,
  // Inquiries
  TRIP_STATES.OWNER_DIRECT_INQUIRY,
  TRIP_STATES.OWNER_MATCHING_INQUIRY,
  TRIP_STATES.OWNER_INQUIRY_LAPSED,
];

/**
 * Sort order is:
 * - All Day
 *  - Ad Hoc unavailable
 *  - Booked with unavailable
 *  - Booked without unavailable
 *    - Multi day
 *    - Stand-alone
 *  - Offer
 *  - Inquiry
 * - TBD
 *  - Multi day
 *  - Stand-alone
 * - Time
 *  - Ad Hoc unavailable
 *  - Booked with unavailable
 *  - Booked without unavailable
 *    - Multi day
 *    - Stand-alone
 *  - Offer
 *  - Inquiry
 * Defer to Renter First Name when there is no other difference
 */

export const orderCalenderEvents = (events: GenericEvent[]) => {
  let sortedEvents = events;

  const allDayEvents: GenericEvent[] = [];
  const tbdEvents: GenericEvent[] = [];
  const timeEvents: GenericEvent[] = [];

  // Loop through events and sort into buckets
  events.map(event => {
    if (isUnavailableEvent(event)) {
      if (event.block_type === 1) {
        return allDayEvents.push(event);
      }
      return timeEvents.push(event);
    }

    if (isCalendarEvent(event)) {
      if (isTbdEvent(event)) {
        return tbdEvents.push(event);
      }
      if (event.total_days && event.day_number) {
        // Multi days
        if (event.total_days > 1) {
          if (eventIsMiddleDay(event)) {
            // Middle days
            return allDayEvents.push(event);
          }
          // First or last day
          return timeEvents.push(event);
        }
      }
      return timeEvents.push(event);
    }
    return event;
  });

  const nonTbdEventSorting = (eventsToSort: GenericEvent[]) => sortBy(eventsToSort, [
    // prioritize blocked days
    x => (isCalendarEvent(x) && x.event_type === RESERVATION && isBlockedAllDay(x) ? -1 : 1),
    x => (isCalendarEvent(x) ? sortByThreadState.indexOf(x?.state?.state!) : -1),
    // prioritize multi-day trips
    x => (isCalendarEvent(x) && (x.total_days && x.total_days > 1) ? -1 : 1),
    x => (isCalendarEvent(x) ? x.renter?.first_name : -1),
  ]);

  const sortedAllDayEvents = nonTbdEventSorting(allDayEvents);

  const sortedTimeEvents = nonTbdEventSorting(timeEvents);

  const sortedTbdEvents = sortBy(tbdEvents, [
    // prioritize multi-day trips
    x => (isCalendarEvent(x) && (x.total_days && x.total_days > 1) ? -1 : 1),
    x => (isCalendarEvent(x) ? x.renter?.first_name : -1),
  ]);

  sortedEvents = sortedAllDayEvents.concat(sortedTbdEvents).concat(sortedTimeEvents);

  return sortedEvents;
};

/**
 * This should only be used with dates that are constructed with a date string
 */
const adjustForTimezones = (date: Date): Date => new Date(
  date.getTime() + (date.getTimezoneOffset() * 60000),
);

/**
 * Used to create the start date for the passed calendar event
 */
export const getStartDate = (event: GenericEvent): Date => adjustForTimezones(
  new Date(`${getDate(event)}T${getEventStartTime(event)}Z`),
);

/**
 * Used to create the end date for the passed calendar event
 */
export const getEndDate = (event: GenericEvent): Date => adjustForTimezones(
  new Date(`${getDate(event)}T${getEventEndTime(event)}Z`),
);

export const getDateRange = (startDate: Date, endDate: Date): Date[] => {
  const diff = differenceInDays(endDate, startDate);
  const range = [];
  for (let i = 0; i <= diff; i += 1) {
    range.push(addDays(startDate, i));
  }
  return range;
};

export const toDateString = (date: Date) => formatDate(date, CALENDAR_DATE_FORMAT_DATE_FNS);

export const getPastPresentFutureBooleans = (date: Date, today: Date) => {
  const firstDayOfMonth = startOfMonth(date);
  const firstDayOfTodaysMonth = startOfMonth(today);

  return {
    isPast: firstDayOfMonth < firstDayOfTodaysMonth,
    isPresent: firstDayOfMonth.getTime() === firstDayOfTodaysMonth.getTime(),
    isFuture: firstDayOfMonth > firstDayOfTodaysMonth,
  };
};

/**
 * Limits the number of visible months for Availability Calendar
 */
export const getMaxNextEvents = (months: CalendarMonthData[], today: Date) => {
  const futureMonths: CalendarMonthData[] = [];
  const presentMonth: CalendarMonthData[] = [];
  const pastMonths: CalendarMonthData[] = [];

  months.forEach(month => {
    const {
      isPast: isInPast,
      isPresent, isFuture,
    } = getPastPresentFutureBooleans(newTZDate(month.startDate), today);
    if (isFuture) futureMonths.push(month);
    if (isPresent) presentMonth.push(month);
    if (isInPast) pastMonths.push(month);
  });

  const maxFutureMonths = [...presentMonth, ...futureMonths].slice(0, MAX_NEXT_MONTHS + 1);
  return [...pastMonths, ...maxFutureMonths];
};

/**
 * @param start the start of the date range
 * @param end the end of the date range
 * @param length the length of date range
 * @returns an array of `Date` strings in `CALENDAR_DATE_FORMAT`
 */
export const getDatesInRange = (
  start: string | undefined,
  end: string | undefined,
  length: number,
) => {
  if (start) {
    const arr = [];
    const endDate = end ? newTZDate(end) : addDays(newTZDate(start), length);
    let dateIterator = newTZDate(start);
    while (dateIterator <= endDate) {
      arr.push(formatDate(dateIterator, CALENDAR_DATE_FORMAT_DATE_FNS));
      dateIterator = addDays(dateIterator, 1);
    }
    return arr;
  }
  return [];
};

/**
 * @param time 'HH:mm'
 * @param timeFormat a string as accepted by date-fns format
 * @returns a string in timeFormat
 */
export const getTimeString = (time: string | undefined, timeFormat: string) => {
  if (!time) return '';
  const date = new Date();
  const [hh, mm] = time.split(/[^0-9]/);
  return format(
    set(date, { hours: Number(hh), minutes: Number(mm) }),
    timeFormat,
  );
};

/**
 * @param startTime 'HH:mm'
 * @param duration in hours
 * @param timeFormat a string as accepted by date-fns format
 * @returns a string in timeFormat
 */
export const getReturnTimeString = (
  startTime: string | undefined,
  duration: number,
  timeFormat: string,
) => {
  if (!startTime) return '';
  const date = new Date();
  const [hh, mm] = startTime.split(/[^0-9]/);
  return format(
    add(
      set(date, { hours: Number(hh), minutes: Number(mm) }),
      { hours: duration },
    ),
    timeFormat,
  );
};

/**
 * @param fixedTimes [{ startTime: 'HH:mm' }]
 * @returns time string formatted as 'HH:mm:ss'
 */
export const getNextStartTime = (fixedTimes: FixedTimes) => {
  if (!fixedTimes || fixedTimes.length === 0) {
    return '08:00:00';
  }
  const date = new Date();
  const lastStartTime = fixedTimes[fixedTimes.length - 1].startTime;
  const [hh, mm] = lastStartTime.split(':');
  return format(
    add(
      set(date, { hours: Number(hh), minutes: Number(mm), seconds: 0 }),
      { minutes: 30 },
    ),
    'HH:mm:ss',
  );
};

const getDateObject = (date: string, time: string) => new Date(`${date}T${time}`);

/**
 * Sorts through the Instabook events, booked Calendar events, and Unavailable events to determine
 * if any of the trip times overlap with the blocked times.
 * @param events GenericEvent[]
 */
export const getTripTimeAvailability = (events: GenericEvent[]) => {
  const fallBack = { unavailable: false, partiallyUnavailable: false };
  if (!events || events.length === 0) return fallBack;

  const unavailableTripTimes: { minStartTime: string; maxStartTime: string }[] = [];
  const partiallyUnavailableTripTimes: { minStartTime: string; maxStartTime: string }[] = [];

  const unavailableEvents = events.filter(event => isUnavailableEvent(event));
  const instabookEvents = events.filter(event => isInstabookEvent(event));
  const calendarEvents = events.filter(event => isCalendarEvent(event));
  const unavailableAndCalendarEvents = unavailableEvents.concat(calendarEvents);

  unavailableAndCalendarEvents.forEach(unavailableOrCalEvent => {
    if (
      !isUnavailableEvent(unavailableOrCalEvent) && !isCalendarEvent(unavailableOrCalEvent)
    ) return fallBack;
    const {
      event_date: eventDate,
      event_start_time: eventStartTime,
      event_end_time: eventEndTime,
      block_type: blockType,
      event_type: eventType,
    } = unavailableOrCalEvent;

    // Return fallback for all events that are not either booked or block-time
    // or set to Remains Available
    if (
      (eventType !== UNAVAILABLE && eventType !== RESERVATION)
      || blockType === 0
    ) return fallBack;
    // Return fallback for all events that are not set by time
    if (!eventStartTime || !eventEndTime) return fallBack;

    const startTime = getDateObject(eventDate, eventStartTime);
    const endTime = getDateObject(eventDate, eventEndTime);

    instabookEvents.forEach(event => {
      if (!isInstabookEvent(event)) return fallBack;
      const { instabook_trip_times: tripTimes, trip_length: tripLengthTime } = event;

      tripTimes.forEach((tripTime) => {
        const { min_start_time: minStartTime, max_start_time: maxStartTime } = tripTime;
        const tripLength = getDateObject(eventDate, tripLengthTime);
        const minTime = getDateObject(eventDate, minStartTime);
        const maxTime = add(
          getDateObject(eventDate, maxStartTime),
          {
            hours: tripLength.getHours(),
            minutes: tripLength.getMinutes(),
            seconds: tripLength.getSeconds(),
          },
        );
        const isFixedTime = minStartTime === maxStartTime;
        const isUnavailable = (isBefore(startTime, minTime) || isEqual(startTime, minTime))
          && (isAfter(endTime, maxTime) || isEqual(endTime, maxTime));

        if (isUnavailable) {
          unavailableTripTimes.push({ minStartTime, maxStartTime });
        }
        if (!isUnavailable) {
          if (isWithinInterval(startTime, { start: minTime, end: maxTime })
            || isWithinInterval(endTime, { start: minTime, end: maxTime })) {
            if (isFixedTime) return unavailableTripTimes.push({ minStartTime, maxStartTime });
            return partiallyUnavailableTripTimes.push({ minStartTime, maxStartTime });
          }
          if (isWithinInterval(minTime, { start: startTime, end: endTime })
            || isWithinInterval(maxTime, { start: startTime, end: endTime })) {
            if (isFixedTime) return unavailableTripTimes.push({ minStartTime, maxStartTime });
            return partiallyUnavailableTripTimes.push({ minStartTime, maxStartTime });
          }
        }
        return fallBack;
      });
      return fallBack;
    });
    return fallBack;
  });

  const allTripTimes = instabookEvents
    .flatMap(event => (isInstabookEvent(event) ? event.instabook_trip_times : [])).length;

  const allTripTimesUnavailable = unavailableTripTimes.length > 0
    && unavailableTripTimes.length === allTripTimes;
  const someTripTimesUnavailable = (partiallyUnavailableTripTimes.length > 0
    && unavailableTripTimes.length !== allTripTimes)
    || (unavailableTripTimes.length > 0 && unavailableTripTimes.length !== allTripTimes);

  return {
    unavailable: allTripTimesUnavailable,
    partiallyUnavailable: someTripTimesUnavailable,
  };
};

export const determineInstabookWarnings = (
  boat: Pick<BoatDetail, 'active'>,
  date: string | Date,
  events: GenericEvent[],
  timeToBook: number,
): InstabookWarning => {
  if (boat && !boat.active) {
    return INSTABOOK_WARNINGS.UNPUBLISHED;
  }

  const tzDate = date instanceof Date ? date : newTZDate(date);
  if (isPast(tzDate)) {
    return INSTABOOK_WARNINGS.PAST;
  }

  const hoursDiff = differenceInHours(tzDate, new Date());
  if (hoursDiff < timeToBook) {
    return INSTABOOK_WARNINGS.DEPARTURE;
  }

  const isBlockedByDay = events.some(event => (
    isUnavailableEvent(event) || isCalendarEvent(event)
  ) && event.block_type === 1);

  if (isBlockedByDay) {
    return INSTABOOK_WARNINGS.UNAVAILABLE_DAY;
  }

  const { unavailable, partiallyUnavailable } = getTripTimeAvailability(events);

  if (unavailable) {
    return INSTABOOK_WARNINGS.UNAVAILABLE_TIMES;
  }

  if (partiallyUnavailable) {
    return INSTABOOK_WARNINGS.PARTIALLY_UNAVAILABLE_TIMES;
  }

  return undefined;
};

export const getFirstOfMonth = (date: string) => format(
  startOfMonth(new Date(date)),
  CALENDAR_DATE_FORMAT_DATE_FNS,
);

export const getMultiInstabookIdsForDate = (date: string, monthEvents: GenericEvent[]) => {
  const uniqueIds = new Set<number>();
  // eslint-disable-next-line no-restricted-syntax
  for (const event of monthEvents) {
    if (
      isMultiInstabookEvent(event)
      && event?.instabook_trip_dates.some((d) => d.date === date)
    ) {
      uniqueIds.add(event.id);
    }
  }
  return Array.from(uniqueIds);
};

export const serializeInstabookEvents = (monthEvents: GenericEvent[]) => {
  const mappedEvents: GenericEvent[] = [];
  monthEvents.forEach((event) => {
    if (isInstabookEvent(event) && event.instabook_trip_dates?.length > 0) {
      const tripDates = event.instabook_trip_dates;
      tripDates.forEach((tripDate) => {
        mappedEvents.push({ ...event, ...tripDate });
      });
    } else {
      mappedEvents.push(event);
    }
  });

  return mappedEvents;
};

export const calculateRepetitionEndDateFNS = (
  endDate?: string | null | undefined,
  count: number = 1,
  frequency: Frequency | null | undefined = 'daily',
): string => {
  if (!endDate) {
    return '';
  }

  switch (frequency) {
    case 'weekly':
      return format(addWeeks(newTZDate(endDate), count - 1), CALENDAR_DATE_FORMAT_DATE_FNS);
    default:
      return '';
  }
};

export const repeatEndDateToCountFNS = (
  startDate: string,
  repeatEndDate: string,
  frequency?: Frequency,
): number => {
  switch (frequency) {
    case 'weekly': {
      const tzRepeatDate = newTZDate(repeatEndDate);
      const tzStartDate = newTZDate(startDate);
      return differenceInWeeks(tzRepeatDate, tzStartDate) + 1;
    }
    default:
      return 1;
  }
};

export const maxDate = (a?: string, b?: string): string | undefined => {
  if (!a || (b && a < b)) {
    return b || undefined;
  }
  return a;
};

export const calculateDurationFNS = (
  endDate: string,
  startDate: string,
): number => Math.abs(differenceInDays(newTZDate(endDate), newTZDate(startDate))) + 1;
