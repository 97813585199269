import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { useField } from 'react-final-form';
import Modal from '../../../common/components/Modal';
import Svg from '../../../common/components/Svg';
import { INSTABOOK_EDITOR_DATE_FIELD, INSTABOOK_TRIP_DATE_MODAL_NAME as modalName } from '../../constants';
import InstabookEditorHeader from '../InstabookEditorHeader';
import InstabookHeaderInfo from '../InstabookHeaderInfo';

import s from './InstabookDatePickerWrapper.module.scss';

type InstabookDatePickerWrapperProps = {
  children: ReactNode;
  datePickerWrapperLabel?: string;
  selectedDays?: Date[] | [];
};

const InstabookDatePickerWrapper: FC<InstabookDatePickerWrapperProps> = ({
  children,
  datePickerWrapperLabel = 'Select Date',
  selectedDays,
}) => {
  const { input: { onBlur } } = useField(INSTABOOK_EDITOR_DATE_FIELD);
  return (
    <Modal
      disableOverlayClick
      label={datePickerWrapperLabel}
      name={modalName}
      classNameModifier={classNames('calendar', 'instabook', 'tripDate')}
      closeModal={onBlur}
      shouldReturnFocusAfterClose={false}
      noExit
    >
      <InstabookEditorHeader
        icon={<Svg className={s.icon} href="#icon-calendar" />}
        title={datePickerWrapperLabel}
        classNameModifier="tripDate"
      >
        <InstabookHeaderInfo isDatePickerEditorOpen selectedDays={selectedDays} />
      </InstabookEditorHeader>
      {children}
    </Modal>
  );
};

export default InstabookDatePickerWrapper;
