export const list: { [key: string]: string[] } = {
  1000: ['14', '21', '58', '59'],
  1001: ['10', '22', '25', '27', '28', '29', '31', '33', '34', '53', '54', '74', '78'],
  1002: ['18'],
  1003: ['24'],
  1004: ['26'],
  1005: ['23'],
  1006: ['32'],
  1007: ['19', '75', '79'],
  1008: ['13', '53', '54', '55', '57'],
  1009: ['20', '29'],
  1010: ['69', '71'],
  1011: ['11', '12', '28', '41', '42', '44', '45', '46', '47', '49', '50', '51', '52', '58'],
  1012: ['42', '43'],
  1013: [
    '17',
    '29',
    '52',
    '79',
    '80',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '90',
  ],
  1014: ['70', '71', '72', '73', '74', '75', '77'],
  1015: ['60', '65'],
  1016: [
    '11',
    '15',
    '35',
    '36',
    '37',
    '39',
    '48',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '73',
    '76',
  ],
  1017: ['30', '78'],
} as const;

const mapCategoriesToGroupIds = (categories: string[]): string[] => Array.from(
  new Set(categories.flatMap((groupCatId) => list[groupCatId])),
);

export default mapCategoriesToGroupIds;
