import React, { FC, ReactNode } from 'react';

import { getClassNameFor } from '../../helpers';
import UniversalLink from '../UniversalLink';
import s from './Navigation.module.scss';
import IconDS22, { IconIds } from '../IconDS22';

type Props = {
  children: ReactNode;
  classNameModifier?: string;
  icon?: string | boolean;
  openInNewTab?: boolean;
  to: string;
};

const NavItem: FC<Props> = ({
  children,
  classNameModifier = '',
  icon,
  openInNewTab,
  to,
}) => (
  <li className={getClassNameFor(s, 'item', classNameModifier)}>
    <UniversalLink
      to={to}
      openInNewTab={openInNewTab}
      className={getClassNameFor(s, 'link', classNameModifier)}
      activeClassName={s.link_active}
    >
      {icon && (
        <IconDS22
          id={icon as IconIds}
          size="l"
          className={getClassNameFor(s, 'iconDS22', classNameModifier)}
        />
      )}
      <span className={getClassNameFor(s, 'children', classNameModifier)}>{children}</span>
    </UniversalLink>
  </li>
);

export default NavItem;
