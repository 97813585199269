import React, { FC, useRef, useState } from 'react';
import { HotKeys } from 'react-hotkeys';
import classNames from 'classnames';
import Icon from 'src/common/components/IconDS22';
import { getClassNameFor } from 'src/common/helpers';
import ToggleButton from 'src/common/components/ToggleButton';
import Dropdown from 'src/common/components/Dropdown';
import s from './RepetitionFrequencySelector.module.scss';

const VISIBILITY_THRESHOLD = 200;

const options = [
  {
    title: 'Do not repeat',
    action: null,
  },
  {
    title: 'Repeat every week',
    sortTitle: 'Every week',
    action: 'weekly',
  },
];

type Props = {
  onChange: (value: string) => void;
  value?: string;
  disabled?: boolean;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
};
const RepetitionFrequencySelector:FC<Props> = ({
  value = null,
  onChange,
  disabled = false,
  formRef,
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isDropdownBelowViewport, setIsDropdownBelowViewport] = useState(false);
  options[1].sortTitle = 'Repeat every week';

  const activeOption = options.find(option => option.action === value) || options[0];

  const setDropdown = () => {
    const formBottom = formRef.current?.getBoundingClientRect().bottom;
    const dropdownBottom = dropdownRef.current?.getBoundingClientRect().bottom;
    if (formBottom && dropdownBottom) {
      if (formBottom - dropdownBottom < VISIBILITY_THRESHOLD) {
        setIsDropdownBelowViewport(true);
      } else {
        setIsDropdownBelowViewport(false);
      }
    }
  };

  const handleToggle = (toggle: () => void) => {
    setDropdown();
    toggle();
  };
  return (
    <Dropdown className={s.root}>
      {({ toggle, isOpen, close }) => (
        <div className={s.menu} ref={dropdownRef}>
          <HotKeys
            handlers={{ toggle: event => { event?.preventDefault(); toggle(); } }}
            keyMap={{ toggle: 'space' }}
          >
            <ToggleButton
              isOpen={isOpen}
              onClick={() => handleToggle(toggle)}
              classNameModifier="haToggleButton"
              disabled={disabled}
            >
              <Icon id="arrow-forward" size="l" className={s.icon} />
              {activeOption.sortTitle || activeOption.title}
            </ToggleButton>
          </HotKeys>
          {isOpen && (
            <ul className={getClassNameFor(s, 'content', classNames({ isDropdownBelowViewport }))}>
              {options.map(option => (
                <li
                  key={option.action}
                  className={getClassNameFor(s, 'item', `${option.action === activeOption.action && 'active'}`)}
                >
                  <button
                    className={s.button}
                    type="button"
                    onClick={() => {
                      onChange(option.action || '');
                      close();
                    }}
                  >
                    {option.title}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </Dropdown>
  );
};

export default RepetitionFrequencySelector;
