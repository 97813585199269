import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Amounts } from 'src/types/common/Amounts';
import Avatar from 'src/common/components/Avatar';
import { getTransaction } from 'src/common/utils/reduxStoreSelectors';
import { getClassNameFor } from '../../../common/helpers';
import { OWNER_OFFER_STATES, TRIP_STATES } from '../../constants';
import { userIsRenter } from '../../helpers';
import useCustomOfferExpiry from '../../../common/hooks/useCustomOfferExpiry';
import CustomOfferExpiry from '../../../common/components/CustomOfferExpiry';
import { ImmutableTrip } from '../../../types/trips/Trips';
import MainTripDetails from '../MainTripDetails';
import OfferSummary from '../OfferSummary';
import TermsAndCancellation from '../TermsAndCancellation';
import Vessel from '../Vessel';

import s from './TripDetails.module.scss';
import CostBreakdown from '../CostBreakdown';

type TripDetailsProps = {
  renderComments?: boolean;
  trip: ImmutableTrip;
  withTooltips?: boolean;
};

const TripDetails: FC<TripDetailsProps> = ({
  renderComments = true,
  trip,
  withTooltips = true,
}) => {
  const transaction = useSelector(getTransaction);
  const { pathname } = useLocation();
  const page = pathname?.split('/')[1];
  const tripState = trip.getIn(['state', 'state']);
  const boat = trip.get('boat');
  const url = trip.getIn(['boat', 'listing_url']);
  // This normalization is necessary because `None` is erroneously returned
  // from the backend and should not be displayed as a valid comment to the user.
  const renterComment = trip.get('renter_comment') !== 'None' ? trip.get('renter_comment') : '';
  const ownerComment = trip.get('owner_comment') !== 'None' ? trip.get('owner_comment') : '';

  const isRenter = userIsRenter(trip);
  const amounts: Amounts = transaction?.get('amounts')?.toJS()
    || trip?.getIn(['transaction', 'amounts'])?.toJS();

  const currency = amounts?.[isRenter ? 'renter_currency' : 'owner_currency'];
  const deposit = amounts?.[isRenter ? 'booking_deposit' : 'listing_deposit'];
  const cancellationPolicy = amounts?.cancellation_policy;

  const { renderCustomExpiry } = useCustomOfferExpiry(trip);

  const shouldRenderPrice = (
    ![TRIP_STATES.OWNER_CHANGES_REQUESTED, TRIP_STATES.OWNER_OFFER_CANCELLED].includes(tripState)
    && !transaction.isEmpty()
  );
  const isInstabook = trip.get('is_instabook');

  const instabookRenterOccasion = isInstabook && renterComment?.split('<br/>')[0];
  const instabookRenterMessage = isInstabook && renterComment?.split('<br/>')[1];

  const theme = page === 'calendar'
    ? 'dark'
    : 'lightBlue';

  return (
    <ul className={s.root}>
      {shouldRenderPrice && OWNER_OFFER_STATES.includes(tripState) && (
        <li className={s.rootItem}>
          <OfferSummary
            amounts={amounts}
            theme="dark"
          />
        </li>
      )}
      {shouldRenderPrice && amounts?.price_sections?.map((priceSection) => (
        <li
          key={priceSection.id}
          className={s.rootItem}
        >
          <CostBreakdown
            open={false}
            priceSection={priceSection}
            theme="light"
          />
        </li>
      ))}
      <li className={getClassNameFor(s, 'rootItem', 'outlined')}>
        <Vessel
          trip={trip}
          vessel={boat}
          classNameModifier="wide"
        />
      </li>
      <li className={s.rootItem}>
        <MainTripDetails trip={trip} />
      </li>
      {renderComments && renterComment && (
        <li className={getClassNameFor(s, 'rootItem', 'comment outlined')}>
          <h6 className={s.author}>
            <Avatar
              classNameModifier="noMargin"
              size="tiny"
              user={trip.get('renter').toJS()}
            />
            {trip.getIn(['renter', 'first_name'])}
          </h6>
          {isInstabook ? (
            <>
              {instabookRenterOccasion && (
                <p className={s.tripDescription}>
                  {instabookRenterOccasion}
                </p>
              )}
              {instabookRenterMessage && (
                <p className={s.tripDescription}>
                  {instabookRenterMessage}
                </p>
              )}
            </>
          ) : (
            <p className={s.tripDescription}>
              {renterComment}
            </p>
          )}
        </li>
      )}
      {renderComments && ownerComment && (
        <li className={getClassNameFor(s, 'rootItem', 'comment outlined')}>
          {isInstabook ? (
            <h4 className={s.title}>
              Trip Description
            </h4>
          ) : (
            <h6 className={s.author}>
              <Avatar
                classNameModifier="noMargin"
                size="tiny"
                user={trip.get('owner').toJS()}
              />
              {trip.getIn(['owner', 'first_name'])}
            </h6>
          )}
          <p className={s.tripDescription}>
            {ownerComment}
          </p>
        </li>
      )}
      {renderCustomExpiry && (
        <li className={getClassNameFor(s, 'rootItem', 'customExpiry')}>
          <CustomOfferExpiry
            trip={trip}
          />
        </li>
      )}
      {shouldRenderPrice && (
        <li className={s.rootItem}>
          <TermsAndCancellation
            currency={currency}
            deposit={deposit}
            listingUrl={url}
            cancellationPolicy={cancellationPolicy}
            withTooltips={withTooltips}
            theme={theme}
          />
        </li>
      )}
    </ul>
  );
};

export default TripDetails;
