import React, { FC } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../../common/helpers';
import Dropdown from '../../../common/components/Dropdown';
import FilterContainer from '../../../common/components/FilterContainer';
import ToggleButton from '../../../common/components/ToggleButton';
import { useCalendarContext } from '../../hooks';
import MiniCal from '../MiniCal';

import s from './CalendarDropdown.module.scss';

type CalendarDropdownProps = {
  classNameModifier?: string;
};

const CalendarDropdown: FC<CalendarDropdownProps> = ({
  classNameModifier = '',
}) => {
  const { dropdownMonth, dropdownMonthRef } = useCalendarContext();
  return (
    <Dropdown className={getClassNameFor(s, 'root', classNameModifier)}>
      {({ isOpen, toggle }) => (
        <>
          <ToggleButton
            isOpen={false}
            classNameModifier="calendarDropdown"
            onClick={toggle}
          >
            <span
              className={s.monthTitle}
              ref={dropdownMonthRef}
              // This uses dangerouslySetInnerHTML because this node's children are manipulated
              // directly so that we are able to achieve very fast updates while scrolling without
              // any state changes that cause the rest of the calendar to rerender. This informs
              // react that it doesn't manage the contents of this node.
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: dropdownMonth }}
            />
          </ToggleButton>
          {isOpen && (
            <FilterContainer classNameModifier={classNames('calendarDropdown', classNameModifier)}>
              <MiniCal
                onSelectedDateChange={toggle}
                isDropDown
              />
            </FilterContainer>
          )}
        </>
      )}
    </Dropdown>
  );
};

export default CalendarDropdown;
