import { RecentSearch } from '../components/RecentSearches/RecentSearch';

export type RecentSearchCookie = Pick<RecentSearch, 'capacity' | 'captained' | 'dates' | 'max_price' | 'min_price' | 'duration'>;
export type RecentInquiryData = Pick<RecentSearch, 'capacity' | 'dates' | 'duration'> & {
  captained?: boolean;
  captainOptionValue?: number;
  bareboat?: boolean;
  maxPrice?: number;
  minPrice?: number;
};

export const RECENT_INQUIRY_VERSION = 'v3';

// Convert multiday times from `P{days}D` to `PT{hours}H`
const convertToRecentDuration = (duration: string | null): string => {
  if (!duration) return '';
  // Don't convert hourly times
  if (duration.endsWith('H')) return duration;

  // invalid format
  if (!duration.endsWith('D')) return '';

  const digits = duration.replace(/[^\d]/g, '');
  const days = parseInt(digits, 10) * 24;
  return `PT${days}H`;
};

/**
  Set RECENT_SEARCH_COOKIE in following pattern in specific order:
  - version;
  - capacity;
  - dates;
  - captained;
  - min_price;
  - max_price;

  The cookie is comma-separated. Values are position base so any values
  not included will be kept as empty like a sparse array i.e. an
  empty cookie would look like: `,,,,,,`

  e.g.
    - version: v1
    - capacity: 3
    - dates: ['2024-11-10','2024-11-11','2024-11-12']
    - captained: true
    - duration: PT2H (2 hours)
    - min_price: 10
    - max_price: 999
    - => v1,3,2024-11-10,2,1,10,999

  e.g.
    - version: v1
    - capacity: 3
    - dates: ['2024-11-10']
    - captained: false
    - duration: ''
    - min_price: 10
    - max_price: null
    - => v1,3,2024-11-10,,,10,
*/
export const constructRecentSearchCookie = ({
  capacity,
  dates,
  captained,
  captainOptionValue,
  duration,
  minPrice,
  maxPrice,
}: RecentInquiryData): string => {
  const datesSearch = dates.length - 1 || '';
  const captainedSearch = captained ? '1' : '0';
  const durationSearch = convertToRecentDuration(duration);
  const captainOptionValueSearch = `${captainOptionValue}` || '';

  return `${RECENT_INQUIRY_VERSION},${capacity ?? ''},${dates[0] ?? ''},${datesSearch},${captainedSearch},${captainOptionValueSearch},${durationSearch},${minPrice ?? ''},${maxPrice ?? ''}`;
};
