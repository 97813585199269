import { createSelector } from 'reselect';
import moment from 'moment';

import { TRIP_LENGTH_FIELD } from '../../../constants';
import { offerGetters } from '../../../selectors';

// Duration stuff
const durationFields = [TRIP_LENGTH_FIELD];
const offerDurationGetters = offerGetters(durationFields);

const calculateTripDays = (
  tripLengthString: string,
) => {
  const tripLengthMoment = moment.duration(tripLengthString);
  const isMultiDay = tripLengthMoment.asDays() >= 1;

  return {
    tripLengthMoment,
    isMultiDay,
  };
};

const offerDurationSelector = createSelector(
  offerDurationGetters,
  calculateTripDays,
);

export default offerDurationSelector;
