import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { HotKeys } from 'react-hotkeys';
import debounce from 'lodash/debounce';

import { trackEvent } from 'src/common/tracking';
import { rffSubmitResponse, scrollPageToBottom } from '../../../common/helpers';
import FormError from '../../../common/components/FormError';
import TextArea from '../../../common/components/inputs/TextArea';
import Button from '../../../common/components/Button';
import { postMessageCall } from '../../ducks/messages';
import { usePubSubContext, TYPING_TIMEOUT } from '../../../pubsub/hook/usePubsub';
import s from './SendMessageForm.module.scss';
import { rffValidators } from '../../../common/validation';

const validateTextArea = rffValidators.maxSize({ size: 8192, message: 'The message is too long. Try shortening it or sending it in multiple parts' });

type SendMessageFormProps = {
  children: React.ReactNode;
  disabled: boolean;
  placeholder: string;
  tripId: number;
  rootRef: (node: HTMLDivElement) => void;
};

const SendMessageForm: React.FC<SendMessageFormProps> = ({
  children,
  disabled,
  placeholder,
  tripId,
  rootRef,
}) => {
  const dispatch = useDispatch();
  const { postThreadTyping } = usePubSubContext();

  const onKeyPress = useMemo(() => debounce(
    () => {
      postThreadTyping(`${tripId}`);
    },
    TYPING_TIMEOUT,
    {
      leading: true,
      maxWait: TYPING_TIMEOUT,
      trailing: false,
    },
  ), [postThreadTyping, tripId]);

  return (
    <div
      className={s.root}
      ref={rootRef}
    >
      {children && (
        <div className={s.innerWrapper}>
          {children}
        </div>
      )}
      <Form
        onSubmit={async (values, { getState }) => {
          if (!getState().dirty) {
            return undefined;
          }
          try {
            // TODO: see if this has the same problems with uncaught errors as before
            await dispatch(postMessageCall(tripId, { ...values, communications: ['email'] }));

            trackEvent('Sent', { event_category: 'Message' });
          } catch (response) {
            return rffSubmitResponse()(response);
          }

          return undefined;
        }}
      >
        {({
          handleSubmit,
          submitting,
          submitError,
          hasValidationErrors,
          form: { reset },
        }) => {
          const submitHandler = (...args) => handleSubmit(...args).then(returnValue => {
            // This is the equivalent of an `onSubmitSuccess` when you need
            // to modify the form directly. Otherwise it throws an error at you saying
            // "cannot reset() in onSubmit()." This is the first dumb thing i've found
            // about react-final-form.
            if (!returnValue) {
              // We check to make sure the value isn't defined because form errors
              // are going to be returned by handleSubmit, and if we call reset() in
              // that case, it's going to get rid of the error.
              reset();
            }
          });

          return (
            <form
              onSubmit={submitHandler}
            >
              <FormError error={submitError} />
              <HotKeys
                className={s.innerWrapper}
                handlers={{ submit: submitHandler }}
                keyMap={{ submit: ['ctrl+enter', 'meta+enter'] }}
                tabIndex={undefined}
              >
                <Field
                  name="content"
                  validate={validateTextArea}
                >
                  {({ input, meta }) => (
                    <TextArea
                      autoResize
                      input={{
                        // onInput is used because android doesn't trigger onKeyPress for
                        // on screen keyboards. onChange doesn't work with the autoResize
                        // prop.
                        onInput: onKeyPress,
                        ...input,
                        // setTimeout is needed to reveal textarea after
                        // appearing/disappearing of the virtual keyboard
                        onBlur: () => setTimeout(scrollPageToBottom, 200),
                        onFocus: () => setTimeout(scrollPageToBottom, 100),
                      }}
                      disabled={disabled}
                      placeholder={placeholder}
                      meta={meta}
                      classNameModifier="message"
                    />
                  )}
                </Field>
                <Button
                  type="submit"
                  submitting={submitting}
                  classNameModifier="trip message"
                  disabled={disabled || hasValidationErrors}
                >
                  Send
                </Button>
              </HotKeys>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default SendMessageForm;
