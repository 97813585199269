import { List, Map } from 'immutable';
import { parse, stringify } from 'query-string';

import { PATHS, EXTERNAL_PATHS, LOCALE_PREFIXES } from '../constants';
import captureException from './captureException';
// Action can be push or replace history methods
export const onSubmitLocationChange = (location, action) => (
  () => { action(location); }
);

/**
 * @param {string} path
 * @returns {List<string>}
 */
export const explodeRoute = path => List(path.split('/'))
  // Get rid of empty route bits
  .filter(x => x.length !== 0)
  // re-add the first empty bit (since we want a leading slash)
  .unshift('');

/**
 * @param {List<string>} pathParts
 * @returns {string}
 */
const assembleRoute = pathParts => pathParts.toJS()
  .join('/')
  // Because we like trailing slashes
  .concat('/');

// TODO: Find something that's like the JS equivalent of Python's os.path
// and use it instead of this.
/**
 * @param {string} path
 * @param {string} newSuffix
 * @returns {string}
 */
export const siblingRoute = (path, newSuffix) => assembleRoute(explodeRoute(path)
  .pop()
  .push(newSuffix));

/**
 * @param {string} path
 * @param {string} newSuffix
 * @returns {string}
 */
export const appendRoute = (path, newSuffix) => (newSuffix
  ? assembleRoute(explodeRoute(path).push(newSuffix))
  : path
);

/**
 * @param {string} path
 * @returns {string}
 */
export const parentRoute = path => assembleRoute(explodeRoute(path)
  .pop());

/**
 * @param {string} pathname
 * @param {{search: string}} location
 * @returns
 */
export const preserveSearch = (pathname, location) => location && {
  search: location.search,
  pathname,
};

/**
 * @param {string} hostname
 * @returns {string}
 */
export const extractTld = hostname => hostname.split('getmyboat.').pop();

/**
 * Consumes the `next` querystring and constructs a new url
 * @param {string} search
 * @param {string} [hash]
 * @returns {string}
 */
export const nextUrl = (search, hash) => {
  const { next, ...restQuery } = parse(search);
  const path = hash ? next + hash : next;
  const newSearch = stringify(restQuery);
  return newSearch ? `${path}?${newSearch}` : path;
};

/**
 * Validates that the given uri is an internal path that should be interacted with via the router
 * @param {string} uri
 * @returns {boolean}
 */
export const isReactPath = uri => Map(PATHS).some(p => uri.startsWith(p));

/**
 * Validates that the given uri is a known external path that may be redirected to.
 */
export const isExternalPath = (uri: string): boolean => {
  const [path] = uri.split('?');
  const nonLocalePathPrefixes = Object.values(EXTERNAL_PATHS);

  // Check non-locale path prefixes
  if (nonLocalePathPrefixes.some(prefix => path.startsWith(prefix))) {
    return true;
  }

  // Check locale home page for exact matches.
  // Exact matching is required to prevent global locale prefix matching.
  if (LOCALE_PREFIXES.some(prefix => path === `${prefix}/`)) {
    return true;
  }

  // Check locale prefixed routes.
  // This is deferred until the end due to limited locale adoption.
  return LOCALE_PREFIXES.some(
    locale => nonLocalePathPrefixes.some(route => path.startsWith(`${locale}${route}`)),
  );
};

/**
 * Predicated for identifying allowed domains that can be redirected to
 */
const isAllowedRedirectDomain = (url: string): boolean => (
  // Full domain with trailing slash is required.
  url.startsWith('https://checkout.stripe.com/')
);

/**
 * Validates redirects are either:
 * - Root relative paths starting with a single `/`
 * - A fully qualified url to an allowed domain
 */
export const validateRedirect = (path: string): string => {
  if (!path) {
    return '/';
  }

  if (isAllowedRedirectDomain(path)) {
    return path;
  }

  const [firstChar, secondChar] = path;
  if (!(firstChar === '/' && secondChar !== '/')) {
    return '/';
  }
  return path;
};

/**
 * Redirects to the provided path if the path is in the allowed list of destinations.
 */
export const authRedirect = (path: string, push: (path: string) => void): void => {
  const target = validateRedirect(path);

  if (isReactPath(target)) {
    push(target);
    return;
  }

  if (isExternalPath(target) || isAllowedRedirectDomain(target)) {
    window.location.assign(target);
    return;
  }

  if (target && target !== '/') {
    // Report any url redirects that have not been processed, as these are likely suspicious.
    captureException(new Error('Invalid auth redirect'), {
      extra: {
        target,
      },
    });
  }

  // Default to the home page.
  window.location.assign('/');
};

/**
 * @param {string} url
 * @returns {boolean}
 */
export const isAbsoluteUrl = url => url.startsWith('http') || url.startsWith('//');

export const homepageRedirect = () => {
  if (typeof window !== 'undefined') {
    window.location.assign('/');
  }
};
