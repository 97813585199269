import PropTypes from 'prop-types';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { getClassNameFor, isMobile } from '../../../helpers';
import FieldWrapper from '../FieldWrapper';
import FormError from '../../FormError';
import s from './TextArea.module.scss';

const TextArea = ({
  id,
  input,
  placeholder,
  classNameModifier,
  disabled,
  autoFocus,
  autoResize,
  meta: { error },
  textareaRef,
  renderErrorMessage = true,
}) => {
  const Component = autoResize ? TextareaAutosize : 'textarea';
  // This is because React gets grumpy if you put useCacheForDomMeasurements
  // on a normal textarea.
  const componentProps = autoResize ? { cacheMeasurements: true } : {};
  const modifiers = [
    classNameModifier,
    error ? 'error' : '',
  ].join(' ');

  return (
    <FieldWrapper
      id={id || input.name}
      // TODO: figure out if this is a sane way of composing styles
      rootClassName={getClassNameFor(s, 'root', modifiers)}
    >
      <Component
        id={id || input.name}
        placeholder={placeholder}
        // TODO: this should probably come from form state,
        // not be a separate prop. What's the use case again?
        disabled={disabled}
        autoFocus={!isMobile() && autoFocus}
        className={getClassNameFor(s, 'field', modifiers)}
        // TODO: figure out what this does, or if it does anything
        ref={textareaRef}
        {...componentProps}
        {...input}
      />
      {renderErrorMessage && error && (
        <FormError error={error} />
      )}
    </FieldWrapper>
  );
};

TextArea.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  classNameModifier: PropTypes.string,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  label: PropTypes.node,
  id: PropTypes.string,
  autoResize: PropTypes.bool,
  fieldRef: PropTypes.object,
  textareaRef: PropTypes.object,
  renderErrorMessage: PropTypes.bool,
};

export default TextArea;
