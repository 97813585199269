import { useState, useEffect } from 'react';

export const sharedMediaQueries = {
  xs: 'only screen and (min-width: 375px)',
  s: 'only screen and (min-width: 414px)',
  m: 'only screen and (min-width: 768px)',
  l: 'only screen and (min-width: 1024px)',
  xl: 'only screen and (min-width: 1280px)',
  xxl: 'only screen and (min-width: 1440px)',
  hasPointer: '(hover: hover)',
  prefersReducedMotion: '(prefers-reduced-motion)',
};

/**
 * Simple stateful wrapper around `window.matchMedia`.
 * Returns `true` when the media query is matched
 * @param {string} mediaQueryString The media query string to check. Example '(min-width: 1024px)'
 */
const useMediaQuery = mediaQueryString => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return () => { };
    }

    const media = window.matchMedia(mediaQueryString);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    if (typeof media.addEventListener === 'function') {
      media.addEventListener('change', listener);
    } else {
      // This is required on Safari 13 and lower
      media.addListener(listener);
    }
    return () => {
      if (typeof media.removeEventListener === 'function') {
        media.removeEventListener('change', listener);
      } else {
        // This is required on Safari 13 and lower
        media.removeListener(listener);
      }
    };
  }, [matches, mediaQueryString]);

  return matches;
};

export default useMediaQuery;
