import React, { useState, useMemo, ReactNode, FC, useCallback } from 'react';
import classNames from 'classnames';
import isAfter from 'date-fns/isAfter';
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';
import format from 'date-fns/format';

import { getClassNameFor } from '../../../common/helpers';
import { todayDate } from '../../helpers';
import s from './SimpleCalendar.module.scss';

const getCalendarMonthDays = (monthDate: Date) => {
  const today = todayDate();
  const firstOfMonth = startOfMonth(monthDate);
  const lastOfMonth = endOfMonth(monthDate);
  const emptyDaysOnFirstRow = firstOfMonth.getDay();

  const days: [number, Date | null][] = [];
  if (emptyDaysOnFirstRow) {
    for (let i = 0; i < emptyDaysOnFirstRow; i += 1) {
      days.push([0, null]);
    }
  }

  const diff = differenceInDays(lastOfMonth, firstOfMonth);
  for (let i = 0; i <= diff; i += 1) {
    days.push([i + 1, addDays(firstOfMonth, i)]);
  }

  if (days.length > 28) {
    while (days.length < 35) {
      days.push([0, null]);
    }
  }
  if (days.length > 35) {
    while (days.length < 42) {
      days.push([0, null]);
    }
  }
  const weeks: [number, Date | null][][] = [];
  const numberOfWeeks = Math.ceil(days.length / 7);
  if (numberOfWeeks >= 4 && numberOfWeeks <= 6) {
    for (let i = 0; i < numberOfWeeks; i += 1) {
      weeks.push(days.slice(i * 7, (i + 1) * 7));
    }
  }
  return { weeks, today };
};

type SimpleCalendarProps = {
  openToDate: Date;
  selected: Date | null;
  minDate: Date;
  maxDate: Date;
  renderDayContents: (
    day: number, date: Date, isPastDay: boolean, selected: boolean, isToday: boolean
  ) => ReactNode;
  onMonthChange: (date: Date) => void;
  onChange: (date: Date) => void;
};

const SimpleCalendar: FC<SimpleCalendarProps> = ({
  openToDate,
  selected,
  minDate,
  maxDate,
  renderDayContents,
  onMonthChange,
  onChange,
}) => {
  const [activeMonth, setActiveMonth] = useState(openToDate);
  const canGoBack = useMemo(() => minDate < activeMonth, [minDate, activeMonth]);
  const canGoForward = useMemo(() => maxDate > activeMonth, [maxDate, activeMonth]);
  const prev = () => {
    if (canGoBack) {
      const previousMonth = subMonths(activeMonth, 1);
      setActiveMonth(previousMonth);
      onMonthChange(previousMonth);
    }
  };
  const next = () => {
    if (canGoForward) {
      const nextMonth = addMonths(activeMonth, 1);
      setActiveMonth(nextMonth);
      onMonthChange(nextMonth);
    }
  };

  const { weeks, today } = useMemo(() => getCalendarMonthDays(activeMonth), [activeMonth]);
  const isDayBlocked = useCallback(
    (day: null | Date) => !!(day && isAfter(day, maxDate)),
    [maxDate],
  );

  return (
    <div className={s.root}>
      <div className={s.header}>
        <button
          type="button"
          onClick={prev}
          aria-disabled={!canGoBack}
          className={getClassNameFor(
            s,
            'navigation',
            classNames('previous', { disabled: !canGoBack }),
          )}
          data-test="SimpleCalendar"
        >
          <span className={s.label}>Previous</span>
        </button>
        <span className={s.caption}>{format(activeMonth, 'MMMM yyyy')}</span>
        <button
          type="button"
          onClick={next}
          aria-disabled={!canGoForward}
          className={getClassNameFor(
            s,
            'navigation',
            classNames('next', { disabled: !canGoForward }),
          )}
        >
          <span className={s.label}>Next</span>
        </button>
      </div>
      <table>
        <thead>
          <tr>
            {weeks[1].map(([, date], index) => (
              <th
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={s.dayName}
                scope="col"
              >
                <div>{date && format(date, 'EEEEEE')}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {weeks.map((week, weekIndex) => (
            <tr
              // eslint-disable-next-line react/no-array-index-key
              key={`row-${weekIndex}`}
            >
              {week.map(([day, date], dayIndex) => {
                const dayIsBlocked = isDayBlocked(date);
                const isPastDay = Boolean(date && date.getTime() < today.getTime());
                const isSelected = Boolean(date && selected?.getTime() === date.getTime());
                const isToday = Boolean(date && date.getTime() === today.getTime());

                return (
                  <td
                    // eslint-disable-next-line react/no-array-index-key
                    key={dayIndex}
                    data-test="CalendarDayButton"
                    className={classNames({
                      [s.outOfRange]: !date,
                      [s.selected]: isSelected,
                      [s.disabled]: dayIsBlocked,
                    })}
                  >
                    {date && (
                      <button
                        type="button"
                        className={getClassNameFor(s, 'day', classNames({ isSelected }))}
                        disabled={dayIsBlocked}
                        onClick={() => onChange(date)}
                      >
                        {renderDayContents(day, date, isPastDay, isSelected, isToday)}
                      </button>
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SimpleCalendar;
