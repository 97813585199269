import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Button from 'src/common/components/Button';
import { PATHS } from '../../../../common/constants';
import SupportEmail from '../../../../common/components/SupportEmail';
import s from './ForgotPasswordSubmitted.module.scss';

const title = 'Check your inbox!';
const ForgotPasswordSubmitted: FC = () => (
  <div className={s.container}>
    <Helmet>
      <title lang="en">{title}</title>
    </Helmet>

    <div className={s.intro}>
      <h1 className={s.title}>{title}</h1>
      <div className={s.message}>
        <p>
          We’ve sent an email to that address if it belongs to a registered account.
          Please check your email inbox to reset your password.
        </p>
        <p>
          {'Need Help? '}
          <SupportEmail
            className={s.link}
          />
        </p>
      </div>
    </div>

    <div className={s.actionGroup}>
      <Button
        href="/"
        fullWidth
      >
        Done
      </Button>
      <div className={s.account}>
        {'In the wrong place? '}
        <Link
          to={PATHS.REGISTER}
          className={s.link}
        >
          Create Account
        </Link>
        {' or '}
        <Link
          to={PATHS.LOGIN}
          className={s.link}
        >
          Sign In.
        </Link>
      </div>
    </div>
  </div>
);

export default ForgotPasswordSubmitted;
