// eslint-disable-next-line import/prefer-default-export
export const FIELD_NAMES = {
  trip_protection: 'trip_protection',
  liability_coverage: 'liability_coverage',
  address1: 'address1',
  address2: 'address2',
  city: 'city',
  zip_code: 'zip_code',
  state: 'state',
  save_address: 'save_address',
} as const;
