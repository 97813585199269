import classNames from 'classnames';
import Downshift from 'downshift';
import { debounce } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Icon from 'src/common/components/IconDS22';
import SearchBarList from 'src/common/components/SearchBar/SearchBarList';
import { filterPlaces, searchByPlaceId, type SearchByPlaceIdPayload } from 'src/common/components/SearchBar/searchUtils';
import type { RecentSearch, RetrievedRecentSearch } from 'src/common/components/RecentSearches/RecentSearch';
import mapCategoriesToGroupIds from 'src/common/utils/mapCategoriesToGroupIds';
import { format, parseISO } from 'date-fns';
import { CALENDAR_DATE_FORMAT_DATE_FNS } from 'src/calendar/constants';
import { trackEvent } from 'src/common/tracking';
import { ReduxState } from '../../../types/reduxState';
import { EXTERNAL_PATHS, NEAR_ME } from '../../constants';
import { decorateComponent, getClassNameFor } from '../../helpers';
import { trackEventFacebook } from '../../tracking/facebook';
import {
  AutocompletePrediction,
  AutocompleteService,
  AutocompleteSessionToken,
} from '../../types/helpers/googleMapHelpers';
import autocompleteProvider from '../../utils/autocompleteProvider';
import { withErrorBoundary } from '../ErrorBoundary';

import s from './SearchBar.module.scss';

const defaultPlaces = [
  {
    description: 'Near Me',
    place_id: NEAR_ME,
    types: [],
  },
];

type SearchBarProps = {
  getAutocompleteService?: () => Promise<{
    autocompleteService: AutocompleteService;
    sessionToken: AutocompleteSessionToken;
  }>;
};

const SearchBar: FC<SearchBarProps> = ({ getAutocompleteService }) => {
  const isOwner = useSelector<ReduxState, boolean | undefined>(
    ({ user }) => user.get('is_owner', false),
  );
  const currencyCode = useSelector<ReduxState, string | undefined>(
    ({ requestCurrency }) => requestCurrency.code,
  );

  const [queryTerm, setQueryTerm] = useState('');
  const [places, setPlaces] = useState<AutocompletePrediction[]>(defaultPlaces);

  const inputRef = useRef<HTMLInputElement>(null);

  const placeholder = 'Where to?';

  const clear = useCallback(() => {
    setQueryTerm('');
    setPlaces(defaultPlaces);

    // focus loss from clicking button
    inputRef.current?.focus();
  }, []);

  const queryPlaces = useCallback(
    (value: string) => {
      if (value) {
        getAutocompleteService?.().then(({ autocompleteService, sessionToken }) => {
          autocompleteService.getPlacePredictions(
            {
              input: value,
              sessionToken,
            },
            (predictions, status) => {
              if (status !== google.maps.places.PlacesServiceStatus.OK) {
                setPlaces(defaultPlaces);
                return;
              }
              const allPlaces: AutocompletePrediction[] = [...filterPlaces(predictions || [])];
              setPlaces(allPlaces);
            },
          );
        });
      } else {
        setPlaces(defaultPlaces);
      }
    },
    [getAutocompleteService],
  );

  const debouncedQueryPlaces = useMemo(() => debounce(queryPlaces, 200), [queryPlaces]);

  const changeQuery = useCallback(value => {
    setQueryTerm(value);
    debouncedQueryPlaces(value);
  }, [debouncedQueryPlaces]);

  const isRetrievedRecentSearch = (item: any): item is RetrievedRecentSearch => 'searchId' in item;

  const onRecentSearchClick = useCallback(async (search: RecentSearch) => {
    const params = Object.entries(search)
      .filter(([key, value]) => value && (key !== 'searchId' && key !== 'location' && key !== 'place_id'))
      .reduce((acc, [key, value]) => {
        switch (key) {
          case 'dates':
            (value as string[]).forEach((date) => {
              acc.append(key, format(parseISO(date), CALENDAR_DATE_FORMAT_DATE_FNS));
            });
            break;
          case 'categories':
            mapCategoriesToGroupIds(search.categories || []).forEach((category) => {
              acc.append(key, category);
            });
            break;
          default:
            acc.append(key, value as string);
        }
        return acc;
      }, new URLSearchParams());
    params.sort();

    if (search.place_id === NEAR_ME) {
      // near-me does not need a location slug; it can redirect immediately
      const redirect = `${EXTERNAL_PATHS.SEARCH}?page=1&${params.toString()}`;
      window.location.href = redirect;
      return;
    }

    /**
     * For non-near-me recent searches, the place_id values might map to a location slug
     * Can be avoided by saving locationSlug with RecentSearch [sc-53512]
     *
     * example @returns
     * - Miami: http://dev.getmyboat.com/boat-rental/Miami--FL--United-States/?page=1
     * - Antarctica: http://dev.getmyboat.com/boat-rental/?page=1&place_id=ChIJS3WQM3uWuaQRdSAPdB--Um4
    */
    const locationUrl = await searchByPlaceId({ placeId: search.place_id });
    const redirect = `${locationUrl}&${params.toString()}`;
    window.location.href = redirect;
  }, []);

  const handleChange = useCallback(
    async (selection: AutocompletePrediction | RetrievedRecentSearch) => {
      if (!selection) {
        return;
      }

      if (isRetrievedRecentSearch(selection)) {
        onRecentSearchClick(selection);
        return;
      }

      if (selection.place_id === NEAR_ME) {
        trackEvent('Near Me Option', {
          event_category: 'Search',
          event_label: 'Clicked',
        });
        window.location.href = EXTERNAL_PATHS.SEARCH;
        return;
      }

      const payload: SearchByPlaceIdPayload = { placeId: selection.place_id };
      if (currencyCode) {
        payload.currency = currencyCode;
      }

      // Send FB conversion for searching
      trackEventFacebook('Search', {
        search_string: queryTerm,
        content_ids: [selection.place_id],
        description: selection.description,
      });
      if (typeof window !== 'undefined' && window.pintrk) {
        window.pintrk('track', 'search', {
          search_query: selection.description,
        });
      }

      const url = await searchByPlaceId(payload);
      if (url) {
        window.location.assign(url.toString());
      }
    },
    [currencyCode, onRecentSearchClick, queryTerm],
  );

  useEffect(
    () => () => {
      debouncedQueryPlaces.cancel();
    },
    [debouncedQueryPlaces],
  );

  const close = useCallback(
    ({ setState }) => () => {
      setState({
        inputValue: '',
        isOpen: false,
        selectedItem: null,
      });
      // Blur the button so .formWrapper:focus-within
      // selector doesn't match anymore to "close" the form
      inputRef.current?.blur();
    },
    [],
  );

  return (
    <Downshift
      onChange={(selection) => handleChange(selection)}
      itemToString={(item) => (item ? item.description : '')}
      onInputValueChange={changeQuery}
      // This makes the first autocomplete option selected by default, which helps
      // the search button behave sanely.
      initialHighlightedIndex={0}
      id="search-dropdown"
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        openMenu,
        isOpen,
        highlightedIndex,
        selectHighlightedItem,
        setState,
      }) => (
        <div className={
          getClassNameFor(s, 'root', classNames({ isOpen }))
        }
        >
          <div className={s.innerWrapper}>
            {/* getLabelProps supplies the htmlFor */}

            {/* form element wrapping the input creates blue GO button on mobile keyboards */}
            <form
              action="#"
              className={s.formWrapper}
              onSubmit={(event) => event.preventDefault()}
            >
              <Icon id="magnifying-glass" size="l" />
              <input
                ref={inputRef}
                {...getInputProps({
                  placeholder,
                  autoCorrect: 'off',
                  autoComplete: 'off',
                  spellCheck: 'false',
                  value: queryTerm,
                  className: getClassNameFor(s, 'input', classNames({ isOwner })),
                  onFocus: () => {
                    if (places.length > 0) {
                      openMenu();
                    }
                    getAutocompleteService?.();
                  },
                })}
                data-test="SearchInput"
              />
              {isOpen && (
                <button
                  type="button"
                  onClick={clear}
                  className={s.clear}
                >
                  <Icon id="x-circle" size="l" />
                </button>
              )}
            </form>
            <SearchBarList
              getItemProps={getItemProps}
              getMenuProps={getMenuProps}
              highlightedIndex={highlightedIndex}
              isOpen={isOpen}
              places={places}
              close={close({ setState })}
              selectHighlightedItem={selectHighlightedItem}
            />
          </div>
        </div>
      )}
    </Downshift>
  );
};

const decorators = [
  autocompleteProvider,
  withErrorBoundary(),
];

export default decorateComponent(SearchBar, decorators);
