import React, { FC, useCallback } from 'react';

import { getClassNameFor, isMobile } from '../../../helpers';

import s from './NumberAddInput.module.scss';

// These should be closures that return the next value when called,
// given a value as input
export const increment = (value: number, step: number) => ((value + step) - (value % step));
export const decrement = (
  value: number,
  step: number,
) => ((value % step) ? value - (value % step) : value - step);

const noop = () => {};

type Props = {
  input: any;
  label?: React.ReactNode;
  minValue?: number;
  maxValue?: number;
  step?: number;
  autoFocus?: boolean;
  classNameModifier?: string;
  format?: (value: number) => string;
  parse?: (value: string) => number;
  normalize?: (value: number, min: number, max: number) => number;
  disabled?: boolean;
  readOnly?: boolean;
  disableDirectInput?: boolean;
  inputText?: string;
  'data-test'?: string;
};

export const BareNumberAddInput:FC<Props> = ({
  input,
  label,
  inputText,
  minValue = 0,
  maxValue = 100,
  step = 1,
  autoFocus,
  classNameModifier,
  format = (value) => (
    (value !== null && typeof value !== 'undefined')
      ? value.toString()
      : ''
  ),
  parse = (value) => Math.abs(Math.round(Number(value))),
  normalize = (value, min, max) => Math.max(Math.min(value, max), min),
  disabled,
  disableDirectInput = false,
  readOnly = false,
  'data-test': dataTest = 'Input',
}) => {
  const {
    value: textValue,
    onBlur,
    onChange,
    onFocus = noop,
    name,
    ...restInput
  } = input;
  const value = Number(textValue);

  const plusClick = useCallback(e => {
    e.preventDefault();
    // We cast to a Number here because `format` ensures
    // that this is going to be a string.
    onChange(increment(Number(value), step));
    onFocus();
  }, [onChange, onFocus, step, value]);

  const minusClick = useCallback(e => {
    e.preventDefault();
    onFocus();
    // IBID
    onChange(decrement(Number(value), step));
  }, [onChange, onFocus, step, value]);

  const onDirectEntry = useCallback(
    event => {
      const numberValue = parse(event.target.value);
      const normalized = normalize(numberValue, minValue, maxValue);
      onChange(normalized);
    },
    [onChange, maxValue, minValue, parse, normalize],
  );

  return (
    <div className={getClassNameFor(s, 'root', classNameModifier)}>
      <button
        type="button"
        disabled={disabled || (value <= minValue)}
        onClick={minusClick}
        onBlur={onBlur}
        className={getClassNameFor(s, 'button', `decrement ${classNameModifier}`)}
        aria-label="Decrease value"
      />
      {/* TODO: Output warning for user if he enters more than maxValue */}
      <div className={s.numberInput}>
        <input
          {...restInput}
          id={name}
          min={minValue}
          max={maxValue}
          step={step}
          value={format(value)}
          onChange={onDirectEntry}
          onFocus={onFocus}
          onBlur={onBlur}
          type="number"
          pattern="\d*" // regex pattern for accepting positive digits without decimals
        // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={!isMobile() && autoFocus}
          className={getClassNameFor(s, 'input', classNameModifier)}
          disabled={disabled || disableDirectInput}
          readOnly={readOnly}
          data-test={dataTest}
        />
        {inputText && (
          <span className={s.inputText}>
            {inputText}
          </span>
        )}
      </div>
      <button
        type="button"
        disabled={disabled || (step > 1 ? value + step >= maxValue : value >= maxValue)}
        onClick={plusClick}
        onBlur={onBlur}
        className={getClassNameFor(s, 'button', `increment ${classNameModifier}`)}
        aria-label="Increase value"
      />
      {label && (
        <label
          htmlFor={name}
          className={getClassNameFor(s, 'label', classNameModifier)}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default BareNumberAddInput;

// This coerces the value to a string, which prevents "01" from being displayed on direct entry
export const format = (value: number) => (
  (value !== null && typeof value !== 'undefined')
    ? value.toString()
    : ''
);

export const parse = (value: string) => Math.abs(Math.round(Number(value)));
// Coerce the number to be in the range of min/max
export const normalize = (
  value: number,
  min: number,
  max: number,
) => Math.max(Math.min(value, max), min);
