import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseLightbox from 'react-images';

class Lightbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxOpen: false,
      currentImage: 0,
    };
  }

  close = () => this.setState({ lightboxOpen: false });

  open = (event, currentImage = 0) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      currentImage,
      lightboxOpen: true,
    });
  };

  gotoNext = () => this.setState(prevState => ({ currentImage: prevState.currentImage + 1 }));

  gotoPrev = () => this.setState(prevState => ({ currentImage: prevState.currentImage - 1 }));

  render() {
    const {
      children,
      ...restProps
    } = this.props;
    const {
      lightboxOpen,
      currentImage,
    } = this.state;
    return (
      <>
        {children({
          close: this.close,
          open: this.open,
        })}
        {lightboxOpen && (
          <BaseLightbox
            isOpen={lightboxOpen}
            onClose={this.close}
            currentImage={currentImage}
            onClickNext={this.gotoNext}
            onClickPrev={this.gotoPrev}
            {...restProps}
          />
        )}
      </>
    );
  }
}

Lightbox.propTypes = {
  children: PropTypes.func.isRequired,
};

export default Lightbox;
