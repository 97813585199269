import React, {
  FC, Ref, useMemo,
} from 'react';
import classNames from 'classnames';
import { SearchPriceNested } from 'src/types/boat/SearchPriceNested';
import { BOOKING_MOTIVATOR_STATE, NEW_TAB_ATTRIBUTES } from 'src/common/constants';
import { getClassNameFor } from 'src/common/helpers';
import type { Breadcrumb } from 'src/types/boat/BoatDetail';
import getYearOfBestOfAward from 'src/common/utils/getYearOfBestOfAward';
import styles from './ListingCard.module.scss';
import UniversalLink from '../UniversalLink';
import ListingPhoto from '../presentation/ListingPhoto';
import ListingBadge from '../ListingBadge';
import WithOverlay from '../WithOverlay';
import ListingCardOverview from '../ListingCardOverview';

type Link = {
  url: string;
  heading: string;
  testMark: string;
  target?: string;
  rel?: string;
};

const { LIMITED_AVAILABILITY, HIGH_DEMAND } = BOOKING_MOTIVATOR_STATE;

const ALLOWED_MOTIVATORS = [LIMITED_AVAILABILITY, HIGH_DEMAND] as const;
type Motivators = typeof ALLOWED_MOTIVATORS[number];

/**
 * Determine if the supplied Motivator is on the "Allowed" list. This prevents an
 * empty ListingBadge from displaying.
 */
const isAllowedMotivator = (x: any): x is Motivators => ALLOWED_MOTIVATORS.includes(x);

export type ListingCardProps = {
  cardRef?: Ref<HTMLDivElement>;
  classNameModifier?: string;
  listingUrl?: string;
  imageSrc: string;
  capacity?: string;
  headline: string;
  rating?: number;
  isSuperOwner?: boolean;
  isActive?: boolean;
  breadcrumbs?: Breadcrumb[];
  category?: Breadcrumb | null;
  awards?: string[];
  instantBookingAvailable?: boolean;
  inquirySent?: boolean;
  openInNewTab?: boolean;
  bookingMotivator?: Motivators;
  searchPricing?: Pick<SearchPriceNested,
  | 'formatted'
  | 'price_unit'
  >;
};

const ListingCard: FC<ListingCardProps> = ({
  cardRef,
  classNameModifier,
  listingUrl,
  imageSrc,
  capacity,
  headline,
  rating,
  isSuperOwner,
  isActive,
  breadcrumbs,
  category,
  awards,
  instantBookingAvailable,
  inquirySent = false,
  bookingMotivator,
  searchPricing = {},
  openInNewTab = false,
}) => {
  const bestOfYear = useMemo(() => getYearOfBestOfAward(awards), [awards]);
  const modifiers = useMemo(
    () => classNames(classNameModifier, { active: isActive }),
    [classNameModifier, isActive],
  );

  const city: string = breadcrumbs && breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1]?.name : '';
  const heading: string = category?.name && city
    ? `${category?.name} in ${city}`
    : category?.name ?? city;

  let link: Link | null = null;
  if (listingUrl) {
    link = {
      url: listingUrl,
      heading,
      testMark: 'ListingCard',
      ...(openInNewTab ? NEW_TAB_ATTRIBUTES : {}),
    };
  }

  const img = (
    <ListingPhoto
      listingUrl={listingUrl}
      photoUrl={imageSrc}
      headline={headline}
      classNameModifier="rounded"
      openInNewTab={openInNewTab}
    />
  );

  const motivatorVariantMap = {
    [HIGH_DEMAND]: 'motivator-high',
    [LIMITED_AVAILABILITY]: 'motivator-limited',
  } as const;

  const motivatorLabelMap = {
    [HIGH_DEMAND]: 'High Demand',
    [LIMITED_AVAILABILITY]: 'Limited Availability',
  } as const;

  return (
    <div className={getClassNameFor(styles, 'root', modifiers)}>
      {bookingMotivator && isAllowedMotivator(bookingMotivator) && (
        <div className={styles.motivator}>
          <ListingBadge
            variant={motivatorVariantMap[bookingMotivator]}
          >
            {motivatorLabelMap[bookingMotivator]}
          </ListingBadge>
        </div>
      )}
      {link && (
        <UniversalLink
          to={link.url}
          aria-label={link.heading}
          data-test={link.testMark}
          className={getClassNameFor(styles, 'link', modifiers)}
          openInNewTab={openInNewTab}
        />
      )}
      <div
        ref={cardRef}
        className={getClassNameFor(styles, 'contents', modifiers)}
      >
        <div className={styles.imageContainer}>
          {(inquirySent) && (
            <WithOverlay
              text="Inquiry Sent"
              classNameModifier={classNames({
                offset: true,
              })}
            />
          )}
          {img}
        </div>
        <ListingCardOverview
          heading={heading}
          guests={capacity}
        />
        <h3 className={getClassNameFor(styles, 'title', modifiers)}>{headline}</h3>
        <div className={getClassNameFor(styles, 'details', modifiers)}>
          {(
            <span>
              <ListingBadge
                variant="rating"
              >
                {rating ? rating.toFixed(1) : 'New'}
              </ListingBadge>
              {isSuperOwner && (
                <ListingBadge
                  variant="superowner"
                >
                  Superowner
                </ListingBadge>
              )}
              {bestOfYear && (
                <ListingBadge
                  variant="bestOfYear"
                >
                  {`Best of ${bestOfYear}`}
                </ListingBadge>
              )}
            </span>
          )}
          <span className={styles.pricingDetails}>
            {searchPricing?.price_unit && (
              <div className={getClassNameFor(styles, 'pricing', modifiers)}>
                <span className={styles.pricingFormatted}>
                  {searchPricing.formatted}
                </span>
                <span className={styles.pricingUnit}>
                  {`/${searchPricing.price_unit}`}
                </span>
              </div>
            )}
            {instantBookingAvailable && (
              <ListingBadge
                variant="instabook"
              >
                Instant Book
              </ListingBadge>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;
