import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { INSTABOOK_EDITOR_MODAL_NAME } from '../constants';
import { useBoats } from '../../common/hooks';
import { useListingsFilterContext } from './useListingsFilter';
import { MinimalBoatDetail } from '../../types/boat/BoatDetail';

type BoatId = MinimalBoatDetail['id'] | null;

const useInstabookListingSelector = () => {
  const { [INSTABOOK_EDITOR_MODAL_NAME]: modalIsOpen } = useSelector(
    (state: any) => state.zippies.toJS(),
  );
  const { boats: activeBoats, allBoats } = useBoats();
  const { activeFilters } = useListingsFilterContext();
  // If owner has only one active boat
  const singleBoatId: BoatId = activeBoats.length === 1 ? activeBoats[0].id : null;
  // If owner selected only one boat in the ListingsFilter
  const singleSelectedBoatId: BoatId = useMemo(
    () => (activeFilters.length === 1 ? activeFilters[0] : null),
    [activeFilters],
  );
  const [preselectedBoatId, setPreselectedBoatId] = useState<BoatId>(null);
  const [selected, setSelected] = useState<null | MinimalBoatDetail>(null);

  const setInstabookListing = useCallback((boatId: BoatId, activeOnly: boolean = false) => {
    const selectedBoat = (activeOnly ? activeBoats : allBoats).find(boat => boat.id === boatId);
    if (selectedBoat) {
      setSelected(selectedBoat);
    }
  }, [allBoats, activeBoats]);

  useEffect(() => {
    setPreselectedBoatId(singleBoatId || singleSelectedBoatId);
  }, [singleBoatId, singleSelectedBoatId]);

  useEffect(() => {
    if (preselectedBoatId && !selected?.id) {
      setInstabookListing(preselectedBoatId, true);
    }
    if (modalIsOpen === false) {
      // Reset selected boat when modal close
      setSelected(null);
    }
  }, [modalIsOpen, preselectedBoatId, selected, setInstabookListing]);

  return {
    instabookListing: selected,
    setInstabookListing,
  };
};

export default useInstabookListingSelector;
