import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../../common/helpers';

import NotificationMessage from '../../../common/components/NotificationMessage';
import { InstabookWarning } from '../../types';

import s from './InstabookCardStatus.module.scss';
import { INSTABOOK_WARNINGS } from '../../constants';

export type InstabookCardStatusProps = {
  published: boolean;
  warning?: InstabookWarning;
  disabled: boolean;
  timeToBook: number;
};

const InstabookCardStatus: FC<InstabookCardStatusProps> = ({
  published,
  warning,
  disabled,
  timeToBook,
}) => {
  let status = 'Draft';
  if (published) status = 'Published';
  if (warning && warning !== INSTABOOK_WARNINGS.PARTIALLY_UNAVAILABLE_TIMES && published) status = 'Not Bookable';

  const copy = useMemo(() => ({
    [INSTABOOK_WARNINGS.UNPUBLISHED]: 'Listing is unpublished',
    [INSTABOOK_WARNINGS.UNAVAILABLE]: 'The date is unavailable',
    [INSTABOOK_WARNINGS.DEPARTURE]: `Trip departure is in less than ${timeToBook} hours`,
    [INSTABOOK_WARNINGS.UNAVAILABLE_DAY]: 'Departure times are unavailable',
    [INSTABOOK_WARNINGS.UNAVAILABLE_TIMES]: 'Departure times are unavailable',
  }), [timeToBook]);

  return (
    <div className={s.root}>
      {warning && warning !== INSTABOOK_WARNINGS.PAST && (
        <NotificationMessage
          outdent={false}
          withIcon={false}
          classNameModifier="instabookCard"
        >
          {copy[warning]}
        </NotificationMessage>
      )}
      <p className={getClassNameFor(s, 'state', classNames({ disabled }))}>{status}</p>
    </div>
  );
};

export default InstabookCardStatus;
