import PropTypes from 'prop-types';
import React from 'react';

import {
  EXTERNAL_PATHS,
  PATHS,
  SUPPORT_URLS,
} from '../../../../common/constants';
import UniversalLink from '../../../../common/components/UniversalLink';
import MessagePanel from '../../presentation/MessagePanel';
import { preserveSearch } from '../../../../common/utils/routing';
import CTA from '../../CTA';
import ArchiveButton from '../../ArchiveButton';

const AccountBanned = ({
  trip,
  location,
}) => {
  const archived = trip.get('archived');
  const cta = (
    <CTA classNameModifier="withSidebar">
      <ArchiveButton
        archived={archived}
        trip={trip}
      >
        {`Move ${archived ? 'from' : 'to'} Archive`}
      </ArchiveButton>
    </CTA>
  );

  return (
    <MessagePanel
      backLocation={preserveSearch(PATHS.INBOX, location)}
      cta={cta}
    >
      <p>
        This conversation has been removed.
      </p>
      <p>
        {'To protect our community Getmyboat occasionally removes users that do not comply with our '}
        <UniversalLink
          to={EXTERNAL_PATHS.TERMS}
        >
          terms of use
        </UniversalLink>
        {' or '}
        <UniversalLink
          to={SUPPORT_URLS.COMMUNITY_GUIDELINES}
          openInNewTab
        >
          community guidelines
        </UniversalLink>.
        {' Sometimes users also simply ask to be removed. If you have any concerns please '}
        <UniversalLink
          to={SUPPORT_URLS.REQUEST}
          openInNewTab
        >
          contact support
        </UniversalLink>.
      </p>
    </MessagePanel>
  );
};

AccountBanned.propTypes = {
  trip: PropTypes.object,
  location: PropTypes.object,
};

export default AccountBanned;
