import React, { FC } from 'react';
import s from './Modal.module.scss';

type HeaderProps = {
  children: React.ReactNode;
};

const Header: FC<HeaderProps> = ({ children }) => (
  <div className={s.header}>
    {children}
  </div>
);

export default Header;
