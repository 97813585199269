import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { ImmutableTrip } from 'src/types/trips/Trips';
import { open } from 'src/common/ducks/zippy';
import UnstyledButton from 'src/common/components/UnstyledButton';
import { getOffer } from 'src/common/utils/reduxStoreSelectors';
import { FORMS } from 'src/inbox/constants';
import { useOffer } from 'src/inbox/hooks';
import CaptainModal from '../cards/Captain/modal';
import TimeModal from '../cards/Time/modal';
import { CTAError } from '../CTA';

type OfferErrorProps = {
  trip: ImmutableTrip;
};

const OfferError: FC<OfferErrorProps> = ({ trip }) => {
  const dispatch = useDispatch();
  const offer = useSelector(getOffer);
  const { errors } = useOffer(trip, offer);

  return (
    <CTAError>
      {"You can't create an offer without a "}
      {errors.pickupTime && (
        <>
          <UnstyledButton onClick={() => dispatch(open(FORMS.TIME))}>
            depart time
          </UnstyledButton>
          <TimeModal trip={trip} />
        </>
      )}
      {!errors.captain && '.'}
      {errors.captain && errors.pickupTime && ' and '}
      {errors.captain && (
        <>
          <UnstyledButton onClick={() => dispatch(open(FORMS.CAPTAIN))}>
            valid captain charter type.
          </UnstyledButton>
          <CaptainModal
            modalName={FORMS.CAPTAIN}
            trip={trip}
          />
        </>
      )}
    </CTAError>
  );
};

export default OfferError;
