import React, { FC, useMemo } from 'react';

import s from './FieldWrapper.module.scss';

type Props = {
  id: string;
  error: string;
  errorClassName?: string;
  warning: string;
  touched: boolean;
  warningClassName?: string;
  shouldBeTouchedToShowError?: boolean;
};

const AlertMessage: FC<Props> = ({
  id,
  touched,
  error,
  warning,
  errorClassName = s.error,
  warningClassName = s.warning,
  shouldBeTouchedToShowError = true,
}) => {
  const element = useMemo(() => (
    <label
      htmlFor={id}
      className={error ? errorClassName : warningClassName}
    >
      {error || warning}
    </label>
  ), [error, errorClassName, id, warning, warningClassName]);

  if (shouldBeTouchedToShowError) {
    return touched ? element : null;
  }
  return element;
};

export default AlertMessage;
