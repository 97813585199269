import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'src/common/components/IconDS22';
import SvgDefs from '../../../common/components/SvgDefs';
import FormError from '../../../common/components/FormError';
import NotificationMessage from '../../../common/components/NotificationMessage';

import PhotoInput from '../PhotoInput';
import PhotoPreview from '../PhotoPreview';
import PhotoPanel from '../PhotoPanel';
import imagesSvg from './images.svg';
import s from './PhotoUploader.module.scss';

const FILE_INPUT_ID = 'photos';

const PhotoUploader = ({
  children,
  images,
  existingImages,
  activeImageUrl,
  handleChange,
  handleSubmit,
  openImage,
  removeImage,
  successMessage,
  warning,
  error,
}) => (
  <form
    className={s.root}
    onSubmit={handleSubmit}
  >
    <SvgDefs href={imagesSvg} />

    <PhotoInput
      inputId={FILE_INPUT_ID}
      label="Add photos to your review"
      onChange={handleChange}
    />

    {successMessage && (
      <p className={s.successMessage}>
        <Icon id="check-circle" />
        {successMessage}
      </p>
    )}

    {warning && (
      <NotificationMessage
        classNameModifier="centered"
        outdent={false}
        withIcon={false}
      >
        {warning}
      </NotificationMessage>
    )}
    <FormError error={error} />

    {!successMessage && (
      <PhotoPanel
        inputId={FILE_INPUT_ID}
        images={images}
        openImage={openImage}
        existingImages={existingImages}
      >
        {children}
      </PhotoPanel>
    )}

    <PhotoPreview
      imageUrl={activeImageUrl}
      removeImage={removeImage}
    />
  </form>
);

PhotoUploader.propTypes = {
  children: PropTypes.node,
  images: PropTypes.array.isRequired,
  existingImages: PropTypes.array,
  activeImageUrl: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  openImage: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
  error: PropTypes.string,
  warning: PropTypes.string,
};

export default PhotoUploader;
