import React, { FC } from 'react';
import s from './Modal.module.scss';

type DescriptionProps = {
  children: React.ReactNode;
};

const Description: FC<DescriptionProps> = ({ children }) => (
  <p className={s.description}>
    {children}
  </p>
);

export default Description;
