import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ImmutableTrip } from '../../../../types/trips/Trips';
import {
  getClassNameFor,
  multidayFormat,
  singleDayFormat,
} from '../../../../common/helpers';
import {
  modified as tripModified,
  offerModified,
} from '../../../helpers';
import { getOffer } from '../../../../common/utils/reduxStoreSelectors';
import DurationSnippet from '../../../../common/components/DurationSnippet';
import { open } from '../../../../common/ducks/zippy';
import {
  FORMS,
  REQUESTED_CHANGES_WARNING,
  TRIP_LENGTH_FIELD,
  UNSENT_CHANGES_WARNING,
} from '../../../constants';
import EditButton from '../../EditButton';
import Modal from '../../../../common/components/Modal';
import DurationForm from './form';
import NotificationMessage from '../../../../common/components/NotificationMessage';
import Card from '../../presentation/Card';
import cardStyles from '../../presentation/Card/Card.module.scss';
import offerDurationSelector from './selectors';
import styles from './DurationCard.module.scss';

// Import multiple css modules into a component
const s = {
  ...cardStyles,
  ...styles,
};
const fields = [TRIP_LENGTH_FIELD];

type Props = {
  trip: ImmutableTrip;
  readOnly?: boolean;
  checkTripModification?: boolean;
  requestedChanges?: boolean;
};

const DurationCard: FC<Props> = ({
  trip,
  readOnly,
  // TODO: change how these work
  checkTripModification,
  requestedChanges,
}) => {
  const dispatch = useDispatch();
  const openModal = () => dispatch(open(FORMS.DURATION));
  const offer = useSelector(getOffer);
  const { isMultiDay, tripLengthMoment } = offerDurationSelector({ offer, trip });

  const isDay = trip.get('has_day_pricing');
  const isNight = trip.get('has_night_pricing');
  const isOnlyDay = isDay && !trip.get('has_hour_pricing');

  // Multiday trips are mix of days and hours. 1 Day and 8 Hours would be equivalent of '2 Days'
  const days = isMultiDay && isDay
    ? Math.ceil(tripLengthMoment.asDays())
    : Math.floor(tripLengthMoment.asDays());

  const offerIsModified = offerModified(offer, trip, fields);
  const tripIsModified = checkTripModification && tripModified(trip, fields);
  const modified = offerIsModified || tripIsModified;

  const tripLengthString = days || isOnlyDay
    ? multidayFormat(days, isDay, isOnlyDay)
    : singleDayFormat(tripLengthMoment);

  return (
    <Card
      highlighted={modified}
      classNameModifier={!readOnly ? 'hoverEffect' : ''}
    >
      {offerIsModified && (
        <NotificationMessage outdent={false}>
          {UNSENT_CHANGES_WARNING}
        </NotificationMessage>
      )}
      {requestedChanges && tripIsModified && !offerIsModified && (
        <NotificationMessage outdent={false}>
          {REQUESTED_CHANGES_WARNING}
        </NotificationMessage>
      )}
      <DurationSnippet isNight={isNight && Math.floor(tripLengthMoment.asDays()) > 0}>
        <h3 className={s.heading}>
          Duration
        </h3>
        <p className={getClassNameFor(s, 'details', modified ? 'modified' : '')}>
          {tripLengthString}
        </p>
      </DurationSnippet>

      {!readOnly && (
        <>
          <EditButton openModal={openModal} />
          <Modal
            name={FORMS.DURATION}
            label="Duration Form"
          >
            <DurationForm
              modified={offerIsModified}
              initialValues={{
                days: Math.floor(tripLengthMoment.asDays()),
                hours: tripLengthMoment.hours(),
                minutes: tripLengthMoment.minutes(),
              }}
              hasDayPricing={isDay}
            />
          </Modal>
        </>
      )}
    </Card>
  );
};

export default DurationCard;
