import React, { FC } from 'react';
import { getClassNameFor } from 'src/common/helpers';
import { useCarousel } from 'src/common/utils/carouselProvider';
import classNames from 'classnames';
import s from './CarouselButtons.module.scss';
import Button, { ButtonProps } from '../Button';
import Icon from '../IconDS22';

export type CarouselButtonsProps = {
  spacing: 'together' | 'apart';
  /** @param invisibleIfBothDisabled If both buttons are disabled, set visibility: hidden.
   * To remove without affecting layout */
  invisibleIfBothDisabled?: boolean;
} & Partial<Omit<ButtonProps, 'iconId'>>;

const CarouselButtons: FC<CarouselButtonsProps> = ({
  classNameModifier = 'tertiary',
  spacing = 'apart',
  invisibleIfBothDisabled,
  ...rest
}) => {
  const {
    scrollPrev,
    scrollNext,
    canScrollPrev,
    canScrollNext,
  } = useCarousel();

  return (
    <div className={getClassNameFor(
      s,
      'root',
      classNames(
        spacing,
        { invisible: invisibleIfBothDisabled && !canScrollPrev && !canScrollNext },
      ),
    )}
    >
      <Button
        iconOnly
        type="button"
        classNameModifier={classNameModifier}
        onClick={scrollPrev}
        disabled={!canScrollPrev}
        {...rest}
      >
        <Icon id="caret-left" />
      </Button>
      <Button
        iconOnly
        type="button"
        classNameModifier={classNameModifier}
        onClick={scrollNext}
        disabled={!canScrollNext}
        {...rest}
      >
        <Icon id="caret-right" />
      </Button>
    </div>
  );
};

export default CarouselButtons;
