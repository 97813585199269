import React, { FC } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { AdHocEventFormState } from 'src/calendar/types';
import {
  CALENDAR_DATE_FORMAT_DATE_FNS,
  DATE_FORMAT_FNS,
  MAX_NEXT_MONTHS,
  UNAVAILABLE_EDITOR_REPEAT_COUNT as REPEAT_COUNT,
  UNAVAILABLE_EDITOR_REPEAT_END_DATE as REPEAT_END_DATE,
  UNAVAILABLE_EDITOR_REPEAT_FREQUENCY as REPEAT_FREQUENCY,
} from 'src/calendar/constants';
import { getClassNameFor } from 'src/common/helpers';
import NumberAddInput from 'src/common/components/inputs/NumberAddInput';
import { calculateDurationFNS, calculateRepetitionEndDateFNS, repeatEndDateToCountFNS } from 'src/calendar/helpers';
import DateField from 'src/common/components/inputs/DateField';
import { addMonths, format } from 'date-fns';
import classNames from 'classnames';
import RepetitionFrequencySelector from '../RepetitionFrequencySelector';
import s from './UnavailableRepeatField.module.scss';

type UnavailableRepeatFieldProps = {
  formRef: React.MutableRefObject<HTMLFormElement | null>;
};

const UnavailableRepeatField: FC<UnavailableRepeatFieldProps> = ({ formRef }) => {
  const form = useForm();
  const {
    values,
    initialValues,
  } = useFormState<AdHocEventFormState>();
  const eventStartDate = values.event_date || initialValues.event_date as string;
  const eventEndDate = values.event_end_date as string;
  const duration = calculateDurationFNS(eventEndDate, eventStartDate);
  const maxEndDate = format(addMonths(new Date(), MAX_NEXT_MONTHS), CALENDAR_DATE_FORMAT_DATE_FNS);
  const maxOccurrences = repeatEndDateToCountFNS(
    eventStartDate,
    maxEndDate,
    values.frequency || undefined,
  );
  return (
    <>
      <div className={s.fieldRow}>
        <div className={s.field}>
          <Field
            name={REPEAT_FREQUENCY}
          >
            {({ input }) => (
              <RepetitionFrequencySelector
                value={input.value}
                onChange={input.onChange}
                disabled={duration > 7}
                formRef={formRef}
              />
            )}
          </Field>
        </div>
      </div>
      <div className={getClassNameFor(s, 'field', classNames(
        'flex',
        {
          hidden: !values.frequency,
        },
      ))}
      >
        <div className={s.repeatUntil}>
          <label
            className={s.label}
            htmlFor={REPEAT_END_DATE}
          >
            Repeats Until:
          </label>
          <Field name={REPEAT_END_DATE}>
            {({ input }) => (
              <DateField
                id={REPEAT_END_DATE}
                value={input.value}
                dateFormat={DATE_FORMAT_FNS}
                outputDateFormat={CALENDAR_DATE_FORMAT_DATE_FNS}
                onDateSelect={input.onChange}
                classNameModifier="unavailableEditor"
              />
            )}
          </Field>
        </div>
        <div>
          <label
            className={s.label}
            htmlFor={REPEAT_END_DATE}
          >
            Occurrences:
          </label>

          <Field
            classNameModifier="unavailableEditor"
            name={REPEAT_COUNT}
          >
            {({ input }) => {
              const changeRepeatEndDate = (value: number) => {
                const val = calculateRepetitionEndDateFNS(
                  values.event_end_date,
                  value,
                  values.frequency,
                );
                form.change(REPEAT_END_DATE, val);
              };

              return (
                <NumberAddInput
                  name="repeatCount"
                  readOnly
                  classNameModifier="repeatCount smallButtons readOnly"
                  input={{
                    onChange: (value: number) => {
                      changeRepeatEndDate(value);
                    },
                    value: input.value,
                  }}
                  minValue={1}
                  maxValue={maxOccurrences}
                />
              );
            }}
          </Field>
        </div>
      </div>
    </>
  );
};

export default UnavailableRepeatField;
