import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../../../common/helpers';
import s from './Card.module.scss';

type Modifier =
  'highlighted'
  | 'hoverEffect'
  | 'instabookDetails'
  | 'loading'
  | 'review'
  | 'reviewShare'
  | 'termsAndCancellation'
  | 'withLargeRightPadding'
  | 'withZeroPadding'
  ;

type CardPropTypes = {
  highlighted: boolean;
  classNameModifier?: Modifier;
  className: string;
  children: React.ReactNode;
  title: string;
};

const Card: FC<CardPropTypes> = ({
  highlighted = false,
  children,
  className = '',
  classNameModifier = '',
  title,
}) => {
  const modifiers = useMemo(() => classNames(classNameModifier, {
    highlighted,
  }), [classNameModifier, highlighted]);

  return (
    <div className={`${getClassNameFor(s, 'root', modifiers)} ${className}`}>
      {title && (
        <h3 className={s.title}>
          {title}
        </h3>
      )}
      {children}
    </div>
  );
};

export default Card;
