import React from 'react';
import { useLocation } from 'react-router-dom';

import type { ImmutableTrip } from 'src/types/trips/Trips';
import { PATHS } from 'src/common/constants';
import { PAGES } from 'src/inbox/constants';
import { preserveSearch } from 'src/common/utils/routing';
import TopScroll from 'src/common/components/TopScroll';
import CheckoutButton from 'src/checkout/CheckoutButton';
import Insurance from 'src/insurance/InsuranceForm';
import CTA from 'src/inbox/components/CTA';
import Card from 'src/inbox/components/presentation/Card';
import TripPanel from 'src/inbox/components/presentation/TripPanel';

type InsurancePageProps = {
  trip: ImmutableTrip;
};

const InsurancePage: React.FC<InsurancePageProps> = ({
  trip,
}) => {
  const location = useLocation();

  const cta = (
    <CTA classNameModifier="reviewBooking">
      <CheckoutButton tripId={trip.get('pk')} />
    </CTA>
  );

  return (
    <TripPanel
      trip={trip}
      subheader="Insurance"
      cta={cta}
      backLocation={preserveSearch(`${PATHS.INBOX}${trip.get('pk')}/${PAGES.REVIEW_BOOKING}/`, location)}
      classNameModifier="withoutSideMargins reviewBooking"
      withTripControls={false}
    >
      <TopScroll />
      <Card>
        <Insurance />
      </Card>
    </TripPanel>
  );
};

export default InsurancePage;
