import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../../common/helpers';
import Avatar from '../../../common/components/Avatar';
import TypingIndicator from '../TypingIndicator';
import { usePubSubContext } from '../../../pubsub/hook/usePubsub';

import s from './UserTyping.module.scss';

const UserTyping = ({ user, tripId }) => {
  const { isThreadTyping } = usePubSubContext();

  return (
    <div className={getClassNameFor(s, 'root', classNames({ idle: !isThreadTyping(tripId) }))}>
      <div className={s.innerWrapper}>
        <div className={s.avatar}>
          <Avatar
            size="tiny"
            user={user.toJS()}
            classNameModifier="noMargin"
          />
        </div>
        <div className={s.typing}>
          <div className={s.innerTyping}>
            <span>{user.get('first_name')} is typing</span>
            <TypingIndicator />
          </div>
        </div>
      </div>
    </div>
  );
};

UserTyping.propTypes = {
  user: PropTypes.object.isRequired,
  tripId: PropTypes.number.isRequired,
};

export default UserTyping;
