import { FC, useCallback, useEffect, useState } from 'react';
import { useInterval } from '../../../common/hooks';
import { usePubSubContext } from '../../../pubsub/hook/usePubsub';

/**
 * Track when the user's window focused.
 * Informs the pubsub connection that the user is active every 30 seconds.
 */
const PresenceIdle: FC = () => {
  const { postPresence } = usePubSubContext();

  const [focused, setFocused] = useState(false);

  const postPresencePageFocus = useCallback(() => {
    if (document.hasFocus()) {
      postPresence();
    }
  }, [postPresence]);

  const onFocus = useCallback(() => {
    setFocused(true);
    postPresence();
  }, [postPresence]);

  const onBlur = useCallback(() => {
    setFocused(false);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (document.hasFocus()) {
        onFocus();
      }

      window.addEventListener('blur', onBlur);
      window.addEventListener('focus', onFocus);

      return () => {
        window.removeEventListener('blur', onBlur);
        window.removeEventListener('focus', onFocus);
      };
    }
    return () => { };
  }, [onBlur, onFocus]);

  useInterval(postPresencePageFocus, 30000, focused);

  return null;
};

export default PresenceIdle;
