import React from 'react';
import { useTranslation } from 'src/common/hooks';
import RadioButtonField, { parseTrueFalseValue } from 'src/common/components/fields/RadioButton';
import { FIELD_NAMES } from '../constants';

const TripProtection: React.FC = () => {
  const { t } = useTranslation('insurance');

  return (
    <fieldset>
      <legend>
        {t('insuranceTitle')}
      </legend>
      <p>
        {t('insuranceDescription')}
      </p>
      <label>
        <RadioButtonField
          name={FIELD_NAMES.trip_protection}
          parse={parseTrueFalseValue}
          value
        />{' '}
        {t('insuranceYes')}
      </label>
      <br />
      <label>
        <RadioButtonField
          name={FIELD_NAMES.trip_protection}
          parse={parseTrueFalseValue}
          value={false}
        />{' '}
        {t('insuranceNo')}
      </label>
    </fieldset>
  );
};

export default TripProtection;
