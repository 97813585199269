import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { Form } from 'react-final-form';
import { Switch, Route, Redirect } from 'react-router-dom';

import { trackEvent } from 'src/common/tracking';
import { PATHS } from '../../../../common/constants';
import { decorateComponent, defined, rffSubmitResponse } from '../../../../common/helpers';
import { explodeRoute, preserveSearch } from '../../../../common/utils/routing';
import { getTripCall } from '../../../ducks/trips';
import {
  REVIEW_DONE_STATES,
  REVIEW_PENDING_STATES,
  REVIEW_WAITING_STATES,
} from '../../../constants';
import {
  getUserRole,
  isNegativeReview,
  userIsRenter,
  getReviewUrl,
} from '../../../helpers';
import { NewReviewCard } from '../../cards';
import {
  postCustomerFeedbackCall,
  postRenterReviewCall,
  postBoatReviewCall,
} from '../../../ducks/reviews';
import {
  REVIEW_PAGES,
  FIELDS,
} from './reviewsConfig';
import {
  Start,
  Categories,
  PrivateNote,
  PublicReview,
  Photos,
  Feedback,
  NPS,
  Thanks,
  ReviewShare,
} from '../../reviewSteps';
import WizardProgressBar from '../../../../common/components/WizardProgress';

const mapStateToProps = ({ reviews }, { trip }) => ({
  reviews: reviews || Map(),
  role: getUserRole(trip),
});

const tripReviewPages = [
  REVIEW_PAGES.START,
  REVIEW_PAGES.CATEGORIES,
  REVIEW_PAGES.PRIVATE_NOTE,
  REVIEW_PAGES.PUBLIC_REVIEW,
];

const wizardPages = [
  ...tripReviewPages,
  REVIEW_PAGES.PHOTOS,
  REVIEW_PAGES.REVIEW_SHARE,
  REVIEW_PAGES.FEEDBACK,
  REVIEW_PAGES.NPS,
];

const { RATING, RECOMMENDATION, NET_PROMOTER_SCORE } = FIELDS;

const Complete = ({
  trip,
  reviews,
  role,
  match: { url },
  location,
  history: { push },
  dispatch,
}) => {
  const [npsState, setNps] = useState(null);
  const tripState = trip.getIn(['state', 'state']);
  const reviewPending = REVIEW_PENDING_STATES.includes(tripState);
  const reviewWaiting = REVIEW_WAITING_STATES.includes(tripState);
  const reviewDone = REVIEW_DONE_STATES.includes(tripState);
  const currentPage = explodeRoute(location.pathname).last();
  const backLocation = preserveSearch(PATHS.INBOX, location);
  const initialTripLocation = preserveSearch(`${PATHS.INBOX}${trip.get('pk')}/`, location);
  const shouldRedirect = reviewDone && tripReviewPages.includes(currentPage);
  const progressBar = (
    <WizardProgressBar
      routes={wizardPages}
      classNameModifier="inbox"
    />
  );

  const pageProps = {
    progressBar,
    trip,
    role,
    backLocation,
    initialTripLocation,
    baseUrl: url,
  };

  const onReviewSubmit = useCallback(
    values => {
      const tripId = trip.get('pk');
      const isOwner = !userIsRenter(trip);
      const postCall = isOwner ? postRenterReviewCall : postBoatReviewCall;
      const minimalValuesHaveSet = defined(values[RATING]) || defined(values[RECOMMENDATION]);

      return minimalValuesHaveSet && dispatch(postCall(tripId, values))
        .then(() => {
          if (isNegativeReview(values[RATING], values[RECOMMENDATION])) {
            // This is logic to skip public review & photo submission if the user had a bad time.
            // That kinda makes sense.
            push(getReviewUrl(REVIEW_PAGES.REVIEW_SHARE, role, 'next', location));
          } else {
            push(getReviewUrl(REVIEW_PAGES.PUBLIC_REVIEW, role, 'next', location));
          }

          trackEvent('Submitted', {
            event_category: 'Review',
            event_label: isOwner ? 'Owner' : 'Renter',
          });

          return dispatch(getTripCall(tripId));
        })
        .catch(rffSubmitResponse());
    },
    [dispatch, location, push, role, trip],
  );

  const onFeedbackSubmit = useCallback(
    (values, { getState }) => (
      getState().dirty && dispatch(postCustomerFeedbackCall(trip.get('pk'), values))
        .then(() => {
          setNps(values[NET_PROMOTER_SCORE]);
          const newLocation = getReviewUrl(REVIEW_PAGES.NPS, role, 'next', location);
          push(newLocation);
        })
        .catch(rffSubmitResponse())),
    [dispatch, location, push, role, trip],
  );

  return (
    <Switch>
      {shouldRedirect && <Redirect to={initialTripLocation} />}
      <Route
        // These are the paths for the review form. We're switching here
        // so that we can get a Form wrap without exploding how switch works.
        exact
        path={[
          url,
          `${url}/review/${REVIEW_PAGES.START}/`,
          `${url}/review/${REVIEW_PAGES.CATEGORIES}/`,
          `${url}/review/${REVIEW_PAGES.PRIVATE_NOTE}/`,
          `${url}/review/${REVIEW_PAGES.PUBLIC_REVIEW}/`,
          `${url}/review/${REVIEW_PAGES.REVIEW_SHARE}/`,
        ]}
      >
        <Form
          onSubmit={onReviewSubmit}
        >
          {formProps => (
            <>
              <Route exact path={url}>
                {/* TODO: see if it even makes sense for this to be nested in this way. */}
                <NewReviewCard
                  reviews={reviews}
                  tripState={tripState}
                  reviewPending={reviewPending}
                  reviewWaiting={reviewWaiting}
                  {...pageProps}
                  {...formProps}
                />
              </Route>
              <Route path={`${url}/review/${REVIEW_PAGES.START}/`}>
                <Start {...pageProps} {...formProps} />
              </Route>
              <Route path={`${url}/review/${REVIEW_PAGES.CATEGORIES}/`}>
                <Categories {...pageProps} {...formProps} />
              </Route>
              <Route path={`${url}/review/${REVIEW_PAGES.PRIVATE_NOTE}/`}>
                <PrivateNote {...pageProps} {...formProps} />
              </Route>
              <Route path={`${url}/review/${REVIEW_PAGES.PUBLIC_REVIEW}/`}>
                <PublicReview {...pageProps} {...formProps} />
              </Route>
              <Route path={`${url}/review/${REVIEW_PAGES.REVIEW_SHARE}/`}>
                <ReviewShare {...pageProps} {...formProps} reviews={reviews} />
              </Route>
            </>
          )}
        </Form>
      </Route>
      <Route path={`${url}/review/${REVIEW_PAGES.PHOTOS}/`}>
        <Photos {...pageProps} />
      </Route>
      {/* These are the feedback form */}
      <Route
        path={[
          `${url}/review/${REVIEW_PAGES.FEEDBACK}/`,
          `${url}/review/${REVIEW_PAGES.NPS}/`,
        ]}
      >
        <Form
          onSubmit={onFeedbackSubmit}
        >
          {formProps => (
            <>
              <Route path={`${url}/review/${REVIEW_PAGES.FEEDBACK}/`}>
                <Feedback {...pageProps} {...formProps} reviews={reviews} />
              </Route>
              <Route
                path={`${url}/review/${REVIEW_PAGES.NPS}/`}
              >
                <NPS {...pageProps} {...formProps} />
              </Route>
            </>
          )}
        </Form>
      </Route>
      <Route path={`${url}/review/${REVIEW_PAGES.THANKS}/`}>
        <Thanks {...pageProps} npsValue={npsState} />
      </Route>
    </Switch>
  );
};

Complete.propTypes = {
  trip: PropTypes.object,
  reviews: PropTypes.object,
  role: PropTypes.string,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  dispatch: PropTypes.func,
};

const decorators = [
  connect(mapStateToProps),
];

export default decorateComponent(Complete, decorators);
