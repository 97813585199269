import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { useField, useFormState } from 'react-final-form';

import { getReturnTimeString, getTimeString } from '../../helpers';
import { getClassNameFor } from '../../../common/helpers';
import { INSTABOOK_FIXED_DEPARTURE_TIMES, INSTABOOK_FIXED_DEPARTURE_TIMES_ERROR, INSTABOOK_TIME_FORMAT } from '../../constants';
import Svg from '../../../common/components/Svg';

import s from './InstabookDepartureTimes.module.scss';

type InstabookDepartureTimesProps = {
  openTimeEditor: () => void;
  disabled: boolean;
  duration: number;
  flexible: boolean;
  flexibleTimes: string[];
  fixedTimes: { startTime: string }[];
};

const InstabookDepartureTimes: FC<InstabookDepartureTimesProps> = ({
  openTimeEditor,
  disabled,
  duration,
  flexible,
  flexibleTimes,
  fixedTimes,
}) => {
  const empty = !flexible && fixedTimes.length === 0;
  const [flexibleTimeStart, flexibleTimeEnd] = flexibleTimes;
  const getTime = useCallback(time => getTimeString(time, INSTABOOK_TIME_FORMAT), []);
  const getReturnTime = useCallback(
    time => getReturnTimeString(time, duration, INSTABOOK_TIME_FORMAT),
    [duration],
  );

  const { errors: { [INSTABOOK_FIXED_DEPARTURE_TIMES_ERROR]: error } } = useFormState();
  const { meta: { submitFailed } } = useField(INSTABOOK_FIXED_DEPARTURE_TIMES);
  const fixedTimesError = error && submitFailed;

  return (
    <div className={s.root}>
      <div className={s.header}>
        <Svg
          className={s.icon}
          href="#old-icon-time"
        />
        <h4 className={s.heading}>
          Departure Times Available
        </h4>
      </div>
      <button
        type="button"
        disabled={disabled}
        className={getClassNameFor(s, 'displayTimes', classNames({ empty, error: fixedTimesError }))}
        onClick={openTimeEditor}
      >
        {empty ? (
          <>
            <span className={getClassNameFor(s, 'addIcon', classNames({ disabled }))} />
            Add departure times
          </>
        ) : (
          <>
            <Svg className={s.editIcon} href="#card-edit-icon" />
            {flexible && flexibleTimeStart && (
              <>
                <div className={s.departures}>
                  <span className={s.time}>Earliest Trip</span>
                  <span className={s.time}>
                    <time>{getTime(flexibleTimeStart)}</time>
                    {' → '}
                    <time>{getReturnTime(flexibleTimeStart)}</time>
                  </span>
                </div>
                <div className={s.departures}>
                  <span className={s.time}>Latest Trip</span>
                  <span className={s.time}>
                    <time>{getTime(flexibleTimeEnd)}</time>
                    {' → '}
                    <time>{getReturnTime(flexibleTimeEnd)}</time>
                  </span>
                </div>
              </>
            )}
            {!flexible && fixedTimes.length > 0 && fixedTimes.map((time, i) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={`${time}${i}`}
                className={s.time}
              >
                <time>{getTime(time.startTime)}</time>
                {' → '}
                <time>{getReturnTime(time.startTime)}</time>
              </span>
            ))}
          </>
        )}
      </button>
      {fixedTimesError && (
        <label
          htmlFor={INSTABOOK_FIXED_DEPARTURE_TIMES}
          className={s.error}
        >
          {error}
        </label>
      )}
    </div>
  );
};

export default InstabookDepartureTimes;
