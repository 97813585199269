import format from 'date-fns/format';
import useBoats, { BoatDetailsLookup } from 'src/common/hooks/useBoats';
import { useCalendarContext } from './useCalendar';
import { determineInstabookWarnings, getFirstOfMonth, newTZDate } from '../helpers';
import { CalendarMonthData, GenericEvent } from '../types';
import { getDate, getBoatId, isUnavailableEvent, isCalendarEvent } from '../utils/calendar';
import { CALENDAR_DATE_FORMAT_DATE_FNS } from '../constants';

const isSameMonth = (eventDate: string, monthStartDate: string) => (
  !!eventDate && monthStartDate === getFirstOfMonth(eventDate)
);
const isSameBoat = (e: GenericEvent, boatId: string) => getBoatId(e) === boatId;
const isSameTrip = (e: GenericEvent, eventId: number) => e.id === eventId;
const isSameDay = (e: GenericEvent, eventDate: string) => (
  eventDate && getDate(e) === format(newTZDate(eventDate), CALENDAR_DATE_FORMAT_DATE_FNS)
);

export const calculateInstabookWarning = (
  months: CalendarMonthData[],
  boatsIndexedById: BoatDetailsLookup,
  date: string,
  boatId: string,
  timeToBook: number,
  eventId: number,
  instabookWarningCache?: Map<string, string | undefined>,
) => {
  if (!boatId) {
    return undefined;
  }

  const instabookWarningKey = `${date}-${boatId}-${timeToBook}-${eventId}`;

  // Check if we have calculated the warning already.
  //
  // Need to check that the key exists so we don't get a
  // false positive cache miss when the warning is undefined.
  if (instabookWarningCache?.has(instabookWarningKey)) {
    return instabookWarningCache.get(instabookWarningKey);
  }

  const monthEvents = months
    .find(({ startDate }: CalendarMonthData) => isSameMonth(date, startDate));
  if (!monthEvents) {
    // Add warning to cache
    instabookWarningCache?.set(instabookWarningKey, undefined);
    return undefined;
  }

  const dayEventsForBoat = monthEvents.events.filter(
    e => isSameDay(e, date) && isSameBoat(e, boatId)
      && (isSameTrip(e, eventId) || isUnavailableEvent(e) || isCalendarEvent(e)),
  );

  const boat = boatsIndexedById[boatId];

  const warning = determineInstabookWarnings(
    boat,
    date,
    dayEventsForBoat,
    timeToBook,
  );

  // Add warning to cache
  instabookWarningCache?.set(instabookWarningKey, warning);

  return warning;
};

const useInstabookWarning = (
  date: string,
  boatId: string,
  timeToBook: number,
  eventId: number,
) => {
  const { events: months, instabookWarningCache } = useCalendarContext();

  const { boatsIndexedById } = useBoats();

  return calculateInstabookWarning(
    months,
    boatsIndexedById,
    date,
    boatId,
    timeToBook,
    eventId,
    instabookWarningCache.current,
  );
};

export default useInstabookWarning;
