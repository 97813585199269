import { EXTERNAL_PATHS, PATHS } from '../../constants';
import { NestedMenuItem } from '../NestedMenu';

export type UserType = 'owner' | 'captain' | 'renter' | 'anonymous';

export type ScreenSizes = 'xs' | 's' | 'm' | 'l' | 'xl';
type ScreenSizeConfig = {
  owner?: ScreenSizes[];
  renter?: ScreenSizes[];
  ownerAdmin?: ScreenSizes[];
  anonymous?: ScreenSizes[];
};

export type NestedNavigationConfigType = NestedMenuItem & {
  /** Passed to react as the `key` to uniquely identify navigation item */
  key: string;
  /**
   * Specify if the navigation item is applicable to specific roles only.
   * When no roles are specified, all user types will be able to access the navigation item.
   */
  roles?: UserType[];
  /** Indicates the menu item that is used as a header nav item for
   * specific screen sizes and user roles.
   */
  isHeaderNavItem?: ScreenSizeConfig;
  iconOnlyScreenSizes?: ScreenSizeConfig;
  openInNewTab?: boolean;
};

export const charge: NestedNavigationConfigType = {
  key: 'charge',
  label: 'Charge',
  to: EXTERNAL_PATHS.CHARGE,
  icon: 'credit-card-charge',
  roles: ['owner'],
  isHeaderNavItem: {
    owner: ['xs'], // Shown on extra small screens for owners
    ownerAdmin: ['m'],
  },
  iconOnlyScreenSizes: {
    owner: ['m'],
    ownerAdmin: ['m'],
  },
};
export const directBooking: NestedNavigationConfigType = {
  key: 'direct-booking',
  label: 'Direct Booking',
  to: EXTERNAL_PATHS.CREATE_DIRECT_BOOKING,
  icon: 'credit-card-arrow',
  roles: ['owner'],
  isHeaderNavItem: {
    owner: ['xs'], // Shown on extra small screens for owners
    ownerAdmin: ['m'],
  },
  iconOnlyScreenSizes: {
    owner: ['m'],
    ownerAdmin: ['m'],
  },
};
export const addOn: NestedNavigationConfigType = {
  key: 'add-on-payment',
  label: 'Add-On Payment',
  to: EXTERNAL_PATHS.CHARGE_ADD_ON,
  icon: 'credit-card-check',
  roles: ['owner'],
  isHeaderNavItem: {
    owner: ['xs'], // Shown on extra small screens for owners
    ownerAdmin: ['m'],
  },
  iconOnlyScreenSizes: {
    owner: ['m'],
    ownerAdmin: ['m'],
  },
  sectionDivider: true,
};
const inbox: NestedNavigationConfigType = {
  key: 'inbox',
  label: 'Inbox',
  to: PATHS.INBOX,
  icon: 'envelope',
  roles: ['owner', 'renter'],
  isHeaderNavItem: {
    owner: ['xs'], // Shown on small screens for owners
    renter: ['xs'], // Shown on medium screens for renters
    ownerAdmin: ['m'], // Special case for owner admin
  },
  iconOnlyScreenSizes: {
    owner: ['l'],
    renter: ['m'],
    ownerAdmin: ['l'],
  },
};
const calendar: NestedNavigationConfigType = {
  key: 'calendar',
  label: 'Calendar',
  to: PATHS.CALENDAR,
  icon: 'calendar',
  roles: ['owner'],
  isHeaderNavItem: {
    owner: ['xs'], // Shown on large screens for owners
    ownerAdmin: ['m'],
  },
  iconOnlyScreenSizes: {
    owner: ['l'],
    ownerAdmin: ['l'],
  },
};
const ownerAdmin: NestedNavigationConfigType = {
  key: 'admin',
  label: 'Admin',
  to: EXTERNAL_PATHS.ADMIN_LISTINGS,
  icon: 'admin',
  roles: ['owner'],
  isHeaderNavItem: {
    owner: ['s'], // Shown on extra large screens for owners
    ownerAdmin: ['m'],
  },
  iconOnlyScreenSizes: {
    owner: ['l'],
    ownerAdmin: ['l'],
  },
};
const favorites: NestedNavigationConfigType = {
  key: 'favorites',
  label: 'Favorites',
  to: EXTERNAL_PATHS.FAVORITES,
  icon: 'heart',
  sectionDivider: true,
  roles: ['captain', 'owner', 'renter'],
};
const listYourBoat: NestedNavigationConfigType = {
  key: 'renter-listings',
  label: 'List Your Boat',
  to: EXTERNAL_PATHS.ADMIN_LISTINGS,
  icon: 'boat-add',
  roles: ['captain', 'renter', 'anonymous'],
};
const accountCaptain: NestedNavigationConfigType = {
  key: 'account-captain',
  label: 'Account',
  to: EXTERNAL_PATHS.ACCOUNT,
  icon: 'person',
  roles: ['captain'],
};
const accountRenter: NestedNavigationConfigType = {
  key: 'account-renter',
  label: 'Account',
  to: EXTERNAL_PATHS.ADMIN_PROFILE,
  icon: 'gear',
  roles: ['renter'],
};
const help: NestedNavigationConfigType = {
  key: 'help',
  label: 'Help',
  to: 'https://getmyboat.zendesk.com/',
  openInNewTab: true,
  icon: 'question',
  sectionDivider: true,
  roles: ['owner', 'captain', 'renter', 'anonymous'],
};
const createAccount: NestedNavigationConfigType = {
  key: 'create-account',
  label: 'Create Account',
  to: PATHS.REGISTER,
  icon: 'profile',
  roles: ['anonymous'],
};
const login: NestedNavigationConfigType = {
  key: 'login',
  label: 'Sign In',
  to: PATHS.LOGIN,
  icon: 'login',
  roles: ['anonymous'],
  isHeaderNavItem: {
    anonymous: ['xl'], // Shown on extra large screens for anonymous users
  },
};

const configItems: NestedNavigationConfigType[] = [
  inbox,
  calendar,
  ownerAdmin,
  favorites,
  listYourBoat,
  accountCaptain,
  accountRenter,
  help,
  createAccount,
  login,
];

export const renterConfigItemsOrdered: NestedNavigationConfigType[] = [
  inbox,
  {
    ...favorites,
    sectionDivider: false,
  },
  accountRenter,
  {
    ...listYourBoat,
    sectionDivider: true,
  },
  help,
];

export const captainConfigItemsOrdered: NestedNavigationConfigType[] = [
  inbox,
  {
    ...favorites,
    sectionDivider: false,
  },
  accountCaptain,
  {
    ...listYourBoat,
    sectionDivider: true,
  },
  help,
];

export const captainAndOwnerConfigItemsOrdered: NestedNavigationConfigType[] = [
  inbox,
  calendar,
  {
    ...favorites,
    sectionDivider: false,
  },
  {
    ...ownerAdmin,
    sectionDivider: true,
  },
  help,
];

export default configItems;
