/*
 * This is a partial rewrite of part of react-google-maps-loader:
 * https://github.com/xuopled/react-google-maps-loader/blob/master/src/loadGoogleMapsSdk/index.js
 *
 * This function takes advantage of the google maps callback feature, which
 * should hopefully help cut down on the number of errors we see as a result
 * of google maps not being loaded.
 */
import load from 'little-loader';
import qs from 'query-string';
import captureException from './captureException';
import retry from './retry';

declare global {
  interface Window {
    recaptchaLoaded: () => void;
  }
}

type Recaptcha = typeof window.grecaptcha;

type RecaptchaCallbackArgs = {
  grecaptcha: Recaptcha;
};
type RecaptchaCallback = (args: RecaptchaCallbackArgs) => void;

const GOOGLE_RECAPTCHA_API = 'https://www.google.com/recaptcha/enterprise.js';
export const RECAPTCHA_COOKIE_NAME = 'g-recaptcha-response';
const NOT_LOADED = 0;
const LOADING = 1;
const LOADED = 2;

const queue: RecaptchaCallback[] = [];
let state = NOT_LOADED;
let grecaptcha: Recaptcha | null = null;

const loadRecaptchaSdk = (callback: RecaptchaCallback) => {
  if (typeof window !== 'undefined') {
    window.recaptchaLoaded = () => {
      state = LOADED;
      // eslint-disable-next-line prefer-destructuring
      grecaptcha = window.grecaptcha;
      while (queue.length > 0) {
        queue.pop()?.({ grecaptcha });
      }
    };

    if (state === LOADED && grecaptcha != null) {
      callback({ grecaptcha });
    } else if (state === LOADING) {
      queue.push(callback);
    } else {
      state = LOADING;
      queue.push(callback);

      const url = `${GOOGLE_RECAPTCHA_API}?${qs.stringify({
        render: __RECAPTCHA_API_KEY__,
        onload: 'recaptchaLoaded',
      })}`;
      const loader = () => new Promise((resolve, reject) => {
        load(url, (err: unknown) => (err ? reject() : resolve(undefined)));
      });

      retry(3, loader, async () => {
        captureException(new Error("Login failed because reCAPTCHA isn't loaded"));
      });
    }
  }
};

export default loadRecaptchaSdk;
