import { fromJS } from 'immutable';

import { fetchCatch } from 'src/core/sentry';
import { apiFetchThunk, backendFetchThunk } from '../../common/ducks/fetch';

export const SET_MESSAGES = 'inbox/messages/SET_MESSAGES';
export const APPEND_MESSAGES = 'inbox/messages/APPEND_MESSAGES';
export const CLEAR_MESSAGES = 'inbox/messages/CLEAR_MESSAGES';
export const PUSH_MESSAGE = 'inbox/messages/PUSH_MESSAGE';
export const UPDATE_MESSAGE = 'inbox/messages/UPDATE_MESSAGE';

export const setMessages = messages => ({ type: SET_MESSAGES, messages });
export const appendMessages = messages => ({ type: APPEND_MESSAGES, messages });
export const clearMessages = () => ({ type: CLEAR_MESSAGES });
export const pushMessage = message => ({ type: PUSH_MESSAGE, message });
export const updateMessage = message => ({ type: UPDATE_MESSAGE, message });

/**
 * @param {string | number} id
 */
const ensureInt = id => (typeof id === 'number' ? id : parseInt(id, 10));

/**
 * @param {unknown} messages
 * @param {string} messageId
 * @returns {number}
 */
const findMessageIndex = (messages, messageId) => (
  messages.get('results')
    .findIndex(message => ensureInt(message.get('id')) === ensureInt(messageId))
);

export const INITIAL_MESSAGES_STATE = fromJS({
  next: null,
  previous: null,
  count: null,
  results: [],
});

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (messages = INITIAL_MESSAGES_STATE, action) => {
  switch (action.type) {
    case SET_MESSAGES:
      return action.messages;
    case CLEAR_MESSAGES:
      return INITIAL_MESSAGES_STATE;
    case APPEND_MESSAGES:
      return messages
        .merge(action.messages)
        .update('results', messageList => messages.get('results').concat(messageList));
    case PUSH_MESSAGE: {
      const messageIndex = findMessageIndex(messages, action.message.get('id'));
      // Add message if not found.
      if (messageIndex === -1) {
        return messages
          .update('count', count => count + 1)
          .update('results', messageList => messageList.unshift(action.message));
      }
      return messages;
    }
    case UPDATE_MESSAGE: {
      /**
       * @typedef {import('../../pubsub/streamProtocol').EditMessage} EditMessagePayload
       * @type {EditMessagePayload}
       */
      const msg = action.message;
      const indexToUpdate = findMessageIndex(messages, msg.data.message_id);
      // Don't update if the message is not found
      if (indexToUpdate === -1) {
        return messages;
      }
      return messages
        .setIn(['results', indexToUpdate, 'content'], msg.data.content);
    }
    default:
      return messages;
  }
};

export const getMessagesCall = tripId => async dispatch => {
  dispatch(clearMessages());
  const response = await dispatch(apiFetchThunk(`/trips/${tripId}/messages/`));
  if (!response.ok) {
    fetchCatch(response);
    throw response;
  }
  const messages = await response.json().then(fromJS);
  dispatch(setMessages(messages));
};

export const updateMessagesCall = next => async dispatch => {
  const response = await dispatch(backendFetchThunk(next));
  if (!response.ok) {
    fetchCatch(response);
    throw response;
  }
  const messages = await response.json().then(fromJS);
  dispatch(appendMessages(messages));
};

export const postMessageCall = (pk, formData) => async dispatch => {
  const response = await dispatch(apiFetchThunk(
    `/trips/${pk}/message/`,
    {
      method: 'POST',
      body: JSON.stringify(formData),
    },
  ));
  if (!response.ok) {
    fetchCatch(response);
    throw response;
  }
  const message = await response.json().then(fromJS);
  dispatch(pushMessage(message));
  return message;
};
