import { useCallback, useEffect, useState } from 'react';
import apiFetch from '../../core/fetch';
import captureException from '../../common/utils/captureException';
import type { AvailabilityBookingSetting } from '../types';
import type { DropdownOption } from '../../common/components/DropdownDS22';
import { useCalendarContext } from './useCalendar';
import { AVAILABLE_SETTINGS_MAP } from '../constants';

export const updateAvailabilityCall = (
  threadId: string,
  threadAvailability: AvailabilityBookingSetting,
) => (
  apiFetch('/calendar/events/set_availability_on_thread/', {
    method: 'POST',
    body: JSON.stringify({
      thread_id: threadId,
      block_type: threadAvailability,
    }),
  }).then(response => response.json()));

const useBoatAvailabilityDropdown = (
  threadId: string,
  threadAvailability: AvailabilityBookingSetting,
) => {
  const { updateBlockingEvent } = useCalendarContext();
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedOption, setSelectedOption] = useState(threadAvailability);
  useEffect(() => setSelectedOption(threadAvailability), [threadAvailability, threadId]);

  const selectAvailability = useCallback(async (selectedOptionObj: DropdownOption) => {
    setIsSelecting(true);
    const getSelectedOptionById = AVAILABLE_SETTINGS_MAP[
      selectedOptionObj?.id as keyof typeof AVAILABLE_SETTINGS_MAP
    ];
    try {
      const response = await updateAvailabilityCall(threadId, getSelectedOptionById);
      // Provides immediate feedback on the calendar that the event has been updated
      updateBlockingEvent(response);
      setSelectedOption(getSelectedOptionById);
      setIsSelecting(false);
    } catch (err) {
      setIsSelecting(false);
      captureException(err);
    }
  }, [threadId, updateBlockingEvent]);

  return {
    selectedOption,
    isSelecting,
    selectAvailability,
  };
};

export default useBoatAvailabilityDropdown;
