import React, { useEffect, useState } from 'react';
import captureException from 'src/common/utils/captureException';
import Button from '../Button';
import Icon from '../IconDS22';

export type CopyLinkProps = {
  /**
   * Label to be displayed on the button
   */
  buttonLabel: string;
  /**
   * Label to be displayed on the button after the link is copied
   */
  successLabel: string;
  /** The string that should be copied to the clipboard */
  textToCopy: string;
  /**
   * Callback function to be called when the link is copied successfully
   */
  onClick?: () => void;
  /**
   * Delay in milliseconds before the button label is reset to the original value
   */
  labelResetDelay?: number;
};

const CopyLink: React.FC<CopyLinkProps> = ({
  buttonLabel, successLabel, textToCopy, onClick, labelResetDelay = 3000,
}) => {
  const [label, setLabel] = useState(buttonLabel);

  useEffect(() => {
    setLabel(buttonLabel);
  }, [buttonLabel]);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setLabel(successLabel);
      setTimeout(() => setLabel(buttonLabel), labelResetDelay);

      if (onClick) {
        onClick();
      }
    } catch (err) {
      captureException('Failed to copy link to clipboard', err);
    }
  };

  return (
    <Button type="button" classNameModifier="secondary small" onClick={copyToClipboard}>
      <Icon id="link" />
      {label}
    </Button>
  );
};
export default CopyLink;
