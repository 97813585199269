import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { type AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import type { Location } from 'history';
import { Waypoint } from 'react-waypoint';
import moment from 'moment';

import type { ReduxState } from 'src/types/reduxState';
import type { ImmutableTrip } from 'src/types/trips/Trips';
import { useOffer } from 'src/inbox/hooks';
import { PATHS } from '../../../../common/constants';
import { scrollPageToBottom, unpackApiError } from '../../../../common/helpers';
import { useClientRect, usePrevious, useResizeObserver } from '../../../../common/hooks';
import { preserveSearch, siblingRoute } from '../../../../common/utils/routing';
import { usePubSubContext } from '../../../../pubsub/hook/usePubsub';
import {
  PAGES,
  INQUIRY_CANCELLED_STATES,
  MESSAGE_BLACKLIST_STATES,
  MOVED_STATES,
  OWNER_NEW_INQUIRY_STATES,
  RENTER_NEW_OFFER,
  TRIP_STATES,
  DATES_FIELDS,
  TIME_FIELDS,
  TRIP_LENGTH_FIELD,
} from '../../../constants';
import { allowMessages, offerModified, getOtherParty } from '../../../helpers';
import Message from '../../Message';
import SendMessageForm from '../../SendMessageForm';
import TripMenu from '../../TripMenu';
import { getMessagesCall, updateMessagesCall } from '../../../ducks/messages';
import CTA, { CTAButton, CTALink, CTAWrapper } from '../../CTA';
import MovedCTA from '../Moved/MovedCTA';
import TripPanel from '../../presentation/TripPanel';
import NotificationMessage from '../../../../common/components/NotificationMessage';
import { clearOffer } from '../../../ducks/offer';
import { requestOfferChanges, updateTrip } from '../../../ducks/trips';
import FormError from '../../../../common/components/FormError';
import UserTyping from '../../UserTyping';
import OfferError from '../../OfferError';

import s from './Messages.module.scss';

const getPlaceholder = (tripState: string) => {
  let placeholder = 'Type a message';

  if (OWNER_NEW_INQUIRY_STATES.includes(tripState)) {
    placeholder = 'Please add a price to continue';
  } else if (INQUIRY_CANCELLED_STATES.includes(tripState)) {
    placeholder = 'This inquiry has been canceled';
  }

  return placeholder;
};

type MessagesProps = {
  trip: ImmutableTrip;
  location: Location;
  match: {
    url: string;
  };
};

type MessageState = {
  error: string | null;
  submitting: boolean;
};

const Messages: React.FC<MessagesProps> = ({ location, match: { url }, trip }) => {
  const [error, setError] = useState<MessageState['error']>(null);
  const [submitting, setSubmitting] = useState<MessageState['submitting']>(false);
  const dispatch = useDispatch();
  const fetchMoreMessages = useCallback(
    (next: string | null) => dispatch(updateMessagesCall(next) as unknown as AnyAction),
    [dispatch],
  );
  const clearTheOffer = useCallback(
    () => dispatch(clearOffer()),
    [dispatch],
  );
  const requestTheChanges = useCallback(
    (pk: number, values) => dispatch(requestOfferChanges(pk, values) as unknown as AnyAction),
    [dispatch],
  );
  const getTheMessages = useCallback(
    (pk: number) => dispatch(getMessagesCall(pk) as unknown as AnyAction),
    [dispatch],
  );
  const { currentUser, offer, messages } = useSelector((state: ReduxState) => ({
    offer: state.offer,
    messages: state.messages,
    currentUser: state.user,
  }));
  const {
    hasErrors,
    handleCreateOfferClick,
  } = useOffer(trip, offer);
  const oldMessages: ReduxState['messages'] = usePrevious(messages); // Analog of prevProps
  const { isThreadTyping, seenThreadMessages } = usePubSubContext();

  // Scroll to the bottom (to the latest message) on mount
  useEffect(() => scrollPageToBottom(), []);

  useEffect(() => seenThreadMessages(`${trip.get('pk') ?? ''}`), [seenThreadMessages, trip]);

  // Reset unread messages when the trip status changes
  useEffect(() => {
    const updatedTrip = trip.update('has_unread_message', () => false);
    dispatch(updateTrip(updatedTrip));
  }, [dispatch, trip]);

  useEffect(() => {
    if (oldMessages) {
      const secondNewestMessage = oldMessages.get('results').first();
      const newestMessage = messages.get('results').first();
      if (secondNewestMessage?.get('id') !== newestMessage?.get('id')) {
        // This implies there is a new "newest message"
        scrollPageToBottom();
      }
    }
  }, [messages, oldMessages]);

  const requestChanges = useCallback(() => {
    setSubmitting(true);
    setError(null);
    requestTheChanges(trip.get('pk'), offer.toJS())
      .then(() => {
        getTheMessages(trip.get('pk'));
        clearTheOffer();
        setSubmitting(false);
      })
      .catch((response: Response) => unpackApiError(response))
      .then((errorObject: { detail: string }) => {
        clearTheOffer();
        setError(errorObject?.detail);
        setSubmitting(false);
      });
  }, [clearTheOffer, getTheMessages, offer, requestTheChanges, trip]);

  const offerIsModified = offerModified(
    offer,
    trip,
    [
      ...DATES_FIELDS,
      ...TIME_FIELDS,
      TRIP_LENGTH_FIELD,
    ],
  );
  const bookingPath = `${PATHS.INBOX}${trip.get('pk')}/`;
  const tripState = trip.getIn(['state', 'state']);
  const bookNowAvailable = RENTER_NEW_OFFER.includes(tripState);
  const requestedChanges = tripState === TRIP_STATES.OWNER_CHANGES_REQUESTED;
  const reviewBookingLink = preserveSearch(
    siblingRoute(location.pathname, PAGES.REVIEW_BOOKING),
    location,
  );
  const otherUser = useMemo(() => getOtherParty(trip), [trip]);
  const otherUserIsTyping = isThreadTyping(trip.get('pk'));
  const { element: formElement, rect: { height: initialHeight }, ref: formRef } = useClientRect();
  const [formElementHeight, setFormElementHeight] = useState(initialHeight);
  const resizeObserverCallback = useCallback(() => {
    setFormElementHeight(formElement?.getBoundingClientRect().height);
  }, [formElement]);
  useResizeObserver(formElement, resizeObserverCallback);
  useEffect(() => {
    if (formElementHeight) {
      scrollPageToBottom();
    }
  }, [formElementHeight]);
  const avatarBottomValue = useMemo(
    // 5px is the margin
    // 39px is the height of the root element of UserTyping component
    () => formElementHeight && (formElementHeight + 5 + (otherUserIsTyping ? 39 : 0)),
    [formElementHeight, otherUserIsTyping],
  );
  const renderMessageForm = !MESSAGE_BLACKLIST_STATES.includes(tripState);

  const cta = (
    <>
      {OWNER_NEW_INQUIRY_STATES.includes(tripState) && (
        <CTA
          classNameModifier="withSidebar"
          withWarning={hasErrors}
        >
          {hasErrors && (<OfferError trip={trip} />)}
          <CTAWrapper>
            <TripMenu trip={trip} />
            <CTALink
              to={preserveSearch(siblingRoute(url, PAGES.PRICE), location)}
              onClick={handleCreateOfferClick}
            >
              Create Offer
            </CTALink>
          </CTAWrapper>
        </CTA>
      )}
      {renderMessageForm && (
        <SendMessageForm
          tripId={trip.get('pk')}
          disabled={!allowMessages(tripState)}
          placeholder={getPlaceholder(tripState)}
          rootRef={formRef}
        >
          <UserTyping
            user={otherUser}
            tripId={trip.get('pk')}
          />
          {offerIsModified && (
            <div>
              <NotificationMessage
                classNameModifier="largerFontSize alignTop"
                outdent={false}
              >
                Your changes to this inquiry have not yet been sent. Go to {' '}
                <Link
                  to={preserveSearch(bookingPath, location)}
                  className={s.link}
                >
                  Trip Details
                </Link>
                {' '} to view these changes.
              </NotificationMessage>
              <div className={s.modifiedCtaWrapper}>
                <CTAButton
                  type="reset"
                  onClick={clearTheOffer}
                  disabled={submitting}
                  classNameModifier="gray"
                >
                  Clear Changes
                </CTAButton>
                <CTAButton
                  type="submit"
                  submitting={submitting}
                  onClick={requestChanges}
                >
                  Send Changes
                </CTAButton>
              </div>
            </div>
          )}

          {bookNowAvailable && !offerIsModified && (
            <div className={s.bookCtaErrorWrapper}>
              <FormError error={error} />
              <div className={s.bookNowWrapper}>
                <CTALink
                  to={reviewBookingLink}
                  classNameModifier="secondary"
                >
                  Book Now
                </CTALink>
              </div>
            </div>
          )}
          {requestedChanges && !offerIsModified && (
            <div className={s.ctaWrapper}>
              <TripMenu trip={trip} />
              <CTALink to={preserveSearch(siblingRoute(url, PAGES.PRICE), location)}>
                Update Offer
              </CTALink>
            </div>
          )}
        </SendMessageForm>
      )}
      {MOVED_STATES.includes(tripState) && (
        <MovedCTA
          archived={trip.get('archived')}
          location={location}
          trip={trip}
        />
      )}
    </>
  );

  let date: string | null = null;

  return (
    <TripPanel
      classNameModifier={renderMessageForm ? 'withMessageForm' : ''}
      backLocation={preserveSearch(PATHS.INBOX, location)}
      cta={cta}
      subheader={otherUser.get('first_name')}
      trip={trip}
      hasPendingTripChanges={offerIsModified}
    >
      {messages.get('next') && (
        <Waypoint
          onEnter={event => event && fetchMoreMessages(messages.get('next'))}
        />
      )}
      <ul className={s.list}>
        {messages.get('results').reverse().map((message, i) => {
          const isSameDay = moment(date).isSame(moment(message?.get('date_created')), 'day');
          date = message?.get('date_created') ?? null;
          return (
            <Message
              key={message?.get('id')}
              message={message}
              currentUser={currentUser}
              avatarStyle={{ bottom: avatarBottomValue }}
              showDate={i === 0 || message?.get('subject') !== 'Message' || !isSameDay}
            />
          );
        })}
      </ul>
    </TripPanel>
  );
};

export default Messages;
