/**
 * @param {string} name The name of the event
 * @param {Record<string, any} [payload] An object containing the event's payload
 * @param {boolean} [custom=false] Indicates that it is a non-standard event
 * @return void
 */
export function trackEventFacebook(name, payload, custom = false) {
  if (typeof window !== 'undefined' && window.fbq) {
    const trackPrefix = custom ? 'trackCustom' : 'track';
    window.fbq(trackPrefix, name, payload);
  }
}

export function trackPageViewFacebook() {
  if (typeof window !== 'undefined' && window.fbq) {
    window.fbq('track', 'PageView');
  }
}
