import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { parse, stringify } from 'query-string';

import { siblingRoute } from '../utils/routing';

/*
 * Some notes:
 *
 * I'm not married to this idea. This will reduce some duplication
 * across the various booking inquiry forms, and gives a slightly easier
 * handle on this logic.
 *
 * However, useCallback isn't really necessary - checking whether the props
 * are the same is probably a similar expense to creating that anonymous
 * function, and while we're at it, it also makes it harder to test. This
 * could also have been written with a function that takes
 * (nextPage, location, history) and does whatever it needs to do.
 * That function would be better-isolated and easier to test.
 *
 * I'm open to thoughts on this.
 */

const useOnSubmitLocationChange = (nextPage: string) => {
  const location = useLocation();
  const { search, pathname } = location;
  const { replace, push } = useHistory();
  const query = parse(search);
  const { nextPage: nextPageQuery, ...restQuery } = query;

  const nextPathname = siblingRoute(pathname, nextPageQuery?.toString() || nextPage);
  const action = nextPageQuery ? replace : push;

  return useCallback(
    (): void => action({ pathname: nextPathname, search: stringify(restQuery) }),
    [action, nextPathname, restQuery],
  );
};

export default useOnSubmitLocationChange;
