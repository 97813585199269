import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import OtpForm from '../../form/Otp';
import { ReduxState } from '../../../../types/reduxState';
import { PATHS } from '../../../../common/constants';
import { authRedirect, nextUrl } from '../../../../common/utils/routing';
import { MFAMethod } from '../../../../types/common/MFAMethods';

import s from './Otp.module.scss';

const titles = {
  totp: 'Multi-factor authentication',
  sms: 'Enter verification code',
  push: 'Push Notification',
};
const subtitleMessages = {
  totp: 'Open the multi-factor authenticator (TOTP) app on your mobile device to view your authentication code.',
  sms: 'The verification code has been sent to',
  push: 'Enter the two-factor authentication code received via PUSH notification',
};

const Otp: FC = () => {
  const { search, hash } = useLocation();
  const { push } = useHistory();
  const currentMFAMethod = useSelector<ReduxState, MFAMethod | null | undefined>(({ user }) => user.get('userMFAMethod', null));
  const currentMFAMethods = useSelector<ReduxState, MFAMethod[] | null | undefined>(({ user }) => user.get('userMFAMethods', null));
  const maskedNumber = useSelector<ReduxState, string | null | undefined>(({ user }) => user.get('maskedNumber', null));
  // Inter uses a typographic convention where +* is automatically styled as superscript
  // to mimic mathematical expressions. And because of this, we need to add a space between
  // the + and * characters to prevent the superscript styling.
  const formattedMaskedNumber = maskedNumber && maskedNumber.replace(/^(\+)(\*+)(\d+)$/, '$1 $2 $3');
  const nextPage = useCallback(
    () => {
      authRedirect(nextUrl(search, hash), push);
    },
    [hash, push, search],
  );
  const onSubmitSuccess = () => nextPage();

  useEffect(() => {
    if (!currentMFAMethod) {
      push(PATHS.LOGIN);
    }
  }, [currentMFAMethod, push]);

  return (
    <div className={s.container}>
      <Helmet>
        <title lang="en">{currentMFAMethod && titles[currentMFAMethod]}</title>
      </Helmet>
      <h1 className={s.title}>{currentMFAMethod && titles[currentMFAMethod]}</h1>
      { currentMFAMethod && (
        <>
          {currentMFAMethod !== 'sms' && (
            <p className={s.message}>
              {subtitleMessages[currentMFAMethod]}
            </p>
          )}
          {currentMFAMethod === 'sms' && formattedMaskedNumber && (
            <p className={s.message}>
              {`${subtitleMessages[currentMFAMethod]}`}
              <br />
              {`${formattedMaskedNumber}`}
            </p>
          )}
          <OtpForm
            currentMFAMethod={currentMFAMethod}
            onSubmitSuccess={onSubmitSuccess}
            showAlternativeMethodLink={!!currentMFAMethods && currentMFAMethods.length > 1}
          />
          <p className={s.footerLink}>
            Having trouble? <a href="mailto:support@getmyboat.com">Contact support</a>
          </p>
        </>
      ) }
    </div>
  );
};

export default Otp;
