import PropTypes from 'prop-types';
import React from 'react';

const TripMenuButton = ({
  children,
  className,
  disabled,
  onClick,
}) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={className}
  >
    {children}
  </button>
);

TripMenuButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TripMenuButton;
