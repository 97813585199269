import PropTypes from 'prop-types';
import React from 'react';

import { TITLE } from '../../constants';
import { getClassNameFor } from '../../helpers';
import s from './Logo.module.scss';

const LogoImage = ({ classNameModifier }) => (
  <svg className={s.image}>
    <title>
      {TITLE.PROJECT}
    </title>
    <use
      xlinkHref="#logo"
      className={getClassNameFor(s, 'icon', classNameModifier)}
    />
    <use
      xlinkHref="#text_logo"
      className={getClassNameFor(s, 'text', classNameModifier)}
    />
  </svg>
);

LogoImage.propTypes = {
  classNameModifier: PropTypes.string,
};

export default LogoImage;
