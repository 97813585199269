import React, { FC } from 'react';

import { Amounts } from 'src/types/common/Amounts';
import { DropdownOption } from 'src/common/components/DropdownDS22';
import useCustomOfferExpiry from '../../../common/hooks/useCustomOfferExpiry';
import CustomOfferExpiry from '../../../common/components/CustomOfferExpiry';
import Card from '../presentation/Card';
import TermsAndCancellation from '../TermsAndCancellation';
import { ImmutableTrip } from '../../../types/trips/Trips';
import CostBreakdown from '../CostBreakdown';

type RenterPriceSectionProps = {
  amounts: Amounts;
  trip: ImmutableTrip;
  onCurrencySelection?: (item: DropdownOption) => void;
};

const RenterPriceSection: FC<RenterPriceSectionProps> = ({
  amounts,
  trip,
  onCurrencySelection,
}) => {
  const listingUrl = trip.getIn(['boat', 'listing_url']);
  const { renderCustomExpiry } = useCustomOfferExpiry(trip);

  return (
    <>
      {amounts?.price_sections?.map((priceSection) => (
        <CostBreakdown
          key={priceSection.id}
          onCurrencySelection={onCurrencySelection}
          priceSection={priceSection}
          renterCurrency={amounts?.renter_currency}
        />
      ))}
      {renderCustomExpiry && (
        <Card classNameModifier="withZeroPadding">
          <CustomOfferExpiry
            trip={trip}
          />
        </Card>
      )}
      <Card>
        <TermsAndCancellation
          currency={amounts?.renter_currency}
          deposit={amounts?.booking_deposit}
          listingUrl={listingUrl}
          cancellationPolicy={amounts?.cancellation_policy}
        />
      </Card>
    </>
  );
};

export default RenterPriceSection;
