import React, { FC, useCallback } from 'react';
import {
  DayPicker as DayPickerComponent,
  DayContentProps,
  DayContent,
  DayModifiers,
  Matcher,
} from 'react-day-picker';
import classNames from 'classnames';

import { getClassNameFor } from '../../helpers';

import Svg from '../Svg';

import s from './ReactDayPicker.module.scss';
import { todayDate } from '../../utils/dateHelpers';

type ReactDayPickerProps = {
  mode?: 'single' | 'multiple' | 'range';
  defaultMonth?: Date;
  numberOfMonths?: number | undefined;
  selectedDays?: Date[] | [];
  fromDate?: Date;
  toDate?: Date;
  modifiers?: DayModifiers;
  disabled?: Matcher | Matcher[];
  onSelect: (days: Date | Date[] | []) => void;
  setSelectedDays?: (days: Date[] | []) => void;
  isSelectedDaysEmpty?: boolean;
  hasPastDates?: boolean;
  showClearAllButton?: boolean;
  withShadow?: boolean;
  classNameModifier?: string;
};

const CustomDayContent: FC<DayContentProps> = (props) => {
  const { blocked } = props.activeModifiers;
  return blocked
    ? (
      <div className={s.blockedDay}>
        <span className={s.blockedDayText}><DayContent {...props} /></span>
      </div>
    )
    : <DayContent {...props} />;
};

const ReactDayPicker: FC<ReactDayPickerProps> = ({
  mode = 'multiple',
  defaultMonth,
  numberOfMonths = 2,
  selectedDays = [],
  fromDate = new Date(),
  toDate,
  modifiers,
  onSelect,
  disabled,
  setSelectedDays = () => {},
  isSelectedDaysEmpty = false,
  hasPastDates = true,
  showClearAllButton = true,
  withShadow = true,
  classNameModifier = '',
}) => {
  const handleClearPastDates = useCallback(() => setSelectedDays(
    selectedDays.filter(d => d >= todayDate()),
  ), [selectedDays, setSelectedDays]);

  const handleClearAllSelected = () => setSelectedDays([]);

  return (
    <div className={getClassNameFor(s, 'root', classNameModifier)}>
      {(hasPastDates || showClearAllButton) && (
        <div className={s.actionButtons}>
          {hasPastDates && (
            <button
              type="button"
              className={s.clearPastAction}
              onClick={handleClearPastDates}
            >
              <Svg className={s.trashIcon} href="#trash-icon-with-arrow" />
              <span>Clear past dates</span>
            </button>
          )}
          {showClearAllButton && (
            <button
              type="button"
              className={getClassNameFor(s, 'clearSelectedAction', classNames({ isDisabled: isSelectedDaysEmpty }))}
              onClick={handleClearAllSelected}
              disabled={isSelectedDaysEmpty}
            >
              <Svg className={s.trashIcon} href="#trash-icon" />
              <span>Clear all selected</span>
            </button>
          )}
        </div>
      )}
      <div className={getClassNameFor(s, 'wrapper', classNames({ withShadow }))}>
        <DayPickerComponent
          className={s.dayPicker}
          mode={mode}
          defaultMonth={defaultMonth}
          numberOfMonths={numberOfMonths}
          selected={selectedDays}
          onSelect={onSelect}
          components={{ DayContent: CustomDayContent }}
          fromDate={fromDate}
          toDate={toDate}
          modifiers={modifiers}
          modifiersClassNames={{
            blocked: s.isBlocked,
            highlighted: s.isHighlighted,
          }}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default ReactDayPicker;
