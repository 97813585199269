import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { parse } from 'query-string';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import useDirectBookingApi from 'src/auth/hooks/useDirectBookingApi';
import LoadingSpinner from 'src/common/components/LoadingSpinner';
import { GuestUser, ImmutableUser } from 'src/types/user/User';
import useApproveCaptain from 'src/common/hooks/useApproveCaptain';
import { trackEvent } from 'src/common/tracking';
import { EXTERNAL_PATHS, PATHS } from '../../../../common/constants';
import { rffSubmitResponse } from '../../../../common/helpers';
import { registerCall } from '../../../../common/ducks/user';
import { nextUrl, authRedirect } from '../../../../common/utils/routing';
import NotFoundContent from '../../../../common/components/NotFoundContent';
import useGrecaptcha from '../../../hooks/useGrecaptcha';
import { Register, Agreement } from '../../pages';
import s from './RegisterForm.module.scss';

async function trackRegistration(user: ImmutableUser) {
  trackEvent('Signup', {
    event_category: 'auth',
  });
  if (typeof window !== 'undefined' && window.pintrk) {
    window.pintrk('track', 'signup');
  }
  return user;
}

const RegisterForm: FC = () => {
  const dispatch = useDispatch();
  const { push, goBack } = useHistory();
  const { search, hash } = useLocation();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { user_id } = parse(search);
  const { userDetails } = useApproveCaptain();
  const directBooking = useDirectBookingApi();

  const register = useCallback(
    (values: Record<string, string>) => dispatch(registerCall(values))
      .then(trackRegistration)
      .then((user: ImmutableUser & GuestUser) => {
        if (directBooking?.isDirectBooking) {
          return directBooking.checkoutRedirect();
        }

        if (directBooking?.isDirectBooking && user?.isGuest) {
          authRedirect(`${EXTERNAL_PATHS.DIRECT_BOOKING}confirmed-email/`, push);
        } else {
          authRedirect(nextUrl(search, hash), push);
        }
        return undefined;
      })
      .catch((response: Response) => (
        // This is janky, but this is to push the user back
        // to the previous page if the backend validation fails,
        // and to show the message there, rather than on the
        // agreement page.
        rffSubmitResponse()(response).then((errorObject) => {
          goBack();
          return errorObject;
        })
      )),
    [dispatch, push, goBack, search, hash, directBooking],
  );

  const onSubmit = useGrecaptcha('signup', register);

  const initialValues = useMemo(() => (directBooking.isDirectBooking
    ? directBooking.initialValues
    : {
      terms_agreed: true,
      user_id,
      ...userDetails,
    }), [directBooking.initialValues, directBooking.isDirectBooking, userDetails, user_id]);

  if (directBooking.isLoading) return <LoadingSpinner />;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, submitting, submitError, dirtySinceLastSubmit }) => (
        <form
          method="POST"
          onSubmit={handleSubmit}
          className={s.root}
        >
          <Switch>
            <Route
              exact
              path={PATHS.REGISTER}
              render={(props) => (
                <Register
                  {...props}
                  error={submitError || directBooking.errorMessage}
                  dirtySinceLastSubmit={dirtySinceLastSubmit}
                />
              )}
            />
            <Route
              exact
              path={PATHS.REGISTER_AGREEMENT}
              render={(props) => (
                <Agreement
                  {...props}
                  submitting={submitting}
                  isError={!!submitError || !!directBooking.errorMessage}
                />
              )}
            />
            <Route
              render={(props) => (
                <NotFoundContent
                  {...props}
                  classNameModifier="modal"
                />
              )}
            />
          </Switch>
        </form>
      )}
    </Form>
  );
};

export default RegisterForm;
