import React, { FC, ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Layout from 'src/common/components/Layout';
import Header from 'src/common/components/Header';
import CloseButton from 'src/common/components/CloseButton';
import useDirectBookingParam from 'src/auth/hooks/useDirectBookingParam';
import { PATHS, EXTERNAL_PATHS } from 'src/common/constants';
import { BackButton } from 'src/common/components/BackButton';
import s from './Auth.module.scss';

type AuthRootProps = {
  children?: ReactNode;
};

const AuthRoot: FC<AuthRootProps> = ({
  children,
}) => {
  const { directBookingId, isDirectBooking } = useDirectBookingParam();
  const location = useLocation();
  const { pathname } = location;
  const { goBack } = useHistory();

  let closeButtonURL = '/';
  if (isDirectBooking) {
    closeButtonURL = `${EXTERNAL_PATHS.DIRECT_BOOKING}${directBookingId}/`;
  }

  const classNameFromPath = pathname.split('/')
    // Get rid of empty route bits
    .filter(x => x.length !== 0)
    .pop();

  const mobileLogoPaths = ['login', 'register'];
  const withMobileLogo = mobileLogoPaths.includes(classNameFromPath || '');

  const classNameModifier = `auth modal with-blue-gradient-background ${classNameFromPath}`;
  return (
    <Layout classNameModifier={classNameModifier}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header
        withNav={false}
        withSearchBar={false}
        withMobileLogo={withMobileLogo}
        classNameModifier={classNameModifier}
      />
      <div className={s.modal}>
        {pathname === PATHS.LOGIN && (
          <CloseButton
            onClick={() => window.location.assign(closeButtonURL)}
          />
        )}
        {pathname !== PATHS.LOGIN && pathname !== PATHS.REGISTER && (
          <BackButton classNameModifier="auth" onClick={goBack} />
        )}
        {children || (
          <div className={s.container}>
            <h1 className={s.title}>Nothing Here</h1>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default AuthRoot;
