import React, { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { open, close } from '../../common/ducks/zippy';
import { unpackApiError, UnpackedApiError } from '../../common/helpers';
import { getTripCall } from '../../inbox/ducks/trips';
import { selectTrip } from '../../inbox/helpers';
import { EVENT_DETAILS_MODAL_NAME } from '../constants';
import { UseEventDetails } from '../types/UseEventDetails';
import { ImmutableTrips } from '../../types/trips/Trips';
import { ReduxState } from '../../types/reduxState';
import { CalendarEvent } from '../types';

const emptyEvent = null;

const useEventDetails = (): UseEventDetails => {
  const dispatch = useDispatch();
  const [event, setEvent] = useState<CalendarEvent | null>(emptyEvent);
  const [isPast, setIsPast] = useState(false);
  const trips = useSelector<ReduxState, ImmutableTrips>(state => state.trips);
  const openModal = useCallback(() => dispatch(open(EVENT_DETAILS_MODAL_NAME)), [dispatch]);
  const closeModal = useCallback(() => {
    setEvent(emptyEvent);
    dispatch(close(EVENT_DETAILS_MODAL_NAME));
  }, [dispatch]);
  const getTrip = useCallback(
    async (threadId: number) => dispatch(getTripCall(threadId)),
    [dispatch],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<UnpackedApiError | null>(null);
  const trip = selectTrip(trips, event?.thread_id!);
  const showEventDetails = useCallback(async (selectedEvent: CalendarEvent, pastEvent: boolean) => {
    setEvent(selectedEvent);
    setIsPast(pastEvent);
    const selectedTrip = selectTrip(trips, selectedEvent?.thread_id!);
    // Check if associated trip is present in the redux store
    if (selectedTrip.isEmpty()) {
      setIsLoading(true);
      getTrip(selectedEvent?.thread_id!)
        .then(() => setIsLoading(false))
        .catch(unpackApiError)
        .then(error => {
          setLoadingError(error ?? null);
          setIsLoading(false);
        });
    }
    openModal();
  }, [getTrip, openModal, trips]);

  return useMemo(() => ({
    event,
    trip,
    openModal,
    closeModal,
    isLoading,
    loadingError,
    showEventDetails,
    isPast,
  }), [
    event,
    trip,
    openModal,
    closeModal,
    isLoading,
    loadingError,
    showEventDetails,
    isPast,
  ]);
};

const noop = () => Promise.resolve();

const EventDetailsContext = createContext<UseEventDetails>({
  event: null,
  trip: null,
  openModal: noop,
  closeModal: noop,
  isLoading: false,
  loadingError: null,
  showEventDetails: noop,
  isPast: false,
});

export const useEventDetailsContext = () => useContext(EventDetailsContext);

export const EventDetailsProvider: FC = ({ children }) => (
  <EventDetailsContext.Provider value={useEventDetails()}>
    {children}
  </EventDetailsContext.Provider>
);

EventDetailsProvider.propTypes = {
  children: PropTypes.node,
};
