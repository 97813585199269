import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { OnFocus } from 'react-final-form-listeners';
import { Helmet } from 'react-helmet-async';

import { useHistory, useLocation } from 'react-router-dom';
import { trackEvent } from 'src/common/tracking';
import type { ReduxState } from '../../../../types/reduxState';
import { formatTime, getTimeFormat } from '../../../../common/utils/time';
import { rffDepartureTimeValidator as validate } from '../../../../common/validation';
import useOnSubmitLocationChange from '../../../../common/hooks/useOnSubmitLocationChange';
import { DATES, PAGES } from '../../../constants';
import { BackButton } from '../../../../common/components/BackButton';
import FormError from '../../../../common/components/FormError';
import { useTimeInput } from '../../../../common/hooks';
import { updateInquiry } from '../../../ducks/inquiry';
import TimeField from '../../../../common/components/fields/TimeField';
import CloseButton from '../../../../common/components/CloseButton';
import Button from '../../../../common/components/Button';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import s from './Time.module.scss';

const fieldName = 'pickup_time';

type PickupTimeValues = {
  [fieldName]: string;
};

const getPickupTimeInitialValues = ({ inquiry }: ReduxState): PickupTimeValues => ({
  [fieldName]: inquiry.getIn(['details', fieldName], ''),
});

const getPreferredDateValue = ({ inquiry }: ReduxState) => ({
  [DATES.PRIMARY]: inquiry.getIn(['details', DATES.PRIMARY], ''),
});

const getTimeZoneId = ({ boat }: ReduxState) => (
  boat.getIn(['timezone'], '')
);

const Time: FC = () => {
  const location = useLocation();
  const { goBack } = useHistory();
  const dispatch = useDispatch();
  const initialValues = useSelector(getPickupTimeInitialValues);
  const preferredDate = useSelector(getPreferredDateValue);
  const boatTimezoneId = useSelector(getTimeZoneId);

  // Location.search is for query string preservation
  const returnUrl = getReturnUrl(location);
  const { timeInputIsSupported } = useTimeInput();
  const onSubmitSuccess = useOnSubmitLocationChange(PAGES.GROUP);
  const onSubmit = (values: PickupTimeValues, { getState }) => {
    const { pickup_time: time } = values;
    trackEvent('send_inquiry_s4_departure_time', {
      p1: time,
    });
    if (getState().pristine) {
      onSubmitSuccess();
      return;
    }
    if (!values[fieldName]) {
      // Explicitly clear out the field if it's not defined
      // when the values are submitted
      dispatch(updateInquiry({ [fieldName]: undefined }));
    } else {
      dispatch(updateInquiry(values));
    }
    onSubmitSuccess();
  };
  return (
    <div className={s.container}>
      <Helmet>
        <title>
          Time
        </title>
      </Helmet>
      <h1 className={s.title}>Departure Time</h1>
      <div className={s.message}>
        <p>
          What time would you like to depart? You can skip this <br />
          step if you prefer the owner to suggest a time.
        </p>
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={(values) => validate({ ...values, ...preferredDate }, boatTimezoneId)}
      >
        {({
          handleSubmit,
          dirty,
          valid,
          error,
          dirtySinceLastSubmit,
          submitFailed,
          form: { change },
        }) => (
          <form onSubmit={handleSubmit} className={s.root}>
            <OnFocus
              name={fieldName}
            >
              {() => !dirty && !initialValues[fieldName] && change(
                fieldName,
                formatTime('09:00', getTimeFormat(timeInputIsSupported)),
              )}
            </OnFocus>
            <div className={s.fieldWrapper}>
              <TimeField
                valid={valid}
                name={fieldName}
                autoFocus={false}
              />
              <button
                className={s.clearButton}
                type="button"
                onClick={() => change(fieldName, undefined)}
              >
                Clear
              </button>
            </div>
            {error && submitFailed && !dirtySinceLastSubmit && (
              <FormError
                error={error}
                className={s.error}
              />
            )}
            <div className={s.actionGroup}>
              <Button
                type="submit"
                data-test={dirty ? 'next' : 'skip'}
                fullWidth
              >
                {dirty ? 'Next' : 'Skip'}
              </Button>
            </div>
          </form>
        )}
      </Form>
      <BackButton onClick={goBack} />
      <CloseButton backUrl={returnUrl} />
    </div>
  );
};

export default Time;
