import { fromJS } from 'immutable';
import { stringify } from 'query-string';

import { fetchCatch } from 'src/core/sentry';
import { apiFetchThunk, backendFetchThunk } from '../../common/ducks/fetch';
import { getTripStartDate } from '../helpers';

/**
 * @typedef {import('../../types/trips/Trips').ImmutableTrip} ImmutableTrip
 */

// app/reducer/action
export const SET_TRIPS = 'inbox/trips/SET_TRIPS';
export const APPEND_TRIPS = 'inbox/trips/APPEND_TRIPS';
export const REMOVE_TRIP = 'inbox/trips/REMOVE_TRIP';
export const UPDATE_TRIP = 'inbox/trips/UPDATE_TRIP';
export const CLEAR_TRIPS = 'inbox/trips/CLEAR_TRIPS';

export const setTrips = (trips, filter) => ({ type: SET_TRIPS, trips, filter });
export const updateTrip = trip => ({ type: UPDATE_TRIP, trip });
export const appendTrips = (trips, filter) => ({ type: APPEND_TRIPS, trips, filter });
export const removeTrip = tripId => ({ type: REMOVE_TRIP, tripId });
export const clearTrips = () => ({ type: CLEAR_TRIPS });

// Helper functions
const sortTripsByLastMessageDate = trips => trips
  .sort((tripA, tripB) => {
    const tripALastMessageDate = new Date(tripA.get('date_last_message')).getTime();
    const tripBLastMessageDate = new Date(tripB.get('date_last_message')).getTime();
    return tripALastMessageDate - tripBLastMessageDate || tripA.get('pk') - tripB.get('pk');
  })
  .reverse();

const sortTripsByTripDate = trips => trips
  .sortBy(trip => getTripStartDate({ trip }).getTime());

const sortTripsBy = ({ filter }) => {
  switch (filter) {
    case 'upcoming':
      return sortTripsByTripDate;
    default:
      return sortTripsByLastMessageDate;
  }
};

// Just for clarity's sake
const INITIAL_TRIPS_STATE = fromJS({
  results: [],
});

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (trips = INITIAL_TRIPS_STATE, action) => {
  switch (action.type) {
    case SET_TRIPS:
      return fromJS(action.trips)
        .update('results', sortTripsBy(action));
    case APPEND_TRIPS:
      return trips
      // TODO: also need to figure out if this will still work
        .update(
          'results',
          results => results
          // Get rid of trips that were previously in the list that are
          // also in the new results
            .filterNot(trip => action.trips
              .get('results')
              .find(newTrip => newTrip.get('pk') === trip.get('pk')))
            .concat(action.trips.get('results')),
        )
        .update('results', sortTripsBy(action))
      // Merge in all the things that aren't the results
        .merge(action.trips.delete('results'));
    case REMOVE_TRIP:
      return trips
        .update('results', results => results.filterNot(trip => trip.get('pk') === action.tripId));
    case UPDATE_TRIP: {
      const tripIndex = trips.get('results')
        .findIndex(trip => trip.get('pk') === action.trip.get('pk'));
      return tripIndex > -1 // trips list contains the trip
        // update that trip
        ? trips.mergeIn(['results', tripIndex], action.trip)
          .update('results', sortTripsBy(action))
        // Append new trip to the end of trips list otherwise
        : trips.setIn(['results', trips.get('results').size], action.trip)
          .update('results', sortTripsBy(action));
    }
    case CLEAR_TRIPS:
      return INITIAL_TRIPS_STATE;
    default:
      return trips;
  }
};

const tripsApiBaseUrl = '/trips/';

// Get should use append - the only time we want to blow away trips is when we're switching filters.
export const getTripsCall = ({ filter, nextUrl }) => async dispatch => {
  const url = `${tripsApiBaseUrl}?${stringify({ filter })}`;
  const response = await (
    nextUrl ? dispatch(backendFetchThunk(nextUrl)) : dispatch(apiFetchThunk(url))
  );
  if (!response.ok) {
    throw response;
  }
  const trips = await response.json();
  dispatch(appendTrips(fromJS(trips), filter));
};

// This uses setTrips because we want trips to be blown away when the filter
// changes.
export const getNewFilterSetCall = ({ filter }) => async dispatch => {
  const url = `${tripsApiBaseUrl}?${stringify({ filter })}`;
  const response = await dispatch(apiFetchThunk(url));
  if (!response.ok) {
    throw response;
  }
  const trips = await response.json();
  dispatch(setTrips(trips, filter));
  return trips;
};

// Handles all of the wrapping logic around network handling and merging
// a returned trip into the store
const tripUpdateWrapper = fetchCreator => (...args) => (
  dispatch => (
    dispatch(fetchCreator(...args))
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(tripObject => {
        const trip = fromJS(tripObject);
        dispatch(updateTrip(trip));
        return trip;
      })
  )
);

/**
 * @type {(pk: number | string, queryParams?: Record<string, any>) => Promise<ImmutableTrip>  }
 */
export const getTripCall = tripUpdateWrapper(
  (pk, queryParams) => apiFetchThunk(
    `/trips/${pk}/?${stringify(queryParams)}`,
  ),
);

/**
 * @type {(pk: number | string, formData: Record<string, any>) => Promise<ImmutableTrip>  }
 */
export const cancelTripCall = tripUpdateWrapper(
  (pk, formData) => apiFetchThunk(
    `/trips/${pk}/cancel/`,
    {
      method: 'PATCH',
      body: JSON.stringify(formData),
    },
  ),
);

/**
 * @type {(pk: number | string, values: Record<string, any>) => Promise<ImmutableTrip>  }
 */
export const sendOfferCall = tripUpdateWrapper(
  (pk, values) => apiFetchThunk(
    `/trips/${pk}/offer/`,
    {
      method: 'POST',
      body: JSON.stringify(values),
    },
  ),
);

/**
 * @type {(pk: number | string, values: Record<string, any>) => Promise<unknown>  }
 */
export const cancelOfferCall = tripUpdateWrapper(
  (pk, values) => apiFetchThunk(
    `/trips/${pk}/offer/`,
    {
      method: 'DELETE',
      body: JSON.stringify(values),
    },
  ),
);

/**
 * @type {(pk: number | string, values: Record<string, any>) => Promise<ImmutableTrip>  }
 */
export const requestOfferChanges = tripUpdateWrapper(
  (pk, values) => apiFetchThunk(
    `/trips/${pk}/request-changes/`,
    {
      method: 'POST',
      body: JSON.stringify(values),
    },
  ),
);

/**
 * @type {(pk: number | string) => Promise<unknown>  }
 */
export const cancelRequestedChangesCall = tripUpdateWrapper(
  pk => apiFetchThunk(
    `/trips/${pk}/request-changes/`,
    {
      method: 'DELETE',
    },
  ),
);

/**
 * @type {(pk: number | string, values: Record<string, any>) => Promise<ImmutableTrip>  }
 */
export const declineInquiryCall = tripUpdateWrapper(
  (pk, values) => apiFetchThunk(
    `/trips/${pk}/decline/`,
    {
      method: 'PATCH',
      body: JSON.stringify(values),
    },
  ),
);

/**
 * @type {(pk: number | string, values: Record<string, any>) => Promise<ImmutableTrip>  }
 */
export const cancelReservationCall = tripUpdateWrapper(
  (pk, values) => (
    apiFetchThunk(
      `/trips/${pk}/cancel-confirmed/`,
      {
        method: 'PATCH',
        body: JSON.stringify(values),
      },
    )
  ),
);

/**
 * @typedef {import('redux').Dispatch} Dispatch
 * @typedef {(tripId: number) => (dispatch: Dispatch) => Promise<ImmutableTrip>} Archiver
 * @type {(action: 'archive' | 'unarchive') => Archiver}
 */
export const tripArchiver = action => tripId => dispatch => dispatch(apiFetchThunk(
  `/trips/${tripId}/${action}/`,
  { method: 'PATCH' },
))
  .then(response => {
    if (!response.ok) {
      throw response;
    }
    return response.json();
  })
// Is it going to work to just remove it from the view?
  .then(() => dispatch(removeTrip(tripId)))
  .catch(fetchCatch);

export const archiveTripCall = tripArchiver('archive');
export const unarchiveTripCall = tripArchiver('unarchive');
