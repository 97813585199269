import React from 'react';

import s from './UnpublishedListingSeparator.module.scss';

const UnpublishedListingSeparator = () => (
  <li className={s.root}>
    <div className={s.item}>
      <span className={s.label}>
        Unpublished Listings
      </span>
    </div>
  </li>
);

export default UnpublishedListingSeparator;
