import React, { FC } from 'react';

import { getClassNameFor } from '../../helpers';
import Svg from '../Svg';
import s from './StarIcon.module.scss';

type StarIconProps = {
  classNameModifier?: string;
};

const StarIcon: FC<StarIconProps> = ({ classNameModifier }) => (
  <Svg
    href="#old-star"
    className={getClassNameFor(s, 'root', classNameModifier)}
    data-test="star"
  />
);

export default StarIcon;
