import React from 'react';

type TechnicalIssuePageProps = {
  staticContext?: {
    fiveHundredError?: true;
  };
};

const TechnicalIssuePage = ({ staticContext }: TechnicalIssuePageProps) => {
  if (staticContext) {
    // eslint-disable-next-line no-param-reassign
    staticContext.fiveHundredError = true;
  }
  return <div>Technical issues</div>;
};

export default TechnicalIssuePage;
