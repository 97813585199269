import React, { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';

import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import type { ImmutableSearchBoatDetails } from '../../../../types/boat/BoatDetail';
import type { ReduxState } from '../../../../types/reduxState';
import { getBoat } from '../../../../common/utils/reduxStoreSelectors';
import { rffGroupSizeValidator as validate } from '../../../../common/validation';
import GroupSizeFieldset from '../../../../common/components/fieldsets/GroupSize';
import { PAGES } from '../../../constants';
import { trackEvent } from '../../../../common/tracking';
import FormError from '../../../../common/components/FormError';
import Button from '../../../../common/components/Button';
import InPageWarning from '../../InPageWarning';
import { updateInquiry, setInquiryMismatch, deleteInquiryMismatch } from '../../../ducks/inquiry';
import useOnSubmitLocationChange from '../../../../common/hooks/useOnSubmitLocationChange';
import { BackButton } from '../../../../common/components/BackButton';
import CloseButton from '../../../../common/components/CloseButton';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import s from './GroupSize.module.scss';

const getNextPage = (boat: ImmutableSearchBoatDetails) => {
  const captained = boat.get('captained');
  const bareboat = boat.get('bareboat');

  if (bareboat === captained) {
    // This means there is ambiguity regarding this boat's options --
    // it can either be captained or bareboat'd, so we provide the option to choose.
    return PAGES.CAPTAIN;
  }

  if (bareboat) {
    // If the boat is only bareboat'able we need to ask for the user's boating creds
    // because it means they are the captain now.
    return PAGES.EXPERIENCE;
  }

  // If the boat is just captained we don't need more info, just proceed to extras.
  return PAGES.EXTRAS;
};

const getCapacityInitialValues = ({ inquiry }: ReduxState) => ({
  adults: inquiry.getIn(['details', 'adults'], 0),
  seniors: inquiry.getIn(['details', 'seniors'], 0),
  children: inquiry.getIn(['details', 'children'], 0),
  infants: inquiry.getIn(['details', 'infants'], 0),
});

const GroupSize: FC = () => {
  const location = useLocation();
  const { goBack } = useHistory();
  const dispatch = useDispatch();
  const boat = useSelector(getBoat);
  const initialValues = useSelector(getCapacityInitialValues);
  const returnUrl = getReturnUrl(location);
  const boatCapacity = boat.get('capacity', 0);

  const onSubmitSuccess = useOnSubmitLocationChange(getNextPage(boat));
  const errMessage = `This listing you selected has a maximum capacity of ${boatCapacity}.`;
  let total = 0;

  const onSubmit = useCallback(
    (values: Record<string, number>) => {
      dispatch(updateInquiry(values));
      trackEvent('send_inquiry_s5_group_size', {
        p1: total,
      });
      if (total > boatCapacity) {
        trackEvent('Continue Past SBI Warning', {
          event_category: 'Booking Inquiry',
          event_label: 'for Group Size',
        });
        dispatch(setInquiryMismatch({ capacity: errMessage }));
      } else {
        dispatch(deleteInquiryMismatch('capacity'));
      }
      return onSubmitSuccess();
    },
    [dispatch, onSubmitSuccess, errMessage, boatCapacity, total],
  );
  const title = 'Group Size';

  return (
    <div className={s.container}>
      <Helmet>
        <title>
          {title}
        </title>
      </Helmet>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting, error, values, touched }) => {
          total = Object.values(values).filter(x => !!x).reduce((a, b) => a + b, 0);
          const overCapacity = (boatCapacity && total > boatCapacity);
          const anyTouched = !!touched && Object.values(touched).some(x => x);
          return (
            <form
              onSubmit={handleSubmit}
              className={s.root}
            >
              <h1 className={s.title}>
                {title}
              </h1>
              <GroupSizeFieldset total={total} />
              {anyTouched && error && <FormError error={error} className={s.error} />}
              {overCapacity && <InPageWarning message={errMessage} />}
              <div className={s.actionGroup}>
                <Button
                  type="submit"
                  submitting={submitting}
                  data-test="next"
                  fullWidth
                >
                  {overCapacity ? 'Continue Anyway' : 'Next'}
                </Button>
              </div>
            </form>
          );
        }}
      </Form>
      <BackButton onClick={goBack} />
      <CloseButton backUrl={returnUrl} />
    </div>
  );
};

export default GroupSize;
