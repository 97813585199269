import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { INSTABOOK_WARNINGS, INSTABOOK_EDITOR_MODAL_NAME as modalName } from '../../constants';
import { getClassNameFor } from '../../../common/helpers';
import { open } from '../../../common/ducks/zippy';
import { getTimeToBook, parseTripDuration } from '../../instabook';
import { useInstabookEditorContext } from '../../hooks';
import useCalendarFocus from '../../hooks/useCalendarFocus';

import { BoatDetail } from '../../../types/boat/BoatDetail';
import { InstabookEvent, InstabookWarning } from '../../types';

import Svg from '../../../common/components/Svg';
import InstabookCardCaptained from '../InstabookCardCaptained';
import InstabookCardTimes from '../InstabookCardTimes';
import InstabookCardStatus from '../InstabookCardStatus';

import s from './InstabookCalendarCard.module.scss';
import { completePriceFormatter } from '../../../common/utils/currency';
import { DEFAULT_CURRENCY, getCurrencyByCode } from '../../../common/currencies';
import { ReduxState } from '../../../types/reduxState';

type MinimalInstabookEventKeys =
| 'id'
| 'boat_id'
| 'published'
| 'captain_conflict'
| 'date'
| 'currency'
| 'captained'
| 'captain_cost_included'
| 'subtotal'
| 'trip_length'
| 'instabook_trip_times'
| 'date_UTC'
| 'expiry_UTC'
| 'instabook_trip_dates'
| 'total_date_count';

type MinimalInstabookEvent = Pick<InstabookEvent, MinimalInstabookEventKeys>;
type MinimalBoatDetail = Pick<BoatDetail, 'active' | 'id'>;

export type InstabookCardProps = {
  event: MinimalInstabookEvent;
  // eslint-disable-next-line react/no-unused-prop-types
  boat: MinimalBoatDetail;
};

const InstabookCalendarCard: FC<InstabookCardProps> = ({ event }) => {
  const displayCurrencies = useSelector(({ currencies }: ReduxState) => (currencies));
  const { loadInstabook, setInstabookListing } = useInstabookEditorContext();
  const timeToBook = useMemo(
    () => getTimeToBook(event.date_UTC, event.expiry_UTC),
    [event.date_UTC, event.expiry_UTC],
  );
  const dispatch = useDispatch();
  /**
   * INFO: Instabook Warnings are removed temporarily due to initial optimization
   * for the Instabook Calendar.
   *
  */
  const warning: InstabookWarning = undefined;
  const currency = getCurrencyByCode(event.currency, displayCurrencies) ?? DEFAULT_CURRENCY;

  const price = completePriceFormatter(event.subtotal, currency.code, currency.symbol);
  const tripLength = parseTripDuration(event.trip_length);
  const title = `${tripLength} ${tripLength > 1 ? 'Hours' : 'Hour'} - ${price}`;
  const disabled = useMemo(
    () => !event.published
      || (!!warning && warning !== INSTABOOK_WARNINGS.PARTIALLY_UNAVAILABLE_TIMES),
    [event.published, warning],
  );

  const { focusedInstabookId, multiInstabookIds } = useCalendarFocus();
  const isFocusedEvent = focusedInstabookId === event.id;
  const isMultiInstabook = multiInstabookIds?.includes(event.id);

  const load = useCallback(() => {
    loadInstabook(event.id);
    setInstabookListing(event.boat_id);
    dispatch(open(modalName));
  }, [event.id, event.boat_id, loadInstabook, setInstabookListing, dispatch]);

  return (
    <button
      type="button"
      className={classNames(
        getClassNameFor(s, 'root', classNames({ disabled, isMultiInstabook })),
        {
          focusedEvent: isFocusedEvent,
        },
      )}
      onClick={load}
    >
      <div className={s.main}>
        <div className={s.header}>
          <h4 className={s.title}>{title}</h4>
          {event.captain_conflict && (
            <Svg
              href="#exclamation-icon"
              className={s.icon}
            />
          )}
        </div>
        <InstabookCardTimes
          duration={event.trip_length}
          tripTimes={event.instabook_trip_times}
          warning={warning}
        />
        <div className={s.footer}>
          <InstabookCardCaptained
            captained={event.captained}
            captainCostIncluded={event.captain_cost_included}
            conflict={event.captain_conflict}
          />
          <InstabookCardStatus
            published={event.published}
            warning={warning}
            disabled={disabled}
            timeToBook={timeToBook}
          />
        </div>
      </div>
      {event.total_date_count && event.total_date_count > 1 && (
        <div className={s.tripDates}>{event.total_date_count} Dates</div>
      )}
    </button>
  );
};

export default InstabookCalendarCard;
