import { Map } from 'immutable';
import type { ThunkAction } from 'redux-thunk';
import type { Action } from 'redux';
import { fetchEntity } from './fetch';

import type { ImmutableBoat, ImmutableSearchBoatDetails } from '../../types/boat/BoatDetail';
import type { ReduxState } from '../../types/reduxState';

// Actions
// -----------------------------------------------------------------------------
export const SET_BOAT = 'common/boat/SET_BOAT';

// Action creators
// -----------------------------------------------------------------------------
export const setBoat = (boat: ImmutableBoat) => ({ type: SET_BOAT, boat } as const);

type FetchBoatThunk = ThunkAction<Promise<ImmutableSearchBoatDetails>, ReduxState, {}, Action>;
export const fetchBoat = (boatId: string, currency?: string): FetchBoatThunk => (
  dispatch => {
    const base = `/search/v1/boats/${boatId}/?strip_tags=true`;
    const route = currency ? `${base}&currency=${currency}` : base;
    return dispatch(fetchEntity(route, setBoat));
  }
);

type ReducerActions = ReturnType<typeof setBoat>;

// Reducers
// -----------------------------------------------------------------------------
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (boat: ImmutableSearchBoatDetails = Map(), action: ReducerActions) => {
  switch (action.type) {
    case SET_BOAT:
      return action.boat;
    default:
      return boat;
  }
};
