import React, { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from 'src/common/helpers';
import Icon from 'src/common/components/IconDS22';
import { Theme, Variant, themes, variants } from './types';
import styles from './CostBreakdown.module.scss';

type DetailsToggleEvent = React.MouseEvent<HTMLDetailsElement, MouseEvent>;

export type CostDetailsProps = {
  /** Formatted amount e.g. `$955.00` */
  amount: string;
  children: React.ReactNode;
  disableCollapse?: boolean;
  open?: boolean;
  theme?: Theme;
  title: string;
  variant?: Variant;
};

const CostDetails:FC<CostDetailsProps> = ({
  amount,
  children,
  disableCollapse = false,
  open: initiallyOpen = true,
  theme = themes[0],
  title,
  variant = variants[0],
}) => {
  const [open, setOpen] = useState(initiallyOpen);
  const toggleHandler = useCallback((event: DetailsToggleEvent) => {
    setOpen(event.currentTarget.open);
  }, []);

  useEffect(() => {
    setOpen(initiallyOpen);
  }, [initiallyOpen]);

  return (
    <details
      className={getClassNameFor(styles, 'root', classNames(theme, variant, { disableCollapse }))}
      open={open}
      onToggle={toggleHandler}
    >
      <summary
        className={getClassNameFor(styles, 'summary', classNames(variant, { disableCollapse }))}
        tabIndex={disableCollapse ? -1 : 0}
      >
        <span className={getClassNameFor(styles, 'priceLabel', variant)}>
          {title}
        </span>
        <span
          className={styles.priceValue}
          data-test={`${title}-summary-price`}
        >
          {amount}
          <Icon
            id={open ? 'caret-up' : 'caret-down'}
            size="m"
          />
        </span>
      </summary>
      <div className={getClassNameFor(styles, 'content', variant)}>
        {children}
      </div>
    </details>
  );
};

export default CostDetails;
