import { SessionData } from '../types';

const getSessionData = (returnUrl: string): SessionData | null => {
  if (typeof window === 'undefined') return null;

  const sessionKey = returnUrl.split('sessionKey=')[1];
  if (!sessionKey) return null;

  const sessionData: SessionData = JSON.parse(sessionStorage.getItem(sessionKey) as string);
  return sessionData;
};

export default getSessionData;
