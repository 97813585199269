import isDarkCode from '../utils/isDarkCode';

export type GtagConfig =
  | Gtag.CustomParams
  | Gtag.ControlParams
  | Gtag.EventParams
  | Gtag.ConfigParams;

// initialize window.dataLayer if not already initialized
if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
}

const dataLayer = (typeof window !== 'undefined') ? window.dataLayer : [];

// Set up the function that does the pushing
const gtag: Gtag.Gtag = function gtag() {
  // eslint-disable-next-line prefer-rest-params
  dataLayer.push(arguments);
};

// We do this because this is how it's set up in the gtag setup docs
if (typeof window !== 'undefined') {
  window.gtag = gtag;
}

export const setupGtag = () => {
  // Do initial setup
  gtag('js', new Date());

  // Set up the AdWords conversion tracking on production only
  if (!isDarkCode()) {
    gtag('config', 'AW-991706362');
  }
};

/**
 * wrapper func for `gtag('event', eventName, parameters)` using the GA4 Event structure
 * @param eventName event type
 * @param config event parameters (custom params)
 */
export const gtag4Event = (eventName: string, config: GtagConfig = {}): void => {
  // @ts-ignore because we don't specify amount of parameters for the gtag function
  gtag('event', eventName, { ...config });
};

export default gtag;
