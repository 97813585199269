import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import { captureException } from '@sentry/browser';
import { createStore, set } from 'idb-keyval';

const trackAhoyNotification = async value => {
  // Email notifications contain a combined string:
  // ?notification_id=<notification-id>-<message-id>
  if (value.length === 73) {
    const ahoyId = value.substring(0, 36);
    const ahoyMsgId = value.substring(37);
    const store = createStore('stream-unseen', 'unseen-notifications');
    set(`unseen_${ahoyId}`, { ahoyId, ahoyMsgId }, store).catch(captureException);
  }
};

const useAhoyNotificationTracking = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const queryParams = parse(location.search);
    const notificationId = queryParams.notification_id;

    if (notificationId) {
      queryParams.notification_id = undefined;
      history.replace({
        search: stringify(queryParams),
      });
      trackAhoyNotification(notificationId);
    }
  }, [history, location.search]);
};

export default useAhoyNotificationTracking;
