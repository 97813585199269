import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { fromJS } from 'immutable';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import 'svgxuse';
import { UserAgentProvider } from '@quentin-sommer/react-useragent';
import { FacebookProvider } from 'react-facebook';
import { HelmetProvider } from 'react-helmet-async';
import { configure as configureReactHotkeys } from 'react-hotkeys';

import initSentry, { setRequestId } from './client-sentry';
import Routes from './routes';
import createBrowserLogger from './common/logger/browserLogger';
import configureStore from './store';
import { SiftScience } from './common/siftscience';
import { trackPageViewFacebook } from './common/tracking/facebook';
import { PubSubProvider } from './pubsub/hook/usePubsub';
import AllowedHostProvider from './common/components/AllowedHostsProvider';
import type { ReduxState } from './types/reduxState';

// Init Sentry as soon as possible in the app, to make sure it can monitor everything.
initSentry();

// Get a hook on history
const history = createBrowserHistory();

// Global configuration for react-hotkeys. Namely telling
// it to listen to events from inputs.
configureReactHotkeys({
  ignoreTags: [],
  // To be honest, I have no idea what a simulated keypress event is
  // or why it exists. However, if this is left as its default (true),
  // the send message form ignores you after "enter" is pressed.
  simulateMissingKeyPressEvents: false,
});

// Set up SiftScience
SiftScience.initialize(__SIFTSCIENCE_SNIPPET_KEY__);

// attach tracking listeners
history.listen(() => {
  // Tracking pageviews should be done on every location change.

  // SiftScience
  SiftScience.push(['_trackPageview']);

  // Facebook
  trackPageViewFacebook();

  // Pinterest
  if (typeof window !== 'undefined' && window.pintrk) {
    window.pintrk('track', 'pagevisit');
  }
});

/**
 * Conditionally wraps the redux state in immutable data structures.
 */
const maybeConvertToImmutable = (
  key: string,
  state: Record<string, unknown>,
): unknown => {
  const immutableKeys = [
    'boat',
    'calendar',
    'fetch',
    'inquiry',
    'loading',
    'messages',
    'offer',
    'price',
    'reviews',
    'transaction',
    'trips',
    'user',
    'zippies',
  ];

  return immutableKeys.includes(key) ? fromJS(state[key]) : state[key];
};

/**
 * This is to hack around the fact that our store structure has a top-level
 * POJO, but some child values are immutable objects.
 */
const immutableizeJSState = (state: Record<string, unknown>): Partial<ReduxState> => (
  Object.keys(state)
    .reduce((newState, key) => {
      // eslint-disable-next-line no-param-reassign
      newState[key] = maybeConvertToImmutable(key, state);
      return newState;
    }, {} as Partial<ReduxState>)
);

// This extracts server encoded state and populates the redux store.
const APP_STATE = JSON.parse(document.querySelector('#APP_STATE')?.textContent ?? '{}');
const store = configureStore(immutableizeJSState(APP_STATE || {}), createBrowserLogger);
setRequestId(store.getState().requestId);

// if (__DEV__) {
//   import('src/core/immutableDebugger').then(mod => mod.default(store));
// }

const App = () => (
  <Provider store={store}>
    <StrictMode>
      <Router history={history}>
        <HelmetProvider>
          <UserAgentProvider ua={window.navigator.userAgent}>
            <FacebookProvider appId={__FB_APP_ID__} wait>
              <PubSubProvider>
                <AllowedHostProvider>
                  <Routes />
                </AllowedHostProvider>
              </PubSubProvider>
            </FacebookProvider>
          </UserAgentProvider>
        </HelmetProvider>
      </Router>
    </StrictMode>
  </Provider>
);

render(<App />, document.getElementById('app'));
