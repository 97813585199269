/* eslint-disable import/prefer-default-export */
type Time = { formattedTime: string; days: number };

export const normalizeTime = (value: string = ''): Time => {
  const [originalHours = 0, originalMinutes = 0] = value.split(':');
  const roundedMinutes = (Math.round(Number(originalMinutes) / 15) * 15);
  const roundedHours = Math.floor(roundedMinutes / 60) + Number(originalHours);
  const days = Math.floor(roundedHours / 24);
  const hours = `${roundedHours % 24}`;
  const minutes = `${roundedMinutes % 60}`;
  return {
    formattedTime: `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:00`,
    days,
  };
};
