import React, { FC, useEffect, useRef } from 'react';
import { formatDistanceToNowStrict, formatISO, isAfter } from 'date-fns';
import { NavLink, useLocation } from 'react-router-dom';
import { Map } from 'immutable';
import classNames from 'classnames';

import { ImmutableTrip } from 'src/types/trips/Trips';
import { ImmutableUser } from 'src/types/user/User';
import { PATHS } from 'src/common/constants';
import { getClassNameFor } from 'src/common/helpers';
import useCustomOfferExpiry from 'src/common/hooks/useCustomOfferExpiry';
import { isUserSuspended, isUserBanned, userIsRenter } from 'src/inbox/helpers';
import { preserveSearch } from 'src/common/utils/routing';
import Avatar from 'src/common/components/Avatar';
import ListingPhoto from 'src/common/components/presentation/ListingPhoto';
import TripStateIndicatorInbox from 'src/common/components/TripStateIndicatorInbox';
import { usePubSubContext } from 'src/pubsub/hook/usePubsub';
import TripCardDetails from '../TripCardDetails';
import s from './Trip.module.scss';

import TypingIndicator from '../TypingIndicator';

type Props = {
  recipient: ImmutableUser;
  trip: ImmutableTrip;
  active: boolean;
};

const Trip: FC<Props> = ({ active, recipient, trip }) => {
  const { getUserPresence, isThreadTyping } = usePubSubContext();
  const recipientIsOnline = getUserPresence(recipient.get('id') ?? 0);
  const tripRef = useRef<HTMLLIElement>(null);
  const location = useLocation();
  const tripState = trip.get('state', Map()).toJS();
  const lastModifiedDate = new Date(trip.get('date_last_message'));
  const tripId = trip.get('pk');
  const unread = trip.get('has_unread_message');
  const messagePath = unread && !trip.get('has_unseen_state_change') ? '/messages' : '';
  const unreadIndicator = unread || trip.get('has_unseen_state_change');
  const userBanned = isUserBanned(trip);
  const tripDisabled = userBanned || isUserSuspended(trip);
  const isRenter = userIsRenter(trip) && !tripDisabled;
  const photoUrl = trip.getIn(['boat', 'photo_url']);
  const headline = trip.getIn(['boat', 'headline']);
  const {
    confirmationPeriod,
    expirationDateInBrowserTimezone,
    offerExpired,
    renderCustomExpiry,
  } = useCustomOfferExpiry(trip);
  // This is a workaround for the case when backend doesn't update
  // `date_last_message` on expiration of custom offer expiry date
  const latestModificationDate = offerExpired
    // Use expirationDate only if it is the newest one.
    && isAfter(expirationDateInBrowserTimezone, lastModifiedDate)
    ? expirationDateInBrowserTimezone
    : lastModifiedDate;

  useEffect(() => {
    const currentRef = tripRef.current;
    const isVisibleInSidebar = () => {
      const tripElementRect = currentRef?.getBoundingClientRect();
      const tripTopOffset = tripElementRect?.top ?? 0;
      const sidebarRect = currentRef?.offsetParent?.getBoundingClientRect() || {
        height: 0,
        top: 0,
      };
      return tripTopOffset >= sidebarRect.top && tripTopOffset <= sidebarRect.height;
    };

    if (active && currentRef && !isVisibleInSidebar()) {
      currentRef.scrollIntoView(false);
    }
  }, [active, tripRef]);

  return (
    <li
      title={__DEV__ ? JSON.stringify(tripState) : undefined}
      className={s.root}
      ref={tripRef}
    >
      <NavLink
        // TODO: rework this. This approach requires the developer
        // to remember to use this function every time they create an
        // intra-inbox link here. Perhaps make new components?
        // We use components that render links that are not gonna
        // be happy with injection. Hmm.
        to={preserveSearch(`${PATHS.INBOX}${tripId}${messagePath}/`, location)}
        className={getClassNameFor(s, 'link', classNames({ disabled: tripDisabled }))}
        activeClassName={s.link_active}
        data-test="TripCard"
      >
        {isRenter && (
          <ListingPhoto
            photoUrl={photoUrl}
            headline={headline}
            offset={2000}
            classNameModifier="trip"
          />
        )}
        <div className={getClassNameFor(s, 'wrapper', classNames({ isRenter }))}>
          {!tripDisabled && (
            <Avatar
              user={recipient.toJS()}
              size="medium"
              offset={2000}
              classNameModifier="noMargin"
            />
          )}
          <TripCardDetails
            disabled={tripDisabled}
            disabledString={`Conversation ${userBanned ? 'removed' : 'on hold'}.`}
            trip={trip}
            name={recipient.get('first_name', '')}
            classNameModifier={isRenter ? 'renter' : ''}
            recipientIsOnline={recipientIsOnline}
            isOwner={!isRenter}
          >
            {renderCustomExpiry && !offerExpired && isRenter && (
              <div className={s.validFor}>Valid for {confirmationPeriod}</div>
            )}
            <ul className={s.meta}>
              <TripStateIndicatorInbox
                tripState={tripState}
                unread={unreadIndicator}
              />
              {(isRenter ? tripState.has_pending_charges : tripState.has_charges) && (
                <li className={s.charge}>
                  Requested Charge
                </li>
              )}
              {isThreadTyping(tripId) ? (
                <li className={s.typing}>
                  <TypingIndicator />
                  <span>typing</span>
                </li>
              ) : (
                <li className={s.time}>
                  <time dateTime={formatISO(latestModificationDate)}>
                    {formatDistanceToNowStrict(latestModificationDate, { addSuffix: true })}
                  </time>
                </li>
              )}
            </ul>
          </TripCardDetails>
        </div>
      </NavLink>
    </li>
  );
};

export default Trip;
