import { Map, fromJS } from 'immutable';
import { stringify } from 'query-string';

import { fetchCatch } from 'src/core/sentry';
import { apiFetchThunk } from './fetch';

export const ENDPOINT_URL = '/support/price-range/';

// app/reducer/action
export const SET_PRICE_RANGE = 'common/currency/SET_PRICE_RANGE';

export const setPriceRange = priceRange => ({ type: SET_PRICE_RANGE, priceRange });

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (priceRange = Map(), action) => {
  switch (action.type) {
    case SET_PRICE_RANGE:
      return fromJS(action.priceRange);
    default:
      return priceRange;
  }
};

export const getPriceRangeCall = currencyCode => (
  dispatch => {
    const queryString = stringify({
      currency: currencyCode,
    });
    const url = queryString ? `${ENDPOINT_URL}?${queryString}` : ENDPOINT_URL;
    return dispatch(apiFetchThunk(url))
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(data => dispatch(setPriceRange(data)))
      .catch(response => fetchCatch(response));
  }
);
