import React from 'react';
import PropTypes from 'prop-types';

import Svg from '../../../common/components/Svg';
import Modal from '../../../common/components/Modal';
import s from './PhotoPrevew.module.scss';

const PhotoPreview = ({
  imageUrl,
  removeImage,
}) => (
  <Modal
    name="photo-preview"
    label="Photo Preview"
    classNameModifier="photo"
  >
    <img
      src={imageUrl}
      alt="Preview"
      className={s.image}
    />
    <button
      type="button"
      onClick={removeImage}
      className={s.button}
    >
      <Svg
        href="#remove-photos"
        className={s.icon}
      />
      Remove photo
    </button>
  </Modal>
);

PhotoPreview.propTypes = {
  imageUrl: PropTypes.string,
  removeImage: PropTypes.func.isRequired,
};

export default PhotoPreview;
