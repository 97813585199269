import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'immutable';
import moment from 'moment';

import { ImmutableTrip } from 'src/types/trips/Trips';
import { ImmutableOffer } from 'src/types/offer/Offer';
import { open } from 'src/common/ducks/zippy';
import {
  ALTERNATIVE_DATE_FIELD,
  ALTERNATIVE_RETURN_DATE_FIELD,
  FORMS,
  PREFERRED_DATE_FIELD,
  REQUESTED_CHANGES_WARNING,
  SUGGESTED_DATES_FIELD,
  TRIP_LENGTH_FIELD,
  UNSENT_CHANGES_WARNING,
  TRIP_STATES,
  RENTER_OFFER_EXPIRED_STATES,
  OWNER_OFFER_EXPIRED_STATES,
  OFFER_CANCELLED_STATES,
  PICKUP_TIME_FIELD,
} from 'src/inbox/constants';
import {
  getOtherParty,
  modified as tripModified,
  offerModified,
  userIsRenter,
  getDatesWithTripDurationEnd,
} from 'src/inbox/helpers';
import DateTime from 'src/inbox/components/DateTime';
import DateTimeSnippet from 'src/common/components/DateTimeSnippet';
import NotificationMessage from 'src/common/components/NotificationMessage';
import EditButton from 'src/inbox/components/EditButton';
import Modal from 'src/common/components/Modal';
import Card from 'src/inbox/components/presentation/Card';
import { MOMENT_FORMAT } from 'src/common/constants';
import cardStyles from 'src/inbox/components/presentation/Card/Card.module.scss';
import { getOffer } from 'src/common/utils/reduxStoreSelectors';
import DatesForm from './form';
import { offerDatesSelector } from './selector';
import styles from './DatesCard.module.scss';

type DatesCardProps = {
  trip: ImmutableTrip;
  readOnly?: boolean;
  requestedChanges?: boolean;
  checkTripModification?: boolean;
  withBookingMotivators?: boolean;
};

const {
  OWNER_OFFER_EDITED,
  OWNER_OFFER_SENT,
  RENTER_OFFER_EDITED,
  RENTER_OFFER_RECEIVED,
  RENTER_CHANGES_REQUESTED,
  OWNER_CHANGES_REQUESTED,
} = TRIP_STATES;

const MODAL_ID = 'dates-form-modal';

/**
 * Import multiple css modules into a component
 *
 * Usual card consist of several independent components. To be able
 * to tie these components via CSS to show EditButton on hover we
 * should combine styles from 2 components.
 */
const s = {
  ...cardStyles,
  ...styles,
};

const CONFIRMED_DATE_TRIP_STATES = [
  OWNER_OFFER_EDITED,
  OWNER_OFFER_SENT,
  RENTER_OFFER_EDITED,
  RENTER_CHANGES_REQUESTED,
  OWNER_CHANGES_REQUESTED,
  RENTER_OFFER_RECEIVED,
  ...OWNER_OFFER_EXPIRED_STATES,
  ...RENTER_OFFER_EXPIRED_STATES,
  ...OFFER_CANCELLED_STATES,
];

/**
 *
 * @param {string} dateString a moment supported ISO 8601 string
 * @param {string} tripLength a duration 'd HH:mm:ss'
 * @returns {string} 'YYYY-MM-DD'
 */
const getMomentFormatted = (dateString: string, tripLength: string) => moment(dateString)
  .add(tripLength)
  .format(MOMENT_FORMAT.VALUE);

export const getOfferReturnDateAlteredFields = (
  preferredDate: string,
  trip: ImmutableTrip,
  offer: ImmutableOffer,
) => {
  if (offer.has(TRIP_LENGTH_FIELD) && !offer.has(PICKUP_TIME_FIELD)) {
    const tripLength = trip.get(TRIP_LENGTH_FIELD);
    const offerTripLength = offer.get(TRIP_LENGTH_FIELD, tripLength);
    const tripReturnDate = getMomentFormatted(preferredDate, tripLength);
    const offerReturnDate = getMomentFormatted(preferredDate, offerTripLength);
    return !moment(tripReturnDate).isSame(offerReturnDate) ? [TRIP_LENGTH_FIELD] : [];
  }
  if (offer.has(PICKUP_TIME_FIELD)) {
    // it is possible that a change in pickup time
    // has caused the return date to fall on the next calendar day
    const tripLength = offer.get(TRIP_LENGTH_FIELD, trip.get(TRIP_LENGTH_FIELD));
    const tripPickupTime = trip.get(PICKUP_TIME_FIELD);
    const offerPickupTime = offer.get(PICKUP_TIME_FIELD);
    const tripReturnDate = getMomentFormatted(`${preferredDate} ${tripPickupTime}`, tripLength);
    const offerReturnDate = getMomentFormatted(`${preferredDate} ${offerPickupTime}`, tripLength);
    return !moment(tripReturnDate).isSame(offerReturnDate)
      ? [PICKUP_TIME_FIELD, ...(offer.has(TRIP_LENGTH_FIELD) ? [TRIP_LENGTH_FIELD] : [])]
      : [];
  }

  return [];
};

export const getSingleChosenDateLabel = (
  trip: ImmutableTrip,
  preferredDate: string,
) => {
  const suggestedDates = trip.get(SUGGESTED_DATES_FIELD, List());
  const newDateSelected = !suggestedDates.includes(preferredDate);
  const isPreferredDateSelected = preferredDate === suggestedDates.get(0);
  const isRenter = userIsRenter(trip);
  const renterName = trip.getIn(['renter', 'first_name']);

  let result = '';
  if (isRenter) {
    result = 'Suggested date:';
    if (isPreferredDateSelected) {
      result = 'Preferred date:';
    } else if (!newDateSelected) {
      result = 'Alternate date:';
    }
  } else {
    result = 'Suggested date:';
    if (isPreferredDateSelected) {
      result = `${renterName}’s preferred date:`;
    } else if (!newDateSelected) {
      result = `${renterName}’s alternate date:`;
    }
  }

  return result;
};

export const shouldRenderSingleChosenDate = (offerIsModified: boolean, tripState: string) => {
  const isConfirmedDateTripState = CONFIRMED_DATE_TRIP_STATES.includes(tripState);
  return offerIsModified || isConfirmedDateTripState;
};

const DatesCard: FC<DatesCardProps> = ({
  trip,
  readOnly,
  checkTripModification,
  requestedChanges,
  withBookingMotivators = false,
}) => {
  const dispatch = useDispatch();
  const openModal = useCallback(() => dispatch(open(FORMS.DATES)), [dispatch]);
  const offer = useSelector(getOffer);

  const {
    preferredDateMoment,
    returnDateMoment,
    tripLengthMoment,
    preferredDate,
    alternativeReturnDate,
  } = offerDatesSelector({ offer, trip });

  const pickupTime = trip.get(PICKUP_TIME_FIELD);
  let suggestedDates = trip.get(SUGGESTED_DATES_FIELD, List());
  const suggestedDatesWithEnds = getDatesWithTripDurationEnd(
    suggestedDates,
    tripLengthMoment,
    pickupTime,
  );
  const offerReturnDateAlteredFields = getOfferReturnDateAlteredFields(preferredDate, trip, offer);
  const fields = [
    PREFERRED_DATE_FIELD,
    ...offerReturnDateAlteredFields,
  ];
  const offerIsModified = offerModified(offer, trip, fields);
  const tripIsModified = checkTripModification && tripModified(trip, fields);
  const modified = offerIsModified || tripIsModified;
  // This should default to the preferred_date,
  // but only if that preferred_date isn't in the suggested dates,
  // since there would be duplicated fields in that case.
  // It should be undefined in that case.
  const alternativeDateCondition = !suggestedDates.includes(preferredDate);
  const isRenter = userIsRenter(trip);
  const otherUser = getOtherParty(trip);
  const renterName = trip.getIn(['renter', 'first_name']);
  const otherName = otherUser.get('first_name');
  const tripState = trip.getIn(['state', 'state']);
  const shouldRenderChosenDate = shouldRenderSingleChosenDate(offerIsModified, tripState);
  const chosenDateLabel = useMemo(
    () => getSingleChosenDateLabel(trip, preferredDate),
    [preferredDate, trip],
  );

  // Include date suggested by owner into the dates list for renter
  if (isRenter) {
    suggestedDates = suggestedDates.unshift(preferredDate);
  }

  return (
    <Card
      highlighted={modified}
      classNameModifier={!readOnly ? 'hoverEffect' : ''}
    >
      {/* These should probably be injected with a `children` prop. */}
      {offerIsModified && (
        <NotificationMessage outdent={false}>
          {UNSENT_CHANGES_WARNING}
        </NotificationMessage>
      )}
      {requestedChanges && tripIsModified && !offerIsModified && (
        <NotificationMessage outdent={false}>
          {REQUESTED_CHANGES_WARNING}
        </NotificationMessage>
      )}
      {shouldRenderChosenDate && (
        <>
          <span className={s.label}>{chosenDateLabel}</span>
          <DateTimeSnippet type="date">
            <DateTime
              departDateTime={preferredDateMoment}
              returnDateTime={returnDateMoment}
              classNameModifier={modified ? 'modified' : ''}
            />
          </DateTimeSnippet>
        </>
      )}

      {!shouldRenderChosenDate && suggestedDatesWithEnds.map((date, index) => (
        // The order of these dates are not likely to change
        // so it should be okay to use array indices
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {index === 0 && (
            <span className={s.label}>
              {isRenter ? 'Preferred date:' : `${renterName}’s preferred date`}
            </span>
          )}
          {index === 1 && suggestedDatesWithEnds.size > 1 && (
            <span className={s.label}>
              Alternate date{suggestedDatesWithEnds.size > 2 ? 's' : ''}:
            </span>
          )}
          <DateTimeSnippet type="date">
            <DateTime
              departDateTime={moment(date.get(0))}
              returnDateTime={moment(date.get(1))}
            />
          </DateTimeSnippet>
        </Fragment>
      ))}

      {/* This could also be injected. */}
      {!readOnly && (
        <>
          <EditButton openModal={openModal} />
          <Modal
            name="dates"
            label="Dates Form"
            id={MODAL_ID}
            classNameModifier={withBookingMotivators ? 'dates' : ''}
          >
            <DatesForm
              withBookingMotivators={withBookingMotivators}
              firstName={otherName}
              tripLength={tripLengthMoment}
              modified={offerIsModified}
              initialValues={{
                [PREFERRED_DATE_FIELD]: preferredDate,
                // Preselect alternative date for owners only
                [ALTERNATIVE_DATE_FIELD]: (!isRenter && alternativeDateCondition
                  ? preferredDate
                  : undefined),
                [ALTERNATIVE_RETURN_DATE_FIELD]: (!isRenter && alternativeReturnDate
                  ? alternativeReturnDate
                  : undefined),
              }}
              suggestedDates={suggestedDates}
              isRenter={isRenter}
              pickupTime={pickupTime}
              modalId={MODAL_ID}
              hasDayPricing={trip.get('has_day_pricing')}
            />
          </Modal>
        </>
      )}
    </Card>
  );
};

export default DatesCard;
