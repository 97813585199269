import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';

import { trackEvent } from 'src/common/tracking';
import type { ReduxState } from '../../../../types/reduxState';
import CloseButton from '../../../../common/components/CloseButton';
import Button from '../../../../common/components/Button';
import { onSubmitLocationChange, appendRoute } from '../../../../common/utils/routing';
import { PAGES } from '../../../constants';
import { trackEventFacebook } from '../../../../common/tracking/facebook';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import s from './WelcomeScreen.module.scss';

const getLoadingStatus = ({ loading }: ReduxState): boolean => loading.get('status') ?? false;
const getBoatId = ({ boat }: ReduxState): string => boat.get('id');
const GA4_EVENT_SEND_INQUIRY_START = 'send_inquiry_s1_start';

const WelcomeScreen: FC = () => {
  const location = useLocation();
  const { push } = useHistory();
  const boatId = useSelector(getBoatId);
  const loading = useSelector(getLoadingStatus);

  const nextPage = appendRoute(location.pathname, PAGES.DURATION) + location.search;
  const returnUrl = getReturnUrl(location);

  useEffect(
    () => trackEventFacebook('InquiryRequestInitiated', { content_ids: [boatId] }, true),
    [boatId],
  );

  return (
    <div className={s.container}>
      <Helmet>
        <title>
          Welcome
        </title>
      </Helmet>
      <h1 className={s.title}>Send a Booking Inquiry</h1>
      <svg className={s.icon}>
        <use xlinkHref="#dolphin" />
      </svg>
      <div className={s.message}>
        <p>
          We’ll ask you a few questions so the listing owner can send you a custom price,
          itinerary and more information. No commitment or credit card needed.
        </p>
      </div>

      <Button
        type="submit"
        onClick={onSubmitLocationChange(nextPage, () => {
          push(nextPage);
          trackEvent(GA4_EVENT_SEND_INQUIRY_START, {
            p1: 'Continue',
          });
        })}
        disabled={loading}
        autoFocus
        data-test="continue"
        fullWidth
      >
        {loading ? 'Loading…' : 'Continue'}
      </Button>
      <CloseButton
        onClick={() => {
          trackEvent(GA4_EVENT_SEND_INQUIRY_START, { p1: 'Close' });
          window?.location.assign(returnUrl);
        }}
      />
    </div>
  );
};

export default WelcomeScreen;
