import { useCallback, useRef } from 'react';
import { useCalendarContext } from './useCalendar';
import useIntersectionObserver from './useIntersectionObserver';

/**
 * Sets the month selector's current label based on when months enter the view.
 * The `ref` should be attached to the container that displays the month's content.
 */
const useScrollingMonthUpdate = <T extends HTMLElement>(date: string) => {
  const { setDropdownMonth, instabookEnabled } = useCalendarContext();
  const callback = useCallback(() => {
    setDropdownMonth(date);
  }, [setDropdownMonth, date]);
  const ref = useRef<T>(null);

  // The height of the mobile header and calendar controls
  let offset = 110;

  if (instabookEnabled) {
    // Additional offset to account for the activity and instabook tabs
    offset += 50;
  }

  useIntersectionObserver({ ref, callback, rootMargin: `-${offset}px 0px 0px 0px` });
  return ref;
};

export default useScrollingMonthUpdate;
