import React, { FC } from 'react';
import { getClassNameFor } from 'src/common/helpers';

import s from './EventDetailsContent.module.scss';

type EventDetailsContentProps = {
  children: React.ReactNode;
  classNameModifier?: string;
};

const EventDetailsContent: FC<EventDetailsContentProps> = ({ children, classNameModifier }) => (
  <div className={getClassNameFor(s, 'root', classNameModifier)}>
    {children}
  </div>
);

export default EventDetailsContent;
