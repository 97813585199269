import React, { FC, StyleHTMLAttributes, useState } from 'react';

import { getClassNameFor } from '../../helpers';
import LazyImg from '../LazyImg';
import sizesConfig, { colorConfig } from './config';
import BoatPlaceholderImage from '../BoatPlaceholderImage';

import s from './Avatar.module.scss';

const defaultSize = sizesConfig.get('default');

type AvatarUser = {
  first_name: string;
  has_photo: boolean;
  photo_url?: string;
  last_name?: string;
};

export type AvatarSizes = 'tiny' | 'small' | 'default' | 'medium' | 'large' | 'extraLarge';
export type AvatarProps = {
  user: Partial<AvatarUser>;
  classNameModifier?: string;
  extraClassName?: string;
  offset?: number;
  scrollContainer?: string;
  overflow?: boolean;
  size: AvatarSizes;
  lazy?: boolean;
  isBoat?: boolean;
  style?: StyleHTMLAttributes<HTMLDivElement>;
};

const Avatar: FC<AvatarProps> = ({
  classNameModifier,
  extraClassName = '',
  offset,
  scrollContainer,
  overflow,
  size,
  user = {},
  lazy,
  isBoat = false,
  style = {},
}) => {
  const [imageLoadFailed, setImageLoadFailed] = useState(false);

  const {
    has_photo: hasPhoto,
    first_name: firstName,
    last_name: lastName,
    photo_url: photoUrl,
  } = user;

  const hasImage = user && hasPhoto && photoUrl;

  // To keep the avatar colors the same,
  // the alphabet is split into fifths with each color assigned to a fifth
  const alphabetIndex = firstName ? firstName.toUpperCase().charCodeAt(0) : 5;
  const color = colorConfig[alphabetIndex % 5];
  const modifiers = [classNameModifier, color, size].join(' ');

  const UserPlaceholder = (
    <div className={getClassNameFor(s, 'placeholder', modifiers)}>
      {firstName && firstName.charAt(0)}
      {lastName && lastName.charAt(0)}
    </div>
  );

  const BoatPlaceholder = (
    <BoatPlaceholderImage classNameModifier="avatar" />
  );

  const Placeholder = isBoat ? BoatPlaceholder : UserPlaceholder;
  return (
    <div
      style={style}
      className={`${getClassNameFor(s, 'root', modifiers)} ${extraClassName}`}
    >
      {(hasImage && !imageLoadFailed) ? (
        <LazyImg
          src={photoUrl}
          alt={firstName || ''}
          disableLQIP
          width={sizesConfig.get(size, defaultSize).w}
          height={sizesConfig.get(size, defaultSize).h}
          imgixProps={{
            ...sizesConfig.get(size, defaultSize),
            fit: 'crop',
            crop: 'faces',
          }}
          offset={offset}
          placeholder={Placeholder}
          scrollContainer={scrollContainer}
          overflow={overflow}
          className={s.image}
          lazy={lazy}
          onError={() => setImageLoadFailed(true)}
        />
      ) : Placeholder}
    </div>
  );
};

export default Avatar;
