import React, { FC, MutableRefObject, useEffect, useRef } from 'react';

import CheckDecorator from '../CheckDecorator';

const CheckableInput: FC<Props> = ({
  checked,
  disabled,
  type = 'checkbox',
  indeterminate,
  ...restProps
}) => {
  const inputRef: MutableRefObject<null | HTMLInputElement> = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = !!indeterminate;
      inputRef.current.checked = checked;
    }
  }, [checked, indeterminate]);

  return (
    <CheckDecorator disabled={disabled}>
      <input
        ref={inputRef}
        type={type}
        {...restProps}
        // Don't pass className because input doesn't need it
        className={undefined}
        disabled={disabled}
      />
    </CheckDecorator>
  );
};

type Props = {
  checked: boolean;
  disabled?: boolean;
  id?: string;
  type: string;
  indeterminate?: boolean;
};

export default CheckableInput;
