import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { BoatDetail, ImmutableBoat } from '../../types/boat/BoatDetail';
import { ReduxState } from '../../types/reduxState';
import { fetchBoat, setBoat } from './boat';

// Actions
// -----------------------------------------------------------------------------
export const SET_BOAT_MAP = 'common/boatMap/SET_BOAT_MAP';

// Actions Creators
// -----------------------------------------------------------------------------
export const setBoatMap = (boat: ImmutableBoat) => ({ type: SET_BOAT_MAP, boat } as const);

type FetchBoatAndSetMapThunk = ThunkAction<void, ReduxState, {}, Action>;
export const fetchBoatAndSetMap = (boatId: string, currency: string): FetchBoatAndSetMapThunk => (
  async (dispatch, getState) => {
    const { boatMap } = getState();
    let boat = boatMap[boatId];
    if (boat) {
      dispatch(setBoat(boat));
    } else {
      boat = (await dispatch(fetchBoat(boatId, currency))) as unknown as ImmutableBoat;
      dispatch(setBoatMap(boat));
    }
    return boat;
  }
);

type ReducerActions = ReturnType<typeof setBoatMap>;

// Reducers
// -----------------------------------------------------------------------------
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (boatMap: Record<string, BoatDetail> = {}, action: ReducerActions) => {
  switch (action.type) {
    case SET_BOAT_MAP: {
      return { ...boatMap, [action.boat.get('id')]: action.boat };
    }
    default: {
      return boatMap;
    }
  }
};

export const getBoatFromMap = (id: string) => (
  ({ boatMap }: ReduxState): ImmutableBoat | undefined => boatMap[id]
);

export const getBoatMap = ({ boatMap }: ReduxState) => boatMap;
