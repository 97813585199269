/*
 * This is a partial rewrite of part of react-google-maps-loader:
 * https://github.com/xuopled/react-google-maps-loader/blob/master/src/loadGoogleMapsSdk/index.js
 *
 * This function takes advantage of the google maps callback feature, which
 * should hopefully help cut down on the number of errors we see as a result
 * of google maps not being loaded.
 */
import load from 'little-loader';
import qs from 'query-string';

const GOOGLE_MAP_PLACES_API = 'https://maps.googleapis.com/maps/api/js';
const NOT_LOADED = 0;
const LOADING = 1;
const LOADED = 2;

const queue = [];
let state = NOT_LOADED;
let googleMaps = null;

function loadGoogleMapsSdk(params, callback) {
  if (typeof window !== 'undefined') {
    window.gm_authFailure = () => {
      callback({ googleMaps, error: 'SDK Authentication Error' });
    };
    window.googleMapsLoaded = () => {
      state = LOADED;
      googleMaps = window.google ? window.google.maps : null;
      while (queue.length > 0) {
        queue.pop()({ googleMaps });
      }
    };

    if (state === LOADED) {
      callback({ googleMaps });
    } else if (state === LOADING) {
      queue.push(callback);
    } else {
      if (!window.google) {
        window.google = undefined;
      }

      state = LOADING;
      queue.push(callback);

      load(`${GOOGLE_MAP_PLACES_API}?${qs.stringify({ ...params, callback: 'googleMapsLoaded' })}`);
    }
  }
}

export default loadGoogleMapsSdk;
