import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import type { Request } from 'express';
import { fetchCatch } from 'src/core/sentry';
import type { ReduxState } from '../../types/reduxState';
import type { Currencies } from '../types';
import { apiFetchThunk } from './fetch';
import { setRequestCurrency } from './requestCurrency';

export const SET_CURRENCIES = 'common/currency/SET_CURRENCIES';

export const setCurrencies = (currencies: Currencies) => ({ type: SET_CURRENCIES, currencies });

type CurrenciesAction = ReturnType<typeof setCurrencies>;

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (currencies = {}, action: CurrenciesAction) => {
  switch (action.type) {
    case SET_CURRENCIES:
      return action.currencies;
    default:
      return currencies;
  }
};

type GetCurrenciesCallThunk = ThunkAction<Promise<void>, ReduxState, {}, Action>;

export const getCurrenciesCall = (request: Request): GetCurrenciesCallThunk => async (dispatch) => {
  const currency: string | undefined = request.cookies.preferred_currency;
  const url = `/support/currencies/?expand=true${currency ? `&currency=${currency}` : ''}`;
  await dispatch(apiFetchThunk(url))
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .then((currencies: Currencies) => {
      dispatch(setCurrencies(currencies));
      dispatch(setRequestCurrency(currencies.display_currency));
    })
    .catch(fetchCatch);
};
