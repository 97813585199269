import { USER_ROLES } from '../../../../common/constants';

const { OWNER, RENTER } = USER_ROLES;

const content = {
  [OWNER]: {
    text: 'Taking the time to leave feedback makes Getmyboat better for everyone. Thanks to you, '
    + 'your guests can build trust in the community and help promote your listing.',
  },
  [RENTER]: {
    text: 'Taking the time to leave feedback makes Getmyboat better for everyone. Thanks to you, '
    + 'future guests will know what to expect and your host will know how they’re doing.',
  },
};

export default content;
