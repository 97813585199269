import React, { ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import getRedirectUrl from 'src/common/utils/getRedirectUrl';
import { validateHost } from 'src/common/utils/validateHost';

type AllowedHostProviderProps = {
  children: ReactNode;
};

const AllowedHostProvider = ({ children }: AllowedHostProviderProps) => {
  const [redirectUrl, setRedirectUrl] = useState('');
  useEffect(() => {
    const allowedHosts = __ALLOWED_HOSTS__.split(',');
    if (!validateHost(window.location.hostname, allowedHosts)) {
      const url = getRedirectUrl(new URL(window.location.href), allowedHosts);
      setRedirectUrl(url);
    }
  }, []);

  return redirectUrl ? (
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
      <meta httpEquiv="refresh" content={`0; url=${redirectUrl}`} />
    </Helmet>
  ) : children;
};

export default AllowedHostProvider;
