import React, { FC } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../helpers';
import Svg from '../Svg';
import s from './CaptainSnippet.module.scss';

export type Props = {
  captained?: boolean;
  classNameModifier?: string;
};

const CaptainIcon: FC<Props> = ({
  captained,
  classNameModifier = '',
}) => (
  <Svg
    href={`#icon-${!captained ? 'no-' : ''}captain`}
    className={getClassNameFor(s, 'icon', classNames(classNameModifier, { noCaptain: !captained }))}
  />
);

export default CaptainIcon;
