import React, { FC } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from 'src/common/helpers';
import styles from './CostBreakdown.module.scss';

type CostListProps = {
  children: React.ReactNode;
  variant?: 'multilevel' | 'offerSummary';
};

const CostList: FC<CostListProps> = ({
  children,
  variant,
}) => (
  <dl className={getClassNameFor(styles, 'prices', classNames(variant))}>
    {children}
  </dl>
);

export const CostListLabel: FC<{ children: React.ReactNode }> = ({ children }) => (
  <dt className={styles.priceLabel}>
    {children}
  </dt>
);

export const CostListValue: FC<{
  children: React.ReactNode;
  priceId?: string;
}> = ({
  children,
  priceId,
}) => (
  <dd
    className={styles.priceValue}
    data-test={priceId}
  >
    {children}
  </dd>
);

export default CostList;
