import React from 'react';

import Icon from 'src/common/components/IconDS22';
import { CAPTAIN_HASH_URLS } from 'src/inbox/constants';
import { trackEvent } from 'src/common/tracking';
import s from './InviteCaptain.module.scss';

const InviteCaptain: React.FC = () => {
  const handleClick = () => {
    trackEvent('captain_invite_open');
  };

  return (
    <a
      className={s.root}
      href={`#${CAPTAIN_HASH_URLS.INVITE}`}
      onClick={handleClick}
    >
      <Icon
        className={s.icon}
        id="plus"
        size="m"
      />
      <div className={s.content}>
        <h6 className={s.title}>
          Invite a new captain
        </h6>
        <p className={s.description}>
          Your captains can be selected once they’ve been registered
          and linked to your account.
        </p>
      </div>
    </a>
  );
};

export default InviteCaptain;
