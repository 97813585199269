import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { TITLE, USER_ROLES } from '../../../../common/constants';
import { getBoatReviewCall, getRenterReviewCall } from '../../../ducks/reviews';
import { decorateComponent, getClassNameFor } from '../../../../common/helpers';
import TripPanel from '../../presentation/TripPanel';
import CTA, { CTAButton } from '../../CTA';
import Card from '../../presentation/Card';
import Svg from '../../../../common/components/Svg';
import content from './content';
import s from '../ReviewStep.module.scss';

const mapDispatchToProps = dispatch => ({
  fetchBoatReview: id => getBoatReviewCall(id)(dispatch),
  fetchRenterReview: id => getRenterReviewCall(id)(dispatch),
});

const Thanks = ({
  trip,
  role,
  fetchBoatReview,
  fetchRenterReview,
  backLocation,
  initialTripLocation,
  npsValue,
}) => {
  const { push } = useHistory();
  const goodNps = npsValue > 3;
  const getReviews = role === USER_ROLES.RENTER ? fetchBoatReview : fetchRenterReview;

  const cta = (
    <CTA classNameModifier="withSidebar">
      <CTAButton
        type="button"
        onClick={() => getReviews(trip.get('pk'))
          .then(() => push(initialTripLocation))}
        classNameModifier={goodNps ? 'gray' : ''}
        data-test="done"
      >
        Done
      </CTAButton>
    </CTA>
  );

  return (
    <TripPanel
      trip={trip}
      subheader={TITLE.REVIEW}
      cta={cta}
      backLocation={backLocation}
    >
      <Card classNameModifier="review">
        <h3 className={getClassNameFor(s, 'title', 'thanks')}>
          Thanks!
        </h3>
        {!goodNps && (
          <Svg
            href="#homepage-action-search"
            className={s.thanksMap}
          />
        )}
        <p className={getClassNameFor(s, 'text', 'bigger')}>
          {content[role].text}
        </p>
        {goodNps && (
          <>
            <a
              href="https://search.google.com/local/writereview?placeid=ChIJVVXFtouAhYARUhXDTo41lVA"
              target="_blank"
              rel="noopener noreferrer"
              className={s.link}
            >
              <Svg
                href="#google-review"
                className={s.googleReview}
              />
              Rate us on Google
            </a>
            <p className={getClassNameFor(s, 'text', 'subtext')}>
              Help us get the word out.<br />
              Leave us a review on our Google page.
            </p>
          </>
        )}
      </Card>
    </TripPanel>
  );
};

Thanks.propTypes = {
  trip: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  backLocation: PropTypes.object.isRequired,
  npsValue: PropTypes.number,
  initialTripLocation: PropTypes.object.isRequired,
  fetchBoatReview: PropTypes.func.isRequired,
  fetchRenterReview: PropTypes.func.isRequired,
};

const decorators = [
  connect(null, mapDispatchToProps),
];

export default decorateComponent(Thanks, decorators);
