import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Form } from 'react-final-form';

import Layout from '../Layout';
import Header from '../Header';
import FormError from '../FormError';
import { homepageRedirect } from '../../utils/routing';
import { rffSubmitResponse } from '../../helpers';
import { userPatchCall } from '../../ducks/user';
import { rffValidators } from '../../validation';
import YesNoFields from '../fieldsets/YesNoFields';
import Button from '../Button';
import s from './NewsletterSubscribe.module.scss';

const validate = rffValidators.boolean({
  message: 'Please indicate whether you would like to receive newsletters.',
});

const NewsletterSubscribe = ({
  history: { push },
  dispatch,
}) => (
  <Layout classNameModifier="with-blue-gradient-background">
    <Helmet>
      <title lang="en">
        Subscribe
      </title>
    </Helmet>
    <Header
      withNav={false}
      withSearchBar={false}
      classNameModifier="modal"
    />
    <div className={s.modal}>
      <div className={s.container}>
        <h1 className={s.title}>
          News from Getmyboat
        </h1>
        <div className={s.message}>
          <p>
            Each month or so we send an email newsletter about marine travel activities,
            unique water experiences and featured Getmyboat trips, destinations and events.
          </p>
          <p>
            Please let us know if you’d like to opt-in. You can change your selection any time.
          </p>
        </div>
        <Form
          onSubmit={values => (
            dispatch(userPatchCall(values))
              .then(() => homepageRedirect(push))
              .catch(rffSubmitResponse())
          )}
        >
          {({ handleSubmit, submitting, submitError }) => (
            <form
              onSubmit={handleSubmit}
              className={s.root}
            >
              <YesNoFields
                name="marketing_consent"
                classNameModifier="subscribe"
                validate={validate}
              />

              <FormError error={submitError} />
              <Button
                type="submit"
                submitting={submitting}
                fullWidth
              >
                Save
              </Button>
            </form>
          )}
        </Form>
      </div>
    </div>
  </Layout>
);

NewsletterSubscribe.propTypes = {
  history: PropTypes.object,
  dispatch: PropTypes.func,
};

export default NewsletterSubscribe;
