import PropTypes from 'prop-types';
import React from 'react';
import { fromJS, List } from 'immutable';

const Svg = ({
  href,
  className,
  ...restProps
}) => (
  <svg
    className={className}
    {...restProps}
  >
    {/* It is possible to pass array of hrefs to output multiple use elements */}
    {List.isList(fromJS(href))
      ? href.map(h => (
        <use key={h} xlinkHref={h} />
      ))
      : <use xlinkHref={href} />}
  </svg>
);

Svg.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
};

export default Svg;
