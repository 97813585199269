import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { parse } from 'query-string';
import { get } from 'lodash';
import { Route, useLocation, type RouteComponentProps } from 'react-router-dom';

import type { ReduxState } from 'src/types/reduxState';
import { getLoading, getTrips } from 'src/common/utils/reduxStoreSelectors';
import type { Action } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import type { ImmutableTrip } from 'src/types/trips/Trips';
import { isMobile } from '../../../common/helpers';
import { PATHS } from '../../../common/constants';
import { getTripsCall } from '../../ducks/trips';
import { getOtherParty } from '../../helpers';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import TopScroll from '../../../common/components/TopScroll';
import Trip from '../Trip';
import TripsFilter from '../TripsFilter';
import s from './Sidebar.module.scss';

const Sidebar: React.FC = () => {
  const dispatch = useDispatch<ThunkDispatch<ReduxState, {}, Action>>();
  const { status: loadingInitialTrips } = useSelector(getLoading).toJS();
  const trips = useSelector(getTrips);
  const nextUrl = trips.get('next');
  const { search } = useLocation();
  const { filter } = useMemo(() => parse(search), [search]);
  const loadMoreTrips = useCallback(
    () => nextUrl && dispatch(getTripsCall({ nextUrl, filter })),
    [dispatch, filter, nextUrl],
  );

  const loadingSplash = <LoadingSpinner variant="m" background spinnerOnly />;

  return (
    <aside className={s.root}>
      {isMobile() && <TopScroll />}
      <TripsFilter />
      {!loadingInitialTrips && (
        <Route
          path={`${PATHS.INBOX}:activeTripId`}
        >
          {({ match }: RouteComponentProps) => {
            const activeTripId = get(match, ['params', 'activeTripId']);
            return (
              <ul className={s.trips}>
                {trips.get('results').map((trip) => (
                  <Trip
                    key={trip?.get('pk')}
                    trip={trip as ImmutableTrip}
                    recipient={getOtherParty(trip)}
                    // TODO: check this
                    active={activeTripId && trip?.get('pk') === Number(activeTripId)}
                  />
                ))}
              </ul>
            );
          }}
        </Route>
      )}
      {!loadingInitialTrips && nextUrl && (
        <LoadingSpinner variant="m" background>
          Loading more…
          {/* Putting the waypoint in here prevents the loading trigger
              from happening until you're _into_ the loading spinner,
              while working around a cross-browser issue that we had
              with leaving it outside and trying to use a negative offset.
              */}
          <Waypoint
            onEnter={loadMoreTrips}
          />
        </LoadingSpinner>
      )}
      {loadingInitialTrips && loadingSplash}
    </aside>
  );
};

export default Sidebar;
