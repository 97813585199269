import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Field, FieldRenderProps } from 'react-final-form';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import Checkbox from 'src/common/components/inputs/Checkbox';
import CodeInput from 'src/common/components/inputs/CodeInput';
import InputWrapper from 'src/common/components/InputWrapper';
import { Link } from 'react-router-dom';
import { PATHS } from 'src/common/constants';
import LoadingSpinner from 'src/common/components/LoadingSpinner';
import Button from '../../../../common/components/Button';
import { unpackApiError } from '../../../../common/helpers';
import { MFALoginAction, resendMFACodeAction } from '../../../../common/ducks/user';
import { ReduxState } from '../../../../types/reduxState';
import { MFAMethod } from '../../../../types/common/MFAMethods';

import s from './OtpForm.module.scss';

const MINUTE_IN_SECONDS = 60;

type OtpFormState = {
  otp: string;
  trust_device?: boolean;
};
type OtpFormProps = {
  currentMFAMethod: MFAMethod;
  onSubmitSuccess: () => void;
  showAlternativeMethodLink?: boolean;
};

const CodeInputWrapper: FC<FieldRenderProps<string>> = ({
  input,
  meta,
  loading,
  ...props
}) => {
  const handleChange = (value: string) => {
    input.onChange(value);
  };
  const hasSubmitError = !meta?.dirtySinceLastSubmit && !!meta?.submitError;
  return (
    <CodeInput
      {...input}
      {...props}
      hasError={hasSubmitError}
      disabled={loading || props.disabled}
      onChange={handleChange}
      centered
      autoFocus
    />
  );
};

const OtpForm: FC<OtpFormProps> = ({
  currentMFAMethod,
  showAlternativeMethodLink = false,
  onSubmitSuccess,
}) => {
  const dispatch = useDispatch<ThunkDispatch<ReduxState, {}, Action>>();
  const [resendError, setResendError] = useState<string | null>(null);
  const [isResending, setIsResending] = useState(false);
  const [countDown, setCountDown] = useState(MINUTE_IN_SECONDS - 1);
  const formattedCountDown = `0:${String(countDown).padStart(2, '0')}`;
  const isCountDownActive = countDown > 0;
  const isSMS = currentMFAMethod === 'sms';

  const onSubmit = useCallback(
    async (values: OtpFormState) => {
      if (!values.otp) {
        return {
          otp: 'Please enter your code',
        };
      }

      return dispatch(MFALoginAction(
        {
          ...values,
          trust_device: values.trust_device ? 1 : undefined,
          method: currentMFAMethod,
        },
      ))
        .then(onSubmitSuccess)
        .catch(async (error) => {
          const { detail } = await unpackApiError(error);
          return {
            otp: detail,
          };
        });
    },
    [
      dispatch,
      onSubmitSuccess,
      currentMFAMethod,
    ],
  );

  const onResendClick = useCallback(
    async () => {
      setIsResending(true);
      return dispatch(resendMFACodeAction({ method: currentMFAMethod }))
        .then(() => {
          setCountDown(MINUTE_IN_SECONDS - 1);
          if (resendError) {
            setResendError(null);
          }
        })
        .catch(() => {
          setResendError('Error resending code');
        })
        .finally(() => {
          setIsResending(false);
        });
    },
    [dispatch, currentMFAMethod, resendError],
  );

  useEffect(() => {
    if (countDown > 0 && isSMS) {
      const timer = setInterval(() => {
        setCountDown((prevCount) => prevCount - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
    return undefined;
  }, [countDown, isSMS]);

  return (
    <Form<OtpFormState>
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitting }) => (
        <form
          method="POST"
          onSubmit={handleSubmit}
          className={s.root}
        >
          <div className={s.formBody}>
            <fieldset className={s.fieldset}>
              <InputWrapper
                name="otp"
                showErrorOnTouch
                showErrorMessageIcon
                preventErrorMessageLayoutShift={false}
                centerErrorMessage
                manualError={resendError || undefined}
              >
                <Field
                  name="otp"
                  component={CodeInputWrapper}
                  handleComplete={handleSubmit}
                  autoFocus
                />
              </InputWrapper>
              {isCountDownActive && isSMS && (
                <p className={s.resendCodeMessage}>
                  You can request a new code in {formattedCountDown}
                </p>
              )}
              {!isCountDownActive && isSMS && (
                <button
                  className={s.resendCodeButton}
                  type="button"
                  onClick={onResendClick}
                  disabled={isResending}
                >
                  Resend Code
                  {isResending && <LoadingSpinner classNameModifier="inline" spinnerOnly variant="xs" />}
                </button>
              )}
              {showAlternativeMethodLink && (
                <p className={s.alternateMethodLink}>
                  <Link to={PATHS.CHOOSE_MFA_METHOD}>Try a different method</Link>
                </p>
              )}
              {currentMFAMethod === 'totp' && (
                <p className={s.recoveryCodeLink}>
                  Restore using a <Link to={PATHS.MFA_RECOVERY}>recovery code</Link>
                </p>
              )}
              <Field name="trust_device" type="checkbox">
                {({ input }) => (
                  <label className={s.trustDevice} htmlFor="trust_device_input">
                    <Checkbox input={{
                      ...input,
                      checked: input.value,
                      id: 'trust_device_input',
                      className: s.trustDeviceCheckbox,
                    }}
                    />
                    <span className={s.trustDeviceLabel}>
                      Trust this device for two weeks
                    </span>
                  </label>
                )}
              </Field>
            </fieldset>
          </div>
          <div className={s.actionGroup}>
            <Button
              type="submit"
              submitting={submitting}
              fullWidth
            >
              Validate
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

export default OtpForm;
