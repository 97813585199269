import React, { FC, ReactNode } from 'react';

import { getClassNameFor, plural } from '../../../common/helpers';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import Svg from '../../../common/components/Svg';
import NotificationMessage from '../../../common/components/NotificationMessage';
import s from './PhotoPanel.module.scss';

type Image = {
  file: { name: string };
  previewUrl: string;
  loading: boolean;
  uploading: boolean;
  uploaded: boolean;
};

type PhotoPanelProps = {
  children?: ReactNode;
  inputId: string;
  images: Image[];
  existingImages?: string[];
  openImage: (url: string) => void;
};

const PhotoPanel: FC<PhotoPanelProps> = ({
  children = <Svg
    href="#sunset-photo"
    className={s.sunset}
  />,
  inputId,
  images,
  existingImages,
  openImage,
}) => {
  const numberOfPhotos = images.length;
  const hasExistingImages = existingImages?.length;

  return (
    <div className={s.root}>
      {numberOfPhotos || hasExistingImages ? (
        <>
          <NotificationMessage
            classNameModifier="largerFontSize centered"
            outdent={false}
          >
            {numberOfPhotos} unsaved photo{plural(numberOfPhotos)}
          </NotificationMessage>
          {!!numberOfPhotos && images.map(({
            file: { name },
            previewUrl: url,
            loading,
            uploading,
            uploaded,
          }) => (
            <button
              type="button"
              key={name}
              className={getClassNameFor(
                s,
                'imageButton',
                [
                  loading ? 'loading' : '',
                  uploading ? 'uploading' : '',
                  uploaded ? 'uploaded' : '',
                ].join(' '),
              )}
              onClick={() => openImage(url)}
            >
              {loading ? <LoadingSpinner>{null}</LoadingSpinner> : (
                <img
                  src={url}
                  alt=""
                  className={s.imagePreview}
                />
              )}
            </button>
          ))}
          {!!hasExistingImages && existingImages.map((url) => (
            <div
              key={url}
              className={getClassNameFor(s, 'imageButton', 'existing')}
            >
              <img
                src={url}
                alt=""
                className={s.imagePreview}
              />
            </div>
          ))}
        </>
      ) : (
        <label
          className={s.label}
          htmlFor={inputId}
        >
          {children}
        </label>
      )}
    </div>
  );
};

export default PhotoPanel;
