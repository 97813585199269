import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { open, close } from '../../../common/ducks/zippy';
import { rffSubmitResponse } from '../../../common/helpers';
import useCustomOfferExpiry from '../../../common/hooks/useCustomOfferExpiry';
import Modal from '../../../common/components/Modal';

import { TRIP_STATES } from '../../constants';
import { getMessagesCall } from '../../ducks/messages';
import { CANCEL_REQUESTED_CHANGES } from '../TripMenu/actions';
import ClearButton from '../../../common/components/ClearButton';
import TripActionForm from '../forms/TripAction';
import UserBlock from '../presentation/UserBlock';

const RenterChangesRequestedMessage = ({ trip }) => {
  const tripState = trip.getIn(['state', 'state']);
  const tripId = trip.get('pk');
  const {
    confirmationPeriod,
    hasCustomExpiry,
    offerExpired,
  } = useCustomOfferExpiry(trip);
  // This is redefined here because `renderCustomExpiry` from useCustomOfferExpiry
  // rely on different set of trip states
  const renderCustomExpiry = hasCustomExpiry && tripState === TRIP_STATES.RENTER_CHANGES_REQUESTED;
  const {
    call: cancelRequestedChangesCall,
    name: modalName,
    title,
    longDescription: description,
    cancelText,
    submitText,
    withMessageField,
  } = CANCEL_REQUESTED_CHANGES;
  const dispatch = useDispatch();
  const openModal = useCallback(
    () => dispatch(open(modalName)),
    [dispatch, modalName],
  );
  const closeModal = useCallback(
    () => dispatch(close(modalName)),
    [dispatch, modalName],
  );
  const onSubmit = useCallback(
    values => dispatch(cancelRequestedChangesCall(tripId, values))
      .then(() => {
        dispatch(getMessagesCall(tripId));
        closeModal();
      })
      .catch(rffSubmitResponse()),
    [cancelRequestedChangesCall, closeModal, dispatch, tripId],
  );

  return (
    <UserBlock user={trip.get('owner')}>
      <strong>
        {trip.getIn(['owner', 'first_name'])} will be prompted to get back to you soon
        about the changes you requested to this trip.
      </strong>
      {renderCustomExpiry && (
        offerExpired
          ? ' Your previous offer has expired.'
          : (
            <>
              {' You can '}
              <ClearButton
                classNameModifier="inline"
                onClick={openModal}
              >
                cancel the change request
              </ClearButton>
              {' '}and book the previous offer.
              The offer will be valid for {confirmationPeriod}.
              <Modal
                extraClassName="ignore-onclickoutside"
                label={title}
                name={modalName}
              >
                <TripActionForm
                  closeBehavior={closeModal}
                  content={{
                    action: modalName,
                    description,
                    submitText,
                    cancelText,
                    title,
                  }}
                  onSubmit={onSubmit}
                  withMessageField={withMessageField}
                />
              </Modal>
            </>
          )
      )}
    </UserBlock>
  );
};

RenterChangesRequestedMessage.propTypes = {
  trip: PropTypes.object.isRequired,
};

export default RenterChangesRequestedMessage;
