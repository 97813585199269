import React, { FC } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { HotKeys } from 'react-hotkeys';

import { decorateComponent } from '../../../../common/helpers';
import TextArea from '../../../../common/components/inputs/TextArea';

import { clearFields, patchOffer } from '../../../ducks/offer';
import FormButtons from '../../../../common/components/FormButtons';
import CommentMessage from '../../CommentMessage';
import ClearButton from '../../../../common/components/ClearButton';
import { modalMapDispatchToProps } from '../../../submission';
import s from './CommentForm.module.scss';

const modalName = 'requests';

const mapDispatchToProps = (dispatch, { fields }) => ({
  clearLocalOfferFields: () => dispatch(clearFields(fields)),
  patchTheOffer: values => dispatch(patchOffer(values)),
  ...modalMapDispatchToProps(modalName)(dispatch),
});

type CommentFormProps = {
  clearLocalOfferFields: () => void;
  closeModal: () => void;
  patchTheOffer: (values: unknown) => void;
  title?: string;
  placeholder?: string;
  modified: boolean;
  initialValues: object;
};

const CommentForm: FC<CommentFormProps> = ({
  clearLocalOfferFields,
  closeModal,
  patchTheOffer,
  title = 'Your Reply',
  placeholder = 'Your comments and additional details.',
  modified,
  initialValues,
}) => (
  <Form
    onSubmit={(values, { getState }) => {
      if (getState().dirty) {
        patchTheOffer(values);
      }
      closeModal();
    }}
    initialValues={initialValues}
  >
    {({ handleSubmit, dirty, submitting, form: { reset } }) => (
      <HotKeys
        handlers={{ submit: handleSubmit }}
        keyMap={{ submit: ['ctrl+enter', 'meta+enter'] }}
        tabIndex={null}
        className={s.root}
      >
        <form
          className={s.wrapper}
          onSubmit={handleSubmit}
        >
          <h3 className={s.title}>
            {title}
          </h3>
          <ClearButton
            onClick={() => {
              reset();
              clearLocalOfferFields();
            }}
            disabled={!(dirty || modified)}
            classNameModifier="comment"
          />
          <Field
            // TODO: this
            name="comment"
            component={TextArea}
            placeholder={placeholder}
            classNameModifier="large"
          />
          <CommentMessage />
          <FormButtons
            classNameModifier="inbox"
            submitting={submitting}
            closeModal={closeModal}
          />
        </form>
      </HotKeys>
    )}
  </Form>
);

const decorators = [
  connect(null, mapDispatchToProps),
];

export default decorateComponent(CommentForm, decorators);
