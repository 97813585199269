import { useEffect, useRef } from 'react';

type Callback = () => void;
/**
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param callback A function to invoke on the interval.
 * @param delay The interval period in milliseconds.
 * @param condition Allows for optionally deferring the start time of the interval.
 */
const useInterval = (callback: Callback, delay: number, condition = true) => {
  const savedCallback = useRef<Callback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback.current?.();
    };
    if (delay != null && condition) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => { };
  }, [condition, delay]);
};

export default useInterval;
