import type { ComponentType } from 'react';
import { FORM_ERROR } from 'final-form';
import moment from 'moment';
import { FullBoatDetail } from 'src/types/boat/BoatDetail';
import { fetchCatch } from 'src/core/sentry';
import type { ImmutableMap } from '../types/ImmutableMap';
import { MEDIA_QUERIES } from './constants';

// TODO: Start breaking these out into related groups/files.

export const getClassNameFor = (
  styles: Record<string, string>,
  baseClassName: string,
  modifier?: string,
) => {
  const getModifiers = (mod: string) => mod.split(' ')
    .map(modifierStr => styles[`${baseClassName}_${modifierStr}`])
    .join(' ')
    .trim();

  return modifier ? `${styles[baseClassName]} ${getModifiers(modifier)}` : `${styles[baseClassName]}`;
};

export type UnpackedApiError = {
  detail?: string;
  code?: string;
};

export const unpackApiError = async (response: Response): Promise<UnpackedApiError> => {
  type MaybeError = {
    code?: {
      general?: string[];
    };
    detail?: string;
  };

  let error: MaybeError = {};
  try {
    // Attempt to unpack the body
    error = await response.json();
  } catch (e) {
    // If it's a 500ish status, we're not going to have a body, so this error will throw
    return {
      detail: 'There was problem with submitting the form.',
    };
  }
  // If there was something in the detail key is going to have our error.
  // We'll also provide the code, which can be used in other processing.
  const errorDetails: UnpackedApiError = {
    detail: error?.detail,
  };
  if (error?.code?.general?.[0]) {
    errorDetails.code = error?.code?.general?.[0];
  } else if (typeof error?.code === 'string') {
    errorDetails.code = error.code;
  }
  return errorDetails;
};

export const noop = () => undefined;

type SubmitSuccess = (response: Response) => unknown;
export const rffSubmitResponse = (
  submitSuccess: SubmitSuccess = noop,
) => async (response: Response) => {
  if (!response.ok) {
    const error = await unpackApiError(response);
    return {
      [FORM_ERROR]: error.detail,
      code: error.code,
    };
  }
  return submitSuccess(response);
};

type ComponentDecorator = <Props extends Record<string, any>>(
  component: ComponentType<Props>
) => ComponentType<Props>;

/**
 * Decorate a component with decorators in order of decorators array
 * Nesting order follows the order of decorators, where it goes from
 * inner to outer as you go down the decorators.
 *
 * @example
 * ```
 *  decorateComponent(MyComponent, [foo, bar, baz]) -> baz(bar(foo(MyComponent)))
 * ```
 */
export const decorateComponent = <Props extends Record<string, any>>(
  component: ComponentType<Props>,
  decorators: ComponentDecorator[] = [],
) => (decorators.reduce((previousComponent, decorator) => decorator(previousComponent), component));

type MediaQueryListenerCallback = Parameters<MediaQueryList['addListener']>[0];
export const getMediaQueryList = (mediaQuery: string, listener?: MediaQueryListenerCallback) => {
  let mediaQueryList: MediaQueryList | null = null;

  if (typeof window !== 'undefined' && mediaQuery && typeof mediaQuery === 'string') {
    mediaQueryList = window.matchMedia(mediaQuery);
    // Register a media query list listener
    if (listener && typeof listener === 'function') {
      mediaQueryList.addListener(listener);
    }
  }

  return mediaQueryList;
};

export const isMobile = () => {
  const mql = getMediaQueryList(MEDIA_QUERIES.MOBILE);

  return mql && mql.matches;
};

export const plural = (quantity: number) => (quantity !== 1 ? 's' : '');
export const multidayFormat = (
  days = 0,
  isDay = false,
  isOnlyDay = false,
) => {
  // &nbsp; to occupy space for mobiles
  if (isOnlyDay && days < 1) {
    // We want 'Full day'
    return 'Full day';
  }
  return (days < 1) ? '\u00A0' : `${days} ${isDay ? 'day' : 'night'}${plural(days)}`;
};

export const singleDayFormat = (duration: moment.Duration) => {
  const hours = duration.hours();
  const hoursString = hours > 0 ? `${hours} hour${plural(hours)}` : '';
  const minutes = duration.minutes();
  const minutesString = minutes > 0 ? `${minutes} minutes` : '';

  return `${hoursString} ${minutesString}`.trim();
};

export const durationFormat = (
  duration: moment.Duration,
  isNight?: boolean,
) => (
  (duration.asDays() >= 1)
    ? multidayFormat(duration.asDays(), !isNight)
    : singleDayFormat(duration)
);

export const scrollPageToBottom = () => window.scrollTo(0, document.body.scrollHeight);

const formatters = {
  guests: (n: number) => `${n} ${n > 1 ? 'people' : 'person'}`,
  adults: (n: number) => `${n} Adult${n > 1 ? 's' : ''}`,
  seniors: (n: number) => `${n} Senior${n > 1 ? 's' : ''}`,
  children: (n: number) => `${n} Child${n > 1 ? 'ren' : ''}`,
  infants: (n: number) => `${n} Infant${n > 1 ? 's' : ''}`,
} as const;
export const formatGroupSize = (number: number, type: keyof typeof formatters) => (
  formatters[type](number)
);

type ImmutablePrice = ImmutableMap<{
  minimum_unit?: string;
  unit?: string;
}>;

export const getMinimumUnit = (price: ImmutablePrice) => price.get('minimum_unit', price.get('unit'));

type PriceType =
  | 'daily_price'
  | 'hourly_price'
  | 'weekly_price'
  | 'nightly_price'
  | 'person_price';
export const priceTypeText = (priceType: PriceType, amount: number) => {
  switch (priceType) {
    case 'daily_price':
      return `day${plural(amount)}`;
    case 'hourly_price':
      return `hour${plural(amount)}`;
    case 'weekly_price':
      return `week${plural(amount)}`;
    case 'nightly_price':
      return `night${plural(amount)}`;
    case 'person_price':
      return amount > 1 ? 'people' : 'person';
    default:
      return null;
  }
};

export const getDisplayName = (WrappedComponent: ComponentType) => (WrappedComponent.displayName || WrappedComponent.name || 'Component');

export const getBoatHeadline = (boat: FullBoatDetail): string => {
  /**
   * Handle the case where the boat is not yet loaded.
   */
  if (!boat || Object.keys(boat).length === 0) return '';

  return boat.shortname || boat.headline;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const dateFormat = (dateString: string | undefined, _name?: string) => moment(dateString);
export const dateParse = (dateMoment: moment.Moment) => (moment.isMoment(dateMoment)
  ? dateMoment.format('YYYY-MM-DD')
  : undefined);

export const defined = (val: unknown) => typeof val !== 'undefined';

export const parseBooleanString = (value: 'true' | 'false') => value === 'true';

export const getReturnDate = (
  date?: moment.MomentInput,
  duration?: moment.Duration,
  { isNight }: { isNight?: boolean } = { isNight: false },
): moment.MomentInput | undefined => {
  if (duration && date) {
    const dayTotal = duration.days();
    if (isNight) return moment(date).add(duration.asDays(), 'days');
    if (dayTotal > 1) {
      const daysToAdd = dayTotal - 1;
      return moment(date).add(daysToAdd, 'days');
    }
    return moment(date);
  }
  return undefined;
};
/**
 * Handles bad HTTP responses by logging the error and throwing a formatted error message
 *
 * @param {Response} response - The HTTP Response object that indicates a failure
 * @throws {Error} - Throws an error with formatted message containing status code and text
 */

export const handleBadResponse = (response: Response) => {
  fetchCatch(response);
  throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
};
