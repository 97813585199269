import { captureException as sentryCaptureException } from '@sentry/browser';
import type { CaptureContext } from '@sentry/types';

const captureException = (exception: unknown, captureContext?: CaptureContext) => {
  if (__SENTRY_DSN__) {
    sentryCaptureException(exception, captureContext);
  } else {
    // This is for development only
    // eslint-disable-next-line no-console
    console.error(exception, captureContext);
  }
};

export default captureException;
