import React, { FC } from 'react';

import { getClassNameFor } from '../../../common/helpers';
import s from './TypingIndicator.module.scss';

type Props = {
  classNameModifier?: string;
};

const TypingIndicator: FC<Props> = ({ classNameModifier }) => (
  <div className={getClassNameFor(s, 'root', classNameModifier)}>
    <div className={s.dot} />
    <div className={s.dot} />
    <div className={s.dot} />
  </div>
);

export default TypingIndicator;
