import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactTelephoneInput, { Country } from 'react-phone-number-input';
import { FieldMetaState, FieldInputProps } from 'react-final-form';
import classNames from 'classnames';

import { setCountryCode } from 'src/common/ducks/countryCode';
import { getCountryCode } from 'src/common/utils/reduxStoreSelectors';
import { getClassNameFor } from '../../helpers';
import FieldWrapper from '../inputs/FieldWrapper';
import s from './PhoneField.module.scss';

type PhoneWrapperProps = {
  classNameModifier?: string;
  input: FieldInputProps<any, HTMLElement>;
  label?: string;
  meta: FieldMetaState<string>;
  countryCode?: Country;
  placeholder?: string;
};

type CountryOption = '|' | '...' | Country;
// Sets up the preferred countries. | is just the separator between preferred and the rest.
const countryOptions: CountryOption[] = [
  'US', 'CA', 'GB', 'IT', 'DE', 'NL', 'ES', 'FR', 'HR', 'GR', '|', '...',
];

const PhoneWrapper: FC<PhoneWrapperProps> = ({
  classNameModifier,
  input,
  label,
  meta: { touched, error },
  placeholder,
}) => {
  const modifiers = classNames(classNameModifier, { error: touched && error });
  const countryCode = useSelector(getCountryCode) ?? 'US';
  const dispatch = useDispatch();
  const handleCountryChange = (country: string | undefined) => {
    if (country) dispatch(setCountryCode(country));
  };

  return (
    <FieldWrapper
      id={input.name}
      touched={touched}
      error={error}
      label={label}
      rootClassName={getClassNameFor(s, 'root', modifiers)}
      errorClassName={s.errorMessage}
    >
      <ReactTelephoneInput
        id={input.name}
        defaultCountry={countryCode}
        countryOptionsOrder={countryOptions}
        countrySelectProps={{ unicodeFlags: true }}
        onCountryChange={handleCountryChange}
        placeholder={placeholder}
        {...input}
      />
    </FieldWrapper>
  );
};

export default PhoneWrapper;
