import { fromJS, Map } from 'immutable';

import apiFetch, { backendFetch } from '../../core/fetch';

// This is empty because we're letting most of the
// defaults come from core/fetch
const defaultOptions = Map();
// app/reducer/action
export const SET_BACKEND_OPTIONS = 'common/fetch/SET_OPTIONS';
export const CLEAR_BACKEND_OPTIONS = 'common/fetch/CLEAR_OPTIONS';
export const setBackendOptions = options => ({ type: SET_BACKEND_OPTIONS, options });
export const clearBackendOptions = () => ({ type: CLEAR_BACKEND_OPTIONS });

const headerWhitelist = [
  // TODO: see if there are any others that need to be sent along
  'user-agent',
  'x-real-ip',
  'x-forwarded-for',
  'x-request-id',
  'remote_addr',
  'cookie',
  'host',
];
const filterHeaders = headersMap => (
  headersMap && headersMap.filter((value, header) => headerWhitelist.includes(header)));

const updateUserAgent = headersMap => {
  if (!headersMap) return Map({ 'user-agent': 'GetMyBoat/React' });

  if (headersMap.get('user-agent') !== 'GetMyBot') {
    return headersMap.set('user-agent', 'GetMyBoat/React');
  }
  return headersMap;
};

const setXForwardedProto = headersMap => (
  headersMap.set('X-Forwarded-Proto', 'https')
);

// Reducer
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (options = defaultOptions, action) => {
  switch (action.type) {
    case SET_BACKEND_OPTIONS:
      return fromJS(action.options)
        .update('headers', filterHeaders)
        // A way to tell the backend where the requests are coming from
        .update('headers', updateUserAgent)
        // Tell the service that this is a https call
        .update('headers', setXForwardedProto)
        // // set x-forwarded-for to x-real-ip for django talky purposes
        // TODO: it seems like this should be handled at the mesh level, not at the
        // application level.
        .update('headers', headers => headers && headers.set('x-forwarded-for', headers.get('x-real-ip')));
    case CLEAR_BACKEND_OPTIONS:
      return defaultOptions;
    default:
      return options;
  }
};

export const stateWrapper = fetchish => (uri, options = {}) => (dispatch, getState) => {
  const optionsState = getState().fetch;
  const optionsForRequest = optionsState.mergeDeep(options).toJS();
  return fetchish(uri, optionsForRequest);
};

export const apiFetchThunk = stateWrapper(apiFetch);
export const backendFetchThunk = stateWrapper(backendFetch);

// This is an experiment. There are a lot of places in our ducks
// where we just request an endpoint and take the response and stick
// it straight into a reducer, without any fancy processing or
// error handling. This should provide a convenient wrapper for that.
export const fetchEntity = (uri, actionCreator) => (
  async dispatch => {
    const response = await dispatch(apiFetchThunk(uri));
    if (!response.ok) {
      throw response;
    }
    const entity = await response.json().then(fromJS);
    dispatch(actionCreator(entity));
    return entity;
  }
);
