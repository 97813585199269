import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { getClassNameFor } from '../../../common/helpers';
import Avatar from '../../../common/components/Avatar';
import { FIELDS } from '../pages/Complete/reviewsConfig';
import RecommendedMessage from './partials/RecommendedMessage';
import ReviewCategories from './partials/Categories';
import RatedMessage from './partials/RatedMessage';
import s from './ReviewItem.module.scss';

const {
  RATING,
  RECOMMENDATION,
  PRIVATE_NOTE,
} = FIELDS;

const ReviewItem = ({
  review,
  hidden,
  trip,
  reviewCategory,
}) => {
  const user = review.get('user', Map());
  // Determine review type. Only boat reviews have `boat` property
  const reviewType = review.has('boat') ? 'boat' : 'renter';
  const modifiers = [
    reviewType,
    hidden ? 'hidden' : '',
  ].join(' ');
  const rating = review.get(RATING);
  const recommendation = review.get(RECOMMENDATION);
  const userSentiment = rating >= 3 || recommendation === true ? 'positive' : 'negative';
  const renderRecommendation = typeof recommendation === 'boolean';
  // Give recommendation precedence over rating
  const renderRating = typeof rating === 'number' && !renderRecommendation;
  const boatHeadline = trip.getIn(['boat', 'headline']);

  return (
    <li
      title={__DEV__ ? `${userSentiment} ${reviewCategory} ${reviewType} review` : null}
      className={getClassNameFor(s, 'root', modifiers)}
    >
      <Avatar
        user={user.toJS()}
        size="small"
        extraClassName={s.avatar}
      />
      <div className={getClassNameFor(s, 'comment', modifiers)}>
        {reviewCategory === 'public' && !hidden && (
          <>
            {renderRecommendation && (
              <RecommendedMessage
                boatHeadline={boatHeadline}
                trip={trip}
                review={review}
              />
            )}
            {renderRating && (
              <RatedMessage
                boatHeadline={boatHeadline}
                review={review}
              />
            )}
          </>
        )}
        {reviewCategory === 'private' && !hidden && review.get(PRIVATE_NOTE)}
        {reviewCategory === 'categories' && !hidden && (
          <ReviewCategories
            review={review}
          />
        )}
      </div>
    </li>
  );
};

ReviewItem.propTypes = {
  review: PropTypes.object,
  hidden: PropTypes.bool,
  reviewCategory: PropTypes.string,
  trip: PropTypes.object,
};

export default ReviewItem;
