import React, { FC } from 'react';
import CopyLink from 'src/common/components/CopyLink';
import getQRCodeUrl from 'src/common/utils/getQRCodeUrl';
import s from './TripPaymentLink.module.scss';

export type Props = {
  textLink: string;
};

const WIDTH = 250;

const TripPaymentLink: FC<Props> = ({ textLink }) => {
  const qrcodeUrl = getQRCodeUrl(textLink);

  return (
    <div className={s.root}>
      <CopyLink
        textToCopy={textLink}
        buttonLabel="Copy Payment Link"
        successLabel="Payment Link Copied!"
      />
      <img src={qrcodeUrl} width={WIDTH} height={WIDTH} alt={textLink} />
    </div>
  );
};

export default TripPaymentLink;
