import { useCallback, useState, useMemo } from 'react';

import apiFetch, { backendFetch } from '../../core/fetch';
import { noop, plural } from '../helpers';
import captureException from '../utils/captureException';
import { trackEvent } from '../tracking';

const GA_EVENT_CATEGORY = 'Photos';
const FILE_SIZE_LIMIT = 10485760; // 10 MB

// Borrowed from https://stackoverflow.com/a/18650828
const formatSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

const renameImageFile = name => name.replace('.', `${Date.now()}.`);

const presignedPost = () => apiFetch('/photos/presigned-post/')
  .then(response => {
    if (!response.ok) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw response;
    }
    return response.json();
  });

const postImage = (formData, url) => backendFetch(url, {
  method: 'POST',
  headers: { 'Content-Type': undefined },
  body: formData,
});

const parseResponseXml = text => new window.DOMParser().parseFromString(text, 'application/xml');

const getUploadKey = xmldoc => xmldoc.getElementsByTagName('Key')[0]?.childNodes[0].nodeValue;

const checkDuplicate = (images, currentFile) => images.some(({ file }) => (
  file.name === currentFile.name
  && file.size === currentFile.size
  && file.lastModified === currentFile.lastModified
));

const usePhotoUploader = ({
  onSubmitSuccess = noop,
  onUploadSuccess,
  openModal,
  closeModal,
}) => {
  const initialState = useMemo(() => ({
    images: [],
    activeImageUrl: null,
    submitting: false,
    error: null,
    warning: null,
  }), []);
  const [state, setState] = useState(initialState);

  const uploadFile = (fields, file, url, index) => {
    const formData = new FormData();
    // Populate formData with fields from presigned post
    Object.entries(fields).forEach(([key, value]) => formData.append(key, value));
    // Populate form data with individual image
    formData.append('file', file, renameImageFile(file.name));

    setState(prevState => {
      const newImages = prevState.images;
      newImages[index].uploading = true;
      return {
        ...prevState,
        images: newImages,
      };
    });

    return postImage(formData, url)
      .then(response => response.text())
      // TODO: make this backend-safe. It's technically only likely to be called
      // on the frontend.
      // This is also something that would probably be better done with the s3 sdk,
      // but this is a hackaround on the meantime
      .then(text => {
        setState(prevState => {
          const newImages = prevState.images;
          newImages[index].uploading = false;
          newImages[index].uploaded = true;
          return {
            ...prevState,
            images: newImages,
          };
        });
        return parseResponseXml(text);
      })
      // TODO: add error handling for when this parsing inevitably fails.
      .then(getUploadKey);
  };

  const handleLoadStart = (fileReader, file) => {
    // Filter out duplicates
    if (checkDuplicate(state.images, file)) {
      return fileReader.abort();
    }

    if (file.size > FILE_SIZE_LIMIT) {
      setState(prevState => ({
        ...prevState,
        warning: `File size (${formatSize(file.size)}) of ${file.name} exceeds the ${formatSize(FILE_SIZE_LIMIT)} limit. Please upload a smaller sized image.`,
      }));
      return fileReader.abort();
    }

    return setState(prevState => ({
      ...prevState,
      images: [
        {
          file,
          loading: true,
        },
        ...prevState.images,
      ],
    }));
  };

  const handleLoad = (previewUrl, file) => setState(
    prevState => ({
      ...prevState,
      images: prevState.images.map(image => {
        if (image.file === file) {
          return {
            ...image,
            previewUrl,
            loading: false,
          };
        }
        return image;
      }),
    }),
  );

  const handleSubmitError = errorObject => {
    const errorDetails = errorObject.message // error from JS exception
      || errorObject.statusText; // error from network rejection,
    setState(prevState => ({
      ...prevState,
      error: `Something went wrong (${errorDetails}). Please try again later.`,
      submitting: false,
    }));
    return captureException(errorObject);
  };

  const addImage = file => {
    const reader = new FileReader();
    reader.onloadstart = ({ target }) => handleLoadStart(target, file);
    reader.onload = ({ target: { result } }) => handleLoad(result, file);
    reader.readAsDataURL(file);
  };

  const handleChange = files => {
    const filesArray = Array.from(files);
    setState(prevState => ({
      ...prevState,
      error: null,
      successMessage: null,
      warning: null,
    }));
    trackEvent('Added', {
      event_category: GA_EVENT_CATEGORY,
      value: filesArray.length,
    });
    return filesArray.forEach(addImage);
  };

  const uploadImages = useCallback(
    () => presignedPost()
      .then(({ url, fields }) => Promise.all(
        state.images.map(({ file }, index) => (
          uploadFile(fields, file, url, index)
        )),
      )
        .then(keys => onUploadSuccess(keys))),
    [state.images, onUploadSuccess],
  );

  const resetState = useCallback(
    () => setState(initialState),
    [initialState],
  );

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      setState(prevState => ({
        ...prevState,
        submitting: true,
        warning: null,
      }));
      uploadImages()
        .then(() => {
          const numberOfImages = state.images.length;
          setState({
            ...initialState,
            successMessage: `${numberOfImages} photo${plural(numberOfImages)} saved to your review!`,
          });
          trackEvent('Uploaded', {
            event_category: GA_EVENT_CATEGORY,
            value: numberOfImages,
          });
          onSubmitSuccess();
        })
        .catch(handleSubmitError);
    },
    [initialState, onSubmitSuccess, state.images.length, uploadImages],
  );

  const openImage = url => {
    setState(prevState => ({
      ...prevState,
      activeImageUrl: url,
    }));
    trackEvent('Previewed', {
      event_category: GA_EVENT_CATEGORY,
      value: 1,
    });
    openModal();
  };

  const removeImage = () => {
    setState(prevState => ({
      ...prevState,
      activeImageUrl: null,
      images: prevState.images.filter(
        ({ previewUrl }) => previewUrl !== prevState.activeImageUrl,
      ),
    }));
    trackEvent('Removed', {
      event_category: GA_EVENT_CATEGORY,
      value: 1,
    });
    closeModal();
  };

  return {
    ...state,
    handleChange,
    handleSubmit,
    openImage,
    removeImage,
    resetState,
  };
};

export default usePhotoUploader;
