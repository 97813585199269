import React, { FC } from 'react';
import type { ImmutableTrip } from 'src/types/trips/Trips';
import { formatGroupSize } from 'src/common/helpers';
import { totalGuests } from 'src/common/components/GroupSizeDetails';
import { CAPTAINED_FIELD, GUESTS_FIELD } from 'src/inbox/constants';
import useCaptainData from 'src/common/hooks/useCaptainData';
import getCaptainedCopy from 'src/common/utils/getCaptainedCopy';
import { useTripDetails } from 'src/inbox/hooks';
import { MOMENT_FORMAT } from 'src/common/constants';
import s from './MainTripDetails.module.scss';

const dateTimeFormatPattern = 'YYYY-MM-DD HH:mm';
const timeFormatPattern = MOMENT_FORMAT.TIME;
const dateFormatPattern = 'ddd MMM D, YYYY';

type MainTripDetailsProps = {
  trip: ImmutableTrip;
};

const MainTripDetails: FC<MainTripDetailsProps> = ({ trip }) => {
  const captained = trip.get(CAPTAINED_FIELD);
  const isInstabook = trip.get('is_instabook');
  const { captainOptionHeading } = useCaptainData(trip);
  const {
    heading: instabookCaptainedHeading,
    body: instabookCaptainedBody,
    // Defaulting `captain_cost_included` to true as this field isn't present on trips endpoint
  } = getCaptainedCopy(captained, trip.get('captain_cost_included', true));

  const {
    departMoment,
    hasTripDepartTime,
    returnMoment,
    tripLengthString,
  } = useTripDetails(trip);

  return (
    <ul className={s.root}>
      <li className={s.section}>
        <dl className={s.detail}>
          <dt className={s.term}>
            Depart
          </dt>
          {hasTripDepartTime && (
            <dd className={s.description}>
              <time
                dateTime={departMoment.format(dateTimeFormatPattern)}
                data-test="depart-time-label"
              >
                {departMoment.format(timeFormatPattern)}
              </time>
            </dd>
          )}
          <dd className={s.description}>
            <time
              dateTime={departMoment.format(dateTimeFormatPattern)}
              data-test="depart-date-label"
            >
              {departMoment.format(dateFormatPattern)}
            </time>
          </dd>
        </dl>
        <dl className={s.detail}>
          <dt className={s.term}>
            Return
          </dt>
          {hasTripDepartTime && (
            <dd className={s.description}>
              <time
                dateTime={returnMoment.format(dateTimeFormatPattern)}
                data-test="return-time-label"
              >
                {returnMoment.format(timeFormatPattern)}
              </time>
            </dd>
          )}
          <dd className={s.description}>
            <time
              dateTime={returnMoment.format(dateTimeFormatPattern)}
              data-test="return-date-label"
            >
              {returnMoment.format(dateFormatPattern)}
            </time>
          </dd>
        </dl>
      </li>
      <li className={s.section}>
        <dl className={s.detail}>
          <dt className={s.term}>
            Duration
          </dt>
          <dd className={s.description}>
            {tripLengthString}
          </dd>
        </dl>
        <dl className={s.detail}>
          <dt className={s.term}>
            Group Size
          </dt>
          <dd className={s.description}>
            {formatGroupSize(totalGuests(trip.get('guests')), GUESTS_FIELD)}
          </dd>
        </dl>
      </li>
      <li className={s.section}>
        <dl className={s.detail}>
          <dt className={s.term}>
            {isInstabook ? instabookCaptainedHeading : captainOptionHeading.heading}
          </dt>
          <dd className={s.description}>
            {isInstabook ? instabookCaptainedBody : captainOptionHeading.subHeading}
          </dd>
        </dl>
      </li>
    </ul>
  );
};

export default MainTripDetails;
