import React from 'react';

import ExternalLink from '../../../common/components/ExternalLink';
import s from './CommentMessage.module.scss';

const CommentMessage = () => (
  <p className={s.root}>
    For safety and security, personal contact info is hidden until the booking is confirmed.
    {' '}
    <ExternalLink
      to="https://getmyboat.zendesk.com/hc/en-us/articles/115003341708-Why-should-I-use-the-Getmyboat-website-to-handle-payments"
    >
      Find out more
    </ExternalLink>.
  </p>
);

export default CommentMessage;
