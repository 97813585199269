import React from 'react';
import { Map } from 'immutable';

import type { ImmutableRenter } from 'src/types/user/User';
import { ImmutableUser } from 'src/types/user/User';
import { getClassNameFor } from '../../../../common/helpers';
import Avatar from '../../../../common/components/Avatar';
import s from './UserBlock.module.scss';

type UserBlockProps = {
  children: React.ReactNode;
  classNameModifier?: string;
  user: ImmutableUser | ImmutableRenter;
};

const UserBlock: React.FC<UserBlockProps> = ({ children, classNameModifier, user = Map() }) => (
  <div
    className={s.root}
  >
    {!user.isEmpty() && (
      <Avatar
        user={user.toJS()}
        size="medium"
      />
    )}
    <div
      className={getClassNameFor(s, 'content', classNameModifier)}
      data-test="user-block-text"
    >
      {children}
    </div>
  </div>
);

export default UserBlock;
