import emailValidator from 'email-validator';
import { FORM_ERROR } from 'final-form';
import type { ReactNode } from 'react';
import { isBefore } from 'date-fns';
import asyncPhoneValidator from './asyncPhoneValidator';
import usaZipCodeValidator from './usaZipCodeValidator';
import { convertToTimeZone } from '../utils/time';

/**
 * @returns string size in bytes
 */
const byteCount = (string: string) => new Blob([string]).size;

export const rffValidators = {
  /**
   * These are field-level validators.
   */
  // TODO: write some tests
  required: ({ message }: { message: string }) => (
    (value: any) => (value ? undefined : message)
  ),

  defined: ({ message }: { message: string }) => (
    (value: any) => (value !== undefined ? undefined : message)
  ),

  notNull: ({ message }: { message: string }) => (
    (value: any) => (value !== null ? undefined : message)
  ),

  boolean: ({ message }: { message: string }) => (
    (value: boolean) => (typeof value === 'boolean' ? undefined : message)
  ),

  number: ({ message }: { message: string }) => (
    (value: any) => (!Number.isNaN(Number(value)) ? undefined : message)
  ),

  minLength: ({ message, length }: { message: string; length: number }) => (
    (value: string) => ((value.length >= length) ? undefined : message)
  ),

  maxLength: ({ message, length }: { message: string; length: number }) => (
    (value: string) => ((value.length <= length) ? undefined : message)
  ),

  maxSize: ({ message, size }: { message: string; size: number }) => (
    (value: string) => ((byteCount(value) <= size) ? undefined : message)
  ),

  email: ({ message }: { message: string }) => (
    (value: string) => (emailValidator.validate(value) ? undefined : message)
  ),

  usaZipCode: ({ message }: { message: string }) => (
    (value: string) => (usaZipCodeValidator(value) ? undefined : message)
  ),

  matches: ({ message, field }: { message: string; field: string }) => (
    (value: string, allValues: Record<string, string>) => (
      (value === allValues[field]) ? undefined : message
    )
  ),

  // This is a very crude first pass, but it's preferable to libphonenumber because
  // it's very lightweight.
  phone: ({ message }: { message: string }) => (
    (value: string) => {
      // Get rid of whitespace
      const trimmed = value.replace(/\s/g, '');
      // We're looking for alphanums and +-()
      const filtered = trimmed.replace(/[^+\-()0-9]/g, '');
      if (trimmed !== filtered) {
        return message;
      }
      // 6 seems right ish?
      if (filtered.length < 6) {
        return message;
      }
      return undefined;
    }
  ),

  regex: ({ message, regex }: { message: string; regex: RegExp }) => (
    // NOTE:
    // This checks for value because it's one of the few validators that
    // we use without requiring the user to enter a value.
    (value: string) => (value && !regex.test(value)
      ? message
      : undefined
    )
  ),

  /**
   * Validate the phone number on the server.
   */
  asyncPhoneValidation: ({ messageFn }: AsyncPhoneValidationArgs) => (
    (phone: string) => asyncPhoneValidator(phone).then(
      message => (message ? messageFn(message) : undefined),
    )
  ),
};

type AsyncPhoneValidationArgs = {
  /**
   * `messageFn` is a function that takes the return from the validation
   * endpoint and returns the desired output. This is to allow for
   * JSX wrapping and the like.
   */
  messageFn: (message: string) => ReactNode;
};

type Validator = (...args: any[]) => ReactNode | undefined;

// Note that this runs validators in order, so if there's a required validator,
// it will catch falsy values before they error on other values.
export const combineValidators = (givenValidators: Validator[]) => (
  // stolen from react-final-form docs
  // The way this is written is clever - if you've got a required validator, it's going
  // to run that first, return the error, then not run the other validators, which means
  // you don't have to worry about undefined values in downstream validators.
  (...args: any[]) => givenValidators
    .reduce<ReactNode | undefined>((error, validator) => error || validator(...args), undefined)
);

export const tripLengthValidator = values => (
  !values.trip_length || (values.trip_length && (
    (values.trip_length.days > 0
      || values.trip_length.hours > 0
      || values.trip_length.minutes > 0
    ))
  )
    ? {}
    : { _error: 'Your trip must be greater than 1 minute' }
);

export const rffTripLengthValidator = values => (
  Object.values(values).some((value = 0) => value > 0)
    ? {}
    : { [FORM_ERROR]: 'Your trip must be greater than 1 minute' }
);

export const rffTripLengthHourValidator = values => (
  Object.values(values).some((value = 0) => value > 0)
    ? {}
    : { [FORM_ERROR]: 'Your trip must be greater than 1 hour' }
);

export const rffTripLengthDayNightValidator = (isDay: boolean) => (values) => (
  Object.values(values).some((value = 0) => value > 0)
    ? {}
    : { [FORM_ERROR]: `Your trip must be greater than 1 ${isDay ? 'day' : 'night'}` }
);

export const groupSizeValidator = values => {
  const { adults, seniors } = values;
  const grownUpTotal = adults + seniors;
  if (grownUpTotal === 0) {
    return { _error: 'There must be at least one adult on the trip.' };
  }
  return {};
};

export const rffGroupSizeValidator = values => {
  const { adults, seniors } = values;
  const grownUpTotal = adults + seniors;
  if (grownUpTotal === 0) {
    return { [FORM_ERROR]: 'There must be at least one adult on the trip.' };
  }
  return {};
};

export const rffDepartureTimeValidator = (values, boatTimezoneId) => {
  const {
    pickup_time: pickupTime,
    preferred_date1: preferredDate,
  } = values;

  if (!pickupTime || !preferredDate) return {};

  const departureDate = new Date(`${preferredDate} ${pickupTime}`);
  const currentDateInBoatTimezone = convertToTimeZone(new Date(), boatTimezoneId || undefined);
  const isPast = isBefore(departureDate, currentDateInBoatTimezone);

  if (isPast) {
    return { [FORM_ERROR]: 'Please select a departure time from now onwards.' };
  }

  return {};
};
