import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { trackEvent } from 'src/common/tracking';
import { rffSubmitResponse } from '../../../../common/helpers';
import { submitBroadcastBoats } from '../../../submit';
import { BROADCAST_BOATS_FIELD, PAGES } from '../../../constants';
import useOnSubmitLocationChange from '../../../../common/hooks/useOnSubmitLocationChange';
import CloseButton from '../../../../common/components/CloseButton';
import Button from '../../../../common/components/Button';
import FormError from '../../../../common/components/FormError';
import SimilarListingsPanel from '../../SimilarListings';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import {
  getBroadcastBoats,
  getInquiryId,
  getNextInquiryPage, getSimilarListingsInitialValues,
} from '../../../../common/utils/reduxStoreSelectors';
import s from './SimilarListings.module.scss';

type SimilarListingsProps = {
  inquiryHasInstabookListings?: boolean;
};

const GA4_EVENT_SEND_INQUIRY_SIMILAR = 'send_inquiry_s9_similar';

const SimilarListings: FC<SimilarListingsProps> = ({ inquiryHasInstabookListings }) => {
  const location = useLocation();
  const inquiryId = useSelector(getInquiryId);
  const initialValues = useSelector(getSimilarListingsInitialValues);
  const broadcastBoats = useSelector(getBroadcastBoats);
  const nextPage = useSelector(getNextInquiryPage);

  const returnUrl = getReturnUrl(location);
  const onSubmitSuccess = useOnSubmitLocationChange(
    inquiryHasInstabookListings ? PAGES.INSTABOOK : nextPage,
  );
  let trackEventMessage: string = 'Yes';
  const onSubmit = useCallback(
    values => {
      trackEvent(GA4_EVENT_SEND_INQUIRY_SIMILAR, { p1: trackEventMessage });
      const boatIds = values.broadcast_boats;
      if (boatIds.length) {
        submitBroadcastBoats({ boats: boatIds, inquiryId })
          .then(onSubmitSuccess)
          .catch(rffSubmitResponse());
      } else {
        onSubmitSuccess();
      }
    },
    [
      onSubmitSuccess,
      trackEventMessage,
      inquiryId,
    ],
  );
  return (
    <div className={s.container}>
      <Helmet>
        <title>
          Similar Listings
        </title>
      </Helmet>
      <div className={s.message}>
        <h1 className={s.title}>
          Similar Listings
        </h1>
        <p>
          We can help speed up your search by sending your inquiry to
          these similar listings in the area. Would you like us to do that?
        </p>
      </div>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitError, values, form: { change } }) => (
          <form onSubmit={handleSubmit} className={s.listingForm}>
            <FormError error={submitError} className={s.error} />
            <SimilarListingsPanel
              broadcastBoats={broadcastBoats}
            />
            <div className={s.buttonGroup}>
              <Button
                type="submit"
              // This clears out the broadcast boats selection
              // NOTE: we're depending on this firing before the submission logic
                onClick={() => {
                  change(BROADCAST_BOATS_FIELD, []);
                  trackEventMessage = 'No';
                }}
                classNameModifier="paired secondary"
                fullWidth
              >
                No Thanks
              </Button>
              <Button
                type="submit"
                classNameModifier="paired"
              // Indicates that all checkboxes are deselected
                disabled={(
                values[BROADCAST_BOATS_FIELD] == null || values[BROADCAST_BOATS_FIELD]?.length === 0
                )}
                fullWidth
              >
                Yes Please
              </Button>
            </div>
          </form>
        )}
      </Form>
      <CloseButton backUrl={returnUrl} />
    </div>
  );
};

export default SimilarListings;
