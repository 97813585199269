/* eslint-disable import/prefer-default-export */

import { Currency } from '../types';

// Checking for support for locales and options arguments
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#Checking_for_support_for_locales_and_options_arguments
const toLocaleStringSupportsOptions = () => !!(
  typeof Intl === 'object'
  && Intl
  && typeof Intl.NumberFormat === 'function'
);

// Strip off the ;q=1 from a language tag that looks like en-AU;q=1
export const parseLanguage = (language: string) => language.split(';')[0];

// Get preferred user's locale from browser
// https://stackoverflow.com/questions/673905/best-way-to-determine-users-locale-within-browser
const getUserLocale = () => {
  let locale = 'en-US';

  if (typeof navigator !== 'undefined') {
    locale = parseLanguage((navigator.languages && navigator.languages.length)
      ? navigator.languages[0]
      : navigator.language);
  }

  return locale;
};

// Currency formatter
export const userLocaleDependantPriceFormatter = toLocaleStringSupportsOptions()
  // If Intl is available, use the smart formatter
  ? ((number: number, currencyCode: string) => (
    new Intl.NumberFormat(
      getUserLocale(),
      {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
      },
    ).format(number)))
  // Otherwise we'll use a fallback
  : ((number: number, currencyCode: string) => `${number.toLocaleString()} ${currencyCode}`);

export const priceFormatter = (
  amount: number,
  currencyCode: string,
  precision = 0,
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: currencyCode,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
  return formatter.format(amount);
};

export const completePriceFormatter = (
  amount: number,
  currencyCode: string,
  currencySymbol: string,
  precision = 0,
  showCurrencyCode = false,
): string => {
  const formattedAmount = priceFormatter(amount, currencyCode, precision);
  const base = `${currencySymbol}${formattedAmount}`;
  return showCurrencyCode ? `${currencyCode} ${base}` : base;
};

export const formatCurrencyLabel = ({ code, symbol }: Currency): string => {
  const currencyCode = symbol === '$' ? code.slice(0, 2) : code;
  const currencySymbol = symbol !== code ? symbol : '';
  // 0xa0 is the non-breakable space character
  return `${currencyCode}\xa0${currencySymbol}`.trim();
};
