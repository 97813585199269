import React, { FunctionComponent, KeyboardEvent } from 'react';
import { FieldMetaState } from 'react-final-form';

import { isMobile, getClassNameFor } from '../../../helpers';
import { useTimeInput } from '../../../hooks';
import Svg from '../../Svg';
import s from './TimeInput.module.scss';
import Icon from '../../IconDS22';

type TimeInputProps = {
  input: any;
  meta: FieldMetaState<string>;
  autoFocus?: boolean;
  classNameModifier?: string;
  extraInputAttrs?: {
    min: string;
    max: string;
    step: number;
  };
  disablePopup?: boolean;
  readOnly?: boolean;
  showErrorIcon? : boolean;
  showErrorWhenSubmitFailed?: boolean;
};

const TimeInput: FunctionComponent<TimeInputProps> = ({
  input,
  meta: {
    error,
    submitFailed,
    valid,
  },
  autoFocus = true,
  classNameModifier,
  extraInputAttrs = {},
  disablePopup = false,
  readOnly = false,
  showErrorIcon = false,
  showErrorWhenSubmitFailed = false,
}) => {
  const showError = showErrorWhenSubmitFailed ? submitFailed && error : !valid && error;
  const modifiers = [
    !showError ? '' : 'error',
    classNameModifier,
  ].join(' ');
  const { timeInputIsSupported } = useTimeInput();
  const onKeyDown = (event: KeyboardEvent) => {
    const SPACE_BAR = ' ';
    if (disablePopup && event.key === SPACE_BAR) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className={getClassNameFor(s, 'root', modifiers)}>
        <label
          htmlFor={input.name}
          className={getClassNameFor(s, 'label', modifiers)}
        >
          <Svg
            href="#old-icon-time"
            className={s.icon}
          />
        </label>
        <input
          readOnly={readOnly}
          title={timeInputIsSupported === false ? 'Please enter time in `09:00 AM` format' : ''}
          id={input.name}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus && !isMobile()}
          placeholder="--:-- --" // is for fallback
          maxLength={8} // is for fallback
          size={8} // is for fallback (field size in characters)
          className={getClassNameFor(s, 'input', modifiers)}
          onKeyDown={onKeyDown}
          {...input}
          // This is down below the input spread because otherwise the `input` supplies
          // its own type, and the assumption here is that we're always
          // going to want this to be `time`.
          type="time"
          {...extraInputAttrs}
        />
      </div>
      {showError && (
        <label
          htmlFor={input.name}
          className={getClassNameFor(s, 'error', modifiers)}
        >
          {showErrorIcon && <Icon id="info-filled" size="xs" />}
          {error}
        </label>
      )}
    </>
  );
};

export default TimeInput;
