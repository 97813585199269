import React, { FunctionComponent } from 'react';
import { HotKeys } from 'react-hotkeys';
import { FieldRenderProps } from 'react-final-form';

import ToggleButton from '../../../common/components/ToggleButton';
import Dropdown from '../../../common/components/Dropdown';
import FilterContainer from '../../../common/components/FilterContainer';
import RadioButton from '../../../common/components/inputs/RadioButton';

import s from './InstabookDepartureTimeDropdown.module.scss';

const options = [
  {
    title: 'Flexible Departure Time',
    value: 'flexible',
    flexible: true,
  },
  {
    title: 'Fixed Departure Times',
    value: 'fixed',
    flexible: false,
  },
];

const InstabookDepartureTimeDropdown: FunctionComponent<FieldRenderProps<boolean>> = ({
  input,
  disabled,
}) => {
  const activeOption = options.find(option => option.flexible === input.value) || options[0];
  return (
    <Dropdown className={s.root}>
      {({ toggle, isOpen, close }) => (
        <div className={s.menu}>
          <HotKeys
            handlers={{
              toggle: event => {
                if (event) event.preventDefault();
                toggle();
              },
            }}
            keyMap={{ toggle: 'space' }}
          >
            <ToggleButton
              isOpen={isOpen}
              onClick={toggle}
              classNameModifier="instabook"
              disabled={disabled}
            >
              {activeOption.title}
            </ToggleButton>
          </HotKeys>
          {isOpen && (
            <FilterContainer classNameModifier="instabookEditor">
              {options.map(option => (
                <button
                  type="button"
                  key={option.value}
                  className={s.option}
                  onClick={() => {
                    input.onChange(option.value === 'flexible');
                    close();
                  }}
                >
                  <RadioButton
                    input={{
                      checked: input.value === option.flexible,
                      tabIndex: -1,
                      // Noop onChange handler added to prevent React warnings as the state
                      // change is done using the wrapping button.
                      onChange: () => { },
                    }}
                  />
                  <span className={s.description}>
                    {option.title}
                  </span>
                </button>
              ))}
            </FilterContainer>
          )}
        </div>
      )}
    </Dropdown>
  );
};

export default InstabookDepartureTimeDropdown;
