import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-final-form';

import { useSelector } from 'react-redux';
import { getCountryCode, getUser } from 'src/common/utils/reduxStoreSelectors';
import { rffValidators } from '../../../validation';
import YesNoFields from '../YesNoFields';
import s from './MarketingConsent.module.scss';

type MarketingConsentProps = {
  isYesTrueOnDefault?: boolean;
};
const validate = rffValidators.boolean({
  message: 'Please indicate whether you would like to receive newsletters.',
});

const MarketingConsent: FC<MarketingConsentProps> = ({ isYesTrueOnDefault }) => (
  <fieldset className={s.root}>
    <h6 className={s.title}>
      Email Newsletters
    </h6>
    <p className={s.text}>
      Would you like to receive occasional emails
      from us with information about our water experiences?
    </p>
    <YesNoFields
      name="marketing_consent"
      validate={validate}
      isYesTrueOnDefault={isYesTrueOnDefault}
    />
  </fieldset>
);

type OptInRelatedFields = {
  phone?: string;
  marketing_consent?: boolean;
};

// Opts in US based users to marketing
// Uses IP to determine eligibility.
const OptInMarketingConsent = () => {
  const countryCode = useSelector(getCountryCode);
  const [revealed, setRevealed] = useState(countryCode !== 'US');
  const { change } = useForm<OptInRelatedFields>();
  const [autoOptIn, setAutoOptIn] = useState(false);
  const user = useSelector(getUser);
  const marketingConsentSelection = user.get('marketing_consent');

  useEffect(() => {
    const usIp = countryCode === 'US';
    const shouldReveal = !usIp;
    // Return early if it was previously set to `true`.
    setRevealed(prev => prev || shouldReveal);
    // Only set auto opt in when it is a US IP.
    setAutoOptIn(usIp);

    // Unset the prop if we're revealing the fields
    if (shouldReveal && marketingConsentSelection === undefined) {
      change('marketing_consent', undefined);
    }
  }, [change, countryCode, marketingConsentSelection, revealed]);

  return (
    <div hidden={!revealed}>
      <MarketingConsent isYesTrueOnDefault={autoOptIn} />
    </div>
  );
};

export default OptInMarketingConsent;
