import React from 'react';
import PropTypes from 'prop-types';

import { PATHS } from '../../../../common/constants';
import { preserveSearch } from '../../../../common/utils/routing';
import {
  CaptainCard,
  CommentCard,
  DatesCard,
  DurationCard,
  GroupSizeCard,
  TimeCard,
  VesselCard,
} from '../../cards';
import TripPanel from '../../presentation/TripPanel';
import TripMenu from '../../TripMenu';
import TripDetailsTitle from '../../presentation/TripDetailsTitle';
import { getMessage } from '../content';
import TopScroll from '../../../../common/components/TopScroll';
import CTA from '../../CTA';
import EditableTripDetails from '../../EditableTripDetails';

const RenterChangesRequested = ({
  location,
  trip,
}) => (
  <TripPanel
    trip={trip}
    subheader={trip.getIn(['state', 'badge'])}
    intro={getMessage(trip, location)}
    cta={(
      <CTA classNameModifier="withSidebar">
        <TripMenu trip={trip} />
      </CTA>
    )}
    backLocation={preserveSearch(PATHS.INBOX, location)}
    classNameModifier="withoutSideMargins"
  >
    <TopScroll />
    <TripDetailsTitle />
    <EditableTripDetails>
      <DatesCard
        readOnly
        trip={trip}
        checkTripModification
        requestedChanges
      />
      <TimeCard
        readOnly
        trip={trip}
        checkTripModification
        requestedChanges
      />
      <DurationCard
        readOnly
        trip={trip}
        checkTripModification
        requestedChanges
      />
      <GroupSizeCard
        readOnly
        trip={trip}
        checkTripModification
        requestedChanges
      />
    </EditableTripDetails>
    <VesselCard
      readOnly
      trip={trip}
    />
    <CaptainCard
      readOnly
      trip={trip}
    />
    <CommentCard
      readOnly
      trip={trip}
    />
  </TripPanel>
);

RenterChangesRequested.propTypes = {
  location: PropTypes.object,
  trip: PropTypes.object,
};

export default RenterChangesRequested;
