const helpMenuItems = [
  {
    text: 'How it Works',
    url: 'https://getmyboat.zendesk.com/hc/en-us/articles/115001860173-How-does-Getmyboat-work-',
  },
  {
    text: 'Service Fees',
    url: 'https://getmyboat.zendesk.com/hc/en-us/articles/115002314073-What-are-the-Getmyboat-Service-Fees-',
  },
  {
    text: 'Incidentals',
    url: 'https://getmyboat.zendesk.com/hc/en-us/articles/115003918448-What-is-the-incidental-coverage-limit-',
  },
  {
    text: 'Cancellations',
    url: 'https://getmyboat.zendesk.com/hc/en-us/articles/115002324894-How-does-Getmyboat-handle-cancellations-and-refunds',
  },
];

export default helpMenuItems;
