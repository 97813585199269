import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import inquiry from './booking-inquiry/ducks/inquiry';
import calendar from './calendar/ducks/calendar';
import { trips, transaction, reviews, messages, offer, paymentAddons, linkedCaptains } from './inbox/ducks';
import {
  boat,
  user,
  loading,
  zippy,
  currency,
  requestCurrency,
  price,
  fetch,
  boatMap,
  featureFlags,
  countryCode,
  locale,
  mobileNativeWebView,
  requestId,
} from './common/ducks';

/**
 * Word to the wise when it comes to these enhancers
 * and middleware: order matters.
 *
 * Case in point: If you don't put the logger enhancer
 * at the end, it'll screw up the shape of your store,
 * and your other enhancers won't know what to do with
 * it.
 */

// Not sure if this is quite the right place to put this.
// It might make more sense to push down.
export const rootReducer = combineReducers({
  user,
  currencies: currency,
  requestCurrency,
  zippies: zippy,
  boat,
  boatMap,
  loading,
  inquiry,
  trips,
  transaction,
  reviews,
  messages,
  price,
  fetch,
  offer,
  calendar,
  featureFlags,
  countryCode,
  paymentAddons,
  linkedCaptains,
  locale,
  mobileNativeWebView,
  requestId,
});

export default function configureStore(initialState, createLogger) {
  const middleware = [thunk];
  const enhancers = [];

  const reducer = rootReducer;

  if (__DEV__) {
    middleware.push(createLogger());
  }

  // The middleware has to be the first thing in
  // the enhancers list, or it won't work correctly,
  // hence this being here above the following __DEV__
  // block
  enhancers.push(applyMiddleware(...middleware));

  let composeEnhancers = compose;
  // Add redux dev tools hooks
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-underscore-dangle
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  const enhancer = composeEnhancers(...enhancers);

  return createStore(reducer, initialState, enhancer);
}
