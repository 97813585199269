import { stringify, parse } from 'query-string';
import apiFetch from '../../core/fetch';

let abortController: AbortController | null = null;

const placeSearchFetcher = (url: string): Promise<Response> => {
  // Cancel the old fetch if it's still going
  if (abortController) {
    abortController.abort();
  }
  // create a new controller for this fetch
  abortController = new AbortController();

  // make the fetch with the signal attached
  return apiFetch(url, { signal: abortController.signal });
};

const abortCatcher = (error: unknown) => {
  if (
    (error.name === 'AbortError')
    || (typeof window !== 'undefined' && error instanceof DOMException)
  ) {
    // This is the "correct" error handling. We don't care about aborts
    // so we swallow them as they only occur on browser fetch.
    // We need to check we are on the Browser before checking for DOMException
    // as the DOMException is not defined in Node and we do not want this to
    // throw a new exception.
    return null;
  }
  // We rethrow the exception so that it can be handled correctly
  // by the errorHandlers in render.tsx
  throw error;
};

// Ensures that page=1 is set on the search url
const ensurePageIsIncluded = (searchUrl = ''): string => {
  const [path = '', queryString = ''] = searchUrl.split('?');
  const query = parse(queryString);
  if (!query.page) {
    query.page = '1';
  }
  return `${path}?${stringify(query)}`;
};

const placeIdSearch = async <T extends { placeId: string }>({
  placeId,
  ...additionalParams
}: T): Promise<string | null> => {
  const queryString = stringify({ place_id: placeId, ...additionalParams });

  return placeSearchFetcher(`/search/v1/places/?${queryString}`)
    .then(response => {
      if (!response.ok) {
        // short-circuit the response handling and jump to error handling
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw response;
      }
      return response.json();
    })
    .then((data: { search_route: string }) => ensurePageIsIncluded(data.search_route))
    .catch(abortCatcher);
};

export default placeIdSearch;
