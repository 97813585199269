import React, { FC, useState } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import Media from 'react-media';
import { HORIZONTAL_ORIENTATION, VERTICAL_ORIENTATION } from 'react-dates/constants';
import classNames from 'classnames';

import { getClassNameFor } from '../../../helpers';
import { MEDIA_QUERIES } from '../../../constants';
import s from './DateInput.module.scss';
import Icon from '../../IconDS22';

type DateInputProps = {
  disabled?: boolean;
  displayFormat?: string;
  input: {
    name: string;
    onChange: (date: moment.Moment | null) => void;
    value: moment.Moment | null;
    onFocus?: () => void;
    onBlur?: () => void;
  };
  meta: {
    touched: boolean;
    error: boolean;
  };
  icon?: boolean;
  shouldBeTouchedToShowError?: boolean;
  classNameModifier?: string;
  placeholder?: string;
  showClearDate?: boolean;
  isDayBlocked?: ((day: moment.Moment) => boolean);
  openDirection?: 'down' | 'up';
  withPortal?: boolean;
};

const DateInput: FC<DateInputProps> = ({
  input: { name, onChange, onFocus, onBlur, value },
  meta: { touched, error },
  disabled,
  displayFormat = 'L',
  icon = true,
  isDayBlocked,
  // TODO: figure out whether this is still necessary with RFF
  shouldBeTouchedToShowError = true,
  classNameModifier,
  placeholder,
  showClearDate = true,
  openDirection = 'down',
  withPortal = false,
}) => {
  const [focused, setFocused] = useState(null);
  const errorMessage = (
    <label
      htmlFor={name}
      className={getClassNameFor(s, 'error', classNameModifier)}
    >
      {error}
    </label>
  );
  const rootModifier = classNames(
    classNameModifier,
    {
      noIcon: !icon,
    },
  );

  return (
    <div className={getClassNameFor(s, 'root', rootModifier)}>
      <Media
        query={MEDIA_QUERIES.DESKTOP}
        defaultMatches
      >
        {matches => (
          <SingleDatePicker
            disabled={disabled}
            id={name}
            readOnly
            placeholder={placeholder}
            displayFormat={displayFormat}
            onDateChange={onChange}
            // avoiding name collisions
            onFocusChange={({ focused: focus }) => {
              setFocused(focus);
              // Trigger `touched` state because dateInput never gets native focus
              if (focus) {
                onFocus?.();
              } else {
                onBlur?.();
              }
            }}
            focused={focused}
            date={value || null}
            showClearDate={showClearDate}
            orientation={matches ? HORIZONTAL_ORIENTATION : VERTICAL_ORIENTATION}
            withFullScreenPortal={!matches}
            // This is a little panel in the bottom-right that our users are unlikely to use.
            hideKeyboardShortcutsPanel
            isDayBlocked={isDayBlocked}
            openDirection={openDirection}
            withPortal={withPortal}
          />
        )}
      </Media>
      {icon && (
        <label
          htmlFor={name}
          className={getClassNameFor(s, 'label')}
        >
          <Icon id="calendar" size="l" className={getClassNameFor(s, 'icon')} />
        </label>
      )}
      {shouldBeTouchedToShowError
        ? touched && error
        && errorMessage
        : error
        && errorMessage}
    </div>
  );
};

export default DateInput;
