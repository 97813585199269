import React, { FC } from 'react';

import { EXTERNAL_PATHS } from '../../constants';
import s from './CookieContent.module.scss';
import UniversalLink from '../UniversalLink';
import Button from '../Button';

export type CookieContentProps = {
  handleClose: () => void;
};

const CookieContent: FC<CookieContentProps> = ({ handleClose }) => (
  <div className={s.root}>
    <div className={s.content}>
      <p className={s.p1}>We use cookies to improve your browsing experience.</p>
      <p className={s.p2}>
        {'And dolphins make us happy. Find out more about our '}
        <UniversalLink
          to={EXTERNAL_PATHS.COOKIES_POLICY}
          className={s.link}
        >
          cookies policy here
        </UniversalLink>
      </p>
    </div>
    <div>
      <Button
        type="button"
        classNameModifier="tertiary"
        onClick={handleClose}
        fullWidth
      >
        Close
      </Button>
    </div>
  </div>
);

export default CookieContent;
