import React from 'react';
import PropTypes from 'prop-types';

import s from './OnlineStatus.module.scss';

const OnlineStatus = ({
  children,
}) => (
  <div className={s.root}>
    {children}
  </div>
);

OnlineStatus.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OnlineStatus;
