import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import {
  META,
} from '../../constants';
import Layout from '../Layout';
import Header from '../Header';
import NotFoundContent from '../NotFoundContent';

type NotFoundPageProps = {
  staticContext?: {
    notFound?: true;
  };
};

const NotFoundPage: FC<NotFoundPageProps> = ({ staticContext }) => {
  // This logic is what causes the server response to be a 404
  if (staticContext) {
    // eslint-disable-next-line no-param-reassign
    staticContext.notFound = true;
  }
  return (
    <Layout>
      <Helmet>
        <title lang="en">Page not found - 404</title>
        <meta name="description" content={META.DESCRIPTION.DEFAULT} />
      </Helmet>
      <Header classNameModifier="bordered" />
      <NotFoundContent />
    </Layout>
  );
};

export default NotFoundPage;
