import React, { FC, ReactNode } from 'react';

import { getClassNameFor } from '../../helpers';
import Svg from '../Svg';
import s from './GroupSizeSnippet.module.scss';

type GroupSizeSnippetProps = {
  children: ReactNode;
  classNameModifier?: string;
};

const GroupSizeSnippet: FC<GroupSizeSnippetProps> = ({
  children,
  classNameModifier,
}) => (
  <div className={s.root}>
    <Svg
      href="#icon-group"
      className={getClassNameFor(s, 'icon', classNameModifier)}
    />
    <div className={s.content}>
      {children}
    </div>
  </div>
);

export default GroupSizeSnippet;
