import React, { FC, ReactNode } from 'react';

import { getClassNameFor } from '../../helpers';
import s from './ToggleDecorator.module.scss';

type ToggleDecoratorProps = {
  children: ReactNode;
  className?: string;
  classNameModifier?: string;
};

const ToggleDecorator: FC<ToggleDecoratorProps> = ({
  children,
  className = '',
  classNameModifier,
}) => (
  <span className={`${getClassNameFor(s, 'root', classNameModifier)} ${className}`.trim()}>
    {children}
    <span className={s.thumb} />
  </span>
);

export default ToggleDecorator;
