import React, { FunctionComponent } from 'react';

import Button from '../../../common/components/Button';
import InstabookOptions from '../InstabookOptions';

export type InstabookFormButtonsProps = {
  closeModal: () => void;
  submitting: boolean;
  onSubmit?: () => void;
  submitText?: string;
  canSubmit: boolean;
};

const InstabookFormButtons: FunctionComponent<InstabookFormButtonsProps> = ({
  closeModal,
  submitting,
  onSubmit,
  submitText = 'Done',
  canSubmit,
}) => (
  <>
    <InstabookOptions closeModal={closeModal} />
    {canSubmit && (
      <Button
        type={onSubmit ? 'button' : 'submit'}
        onClick={onSubmit}
        submitting={submitting}
        classNameModifier="instabookEditor instabook-green"
        fullWidth
      >
        {submitText}
      </Button>
    )}
  </>
);

export default InstabookFormButtons;
