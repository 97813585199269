import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { BlockedTime } from 'src/common/types/UseBookingMotivators';
import IconDS22, { IconIds } from 'src/common/components/IconDS22';
import { getClassNameFor } from '../../../helpers';
import Svg from '../../Svg';
import s from './Motivator.module.scss';

type MotivatorProps = {
  icon: string;
  minimal?: boolean;
  modifier?: string;
  title?: string;
  description?: ReactNode;
  blockedTimes?: BlockedTime[];
  loading?: boolean;
};

const useIconDS22 = [
  'calendar-day',
  'instabook',
];

const Motivator: FC<MotivatorProps> = ({
  icon,
  minimal = false,
  modifier,
  title,
  description,
  blockedTimes = [],
  loading,
}) => {
  const hasBlockedTimes = blockedTimes.length > 0;

  if (loading) {
    return (
      <div className={getClassNameFor(s, 'root', classNames(modifier, 'centered'))}>
        <div className={s.loading} />
      </div>
    );
  }

  return (
    <li className={getClassNameFor(s, 'root', classNames(modifier))}>
      {icon && !minimal && (
        <div className={getClassNameFor(s, 'iconWrapper', modifier)}>
          {useIconDS22.includes(icon) ? (
            <IconDS22
              id={icon as IconIds}
              size="l"
            />
          ) : (
            <Svg
              href={icon}
              className={getClassNameFor(s, 'icon', modifier)}
            />
          )}
        </div>
      )}
      <div>
        {title && (
          <h6
            className={getClassNameFor(s, 'title', modifier)}
            data-test="MotivatorTitle"
          >
            {title}
          </h6>
        )}
        {!hasBlockedTimes && description && !minimal && (
          <p
            className={getClassNameFor(s, 'description', modifier)}
            data-test="MotivatorDescription"
          >
            {description}
          </p>
        )}
        {hasBlockedTimes && (
          <>
            {blockedTimes.slice(0, 3).map((time) => (
              <p
                key={`${time.start_time}-${time.end_time}`}
                className={getClassNameFor(s, 'blockedTimes', modifier)}
              >
                {`${time.start_time} - ${time.end_time} is unavailable.`}
              </p>
            ))}
            {blockedTimes.length > 3 && (
              <p
                className={getClassNameFor(s, 'blockedTimes', modifier)}
              >
                Several other slots are unavailable
              </p>
            )}
          </>
        )}
      </div>
    </li>
  );
};

export default Motivator;
