import React, { FC } from 'react';
import { Field, Form } from 'react-final-form';
import { HotKeys } from 'react-hotkeys';
import type { Config } from 'final-form';

import { rffValidators } from '../../../../common/validation';
import TextArea from '../../../../common/components/inputs/TextArea';
import FormError from '../../../../common/components/FormError';
import CommentMessage from '../../CommentMessage';
import FormButtons from '../../../../common/components/FormButtons';
import { SEND_MESSAGE_ACTION_NAME } from '../../TripMenu/actions';
import s from './TripAction.module.scss';

const messageValidate = rffValidators.required({ message: 'Please enter your message' });

type BareTripActionFormProps = {
  fieldName: string;
  onSubmit: Config['onSubmit'];
  closeBehavior: () => unknown;
  content: {
    action: string;
    title: string;
    description: string;
    submitText: string;
    cancelText: string;
    placeholder: string;
  };
  withMessageField?: boolean;
};

export const BareTripActionForm: FC<BareTripActionFormProps> = ({
  closeBehavior,
  content: { action, title, description, submitText, cancelText, placeholder },
  fieldName,
  onSubmit,
  withMessageField = true,
}) => (
  <Form
    onSubmit={onSubmit}
  >
    {({
      error,
      handleSubmit,
      submitError,
      submitting,
    }) => (
      <form
        onSubmit={handleSubmit}
        className={s.root}
      >
        <HotKeys
          handlers={{ submit: handleSubmit }}
          keyMap={{ submit: ['ctrl+enter', 'meta+enter'] }}
          tabIndex={null}
          className={s.wrapper}
        >
          <h3 className={s.title}>
            {title}
          </h3>

          <label
            htmlFor={withMessageField ? fieldName : undefined}
            className={s.label}
          >
            {description}
          </label>

          <FormError error={error || submitError} />

          {withMessageField && (
            <>
              <Field
                name={fieldName}
                component={TextArea}
                placeholder={placeholder || `Optional – add a comment about why you have to ${action}.`}
                validate={action === SEND_MESSAGE_ACTION_NAME
                  ? messageValidate
                  // Don't validate the field for any other actions
                  // because message field on those forms is optional
                  : undefined}
              />
              <CommentMessage />
            </>
          )}
          <FormButtons
            classNameModifier="inbox"
            submitting={submitting}
            closeModal={closeBehavior}
            cancelText={cancelText}
            submitText={submitText || title}
          />
        </HotKeys>
      </form>
    )}
  </Form>
);

export default BareTripActionForm;
