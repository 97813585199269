import React, { FC, useMemo } from 'react';
import { getClassNameFor } from '../../../common/helpers';
import { INSTABOOK_TIME_FORMAT, INSTABOOK_TIME_FORMAT as dateFormat } from '../../constants';
import { getReturnTimeString, getTimeString } from '../../helpers';

import s from './InstabookDepartureTimesTooltip.module.scss';

export type InstabookDepartureTimesTooltipProps = {
  earliestDepartureTime: string;
  latestDepartureTime?: string;
  duration: string;
};

const InstabookDepartureTimesTooltip: FC<InstabookDepartureTimesTooltipProps> = ({
  earliestDepartureTime,
  latestDepartureTime,
  duration,
}) => {
  const earliestDepartureTimeFormatted = useMemo(
    () => getTimeString(earliestDepartureTime, INSTABOOK_TIME_FORMAT),
    [earliestDepartureTime],
  );

  const latestDepartureTimeFormatted = useMemo(
    () => getTimeString(latestDepartureTime, INSTABOOK_TIME_FORMAT),
    [latestDepartureTime],
  );

  const earliestDepartureReturnTime = useMemo(
    () => getReturnTimeString(
      earliestDepartureTime,
      parseInt(duration, 10),
      dateFormat,
    ),
    [earliestDepartureTime, duration],
  );

  if (latestDepartureTime) {
    const latestDepartureReturnTime = getReturnTimeString(
      latestDepartureTime,
      parseInt(duration, 10),
      dateFormat,
    );
    return (
      <div className={getClassNameFor(s, 'root', 'flexible')}>
        <div className={s.tripBlock}>
          <span className={s.title}>Earliest Trip</span>
          <div className={s.times}>
            <div className={s.depart}>
              <span className={s.label}>Depart</span>
              <time
                dateTime={earliestDepartureTimeFormatted}
                className={s.time}
              >
                {earliestDepartureTimeFormatted}
              </time>
            </div>
            <span className={s.divider}>→</span>
            <div className={s.return}>
              <span className={s.label}>Return</span>
              <time
                dateTime={earliestDepartureReturnTime}
                className={s.time}
              >
                {earliestDepartureReturnTime}
              </time>
            </div>
          </div>
        </div>
        <div className={s.tripBlock}>
          <span className={s.title}>Latest Trip</span>
          <div className={s.times}>
            <div className={s.depart}>
              <span className={s.label}>Depart</span>
              <time
                dateTime={latestDepartureTimeFormatted}
                className={s.time}
              >
                {latestDepartureTimeFormatted}
              </time>
            </div>
            <span className={s.divider}>→</span>
            <div className={s.return}>
              <span className={s.label}>Return</span>
              <time
                dateTime={latestDepartureReturnTime}
                className={s.time}
              >
                {latestDepartureReturnTime}
              </time>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={s.root}>
      <div className={s.depart}>
        <span className={s.label}>Depart</span>
        <time
          dateTime={earliestDepartureTimeFormatted}
          className={s.time}
        >
          {earliestDepartureTimeFormatted}
        </time>
      </div>
      <span className={s.divider}>→</span>
      <div className={s.return}>
        <span className={s.label}>Return</span>
        <time
          dateTime={earliestDepartureReturnTime}
          className={s.time}
        >
          {earliestDepartureReturnTime}
        </time>
      </div>
    </div>
  );
};

export default InstabookDepartureTimesTooltip;
