type CaptainCopy = {
  heading: string;
  shortHeading: string;
  body: string;
};

export const emptyResult = { heading: '', shortHeading: '', body: '' };

export const captainNotProvided = {
  heading: 'Captain Not Provided',
  shortHeading: 'Self-Captain',
  body: 'Customer provides or acts as the qualified operator.',
};

export const captainProvided = {
  heading: 'Captain Provided in Price',
  shortHeading: 'With Captain',
  body: 'Professional Captain provided and included in the price.',
};

export const captainSeparate = {
  heading: 'Independent Captain',
  shortHeading: 'With Captain',
  body: 'Professional Captain required, arranged and paid separately.',
};

const getCaptainedCopy = (captained?: boolean, captainedCost?: boolean): CaptainCopy => {
  if (captained === undefined || captainedCost === undefined) {
    return emptyResult;
  }

  if (!captained) {
    return captainNotProvided;
  }

  return captainedCost
    ? captainProvided
    : captainSeparate;
};

export default getCaptainedCopy;
