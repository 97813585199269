import React, { ChangeEvent, FC } from 'react';
import { Price, PriceGroup, PriceSection } from 'src/types/common/Amounts';
import PriceTerm from 'src/common/components/PriceTerm';
import CurrencyDropdown from 'src/common/components/CurrencyDropdown';
import { DropdownOption } from 'src/common/components/DropdownDS22';
import type { Currency } from 'src/types/common/Currencies';
import CostDetails from './CostDetails';
import CostList, { CostListLabel, CostListValue } from './CostList';
import { Theme, themes, variants } from './types';
import styles from './CostBreakdown.module.scss';

export type CostBreakdownProps = {
  open?: boolean;
  priceSection?: PriceSection;
  renterCurrency?: Currency;
  theme?: Theme;
  onCurrencySelection?: (item: ChangeEvent<HTMLSelectElement> & DropdownOption) => void;
};

const renderPriceLines = (priceGroup: PriceGroup): React.ReactNode => priceGroup?.price_lines
  ?.map((price: Price) => (
    <React.Fragment key={price.id}>
      <CostListLabel>
        <PriceTerm
          classNameModifier="payoutBreakdown"
          tooltipContent={price.help}
        >
          {price.label}
        </PriceTerm>
      </CostListLabel>
      <CostListValue priceId={price.id}>
        {price.formatted_amount}
      </CostListValue>
    </React.Fragment>
  ));

const CostBreakdown: FC<CostBreakdownProps> = ({
  open = true,
  priceSection,
  renterCurrency,
  theme = themes[0],
  onCurrencySelection,
}) => {
  const footerLabel = priceSection?.footer_label;
  const showFooter = !!footerLabel || (onCurrencySelection && renterCurrency);
  const priceGroupsNumber = priceSection?.price_groups?.length ?? 0;
  const multilevel = priceGroupsNumber > 1;
  const withCurrencySelector = !!(onCurrencySelection && renterCurrency);

  return (
    <CostDetails
      amount={priceSection?.formatted_amount ?? ''}
      // disableCollapse={priceGroupsNumber === 1}
      open={open}
      theme={theme}
      title={priceSection?.header_label ?? ''}
      variant={variants[0]}
    >
      {priceSection?.price_groups?.map((priceGroup: PriceGroup) => (multilevel ? (
        <CostDetails
          amount={priceGroup.formatted_amount}
          key={priceGroup.id}
          open={false}
          title={priceGroup.label}
          variant="secondary"
        >
          <CostList variant="multilevel">
            {renderPriceLines(priceGroup)}
          </CostList>
        </CostDetails>
      ) : (
        <CostList key={priceGroup.id}>
          {renderPriceLines(priceGroup)}
        </CostList>
      )))}

      {showFooter && (
        <div className={styles.footer}>
          {!!footerLabel
          && (
            <div className={styles.total}>
              <span>{footerLabel}</span>
              <span data-test="total-price">
                {priceSection.formatted_amount}
              </span>
            </div>
          )}
          {withCurrencySelector && (
            <CurrencyDropdown
              selectedCurrency={renterCurrency}
              onChange={onCurrencySelection}
            />
          )}
        </div>
      )}
    </CostDetails>
  );
};

export default CostBreakdown;
