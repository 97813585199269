import React, { FC } from 'react';
import Icon from 'src/common/components/IconDS22';
import { completePriceFormatter } from 'src/common/utils/currency';
import { format, isAfter, parseISO } from 'date-fns';
import { getClassNameFor } from 'src/common/helpers';
import { useSelector } from 'react-redux';
import { getCurrencies } from 'src/common/utils/reduxStoreSelectors';
import { GetItemPropsOptions } from 'downshift';
import classNames from 'classnames';
import { RetrievedRecentSearch } from './RecentSearch';
import categories from './categories';

import s from './RecentSearches.module.scss';

export type RecentSearchesProps = {
  searches: RetrievedRecentSearch[];
  onClick: () => void;
  getItemProps: (props: GetItemPropsOptions<any>) => any;
  numPlaces:number;
  highlightedIndex: number | null;
};

type SecondaryFilters = Pick<RetrievedRecentSearch, 'instabooks' | 'captained' | 'min_price' | 'max_price' | 'categories' | 'superowners'>;

type SecondaryFilterValue = RetrievedRecentSearch[keyof SecondaryFilters];

const captainedLabels = {
  true: 'Captained',
  false: 'No Captain',
};

const RecentSearches: FC<RecentSearchesProps> = ({
  searches,
  onClick,
  getItemProps,
  numPlaces,
  highlightedIndex,
}) => {
  const currency = useSelector(getCurrencies)?.display_currency;
  const getSecondaryFilters = (search: RetrievedRecentSearch): SecondaryFilters => ({
    instabooks: search.instabooks,
    captained: search.captained,
    min_price: search.min_price,
    max_price: search.max_price,
    categories: search.categories,
    superowners: search.superowners,
  });

  const getDateContent = (dates: RetrievedRecentSearch['dates']): string => {
    if (!dates[0]) return '';
    const selectedDate = parseISO(dates[0]);
    let alternateDateCount = 0;
    dates.forEach((date) => {
      if (isAfter(parseISO(date), selectedDate)) alternateDateCount += 1;
    });

    const alternateDateString = alternateDateCount
      ? `\u00A0± ${alternateDateCount > 1 ? `${alternateDateCount} days` : '1 day'}`
      : '';
    const formattedDate = format(selectedDate, 'MMM do');
    return `${formattedDate}${alternateDateString}`;
  };

  const getFilterContent = (
    key: keyof SecondaryFilters,
    value: SecondaryFilterValue,
    index: number,
    search: RetrievedRecentSearch,
  ): string | string[] | null => {
    const isPriceUnlimited = search.min_price && !search.max_price;
    let output = index > 0 && key !== 'max_price' ? ' | ' : '';
    switch (key) {
      case 'instabooks':
        output += value
          ? 'Instabook'
          : '';
        break;
      case 'captained':
        output += value
          ? captainedLabels[value as 'true' | 'false']
          : '';
        break;
      case 'min_price':
        output += value
          ? `${completePriceFormatter(Number(value), currency.code, currency.symbol)}${isPriceUnlimited ? '+' : ''}`
          : '';
        break;
      case 'max_price':
        output += value
          ? `-${completePriceFormatter(Number(value), currency.code, currency.symbol)}`
          : '';
        break;
      case 'categories':
        output += (value as string[]).length
          ? (value as string[]).map((category) => categories[`category-${category}`])
            .join(' | ')
          : '';
        break;
      case 'superowners':
        output += value ? 'Superowner' : '';
        break;
      default:
        break;
    }
    return output;
  };

  const filterSecondaryFilters = (filter: SecondaryFilterValue): boolean => {
    if (Array.isArray(filter)) {
      if (filter.length === 0) return false;
      return true;
    }
    if (filter) return true;
    return false;
  };

  return (
    <div className={s.root}>
      <h3 className={s.title}>Recent Searches</h3>
      <ul className={s.list}>
        {searches.map((search, itemIndex) => {
          const offsetIndex = itemIndex + numPlaces; // combobox id spans 2 arrays

          return (
            <li
              key={search.searchId}
              {...getItemProps({
                key: search.searchId,
                item: search,
                index: offsetIndex,
                className: getClassNameFor(
                  s,
                  'item',
                  classNames({ active: highlightedIndex === offsetIndex }),
                ),
              })}
            >
              <button type="button" className={s.button} onClick={onClick}>
                <div className={s.icon}>
                  <Icon id="clock" size="l" />
                </div>
                <div className={s.searchContent}>
                  <span className={s.searchTitle}>
                    {search.location && search.place_id ? search.location : 'Near me'}
                    {search.dates[0] && ` • ${getDateContent(search.dates)}`}
                    {search.capacity && ` • ${Number(search.capacity)} ${Number(search.capacity) > 1 ? 'guests' : 'guest'}`}
                  </span>
                  <span className={s.secondaryFilters}>
                    {Object.entries(getSecondaryFilters(search))
                      .filter(([, value]) => filterSecondaryFilters(value))
                      .map(([key, value], index) => (
                        value && (
                          <span key={key} className={s.secondaryFilter}>
                            {`${getFilterContent(key as keyof SecondaryFilters, value, index, search)}`}
                          </span>
                        )
                      ))}
                  </span>
                </div>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default RecentSearches;
