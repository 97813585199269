import { Map } from 'immutable';
import { ImmutableMap } from '../../types/ImmutableMap';

// app/reducer/action
export const FOCUS_CALENDAR = 'calendar/calendar/FOCUS_CALENDAR' as const;

type CalendarState = {
  selectedDate?: string;
  focusedThreadId?: number;
  focusedAdHocEventId?: number;
  focusedInstabookId?: number;
  scrollToEvent?: boolean;
  renderKey: boolean;
  multiInstabookIds?: number[];
};

export type ImmutableCalendar = ImmutableMap<CalendarState>;

type FocusCalendarOptions = {
  /** YYYY-MM-DD */
  selectedDate?: string;
  focusedThreadId?: number;
  focusedAdHocEventId?: number;
  focusedInstabookId?: number;
  scrollToEvent?: boolean;
  multiInstabookIds?: number[];
};

type FocusCalendarAction = {
  type: typeof FOCUS_CALENDAR;
  selectedDate: string | undefined;
  focusedThreadId: number | undefined;
  focusedAdHocEventId: number | undefined;
  focusedInstabookId: number | undefined;
  scrollToEvent: boolean;
  multiInstabookIds?: number[];
};

export const focusCalendar = ({
  selectedDate = undefined,
  focusedThreadId = undefined,
  focusedAdHocEventId = undefined,
  focusedInstabookId = undefined,
  scrollToEvent = false,
  multiInstabookIds,
}: FocusCalendarOptions): FocusCalendarAction => ({
  type: FOCUS_CALENDAR,
  selectedDate,
  focusedThreadId,
  focusedAdHocEventId,
  focusedInstabookId,
  scrollToEvent,
  multiInstabookIds,
});

type CalendarActions = FocusCalendarAction;

const INITIAL_CALENDAR_STATE: ImmutableCalendar = Map({
  // This boolean is flipped each time focus calendar is called so that we are able to focus on the
  // same element after having scrolled away from it. It is used to ensure the `key` can be changed
  // when rendering using the same selected date.
  renderKey: false,
}) as ImmutableCalendar;

export default (
  state: ImmutableCalendar = INITIAL_CALENDAR_STATE,
  action?: CalendarActions,
) => {
  switch (action?.type) {
    case FOCUS_CALENDAR:
      return state
        .set('selectedDate', action.selectedDate ?? state.get('selectedDate'))
        .set('focusedThreadId', action.focusedThreadId)
        .set('focusedAdHocEventId', action.focusedAdHocEventId)
        .set('focusedInstabookId', action.focusedInstabookId)
        .set('scrollToEvent', action.scrollToEvent)
        .set('multiInstabookIds', action.multiInstabookIds)
        .set(
          'renderKey',
          action.selectedDate && action.selectedDate === state.get('selectedDate')
            ? !state.get('renderKey')
            : state.get('renderKey'),
        );

    default:
      return state;
  }
};
