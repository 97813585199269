import React from 'react';
import { Helmet } from 'react-helmet-async';

import { EXTERNAL_PATHS, TITLE } from '../../constants';
import s from '../../../auth/components/pages/Register/Register.module.scss';
import UniversalLink from '../UniversalLink';

const AgreementContent = () => (
  <>
    <Helmet>
      <title>
        {TITLE.AGREEMENT}
      </title>
    </Helmet>
    <h1 className={s.title}>
      {TITLE.AGREEMENT}
    </h1>
    <div className={s.message}>
      <p>
        Getmyboat protects your personal information.
        {' To create an account, please accept our '}
        <UniversalLink
          to={EXTERNAL_PATHS.PRIVACY_POLICY}
          className={s.link}
          openInNewTab
        >
          Privacy Policy
        </UniversalLink>
        {', '}
        <UniversalLink
          to={EXTERNAL_PATHS.MEMBER_INTERFACE}
          className={s.link}
          openInNewTab
        >
          Member Interface Agreement
        </UniversalLink>
        {', and '}
        <UniversalLink
          to={EXTERNAL_PATHS.TERMS}
          className={s.link}
          openInNewTab
        >
          Terms of Use
        </UniversalLink>.
      </p>
    </div>
  </>
);

export default AgreementContent;
