import React, { useState, useMemo, useEffect, FC } from 'react';
import { Field, useForm, useFormState, FieldProps, FieldRenderProps } from 'react-final-form';
import { debounce } from 'lodash';
import { defaultTopLevelDomains, run } from 'mailcheck';

import s from './EmailField.module.scss';
import Input from '../../inputs/Input';

defaultTopLevelDomains.push('co.za'); // extend existing TLDs

export type EmailFieldProps = FieldProps<string, FieldRenderProps<string, HTMLElement>> & {
  disabled?: boolean;
  placeholder?: string;
};

const EmailField: FC<EmailFieldProps> = (props) => {
  const { change } = useForm();
  const [suggestedEmail, setSuggestedEmail] = useState('');
  // NOTE: this doesn't really use the suggested warning functionality
  // in the react-final-form docs, nor does it mimic the old redux-form
  // API, but it seems sufficient for this use case. If we decide that
  // warning functionality is useful enough across the codebase,
  // we can re-implement it as a reusable hook or something to that effect.
  const warn = useMemo(
    () => (
      debounce(
        (email) => {
          run({
            email,
            suggested: ({ full }: { full: string }) => setSuggestedEmail(full),
            empty: () => setSuggestedEmail(''),
          });
        },
        1000, // ms
      )
    ),
    [],
  );
  useFormState({
    subscription: { values: true },
    onChange: ({ values }) => {
      if (props.disabled) return;

      if (values) {
        const { email } = values;
        warn(email);
      }
    },
  });
  useEffect(
    // This theoretically says "when warn changes or the component unmounts,
    // cancel the warning function". Need to test this.
    () => () => warn.cancel(),
    [warn],
  );
  return (
    <>
      <Field
        {...props}
        component={Input as React.ComponentType<FieldRenderProps<string>>}
        placeholder={props.placeholder ?? 'Email Address'}
        type="email"
      />
      {suggestedEmail && (
        <button
          className={s.suggestion}
          onClick={() => {
            change('email', suggestedEmail);
            setSuggestedEmail('');
          }}
          type="button"
        >
          Did you mean {suggestedEmail}?
        </button>
      )}
    </>
  );
};

export default EmailField;
