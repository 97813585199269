import PropTypes from 'prop-types';
import React from 'react';

import {
  CaptainCard,
  CommentCard,
  DatesCard,
  DurationCard,
  GroupSizeCard,
  TimeCard,
  VesselCard,
} from '../../cards';
import CTA, { CTALink } from '../../CTA';
import TripPanel from '../../presentation/TripPanel';
import TripDetailsTitle from '../../presentation/TripDetailsTitle';
import { getMessage } from '../content';
import TopScroll from '../../../../common/components/TopScroll';
import { PATHS } from '../../../../common/constants';
import { appendRoute, preserveSearch } from '../../../../common/utils/routing';
import { CAPTAINED_FIELD, PAGES } from '../../../constants';
import TripMenu from '../../TripMenu';

const OwnerChangesRequested = ({
  trip,
  location,
}) => (
  <TripPanel
    cta={(
      <CTA classNameModifier="withSidebar">
        <TripMenu trip={trip} />
        <CTALink to={preserveSearch(appendRoute(location.pathname, PAGES.PRICE), location)}>
          Update Offer
        </CTALink>
      </CTA>
    )}
    trip={trip}
    subheader={trip.getIn(['state', 'badge'])}
    intro={getMessage(trip)}
    backLocation={preserveSearch(PATHS.INBOX, location)}
    classNameModifier="withoutSideMargins"
  >
    <TopScroll />
    <TripDetailsTitle />
    <VesselCard
      // TODO: see if there's another way of approaching the concept
      // of checkTripModification
      checkTripModification
      trip={trip}
    />
    <DatesCard
      requestedChanges
      checkTripModification
      trip={trip}
    />
    <TimeCard
      requestedChanges
      checkTripModification
      trip={trip}
    />
    <DurationCard
      requestedChanges
      checkTripModification
      trip={trip}
    />
    <GroupSizeCard
      requestedChanges
      checkTripModification
      trip={trip}
    />
    {trip.get(CAPTAINED_FIELD) !== null && (
      <CaptainCard
        checkTripModification
        trip={trip}
      />
    )}
    <CommentCard
      checkTripModification
      trip={trip}
    />
  </TripPanel>
);

OwnerChangesRequested.propTypes = {
  trip: PropTypes.object,
  location: PropTypes.object,
};

export default OwnerChangesRequested;
