import React, { FC, ReactNode } from 'react';
import { Field } from 'react-final-form';

import { getClassNameFor } from '../../../helpers';
import NumberAddInput from '../../inputs/NumberAddInput';

import s from '../../GroupSizeFieldset/GroupSizeFieldset.module.scss';

type BareGroupSizeFieldsetProps = {
  total: number;
  classNameModifier?: string;
  children?: ReactNode;
  fieldNamePrefix?: string;
};

export const BareGroupSizeFieldset: FC<BareGroupSizeFieldsetProps> = (
  { total, classNameModifier, children, fieldNamePrefix = '' },
) => (
  <div className={getClassNameFor(s, 'root', classNameModifier)}>
    {children}
    <Field
      name={`${fieldNamePrefix ? `${fieldNamePrefix}.` : ''}adults`}
      component={NumberAddInput}
      autoFocus
      label="Adults"
      data-test="GroupSizeAdultsInput"
    />
    <Field
      name={`${fieldNamePrefix ? `${fieldNamePrefix}.` : ''}seniors`}
      component={NumberAddInput}
      label="Seniors"
      data-test="GroupSizeSeniorsInput"
    />
    <Field
      name={`${fieldNamePrefix ? `${fieldNamePrefix}.` : ''}children`}
      component={NumberAddInput}
      label="Children"
      data-test="GroupSizeChildrenInput"
    />
    <Field
      name={`${fieldNamePrefix ? `${fieldNamePrefix}.` : ''}infants`}
      component={NumberAddInput}
      label="Infants"
      data-test="GroupSizeInfantsInput"
    />

    <div className={s.total}>
      Total: {total}
    </div>
  </div>
);

export default BareGroupSizeFieldset;
