import { useCallback, useState } from 'react';
import gitShaVersion from 'src/pubsub/gitShaVersion';
import useInterval from '../../hooks/useInterval';
import retry from '../../utils/retry';
import config from './config';

type BuildVersion = {
  /** The git sha for the current build */
  version: string;
};

/**
 * Stores the current and latest versions of the app and manages intermediate version checks.
 */
const useAppVersion = (currentVersion: string) => {
  const [latestVersion, setLatestVersion] = useState(config.CURRENT_VERSION);

  const checkVersion = useCallback(() => {
    if (typeof window !== 'undefined') {
      const loader = () => fetch('/assets/version.json', { headers: gitShaVersion });
      retry(3, loader)
        .then(res => {
          if (res && res.status === 200) {
            return res.json();
          }
          return {};
        })
        .then((data: Partial<BuildVersion>) => {
          if (data?.version && data.version !== currentVersion) {
            setLatestVersion(data.version);
          }
        });
    }
  }, [currentVersion]);

  useInterval(checkVersion, config.CHECK_VERSION_INTERVAL);

  return {
    currentVersion,
    latestVersion,
  };
};

export default useAppVersion;
