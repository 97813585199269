import React, { FC } from 'react';
import classNames from 'classnames';

import { INSTABOOK_PUBLISHED_FIELD } from '../../constants';
import { getClassNameFor } from '../../../common/helpers';
import RadioButton, { parseTrueFalseValue } from '../../../common/components/fields/RadioButton';
import s from './InstabookDraftPublish.module.scss';

type Props = {
  checked: boolean;
  disabled: boolean;
  description: string;
  title: string;
  value: boolean;
};

const InstabookDraftPublishLabel: FC<Props> = ({
  checked,
  disabled = false,
  description,
  title,
  value,
}) => (
  <label className={getClassNameFor(s, 'label', classNames({ checked, disabled }))}>
    <RadioButton
      disabled={disabled}
      name={INSTABOOK_PUBLISHED_FIELD}
      parse={parseTrueFalseValue}
      value={value}
    />
    <strong className={s.title}>
      {title}
    </strong>
    <p className={s.description}>
      {description}
    </p>
  </label>
);

export default InstabookDraftPublishLabel;
