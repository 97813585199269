import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';

import { open } from '../../../../common/ducks/zippy';
import { UNSENT_CHANGES_WARNING } from '../../../constants';
import TripDetailComment from '../../TripDetailComment';
import Comment from '../../../../common/components/Comment';
import Avatar from '../../../../common/components/Avatar';
import EditButton from '../../EditButton';
import Modal from '../../../../common/components/Modal';
import Card from '../../presentation/Card';
import CommentForm from './form';
import cardStyles from '../../presentation/Card/Card.module.scss';
import styles from './CommentCard.module.scss';
import NotificationMessage from '../../../../common/components/NotificationMessage';
import { ImmutableTrip } from '../../../../types/trips/Trips';
import { getOffer } from '../../../../common/utils/reduxStoreSelectors';

// Import multiple css modules into a component
const s = {
  ...cardStyles,
  ...styles,
};

// 'comment' field is used on offer, whereas 'owner_comment' & 'renter_comment' are used on trips
const ownerCommentField = 'owner_comment';
const fields = ['comment'];

const modalName = 'requests';

type Props = {
  trip: ImmutableTrip;
  checkTripModification?: boolean;
  readOnly?: boolean;
};

const CommentCard: FC<Props> = ({ trip, readOnly, checkTripModification }) => {
  const dispatch = useDispatch();
  const offer = useSelector(getOffer);
  const openModal = () => dispatch(open(modalName));
  const offerIsModified = !!offer.get('comment');
  const tripIsModified = checkTripModification && trip.getIn(['modified', ownerCommentField]);
  // This normalization is necessary because `None` is erroneously returned
  // from the backend and should not be displayed as a valid comment to the user.
  const renterComment = trip.get('renter_comment') !== 'None' ? trip.get('renter_comment') : '';
  const owner = trip.get('owner', Map());
  let ownerComment = offer.get('comment', trip.get(ownerCommentField));
  ownerComment = ownerComment !== 'None' ? ownerComment : '';
  const hasComment = ownerComment || renterComment;

  // Handling for instabook renter comments
  const isInstabook = trip.get('is_instabook');
  const instabookRenterOccasion = isInstabook && renterComment?.split('<br/>')[0];
  const instabookRenterMessage = isInstabook && renterComment?.split('<br/>')[1];

  if (!hasComment && readOnly) {
    return null;
  }

  return (
    <Card
      highlighted={offerIsModified || tripIsModified}
      classNameModifier={readOnly ? '' : 'hoverEffect'}
    >
      {offerIsModified && (
        <NotificationMessage outdent={false}>
          {UNSENT_CHANGES_WARNING}
        </NotificationMessage>
      )}
      {renterComment && (
        <Comment
          classNameModifier="top"
          title={<span className={s.specialRequestTitle}>Special Requests</span>}
        >
          {isInstabook ? (
            <>
              {instabookRenterOccasion && (
                <span className={s.paragraph}>{instabookRenterOccasion}</span>
              )}
              {instabookRenterMessage && (
                <span className={s.paragraph}>{instabookRenterMessage}</span>
              )}
            </>

          ) : renterComment}
        </Comment>
      )}

      {ownerComment && <TripDetailComment user={owner}>{ownerComment}</TripDetailComment>}

      {!readOnly && (
        <>
          {!(ownerComment || offerIsModified) && (
            <span className={s.triggerElement}>
              <Avatar
                size="default"
                user={owner.toJS()}
              />
              Add your comments (optional)
            </span>
          )}
          <EditButton openModal={openModal} />
          <Modal
            name={modalName}
            label="Comment Form"
          >
            <CommentForm
              initialValues={{
                comment: ownerComment,
              }}
              modified={offerIsModified}
              fields={fields}
            />
          </Modal>
        </>
      )}
    </Card>
  );
};

export default CommentCard;
