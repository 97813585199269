import React, { FC, useCallback, useEffect, useState } from 'react';
import { Field, FieldRenderProps, useField } from 'react-final-form';
import { useDispatch } from 'react-redux';
import formatDuration from 'date-fns/formatDuration';

import DatePickerWithBookingMotivators from '../../../common/components/DatePickerWithBookingMotivators';
import { INSTABOOK_EDITOR_DATES_FIELD, INSTABOOK_EDITOR_TRIP_DURATION_FIELD, INSTABOOK_TRIP_DATE_MODAL_NAME as modalName } from '../../constants';
import { useInstabookEditorContext } from '../../hooks';
import Svg from '../../../common/components/Svg';
import { close, open } from '../../../common/ducks/zippy';
import InstabookDatePickerWrapper from '../InstabookDatePickerWrapper';

import s from './InstabookAvailabilityDates.module.scss';
import InstabookAvailabilityDatesField from './InstabookAvailabilityDatesField';

type Props = {
  disabled: boolean;
  boatId?: string;
};

const InstabookAvailabilityDates: FC<Props> = ({ disabled, boatId }) => {
  const {
    instabook,
  } = useInstabookEditorContext();
  const dispatch = useDispatch();
  const {
    input: {
      value: durationValue,
    },
  } = useField(INSTABOOK_EDITOR_TRIP_DURATION_FIELD);
  const {
    meta: {
      active: dateFieldActive,
    },
    input: { value },
  } = useField(INSTABOOK_EDITOR_DATES_FIELD);

  // Force array of date strings we get from api state to Date array to pass to date-picker
  const initialDays: Date[] = value || [];
  const [selectedDays, setSelectedDays] = useState<Date[] | []>(initialDays);

  const duration = (tripLength: string) => {
    const [hours, minutes, seconds] = tripLength.split(':').map(Number);
    return formatDuration({ hours, minutes, seconds });
  };

  const tripLength = (durationValue && `${durationValue} hours`)
  || (instabook?.trip_length && duration(instabook.trip_length));

  const openModal = useCallback(() => dispatch(open(modalName)), [dispatch]);
  const closeModal = useCallback(() => dispatch(close(modalName)), [dispatch]);

  useEffect(() => {
    if (dateFieldActive) {
      openModal();
    } else {
      closeModal();
    }
  }, [dateFieldActive, openModal, closeModal]);

  return (
    <div className={s.root}>
      <h2 className={s.title}>
        Availability
      </h2>
      <div className={s.card}>
        <div
          className={s.header}
        >
          <Svg
            href="#icon-calendar"
            className={s.icon}
          />
          <h4 className={s.heading}>Date(s)</h4>
        </div>
        <div className={s.fieldWrapper}>
          <Svg className={s.editIcon} href="#card-edit-icon" />
          <InstabookAvailabilityDatesField fieldValue={value} />
          <Field<Date[]>
            allowUnavailableDateSelection
            withIcon={false}
            name={INSTABOOK_EDITOR_DATES_FIELD}
            tripLength={tripLength}
            classNameModifier="instabook date tripDate instabookTripEditor"
            showClearDate={false}
            disabled={disabled}
            boatId={boatId}
            portalId={boatId}
            shouldBeTouchedToShowError={false}
            renderMotivators={false}
            render={(props: FieldRenderProps<Date[]>) => (
              <DatePickerWithBookingMotivators
                {...props}
                DatePickerWrapper={InstabookDatePickerWrapper}
                datePickerWrapperLabel="Select Dates"
                isUsingReactDayPicker
                selectedDays={selectedDays}
                setSelectedDays={setSelectedDays}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default InstabookAvailabilityDates;
