import React from 'react';

import { Amounts } from 'src/types/common/Amounts';
import { getClassNameFor } from 'src/common/helpers';
import { completePriceFormatter } from 'src/common/utils/currency';
import CostList, { CostListLabel, CostListValue } from '../CostBreakdown/CostList';
import { Theme } from '../CostBreakdown/types';
import s from './OfferSummary.module.scss';

type OfferSummaryProps = {
  amounts: Amounts;
  theme?: Theme;
};

/**
 * This component renders base cost & captain cost of offer.
 * It is intended for trips in OFFER_SENT_STATES & OWNER_OFFER_EXPIRED_STATES only.
 */
const OfferSummary: React.FC<OfferSummaryProps> = ({
  amounts,
  theme = 'light',
}) => (amounts ? (
  <div className={getClassNameFor(s, 'root', theme)}>
    <CostList variant="offerSummary">
      <CostListLabel>
        Base Cost
      </CostListLabel>
      <CostListValue>
        {completePriceFormatter(
          amounts.offer_amount,
          amounts.owner_currency.code,
          amounts.owner_currency.symbol,
          amounts.owner_currency.precision,
          true,
        )}
      </CostListValue>
      {amounts.captain_amount > 0 && (
        <>
          <CostListLabel>
            Captain Cost
          </CostListLabel>
          <CostListValue>
            {completePriceFormatter(
              amounts.captain_amount,
              amounts.owner_currency.code,
              amounts.owner_currency.symbol,
              amounts.owner_currency.precision,
              true,
            )}
          </CostListValue>
        </>
      )}
    </CostList>
  </div>
) : null);

export default OfferSummary;
