import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { AnyObject, Decorator, FORM_ERROR, FormApi } from 'final-form';
import { Form } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import { stringify } from 'query-string';
import moment from 'moment';
import { differenceInHours, format, isAfter, isBefore, isEqual } from 'date-fns';
import { ReduxState } from 'src/types/reduxState';
import { Amounts } from 'src/types/common/Amounts';
import { ImmutableTrip } from 'src/types/trips/Trips';
import { match as Match } from 'react-router-dom';
import { History, Location } from 'history';
import { Dispatch } from 'redux';

import useCaptainData from 'src/common/hooks/useCaptainData';
import { getBoat } from 'src/common/utils/reduxStoreSelectors';
import UnstyledButton from 'src/common/components/UnstyledButton';
import { trackEvent } from 'src/common/tracking';
import { CAPTAIN_OPTION_KEYS, PATHS } from '../../../../common/constants';
import { decorateComponent, rffSubmitResponse } from '../../../../common/helpers';
import { useFocusByRefOnMount, useFlags } from '../../../../common/hooks';
import useCustomOfferExpiry from '../../../../common/hooks/useCustomOfferExpiry';
import { dateGetter } from '../../cards/Dates/selector';
import { preserveSearch, parentRoute } from '../../../../common/utils/routing';
import apiFetch from '../../../../core/fetch';
import CTA, { CTAButton } from '../../CTA';
import { BackButton } from '../../../../common/components/BackButton';
import FormError from '../../../../common/components/FormError';
import OwnerPriceSection from '../../PriceSection/owner';
import { BookingInfoCard, CustomOfferExpiryCard } from '../../cards';
import TripPanel from '../../presentation/TripPanel';
import {
  FORMS,
  OFFER_EXPIRY_DATE_FIELD,
  OFFER_EXPIRY_TIME_FIELD,
  OFFER_EXPIRY_HOURS_FIELD,
  OFFER_EXPIRY_TIME_FORMAT,
  OFFER_EXPIRY_DATE_FORMAT,
  CAPTAINED_FIELD,
} from '../../../constants';
import { sendOfferCall } from '../../../ducks/trips';
import { clearOffer } from '../../../ducks/offer';
import { getMessagesCall } from '../../../ducks/messages';
import { getTransactionCall } from '../../../ducks/transaction';
import { getTripStartDate, getTripValue, roundToNextMinutes } from '../../../helpers';
import CustomOfferExpiryIntroModal from '../../CustomOfferExpiryIntroModal';
import { close, open } from '../../../../common/ducks/zippy';
import captureException from '../../../../common/utils/captureException';
import retry from '../../../../common/utils/retry';
import { adjustTimezone } from '../../../../common/utils/time';
import PriceEditor from '../../PriceEditor';
import CaptainModal from '../../cards/Captain/modal';

const DEFAULT_EXPIRY_PERIOD_IN_HOURS = 48;
const INTRO_MODAL_NAME = 'introductionCustomOfferExpiry';
const INTRO_MODAL_FLAG_NAME = 'custom_offer_expiry_intro_shown';

type ExpiryTimeLinkProps = {
  children: React.ReactNode;
};
const ExpiryTimeLink = ({ children }: ExpiryTimeLinkProps) => <a href={`#${OFFER_EXPIRY_TIME_FIELD}`}>{children}</a>;

const mapStateToProps = ({ offer, transaction }: ReduxState) => ({
  offer,
  transaction: transaction.toJS(),
});

type PriceFormValues = {
  captain_subtotal: number;
  subtotal: number;
  [OFFER_EXPIRY_DATE_FIELD]?: string;
  [OFFER_EXPIRY_TIME_FIELD]?: string;
  [OFFER_EXPIRY_HOURS_FIELD]?: number;
};

type PriceProps = PropsFromRedux & {
  trip: ImmutableTrip;
  location: Location;
  history: History;
  match: Match;
  dispatch: Dispatch;
};

/*
 * NOTE:
 * This component is a bit of an experiment.
 * It's the only place where the hypothetical offer transaction
 * endpoint is used, and it seems like it would be nice to contain
 * that logic to this component, rather than having to make sure
 * that the transaction duck is correctly initialized and then
 * reset upon leaving this page.
 *
 * The way that this was done was to make this an uncontrolled
 * component of sorts. It takes the transaction from the duck as
 * a starting point for the internal transaction state, but otherwise
 * displays its internal state as that changes as the user modifies
 * the form.
 *
 * There's potential gotchas here - controlled components are generally
 * nice for a reason. However, in this case, it seems like isolating the
 * logic to this form makes sense, as it means we don't need separate
 * redux state for the hypothetical transactions, if that makes sense.
 */
const Price = ({
  trip,
  offer,
  location,
  history: { goBack, push },
  match: { url },
  transaction,
  dispatch,
}: PriceProps) => {
  const amountFieldRef = useFocusByRefOnMount<HTMLInputElement>();
  const [errorState, setErrorState] = useState<string | null>(null);
  const [transactionState, setTransactionState] = useState(transaction);
  const amounts: Amounts = transactionState?.amounts
    || trip.getIn(['transaction', 'amounts'])?.toJS();
  const isEditOffer = true;
  const { selectedCaptainOption } = useCaptainData(trip, offer, isEditOffer);
  const captainCostEnabled = selectedCaptainOption === CAPTAIN_OPTION_KEYS.ARRANGED_SEPARATELY;
  const {
    timezoneOffsetInHours,
  } = useCustomOfferExpiry(trip);
  const [customExpiryIsOpen, setCustomExpiryIsOpen] = useState(false);
  const currentDate = useMemo(() => new Date(), []);
  const preferredCurrency = useMemo(
    () => trip?.get('listing_currency').toJS(),
    [trip],
  );
  const tripStartDate = useMemo(
    () => getTripStartDate({ defaultsToDayEnd: true, offer, trip }),
    [offer, trip],
  );
  const hoursUntilTripStart = useMemo(
    () => differenceInHours(tripStartDate, currentDate) + timezoneOffsetInHours,
    [currentDate, timezoneOffsetInHours, tripStartDate],
  );

  // Subtract 1hr if current number of minutes will be rounded to 00
  // to prevent the case when initial expiry date set the same as trip start date
  const hoursToSubtract = currentDate.getMinutes() > 45 ? 1 : 0;
  const initialHoursUntilCustomOfferExpiry = Math.max(
    1,
    Math.min(DEFAULT_EXPIRY_PERIOD_IN_HOURS, hoursUntilTripStart - hoursToSubtract),
  );

  const [offerExpiryDate, setOfferExpiryDate] = useState(() => {
    const roundedDate = roundToNextMinutes(currentDate, { roundToNext: 15 });
    return adjustTimezone(
      roundedDate,
      initialHoursUntilCustomOfferExpiry - timezoneOffsetInHours,
    );
  });

  const captainHourlyCost = useSelector(getBoat).get('captain_hourly_cost');

  // Calculate the initial captain cost based on the duration
  const initialCaptainCost = useMemo(() => {
    // Determine if we're using trip or offer
    const tripLength = offer.get('trip_length', trip.get('trip_length'));

    const durationDays = Math.floor(moment.duration(tripLength).asDays());
    const durationHours = moment.duration(tripLength).asHours();

    const durationInHours = durationDays > 0 ? durationDays * 8 : durationHours;

    let captainCost = captainHourlyCost
      ? Math.ceil(captainHourlyCost * (durationInHours ?? 0))
      : 0;

    // If the trip is longer than 1 day, we don't pre-fill the captain cost
    if (durationDays > 1) {
      captainCost = 0;
    }

    return captainCost;
  }, [trip, offer, captainHourlyCost]);

  const initialValues: PriceFormValues = useMemo(() => {
    const hours = differenceInHours(
      offerExpiryDate,
      adjustTimezone(currentDate, -timezoneOffsetInHours),
    );
    const customExpiryInitialValues = (customExpiryIsOpen ? {
      [OFFER_EXPIRY_DATE_FIELD]: format(offerExpiryDate, OFFER_EXPIRY_DATE_FORMAT),
      [OFFER_EXPIRY_TIME_FIELD]: format(offerExpiryDate, OFFER_EXPIRY_TIME_FORMAT),
      [OFFER_EXPIRY_HOURS_FIELD]: hours,
    } : {});
    return {
      subtotal: amounts?.offer_amount ?? 0,
      captain_subtotal: captainCostEnabled ? amounts?.captain_amount ?? initialCaptainCost : 0,
      ...customExpiryInitialValues,
    };
  }, [
    amounts,
    captainCostEnabled,
    initialCaptainCost,
    currentDate,
    customExpiryIsOpen,
    offerExpiryDate,
    timezoneOffsetInHours,
  ]);

  const captained = getTripValue(offer, trip, CAPTAINED_FIELD);
  const offerId = trip.get('offer');
  const tripGuests = trip.get('guests').toJS();
  const adults = offer.get('adults') ?? tripGuests.adults;
  const children = offer.get('children') ?? tripGuests.children;
  const seniors = offer.get('seniors') ?? tripGuests.seniors;
  const infants = offer.get('infants') ?? tripGuests.infants;
  const tripLength = offer.get('trip_length') ?? trip.get('trip_length');
  // This is defined here, rather than on the transaction duck, because
  // it's the only place where the ephemeral state from the hypothetical offer
  // transaction endpoint is desired. Everywhere else it should be using
  // the state from the trip/transaction endpoint.
  const getTransaction = useCallback(
    (subtotal: number, captain_subtotal?: number) => {
      const urlBase = `/offers/${offerId}/transaction/`;
      // If subtotal and/or currency aren't defined, they're
      // simply not set.
      const queryString = stringify({
        subtotal,
        captain_subtotal: captainCostEnabled ? captain_subtotal : 0,
        currency: preferredCurrency?.code,
        captained,
        // guests and duration influence returned pricing
        adults,
        children,
        seniors,
        infants,
        trip_length: tripLength,
      });
      const fetchUrl = queryString ? `${urlBase}?${queryString}` : urlBase;

      return apiFetch(fetchUrl)
        .then((response) => response.json())
        .then((responseTransaction) => {
          setTransactionState(responseTransaction);
          setErrorState(null);
        })
        .catch(() => setErrorState('There was an error getting updated transaction details.'));
    },
    [
      offerId,
      captainCostEnabled,
      preferredCurrency?.code,
      captained,
      adults,
      children,
      seniors,
      infants,
      tripLength,
    ],
  );
  // This needs to be outside of the form because we need the debounce behavior,
  // and you can't use hooks inside of a renderprops context
  const onPriceChange = useMemo(
    () => debounce((
      { subtotal, captain_subtotal }: PriceFormValues,
    ) => subtotal > 0 && getTransaction(subtotal, captain_subtotal), 500),
    [getTransaction],
  );

  // Re-evaluate the price details if the captained state has changed,
  // as it could affect if local taxes are included or not
  const [refreshPriceDetails, setRefreshPriceDetails] = useState(() => {
    const offerCaptained = offer.get(CAPTAINED_FIELD);
    const tripCaptained = trip.get(CAPTAINED_FIELD);
    return offerCaptained != null && offerCaptained !== tripCaptained;
  });
  useEffect(() => {
    const subtotal = amounts?.offer_amount ?? 0;
    if (subtotal && refreshPriceDetails) {
      onPriceChange({
        subtotal,
        captain_subtotal: amounts?.captain_amount ?? 0,
      });
      setRefreshPriceDetails((state) => !state);
    }
  }, [amounts, offer, onPriceChange, refreshPriceDetails, transactionState, trip]);

  const onSubmit = useCallback(
    (values: PriceFormValues, { getState }: FormApi<PriceFormValues>) => {
      const {
        subtotal,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        captain_subtotal,
        [OFFER_EXPIRY_DATE_FIELD]: expiryDate,
        [OFFER_EXPIRY_HOURS_FIELD]: expiryHours,
      } = values;
      const valuesToSubmit = {
        currency: preferredCurrency?.code,
        [OFFER_EXPIRY_DATE_FIELD]: expiryDate,
        subtotal,
        captain_subtotal,
      };

      return (
        getState().valid && dispatch(
          sendOfferCall(trip.get('pk'), { ...offer.toJS(), ...valuesToSubmit }),
        )
          .then(() => Promise.all([
            dispatch(getMessagesCall(trip.get('pk'))),
            dispatch(clearOffer()),
            dispatch(getTransactionCall(trip.get('pk'))),
          ]))
          .then(() => push(parentRoute(url)))
          .then(() => {
            trackEvent('Sent', { event_category: 'Offer' });
            if (expiryHours) {
              trackEvent('Offer with Custom Expiry', {
                event_category: 'Custom Offer Expiry',
                event_label: `${expiryHours} hours`,
              });
            }
          })
          .catch(rffSubmitResponse())
      );
    },
    [dispatch, offer, preferredCurrency?.code, push, trip, url],
  );

  const preferredDateMoment = moment(dateGetter({ trip, offer }));

  const validate = useCallback(
    ({
      [OFFER_EXPIRY_DATE_FIELD]: expiryDateValue,
      captain_subtotal,
      subtotal,
    }: PriceFormValues): AnyObject => {
      const formErrors: Record<string, string> = {};
      if (expiryDateValue) {
        const currentOfferExpiryDate = new Date(expiryDateValue);
        const expiryDateIsAfterTripStartDate = isAfter(currentOfferExpiryDate, tripStartDate);
        const expiryDateIsSameAsTripStartDate = isEqual(currentOfferExpiryDate, tripStartDate);
        const minimumExpiryDate = adjustTimezone(new Date(), 1 - timezoneOffsetInHours);
        if (isBefore(currentOfferExpiryDate, minimumExpiryDate)) {
          return {
            [FORM_ERROR]: (
              <>
                <ExpiryTimeLink>Expiry time</ExpiryTimeLink>
                {' '}
                must be at least 1 hour away.
              </>),
          };
        }
        if (expiryDateIsAfterTripStartDate || expiryDateIsSameAsTripStartDate) {
          return {
            [FORM_ERROR]: (
              <>
                Please
                {' '}
                <ExpiryTimeLink>set an expiry time</ExpiryTimeLink>
                {' '}
                earlier than the trip start.
              </>),
          };
        }
      }
      if (captainCostEnabled && captain_subtotal < 1) {
        formErrors.captain_subtotal = 'Please set the price.';
      }
      if (subtotal < 1) {
        formErrors.subtotal = 'Please set the price.';
      }
      return formErrors;
    },
    [captainCostEnabled, timezoneOffsetInHours, tripStartDate],
  );

  const transactionUpdater = useMemo(() => createDecorator({
    field: ['subtotal', 'captain_subtotal'],
    updates: (_value, _name, allValues) => {
      onPriceChange(allValues as PriceFormValues);
      // Return {} means values unchanged
      return {};
    },
  }), [onPriceChange]) as Decorator<PriceFormValues>;

  const { getFlag, setFlag } = useFlags();

  useEffect(() => {
    const checkFlagShown = async () => {
      getFlag(INTRO_MODAL_FLAG_NAME)
        .then((flagStatus) => {
          if (!flagStatus) {
            dispatch(open(INTRO_MODAL_NAME));
            trackEvent('Introductory Modal Shown', {
              event_category: 'Custom Offer Expiry',
            });
            retry(
              2,
              () => setFlag(INTRO_MODAL_FLAG_NAME, true).catch(captureException),
            );
          }
        })
        .catch(captureException);
    };
    checkFlagShown();
  }, [getFlag, setFlag, dispatch]);

  const closeModal = () => {
    trackEvent('Introductory Modal Closed', {
      event_category: 'Custom Offer Expiry',
    });
    dispatch(close(INTRO_MODAL_NAME));
  };
  const onModalAccept = () => {
    closeModal();
    setCustomExpiryIsOpen(true);
  };

  // Scroll page to top on first render
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    // This is the outermost component because the CTA needs a value from the Form, and the CTA
    // needs to go in the TripPanel, which is otherwise the outermost component
    <Form<PriceFormValues>
      decorators={[transactionUpdater]}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ error, submitting, submitError, form: { submit } }) => {
        const anyError = submitError || errorState;
        // Don't check offer date during submission cuz after CLEAR_OFFER action
        // it will be in past again for offer expired states
        const isPast = !submitting && moment().diff(preferredDateMoment, 'day') > 0;
        const cta = (
          <CTA classNameModifier="withSidebar">
            <BackButton
              classNameModifier="inbox"
              onClick={goBack}
            />
            <CTAButton
              type="button"
              disabled={isPast || !selectedCaptainOption}
              onClick={submit}
              submitting={submitting}
              data-test="send-offer"
            >
              Send Offer
            </CTAButton>
          </CTA>
        );

        const getWarning = (): React.ReactNode => {
          let output = null;
          if (isPast) {
            output = "This offer's date is in the past. Please update it to a future date.";
          } else if (!selectedCaptainOption) {
            output = (
              <UnstyledButton onClick={() => dispatch(open(FORMS.CAPTAIN))}>
                Please select a captain charter type
              </UnstyledButton>
            );
          } else if (error) {
            output = error;
          }
          return output;
        };
        const warning = getWarning();

        return (
          <TripPanel
            classNameModifier="centered largerFontSize" // for Warning
            trip={trip}
            subheader="Add Price"
            intro="Please add your price and check that all the trip details are correct before sending.
              The customer will be asked to make a payment online to confirm the booking."
            warning={warning}
            cta={cta}
            backLocation={preserveSearch(PATHS.INBOX, location)}
          >
            <OwnerPriceSection
              amounts={amounts}
              listingUrl={trip.getIn(['boat', 'listing_url'])}
              trip={trip}
            >
              <PriceEditor
                currencySymbol={preferredCurrency?.symbol || ''}
                amountFieldRef={amountFieldRef}
                captainCostEnabled={captainCostEnabled}
              />
              {anyError && <FormError error={anyError} />}
            </OwnerPriceSection>
            <BookingInfoCard
              trip={trip}
              classNameModifier={isPast ? 'past' : ''}
            />
            <CustomOfferExpiryCard
              hoursUntilTripStart={hoursUntilTripStart}
              isOpen={customExpiryIsOpen}
              setIsOpen={setCustomExpiryIsOpen}
              setOfferExpiryDate={setOfferExpiryDate}
              trip={trip}
              tripStartDate={tripStartDate}
              timezoneOffsetInHours={timezoneOffsetInHours}
            />
            <CustomOfferExpiryIntroModal
              modalName={INTRO_MODAL_NAME}
              onAccept={onModalAccept}
              onModalClose={closeModal}
            />
            <CaptainModal
              modalName={FORMS.CAPTAIN}
              trip={trip}
            />
          </TripPanel>
        );
      }}
    </Form>
  );
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const decorators: any[] = [
  connector,
];

export default decorateComponent(Price, decorators);
