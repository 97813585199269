import React, { FC } from 'react';

declare global {
  interface Window {
    ttd_dom_ready: (callback: () => void) => void;
    TTDUniversalPixelApi: {
      new (): {
        init: (id: string, tags: string[], url: string) => void;
      };
    };
  }
}

type YelpConversionPixelProps = {
  value: string;
  orderId: number;
  currency: string;
};
export const YelpConversionPixel: FC<YelpConversionPixelProps> = ({ value, orderId, currency }) => {
  if (!__YELP_AD_TAG_ID__) {
    return null;
  }

  // To ensure that the pixel loads for internal route changes, we need to add a cache buster
  // Without this, React doesn't re-render image, and we are unable to track the page change
  const cacheBuster = Math.floor(Math.random() * 1000000000);

  return (
    <img
      key={cacheBuster}
      height="1"
      width="1"
      style={{ borderStyle: 'none', width: 0, height: 0 }}
      alt=""
      src={`https://insight.adsrvr.org/track/pxl/?adv=${__YELP_AD_TAG_ID__}&ct=0:x67yajy&fmt=3&v=${value}&orderid=${orderId}&vf=${currency}`}
    />
  );
};

export default function yelp(): string | null {
  return __YELP_AD_TAG_ID__ ? `(function(w,d,a,u,t,r,l,p){
  l=function(){w.ttd_dom_ready(function(){if(typeof w.TTDUniversalPixelApi=='function'){p=new
  w.TTDUniversalPixelApi();p.init(a,[u],'https://insight.adsrvr.org/track/up');}});};r=document.
  getElementsByTagName("script")[0];t=document.createElement("script");t.async=!0;t.nonce=r.nonce;
  t.onload=l;t.src='https://js.adsrvr.org/up_loader.1.1.0.js';r.parentNode.insertBefore(t,r);
  })(window,document,'${__YELP_AD_TAG_ID__}','007mas0');` : null;
}
