import React, { FC, DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { withSuffix } from 'src/common/utils/dataAttributeHelpers';
import { getClassNameFor } from 'src/common/helpers';
import styles from '../Input.module.scss';

export type TextInputProps =
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
  {
    hasError?: boolean;
    /**
     * Purpose of parse function is to make sure that value should be removed from
     * values object if it's empty string
     * Reference: https://github.com/final-form/react-final-form/issues/130
     */
    parse?: (value: string) => string;
    'data-test'?: string;
    allowAutoComplete?: boolean;
  };

/**
 * Styled text input for use with react-final-form
 */
const TextInput: FC<TextInputProps> = ({
  name,
  hasError,
  parse = (value) => value,
  'data-test': dataTest,
  allowAutoComplete = true,
  ...props
}) => (
  <Field
    parse={parse}
    type="text"
    {...props}
    title={props.placeholder}
    component="input"
    autoComplete={allowAutoComplete ? 'on' : 'off'}
    name={name || ''}
    className={getClassNameFor(styles, 'input', classNames({ hasError }))}
    data-test={withSuffix(dataTest, 'input')}
  />
);

export default TextInput;
