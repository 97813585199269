import { INQUIRY, OFFER, RESERVATION, UNAVAILABLE } from '../../constants';

const filters = [
  {
    label: 'All Confirmed Bookings',
    name: RESERVATION,
  },
  {
    label: 'All Sent Offers',
    name: OFFER,
  },
  {
    label: 'All Pending Inquiries',
    name: INQUIRY,
  },
  {
    label: 'All Unavailable Dates',
    name: UNAVAILABLE,
  },
];

export default filters;
