import { USER_ROLES } from '../../../../common/constants';

const { OWNER, RENTER } = USER_ROLES;

const content = (role, renterName, ownerName) => {
  const map = {
    [OWNER]: {
      title: `Write a public recommendation for ${renterName}.`,
      subTitle: `Endorsing ${renterName} publicly helps them with future bookings.`,
      placeholder: `Your public review for ${renterName}.`,
    },
    [RENTER]: {
      title: `Write your public review for ${ownerName}.`,
      subTitle: 'Tell future travellers about the highlights of your experience, anything you especially loved or things they should know about this experience.',
      placeholder: `Your public review for ${ownerName}.`,
    },
  };
  return map[role];
};

export default content;
