import {
  ListingCaptainOption,
  providedInPrice,
  arrangedSeparately,
  notProvided,
  withCaptain,
  CaptainOptionsEnum,
} from './getListingCaptainOption';
import {
  COMPLETE_STATES,
  CONFIRMED_STATES,
  OWNER_NEW_INQUIRY_STATES,
  TRIP_STATES,
} from '../../inbox/constants';
import { ImmutableTrip } from '../../inbox/ducks/trips';
import { userIsRenter } from '../../inbox/helpers';

export type CaptainHeadingType = (
  Pick<ListingCaptainOption, 'heading' | 'shortLabel' | 'subHeading' | 'key'>
);

const READONLY_STATES = [
  ...CONFIRMED_STATES,
  ...COMPLETE_STATES,
  TRIP_STATES.OWNER_OFFER_SENT,
  TRIP_STATES.RENTER_OFFER_RECEIVED,
];

const INQUIRY_STATES = [
  ...OWNER_NEW_INQUIRY_STATES,
  TRIP_STATES.RENTER_DIRECT_INQUIRY,
  TRIP_STATES.RENTER_MATCHING_INQUIRY,
];

const getCaptainHeadings = (
  captainOptionBinaryKey: CaptainOptionsEnum,
  selectedCaptainOption: ListingCaptainOption | undefined,
  trip: ImmutableTrip,
  isEditOffer: boolean = false,
): CaptainHeadingType => {
  const isCaptained = trip.get('captained');
  const isCostIncluded = trip.get('captain_cost_included');
  const tripState = trip.getIn(['state', 'state']);
  const isOwner = !userIsRenter(trip);

  let captainHeadings: CaptainHeadingType = {
    heading: '',
    subHeading: '',
    shortLabel: '',
    key: '',
  };

  if (selectedCaptainOption) {
    captainHeadings = selectedCaptainOption;
  } else if (!isCaptained) {
    captainHeadings = notProvided;
  } else if (isCaptained) {
    captainHeadings = withCaptain;
    if (
      INQUIRY_STATES.includes(tripState)
      && captainOptionBinaryKey === CaptainOptionsEnum.CAPTAIN_OPTION_111
    ) {
      if (isOwner) {
        captainHeadings = {
          heading: 'Captain Requested',
          subHeading:
            'Renter requires a Captain. Please choose if your captain will be arranged separately or included in the price for this trip.',
          shortLabel: withCaptain.shortLabel,
          key: '',
        };
      }
    } else if (
      INQUIRY_STATES.includes(tripState)
      && captainOptionBinaryKey === CaptainOptionsEnum.CAPTAIN_OPTION_110
    ) {
      if (isOwner) {
        captainHeadings = {
          heading: 'Please select how this trip will be operated.',
          subHeading:
            'The renter would like you to suggest how this trip will be captained, or provide more information for discussion.',
          shortLabel: withCaptain.shortLabel,
          key: '',
        };
      }
    } else if (isCostIncluded === true) {
      captainHeadings = providedInPrice;
    } else if (isCostIncluded === false) {
      captainHeadings = arrangedSeparately;
    } else if (isCostIncluded === null) {
      if (captainOptionBinaryKey === CaptainOptionsEnum.CAPTAIN_OPTION_100) {
        captainHeadings = providedInPrice;
      } else if (captainOptionBinaryKey === CaptainOptionsEnum.CAPTAIN_OPTION_010) {
        captainHeadings = arrangedSeparately;
      } else if (
        captainOptionBinaryKey === CaptainOptionsEnum.CAPTAIN_OPTION_011
        || captainOptionBinaryKey === CaptainOptionsEnum.CAPTAIN_OPTION_110
        || captainOptionBinaryKey === CaptainOptionsEnum.CAPTAIN_OPTION_111
        || captainOptionBinaryKey === CaptainOptionsEnum.CAPTAIN_OPTION_101
      ) {
        captainHeadings = {
          ...withCaptain,
          subHeading: 'I want to book with a captain, guide or host',
          key: '',
        };
      }

      const isReadonlyThread = READONLY_STATES.includes(tripState) && !isEditOffer;
      if (isReadonlyThread) {
        captainHeadings = {
          ...withCaptain,
          subHeading: 'I want to book with a captain, guide or host',
        };
      }
    }
  }

  return {
    heading: captainHeadings.heading,
    subHeading: captainHeadings.subHeading,
    shortLabel: captainHeadings.shortLabel,
    key: captainHeadings.key,
  };
};

export default getCaptainHeadings;
