import { useEffect, useRef } from 'react';

const useOnWindowWidthChange = (onChange: () => void = () => {}): void => {
  const previousWidth = useRef<number | null>(null);
  const sideEffect = useRef<() => void | null>(onChange);

  // Must be manually assigned, not as default value in useRef
  // otherwise dependency values don't resolve correctly
  sideEffect.current = onChange;

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (previousWidth.current !== width) {
        previousWidth.current = width;
        sideEffect.current?.();
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
};

export default useOnWindowWidthChange;
