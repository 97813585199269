import React, { CSSProperties, FC, HTMLInputTypeAttribute } from 'react';
import { FieldRenderProps } from 'react-final-form';
import classNames from 'classnames';

import { getClassNameFor, isMobile } from '../../../helpers';
import FieldWrapper from '../FieldWrapper';
import s from './Input.module.scss';

export type InputProps = FieldRenderProps<string, HTMLInputElement> & {
  autoFocus?: boolean;
  autoResize?: boolean;
  classNameModifier?: string;
  disabled?: boolean;
  externalError?: string;
  fieldRef?: React.MutableRefObject<HTMLInputElement>;
  id?: string;
  label?: React.ReactNode;
  max?: number;
  min?: number;
  placeholder?: string;
  shouldBeTouchedToShowError?: boolean;
  style?: CSSProperties;
  textareaRef?: React.MutableRefObject<HTMLTextAreaElement>;
  type?: HTMLInputTypeAttribute;
  warning?: string;
};

const Input: FC<InputProps> = ({
  id,
  input,
  meta,
  placeholder,
  classNameModifier,
  disabled,
  autoFocus,
  type = 'text',
  fieldRef,
  min,
  max,
  label,
  shouldBeTouchedToShowError,
  externalError, // API error
  style = {},
  warning,
}) => {
  const anyError = externalError || meta.error;
  const modifiers = classNames(classNameModifier, { error: meta.touched && meta.error });

  return (
    <FieldWrapper
      id={id || input.name}
      touched={meta.touched}
      error={anyError}
      warning={warning}
      label={label}
      rootClassName={getClassNameFor(s, 'root', modifiers)}
      errorClassName={getClassNameFor(s, 'error', modifiers)}
      shouldBeTouchedToShowError={shouldBeTouchedToShowError}
    >
      <input
        id={id || input.name}
        type={type}
        pattern={type === 'number' ? '\\d*' : undefined}
        disabled={disabled}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={!isMobile() && autoFocus}
        placeholder={placeholder}
        ref={fieldRef}
        className={getClassNameFor(s, 'field', modifiers)}
        min={min}
        max={max}
        style={style}
        {...input}
      />
    </FieldWrapper>
  );
};

export default Input;
