import React, { FC } from 'react';

type Props = {
  event: 'lead' | 'purchase' | 'signup';
  value?: number;
  orderId?: number;
};
const IHeartMediaTag: FC<Props> = ({ event = 'lead', value, orderId }) => {
  if (!__IHEARTMEDIA_ID__) {
    return null;
  }

  // To ensure that the pixel loads for internal route changes, we need to add a cache buster
  // Without this, React doesn't re-render image, and we are unable to track the page change
  const cacheBuster = Math.floor(Math.random() * 1000000000);

  const purchaseParam = event === 'purchase' ? `&value=${value}&order_id=${orderId}` : '';

  return (
    <img
      key={cacheBuster}
      src={`https://arttrk.com/pixel/?ad_log=referer&action=${event}${purchaseParam}&pixid=${__IHEARTMEDIA_ID__}`}
      alt=""
      aria-hidden="true"
      style={{
        position: 'absolute',
        width: 0,
        height: 0,
        zIndex: -1,
      }}
    />
  );
};

export default IHeartMediaTag;
