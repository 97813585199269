import { ImmutableOffer } from '../../types/offer/Offer';
import { ImmutableTrip } from '../../types/trips/Trips';
import { CAPTAIN_OPTION_KEYS } from '../constants';

const getTripCaptainOption = (trip: ImmutableTrip, offer: ImmutableOffer | undefined): string => {
  const captained = offer?.get('captained') ?? trip.get('captained');
  const captainedCostIncluded = offer?.get('captain_cost_included') ?? trip.get('captain_cost_included');

  let option = '';
  if (captained === true && captainedCostIncluded === true) {
    option = CAPTAIN_OPTION_KEYS.PROVIDED;
  } else if (captained === true && captainedCostIncluded === false) {
    option = CAPTAIN_OPTION_KEYS.ARRANGED_SEPARATELY;
  } else if (!captained) {
    option = CAPTAIN_OPTION_KEYS.NOT_PROVIDED;
  }

  return option;
};

export default getTripCaptainOption;
