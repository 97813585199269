import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { HotKeys } from 'react-hotkeys';

import { PAGES } from '../../../constants';
import FormSection from '../../../../common/components/FormSection';
import Label from '../../../../common/components/PrefixedLabel';
import { PrefixedField as Field } from '../../../../common/components/fields';
import TextArea from '../../../../common/components/inputs/TextArea';
import useOnSubmitLocationChange from '../../../../common/hooks/useOnSubmitLocationChange';
import { rffFormSubmit } from '../../../submit';
import Button from '../../../../common/components/Button';
import s from './ExperienceForm.module.scss';
import { ReduxState } from '../../../../types/reduxState';

type ExperienceValues = {
  captain_experience: {
    watercraft_experience_description?: string;
    recognized_qualifications_description?: string;
  };
};

export const populated = ({ captain_experience: captainExperience }: ExperienceValues) => (
  captainExperience && Object.values(captainExperience).some(x => !!x)
);

export const getExperienceInitialValues = ({
  inquiry,
  user,
}: ReduxState): ExperienceValues => ({
  captain_experience: {
    watercraft_experience_description:
        inquiry.getIn(['details', 'captain_experience', 'watercraft_experience_description'])
        || user.getIn(['boating_experience', 'watercraft_experience_description']),
    recognized_qualifications_description:
        inquiry.getIn(['details', 'captain_experience', 'recognized_qualifications_description'])
        || user.getIn(['boating_experience', 'recognized_qualifications_description']),
  },
});

const experiencePlaceholder = 'Please describe any relevant experience (eg. "5 years boating'
  + ' experience. Currently own a 24\' bowrider."';
const qualificationsPlaceholder = 'Please list any recognized qualifications (eg. IYT, RYA, ASA,'
  + ' USCG, State Boater Safety etc).';

const ExperienceForm: FC = () => {
  const dispatch = useDispatch();
  const initialValues = useSelector(getExperienceInitialValues);
  const onSubmitSuccess = useOnSubmitLocationChange(PAGES.EXTRAS);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={rffFormSubmit(dispatch, onSubmitSuccess)}
    >
      {({ handleSubmit, dirty }) => (
        <form
          onSubmit={handleSubmit}
          className={s.root}
        >
          <HotKeys
            handlers={{ submit: handleSubmit }}
            keyMap={{ submit: ['ctrl+enter', 'meta+enter'] }}
            tabIndex={undefined}
          >
            <FormSection prefix="captain_experience">
              <Label
                htmlFor="watercraft_experience_description"
                className={s.label}
              >
                Relevant Experience
              </Label>
              <Field
                autoFocus
                name="watercraft_experience_description"
                placeholder={experiencePlaceholder}
                component={TextArea}
              />

              <Label
                htmlFor="recognized_qualifications_description"
                className={s.label}
              >
                Recognized Qualifications
              </Label>
              <Field
                name="recognized_qualifications_description"
                placeholder={qualificationsPlaceholder}
                component={TextArea}
              />
            </FormSection>

            <div className={s.actionGroup}>
              <Button
                type="submit"
                fullWidth
              >
                {/* This essentially asks whether values have been set */}
                {(dirty || populated(initialValues)) ? 'Next' : 'Skip'}
              </Button>
            </div>
          </HotKeys>
        </form>
      )}
    </Form>
  );
};

export default ExperienceForm;
