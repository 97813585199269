import React, { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { useBoats } from 'src/common/hooks';
import { BoatDetail } from 'src/types/boat/BoatDetail';
import { PATHS } from 'src/common/constants';
import { getClassNameFor } from 'src/common/helpers';
import Dropdown from 'src/common/components/Dropdown';
import { useCalendarContext, useListingsFilterContext } from 'src/calendar/hooks';
import FilterButton from 'src/calendar/components/FilterButton';
import ListingsDropdown from 'src/calendar/components/ListingsDropdown';
import Icon from 'src/common/components/IconDS22';
import s from './ListingFilter.module.scss';

const ListingFilter: FC<Props> = ({ classNameModifier = '' }) => {
  const { boats, allBoats, unpublishedBoats } = useBoats();
  const {
    filters,
    activeFilters,
    toggleFilter,
    toggleAllFilters,
    setActiveFilters,
  } = useListingsFilterContext();
  const { getInstabooksOnFilterChange } = useCalendarContext();
  const { pathname } = useLocation();
  const isInstabook = pathname === PATHS.INSTABOOK;

  const selectAllInputProps = useMemo(() => ({
    input: {
      checked: activeFilters.length === filters.length,
      name: 'all',
      onChange: toggleAllFilters,
    },
    indeterminate: activeFilters.length > 0 && activeFilters.length < filters.length,
  }), [activeFilters.length, filters.length, toggleAllFilters]);

  const boatShortName = useMemo(
    () => allBoats.find(
      (boat) => activeFilters.includes(boat.id),
    )?.shortname,
    [activeFilters, allBoats],
  );

  const getInputProps = useCallback((toggleFn: () => void) => (boatHashId: BoatDetail['id']) => {
    const input = {
      checked: activeFilters.includes(boatHashId),
      name: boatHashId,
      onChange: (event: SyntheticEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!isInstabook) {
          toggleFilter(boatHashId);
          return;
        }

        if (activeFilters.includes(boatHashId)) {
          event.preventDefault();
        } else {
          setActiveFilters([boatHashId]);
          getInstabooksOnFilterChange(boatHashId);
          toggleFn();
        }
      },
    };
    return {
      input,
    };
  }, [
    activeFilters,
    isInstabook,
    toggleFilter,
    setActiveFilters,
    getInstabooksOnFilterChange,
  ]);

  return (
    <Dropdown className={s.root}>
      {({ isOpen, toggle }) => (
        <>
          {isInstabook && (
            <span className={s.listingLabel}>Listing:</span>
          )}
          <FilterButton
            classNameModifier={classnames(classNameModifier, {
              open: isOpen,
              active: activeFilters.length > 0,
            })}
            handleClick={toggle}
          >
            {isInstabook ? (
              <div className={s.currentListing}>
                <span className={s.listingName}>{boatShortName}</span>
                <Icon
                  id={isOpen ? 'caret-up' : 'caret-down'}
                  size="s"
                  className={s.icon}
                />
              </div>
            ) : 'Listings'}
            {!isInstabook && activeFilters.length > 0 && (
              <span className={getClassNameFor(s, 'filterCount')}>
                {activeFilters.length}
              </span>
            )}
          </FilterButton>

          {isOpen && (
            <ListingsDropdown
              activeBoats={boats}
              unpublishedBoats={unpublishedBoats}
              classNameModifier={classnames('calendar', classNameModifier, {
                instabook: isInstabook,
              })}
              control={isInstabook ? 'radio' : 'checkbox'}
              getInputProps={getInputProps(toggle)}
              selectAllInputProps={
                !isInstabook ? selectAllInputProps : undefined
              }
              title={isInstabook
                ? 'Select a listing to display in Instabook Calendar.'
                : 'Select listings to display in calendar.'}
              mobileActionToggle={toggle}
            />
          )}
        </>
      )}
    </Dropdown>
  );
};

type Props = {
  classNameModifier?: string;
};

export default ListingFilter;
