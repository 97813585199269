import { fromJS, List } from 'immutable';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { fetchCatch } from 'src/core/sentry';
import { BoatsResponse } from 'src/types/boat/BoatsResponse';
import type { ImmutableBoats } from '../../types/boat/BoatDetail';
import type { ImmutableInquiry, ImmutableInstabookListings } from '../../types/inquiry/Inquiry';
import type { ImmutableMap } from '../../types/ImmutableMap';
import type { ReduxState } from '../../types/reduxState';
import { apiFetchThunk } from '../../common/ducks/fetch';
import { BROADCAST_BOATS_FIELD, INQUIRY_ID_FIELD, INSTABOOK_LISTINGS_FIELD } from '../constants';
import { removeEmptyFields } from '../helpers';

export const UPDATE_INQUIRY = 'booking_inquiry/inquiry/UPDATE_INQUIRY';
export const SET_INQUIRY_MISMATCH = 'booking_inquiry/inquiry/SET_INQUIRY_MISMATCH';
export const DELETE_INQUIRY_MISMATCH = 'booking_inquiry/inquiry/DELETE_INQUIRY_MISMATCH';
export const SET_BROADCAST_BOATS = 'booking_inquiry/inquiry/SET_BROADCAST_BOATS';
export const SET_INSTABOOK_LISTINGS = 'booking_inquiry/inquiry/SET_INSTABOOK_LISTINGS';
export const SET_COMMS_WARNING = 'booking_inquiry/inquiry/SET_COMMS_WARNING';
export const SET_SEARCH_URL = 'booking_inquiry/inquiry/SET_SEARCH_URL';
export const REMOVE_FLAGGED_FIELDS = 'booking_inquiry/inquiry/REMOVE_FLAGGED_FIELDS';
export const SET_INQUIRY_ID = 'booking_inquiry/inquiry/SET_INQUIRY_ID';

type MaybeImmutableMap<T extends Record<string, unknown>> = ImmutableMap<T> | T;
export const updateInquiry = <T extends MaybeImmutableMap<Record<string, unknown>>>(
  details: T,
) => ({ type: UPDATE_INQUIRY, details } as const);
export const setInquiryMismatch = (mismatch: Record<string, string>) => (
  { type: SET_INQUIRY_MISMATCH, mismatch } as const
);
export const deleteInquiryMismatch = (mismatch: string) => (
  { type: DELETE_INQUIRY_MISMATCH, mismatch } as const
);
export const setBroadcastBoats = (boats: ImmutableBoats) => (
  { type: SET_BROADCAST_BOATS, boats } as const
);
export const setInstabookListings = (listings: ImmutableInstabookListings) => (
  { type: SET_INSTABOOK_LISTINGS, listings } as const
);
export const setCommsWarning = (warn: boolean) => ({ type: SET_COMMS_WARNING, warn } as const);
export const setSearchUrl = (url: string) => ({ type: SET_SEARCH_URL, url } as const);
export const removeFlaggedFields = () => ({ type: REMOVE_FLAGGED_FIELDS } as const);
export const setInquiryId = (inquiryId: string) => ({ type: SET_INQUIRY_ID, inquiryId } as const);

const INQUIRY_DEFAULT_STATE: ImmutableInquiry = fromJS({
  details: {},
  mismatches: {},
  [BROADCAST_BOATS_FIELD]: [],
  communications_warning: false,
  search_url: '/boat-rental/San-Francisco--CA--United-States/',
  [INSTABOOK_LISTINGS_FIELD]: [],
  [INQUIRY_ID_FIELD]: '',
});

type InquiryActions =
  | ReturnType<typeof updateInquiry>
  | ReturnType<typeof setInquiryMismatch>
  | ReturnType<typeof deleteInquiryMismatch>
  | ReturnType<typeof setBroadcastBoats>
  | ReturnType<typeof setInstabookListings>
  | ReturnType<typeof setCommsWarning>
  | ReturnType<typeof setSearchUrl>
  | ReturnType<typeof removeFlaggedFields>
  | ReturnType<typeof setInquiryId>;

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (inquiry = INQUIRY_DEFAULT_STATE, action: InquiryActions) => {
  switch (action.type) {
    case UPDATE_INQUIRY:
      return inquiry.mergeIn(['details'], action.details);
    case SET_INQUIRY_MISMATCH:
      return inquiry.mergeIn(['mismatches'], action.mismatch);
    case DELETE_INQUIRY_MISMATCH:
      return inquiry.deleteIn(['mismatches', action.mismatch]);
    case SET_BROADCAST_BOATS:
      return inquiry.set(BROADCAST_BOATS_FIELD, action.boats);
    case SET_INSTABOOK_LISTINGS:
      return inquiry.set(INSTABOOK_LISTINGS_FIELD, action.listings);
    case SET_COMMS_WARNING:
      return inquiry.set('communications_warning', action.warn);
    case SET_SEARCH_URL:
      return inquiry.set('search_url', action.url);
    case REMOVE_FLAGGED_FIELDS: {
      const communicationsWarning = inquiry.get('communications_warning');
      if (communicationsWarning) {
        return inquiry
          .deleteIn(['details', 'captain_experience'])
          .deleteIn(['details', 'captain_required'])
          .set('communications_warning', false);
      }
      return inquiry
        .deleteIn(['details', 'captain_required']);
    }
    case SET_INQUIRY_ID:
      return inquiry.set(INQUIRY_ID_FIELD, action.inquiryId);
    default:
      return inquiry;
  }
};

// TODO: test
type GetBroadcastBoatsThunk = ThunkAction<ImmutableBoats, ReduxState, {}, Action>;
export const getBroadcastBoats = (
  values: Record<string, string>,
): GetBroadcastBoatsThunk => (
  dispatch => (
    dispatch(apiFetchThunk(
      '/inquiries/broadcast_boats/',
      {
        method: 'POST',
        body: JSON.stringify(removeEmptyFields(values)),
      },
    )))
    .then((response: Response) => {
      if (!response.ok) {
        if (response.status === 404) {
          // TODO: this is awkward and probably should not be nested.
          return response
            .json()
            .then(body => dispatch(setSearchUrl(body.search_url)))
            .then(() => List());
        }
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw response;
      }
      return response.json();
    })
    .then(fromJS)
    .then((boats: ImmutableBoats) => {
      dispatch(setBroadcastBoats(boats));
      return boats;
    })
);

type GetInstabookListingsThunk = ThunkAction<ImmutableInstabookListings, ReduxState, {}, Action>;
export const getInstabookListings = (
  values: Record<string, string>,
): GetInstabookListingsThunk => (
  dispatch => (
    dispatch(apiFetchThunk(
      '/inquiries/related_instabooks/',
      {
        method: 'POST',
        body: JSON.stringify(removeEmptyFields(values)),
      },
    ))
  )
    .then((response: Response) => {
      if (!response.ok) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw response;
      }
      return response.json();
    })
    .then((relatedListings: string[]) => {
      if (relatedListings.length) {
        return dispatch(apiFetchThunk(
          `search/v1/boats/?boat_ids=${relatedListings.toString()}`,
          {
            method: 'GET',
          },
        )).then((response: Response) => {
          if (!response.ok) {
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw response;
          }
          return response.json();
        });
      }
      return new Promise((resolve) => { resolve({ results: [] }); });
    })
    .then((boatsResponse: BoatsResponse) => fromJS(boatsResponse.results))
    .then((listings: ImmutableInstabookListings) => {
      dispatch(setInstabookListings(listings));
      return listings;
    })
);
type InspectCommentsThunk = ThunkAction<void, ReduxState, {}, Action>;
/**
 * Checks texts fields for communications that violate the TOS.
 */
export const inspectComments = (comment: string): InspectCommentsThunk => (
  // We wanna return a promise here so that we can `promise.all`
  // it, so we wrap with async even though we're not using await.
  async (dispatch, getState) => {
    // We use `getState` here rather than passing in the inquiry
    // because this function is called right after the comment is
    // dispatched into the store, so the comment isn't going to be
    // available in props by that point.
    const { inquiry } = getState();
    const comments: string[] = [
      inquiry.getIn([
        'details',
        'captain_experience',
        'watercraft_experience_description',
      ]),
      inquiry.getIn([
        'details',
        'captain_experience',
        'recognized_qualifications_description',
      ]),
      comment,
    ].filter(x => !!x);
    // Do backend call only if comments were set
    return comments?.length > 0
      ? dispatch(apiFetchThunk(
        '/support/strings/inspect/',
        {
          method: 'POST',
          body: JSON.stringify({ strings: removeEmptyFields(comments) }),
        },
      ))
        .then((response: Response) => {
          if (!response.ok) {
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw response;
          }
          response.json().then(({ strings }: { strings: string[] }) => {
            if (strings.some(str => str)) {
              dispatch(setCommsWarning(true));
            }
          });
        })
        .catch(fetchCatch)
      : null;
  }
);
