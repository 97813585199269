import React from 'react';

import AlertMessage from './AlertMessage';
import s from './FieldWrapper.module.scss';

type FieldWrapperProps = {
  error?: React.ReactNode;
  warning?: React.ReactNode;
  touched?: boolean;
  label?: React.ReactNode;
  id: string;
  rootClassName?: string;
  errorClassName?: string;
  warningClassName?: string;
  labelClassName?: string;
  shouldBeTouchedToShowError?: boolean;
};

const FieldWrapper: React.FC<FieldWrapperProps> = ({
  id,
  children,
  label,
  touched,
  error,
  warning,
  rootClassName = s.root,
  errorClassName = s.error,
  labelClassName = s.label,
  warningClassName = s.warning,
  shouldBeTouchedToShowError,
}) => (
  <div className={rootClassName}>
    {label && (
      <label
        htmlFor={id}
        className={labelClassName}
      >
        {label}
      </label>
    )}
    {children}
    {!!(error || warning) && (
      <AlertMessage
        id={id}
        error={error}
        errorClassName={errorClassName}
        warning={warning}
        warningClassName={warningClassName}
        touched={touched}
        shouldBeTouchedToShowError={shouldBeTouchedToShowError}
      />
    )}
  </div>
);

export default FieldWrapper;
