// We extend the global window interface with the `uetq` namespace for the
// Microsoft Bing event tracking functionality. This allows us to use `window.uetq`
// without Typescript complaining that it doesn't exist on the global.
declare global {
  interface Window {
    uetq: any[];
  }
}

// initialize window.uetq if not already initialized
if (typeof window !== 'undefined') {
  window.uetq = window.uetq || [];
}

/**
 * wrapper func for Microsoft Bing's `window.uetq('event', eventName, parameters)`
 * @param eventName event type
 * @param params event parameters object
 */
export function microsoftEvent(
  eventName: string,
  params: {},
): void {
  const { uetq } = window;
  if (uetq) {
    uetq.push('event', eventName, params);
  }
}

export default function microsoft(): string | null {
  return __MICROSOFT_TAG_ID__
    ? `(function(w,d,t,r,u) { var f,n,i; w[u]=w[u]||[],f=function() {
    var o={ti:"${__MICROSOFT_TAG_ID__}", enableAutoSpaTracking: true}; o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad") },
    n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function() {
    var s=this.readyState; s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null) },
    i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i) }) (window,document,"script","//bat.bing.com/bat.js","uetq");` : null;
}
