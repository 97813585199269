import React, { FC, ReactNode } from 'react';

import type { ImmutableTrip } from 'src/types/trips/Trips';
import { getClassNameFor } from '../../../common/helpers';
import { BackLink } from '../../../common/components/BackButton';
import TripControls from '../TripControls';
import s from './SubHeader.module.scss';

type SubHeaderProps = {
  trip: ImmutableTrip;
  children: ReactNode;
  backLocation: {
    search: string;
    pathname: string;
  };
  classNameModifier?: string;
  withTripControls?: boolean;
  hasPendingTripChanges: boolean;
};

const SubHeader: FC<SubHeaderProps> = ({
  trip,
  children,
  backLocation,
  classNameModifier = '',
  withTripControls = true,
  hasPendingTripChanges,
}) => (
  <div className={getClassNameFor(s, 'root', classNameModifier)}>
    <BackLink
      to={backLocation}
      classNameModifier={`mobile-aib ${classNameModifier}`}
    />
    <div className={s.wrapper}>
      <h1 className={getClassNameFor(s, 'heading', classNameModifier)}>
        {children}
      </h1>
      {withTripControls && (
        <TripControls
          trip={trip}
          hasPendingTripChanges={hasPendingTripChanges}
          hasUnreadMessages={trip.get('has_unread_message', false)}
        />
      )}
    </div>
  </div>
);

export default SubHeader;
