import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

const useTripWire = ({ initialTripped = false, top = 0, callback = () => {} }) => {
  const tripped = useRef(initialTripped);

  const { ref, entry } = useInView({
    rootMargin: `-${top}px 0px 0px 0px`,
    threshold: [0, 1],
    initialInView: initialTripped,
  });
  const newIsTripped = top > entry?.boundingClientRect?.top;

  useEffect(() => {
    if (tripped.current !== newIsTripped) {
      callback(newIsTripped);
      tripped.current = newIsTripped;
    }
  }, [callback, newIsTripped]);

  return {
    ref,
  };
};

export default useTripWire;
