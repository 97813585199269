import React, { FC } from 'react';

import Svg from '../../Svg';
import s from './RecommendationFields.module.scss';

type ThumbProps = {
  htmlFor: string;
};

const Thumb: FC<ThumbProps> = ({ htmlFor }) => (
  <label
    className={s.label}
    htmlFor={htmlFor}
  >
    <Svg
      href="#thumb"
      className={s.thumb}
    />
  </label>
);

export default Thumb;
