import { OrderedMap } from 'immutable';

/**
 * Defines non-english locales that should be supported in url prefixes.
 * @see `isExternalPath`
 */
export const LOCALE_PREFIXES = [
  // `en` doesn't have a prefix, so is excluded
  '/fr',
  '/es',
  '/pt',
  '/ja',
];

export const LOCALE_COOKIE_NAME = 'gmb_locale';
export const RECENT_SEARCH_NAME = 'recent_search';

// Used in inbox experience form
export const EXPERIENCE_MAP = OrderedMap([
  ['captain_certification_asa', 'ASA'],
  ['captain_certification_usps', 'USPS'],
  ['captain_certification_usgc', 'USGC'],
  ['captain_certification_state_boater_safety', 'State Boater Safety'],
  ['captain_certification_oupv', 'OUPV'],
  ['captain_certification_50ton', '50ton'],
  ['captain_certification_100ton', '100ton'],
  ['captain_certification_200ton', '200ton'],
  ['captain_certification_500ton', '500ton'],
  ['captain_certification_1500ton', '1500ton'],
  ['captain_certification_unlimited', 'Unlimited'],
]);

export const KEYCODES = {
  ESC: 27,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
  BACK_SPACE: 8,
};
export const MEDIA_QUERIES = {
  MOBILE: '(max-width: 767px)',
  DESKTOP: '(min-width: 768px)',
  // For some odd reason more readable form:
  // `(min-width: 768px) and (max-width: 1023px)`
  // Reported in reverse order in mediaQueryList at
  // least in Google Chrome. TODO: find the cause
  SMALL_DESKTOP: '(max-width: 1023px) and (min-width: 768px)',
  LARGE_DESKTOP: '(min-width: 1024px)',
  EXTRA_LARGE_DESKTOP: '(min-width: 1280px)',
  NOT_DESKTOP: '(max-width: 1023px)',
  // new breakpoints
  // TODO: get rid of old breakpoints
  XXS: '(max-width: 480px)',
  XS: '(max-width: 767px)',
  S: '(max-width: 1023px) and (min-width: 768px)',
  M: '(max-width: 1279px) and (min-width: 1024px)',
  L: '(max-width: 1439px) and (min-width: 1280px)',
  XL: '(max-width: 1599px) and (min-width: 1440px)',
  XXL: '(max-width: 1919px) and (min-width: 1600px)',
  XXXL: '(min-width: 1920px)',
};
export const META = {
  DESCRIPTION: {
    DEFAULT:
      'From pontoon boat rentals to yacht rentals, we’re the leading marketplace for renting a boat across the globe. Rent a boat near you with Getmyboat.',
  },
};
export const MOMENT_FORMAT = {
  DATE: 'ddd ll',
  TIME: 'h:mm A', // TODO: rename to TIME_12 for consistency
  TIME_24: 'HH:mm', // TODO: replace all occurrences
  VALUE: 'YYYY-MM-DD',
};

/** eg: Sat, May 25 2024 */
export const DISPLAY_DATE_FORMAT_LONG = 'EEE, MMM d yyyy';
export const DISPLAY_DATE_FORMAT = 'dd MMM yyyy';

export const TIME_12 = 'h:mm a';

/*
 * Pulling these:
 * select count(*), avg(rating) from boat_reviews;
 * select count(*), avg(rating) from renter_reviews;
 * select count(*), avg(net_promoter_score) from customer_feedback;
 *
 * weighted average of all three is:
 * sum((total * average) for average, total in averages) / sum(total for total in averages)
 *
 * Data is current as of 2020-01-16
 */

export const AVERAGE_RATING = 4.9;
export const NUMBER_OF_REVIEWS = 385000;

const LEGAL_PREFIX = '/legal/';
const AUTH_PREFIX = '/s/auth/';
const ADMIN_PREFIX = '/admin/';
const CHARGE_PREFIX = '/charge/';
const LISTING_PREFIX = '/trips/';

export const QRCODE_API_PREFIX = '/api/qrcode/';

// React URLs
export const PATHS = {
  AUTH: AUTH_PREFIX,
  LOGIN: `${AUTH_PREFIX}login/`,
  REGISTER: `${AUTH_PREFIX}register/`,
  REGISTER_AGREEMENT: `${AUTH_PREFIX}register/agreement/`,
  PASSWORD_FORGOT: `${AUTH_PREFIX}password/forgot/`,
  PASSWORD_FORGOT_SUBMITTED: `${AUTH_PREFIX}password/forgot/submitted/`,
  PASSWORD_FORGOT_CONFIRM: `${AUTH_PREFIX}password/forgot/confirm/`,
  OTP: `${AUTH_PREFIX}login/otp`,
  CHOOSE_MFA_METHOD: `${AUTH_PREFIX}login/choose-mfa-method`,
  MFA_RECOVERY: `${AUTH_PREFIX}login/mfa-recovery`,
  INBOX: '/inbox/',
  INQUIRY: '/s/inquiry/',
  BANNED: '/s/banned/',
  SUSPENDED: '/s/suspended/',
  SUBSCRIBE: '/marketing/subscribe/',
  CALENDAR: '/calendar/',
  INSTABOOK: '/calendar/instabook/',
  INSTABOOK_BOOKING: '/instabook/',
};

export const MOVED_PAGES = {
  ABOUT: '/about/',
  SEARCH: '/boat-rental/',
  LISTING: LISTING_PREFIX,
  CATEGORIES: '/categories/',
  PRESS: '/press/',
  HOW_IT_WORKS: '/how-it-works/',
  FOR_OWNERS: '/for-owners/',
  MOBILE_APPS: '/mobile-apps/',
  CONTACT_US: '/contact-us/',
  DESTINATIONS: '/destinations/',
};

// Django & Next URLs
export const EXTERNAL_PATHS = {
  ACCOUNT: `${ADMIN_PREFIX}account/`,
  ACCOUNT_SETTINGS: '/account/settings/',
  BRIDGE: '/bridge/',
  JOURNAL: '/journal/',
  PAYOUTS: '/user/payments/',
  LEGAL: LEGAL_PREFIX,
  TERMS: `${LEGAL_PREFIX}terms/`,
  COOKIES_POLICY: `${LEGAL_PREFIX}cookies-policy/`,
  PRIVACY_POLICY: `${LEGAL_PREFIX}privacy-policy/`,
  MEMBER_INTERFACE: `${LEGAL_PREFIX}member-agreement/`,
  ACCESSIBILITY_STATEMENT: `${LEGAL_PREFIX}accessibility-statement/`,
  ADMIN_PROFILE: `${ADMIN_PREFIX}profile/`,
  ADMIN_SETTINGS: `${ADMIN_PREFIX}settings/`,
  ADMIN_PAYMENTS: `${ADMIN_PREFIX}payments/`,
  ADMIN_LISTINGS: `${ADMIN_PREFIX}listings/`,
  ADMIN_LISTING_EDITOR: `${ADMIN_PREFIX}listing/`,
  ADMIN_LISTING_NEW: `${ADMIN_PREFIX}listing/new/`,
  YOUR_LISTINGS: '/boat/my_boats/',
  /**
   * This is the old listing page URL pattern (e.g. `/boat/12345/`).
   * We need it here so it can be handled by the `getReturnUrl()` to allow to redirect
   * to a new URL (e.g. `/trips/<e1wAZpmK>/`).
   */
  OLD_LISTING: '/boat/',
  BOOK_NOW: `${LISTING_PREFIX}book-now/`,
  FAVORITES: '/favorites/',
  DIRECT_BOOKING: '/direct-booking/',
  /**
   * This is temporary URL for direct booking to initiate the working flow.
   * It will be update once flow is completed.
   */
  CREATE_DIRECT_BOOKING: '/direct-booking/owner/new/',
  CHARGE: `${CHARGE_PREFIX}`,
  CHARGE_ADD_ON: `${CHARGE_PREFIX}add-on/`,
  ...MOVED_PAGES,
};

export const SUPPORT_URLS = {
  REQUEST: 'https://getmyboat.zendesk.com/hc/en-us/requests/new',
  HELP_CENTER: 'https://getmyboat.zendesk.com/hc/en-us/?mobile_site=false',
  WHY_COMMUNICATE_AND_PAY_THROUGH_GETMYBOAT:
    'https://getmyboat.zendesk.com/hc/en-us/articles/360002544833-Why-should-I-communicate-and-pay-through-Getmyboat-',
  COMMUNITY_GUIDELINES:
    'https://getmyboat.zendesk.com/hc/en-us/articles/360003553693-Getmyboat-Community-Guidelines-',
  HOW_GMB_WORK:
    'https://getmyboat.zendesk.com/hc/en-us/articles/360000962974-How-does-Getmyboat-work-',
  CREATE_LISTING_TIPS:
    'https://getmyboat.zendesk.com/hc/en-us/articles/115002673573-Recommendations-for-creating-a-great-listing-and-profile-',
};

export const TITLE = {
  PROJECT: 'Getmyboat',
  DEFAULT: 'Boat Rentals, Yacht Charters, and More',
  INQUIRY: 'Booking Inquiry',
  AI: 'Inbox',
  REVIEW: 'Add your Review',
  PRESS: 'Getmyboat in the News',
  CONTACT_US: 'Contact Us',
  BANNED: 'Account Disabled',
  SUSPENDED: 'Account Disabled Temporally',
  TERMS: 'Terms of Use',
  COOKIES_POLICY: 'Cookies Policy',
  PRIVACY_POLICY: 'Privacy Policy',
  MEMBER_INTERFACE: 'Member Interface Agreement',
  ACCESSIBILITY_STATEMENT: 'Accessibility Statement',
  AGREEMENT: 'Our Agreement',
  CALENDAR: 'Calendar',
  INSTABOOK: 'Instabook',
};
// For Inbox
export const TOOLTIP = {
  FEE: 'This non-refundable 7% service fee helps us run our platform, connect you with new customers and provide customer service.',
  OWNER_BREAKDOWN:
    'Your customer will be charged this total amount for your services plus credit card processing and Getmyboat customer service fees.',
  RENTER_BREAKDOWN:
    'You will be charged this total amount for your trip plus credit card processing and Getmyboat customer service fees.',
  CAPACITY: 'It might be useful to provide this customer with a maximum capacity for this trip.',
};

export const USER_ROLES = {
  OWNER: 'owner',
  RENTER: 'renter',
};

export const LOCKDOWN_PAGES = [PATHS.SUSPENDED, PATHS.BANNED];

export const PRESS_TITLE = 'Getmyboat in the press';

export const COOKIE_POLICY_DATE = '2018-06-04';

// This is a list of category slugs
// used in routing logic in render.tsx
export const CATEGORY_SLUG_LIST = [
  'powerboats',
  'events-large-groups',
  'personal-watercraft',
  'sailboat-rental',
  'houseboat-overnight',
  'tours-lessons',
  'fishing-charters',
  'paddle-sports',
  'cruises-water-taxis-ferries',
  'rent-a-powerboat',
  'pontoon-boat-rentals',
  'deck-boat-rental',
  'trawler-rental',
  'bowrider-rental',
  'inboard-propulsion-rental',
  'dinghy-rental',
  'canal-boat-rental',
  'power-catamaran-charter',
  'rib-rental',
  'airboat-tour',
  'electric-boat-rental',
  'bass-boat-rental',
  'jon-boat-rental',
  'center-console-boat-rentals',
  'cuddy-cabin-rental',
  'powerboat-lessons',
  'water-taxi',
  'luxury-yacht-charter',
  'yacht-rentals',
  'passenger-boat',
  'sailing-mega-yacht-charter',
  'power-mega-yacht-charter',
  'jet-ski-rental',
  'jet-ski-lessons',
  'rent-a-sailboat',
  'cruising-monohull',
  'beach-monohull-rental',
  'daysailer-rental',
  'wind-jammer-rental',
  'gulet-charter',
  'sloop-rental',
  'schooner-rental',
  'tall-ship-rental',
  'cruising-catamaran-rental',
  'beach-catamaran-rental',
  'trimaran-rental',
  'boating-lessons',
  'sup-yoga',
  'surf-lessons',
  'scuba-lessons',
  'jet-ski-tour',
  'eco-tour',
  'jet-boat-tour',
  'wildlife-tour',
  'whale-watching',
  'dolphin-tour',
  'swamp-tour',
  'dinner-cruise',
  'river-cruise',
  'sightseeing',
  'cruising',
  'city-tour',
  'fishing-boat-rental',
  'sport-fishing',
  'head-boat-rental',
  'surf-charter',
  'snorkeling',
  'wakeboarding',
  'flyboarding',
  'windsurfing',
  'diving',
  'parasailing',
  'kiteboarding',
  'water-skiing',
  'rafting',
  'canoe-rentals',
  'row-boat-rentals',
  'raft-rentals',
  'paddle-boat-rentals',
  'paddleboard-rentals',
  'kayak-rentals',
  'sailing-lessons',
  'wakeboarding-lessons',
  'water-skiing-lessons',
  'non-powered-watercraft',
  'water-sports',
  'tours',
  'houseboat-rentals',
  'windjammer-rental',
];

export const NEW_TAB_ATTRIBUTES = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

export const SUPPORT_EMAIL = 'support@getmyboat.com';
export const SUPPORT_PHONE_NUMBER = '+18189272148';

export const BOOKING_MOTIVATOR_STATE = {
  UNAVAILABLE: 'UNAVAILABLE',
  LOOKS_GOOD: 'LOOKS_GOOD',
  LIMITED_AVAILABILITY: 'LIMITED_AVAILABILITY',
  IN_DEMAND: 'IN_DEMAND',
  HIGH_DEMAND: 'HIGH_DEMAND',
  UNUSUAL_SIGHTING: 'UNUSUAL_SIGHTING',
} as const;

export const NEAR_ME = 'near-me';

export const CAPTAIN_OPTION = {
  CAPTAIN_PROVIDED_IN_PRICE: 'Provided In Price',
  CAPTAIN_ARRANGED_SEPARATELY: 'Arranged Separately',
  CAPTAIN_FLEXIBLE: 'Flexible',
  CAPTAIN_NOT_APPLICABLE: 'Not Applicable',
} as const;

export const LISTING_CAPTAIN_OPTION = {
  PROVIDED_IN_PRICE: 1,
  ARRANGED_SEPARATELY: 2,
  NOT_PROVIDED: 3,
  WITH_CAPTAIN: 4,
};

export const CAPTAIN_OPTION_KEYS = {
  PROVIDED: 'captainProvided',
  ARRANGED_SEPARATELY: 'captainArrangedSeparately',
  NOT_PROVIDED: 'captainNotProvided',
} as const;

export const PASSWORD_LENGTH = 9;

export const RECENT_SEARCHES_KEY = 'recent_searches';

export const BEST_OF_YEAR = '2025';
export const BEST_OF = `best_of_${BEST_OF_YEAR}`;
