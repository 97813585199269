import React, { FC } from 'react';
import s from './Modal.module.scss';

type TitleProps = {
  children: React.ReactNode;
};

const Title: FC<TitleProps> = ({ children }) => (
  <h3 className={s.title}>
    {children}
  </h3>
);

export default Title;
