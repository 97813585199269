import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import 'react-dates/initialize';
import classNames from 'classnames';
import { Route, useRouteMatch } from 'react-router-dom';

import Media from 'react-media';
import { MEDIA_QUERIES, PATHS } from 'src/common/constants';
import { getClassNameFor } from 'src/common/helpers';
import {
  INQUIRY,
  INSTABOOK,
  OFFER,
  RESERVATION,
  UNAVAILABLE,
  PARTIALLY_UNAVAILABLE,
  CONFLICTED,
} from 'src/calendar/constants';
import {
  addMonths,
  aggregateEvents,
  dayHasEventType,
  dayHasOnlyUnavailableEvents,
  flattenMonths,
  todayDate,
  startOfMonth,
  subMonths,
  newTZDate,
  toDateString,
  getMultiInstabookIdsForDate,
} from 'src/calendar/helpers';
import {
  MAX_NEXT_MONTHS,
  MAX_NEXT_MONTHS_INSTABOOK_OWNER,
  MAX_PREVIOUS_MONTHS,
  useCalendarContext,
} from 'src/calendar/hooks';
import useCalendarFocus from 'src/calendar/hooks/useCalendarFocus';
import Icon from 'src/common/components/IconDS22';
import InstabookEditorWrapper from 'src/calendar/components/InstabookEditorWrapper';
import UnavailableEditor from 'src/calendar/components/UnavailableEditor';
import Button from 'src/common/components/Button';
import SimpleCalendar from './SimpleCalendar';
import s from './MiniCal.module.scss';

type MiniCalProps = {
  onSelectedDateChange?: () => void;
  isDropDown?: boolean;
  selectedInstabookDate?: Date | null;
};

const MiniCal: FC<MiniCalProps> = ({
  onSelectedDateChange = () => {},
  isDropDown = false,
  selectedInstabookDate,
}) => {
  const { events, setActiveMonth, activeMonthRef } = useCalendarContext();
  const { selectedDate, setCalendarFocus, renderKey } = useCalendarFocus();
  const isInstabook = useRouteMatch(PATHS.INSTABOOK);

  const minDate = useMemo(() => subMonths(startOfMonth(todayDate()), MAX_PREVIOUS_MONTHS), []);
  const maxDate = useMemo(
    () => addMonths(
      startOfMonth(todayDate()),
      isInstabook ? MAX_NEXT_MONTHS_INSTABOOK_OWNER : MAX_NEXT_MONTHS,
    ),
    [isInstabook],
  );

  const flattenedEvents = useMemo(() => flattenMonths(events), [events]);
  const aggregatedEvents = useMemo(
    () => aggregateEvents(flattenedEvents),
    [flattenedEvents],
  );
  const instabookRoute = Boolean(isInstabook);
  const Dots = useCallback(
    (day: number, date: Date, isPast: boolean, isSelected: boolean, isToday: boolean) => {
      const dateKey = toDateString(date);
      const isUnavailable = dayHasEventType(dateKey, aggregatedEvents, UNAVAILABLE);
      return (
        <>
          {dayHasEventType(dateKey, aggregatedEvents, INSTABOOK) && (
            <Icon
              id="instabook-filled"
              size="xs"
              className={classNames(getClassNameFor(s, 'instabookStar', classNames({ isPast, instabookRoute })))}
            />
          )}
          {dayHasEventType(dateKey, aggregatedEvents, CONFLICTED)
            && dayHasOnlyUnavailableEvents(dateKey, aggregatedEvents)
             && (
               <Icon id="warning" size="xs" className={getClassNameFor(s, 'warning', classNames({ isPast, instabookRoute }))} />
             )}
          {dayHasEventType(dateKey, aggregatedEvents, PARTIALLY_UNAVAILABLE)
          && (
            <span className={getClassNameFor(s, 'partiallyUnavailable', classNames({ isPast }))} />
          )}
          <span className={getClassNameFor(s, 'dateNumber', classNames({ isUnavailable, isPast, isSelected, isToday }))}>{day}</span>
          {isUnavailable && (
            <span className={classNames(s.unavailable)} />
          )}
          <div className={s.bottom}>
            {dayHasEventType(dateKey, aggregatedEvents, RESERVATION) && (
              <span className={getClassNameFor(s, 'reservation', classNames({ isPast, instabookRoute }))} />
            )}
            {dayHasEventType(dateKey, aggregatedEvents, OFFER) && (
              <span className={getClassNameFor(s, 'offer', classNames({ isPast, instabookRoute }))} />
            )}
            {dayHasEventType(dateKey, aggregatedEvents, INQUIRY) && (
              <span className={getClassNameFor(s, 'bookingInquiry', classNames({ isPast, instabookRoute }))} />
            )}
          </div>
        </>
      );
    },
    [aggregatedEvents, instabookRoute],
  );

  const [selected, setSelected] = useState(() => newTZDate(selectedDate));
  const [selectedMonth, setSelectedMonth] = useState(() => (
    startOfMonth(newTZDate((isDropDown && activeMonthRef.current) || selectedDate))
  ));

  // When the MiniCal is used in the dropdown, we need to open it to the correct selected month.
  useEffect(() => {
    if (isDropDown && activeMonthRef.current !== selectedDate) {
      setSelectedMonth(newTZDate((isDropDown && activeMonthRef.current) || selectedDate));
    }
  }, [activeMonthRef, isDropDown, selectedDate]);

  const onChange = useCallback(
    (date: Date) => {
      setSelected(date);
      setSelectedMonth(startOfMonth(date));
      onSelectedDateChange();
      const multiInstabookIds = getMultiInstabookIdsForDate(toDateString(date), flattenedEvents);
      setCalendarFocus({ selectedDate: toDateString(date), multiInstabookIds });
    },
    [flattenedEvents, onSelectedDateChange, setCalendarFocus],
  );

  const onMonthChange = useCallback(
    (date: Date) => {
      setActiveMonth(toDateString(startOfMonth(date)));
    },
    [setActiveMonth],
  );

  const jumpToToday = () => {
    onSelectedDateChange();
    const startOfTodaysMonth = startOfMonth(todayDate());
    // Triggers the loading of data if required
    setActiveMonth(toDateString(startOfTodaysMonth));
    setSelected(todayDate());
    // Ensures the selected month is correct to prevent renders from reverting to the previous month
    setSelectedMonth(startOfTodaysMonth);
    setCalendarFocus({ selectedDate: toDateString(todayDate()) });
  };

  // Update the MiniCal when the select date is changed from elsewhere
  // - e.g. when an unavailable event gets created
  useEffect(() => {
    if (selectedDate) {
      const date = newTZDate(selectedDate);
      setSelected(date);
      setSelectedMonth(startOfMonth(date));
    }
  }, [selectedDate]);

  return (
    <div className={s.root}>
      <div>
        <SimpleCalendar
          key={`${selected.getTime()}-${renderKey}`}
          openToDate={selectedMonth}
          selected={selected}
          onChange={onChange}
          onMonthChange={onMonthChange}
          renderDayContents={Dots}
          minDate={minDate}
          maxDate={maxDate}
        />
        <Media
          queries={{
            large: MEDIA_QUERIES.DESKTOP,
          }}
        >
          {(matches) => (
            matches.large && (
              <div className={s.actionWrapper}>
                <Button
                  type="button"
                  classNameModifier="secondary"
                  onClick={jumpToToday}
                >
                  <Icon
                    id="arrow-forward"
                    size="m"
                  />
                  Today
                </Button>
                <Route
                  exact
                  path={PATHS.CALENDAR}
                >
                  <UnavailableEditor modalName="unavailableEditor" />
                </Route>
                <Route
                  exact
                  path={PATHS.INSTABOOK}
                >
                  <InstabookEditorWrapper selectedInstabookDate={selectedInstabookDate} />
                </Route>
              </div>
            )
          )}
        </Media>
      </div>
    </div>
  );
};

export default MiniCal;
