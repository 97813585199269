import React, { FC } from 'react';
import Tooltip from '../../../common/components/Tooltip';

const CaptainOptionInfo: FC = () => (
  <Tooltip>
    The options available here are determined by your listing page selections.
  </Tooltip>
);

export default CaptainOptionInfo;
