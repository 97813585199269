import React from 'react';

import type { ImmutableTrip } from 'src/types/trips/Trips';
import type { ImmutableOffer } from 'src/types/offer/Offer';
import { offerIsEmpty } from 'src/inbox/helpers';
import NotificationMessage from '../../../../common/components/NotificationMessage';
import UserBlock from '../UserBlock';
import s from './NewInquiryMessage.module.scss';

type NewInquiryMessageProps = {
  trip: ImmutableTrip;
  offer: ImmutableOffer;
};

const NewInquiryMessage: React.FC<NewInquiryMessageProps> = ({ trip, offer }) => {
  const renter = trip.get('renter');

  return (
    <>
      {!offerIsEmpty(offer) && (
        // TODO: can this be replaced by an OfferWarning?
        <NotificationMessage
          classNameModifier="alignTop largerFontSize largerBottomMargin notSendChanges"
          outdent={false}
        >
          Changes to your offer have not yet been sent. <br />
          Please select {'"Create Offer"'} to send your offer to this customer.
        </NotificationMessage>
      )}
      <UserBlock user={renter}>
        <p className={s.copy}>
          <strong className={s.name}>
            {renter.get('first_name')}
          </strong>
          {' '}
          sent you a booking inquiry.
          Please review the requested trip details and send an offer to respond.
        </p>
      </UserBlock>
    </>
  );
};

export default NewInquiryMessage;
