import { TIME_12 } from 'src/common/constants';
import { TRIP_STATES } from '../inbox/constants';

export const INQUIRY = 'booking_inquiry';
export const OFFER = 'offer';
export const RESERVATION = 'reservation';
export const UNAVAILABLE = 'unavailable';
export const PARTIALLY_UNAVAILABLE = 'partially_unavailable';
export const CONFLICTED = 'conflicted';
export const INSTABOOK = 'instabook';

export const EVENT_TYPES = [INQUIRY, OFFER, RESERVATION, UNAVAILABLE, INSTABOOK];

export const GA_EVENT_ACTION_PREFIX = {
  UNAVAILABLE: 'Unavailable Event -',
  INSTABOOK: 'Instabook Event -',
};
export const GA_EVENT_OPTIONS = {
  event_category: 'Calendar',
};

// These are equivalent but date-fns requires the latter format
export const CALENDAR_DATE_FORMAT = 'YYYY-MM-DD';
export const CALENDAR_DATE_FORMAT_DATE_FNS = 'yyyy-MM-dd';
export const DATE_FORMAT_FNS = 'EE MMM d, yyyy';

export const CALENDAR_MONTH_LONG = 'MMMM';
export const CALENDAR_MONTH_SHORT = 'MMM';
export const CALENDAR_YEAR_LONG = 'yyyy';
export const CALENDAR_TODAY_DATE_DIVIDER = 'MMM d, yyyy';
export const CALENDAR_DAY_NAME = 'EEE';
export const CALENDAR_DAY_NUMBER = 'd';
export const CALENDAR_REPEATS_UNTIL_DATE = 'MMM d';
export const CALENDAR_EVENT_DETAILS_DATE = 'EEE MMM d, yyyy';

export const MAX_PREVIOUS_MONTHS = 12;
export const MAX_NEXT_MONTHS = 6;

export const UNAVAILABLE_EDITOR_START_DATE = 'event_date';
export const UNAVAILABLE_EDITOR_END_DATE = 'event_end_date';
export const UNAVAILABLE_EDITOR_REPEAT_FREQUENCY = 'frequency';
export const UNAVAILABLE_EDITOR_REPEAT_COUNT = 'repeat_count';
export const UNAVAILABLE_EDITOR_REPEAT_END_DATE = 'repetitions_end_date';
export const UNAVAILABLE_EDITOR_SELECT_ALL_LISTINGS = 'all_boats';
export const UNAVAILABLE_EDITOR_LISTING_IDS = 'boat_ids';
export const UNAVAILABLE_EDITOR_START_TIME = 'event_start_time';
export const UNAVAILABLE_EDITOR_END_TIME = 'event_end_time';
export const UNAVAILABLE_EDITOR_ALL_DAY = 'all_day';

export const EVENT_DETAILS_MODAL_NAME = 'eventDetails';

// This lookup list is used to set the `event_type` based on the event's `state`.
export const STATE_TO_EVENT_TYPE_LOOKUP = {
  [TRIP_STATES.OWNER_DIRECT_INQUIRY]: INQUIRY,
  [TRIP_STATES.OWNER_INQUIRY_LAPSED]: INQUIRY,
  [TRIP_STATES.OWNER_MATCHING_INQUIRY]: INQUIRY,
  [TRIP_STATES.OWNER_OFFER_CANCELLED]: INQUIRY,
  [TRIP_STATES.OWNER_OFFER_AUTO_CANCELLED]: INQUIRY,

  [TRIP_STATES.OWNER_CHANGES_REQUESTED]: OFFER,
  [TRIP_STATES.OWNER_OFFER_EDITED]: OFFER,
  [TRIP_STATES.OWNER_OFFER_EXPIRED]: OFFER,
  [TRIP_STATES.OWNER_OFFER_SENT]: OFFER,

  [TRIP_STATES.OWNER_RESERVATION_CONFIRMED]: RESERVATION,
  [TRIP_STATES.OWNER_TRIP_COMPLETE_REVIEW_DONE]: RESERVATION,
  [TRIP_STATES.OWNER_TRIP_COMPLETE_REVIEW_PENDING]: RESERVATION,
  [TRIP_STATES.OWNER_TRIP_COMPLETE_REVIEW_WAITING]: RESERVATION,
};

export const INSTABOOK_EDITOR_MODAL_NAME = 'instabookEditor';
export const INSTABOOK_TRIP_SELECTOR_MODAL_NAME = 'instabookTripSelector';
export const INSTABOOK_DEPARTURE_TIMES_MODAL_NAME = 'instabookDepartureTimes';
export const INSTABOOK_TRIP_DATE_MODAL_NAME = 'instabookTripDate';
export const INSTABOOK_EDITOR_DATE_FIELD = 'date';
export const INSTABOOK_EDITOR_DATES_FIELD = 'dates';
export const INSTABOOK_EDITOR_DATE_CUSTOM_ERROR = 'date_error';

export const INSTABOOK_DEPARTURE_TIME_FLEXIBLE = 'instabook_departure_time_flexible';
export const INSTABOOK_FLEXIBLE_DEPARTURE_TIME_START = 'instabook_flexible_departure_time_start';
export const INSTABOOK_FLEXIBLE_DEPARTURE_TIME_END = 'instabook_flexible_departure_time_end';
export const INSTABOOK_FIXED_DEPARTURE_TIMES = 'instabook_fixed_departure_times';
export const INSTABOOK_EDITOR_TRIP_DURATION_FIELD = 'trip_duration';

export const INSTABOOK_EDITOR_DATE_ERROR = 'date_error';
export const INSTABOOK_FIXED_DEPARTURE_TIMES_ERROR = 'instabook_fixed_departure_times_error';
export const INSTABOOK_EDITOR_TRIP_DURATION_ERROR = 'trip_duration_error';

export const INSTABOOK_MIN_DURATION = 1;
export const INSTABOOK_MAX_DURATION = 23;

export const INSTABOOK_INITIAL_FLEXIBLE_DEPARTURE_TIME_START = '10:00';
export const INSTABOOK_INITIAL_FLEXIBLE_DEPARTURE_TIME_END = '16:00';

export const INSTABOOK_CAPTAINED_FIELD = 'captained';
export const INSTABOOK_CAPTAINED_ERROR = 'captained_error';
export const INSTABOOK_CAPTAIN_COST_INCLUDED_FIELD = 'captain_cost_included';

export const INSTABOOK_EDITOR_TRIP_TITLE_FIELD = 'trip_title';
export const INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_FIELD = 'additional_information';
export const INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_ERROR = 'additional_information_error';
export const INSTABOOK_EDITOR_ADDITIONAL_INFORMATION_LENGTH_ERROR = 'additional_information_length_error';

export const INSTABOOK_PRICE_FIELD = 'subtotal';
export const INSTABOOK_CAPTAIN_PRICE_FIELD = 'captain_subtotal';
export const INSTABOOK_PUBLISHED_FIELD = 'published';

export const INSTABOOK_TIME_FORMAT = TIME_12;

export const INSTABOOK_STATES = {
  NOT_BOOKABLE: 'NOT_BOOKABLE',
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
} as const;

export const INSTABOOK_WARNINGS = {
  UNPUBLISHED: 'UNPUBLISHED',
  PAST: 'PAST',
  DEPARTURE: 'DEPARTURE',
  UNAVAILABLE: 'UNAVAILABLE',
  UNAVAILABLE_DAY: 'UNAVAILABLE_DAY',
  UNAVAILABLE_TIMES: 'UNAVAILABLE_TIMES',
  PARTIALLY_UNAVAILABLE_TIMES: 'PARTIALLY_UNAVAILABLE_TIMES',
} as const;

export const INSTABOOK_INTRO_MODAL_FLAG_NAME = 'instabook_intro_shown';

export const OPTION_BLOCK_DAY = 'block-day';
export const OPTION_BLOCK_TIME = 'block-time';
export const OPTION_REMAINS_AVAILABLE = 'remains-available';

export const AVAILABLE_SETTINGS_MAP = {
  [OPTION_REMAINS_AVAILABLE]: 0,
  [OPTION_BLOCK_DAY]: 1,
  [OPTION_BLOCK_TIME]: 2,
} as const;
