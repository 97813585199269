import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { SUPPORT_URLS, EXTERNAL_PATHS } from '../../../../common/constants';
import CloseButton from '../../../../common/components/CloseButton';
import UniversalLink from '../../../../common/components/UniversalLink';
import { siblingRoute } from '../../../../common/utils/routing';
import { PAGES } from '../../../constants';
import { CTALink } from '../../../../inbox/components/CTA';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import s from './CommunicationWarning.module.scss';

const nextPage = PAGES.DONE;

const CommunicationWarning: FC = () => {
  const location = useLocation();
  const nextPath = siblingRoute(location.pathname, nextPage) + location.search;
  const returnUrl = getReturnUrl(location);

  return (
    <div className={s.container}>
      <Helmet>
        <title>
          Communication Warning
        </title>
      </Helmet>
      <h1 className={s.title}>
        Communicating through Getmyboat
      </h1>
      <div className={s.message}>
        <p>
          Communicating or paying outside Getmyboat can put you at risk of fraud
          {' and is against our '}
          <UniversalLink
            to={EXTERNAL_PATHS.TERMS}
            className={s.link}
          >
            Terms of Use
          </UniversalLink>.
          To prevent this, we remove text from messages that might include contact information.
          After a booking payment is made through Getmyboat you will be able to communicate via
          {' email and phone if you prefer. '}
          <UniversalLink
            to={SUPPORT_URLS.WHY_COMMUNICATE_AND_PAY_THROUGH_GETMYBOAT}
            className={s.link}
            openInNewTab
          >
            Find out more
          </UniversalLink>.
        </p>
      </div>

      <div className={s.buttons}>
        <CTALink to={nextPath}>
          Continue
        </CTALink>
      </div>
      <CloseButton backUrl={returnUrl} />
    </div>
  );
};

export default CommunicationWarning;
