import { useMemo } from 'react';

import { rffValidators } from '../validation';

export const testTimeInputSupport = () => {
  let testInput = null;
  if (typeof document !== 'undefined') {
    testInput = document.createElement('input');
    testInput.setAttribute('type', 'time');
  }
  // test whether a new time input falls back to a text input or not
  return testInput && testInput.type !== 'text';
};

const format = (value = '') => value.toUpperCase();

// https://www.regextester.com/104041
const twelveHoursFormatRegex = /((1[0-2]|0[1-9]):([0-5][0-9]) ([ap][m]))/i;

const useTimeInput = () => {
  const timeInputIsSupported = useMemo(() => testTimeInputSupport(), []);

  const validate = useMemo(() => (timeInputIsSupported
  // If it's supported we're going to trust the browser
  // to give us good input
    ? undefined
    : rffValidators.regex({
      message: 'Invalid time format.',
      regex: twelveHoursFormatRegex,
    })), [timeInputIsSupported]);

  return {
    format,
    validate,
    timeInputIsSupported,
  };
};

export default useTimeInput;
