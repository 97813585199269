import React, { FC } from 'react';
import { getClassNameFor } from 'src/common/helpers';
import styles from './UnavailableEditorSection.module.scss';

type Props = {
  title: string;
  description?: string;
  children?: React.ReactNode;
  classNameModifier?: string;
};
const UnavailableEditorSection:FC<Props> = ({
  title,
  description,
  children,
  classNameModifier = '',
}) => (
  <div className={getClassNameFor(styles, 'root', classNameModifier)}>
    <h4 className={styles.title}>{title}</h4>
    {description && <p className={styles.description}>{description}</p>}
    {children}
  </div>
);

export default UnavailableEditorSection;
