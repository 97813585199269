import { List } from 'immutable';
import { createSelector } from 'reselect';

import { BOAT_FIELD } from '../../../constants';
import { offerGetters, tripGetters } from '../../../selectors';

const boatsGetter = ({ user }) => user.get('boats', List());
const offerBoatGetters = [...offerGetters([BOAT_FIELD]), boatsGetter];
const tripBoatGetters = [...tripGetters([BOAT_FIELD]), boatsGetter];

const boatMunger = (maybeBoatMap, userBoats) => {
  const boatId = maybeBoatMap.get('id');
  // We're not using the passed-in value because that may come from the offer, which won't
  // have the entire boat object
  const boat = userBoats.find(userBoat => userBoat.get('id') === boatId);
  return {
    userBoats: userBoats
      // We don't want unpublished boats here because it won't let you patch an unpublished boat
      .filter(userBoat => userBoat.get('active', false))
      .filterNot(userBoat => boatId === userBoat.get('id'))
      .unshift(boat),
  };
};

export const offerBoatSelector = createSelector(offerBoatGetters, boatMunger);
export const tripBoatSelector = createSelector(tripBoatGetters, boatMunger);
