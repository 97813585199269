import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { getClassNameFor } from 'src/common/helpers';
import IconDS22, { IconIds } from '../IconDS22';
import styles from './ListingBadge.module.scss';

export const variants = ['motivator-high', 'motivator-limited', 'rating', 'superowner', 'bestOfYear', 'instabook'] as const;
type Variant = typeof variants[number];

const iconMap: Partial<Record<Variant, IconIds>> = {
  rating: 'star',
  superowner: 'medal',
  bestOfYear: 'trophy',
  instabook: 'instabook-filled',
};

export type ListingBadgeProps = {
  children: ReactNode;
  variant: Variant;
};

const ListingBadge: FC<ListingBadgeProps> = ({
  children,
  variant,
}) => {
  const isMotivator = variant === 'motivator-high' || variant === 'motivator-limited';
  const iconId = iconMap[variant];
  return (
    <div
      className={getClassNameFor(
        styles,
        'root',
        classNames(variant),
      )}
    >

      <div
        className={getClassNameFor(styles, 'icon', classNames(variant))}
      >
        {!isMotivator && iconId && (
          <IconDS22
            id={iconId}
            size="s"
          />
        )}
      </div>

      {children}
    </div>
  );
};

export default ListingBadge;
