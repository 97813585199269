import { CAPTAIN_OPTION_KEYS, LISTING_CAPTAIN_OPTION } from '../constants';

/**
 * Consider a listing's captain options configuration;
 *```
 *  ┌───────────────────────────┐ ┌─────────────────────────────┐ ┌──────────────────────┐
 *  │                           │ │                             │ │                      │
 *  │             X             │ │             Y               │ │          Z           │
 *  │                           │ │                             │ │                      │
 *  │ CAPTAIN PROVIDED IN PRICE │ │ CAPTAIN ARRANGED SEPARATELY │ │ CAPTAIN NOT PROVIDED │
 *  │                           │ │                             │ │                      │
 *  └───────────────────────────┘ └─────────────────────────────┘ └──────────────────────┘
 *
 *  ┌─────┬───┬───┬───┐
 *  │ KEY │ X │ Y │ Z │
 *  ├─────┼───┼───┼───┤
 *  │ 001 │ 0 │ 0 │ 1 │ -> Captain Not Provided
 *  │ 010 │ 0 │ 1 │ 0 │ -> Captain Arranged Separately
 *  │ 011 │ 0 │ 1 │ 1 │ -> Captain Arranged Separately and Captain Not Provided
 *  │ 100 │ 1 │ 0 │ 0 │ -> Captain Provided In Price
 *  │ 101 │ 1 │ 0 │ 1 │ -> Captain Provided In Price and Captain Not Provided
 *  │ 110 │ 1 │ 1 │ 0 │ -> Captain Provided In Price and Captain Arranged Separately
 *  │ 111 │ 1 │ 1 │ 1 │ -> Captain Provided In Price, Captain Arranged Separately
 *  |_____|___|___|___|    and Captain Not Provided
 * ```
 *
 * A listing can have the following options enabled:
 *
 * - CAPTAIN_PROVIDED_IN_PRICE
 * - CAPTAIN_ARRANGED_SEPARATELY
 * - CAPTAIN_NOT_PROVIDED
 *
 * At least 1 captain option has to be selected.
 *
 * The boolean permutations of the captain option can be represented using binary
 * and bitwise operations, where all the permutations are represented as `XYZ`
 *
 * - `X` the a bit representing the CAPTAIN_PROVIDED_IN_PRICE option.
 * - `Y` the a bit representing the CAPTAIN_ARRANGED_SEPARATELY option.
 * - `Z` the a bit representing the CAPTAIN_NOT_PROVIDED option.
 */

export type ListingCaptainOption = {
  id: number;
  key: string;
  heading: string;
  subHeading: string;
  shortLabel: string;
  icon: string;
};

export const providedInPrice: ListingCaptainOption = {
  id: LISTING_CAPTAIN_OPTION.PROVIDED_IN_PRICE,
  key: CAPTAIN_OPTION_KEYS.PROVIDED,
  heading: 'Captain Provided In Price',
  subHeading: 'Professional Captain provided and included in the price.',
  shortLabel: 'With Captain',
  icon: '#icon-tick',
};

export const arrangedSeparately: ListingCaptainOption = {
  id: LISTING_CAPTAIN_OPTION.ARRANGED_SEPARATELY,
  key: CAPTAIN_OPTION_KEYS.ARRANGED_SEPARATELY,
  heading: 'Independent Captain',
  subHeading: 'Professional Captain required, arranged and paid for separately through Getmyboat.',
  shortLabel: 'With Captain',
  icon: '#icon-optional',
};

export const notProvided: ListingCaptainOption = {
  id: LISTING_CAPTAIN_OPTION.NOT_PROVIDED,
  key: CAPTAIN_OPTION_KEYS.NOT_PROVIDED,
  heading: 'Captain Not Provided',
  subHeading: 'Customer provides or acts as the qualified operator.',
  shortLabel: 'Self-Captain',
  icon: '#icon-cross',
};

export const withCaptain: ListingCaptainOption = {
  id: LISTING_CAPTAIN_OPTION.WITH_CAPTAIN,
  key: CAPTAIN_OPTION_KEYS.PROVIDED,
  heading: 'With Captain',
  subHeading:
    'A captain is included in the price or otherwise arranged to host and operate the trip.',
  shortLabel: 'With Captain',
  icon: '#icon-tick',
};

const CaptainOptionLabelToBinaryLookup = {
  'Provided In Price': 2, // 10
  'Arranged Separately': 1, // 01
  Flexible: 3, // 11
  'Not Applicable': 0, // 00
} as const;

type CaptainOptionLabels = keyof typeof CaptainOptionLabelToBinaryLookup;

/**
 * ```
 *   ┌─────┬───┬───┬───┐
 *   │ KEY │ X │ Y │ Z │
 *   ├─────┼───┼───┼───┤
 *   │ 001 │ 0 │ 0 │ 1 │ -> 1 -> Captain Not Provided
 *   │ 010 │ 0 │ 1 │ 0 │ -> 2 -> Captain Arranged Separately
 *   │ 011 │ 0 │ 1 │ 1 │ -> 3 -> Captain Arranged Separately and Captain Not Provided
 *   │ 100 │ 1 │ 0 │ 0 │ -> 4 -> Captain Provided In Price
 *   │ 101 │ 1 │ 0 │ 1 │ -> 5 -> Captain Provided In Price and Captain Not Provided
 *   │ 110 │ 1 │ 1 │ 0 │ -> 6 -> Captain Provided In Price and Captain Arranged Separately
 *   │ 111 │ 1 │ 1 │ 1 │ -> 7 -> Captain Provided Price, Captain Arranged Separately
 *   |_____|___|___|___|          and Captain Not Provided
 * ```
 */

export enum CaptainOptionsEnum {
  CAPTAIN_OPTION_001 = 1,
  CAPTAIN_OPTION_010 = 2,
  CAPTAIN_OPTION_011 = 3,
  CAPTAIN_OPTION_100 = 4,
  CAPTAIN_OPTION_101 = 5,
  CAPTAIN_OPTION_110 = 6,
  CAPTAIN_OPTION_111 = 7,
}

export const getBinaryKeyForCaptainOption = (
  bareboat: boolean,
  captainOption: string,
): CaptainOptionsEnum => {
  const value = CaptainOptionLabelToBinaryLookup[captainOption as CaptainOptionLabels];
  return value * 2 + Number(bareboat);
};

const getListingCaptainOption = (bareboat: boolean, captainOption: string) => {
  const key = getBinaryKeyForCaptainOption(bareboat, captainOption);
  const lookupMap = {
    // '001'
    1: [notProvided],
    // '010'
    2: [arrangedSeparately],
    // '011'
    3: [arrangedSeparately, notProvided],
    // '100'
    4: [providedInPrice],
    // '101'
    5: [providedInPrice, notProvided],
    // '110'
    6: [providedInPrice, arrangedSeparately],
    // '111'
    7: [providedInPrice, arrangedSeparately, notProvided],
  };
  return lookupMap[key];
};

export default getListingCaptainOption;
