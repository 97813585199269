import React from 'react';
import { Field } from 'react-final-form';
import Input from 'src/common/components/inputs/Input';
import { useTranslation } from 'src/common/hooks';
import { combineValidators, rffValidators } from 'src/common/validation';
import Checkbox from 'src/common/components/fields/Checkbox';
import { FIELD_NAMES } from '../constants';

const zipCodeValidate = combineValidators([
  rffValidators.required({ message: 'Please provide a zip code.' }),
  rffValidators.usaZipCode({ message: 'Invalid zip code.' }),
]);
const addressValidate = rffValidators.required({ message: 'Please enter address.' });
const cityValidate = rffValidators.required({ message: 'Please enter city.' });
const stateValidate = rffValidators.required({ message: 'Please enter state.' });

const AddressFieldset: React.FC = () => {
  const { t } = useTranslation('insurance');

  return (
    <fieldset>
      <legend>
        {t('addressTitle')}
      </legend>
      <p>
        {t('addressDescription')}
      </p>
      <Field
        name={FIELD_NAMES.address1}
        render={(renderProps) => (
          <Input
            {...renderProps}
            type="text"
            label={t('addressLine1')}
          />
        )}
        validate={addressValidate}
      />
      <Field
        name={FIELD_NAMES.address2}
        validate={addressValidate}
        render={(renderProps) => (
          <Input
            {...renderProps}
            type="text"
            label={t('addressLine2')}
          />
        )}
      />
      <Field
        name={FIELD_NAMES.city}
        validate={cityValidate}
        render={(renderProps) => (
          <Input
            {...renderProps}
            type="text"
            label={t('city')}
          />
        )}
      />
      <Field
        name={FIELD_NAMES.zip_code}
        validate={zipCodeValidate}
        render={(renderProps) => (
          <Input
            {...renderProps}
            type="text"
            label={t('zipCode')}
          />
        )}
      />
      <Field
        name={FIELD_NAMES.state}
        validate={stateValidate}
        render={(renderProps) => (
          <Input
            {...renderProps}
            type="text"
            label={t('state')}
          />
        )}
      />

      <label>
        <Checkbox
          name={FIELD_NAMES.save_address}
        />
        {' '}
        {t('saveAddress')}
      </label>
    </fieldset>
  );
};

export default AddressFieldset;
