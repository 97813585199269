import React, { ReactNode, FC, Children } from 'react';
import { useCarousel } from 'src/common/utils/carouselProvider';
import s from './Carousel.module.scss';

export type CarouselProps = {
  children: ReactNode;
};
/**
 * This `Carousel` manages scrollable grid layout only.
 * It is a companion to `CarouselButtons`, which uses embla methods like `scrollNext()`.
 * Wrap both in a CarouselProvider and then position each independently.
 */
const Carousel: FC<CarouselProps> = ({ children }) => {
  const { emblaRef } = useCarousel();

  return (
    <div className={s.container}>
      <div
        className={s.viewport}
        ref={emblaRef}
      >
        <ul
          className={s.list}
        >
          {Children.map(children, (child) => (
            <li>
              {child}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Carousel;
