import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { fullName } from 'src/common/utils/user';
import { TITLE } from '../../../../common/constants';
import { getReviewUrl } from '../../../helpers';
import TripPanel from '../../presentation/TripPanel';
import CTA, { CTABackLink, CTALink } from '../../CTA';
import Card from '../../presentation/Card';
import { REVIEW_PAGES } from '../../pages/Complete/reviewsConfig';
import Category from '../Category';
import categories from './categories';
import s from '../ReviewStep.module.scss';

const Categories = ({
  trip,
  role,
  backLocation,
  values: { rating },
  progressBar,
}) => {
  const location = useLocation();
  const backUrl = getReviewUrl(REVIEW_PAGES.CATEGORIES, role, 'back', location);
  const nextUrl = getReviewUrl(REVIEW_PAGES.CATEGORIES, role, 'next', location);

  const owner = trip.get('owner');
  const ownerName = fullName(owner);

  const cta = rating && (
    <CTA classNameModifier="withSidebar">
      <CTABackLink to={backUrl} />
      <CTALink to={nextUrl}>
        Next
      </CTALink>
    </CTA>
  );

  return (
    <TripPanel
      trip={trip}
      subheader={TITLE.REVIEW}
      cta={cta}
      backLocation={backLocation}
    >
      <Card classNameModifier="review">
        {progressBar}
        <h4 className={s.title}>
          {rating > 2
            ? 'Please rate your trip in each of these categories.'
            : 'Sorry to hear that. Can you rate each part of your trip?'}
        </h4>
        <p className={s.subTitle}>
          The details you provide are private between you and {ownerName}.
        </p>
        {categories.map(category => (
          <Category
            key={category.name}
            category={category}
            ownerName={ownerName}
          />
        ))}
      </Card>
    </TripPanel>
  );
};

Categories.propTypes = {
  trip: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  backLocation: PropTypes.object.isRequired,
  values: PropTypes.object,
  progressBar: PropTypes.node,
};

export default Categories;
