import React, { FunctionComponent, FocusEvent, ChangeEvent } from 'react';
import { FieldValidator } from 'final-form';
import { Field } from 'react-final-form';

import TimeInput from '../../../common/components/inputs/TimeInput';
import { normalizeTime } from '../../../common/timeHelpers';

import s from './InstabookTime.module.scss';

const parseTime = (value: string) => (value === '' ? '00:00:00' : value);

type InstabookTimeProps = {
  label: string;
  name: string;
  subtitle?: string;
  validate?: FieldValidator<string>;
};

const InstabookTime: FunctionComponent<InstabookTimeProps> = ({
  label,
  name,
  subtitle,
  validate,
}) => (
  <label
    htmlFor={name}
    className={s.root}
  >
    {label}
    {subtitle && (<span className={s.subtitle}>{subtitle}</span>)}
    <Field
      autoFocus={false}
      classNameModifier="instabook"
      name={name}
      parse={parseTime}
      validate={validate}
      extraInputAttrs={{
        min: '00:00',
        max: '23:45',
        step: 900,
      }}
    >
      {({ input, ...restProps }) => (
        <TimeInput
          disablePopup
          classNameModifier="instabook"
          input={{
            ...input,
            onBlur: ({ target: { value } }: FocusEvent<HTMLInputElement>) => {
              const { formattedTime } = normalizeTime(value);
              input.onChange(formattedTime);
            },
            onChange: ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
              input.onChange(value);
            },
            required: true,
          }}
          {...restProps}
        />
      )}
    </Field>
  </label>
);

export default InstabookTime;
