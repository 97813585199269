import React, { FC } from 'react';

import { getClassNameFor } from '../../helpers';
import SupportEmail from '../SupportEmail';
import SupportPhoneNumber from '../SupportPhoneNumber';
import s from './ContactSupport.module.scss';

type ContactSupportProps = {
  classNameModifier: string;
};

const ContactSupport: FC<ContactSupportProps> = ({
  classNameModifier,
}) => (
  <div className={getClassNameFor(s, 'root', classNameModifier)}>
    <p className={getClassNameFor(s, 'text', classNameModifier)}>
      Please contact Getmyboat customer support if you need to make changes to this booking.
    </p>
    <ul className={s.contacts}>
      <li>
        <SupportPhoneNumber
          className={getClassNameFor(s, 'supportLink', classNameModifier)}
        />
      </li>
      <li>
        <SupportEmail
          className={getClassNameFor(s, 'supportLink', classNameModifier)}
        />
      </li>
    </ul>
  </div>
);

export default ContactSupport;
