import { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { stringify } from 'query-string';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';
import startOfMonth from 'date-fns/startOfMonth';
import differenceInMonths from 'date-fns/differenceInMonths';

import apiFetch from '../../core/fetch';
import { BookingMotivators, BookingMotivatorsByDate, DateString, Period } from '../types';
import { formatDate, newTZDate, todayDate } from '../utils/dateHelpers';
import usePrevious from './usePrevious';

const getBookingMotivatorsUrl = (
  boatId: string,
  startDate: DateString,
  period: Period = 'month',
) => `/boats/${boatId}/booking-motivators/?${stringify({
  period,
  start_date: startDate,
})}`;

const getBookingMotivators = async (
  boatId: string,
  startDate: DateString,
  period: Period,
): Promise<BookingMotivators[]> => {
  const url = getBookingMotivatorsUrl(boatId, startDate, period);
  const response = await apiFetch(url);
  if (!response.ok) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw response;
  }
  return response.json();
};

export const isWithinCalendarConstraints = (month: DateString): boolean => {
  const diff = differenceInMonths(startOfMonth(newTZDate(month)), startOfMonth(todayDate()));
  return diff >= 0;
};

const mergeBookingMotivators = (
  state: BookingMotivatorsByDate,
  newBookingMotivators: BookingMotivators[],
) => ({
  ...state,
  ...newBookingMotivators.reduce((
    bookingMotivatorsAcc: BookingMotivatorsByDate,
    bookingMotivator: BookingMotivators,
  ) => {
    // eslint-disable-next-line no-param-reassign
    bookingMotivatorsAcc[bookingMotivator.date] = bookingMotivator.motivators;
    return bookingMotivatorsAcc;
  }, {}),
});

const startMonth = startOfMonth(todayDate());

const useBookingMotivatorsData = (boatId?: string) => {
  const prevBoatId = usePrevious(boatId);
  const loadedMonths = useRef(new Set());
  const [activeMonth, setActiveMonth] = useState(formatDate(startMonth));

  const initialBookingMotivators = {};
  const [
    bookingMotivators,
    setBookingMotivators,
  ] = useState<BookingMotivatorsByDate>(initialBookingMotivators);

  const loadBookingMotivators = useCallback((startDate: string) => {
    if (loadedMonths.current.has(startDate) || !isWithinCalendarConstraints(startDate) || !boatId) {
      return;
    }
    loadedMonths.current.add(startDate);
    getBookingMotivators(boatId, startDate, 'month')
      .then(monthData => {
        setBookingMotivators(currentState => mergeBookingMotivators(currentState, monthData));
      });
  }, [boatId]);

  const loadInitialData = useCallback(async () => {
    await Promise.all(
      [0, 1, 2]
        .map(offset => formatDate(addMonths(startMonth, offset)))
        .map(loadBookingMotivators),
    );
  }, [loadBookingMotivators]);

  // Reset booking motivators on boat change
  useEffect(() => {
    if (boatId && boatId !== prevBoatId) {
      loadedMonths.current = new Set();
      setBookingMotivators({});
    }
  }, [boatId, prevBoatId]);

  // Load the first three month's of data on initial on mount.
  // Three months are loaded so we have the data for the next month preloaded.
  useEffect(
    () => {
      loadInitialData();
    },
    [loadInitialData],
  );

  // Preload the next month's data when the active month changes so it is
  // available when the user navigates to the next month.
  useEffect(
    () => {
      loadBookingMotivators(formatDate(addMonths(newTZDate(activeMonth), 2)));
    },
    [loadBookingMotivators, activeMonth],
  );

  const canGoBack = useMemo(
    () => isWithinCalendarConstraints(formatDate(subMonths(newTZDate(activeMonth), 1))),
    [activeMonth],
  );

  return {
    setActiveMonth,
    bookingMotivators,
    canGoBack,
  };
};

export default useBookingMotivatorsData;
