import React, { FC } from 'react';
import { List } from 'immutable';

import type { ImmutableConflictingTrip } from '../../../../types/trips/Trips';
import { plural } from '../../../../common/helpers';
import Dropdown from '../../../../common/components/Dropdown';
import ToggleButton from '../../../../common/components/ToggleButton';
import ConflictingTripsList from '../ConflictingTripsList';
import IconDS22 from '../../../../common/components/IconDS22';

import s from './BookingConflicts.module.scss';

type BookingConflictsProps = {
  conflictingTrips?: List<ImmutableConflictingTrip>;
};

const BookingConflicts: FC<BookingConflictsProps> = ({
  conflictingTrips = List(),
}) => {
  const openOffers = conflictingTrips
    .filter(conflictingTrip => conflictingTrip?.get('reservation_status') === 'pending');
  const otherBookings = conflictingTrips
    .filter(conflictingTrip => conflictingTrip?.get('reservation_status') === 'confirmed');

  const hasOtherBookings = !otherBookings.isEmpty();
  const hasOpenOffers = !openOffers.isEmpty();
  const hasOtherBookingsAndOffers = hasOtherBookings && hasOpenOffers;

  return !conflictingTrips.isEmpty() ? (
    <div className={s.root}>
      <div className={s.wrapper}>
        <IconDS22 id="info" size="m" />
        <Dropdown disableOnClickOutside>
          {({ toggle, isOpen }) => (
            <>
              You have
              {hasOtherBookings && ` ${otherBookings.size} other booking${plural(otherBookings.size)} `}
              {hasOtherBookingsAndOffers && 'and '}
              {hasOpenOffers && ` ${openOffers.size} offer${plural(openOffers.size)} `}
              during the same period as this booking.
              {' '}
              <ToggleButton
                isOpen={isOpen}
                onClick={toggle}
                classNameModifier="bookingConflicts"
              >
                {isOpen ? 'Hide' : 'Show'} details
              </ToggleButton>

              {isOpen && (
                <>
                  <ConflictingTripsList
                    conflictsList={openOffers}
                    title="OPEN OFFERS"
                  />
                  <ConflictingTripsList
                    conflictsList={otherBookings}
                    title="BOOKINGS"
                  />
                </>
              )}
            </>
          )}
        </Dropdown>
      </div>
    </div>
  ) : null;
};

export default BookingConflicts;
