/* eslint-disable import/prefer-default-export */

/**
 * Appends the provided suffix to the base value.
 *
 * @example
 * ```ts
 *   withSuffix('base', 'suffix') // 'base-suffix'
 *   withSuffix(123, 'suffix') // '123-suffix'
 *   withSuffix(undefined, 'suffix') // undefined
 *   withSuffix(null, 'suffix') // undefined
 * ```
 *
 * @param value Optional base value.
 * @param suffix Suffix to append.
 * @returns `undefined` if the base value is null or undefined,
 *   otherwise `string`.
 */
export function withSuffix<T extends string | number>(
  value: T | undefined,
  suffix: string,
): undefined | string {
  return value != null ? `${value}-${suffix}` : undefined;
}
