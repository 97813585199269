import React, { FC, MutableRefObject, ReactNode } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from 'src/common/helpers';
import Icon from 'src/common/components/IconDS22';
import s from './NotificationMessage.module.scss';

export type NotificationMessageProps = {
  children: ReactNode;
  withIcon?: boolean;
  outdent?: boolean;
  classNameModifier?: string;
  rootRef?: MutableRefObject<null | HTMLDivElement>;
  variant?: 'warning' | 'info';
};

const NotificationMessage: FC<NotificationMessageProps> = ({
  children,
  classNameModifier,
  outdent = true,
  withIcon = true,
  rootRef,
  variant = 'warning',
}) => {
  const modifiers = classNames(classNameModifier, variant, { outdent, withIcon });

  return (
    <div
      className={getClassNameFor(s, 'root', modifiers)}
      ref={rootRef}
    >
      {withIcon && (
        <Icon
          id={variant === 'warning' ? 'warning' : 'info'}
          className={getClassNameFor(s, 'icon', modifiers)}
        />
      )}
      {children && (
        <div data-test="warningMessage">
          {children}
        </div>
      )}
    </div>
  );
};

export default NotificationMessage;
