import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import type { ImmutableTrip } from '../../../../types/trips/Trips';
import Modal from '../../../../common/components/Modal';
import useCaptainData from '../../../../common/hooks/useCaptainData';
import { getOffer } from '../../../../common/utils/reduxStoreSelectors';
import CaptainForm from './form';

type CaptainModalProps = {
  isEditOffer?: boolean;
  modalName: string;
  trip: ImmutableTrip;
};

const CaptainModal: FC<CaptainModalProps> = ({
  isEditOffer = false,
  modalName,
  trip,
}) => {
  const offer = useSelector(getOffer);
  const {
    captainOptionHeading,
    selectedCaptainOption,
    listingCaptainOptions,
  } = useCaptainData(trip, offer, isEditOffer);

  return (
    <Modal
      name={modalName}
      label="Your comments and additional details"
      classNameModifier="captainOption"
    >
      <CaptainForm
        modalName={modalName}
        initialValues={{ captainOption: selectedCaptainOption || captainOptionHeading.key }}
        listingCaptainOptions={listingCaptainOptions}
      />
    </Modal>
  );
};

export default CaptainModal;
