import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { open, close } from '../../../common/ducks/zippy';
import { rffSubmitResponse } from '../../../common/helpers';
import Modal from '../../../common/components/Modal';

import { PAGES } from '../../constants';
import { getMessagesCall } from '../../ducks/messages';
import { CANCEL_INQUIRY } from '../TripMenu/actions';
import ClearButton from '../../../common/components/ClearButton';
import TripActionForm from '../forms/TripAction';
import UserBlock from '../presentation/UserBlock';
import { appendRoute, preserveSearch } from '../../../common/utils/routing';

const RenterCustomOfferExpiredMessage = ({ location, trip }) => {
  const tripId = trip.get('pk');
  const {
    call: cancelTripCall,
    name: modalName,
    title,
    longDescription: description,
    cancelText,
    submitText,
    withMessageField,
  } = CANCEL_INQUIRY;
  const dispatch = useDispatch();
  const openModal = useCallback(
    () => dispatch(open(modalName)),
    [dispatch, modalName],
  );
  const closeModal = useCallback(
    () => dispatch(close(modalName)),
    [dispatch, modalName],
  );
  const onSubmit = useCallback(
    values => dispatch(cancelTripCall(tripId, values))
      .then(() => {
        dispatch(getMessagesCall(tripId));
        closeModal();
      })
      .catch(rffSubmitResponse()),
    [cancelTripCall, closeModal, dispatch, tripId],
  );

  return (
    <UserBlock user={trip.get('owner')}>
      <strong>
        Your offer from {trip.getIn(['owner', 'first_name'])} expired because its expiry date has now passed.
      </strong>
      {' '}
      <Link
        to={preserveSearch(appendRoute(location.pathname, PAGES.MESSAGES), location)}
      >
        Send a message
      </Link>
      {' '}
      to request a new offer or
      {' '}
      <ClearButton
        classNameModifier="inline"
        onClick={openModal}
      >
        cancel your inquiry
      </ClearButton>
      {' '}
      if you are no longer interested.
      <Modal
        extraClassName="ignore-onclickoutside"
        label={title}
        name={modalName}
      >
        <TripActionForm
          closeBehavior={closeModal}
          content={{
            action: modalName,
            description,
            submitText,
            cancelText,
            title,
          }}
          fieldName="message"
          onSubmit={onSubmit}
          withMessageField={withMessageField}
        />
      </Modal>
    </UserBlock>
  );
};

RenterCustomOfferExpiredMessage.propTypes = {
  location: PropTypes.object.isRequired,
  trip: PropTypes.object.isRequired,
};

export default RenterCustomOfferExpiredMessage;
