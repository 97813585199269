import React, { FC } from 'react';
import Button from 'src/common/components/Button';
import { trackPurchaseEvent } from 'src/common/tracking';
import retrieveInitialLandingPage from 'src/common/utils/retrieveInitialLandingPage';

const handleClick = () => {
  const transactionId = Math.round(Math.random() * 1000000000000);
  const boatId = Math.round(Math.random() * 10000000).toString();
  const value = Math.random() * 10000 + 200;
  const bookingTotal = Math.round(value * 100) / 100;
  const initialLandingPage = retrieveInitialLandingPage();

  trackPurchaseEvent({
    transactionId,
    bookingTotal,
    currency: 'USD',
    locale: 'en',
    boatId,
    boatHeadline: 'Trip Title Here',
    initialLandingPage,
  });
};

/**
 * Trigger purchase event for testing. Only displays in dev mode
 */
const PurchaseEventTestButton: FC = () => {
  if (__DEV__) {
    return (
      <div style={{ position: 'absolute', top: 100, left: 20, zIndex: 2 }}>
        <Button
          type="button"
          classNameModifier="small secondary"
          onClick={handleClick}
        >
          Send test purchase event
        </Button>
      </div>
    );
  }
  return null;
};

export default PurchaseEventTestButton;
