import { JSONLD, Generic } from 'react-structured-data';
import React, { FC } from 'react';

import {
  AVERAGE_RATING,
  NUMBER_OF_REVIEWS,
  SUPPORT_EMAIL,
  SUPPORT_PHONE_NUMBER,
} from '../../../constants';

// TODO: Construct absolute URLs w/ correct TLD here!!!
const orgSchema = {
  name: 'Getmyboat',
  logo: 'https://www.getmyboat.com/static/img/getmyboat-email-logo.png',
  url: 'https://www.getmyboat.com',
  sameAs: [
    'https://www.facebook.com/getmyboat/',
    'https://twitter.com/getmyboat',
    'https://www.instagram.com/getmyboat/',
    'https://www.pinterest.com/getmyboat',
    'https://www.linkedin.com/company/getmyboat/',
    'https://www.youtube.com/@getmyboatofficial',
  ],
};

type SchemaProps = {
  withAggregateRating?: boolean;
};

const Schema: FC<SchemaProps> = ({ withAggregateRating = true }) => (
  <JSONLD dangerouslyExposeHtml>
    <Generic type="organization" jsonldtype="Organization" schema={orgSchema}>
      <Generic
        type="contactPoint"
        jsonldtype="ContactPoint"
        schema={{
          telephone: SUPPORT_PHONE_NUMBER,
          email: SUPPORT_EMAIL,
          contactType: 'Customer service',
        }}
      />
      {withAggregateRating && (
        <Generic
          type="aggregateRating"
          jsonldtype="AggregateRating"
          schema={{
            ratingValue: AVERAGE_RATING.toString(),
            reviewCount: NUMBER_OF_REVIEWS.toString(),
          }}
        />
      )}
    </Generic>
  </JSONLD>
);

export default Schema;
