import React from 'react';

import { getClassNameFor } from '../../helpers';
import s from './Quote.module.scss';

type QuotePropsType = {
  name: string;
  children: React.ReactNode;
  classNameModifier?: string;
};

const Quote: React.FC<QuotePropsType> = ({
  name,
  children,
  classNameModifier,
}) => (
  <blockquote className={getClassNameFor(s, 'root', classNameModifier)}>
    <p>
      {children}
    </p>
    {name && `- ${name}`}
  </blockquote>
);

export default Quote;
