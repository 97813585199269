import React, { FC } from 'react';
import { FieldInputProps } from 'react-final-form';

import CheckableInput from '../../CheckableInput';

export type Props = {
  input: Partial<FieldInputProps<string, HTMLInputElement>>;
  indeterminate?: boolean;
};

const Checkbox: FC<Props> = ({ input, indeterminate }) => (
  <CheckableInput
    // If CheckboxInput is rendered by a Field, it has `input.type` field
    // otherwise we set type prop manually
    type="checkbox"
    {...input}
    indeterminate={indeterminate}
  />
);

export default Checkbox;
