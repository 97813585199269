import React, { FC } from 'react';

import type { Captain } from 'src/types/boat/LinkedCaptains';
import Avatar from 'src/common/components/Avatar';
import Icon, { IconIds } from 'src/common/components/IconDS22';
import UniversalLink from 'src/common/components/UniversalLink';
import s from './CtaWidget.module.scss';

type CtaWidgetProps = {
  iconId?: IconIds;
  title: string;
  description: React.ReactNode;
  href?: string;
  onClick?: () => void;
  user?: Captain | null;
  withEditIcon?: boolean;
};

const CtaWidget: FC<CtaWidgetProps> = ({
  iconId,
  title,
  description,
  href,
  onClick = () => {},
  user,
  withEditIcon,
}) => {
  const Content = (
    <>
      {iconId && !user && (
        <Icon
          className={s.icon}
          id={iconId}
          size="xl"
        />
      )}
      {user && !iconId && (
        <Avatar
          classNameModifier="noMargin"
          size="medium"
          user={user}
        />
      )}
      <hgroup className={s.description}>
        <h6 className={s.title}>
          {title}
        </h6>
        <p className={s.description}>
          {description}
        </p>
      </hgroup>
      {withEditIcon && (
        <Icon
          className={s.editIcon}
          id="note"
          size="l"
        />
      )}
    </>
  );

  return (href ? (
    <UniversalLink
      to={href}
      className={s.root}
    >
      {Content}
    </UniversalLink>
  ) : (
    <button
      className={s.root}
      onClick={onClick}
      type="button"
    >
      {Content}
    </button>
  ));
};

export default CtaWidget;
