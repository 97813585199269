import React, { FC } from 'react';
import RadioButton from '../../../common/components/inputs/RadioButton';
import { noop } from '../../../common/helpers';

import s from './InstabookCaptainOption.module.scss';

type CaptainOptionItemProps = {
  heading: string;
  description?: string;
  onClick: () => void;
  checked: boolean;
};

const CaptainOptionItem: FC<CaptainOptionItemProps> = ({
  heading,
  description,
  onClick,
  checked,
}) => (
  <button
    type="button"
    className={s.option}
    onClick={onClick}
  >
    <RadioButton
      input={{
        checked,
        tabIndex: -1,
        // Noop onChange handler added to prevent React warnings as the state
        // change is done using the wrapping button.
        onChange: noop,
      }}
    />
    <div>
      <span className={s.heading}>
        {heading}
      </span>
      {description && (
        <span className={s.description}>
          {description}
        </span>
      )}
    </div>
  </button>
);

export default CaptainOptionItem;
