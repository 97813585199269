import React, { FC } from 'react';
import newIcons from './icons.svg';

export const sizes = {
  xs: 12,
  s: 16,
  m: 20,
  l: 24,
  xl: 32,
  xxl: 48,
} as const;

type Sizes = keyof typeof sizes;

export const iconIds = [
  'archive-box',
  'arrow-left',
  'calendar',
  'calendar-day',
  'calendar-events',
  'calendar-open',
  'captain',
  'captain-plus',
  'caret-down',
  'caret-up',
  'caret-left',
  'caret-right',
  'check-circle',
  'chevron-back',
  'credit-card-arrow',
  'credit-card-charge',
  'credit-card-check',
  'dots-three',
  'duration',
  'eye',
  'eye-slash',
  'gear',
  'heart-filled',
  'heart',
  'info',
  'info-filled',
  'instabook',
  'instabook-filled',
  'link',
  'listings',
  'magnifying-glass',
  'medal',
  'menu',
  'minus-circle',
  'mountain',
  'moon',
  'note',
  'note-cancel',
  'admin',
  'paper-plane',
  'payment',
  'pencil',
  'plus',
  'plus-circle',
  'price',
  'share-network',
  'sail-boat',
  'star',
  'trash',
  'trophy',
  'boat-add',
  'person',
  'profile',
  'login',
  'logout',
  'envelope',
  'boat',
  'qr-code',
  'question',
  'facebook',
  'twitter',
  'generic-link',
  'warning',
  'warning-filled',
  'clock',
  'x',
  'x-circle',
  'arrow-forward',
  'trip-details',
  'chat',
] as const;

export type IconIds = typeof iconIds[number];

export type IconProps = {
  id: IconIds;
  className?: string;
  /**
   * Descriptive text that can be used by screen readers
   */
  alt?: string;
  size?: Sizes;
};

const Icon: FC<IconProps> = ({ id, className, size = 'm', alt }) => {
  const widthHeight = sizes[size];

  return (
    <svg
      className={className}
      width={widthHeight}
      height={widthHeight}
      viewBox="0 0 32 32"
    >
      {alt && <title>{alt}</title>}
      <use
        xlinkHref={`${newIcons}#${id}`}
        aria-hidden="true"
      />
    </svg>
  );
};

export default Icon;
