import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { formatDate } from 'src/common/utils/dateHelpers';
import { CALENDAR_DATE_FORMAT_DATE_FNS } from '../constants';
import { focusCalendar as focusCalendarAction } from '../ducks/calendar';
import { todayDate } from '../helpers';
import { ReduxState } from '../../types/reduxState';

type CalendarFocus = {
  selectedDate: string;
  focusedThreadId?: number;
  focusedAdHocEventId?: number;
  focusedInstabookId?: number;
  scrollToEvent?: boolean;
  renderKey?: boolean;
  multiInstabookIds?: number[];
};

type CalendarFocusOptions = Partial<CalendarFocus>;

const useCalendarFocus = () => {
  const {
    selectedDate,
    focusedThreadId,
    focusedAdHocEventId,
    focusedInstabookId,
    renderKey,
    scrollToEvent,
    multiInstabookIds,
  } = useSelector<ReduxState, CalendarFocus>(({ calendar }) => ({
    selectedDate: calendar?.get('selectedDate') || formatDate(todayDate(), CALENDAR_DATE_FORMAT_DATE_FNS),
    focusedThreadId: calendar?.get('focusedThreadId'),
    focusedAdHocEventId: calendar?.get('focusedAdHocEventId'),
    focusedInstabookId: calendar?.get('focusedInstabookId'),
    renderKey: calendar?.get('renderKey'),
    scrollToEvent: calendar?.get('scrollToEvent'),
    multiInstabookIds: calendar?.get('multiInstabookIds'),
  }));

  const dispatch = useDispatch();

  const setCalendarFocus = useCallback(
    (options: CalendarFocusOptions) => dispatch(focusCalendarAction(options)),
    [dispatch],
  );

  return {
    selectedDate,
    focusedThreadId,
    focusedAdHocEventId,
    focusedInstabookId,
    renderKey,
    setCalendarFocus,
    scrollToEvent,
    multiInstabookIds,
  };
};

export default useCalendarFocus;
