import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../helpers';
import { useClientRect } from '../../hooks';
import Dropdown from '../Dropdown';
import Svg from '../Svg';
import s from './Tooltip.module.scss';

const getScrollableParent = (node: HTMLElement) => {
  let style = getComputedStyle(node);
  const excludeStaticParent = style.position === 'absolute';
  const overflowRegex = /(auto|scroll)/;

  if (style.position === 'fixed') {
    return document.body;
  }
  // eslint-disable-next-line no-cond-assign
  for (let parent: HTMLElement | null = node; (parent = parent.parentElement);) {
    style = getComputedStyle(parent);
    if (excludeStaticParent && style.position === 'static') {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
      return parent;
    }
  }

  return document.body;
};

type TooltipCTAProps = {
  className: string;
  // Linting is disabled for these props because eslint incorrectly reports them as unused
  // eslint-disable-next-line react/no-unused-prop-types
  isOpen: boolean; open: () => void; close: () => void;
  toggle: () => void;
  tooltipCTARef?: React.LegacyRef<HTMLButtonElement>;
};

export const DefaultCTA: FC<TooltipCTAProps> = ({
  children, className, toggle, tooltipCTARef,
}) => (
  <button
    type="button"
    className={className}
    onClick={toggle}
    ref={tooltipCTARef}
  >
    {children || (
      <Svg
        href="#more-info"
        className={s.icon}
      />
    )}
  </button>
);

export type TooltipProps = {
  renderCTA?: FC<TooltipCTAProps>;
  classNameModifier?: string;
  onToggle?: () => void;
};

const Tooltip: FC<TooltipProps> = ({
  renderCTA: CTA = DefaultCTA,
  children,
  classNameModifier,
  onToggle,
}) => {
  const { rect, ref } = useClientRect<HTMLDivElement>();
  const [leftOffset, setLeftOffset] = useState<number | undefined>();
  const [scrollableContainer, setScrollableContainer] = useState(document.documentElement);
  const [directionModifier, setDirectionModifier] = useState('toBottom');
  const ctaElementRef = useRef<HTMLButtonElement>(null);

  useEffect(
    () => {
      const { left, right, top, bottom } = scrollableContainer.getBoundingClientRect();
      const extraLeftOffset = 15;
      const extraBottomOffset = 50;
      const isBoundaryScrollableContainer = ['HTML', 'BODY'].includes(scrollableContainer.tagName);
      const bottomEdge = isBoundaryScrollableContainer ? window.innerHeight : bottom;
      const topEdge = isBoundaryScrollableContainer ? 0 : top;

      // Reposition the tooltip within viewport
      // if tooltip content exceed the right edge of viewport
      if (rect.right != null && rect.right > right) {
        setLeftOffset(right - rect.right - extraLeftOffset);
        // if tooltip content exceed the left edge of viewport
      } else if (rect.left != null && rect.left < left) {
        setLeftOffset(left - rect.left + extraLeftOffset);
      }
      // if tooltip content exceed the bottom edge of viewport
      if (rect.bottom != null && rect.bottom + extraBottomOffset > bottomEdge) {
        setDirectionModifier('toTop');
        // if tooltip content exceed the top edge of viewport
      } else if (rect.top != null && rect.top < topEdge) {
        setDirectionModifier('toBottom');
      }
    },
    [rect, scrollableContainer],
  );

  useEffect(
    () => {
      // the first two nodes are intentionally, because the button and root
      // node are not the nodes we are looking for.
      const getTooltipsParent = (): HTMLElement => (
        ctaElementRef?.current?.parentNode?.parentNode as HTMLElement
      );
      const handleResize = () => {
        setLeftOffset(undefined);
        setScrollableContainer(getScrollableParent(getTooltipsParent()));
      };

      setScrollableContainer(getScrollableParent(getTooltipsParent()));
      // Listen to window.resize to reset the offset to prevent
      // incorrect positioning of the tooltip content on resize
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    },
    [],
  );
  const contentModifier = classNames(classNameModifier, directionModifier);

  return (
    <Dropdown
      className={getClassNameFor(s, 'root', `${classNameModifier}`)}
      onToggle={onToggle}
    >
      {({ open, close, toggle, isOpen }) => (
        <>
          <CTA
            className={getClassNameFor(s, 'button', classNames(classNameModifier, directionModifier, { open: isOpen }))}
            isOpen={isOpen}
            open={open}
            close={close}
            toggle={toggle}
            tooltipCTARef={ctaElementRef}
          />
          {isOpen && (
            <div
              ref={ref}
              className={getClassNameFor(s, 'content', contentModifier)}
              style={{
                left: leftOffset,
              }}
            >
              {children}
            </div>
          )}
        </>
      )}
    </Dropdown>
  );
};

export default Tooltip;
