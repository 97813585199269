export type TimestampMessage<T = unknown> = {
  /**
   * A nanosecond precision timestamp represented as a string.
   */
  timestamp: string;
  message: T;
};

export type PubSubMessageType = 'close' | 'message';

/**
 * Message format used for sharing messages between
 * the socket worker, worker host and other tabs.
 */
export type PubSubMessage<T = unknown> = {
  data: TimestampMessage<T>;
  type: PubSubMessageType;
};

export type PubSubTypingSocketPayload = {
  type: 'typing';
  threadId: string;
};

export type PubSubSeenMessageSocketPayload = {
  type: 'seen';
  ahoyId: string;
  ahoyMsgId: string;
  threadId?: string;
  messageId?: string;
};

export type PubSubPresenceSocketPayload = {
  type: 'presence';
  threadId?: string;
};

export enum NotificationSoundTypes {
  Message = 'Message',
}

export type UnseenNotification = {
  ahoyId: string;
  ahoyMsgId: string;
};
