import React, { FC } from 'react';
import { useField } from 'react-final-form';

import { INSTABOOK_PUBLISHED_FIELD } from '../../constants';
import NotificationMessage from '../../../common/components/NotificationMessage';
import InstabookDraftPublishLabel from './Label';
import s from './InstabookDraftPublish.module.scss';

type Props = {
  disabled: boolean;
};

const InstabookDraftPublish: FC<Props> = ({ disabled }) => {
  const { input: { value: published } } = useField(INSTABOOK_PUBLISHED_FIELD);

  return (
    <div className={s.root}>
      <InstabookDraftPublishLabel
        checked={!published}
        disabled={disabled}
        description="Save your work in progress, or hide a previously published trip by saving it as draft."
        title="Draft"
        value={false}
      />
      <InstabookDraftPublishLabel
        checked={published}
        disabled={disabled}
        description="Publish this trip to make it available on your listing for instant booking by renters."
        title="Publish"
        value
      />
      {published && (
        <NotificationMessage
          classNameModifier="instabook gray alignTop"
          outdent={false}
        >
          Please only publish instabook trips that you are absolutely certain you can accommodate.
        </NotificationMessage>
      )}
    </div>
  );
};

export default InstabookDraftPublish;
