/**
 * Creates a git sha-like string
 * @returns {string}
 */
const makeFakeSha = () => Array.from(
  { length: 40 },
  () => Math.floor(Math.random() * 16).toString(16),
).join('');

export default makeFakeSha;
