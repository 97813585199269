import { groupBy } from 'lodash';
import { UNAVAILABLE } from '../constants';
import { CalendarEvent, GenericEvent, InstabookEvent, UnavailableEvent } from '../types';

export const isCalendarEvent = (x: any): x is CalendarEvent => (
  typeof x.thread_id === 'number'
  && typeof x.event_date === 'string'
);

export const isUnavailableEvent = (x: any): x is UnavailableEvent => (
  typeof x.adhoc_calendar_event_id === 'number'
  && x.event_type === UNAVAILABLE
);

export const isInstabookEvent = (x: any): x is InstabookEvent => (
  Array.isArray(x.instabook_trip_times)
);

export const isMultiInstabookEvent = (x: GenericEvent): x is InstabookEvent => (
  isInstabookEvent(x) && Array.isArray(x.instabook_trip_dates) && x.instabook_trip_dates.length > 1
);

const eventHasBoatDetails = (x: any): x is { boat: { id: string } } => (
  typeof x?.boat?.id === 'string'
);

/**
 * Returns the date that the calendar or instabook event is on.
 */
export const getDate = (x: GenericEvent) => (isInstabookEvent(x) ? x.date : x.event_date);

/**
 * Sorts by date in ascending order.
 */
export const sortByDate = (a: GenericEvent, b: GenericEvent) => (getDate(a) > getDate(b) ? 1 : -1);

/**
 * Returns an iteratee for use with groupBy.
 */
const startOfMonthIteratee = (event: GenericEvent) => getDate(event)?.replace(/\d\d$/, '01');

/**
 * Groups all events by month.
 */
export const groupByMonth = <T extends GenericEvent>(events: T[]): Record<string, T[]> => (
  groupBy(events, startOfMonthIteratee)
);

/**
 * Groups all events by day.
 */
export const groupByDay = <T extends GenericEvent>(events: T[]): Record<string, T[]> => (
  groupBy(events, getDate)
);

/**
 * Sorts all Instabook events by first event start time.
 */
export const sortInstabookEventsByTime = (events: InstabookEvent[]) => events.sort(
  (a, b) => {
    // Drafts may be missing a start time. Sort them to the top
    const aStartTime = a.instabook_trip_times[0]?.min_start_time ?? '';
    const bStartTime = b.instabook_trip_times[0]?.min_start_time ?? '';

    return aStartTime.localeCompare(bStartTime);
  },
);

export const getBoatId = (x: GenericEvent) => eventHasBoatDetails(x) && x.boat.id;

export const groupByBoat = <T extends GenericEvent>(events: T[]): Record<string, T[]> => (
  groupBy(events, getBoatId)
);
