import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';

import MFARecoveryForm from '../../form/MFARecovery';
import { authRedirect, nextUrl } from '../../../../common/utils/routing';
import ExternalLink from '../../../../common/components/ExternalLink';

import s from './MFARecovery.module.scss';

const title = 'Recovery code';

const MFARecovery: FC = () => {
  const { search, hash } = useLocation();
  const { push } = useHistory();
  const nextPage = useCallback(
    () => {
      authRedirect(nextUrl(search, hash), push);
    },
    [hash, push, search],
  );
  const onSubmitSuccess = () => nextPage();
  return (
    <div className={s.container}>
      <Helmet>
        <title lang="en">{title}</title>
      </Helmet>
      <h1 className={s.title}>{title}</h1>
      <p className={s.message}>
        If you are unable to access your mobile
        device, enter one of your recovery codes
        to verify your identity.
      </p>
      <MFARecoveryForm onSubmitSuccess={onSubmitSuccess} />
      <p className={s.footerLink}>
        Lost your recovery code?
        <br />
        Please
        {' '}
        <ExternalLink to="https://getmyboat.zendesk.com/hc/en-us/requests/new">
          contact Getmyboat support
        </ExternalLink>
      </p>
    </div>
  );
};

export default MFARecovery;
