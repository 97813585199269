import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_CURRENCY } from 'src/common/currencies';
import { getCurrencies } from 'src/common/utils/reduxStoreSelectors';
import FlagIcon from 'src/common/components/FlagIcon';
import { formatCurrencyLabel } from 'src/common/utils/currency';
import type { Currency } from 'src/types/common/Currencies';
import DropdownDS22, { DropdownOption } from '../DropdownDS22';
import styles from './CurrencyDropdown.module.scss';

const itemRenderer = (item: DropdownOption) => (
  <>
    <FlagIcon
      id={item?.id || ''}
      className={styles.flag}
    />
    <span className={styles.currencyText}>{item?.selectedLabel}</span>
  </>
);

export type Props = {
  id? : string;
  onChange: (item: DropdownOption) => void;
  selectedCurrency?: Currency;
};

const CurrencyDropdown: FC<Props> = ({
  id = 'currency-dropdown',
  onChange,
  selectedCurrency = DEFAULT_CURRENCY,
}) => {
  const currencies = useSelector(getCurrencies)?.payin_currencies;
  const defaultCurrencyLabel = formatCurrencyLabel(selectedCurrency);
  const currencyOptions: DropdownOption[] = useMemo(
    () => currencies?.map((currency) => {
      const currencyLabel = formatCurrencyLabel(currency);
      return {
        id: currency.code.slice(0, 2),
        selectedLabel: currencyLabel,
        dropdownLabel: currencyLabel,
        option: {
          code: currency.code,
        },
      };
    }),
    [currencies],
  );

  return (
    <DropdownDS22
      id={id}
      options={currencyOptions}
      onChange={onChange}
      selectedItemRenderer={itemRenderer}
      defaultOption={{
        id: selectedCurrency?.code?.slice(0, 2),
        selectedLabel: defaultCurrencyLabel,
        dropdownLabel: defaultCurrencyLabel,
        option: {
          code: selectedCurrency?.code,
        },
      }}
      variant="white"
      fullWidth
      scrollable
    />
  );
};

export default CurrencyDropdown;
