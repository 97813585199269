import { useSelector } from 'react-redux';
import { Locale } from 'src/types/common/Locale';
import { ReduxState } from '../../types/reduxState';

const getLocale = (state: ReduxState): Locale => state.locale;

function useLocale(): Locale {
  const locale = useSelector(getLocale);
  return locale;
}

export default useLocale;
