import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { getClassNameFor } from '../../helpers';
import Svg from '../Svg';
import Dropdown from '../Dropdown';
import s from './NavDropdown.module.scss';

type NavDropdownProps = {
  children: ReactNode;
  className: string;
  classNameModifier?: string;
  heading: ReactNode;
  'aria-label': string;
  'data-test': string;
  withChevron?: boolean;
};

const NavDropdown: FC<NavDropdownProps> = ({
  children,
  className,
  classNameModifier = '',
  heading,
  'aria-label': ariaLabel,
  'data-test': dataTest,
  withChevron = true,
}) => (
  <Dropdown className={className}>
    {({ toggle, isOpen }) => (
      <>
        <button
          onClick={toggle}
          type="button"
          className={getClassNameFor(s, 'button', classNames({ open: isOpen }))}
          aria-label={ariaLabel}
          data-test={dataTest}
        >
          {heading}
          {withChevron && (
            <Svg
              href="#down-arrow"
              className={s.arrow}
            />
          )}
        </button>
        {isOpen && (
          <ul className={getClassNameFor(s, 'content', classNameModifier)}>
            {children}
          </ul>
        )}
      </>
    )}
  </Dropdown>
);

export default NavDropdown;
