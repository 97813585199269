import React, { FC } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { type Duration, type MomentInput } from 'moment';
import { formatDate } from 'src/common/utils/dateHelpers';
import { durationFormat, getClassNameFor, getReturnDate } from '../../../common/helpers';
import { siblingRoute, preserveSearch } from '../../../common/utils/routing';
import { PAGES } from '../../constants';
import s from './DurationReturn.module.scss';

type DurationReturnProps = {
  classNameModifier?: string;
  date?: MomentInput;
  tripLength?: Duration;
  isNight?: boolean;
};

const DurationReturn: FC<DurationReturnProps> = ({
  classNameModifier = '',
  date,
  tripLength,
  isNight = false,
}) => {
  const location = useLocation();
  const returnDate = getReturnDate(date, tripLength, { isNight });

  return (
    <dl className={getClassNameFor(s, 'root', classNameModifier)}>
      <div className={getClassNameFor(s, 'row', classNames('duration', classNameModifier))}>
        <dt>
          {'Duration: '}
        </dt>
        <dd>
          {tripLength
            ? durationFormat(tripLength, isNight)
            : (
              <Link
                to={preserveSearch(siblingRoute(location.pathname, PAGES.DURATION), location)}
                className={s.link}
              >
                Set Duration
              </Link>
            )}
        </dd>
      </div>
      <div className={getClassNameFor(s, 'row', classNames('return', classNameModifier))}>
        <dt>
          {'Return: '}
        </dt>
        <dd>
          {returnDate ? formatDate(returnDate, 'PP', {}) : ''}
        </dd>
      </div>
    </dl>
  );
};

export default DurationReturn;
