import React from 'react';
import PropTypes from 'prop-types';

import ReviewCategory from '../Category';
import { FIELDS } from '../../../pages/Complete/reviewsConfig';

const {
  LISTING_ACCURACY,
  DEPARTURE_AND_RETURN,
  VESSEL_AND_EQUIPMENT,
  COMMUNICATION,
  VALUE,
  ITINERARY_AND_EXPERIENCE,
} = FIELDS;

const ReviewCategories = ({
  review,
}) => {
  const categories = [
    {
      title: 'Listing Accuracy',
      rating: review.get(LISTING_ACCURACY),
      comment: review.get(`${LISTING_ACCURACY}_comment`),
    },
    {
      title: 'Departure & Return',
      rating: review.get(DEPARTURE_AND_RETURN),
      comment: review.get(`${DEPARTURE_AND_RETURN}_comment`),
    },
    {
      title: 'Vessel & Equipment',
      rating: review.get(VESSEL_AND_EQUIPMENT),
      comment: review.get(`${VESSEL_AND_EQUIPMENT}_comment`),
    },
    {
      title: 'Communication',
      rating: review.get(COMMUNICATION),
      comment: review.get(`${COMMUNICATION}_comment`),
    },
    {
      title: 'Value',
      rating: review.get(VALUE),
      comment: review.get(`${VALUE}_comment`),
    },
    {
      title: 'Itinerary & Experience',
      rating: review.get(ITINERARY_AND_EXPERIENCE),
      comment: review.get(`${ITINERARY_AND_EXPERIENCE}_comment`),
    },
  ];

  return categories.map(({ title, rating, comment }) => (rating || comment) && (
    <ReviewCategory
      key={title}
      title={title}
      rating={rating}
      comment={comment}
    />
  ));
};

ReviewCategories.propTypes = {
  review: PropTypes.object.isRequired,
};

export default ReviewCategories;
