import React, { FC, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { getClassNameFor } from '../../helpers';
import Modal from '../Modal';
import style from './NavModal.module.scss';
import { open } from '../../ducks/zippy';

const MODAL_NAME = 'sub-navigation';

type Props = {
  children: ReactNode;
  heading: ReactNode;
  classNameModifier?: string;
};

const NavModal: FC<Props> = ({ children, heading, classNameModifier = '' }) => {
  const dispatch = useDispatch();
  const openModal = () => dispatch(open(MODAL_NAME));

  return (
    <>
      <button
        type="button"
        onClick={openModal}
        className={getClassNameFor(style, 'root', classNameModifier)}
        aria-label="Main menu"
      >
        {heading}
      </button>
      <Modal
        name={MODAL_NAME}
        classNameModifier={classNameModifier}
      >
        <Modal.Body classNameModifier={classNameModifier}>
          <ul className={getClassNameFor(style, 'content', classNameModifier)}>{children}</ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NavModal;
