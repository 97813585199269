import React from 'react';
import { Field } from 'react-final-form';

import CheckboxInput from '../../inputs/Checkbox';

export default fieldProps => (
  <Field
    component={CheckboxInput}
    // type prop must be set
    // see https://final-form.org/docs/react-final-form/types/FieldProps#value
    type="checkbox"
    {...fieldProps}
  />
);
