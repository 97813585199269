import { useCallback, useMemo } from 'react';
import useTripWire from './useTripWire';

const useTodayTripWire = () => {
  const root = useMemo(() => document.querySelector('#app'), []);
  const callback = useCallback(isTripped => {
    root.classList.toggle('present', isTripped);
  }, [root]);

  const { ref } = useTripWire({ top: 110, callback });

  return {
    ref,
  };
};
export default useTodayTripWire;
