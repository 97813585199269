import type { Location } from 'history';
import { parse } from 'query-string';
import { isExternalPath, isReactPath } from './routing';
import captureException from './captureException';

/**
 * Extracts the return url encoded in the query params.
 * Defaults to the home page if there is no valid `return` param.
 */
const getReturnUrl = (location: Pick<Location, 'search'>): string => {
  const query = parse(location.search);
  const target = Array.isArray(query.return) ? query.return[0] : query.return;

  if (target && (isReactPath(target) || isExternalPath(target))) {
    return target;
  }

  if (target && target !== '/') {
    // Report any url redirects that have not been processed, as these are likely suspicious.
    captureException(new Error('Invalid return url'), {
      extra: {
        target,
      },
    });
  }

  // Default to the home page
  return '/';
};

export default getReturnUrl;
