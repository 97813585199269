import React, { FC } from 'react';
import { CTAButton } from '../../inbox/components/CTA';
import useCheckout from '../hooks/useCheckout';

type CheckoutButtonProps = {
  tripId: number;
};

const CheckoutButton: FC<CheckoutButtonProps> = ({ tripId }) => {
  const { checkout, submitError, submitting } = useCheckout();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return submitError ? <>{submitError}</> : (
    <CTAButton
      type="button"
      onClick={() => checkout(tripId, 'inquiry')}
      submitting={submitting}
      data-test="proceed-to-payment"
    >
      Proceed to Payment
    </CTAButton>
  );
};

export default CheckoutButton;
