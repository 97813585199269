import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const IntlSEOMeta = () => {
  const { pathname } = useLocation();
  const language = 'en';

  return (
    <Helmet>
      <link rel="canonical" href={`https://www.getmyboat.com${pathname}`} />
      <html lang={language} />
      <meta httpEquiv="content-language" content={language} />
      <meta name="google-site-verification" content="nv811terVIIzPPxnXEvsq9uF7AOSDyiXjoX4fp5swXY" />
    </Helmet>
  );
};

export default IntlSEOMeta;
