import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TripStateIndicatorCalendar from '../../../common/components/TripStateIndicatorCalendar';
import useCalendarFocus from '../../hooks/useCalendarFocus';
import { useEventDetailsContext } from '../../hooks/useEventDetails';
import EventShell from '../EventShell';

const Event = ({
  children,
  event,
  time,
  pastEvent,
  unavailable,
}) => {
  const { showEventDetails } = useEventDetailsContext();

  const { renter } = event;
  const tripState = event.state;
  const threadId = event.thread_id;
  const { focusedThreadId, setCalendarFocus } = useCalendarFocus();
  const title = renter.first_name;
  const multiDayDuration = event.total_days > 1 && `${event.day_number}/${event.total_days}`;

  const handleClick = useCallback(() => {
    setCalendarFocus({ focusedThreadId: threadId });
    showEventDetails(event, pastEvent);
  }, [event, pastEvent, setCalendarFocus, showEventDetails, threadId]);

  const classNameModifier = classNames(tripState.state, { past: pastEvent });

  return (
    <EventShell
      className={classNames({
        focusedEvent: focusedThreadId === threadId,
      })}
      classNameModifier={classNameModifier}
      conflicted={event.conflicted}
      eventType={event.event_type}
      handleClick={handleClick}
      groupId={`${event.event_type}-${threadId}`}
      time={time}
      title={title}
      multiDayDuration={multiDayDuration}
      unavailable={unavailable}
      tripStateIndicator={(
        <TripStateIndicatorCalendar
          classNameModifier={classNames({ past: pastEvent })}
          tripState={tripState}
        />
      )}
      tripState={tripState}
    >
      {children}
    </EventShell>
  );
};

Event.propTypes = {
  children: PropTypes.node,
  event: PropTypes.object.isRequired,
  time: PropTypes.node.isRequired,
  pastEvent: PropTypes.bool.isRequired,
  unavailable: PropTypes.node,
};

export default Event;
