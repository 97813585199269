import React, { FC, ReactNode } from 'react';
import type { Duration } from 'moment';

import DurationReturn from '../../DurationReturn';
import s from './Dates.module.scss';

type DateBlockProps = {
  date: string;
  tripLength: Duration;
  children: ReactNode;
  isNight?: boolean;
};

const DateBlock: FC<DateBlockProps> = ({
  children,
  date,
  tripLength,
  isNight = false,
}) => (
  <div className={s.fieldGroup}>
    {children}
    <DurationReturn
      date={date}
      tripLength={tripLength}
      isNight={isNight}
    />
  </div>
);

export default DateBlock;
