import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, useHistory, useLocation } from 'react-router-dom';
import { Form } from 'react-final-form';
import { HotKeys } from 'react-hotkeys';
import { Helmet } from 'react-helmet-async';

import type { ImmutableUser } from '../../../../types/user/User';
import { PASSWORD_LENGTH, PATHS } from '../../../../common/constants';
import { rffSubmitResponse } from '../../../../common/helpers';
import useGrecaptcha from '../../../../auth/hooks/useGrecaptcha';
import useOnSubmitLocationChange from '../../../../common/hooks/useOnSubmitLocationChange';
import { registerCall } from '../../../../common/ducks/user';
import { PAGES } from '../../../constants';
import { BackButton } from '../../../../common/components/BackButton';
import Button from '../../../../common/components/Button';
import CloseButton from '../../../../common/components/CloseButton';
import AgreementContent from '../../../../common/components/AgreementContent';
import CreatePassword from '../../../../common/components/fieldsets/CreatePassword';
import FormError from '../../../../common/components/FormError';
import getReturnUrl from '../../../../common/utils/getReturnUrl';
import { getUser } from '../../../../common/utils/reduxStoreSelectors';
import s from './PasswordPage.module.scss';

type PasswordFieldsValue = {
  password1: string;
  password2: string;
};

const getRegisterData = (user: ImmutableUser, values: PasswordFieldsValue) => ({
  email: user.get('email'),
  first_name: user.get('first_name'),
  last_name: user.get('last_name'),
  phone: user.get('phone'),
  marketing_consent: user.get('marketing_consent'),
  terms_agreed: true,
  ...values,
});

type PasswordPageProps = {
  match: { url: string };
};

const PasswordPage: FC<PasswordPageProps> = ({ match: { url } }) => {
  const { goBack } = useHistory();
  const location = useLocation();
  const { search } = location;
  const returnUrl = getReturnUrl(location);
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  // NOTE: this is like this because the hook goes to the sibling page, where we want it
  // to go to a child page.
  // All of this indirection is designed to preserve the progress bar on the top of the page.
  // TODO: refactor that to be less magical.
  const goToAgreement = useOnSubmitLocationChange(`${PAGES.PASSWORD}/${PAGES.AGREEMENT}`);
  const goToRedirect = useOnSubmitLocationChange(PAGES.REDIRECT);

  const register = useCallback(
    (values) => {
      const registerData = getRegisterData(user, values);
      return dispatch(registerCall(registerData)).then(goToRedirect).catch(rffSubmitResponse());
    },
    [dispatch, goToRedirect, user],
  );

  const onSubmit = useGrecaptcha('signup', register);

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting, submitError, valid, form: { getRegisteredFields, blur } }) => {
        const agreementTransition = () => {
          getRegisteredFields().forEach(blur);
          if (valid) {
            goToAgreement();
          }
        };
        const title = 'Password';

        return (
          <form
            method="POST"
            className={s.container}
            onSubmit={handleSubmit}
          >
            {/* password form */}
            <Route
              exact
              path={url}
              render={() => (
                <>
                  <Helmet>
                    <title>{title}</title>
                  </Helmet>
                  <h1 className={s.title}>{title}</h1>
                  <div className={s.message}>
                    <p>
                      {'This will create an account with Getmyboat so you can read and respond'
                        + ` to messages from owners. Passwords must be at least ${PASSWORD_LENGTH} characters.`}
                    </p>
                    <p>
                      {'Have an account with Getmyboat? '}
                      <Link
                        to={`${PATHS.LOGIN}${search}`}
                        className={s.link}
                      >
                        Sign In
                      </Link>
                      .
                    </p>
                  </div>
                  <HotKeys
                    handlers={{ submit: agreementTransition }}
                    keyMap={{ submit: ['enter'] }}
                    className={s.form}
                  >
                    <CreatePassword autoFocus />

                    <Button
                      type="button"
                      onClick={agreementTransition}
                    >
                      Create Account
                    </Button>
                  </HotKeys>
                </>
              )}
            />
            {/* agreement form */}
            <Route
              exact
              path={`${url}/${PAGES.AGREEMENT}/`}
              render={() => (
                <>
                  <AgreementContent />

                  <FormError error={submitError} />

                  <Button
                    type="submit"
                    submitting={submitting}
                  >
                    Accept
                  </Button>
                  <BackButton onClick={goBack} />
                  <CloseButton backUrl={returnUrl} />
                </>
              )}
            />
            <BackButton onClick={goBack} />
            <CloseButton backUrl={returnUrl} />
          </form>
        );
      }}
    </Form>
  );
};

export default PasswordPage;
