import React, { FC, useCallback } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { FullBoatDetail } from 'src/types/boat/BoatDetail';
import { UNAVAILABLE_EDITOR_LISTING_IDS, UNAVAILABLE_EDITOR_SELECT_ALL_LISTINGS } from 'src/calendar/constants';
import Checkbox from 'src/common/components/inputs/Checkbox';
import { AdHocEventFormState } from 'src/calendar/types';
import styles from './UnavailableListingList.module.scss';

type Props = {
  listings: FullBoatDetail[];
};
const UnavailableListingList:FC<Props> = ({ listings }) => {
  const { change } = useForm<AdHocEventFormState>();
  const { values } = useFormState<AdHocEventFormState>();
  const selectedCount = values.boat_ids.length;

  const toggleAll = useCallback(
    () => ((selectedCount === listings.length)
      ? change(UNAVAILABLE_EDITOR_LISTING_IDS, []) // Only toggle all items off if all are active
      : change(UNAVAILABLE_EDITOR_LISTING_IDS, listings.map(b => b.boat_id))),
    [change, listings, selectedCount],
  );
  return (
    <ul className={styles.root}>
      <li className={styles.listItem}>
        <label className={styles.selectAllField}>
          <Checkbox
            indeterminate={
                selectedCount > 0
                && selectedCount < listings.length
              }
            input={{
              checked: selectedCount === listings.length,
              name: UNAVAILABLE_EDITOR_SELECT_ALL_LISTINGS,
              onChange: toggleAll,
            }}
          />
          Select All
        </label>
      </li>
      {listings.map((listing) => (
        <li key={listing.boat_id} className={styles.listItem}>
          <label className={styles.checkboxField}>
            <Field
              name={UNAVAILABLE_EDITOR_LISTING_IDS}
              value={listing.boat_id}
              component={Checkbox}
              type="checkbox"
            />
            {listing.shortname || listing.headline}
          </label>
        </li>
      ))}
    </ul>
  );
};

export default UnavailableListingList;
