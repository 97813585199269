import React, { FC } from 'react';

import { getClassNameFor } from '../../../common/helpers';

import s from './EventDetailsFooter.module.scss';

type EventDetailsFooter = {
  classNameModifier?: string;
};

const EventDetailsFooter: FC<EventDetailsFooter> = ({ children, classNameModifier }) => (
  <div className={getClassNameFor(s, 'root', classNameModifier)}>
    {children}
  </div>
);

export default EventDetailsFooter;
