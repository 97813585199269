import React, { Component } from 'react';
import PropTypes from 'prop-types';

import loadGoogleMapsSdk from './loadGoogleMapsSdk';

export class AutocompleteProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autocompleteService: null,
    };
    // This is to get around the fact that this component might
    // unmount before the callback is fired, in which case
    // there would be a hanging setState reference on an unmounted
    // component, which would be bad. Referencing the function
    // this way and then cleaning up in componentWillUnmount should
    // get around this.
    this.callback = this.onLoad;
  }

  componentWillUnmount() {
    this.callback = () => {};
  }

  getAutocompleteService = () => {
    if (this.state.autocompleteService) {
      return Promise.resolve(this.state.autocompleteService);
    }
    return new Promise(resolve => {
      loadGoogleMapsSdk({
        key: __GMAPS_API_KEY__,
        libraries: 'places',
      }, ({ googleMaps }) => {
        const autocompleteService = new googleMaps.places.AutocompleteService();
        const sessionToken = this.state.sessionToken
          || new googleMaps.places.AutocompleteSessionToken();
        this.callback(autocompleteService, sessionToken);
        resolve({ autocompleteService, sessionToken });
      });
    });
  };

  onLoad = ({ service: autocompleteService }, sessionToken) => {
    this.setState({ autocompleteService, sessionToken });
  };

  render() {
    const { getAutocompleteService } = this;
    return this.props.children({ getAutocompleteService });
  }
}

AutocompleteProvider.propTypes = {
  children: PropTypes.func.isRequired,
};

export default WrappedComponent => props => (
  <AutocompleteProvider>
    {({ getAutocompleteService }) => (
      <WrappedComponent getAutocompleteService={getAutocompleteService} {...props} />
    )}
  </AutocompleteProvider>
);
