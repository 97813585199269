import { Map, fromJS } from 'immutable';

// Actions
export const SET_OFFER = 'offer/offer/SET_OFFER';
export const PATCH_OFFER = 'offer/offer/PATCH_OFFER';
export const CLEAR_OFFER = 'offer/offer/CLEAR_OFFER';
export const CLEAR_FIELDS = 'offer/offer/CLEAR_FIELDS';

// Reducer
// eslint-disable-next-line @typescript-eslint/default-param-last
export default function offerReducer(state = Map(), action) {
  switch (action.type) {
    case SET_OFFER:
      // Merge might be more appropriate.
      // Do I really just want to return the whole thing?
      return state.merge(action.offer);
    case PATCH_OFFER:
      return state.merge(fromJS(action.fields));
    case CLEAR_OFFER:
      return Map();
    case CLEAR_FIELDS:
      return state.filter((value, field) => !action.fields.includes(field));
    default:
      return state;
  }
}

// Basic action creators
export const setOffer = offer => ({ type: SET_OFFER, offer });
export const patchOffer = fields => ({ type: PATCH_OFFER, fields });
export const clearOffer = () => ({ type: CLEAR_OFFER });
// NOTE: this takes an array, not an object
export const clearFields = fields => ({ type: CLEAR_FIELDS, fields });
