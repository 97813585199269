import React, { FC, useCallback, useRef, useState } from 'react';
import classNames from 'classnames';

import { useDispatch } from 'react-redux';
import Icon from 'src/common/components/IconDS22';
import { BoatDetail } from '../../../types/boat/BoatDetail';
import { useBoats } from '../../../common/hooks';
import { useInstabookEditorContext } from '../../hooks/useInstabookEditor';
import { getBoatHeadline, getClassNameFor, plural } from '../../../common/helpers';
import { INSTABOOK_TRIP_SELECTOR_MODAL_NAME as modalName } from '../../constants';
import Avatar from '../../../common/components/Avatar';
import ListingsDropdown from '../ListingsDropdown';
import Modal from '../../../common/components/Modal';
import { open, close } from '../../../common/ducks/zippy';
import InstabookEditorHeader from '../InstabookEditorHeader';
import InstabookHeaderInfo from '../InstabookHeaderInfo';
import EventDetailsFooter from '../EventDetailsFooter';
import Button from '../../../common/components/Button';

import s from './InstabookListingSelector.module.scss';

const InstabookListingSelector: FC<Props> = ({ boat }) => {
  const { boats } = useBoats();
  const {
    instabookListing,
    setInstabookListing,
  } = useInstabookEditorContext();
  const [selectedBoat, setSelectedBoat] = useState<string | null>(instabookListing?.id || null);
  const boatCapacity = boat?.capacity ?? 1;
  const guests = boat && `${boatCapacity} guest${plural(boatCapacity)}`;
  const selectorRef = useRef<HTMLDivElement | null>(null);
  const filterContainerRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();

  const openModal = useCallback(() => dispatch(open(modalName)), [dispatch]);
  const closeModal = useCallback(() => dispatch(close(modalName)), [dispatch]);
  const onContinue = useCallback(() => {
    setInstabookListing(selectedBoat);
    closeModal();
  }, [
    selectedBoat,
    setInstabookListing,
    closeModal,
  ]);
  const onCancel = useCallback(() => {
    setSelectedBoat(instabookListing?.id || null);
    closeModal();
  }, [closeModal, instabookListing]);

  return (
    <div
      className={getClassNameFor(s, 'root', classNames({ noListing: !instabookListing }))}
      ref={selectorRef}
    >
      {instabookListing ? (
        <Avatar
          isBoat
          classNameModifier="boat instabook"
          extraClassName={s.avatar}
          lazy={false}
          size="large"
          user={{
            photo_url: instabookListing?.photo_url,
            has_photo: !!instabookListing?.photo_url,
            first_name: getBoatHeadline(instabookListing),
          }}
        />
      )
        : (
          <Button
            type="button"
            onClick={openModal}
            classNameModifier="primary rounded"
            iconOnly
          >
            <Icon id="plus" />
          </Button>
        )}
      {instabookListing && (
        <dl className={s.info}>
          <dt>{getBoatHeadline(instabookListing)}</dt>
          <dd>
            {/* Render boat details for published boat only and if selected boat
              is the same boat that set is in the redux store */
              (boat?.active && boat?.id === instabookListing?.id) && `${guests}`
            }
          </dd>
        </dl>
      )}
      {(boats.length > 1 && (!boat || boat?.active)) && (
        <>
          <button
            type="button"
            onClick={openModal}
            className={s.toggleButton}
          >
            {instabookListing ? 'Change' : 'Select'}
            {' '}
            Listing
          </button>
          <Modal
            disableOverlayClick
            label="Select Listing"
            name={modalName}
            classNameModifier={classNames('calendar', 'instabook', 'instabookListingSelector')}
            noExit
          >
            <InstabookEditorHeader
              title="Select Listing"
              classNameModifier="instabookListingSelector"
            >
              <InstabookHeaderInfo />
            </InstabookEditorHeader>
            <ListingsDropdown
              activeBoats={boats}
              classNameModifier="calendar instabookListingSelector"
              control="radio"
              getInputProps={(boatId: BoatDetail['id']) => ({
                input: {
                  checked: selectedBoat === boatId,
                  name: 'instabookListing',
                  onChange: () => {
                    setSelectedBoat(boatId);
                  },
                  value: boatId,
                },
              })}
              filterContainerRef={filterContainerRef}
            />
            <EventDetailsFooter classNameModifier="modalFooter instabookListingSelector">
              <Button
                type="button"
                classNameModifier="secondary paired"
                onClick={onCancel}
              >
                <Icon
                  id="caret-left"
                />
                Back
              </Button>
              <Button
                type="button"
                onClick={onContinue}
                classNameModifier="paired instabook-green date"
              >
                Continue
              </Button>
            </EventDetailsFooter>
          </Modal>
        </>
      )}
    </div>
  );
};

type Props = {
  boat: null | BoatDetail;
};

export default InstabookListingSelector;
