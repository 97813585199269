import PropTypes from 'prop-types';
import React from 'react';

import { PATHS } from '../../../../common/constants';
import { preserveSearch } from '../../../../common/utils/routing';
import DeclineMessages from '../../DeclineMessages';
import TripPanel from '../../presentation/TripPanel';
import content, { getMessage } from '../content';
import MovedCTA from './MovedCTA';

const Moved = ({
  trip,
  location,
}) => {
  const tripState = trip.getIn(['state', 'state']);

  return (
    <TripPanel
      trip={trip}
      subheader={content.title[tripState]}
      intro={getMessage(trip, location)}
      cta={(
        <MovedCTA
          archived={trip.get('archived')}
          location={location}
          trip={trip}
        />
      )}
      backLocation={preserveSearch(PATHS.INBOX, location)}
    >
      <DeclineMessages trip={trip} />
    </TripPanel>
  );
};

Moved.propTypes = {
  trip: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Moved;
