import React, { FC } from 'react';

import Icon from '../IconDS22';
import s from './ToggleButton.module.scss';

export type ToggleButtonIconProps = {
  isOpen: boolean;
};

const ToggleButtonIcon: FC<ToggleButtonIconProps> = ({
  isOpen,
}) => (
  <Icon
    id={isOpen ? 'caret-up' : 'caret-down'}
    size="s"
    className={s.icon}
  />
);

export default ToggleButtonIcon;
