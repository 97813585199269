import { Locale } from 'src/types/common/Locale';

// Actions
// -----------------------------------------------------------------------------
export const SET_LOCALE = 'common/locale/SET_LOCALE';

// Action creators
// -----------------------------------------------------------------------------
export const setLocale = (locale: Locale) => (
  { type: SET_LOCALE, locale } as const
);

type Actions = ReturnType<typeof setLocale>;

// Reducers
// -----------------------------------------------------------------------------
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (locale: Locale = 'en', action: Actions) => {
  switch (action.type) {
    case SET_LOCALE:
      return action.locale;
    default:
      return locale;
  }
};
