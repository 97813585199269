import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { LOCKDOWN_PAGES } from '../../constants';
import { getUser } from '../../utils/reduxStoreSelectors';
import Logo from '../Logo';
import SearchBar from '../SearchBar';
import Navigation from '../Navigation';
import OrganizationSchema from '../schema/organization';
import BareHeader from './BareHeader';
import { getIsLoggedIn } from '../../ducks/user';

type HeaderProps = {
  children?: ReactNode;
  classNameModifier?: string;
  withNav?: boolean;
  withSearchBar?: boolean;
  withMobileLogo?: boolean;
  withOrgSchemaAggregateRating?: boolean;
  navigationVariant?: 'owner-calendar-inbox';
};

const Header: FC<HeaderProps> = ({
  children,
  classNameModifier,
  withNav = true,
  withSearchBar = true,
  withOrgSchemaAggregateRating = false,
  withMobileLogo = true,
  navigationVariant,
}) => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const user = useSelector(getUser);
  const { pathname } = useLocation();
  const calendarInboxVariant = navigationVariant === 'owner-calendar-inbox';
  const isOwner = user.get('owner_approved');

  const modifiers = classNames(classNameModifier, {
    owner: isOwner && !calendarInboxVariant,
    ownerCalendarInbox: isOwner && calendarInboxVariant,
    withMobileLogo,
  });

  // Only show the SearchBar to Renters, and wait for the owner_approved status to be loaded
  const showSearchBar = withSearchBar && user.get('owner_approved') !== undefined && !isOwner;

  return (
    <BareHeader
      classNameModifier={modifiers}
      extraChildren={(
        <OrganizationSchema withAggregateRating={withOrgSchemaAggregateRating} />
      )}
    >
      <Logo
        classNameModifier={modifiers}
        noLink={LOCKDOWN_PAGES.includes(pathname)}
      />
      {children}
      {showSearchBar && (
        <SearchBar />
      )}
      {withNav && isLoggedIn !== null && (
        <Navigation
          user={user}
        />
      )}
    </BareHeader>
  );
};

export default Header;
