import { NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import type { ImmutableTrip } from 'src/types/trips/Trips';
import {
  EXTERNAL_PATHS,
  PATHS,
  SUPPORT_URLS,
  USER_ROLES,
} from '../../../common/constants';
import { rffSubmitResponse } from '../../../common/helpers';
import {
  MESSAGE_BLACKLIST_STATES,
  PAGES,
  PREFERRED_DATE_FIELD,
  TRIP_STATES,
} from '../../constants';
import {
  postMessageCall,
} from '../../ducks/messages';
import {
  cancelRequestedChangesCall,
  cancelTripCall,
  cancelOfferCall,
  cancelReservationCall,
  archiveTripCall,
  unarchiveTripCall,
  declineInquiryCall,
} from '../../ducks/trips';
import {
  focusCalendar,
} from '../../../calendar/ducks/calendar';
import { getUserRole, firstTripRedirectThunk, isChargeEligible } from '../../helpers';
import ExternalLink from '../../../common/components/ExternalLink';
import TripMenuButton from '../TripMenuButton';
import { isWithinCalendarConstraints } from '../../../calendar/hooks/useCalendar';

const CONTACT_SUPPORT = {
  name: 'contact support',
  component: ExternalLink,
  icon: 'question',
  title: 'Contact Support',
  url: SUPPORT_URLS.REQUEST,
};

export const SEND_MESSAGE_ACTION_NAME = 'send message';
const SEND_MESSAGE = {
  name: SEND_MESSAGE_ACTION_NAME,
  icon: 'paper-plane',
  title: 'Send a Message',
  destination: `${PAGES.MESSAGES}/`,
};

const SEND_MESSAGE_MODAL = {
  ...SEND_MESSAGE,
  submitText: 'Send Message',
  component: TripMenuButton,
  longDescription: 'You can request or provide more information before sending your booking offer to the renter.',
  placeholder: 'Your message...',
  call: postMessageCall,
};

const SEND_MESSAGE_LINK = {
  ...SEND_MESSAGE,
  component: NavLink,
};

const EDIT_OFFER = {
  name: 'edit offer',
  component: NavLink,
  destination: `${PAGES.EDIT}/`,
  icon: 'pencil',
  title: 'Edit Offer',
  shortDescription: 'This will cancel and resend',
};

const OPEN_IN_CALENDAR = {
  name: 'open in calendar',
  component: NavLink,
  url: PATHS.CALENDAR,
  icon: 'calendar-open',
  title: 'Open in Calendar',
  onClick: (dispatch, trip) => dispatch(focusCalendar({
    selectedDate: trip.get(PREFERRED_DATE_FIELD),
    focusedThreadId: trip.get('pk'),
    scrollToEvent: true,
  })),
  isDisabled: trip => {
    const tripStartDate = trip.get(PREFERRED_DATE_FIELD);
    return !isWithinCalendarConstraints(tripStartDate);
  },
};

const CHARGE = {
  name: 'charge',
  component: TripMenuButton,
  icon: 'credit-card-charge',
  title: 'Charge Add-On',
  onClick: ((_dispatch: Dispatch, trip: ImmutableTrip) => {
    const tripId = trip.get('pk').toString();
    const searchParams = new URLSearchParams({ trip: tripId });
    const url = `${EXTERNAL_PATHS.CHARGE_ADD_ON}price/?${searchParams.toString()}`;
    // Redirect to the price screen of the charge add-on journey
    window.location.href = url;
  }),
  isDisabled: (trip: ImmutableTrip) => !isChargeEligible(trip),
};

const CANCEL_OFFER = {
  name: 'cancel offer',
  component: TripMenuButton,
  icon: 'minus-circle',
  title: 'Cancel Offer',
  longDescription: 'Are you sure you want to cancel your offer to this customer?',
  cancelText: "Don't Cancel",
  call: cancelOfferCall,
};

export const CANCEL_INQUIRY = {
  name: 'cancel inquiry',
  component: TripMenuButton,
  icon: 'minus-circle',
  title: 'Cancel Inquiry',
  longDescription: 'Are you sure you want to cancel your inquiry?',
  cancelText: "Don't Cancel",
  call: cancelTripCall,
};

export const CANCEL_REQUESTED_CHANGES = {
  name: 'Cancel Requested Changes',
  component: TripMenuButton,
  icon: 'note-cancel',
  title: 'Cancel Requested Changes',
  longDescription: 'Are you sure you want to cancel the changes you requested to this offer?',
  submitText: 'Cancel Changes',
  cancelText: "Don't Cancel",
  call: cancelRequestedChangesCall,
  withMessageField: false,
};

const DECLINE_INQUIRY = {
  name: 'decline inquiry',
  component: TripMenuButton,
  icon: 'x-circle',
  title: 'Decline Inquiry',
  longDescription: 'It’s usually better to reply with suggested changes instead of '
  + 'declining. Trip inquiries are often flexible and you might be able to offer a trip '
  + 'that’s even better than the original.',
  cancelText: 'Cancel',
  call: declineInquiryCall,
};

export const CANCEL_RESERVATION_ACTION_NAME = 'cancel reservation';
const CANCEL_RESERVATION = {
  name: CANCEL_RESERVATION_ACTION_NAME,
  component: TripMenuButton,
  icon: 'x-circle',
  title: 'Cancel Reservation',
  longDescription: 'Are you sure you want to cancel this reservation? Please note that service fees are non-refundable.',
  placeholder: 'Optional message to other party',
  cancelText: "Don't Cancel",
  call: cancelReservationCall,
};

export const ARCHIVE_TRIP = {
  name: 'archive',
  component: TripMenuButton,
  icon: 'archive-box',
  title: 'Archive this inquiry',
  onClick: (dispatch, trip, { history, location }) => dispatch(archiveTripCall(trip.get('pk')))
    .then(() => dispatch(firstTripRedirectThunk(history, location)))
    .catch(rffSubmitResponse()),
};

export const UNARCHIVE_TRIP = {
  name: 'unarchive',
  component: TripMenuButton,
  icon: 'archive-box',
  title: 'Unarchive this inquiry',
  onClick: (dispatch, trip, { history, location }) => dispatch(unarchiveTripCall(trip.get('pk')))
  // TODO: wat? shouldn't this continue showing the same thread?
    .then(() => dispatch(firstTripRedirectThunk(history, location)))
    .catch(rffSubmitResponse()),
};

const getArchiveAction = archived => (
  archived
    ? UNARCHIVE_TRIP
    : ARCHIVE_TRIP
);

const getSendMessageAction = tripState => (
  MESSAGE_BLACKLIST_STATES.includes(tripState)
    ? SEND_MESSAGE_MODAL
    : SEND_MESSAGE_LINK
);

const getRenterActions = trip => [
  getSendMessageAction(trip.getIn(['state', 'state'])),
  CANCEL_REQUESTED_CHANGES,
  CANCEL_INQUIRY,
  CANCEL_RESERVATION,
  getArchiveAction(trip.get('archived', false)),
  CONTACT_SUPPORT,
];

const getOwnerActions = trip => [
  CHARGE,
  getSendMessageAction(trip.getIn(['state', 'state'])),
  OPEN_IN_CALENDAR,
  EDIT_OFFER,
  CANCEL_OFFER,
  DECLINE_INQUIRY,
  CANCEL_RESERVATION,
  getArchiveAction(trip.get('archived', false)),
  CONTACT_SUPPORT,
];

const COMMON_ACTIONS = [
  SEND_MESSAGE,
  ARCHIVE_TRIP,
  UNARCHIVE_TRIP,
  CONTACT_SUPPORT,
];

const STATE_TO_ACTIONS_MAP = {
  [TRIP_STATES.OWNER_DIRECT_INQUIRY]: [
    DECLINE_INQUIRY,
    OPEN_IN_CALENDAR,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.OWNER_MATCHING_INQUIRY]: [
    DECLINE_INQUIRY,
    OPEN_IN_CALENDAR,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.OWNER_OFFER_SENT]: [
    EDIT_OFFER,
    CANCEL_OFFER,
    DECLINE_INQUIRY,
    OPEN_IN_CALENDAR,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.OWNER_CHANGES_REQUESTED]: [
    DECLINE_INQUIRY,
    OPEN_IN_CALENDAR,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.OWNER_OFFER_CANCELLED]: [
    DECLINE_INQUIRY,
    OPEN_IN_CALENDAR,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.OWNER_OFFER_AUTO_CANCELLED]: [
    DECLINE_INQUIRY,
    OPEN_IN_CALENDAR,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.OWNER_OFFER_EDITED]: [
    EDIT_OFFER,
    CANCEL_OFFER,
    DECLINE_INQUIRY,
    OPEN_IN_CALENDAR,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.OWNER_OFFER_EXPIRED]: [
    DECLINE_INQUIRY,
    OPEN_IN_CALENDAR,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.OWNER_CUSTOM_OFFER_EXPIRED]: [
    DECLINE_INQUIRY,
    OPEN_IN_CALENDAR,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.OWNER_RESERVATION_CONFIRMED]: [
    CHARGE,
    CANCEL_RESERVATION,
    OPEN_IN_CALENDAR,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.OWNER_RESERVATION_CANCELLED_BY_RENTER]: [
    DECLINE_INQUIRY,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.OWNER_RESERVATION_CANCELLED_BY_OWNER]: [
    DECLINE_INQUIRY,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.OWNER_TRIP_COMPLETE_REVIEW_PENDING]: [
    CHARGE,
    OPEN_IN_CALENDAR,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.OWNER_TRIP_COMPLETE_REVIEW_WAITING]: [
    CHARGE,
    OPEN_IN_CALENDAR,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.OWNER_TRIP_COMPLETE_REVIEW_DONE]: [
    CHARGE,
    OPEN_IN_CALENDAR,
    ...COMMON_ACTIONS,
  ],

  [TRIP_STATES.RENTER_DIRECT_INQUIRY]: [
    CANCEL_INQUIRY,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.RENTER_MATCHING_INQUIRY]: [
    CANCEL_INQUIRY,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.RENTER_OFFER_RECEIVED]: [
    CANCEL_INQUIRY,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.RENTER_CHANGES_REQUESTED]: [
    CANCEL_REQUESTED_CHANGES,
    CANCEL_INQUIRY,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.RENTER_OFFER_CANCELLED]: [
    CANCEL_INQUIRY,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.RENTER_OFFER_EDITED]: [
    CANCEL_INQUIRY,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.RENTER_OFFER_EXPIRED]: [
    CANCEL_INQUIRY,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.RENTER_CUSTOM_OFFER_EXPIRED]: [
    CANCEL_INQUIRY,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.RENTER_INQUIRY_DECLINED]: [
    CANCEL_INQUIRY,
    ...COMMON_ACTIONS,
  ],
  [TRIP_STATES.RENTER_INQUIRY_CANCELLED]: [
    ARCHIVE_TRIP,
    UNARCHIVE_TRIP,
    CONTACT_SUPPORT,
  ],
  [TRIP_STATES.RENTER_RESERVATION_CONFIRMED]: [
    CANCEL_RESERVATION,
    ...COMMON_ACTIONS,
  ],
};

export const getRoleSpecificActions = trip => (
  getUserRole(trip) === USER_ROLES.OWNER
    ? getOwnerActions(trip)
    : getRenterActions(trip)
);

export const getActions = state => STATE_TO_ACTIONS_MAP[state] || COMMON_ACTIONS;
