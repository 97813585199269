import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { type Amounts } from 'src/types/common/Amounts';
import type { ImmutableTrip } from 'src/types/trips/Trips';
import captureException from 'src/common/utils/captureException';
import { getTransaction } from 'src/common/utils/reduxStoreSelectors';
import { useAppDispatch, useFeatureFlag } from 'src/common/hooks';
import Button from 'src/common/components/Button';
import ErrorComponent from 'src/common/components/Error';
import { BookingInfoCard } from '../../cards';
import RenterPriceSection from '../../PriceSection/renter';
import TripPanel from '../../presentation/TripPanel';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import TopScroll from '../../../../common/components/TopScroll';
import { PATHS } from '../../../../common/constants';
import CTA, { CTAButton } from '../../CTA';
import { preserveSearch } from '../../../../common/utils/routing';
import { updateTransactionCall } from '../../../ducks/transaction';
import CheckoutButton from '../../../../checkout/CheckoutButton';

type ReviewYourBookingProps = {
  trip: ImmutableTrip;
};

const ReviewYourBooking: React.FC<ReviewYourBookingProps> = ({
  trip,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isInsuranceEnabled = useFeatureFlag('insurance');
  const transaction = useSelector(getTransaction);
  const amounts: Amounts = transaction?.get('amounts')?.toJS()
    || trip?.getIn(['transaction', 'amounts'])?.toJS();
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setLoading] = useState(true);
  const tripId = trip.get('pk');
  const listingUrl = trip.getIn(['boat', 'listing_url']);
  const insuranceUrl = `${listingUrl}checkout/${tripId}/`;

  const recalculateTax = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      await dispatch(updateTransactionCall(tripId, {}));
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to update transaction'));
      captureException(new Error('Error updating transaction'), { extra: { err } });
    } finally {
      setLoading(false);
    }
  }, [dispatch, tripId]);

  const bookingInfoCard = (
    <BookingInfoCard trip={trip} />
  );

  const cta = (
    <CTA classNameModifier="reviewBooking">
      {isInsuranceEnabled ? (
        <CTAButton
          href={insuranceUrl}
        >
          Continue
        </CTAButton>
      ) : (
        <CheckoutButton tripId={tripId} />
      )}
    </CTA>
  );

  useEffect(() => {
    recalculateTax();
  }, [recalculateTax]);

  return (
    <TripPanel
      trip={trip}
      subheader="Review your Booking"
      intro={isLoading ? null : bookingInfoCard}
      cta={isLoading ? null : cta}
      backLocation={preserveSearch(`${PATHS.INBOX}${trip.get('pk')}/`, location)}
      classNameModifier="withoutSideMargins reviewBooking"
      withTripControls={false}
    >
      <TopScroll />
      {error && (
        <ErrorComponent centered showIcon>
          Unable to update transaction.
          <Button
            type="button"
            onClick={recalculateTax}
            classNameModifier="secondary small"
          >
            Please retry
          </Button>
        </ErrorComponent>
      )}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <RenterPriceSection
          amounts={amounts}
          trip={trip}
        />
      )}
    </TripPanel>
  );
};

export default ReviewYourBooking;
