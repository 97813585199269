export const INITIAL_LANDING_PAGE_KEY = 'initial_landing_page';

const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'] as const;
type UTMParams = typeof utmParams[number];

export type InitialLandingPage = {
  url?: string;
  referrer?: string;
  gclid?: string;
  utmValues?: Partial<Record<UTMParams, string | undefined>>;
};

export const clearInitialLandingPage = () => {
  try {
    localStorage.removeItem(INITIAL_LANDING_PAGE_KEY);
  } catch (error) {
    // Handle error silently
  }
};

const retrieveInitialLandingPage = (): InitialLandingPage | undefined => {
  let storedValue = null;
  try {
    storedValue = localStorage.getItem(INITIAL_LANDING_PAGE_KEY);
  } catch (error) {
    // Handle error silently
  }
  if (!storedValue) {
    return undefined;
  }

  try {
    return storedValue ? JSON.parse(storedValue) as InitialLandingPage : undefined;
  } catch (error) {
    return undefined;
  }
};

export default retrieveInitialLandingPage;
