import React, { FC, useState } from 'react';
import { Field } from 'react-final-form';

import Icon from 'src/common/components/IconDS22';
import Input from '../../inputs/Input';
import { rffValidators, combineValidators } from '../../../validation';
import { PASSWORD_LENGTH } from '../../../constants';
import s from './CreatePassword.module.scss';

const password1Validate = combineValidators([
  rffValidators.required({ message: 'Please enter a password.' }),
  rffValidators.minLength({
    message: `The password must be at least ${PASSWORD_LENGTH} characters and avoid using common phrases.`,
    // This is the same as what's enforced by the backend.
    // TODO: Get this number dynamically from the backend.
    length: 9,
  }),
]);
const password2Validate = combineValidators([
  rffValidators.required({ message: 'Please confirm your password.' }),
  rffValidators.matches({ field: 'password1', message: 'The passwords do not match.' }),
]);

type PasswordInputProps = {
  autoFocus?: boolean;
};

const PasswordInput: FC<PasswordInputProps> = ({ autoFocus }) => {
  const [isPassword1Visible, setIsPassword1Visible] = useState<boolean>(false);
  const [isPassword2Visible, setIsPassword2Visible] = useState<boolean>(false);

  return (
    <>
      <div className={s.passwordWrapper}>
        <Field
          name="password1"
          validate={password1Validate}
          validateFields={['password2']}
          component={Input}
          placeholder="Password"
          type={isPassword1Visible ? 'text' : 'password'}
          autoFocus={autoFocus}
        />
        <button
          className={s.visiblePasswordButton}
          type="button"
          onClick={() => setIsPassword1Visible(!isPassword1Visible)}
        >
          <Icon id={isPassword1Visible ? 'eye' : 'eye-slash'} />
        </button>
      </div>

      <div className={s.passwordWrapper}>
        <Field
          name="password2"
          validate={password2Validate}
          validateFields={['password1']}
          component={Input}
          placeholder="Re-enter Password"
          type={isPassword2Visible ? 'text' : 'password'}
        />
        <button
          className={s.visiblePasswordButton}
          type="button"
          onClick={() => setIsPassword2Visible(!isPassword2Visible)}
        >
          <Icon id={isPassword2Visible ? 'eye' : 'eye-slash'} />
        </button>
      </div>
    </>
  );
};

export default PasswordInput;
