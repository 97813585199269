/* eslint-disable import/prefer-default-export */
import startOfMonth from 'date-fns/startOfMonth';
import add from 'date-fns/add';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import sub from 'date-fns/sub';
import defaultLocale from 'date-fns/locale/en-US';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT, DISPLAY_DATE_FORMAT_LONG, TIME_12 } from 'src/common/constants';
import {
  CALENDAR_DATE_FORMAT_DATE_FNS,
  CALENDAR_DAY_NAME, CALENDAR_EVENT_DETAILS_DATE, CALENDAR_TODAY_DATE_DIVIDER,
} from 'src/calendar/constants';
import { DateString } from '../types';
import captureException from './captureException';

/** Date formats that do not require localization */
const DATE_FORMATS_NUMERIC = {
  d: 'd', /** e.g., 01 */
  dd: 'dd', /** `DATE_FORMAT` day of month, e.g., 01 */
  'd MMM yyyy': 'd MMM yyyy', /** `CALENDAR_REPEATS_UNTIL_DATE` e.g., 1 Jan, 2024 */
  'MM/dd/yyyy': 'MM/dd/yyyy',
  'MMM yyyy': 'MMM yyyy', /** `CALENDAR_MONTH_SHORT` and year, e.g., Jan 2024 */
  'MMMM yyyy': 'MMMM yyyy', /** `CALENDAR_MONTH_LONG` and year, e.g., January 2024 */
  'yyyy-MM-dd HH:mm:ss': 'yyyy-MM-dd HH:mm:ss',
  /** `DATE_FORMAT` ISO date / Calendar date  */
  [CALENDAR_DATE_FORMAT_DATE_FNS]: CALENDAR_DATE_FORMAT_DATE_FNS,
  yyyy: 'yyyy', /** Year */
  "yyyy-MM-dd'T'HH:mm": "yyyy-MM-dd'T'HH:mm", /** `OFFER_EXPIRY_DATE_FORMAT` T is quoted for escaping the character */
} as const;
type DateFormatsNumeric = keyof typeof DATE_FORMATS_NUMERIC;

/** Date formats that require localization  */
const DATE_FORMATS_REQUIRE_LOCALIZATION = {
  [TIME_12]: TIME_12, /** 12 hour time format, e.g., 12:00 AM */
  [CALENDAR_DAY_NAME]: CALENDAR_DAY_NAME, /** EEE, e.g., Mon */
  /** EEE MMM d, yyyy, e.g, Mon Jan 1, 2024 */
  [CALENDAR_EVENT_DETAILS_DATE]: CALENDAR_EVENT_DETAILS_DATE,
  /** EEEE, MMMM d, yyyy, e.g., Monday, January 1, 2024 */
  [DISPLAY_DATE_FORMAT_LONG]: DISPLAY_DATE_FORMAT_LONG,
  [CALENDAR_TODAY_DATE_DIVIDER]: CALENDAR_TODAY_DATE_DIVIDER, /** MMM d, yyyy, e.g., Jan 1, 2024 */
  'd MMM y': 'd MMM y', /** Short date, month, year, e.g., 1 Jan 2024 */
  'dd MMM yyyy hh:mm a': 'dd MMM yyyy hh:mm a', /** e.g., 01 Jan 2024 01:15 PM */
  [DISPLAY_DATE_FORMAT]: DISPLAY_DATE_FORMAT, /** dd MMM yyyy, e.g.,: 01 Jan 2024 */
  'EEE d MMM': 'EEE d MMM', /** Instabook Date, e.g., Mon 1 Jan */
  'EEE MMM dd, yyyy': 'EEE MMM dd, yyyy', /** e.g., Mon Jan 01, 2024 */
  'EEEE, MMMM d yyyy': 'EEEE, MMMM d yyyy', /** e.g., Monday, January 01 2024 */
  'iii dd': 'iii dd', /** e.g., Mon 01 */
  'iii dd MMM yyyy': 'iii dd MMM yyyy', /** e.g Mon 01 Jan 2024 */
  'LLL d': 'LLL d', /** Month short day of month, e.g., Jun 16 */
  MMM: 'MMM', /** Short month name, e.g., Jan */
  'MMM d': 'MMM d', /** `DATE_FORMAT_SHORT: `MMM d`, e.g., Jan 1` */
  'MMM dd': 'MMM dd', /** Short month and date, e.g., Jan 02 */
  'MMM do': 'MMM do', /** Short month and date (Ordinal), e.g., June 16th */
  'MMM d yyyy': 'MMM d yyyy', /** Short month, date and year, e.g., Jan 01 2024 */
  'MMM yyyy': 'MMM yyyy', /** e.g., Jan 2024 */
  'MMMM d': 'MMMM d', /** e.g., January 1 */
  'MMMM dd, yyyy': 'MMMM dd, yyyy', /** Journal Entry Date, e.g., January 01, 2024 */
  'MMMM dd': 'MMMM dd', /** Full month and date:, e.g., January 02 */
  'MMMM do': 'MMMM do', /** Full month and date (Ordinal), e.g., January 1st */
  'MMMM, yyyy': 'MMMM, yyyy', /** Full month and year, Review Date, e.g., January, 2024 */
  MMMM: 'MMMM', /** Full month name, e.g., January */
  p: 'p', /** Long localized time, e.g., 12:00 AM */
  P: 'P', /** Long localized date, e.g., 04/29/1453 */
  PP: 'PP', /** Long localized date: Month name, day of month, year, e.g., Apr 29, 1453 */
} as const;
type DateFormatsRequireLocalization = keyof typeof DATE_FORMATS_REQUIRE_LOCALIZATION;

const MOMENT_FORMATS = {
  [TIME_12]: 'h:mm A',
  [CALENDAR_DATE_FORMAT_DATE_FNS]: 'YYYY-MM-DD',
  yyyy: 'YYYY',
  'EEE MMM dd, yyyy': 'ddd MMM DD, YYYY',
  PP: 'll',
};
type MomentFormats = keyof typeof MOMENT_FORMATS;

/** Supported date formats */
type DateFnsFormats = DateFormatsNumeric | DateFormatsRequireLocalization;
type DateFormats = DateFnsFormats | MomentFormats;
type DateLike = Date | moment.Moment;

const lookupFormatString = (dateFnsFormat: DateFnsFormats): string => {
  const momentFormat = MOMENT_FORMATS[dateFnsFormat as MomentFormats];
  if (!momentFormat && __DEV__) {
    // eslint-disable-next-line no-console
    console.warn(`date-fns ${format} format doesn't have a for moment`);
  }
  return momentFormat ?? dateFnsFormat;
};

interface FormatDate {
  /**
   * ! Formats a date object using `date-fns` or `moment`.
   *
   * @param date The date to format
   * @param formatString The `date-fns` format string to use.
   *   Defaults to `yyyy-MM-dd`.
   *   e.g. `2024-09-03`
   * @param locale Required for displaying non numeric date appropriately for the locale.
   *
   * @example
   * ```ts
   * * Default format is `yyyy-MM-dd` and does not require localization.
   * formatDate(selectedDate);
   *
   * * Numeric format does not require localization
   * formatDate(selectedDate, 'yyyy-MM-dd');
   * formatDate(selectedDate, 'yyyy');
   *
   * * Non-numeric formats do require localization
   * const dateFnsLocale = useDateFnsLocale();
   * formatDate(selectedDate, 'MMM do', dateFnsLocale);
   * formatDate(selectedDate, 'h:mm a', dateFnsLocale);
   * formatDate(selectedDate, 'EEE MMM dd, yyyy', dateFnsLocale);
   * ```
   */
  (
    date: DateLike,
    formatString: DateFormatsRequireLocalization,
    locale: Locale
  ): string;
  (
    date: DateLike,
    formatString: DateFormatsNumeric,
    locale?: Locale
  ): string;
  (date: DateLike): string;
}

export const formatDate: FormatDate = (
  date: DateLike,
  formatString: DateFormats = CALENDAR_DATE_FORMAT_DATE_FNS,
  // locale?: Locale,
): string => {
  if (moment.isMoment(date)) {
    return date.format(lookupFormatString(formatString));
  }
  return format(date, formatString, { locale: defaultLocale });
};

/**
 * This should only be used with dates that are constructed with a date string
 */
const adjustForTimezones = (date: Date): Date => new Date(
  date.getTime() + (date.getTimezoneOffset() * 60000),
);

/**
 * Returns a Date that has been adjusted for timezones.
 */
export const newTZDate = (date: DateString) => adjustForTimezones(new Date(date));

/**
 * Returns a Date representing the start of today.
 */
export const todayDate = () => startOfDay(new Date());

/**
 * Returns a Date representing the start of tomorrow.
 */
export const tomorrowDate = () => add(todayDate(), { days: 1 });

/**
 * Returns a Date representing the start of month.
 */
export const startDateOfMonth = (month: Date) => startOfMonth(month);

/**
 * Adds 1 month to the specified month.
 */
export const addMonth = (month: DateString): DateString => (
  formatDate(add(newTZDate(month), { months: 1 }))
);

/**
 * Subtracts 1 month to the specified month.
 */
export const subMonth = (month: DateString): DateString => (
  formatDate(sub(newTZDate(month), { months: 1 }))
);

/**
 *
 * Returns an object representing the duration of a timespan.
 */
export const convertTimeSpanToDuration = (value: string): Duration => {
  const regex = /(\d+|)(?:\.|\s|)(\d\d:\d\d:\d\d)/;
  try {
    if (!regex.test(value)) throw new Error(`Invalid timespan: ${value}`);
  } catch (err) {
    captureException(err);
  }
  const [, days, time] = regex.exec(value) as string[];
  const [hours, minutes] = time.split(':');
  return {
    days: parseInt(days || '0', 10),
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
  };
};
