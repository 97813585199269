import React, { FunctionComponent, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Field, useField } from 'react-final-form';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { Mutators } from 'final-form-arrays';
import { OnChange } from 'react-final-form-listeners';

import Icon from 'src/common/components/IconDS22';
import { getNextStartTime, getReturnTimeString, getTimeString } from '../../helpers';
import { getClassNameFor } from '../../../common/helpers';
import {
  INSTABOOK_DEPARTURE_TIME_FLEXIBLE,
  INSTABOOK_EDITOR_TRIP_DURATION_FIELD,
  INSTABOOK_FIXED_DEPARTURE_TIMES,
  INSTABOOK_FLEXIBLE_DEPARTURE_TIME_END,
  INSTABOOK_FLEXIBLE_DEPARTURE_TIME_START,
  INSTABOOK_TIME_FORMAT,
  INSTABOOK_INITIAL_FLEXIBLE_DEPARTURE_TIME_START,
  INSTABOOK_INITIAL_FLEXIBLE_DEPARTURE_TIME_END,
  INSTABOOK_FIXED_DEPARTURE_TIMES_ERROR,
} from '../../constants';

import hasFixedTimeDuplicates from '../../utils/hasFixedTimeDuplicates';
import Svg from '../../../common/components/Svg';
import EventDetailsFooter from '../EventDetailsFooter';
import InstabookDepartureTimeDropdown from '../InstabookDepartureTimeDropdown';
import InstabookTime from '../InstabookTime';
import InstabookHeaderInfo from '../InstabookHeaderInfo';
import InstabookTripDetails from '../InstabookTripDetails';
import InstabookEditorHeader from '../InstabookEditorHeader';
import Button from '../../../common/components/Button';

import s from './InstabookDepartureTimesEditor.module.scss';

const copy = {
  flexible: 'Allow renters to book any departure time in 15 minute increments within this range:',
  fixed: 'Require renters to select a departure time from these options:',
};

type FixedTime = {
  id: number;
  startTime: string;
};

type FixedTimeError = {
  startTime?: string;
};

type InstabookDepartureTimesEditorProps = {
  show: boolean;
  mutators: Mutators;
  values: Record<string, any>;
  errors: any;
  closeTimeEditor: () => void;
};

let nextId = 1;

const validateFixedTimes = (allValues: FixedTime[]): FixedTimeError[] => {
  if (!allValues.length) {
    return [];
  }

  const errorsArray: FixedTimeError[] = [];

  if (hasFixedTimeDuplicates(allValues)) {
    allValues.forEach(value => {
      if (value) {
        const err: FixedTimeError = {};

        const hasDuplicates = allValues.some(v => (
          // there is a value with the same `startTime`
          v.startTime === value.startTime
          // ensure it isn't the current value
          && v.id !== value.id
        ));

        if (hasDuplicates) err.startTime = 'Duplicate values are not allowed';

        errorsArray.push(err);
      }
    });
  }

  return errorsArray;
};

const InstabookDepartureTimesEditor: FunctionComponent<InstabookDepartureTimesEditorProps> = (
  { show, mutators, values, errors, closeTimeEditor },
) => {
  const { input: flexibleField } = useField(INSTABOOK_DEPARTURE_TIME_FLEXIBLE);
  const { input: fixedTimesField } = useField(INSTABOOK_FIXED_DEPARTURE_TIMES);
  const { input: flexibleTimesStartField } = useField(INSTABOOK_FLEXIBLE_DEPARTURE_TIME_START);
  const { input: flexibleTimesEndField } = useField(INSTABOOK_FLEXIBLE_DEPARTURE_TIME_END);
  const duration = values[INSTABOOK_EDITOR_TRIP_DURATION_FIELD];
  const flexible = values[INSTABOOK_DEPARTURE_TIME_FLEXIBLE];
  const flexibleTimes = [
    values[INSTABOOK_FLEXIBLE_DEPARTURE_TIME_START],
    values[INSTABOOK_FLEXIBLE_DEPARTURE_TIME_END],
  ];
  const fixedTimes = values[INSTABOOK_FIXED_DEPARTURE_TIMES];

  // These values are being used as initial values for the form fields,
  // if we 'reset' the form to it's state prior to opening the editor.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialFormState = useMemo(() => ({ flexible, flexibleTimes, fixedTimes }), [show]);

  const getTime = useCallback(time => getTimeString(time, INSTABOOK_TIME_FORMAT), []);
  const getReturnTime = useCallback(
    time => getReturnTimeString(time, duration, INSTABOOK_TIME_FORMAT),
    [duration],
  );

  const cancelEdit = useCallback(() => {
    // reset the form to the initial state
    flexibleField.onChange(initialFormState.flexible);
    fixedTimesField.onChange(initialFormState.fixedTimes);
    flexibleTimesStartField.onChange(initialFormState.flexibleTimes[0]);
    flexibleTimesEndField.onChange(initialFormState.flexibleTimes[1]);

    closeTimeEditor();
  }, [
    flexibleField,
    fixedTimesField,
    flexibleTimesStartField,
    flexibleTimesEndField,
    closeTimeEditor,
    initialFormState,
  ]);

  const onSubmit = () => {
    const hasFlexibleTimeErrors = errors[INSTABOOK_FLEXIBLE_DEPARTURE_TIME_START];
    const hasErrors = validateFixedTimes(values[INSTABOOK_FIXED_DEPARTURE_TIMES])
      .some(err => err.startTime) || hasFlexibleTimeErrors;

    if (!errors[INSTABOOK_FIXED_DEPARTURE_TIMES_ERROR] && !hasErrors) {
      closeTimeEditor();
    }
  };

  const addFixedTime = useCallback(() => {
    const startTime = getNextStartTime(fixedTimes);
    nextId += 1;
    mutators.push(INSTABOOK_FIXED_DEPARTURE_TIMES, {
      id: nextId,
      startTime,
    });
  }, [fixedTimes, mutators]);

  return (
    <div className={getClassNameFor(s, 'root', classNames({ show }))}>
      <InstabookEditorHeader
        title="Edit Departure Times"
        icon={<Svg className={s.icon} href="#old-icon-time" />}
        classNameModifier="departureTimesEditor"
      >
        <InstabookHeaderInfo />
      </InstabookEditorHeader>
      <div className={s.body}>
        <div className={s.content}>
          <InstabookTripDetails>
            <Field
              name={INSTABOOK_DEPARTURE_TIME_FLEXIBLE}
              component={InstabookDepartureTimeDropdown}
            />
            <OnChange name={INSTABOOK_DEPARTURE_TIME_FLEXIBLE}>
              {(isFlexible: boolean) => {
                if (isFlexible) {
                  fixedTimesField.onChange([]);
                } else {
                  flexibleTimesStartField.onChange(INSTABOOK_INITIAL_FLEXIBLE_DEPARTURE_TIME_START);
                  flexibleTimesEndField.onChange(INSTABOOK_INITIAL_FLEXIBLE_DEPARTURE_TIME_END);
                }
              }}
            </OnChange>
            <p className={s.description}>{copy[flexible ? 'flexible' : 'fixed']}</p>
            <div className={getClassNameFor(s, 'main', classNames({ flexible }))}>
              {flexible ? (
                <>
                  <div>
                    <InstabookTime
                      label="Earliest Departure"
                      name={INSTABOOK_FLEXIBLE_DEPARTURE_TIME_START}
                    />
                    {flexibleTimes[0] && (
                      <>
                        <p className={s.tripTitle}>Earliest Trip</p>
                        <span className={s.tripTimes}>
                          <time>{getTime(flexibleTimes[0])}</time>
                          {' → '}
                          <time>{getReturnTime(flexibleTimes[0])}</time>
                        </span>
                      </>
                    )}
                  </div>
                  <div>
                    <InstabookTime
                      label="Latest Departure"
                      name={INSTABOOK_FLEXIBLE_DEPARTURE_TIME_END}
                    />
                    {flexibleTimes[1] && (
                      <>
                        <p className={s.tripTitle}>Latest Trip</p>
                        <span className={s.tripTimes}>
                          <time>{getTime(flexibleTimes[1])}</time>
                          {' → '}
                          <time>{getReturnTime(flexibleTimes[1])}</time>
                        </span>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className={s.addButton}
                    onClick={addFixedTime}
                  >
                    <span className={s.addIcon} />
                    {
                      !fixedTimes || fixedTimes.length === 0
                        ? 'Add departure times'
                        : 'Add another option'
                    }
                  </button>
                  <FieldArray
                    name={INSTABOOK_FIXED_DEPARTURE_TIMES}
                    validate={validateFixedTimes}
                  >
                    {(fieldArrayProps: FieldArrayRenderProps<string, HTMLInputElement>) => (
                      fieldArrayProps.fields.map((name: string, index: number) => (
                        <div
                          key={name}
                          className={s.fixedTime}
                        >
                          <InstabookTime
                            label={`Departure ${index + 1}`}
                            subtitle={`Return: ${getReturnTime(fixedTimes[index].startTime)}`}
                            name={`${name}.startTime`}
                          />
                          <button
                            type="button"
                            className={s.deleteButton}
                            onClick={() => fieldArrayProps.fields.remove(index)}
                            aria-label="Delete"
                          >
                            &minus;
                          </button>
                        </div>
                      ))
                    )}
                  </FieldArray>
                </>
              )}
            </div>
          </InstabookTripDetails>
        </div>
      </div>
      <EventDetailsFooter classNameModifier="modalFooter">
        <Button
          type="button"
          classNameModifier="paired secondary"
          onClick={cancelEdit}
        >
          <Icon
            id="caret-left"
          />
          Back
        </Button>
        <Button
          type="button"
          onClick={onSubmit}
          classNameModifier="paired instabook-green date"
        >
          Continue
        </Button>
      </EventDetailsFooter>
    </div>
  );
};

export default InstabookDepartureTimesEditor;
