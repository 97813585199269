import React from 'react';

import { EXTERNAL_PATHS } from 'src/common/constants';
import {
  getClassNameFor,
} from '../../../common/helpers';
import ExternalLink from '../../../common/components/ExternalLink';
import s from './PayoutLink.module.scss';

type PayoutLinkProps = {
  children: React.ReactNode;
  classNameModifier?: string;
};

const PayoutLink: React.FC<PayoutLinkProps> = ({
  children,
  classNameModifier,
}) => (
  <ExternalLink
    to={EXTERNAL_PATHS.ADMIN_PAYMENTS}
    className={getClassNameFor(s, 'root', classNameModifier)}
  >
    {children}
  </ExternalLink>
);

export default PayoutLink;
