import classNames from 'classnames';
import React, { FC } from 'react';
import { getClassNameFor } from 'src/common/helpers';
import Icon from '../IconDS22';
import styles from './Error.module.scss';

export type ErrorProps = {
  children: React.ReactNode;
  centered?: boolean;
  showIcon?: boolean;
};

const Error: FC<ErrorProps> = ({ children, centered, showIcon }) => (
  <div className={getClassNameFor(styles, 'root', classNames({ centered }))}>
    {showIcon && <Icon id="warning-filled" size="m" />}
    {children}
  </div>
);

export default Error;
