import Cookies from 'js-cookie';
import gitShaVersion from './gitShaVersion';

/**
 * This is a slimmed down version of the full apiFetch specifically to be used
 * in the pubsub worker iframe. It only works client-side.
 */
const apiFetch = (url: string, initOptions: RequestInit) => fetch(
  `/api/v4${url}`,
  {
    ...initOptions,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
      ...gitShaVersion,
      ...initOptions.headers,
    } as Record<string, string>,
  },
);

/**
 * Ignore various network related errors.
 */
export const ignoreSpecificNetworkErrors = (error: unknown): void => {
  // Safari throws this error when there is an issue connecting to the server.
  if (error instanceof TypeError && error.message === 'Load failed') {
    return;
  }

  // Chrome throws this error when there is an issue connecting to the server.
  if (error instanceof TypeError && error.message === 'Failed to fetch') {
    return;
  }

  // Firefox throws this error when there is an issue connecting to the server.
  if (error instanceof TypeError && error.message === 'NetworkError when attempting to fetch resource.') {
    return;
  }

  // Rethrow the error as we're not specifically handling it here.
  throw error;
};

export default apiFetch;
