import insurance from 'src/locales/en/insurance.json';

const translations = {
  insurance,
};

const useTranslation = (namespace: keyof typeof translations) => {
  const t = (
    key: keyof typeof translations[typeof namespace],
  ) => translations[namespace][key] ?? key;

  return { t };
};

export default useTranslation;
