import React, { FC } from 'react';
import { formatDate } from 'src/common/utils/dateHelpers';
import { INSTABOOK_TIME_FORMAT } from 'src/calendar/constants';
import { CalendarEvent, UnavailableEvent } from 'src/calendar/types';
import { getEndDate, getStartDate, isSameSecond, isSameDay } from 'src/calendar/helpers';

type TimeProps = {
  event: CalendarEvent | UnavailableEvent;
  isUnavailableEvent?: boolean;
};

const Time: FC<TimeProps> = ({
  event,
  isUnavailableEvent = false,
}) => {
  const end = getEndDate(event);
  const start = getStartDate(event);
  const totalDays = event.total_days ?? 1;
  let label;

  // All events
  if (event.event_start_time === null && totalDays === 1) {
    // Booking without departure time
    label = 'TBD';
  } else if (event.event_start_time === null && event.day_number === 1) {
    // Multi day: booking inquiry without departure time
    label = 'TBD';
  } else if (event.event_end_time == null && event.day_number === totalDays) {
    // Multi day: booking inquiry without departure time, thus no return time
    label = 'TBD';
  } else if (totalDays > 1 && event.day_number === 1) {
    // Multi day: first day
    label = `Depart ${formatDate(start, INSTABOOK_TIME_FORMAT)}`;
  } else if (totalDays > 1 && event.day_number === totalDays) {
    // Multi day: last day
    label = `Return ${formatDate(end, INSTABOOK_TIME_FORMAT)}`;
  } else if (totalDays > 1) {
    // Multi day middle days
    label = 'All day';
  } else if (isSameSecond(start, end)) {
    // Single time event
    label = formatDate(start, INSTABOOK_TIME_FORMAT);
  } else if (isSameDay(start, end)) {
    // Single day event
    label = `${formatDate(start, INSTABOOK_TIME_FORMAT)} → ${formatDate(end, INSTABOOK_TIME_FORMAT)}`;
  }

  // Unavailable events only
  if (isUnavailableEvent) {
    if (event.block_type === 1) {
      // All events blocked by whole day
      label = 'All day';
    } else if (event.event_start_time && event.event_end_time) {
      // Time Events
      label = `${formatDate(start, INSTABOOK_TIME_FORMAT)} → ${formatDate(end, INSTABOOK_TIME_FORMAT)}`;
    } else if (totalDays > 1 && event.day_number === 1) {
      // Multi day: first day
      label = `Start ${formatDate(start, INSTABOOK_TIME_FORMAT)}`;
    } else if (totalDays > 1 && event.day_number === totalDays) {
      // Multi day: last day
      label = `End ${formatDate(end, INSTABOOK_TIME_FORMAT)}`;
    }
  }

  return (
    <time
      dateTime={formatDate(start, INSTABOOK_TIME_FORMAT)}
    >
      {label}
    </time>
  );
};

export default Time;
