import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import ProgressBar from '../ProgressBar';

type WizardProgressBarProps = {
  classNameModifier?: string;
  routes: string[];
};

const WizardProgressBar: FC<WizardProgressBarProps> = ({ classNameModifier, routes }) => {
  const { pathname } = useLocation();

  // Maps routes and currentPath to [0, 100]
  const page = routes.findIndex(path => pathname.includes(path)) + 1;
  const progress = Math.round((page / routes.length) * 100);

  return (
    <ProgressBar
      progress={progress}
      classNameModifier={classNameModifier}
    />
  );
};

export default WizardProgressBar;
