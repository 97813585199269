import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import moment from 'moment';
import { add, format, set } from 'date-fns';

import { trackEvent } from 'src/common/tracking';
import { dateFormat } from '../../../../common/helpers';
import { roundToNextMinutes } from '../../../helpers';
import useTimeZones from '../../../../common/hooks/useTimeZones';
import FormError from '../../../../common/components/FormError';
import DateInput from '../../../../common/components/inputs/DateInput';
import NumberAddInput from '../../../../common/components/inputs/NumberAddInput';
import TimeInput from '../../../../common/components/inputs/TimeInput';
import {
  OFFER_EXPIRY_DATE_FIELD,
  OFFER_EXPIRY_TIME_FIELD,
  OFFER_EXPIRY_HOURS_FIELD,
  OFFER_EXPIRY_DATE_FORMAT,
} from '../../../constants';
import s from './CustomOfferExpiryCard.module.scss';
import { adjustTimezone } from '../../../../common/utils/time';
import { normalizeTime } from '../../../../common/timeHelpers';

const classNameModifier = 'customOfferExpiry';
const parseTime = value => (value === '' ? '00:00' : value);

const CustomOfferExpiryFieldset = ({
  hoursUntilTripStart,
  setOfferExpiryDate,
  tripStartDate,
  trip,
  timezoneOffsetInHours,
}) => {
  const {
    active: activeField,
    error,
    values: {
      [OFFER_EXPIRY_DATE_FIELD]: offerExpiryDateValue = '',
      [OFFER_EXPIRY_TIME_FIELD]: offerExpiryTimeValue = '',
    },
  } = useFormState();
  const { boatTimeZoneId } = useTimeZones(trip);
  const parseDate = useCallback(dateMoment => {
    const [hours = 0, minutes = 0] = offerExpiryTimeValue.split(':');
    return moment.isMoment(dateMoment)
      ? format(set(dateMoment.toDate(), { hours, minutes }), OFFER_EXPIRY_DATE_FORMAT)
      : undefined;
  }, [offerExpiryTimeValue]);

  return (
    <fieldset className={s.fieldset}>
      <h4 className={s.legend}>
        Offer Expires After:
      </h4>
      <FormError
        className={s.error}
        error={error}
      />
      <div className={s.dateTimeFields}>
        <label className={s.fieldLabel}>
          Date
          <Field
            component={DateInput}
            name={OFFER_EXPIRY_DATE_FIELD}
            format={dateFormat}
            parse={parseDate}
            displayFormat="ddd MMM D, YYYY"
            openDirection="up"
            showClearDate={false}
            classNameModifier={classNameModifier}
            isDayBlocked={day => day.isAfter(tripStartDate, 'day')}
            shouldBeTouchedToShowError={false}
          />
          <OnChange name={OFFER_EXPIRY_DATE_FIELD}>
            {(value, prevValue) => {
              if (prevValue // Prevent activation of listener on setting initial value
                  // DateField is active (focused) only when date picker is open
                  // Thus activeField is undefined once date picker is closed
                  // This is a workaround to determine that the activeField was
                  // the DATE_FIELD_NAME
                  && activeField !== OFFER_EXPIRY_TIME_FIELD
                  && activeField !== OFFER_EXPIRY_HOURS_FIELD) {
                const newOfferExpiryDate = new Date(value);
                setOfferExpiryDate(newOfferExpiryDate);
                trackEvent('Change expiry time', {
                  event_category: 'Custom Offer Expiry',
                  event_label: 'Date',
                });
              }
            }}
          </OnChange>
        </label>
        <label className={s.fieldLabel}>
          Time (15 min intervals)
          <Field
            autoFocus={false}
            classNameModifier={classNameModifier}
            name={OFFER_EXPIRY_TIME_FIELD}
            parse={parseTime}
            extraInputAttrs={{
              min: '00:00',
              max: '23:45',
              step: 900,
            }}
          >
            {({ input, ...restProps }) => {
              const changeOfferExpiryDate = (value, days = 0) => {
                const [hours, minutes] = value.split(':');
                let currentOfferExpiryDate = new Date(offerExpiryDateValue);
                if (days) {
                  currentOfferExpiryDate = add(currentOfferExpiryDate, { days });
                }
                const newOfferExpiryDate = set(currentOfferExpiryDate, { hours, minutes });
                setOfferExpiryDate(newOfferExpiryDate);
                trackEvent('Change expiry time', {
                  event_category: 'Custom Offer Expiry',
                  event_label: 'Time',
                });
              };

              return (
                <TimeInput
                  disablePopup
                  input={{
                    ...input,
                    onBlur: ({ target: { value } }) => {
                      const { formattedTime, days } = normalizeTime(value);
                      input.onChange(formattedTime);
                      changeOfferExpiryDate(formattedTime, days);
                    },
                    onChange: ({ target: { value } }) => {
                      input.onChange(value);
                      changeOfferExpiryDate(value);
                    },
                    required: true,
                  }}
                  {...restProps}
                />
              );
            }}
          </Field>
        </label>
        {timezoneOffsetInHours !== 0 && (
          <label
            className={s.timeZoneLabel}
            htmlFor={OFFER_EXPIRY_TIME_FIELD}
          >
            {`Time zone: ${boatTimeZoneId}`}
          </label>
        )}
      </div>
      <Field
        component={NumberAddInput}
        name={OFFER_EXPIRY_HOURS_FIELD}
        label="Number of hours"
        minValue={1}
        maxValue={hoursUntilTripStart}
        classNameModifier={classNameModifier}
      />
      <OnChange name={OFFER_EXPIRY_HOURS_FIELD}>
        {value => {
          if (activeField === OFFER_EXPIRY_HOURS_FIELD) {
            const actualDate = roundToNextMinutes(new Date(), { roundToNext: 15 });
            const newOfferExpiryDate = adjustTimezone(actualDate, value - timezoneOffsetInHours);
            setOfferExpiryDate(newOfferExpiryDate);
            trackEvent('Change expiry time', {
              event_category: 'Custom Offer Expiry',
              event_label: 'Relative',
            });
          }
        }}
      </OnChange>
    </fieldset>
  );
};

CustomOfferExpiryFieldset.propTypes = {
  hoursUntilTripStart: PropTypes.number.isRequired,
  setOfferExpiryDate: PropTypes.func.isRequired,
  tripStartDate: PropTypes.instanceOf(Date).isRequired,
  trip: PropTypes.object.isRequired,
  timezoneOffsetInHours: PropTypes.number.isRequired,
};

export default CustomOfferExpiryFieldset;
