import React, { FC } from 'react';

import classNames from 'classnames';
import { getClassNameFor } from '../../../common/helpers';
import PriceTerm from '../../../common/components/PriceTerm';
import { Currency } from '../../../types/common/Currencies';
import s from './TermsAndCancellation.module.scss';
import { completePriceFormatter } from '../../../common/utils/currency';

type TermsAndCancellationProps = {
  currency: Currency;
  classNameModifier?: string;
  deposit: number;
  listingUrl?: string;
  cancellationPolicy?: string;
  withTooltips?: boolean;
  theme?: 'light' | 'lightBlue' | 'dark';
};

const TermsAndCancellation: FC<TermsAndCancellationProps> = ({
  currency,
  deposit = 0,
  cancellationPolicy,
  listingUrl,
  classNameModifier,
  withTooltips = true,
  theme = 'light',
}) => (
  <ul className={getClassNameFor(s, 'root', classNames(classNameModifier, theme))}>
    {cancellationPolicy && (
      <li className={s.term}>
        <div className={s.termHeading}>Terms and Cancellation Policy</div>
        Service fees are non-refundable.
        <br />
        <PriceTerm tooltipContent={withTooltips && (
          <>
            Please review
            {' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={listingUrl}
            >
              the listing description
            </a>
            {' '}
            for complete rental requirements and terms.
          </>
        )}
        >
          {cancellationPolicy}
        </PriceTerm>
      </li>
    )}
    {deposit > 0 && (
      <li className={s.term}>
        <div className={s.termHeading}>Security Allowance</div>
        <PriceTerm tooltipContent={withTooltips && (
          <>
            In case of minor expenses or damage,
            the listing owner may claim up to this amount
            as an additional charge.
            {' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://getmyboat.zendesk.com/hc/en-us/articles/115003922928-The-owner-has-filed-a-claim-under-the-incidental-coverage-limit-What-is-this-"
            >
              Find out more.
            </a>
          </>
        )}
        >
          {`${completePriceFormatter(
            deposit,
            currency?.code,
            currency?.symbol,
            currency?.precision,
          )} damage & incidentals.`}
        </PriceTerm>
      </li>
    )}
  </ul>
);

export default TermsAndCancellation;
