import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ImmutableTrip } from '../../types/trips/Trips';
import getListingCaptainOption, {
  getBinaryKeyForCaptainOption,
  ListingCaptainOption,
} from '../utils/getListingCaptainOption';
import getCaptainInitialValues from '../utils/getCaptainInitialValues';
import getCaptainHeadings from '../utils/getCaptainHeadings';
import { ImmutableOffer } from '../../types/offer/Offer';
import { getBoatMap } from '../ducks/boatMap';
import getTripCaptainOption from '../utils/getTripCaptainOption';
import { CAPTAIN_OPTION_KEYS } from '../constants';

const CaptainOptions = Object.values(CAPTAIN_OPTION_KEYS);
export type CaptainOption = typeof CaptainOptions[number] | '';

const useCaptainData = (
  trip: ImmutableTrip,
  offer: ImmutableOffer | undefined = undefined,
  isEditOffer: boolean = false,
): {
  captainOptionHeading: Pick<ListingCaptainOption, 'heading' | 'shortLabel' | 'subHeading' | 'key'>;
  selectedCaptainOption: CaptainOption;
  editCaptainOption: boolean;
  showCaptainConflict: boolean;
  listingCaptainOptions: ListingCaptainOption[];
} => {
  const boatMap = useSelector(getBoatMap);
  let { boat } = trip.toJS();
  if (offer) {
    const offerBoatId = offer.getIn(['boat', 'id'], '');
    const tripBoatId = boat.id;
    if (offerBoatId && tripBoatId !== offerBoatId && boatMap[offerBoatId]) {
      boat = boatMap[offerBoatId].toJS();
    }
  }

  const listingCaptainOptions = getListingCaptainOption(
    boat.bareboat as boolean,
    boat.captain_option as string,
  );

  const captainOptionBinaryKey = getBinaryKeyForCaptainOption(
    boat.bareboat as boolean,
    boat.captain_option as string,
  );

  const tripCaptainOption = useMemo(() => getTripCaptainOption(trip, offer), [offer, trip]);

  const showCaptainConflict = useMemo(
    () => (tripCaptainOption && listingCaptainOptions && listingCaptainOptions.length > 1
      ? !listingCaptainOptions.some((o: ListingCaptainOption) => o.key === tripCaptainOption)
      : false),
    [listingCaptainOptions, tripCaptainOption],
  );

  const selectedCaptainOption = useMemo(
    () => {
      if (!showCaptainConflict) {
        const selectedOption = getCaptainInitialValues(trip, offer);
        const isCaptainOptionAutoPatched = listingCaptainOptions?.length === 1
          && listingCaptainOptions[0].key !== selectedOption;

        if (isEditOffer && isCaptainOptionAutoPatched) {
          return listingCaptainOptions[0].key;
        }
        return selectedOption;
      }
      return '';
    },
    [offer, showCaptainConflict, trip, listingCaptainOptions, isEditOffer],
  );

  const selectedListingCaptainOption = listingCaptainOptions?.find(
    (o) => o.key === selectedCaptainOption,
  );

  const captainOptionHeading = useMemo(
    () => getCaptainHeadings(
      captainOptionBinaryKey,
      selectedListingCaptainOption,
      trip,
      isEditOffer,
    ),
    [captainOptionBinaryKey, isEditOffer, selectedListingCaptainOption, trip],
  );

  let editCaptainOption = false;
  if (listingCaptainOptions && listingCaptainOptions.length > 1) {
    editCaptainOption = true;
  }

  return {
    captainOptionHeading,
    selectedCaptainOption,
    editCaptainOption,
    showCaptainConflict,
    listingCaptainOptions,
  };
};

export default useCaptainData;
