import React, { FC } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

import useMounted from 'src/common/hooks/useMounted';
import { useOnWindowWidthChange } from 'src/common/hooks';
import { getClassNameFor } from 'src/common/helpers';
import CloseButton from '../CloseButton';
import ScrollLock from '../ScrollLock';
import styles from './ActionSheet.module.scss';

export type ActionSheetProps = {
  children: React.ReactNode;
  close: () => void;
  isOpen: boolean;
  classNameModifier?: string;
};
/**
 * Beware React portal can prevent children from matching nested css.
 * Children passed to this component should set their styles at the root level of their module.scss
 *
 * Does not work because children are portaled to another node, which is not a descendant of .root
 * `.root{ .children {} }`
 *
 * Instead, lift .children selector to the top level of it's module.scss
 * `.root{} .children{}`
 * */
const ActionSheet: FC<ActionSheetProps> = ({
  children,
  close,
  isOpen,
  classNameModifier = '',
}) => {
  const isMounted = useMounted();

  useOnWindowWidthChange(() => {
    if (isOpen) {
      close();
    }
  });

  if (!isMounted) {
    return null;
  }

  return createPortal(
    (
      <div className={getClassNameFor(styles, 'root', classNames({ isOpen }, classNameModifier))}>
        <button
          type="button"
          className={styles.overlay}
          onClick={close}
          aria-label="Close"
        />
        <div
          className={getClassNameFor(styles, 'content', classNames({ isOpen }))}
          role="alertdialog"
          aria-modal="true"
        >
          <div className={styles.close}>
            <CloseButton
              onClick={close}
              positionAbsolute={false}
              classNameModifier="actionSheet"
            />
          </div>
          {isOpen && (
            <>
              <ScrollLock />
              {children}
            </>
          )}
        </div>
      </div>
    ),
    document.body,
  );
};

export default ActionSheet;
