import React from 'react';
import PropTypes from 'prop-types';

export const FieldPrefixContext = React.createContext();

const FormSection = ({ children, prefix }) => (
  <FieldPrefixContext.Provider value={prefix}>
    {children}
  </FieldPrefixContext.Provider>
);

FormSection.propTypes = {
  children: PropTypes.node.isRequired,
  prefix: PropTypes.string.isRequired,
};

export default FormSection;
